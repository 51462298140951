import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

import ActionCardRenderer from './ActionCardRenderer';

class ActionCard extends Component {

    constructor(props) {
        super(props);
        this.state = { mediaQuery: false };
    }

    componentDidMount() {

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
    }

    render() {

        const minWidth = this.state.mediaQuery.matches;

        const useStyle = makeStyles({
            card: {
                height: minWidth ? 300 : null,
                width: minWidth ? '15vw' : '100%',
                margin: minWidth ? 10 : 0,
                position: 'relative',
            },
            cardTitle: {
                borderBottom: '1px solid #E0E0E0',
                display: 'flex',
                alignItems: 'center',
            },
            footer: {
                position: 'absolute',
                bottom: 0,
            },
            goIcon: {
                width: 36,
                height: 36,
                color: '#373737',
            },
            goIconButton: {
                position: 'absolute',
                right: 5,
                height: 72,
                width: 72,
                padding: 18,
            },
        });
        return (
          <ActionCardRenderer useStyle={useStyle} parent={this} minWidth={minWidth}/>
        );
    }
}

ActionCard.defaultProps = {
    subtitle: '',
    footer: '',
};

ActionCard.propTypes = {
    action: PropTypes.func.isRequired,
    footer: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
};

export default ActionCard;