import {
  CALLRECORDING_HTTP_SUCCESS,
  EDITCALLRECORDING_HTTP_SUCCESS,
  CALLRECORDING_HTTP_FAILURE,
  EDITCALLRECORDING_HTTP_FAILURE,
  RESET_FLAG,
} from '../actions/typeConstants';

const initialState = {
  callRecordingData: null,
  successMsg: null,
  errorMsg: null,
}

function parseError(error) {
  const defaultError = 'Error occurred';

  try{
    let parsedError = JSON.parse(error);
    return get(parsedError, 'error.message', defaultError);
  } catch(error) {
    return defaultError;
  }
}

export default function(state = initialState, action) {
  switch(action.type) {
    case CALLRECORDING_HTTP_SUCCESS:
      return { ...state, callRecordingData: action.data, errorMsg: null, errorCode: null }
    case CALLRECORDING_HTTP_FAILURE:
      return { ...state, errorMsg: parseError(action.error.response.text), successMsg: null, errorCode: action.error.response.status }
    case EDITCALLRECORDING_HTTP_SUCCESS:
      return { ...state, callRecordingData: action.data, successMsg: 'Saved!', errorMsg: null}
    case EDITCALLRECORDING_HTTP_FAILURE:
      return { ...state, errorMsg: parseError(action.error.response.text), successMsg: null, errorCode: action.error.response.status }
    case RESET_FLAG:
      return {...state, successMsg: null, errorMsg: null, errorCode: null}
    default:
      return { ...state }
  }
}
