import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import TextInput from 'presentational/formControls/TextInput';
import { DateTimePicker } from "@material-ui/pickers";
import SelectInput from 'presentational/formControls/SelectInput';
import { postCallHttp } from 'actions/httpRequest';
import { DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = () => ({
  button: {
    fontSize: '100%',
  },
  contentStyle: {
    height: '80%'
  },
});

class TimeMachine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      country: '',
      date: null,
      phoneNumber: '',
      timeZone: '',
      open: true
    }
  }

  onChange = (field, data) => {
    this.setState({ [field]: data });
  }

  handleCall = () => {
    const date = new Date(this.state.date);
    let day = date.getDate();

    if(String(day).length<2)
      day = '0'+day;
    let year = date.getFullYear();
    let month =  date.getMonth()+1;

    if(String(month).length<2)
      month = '0'+month
    let hour = date.getHours();

    if(String(hour).length<2)
      hour = '0'+hour;
    let minutes =  date.getMinutes();
    if(String(minutes).length<2)
      minutes = '0'+minutes;


    const obj = {
      id: this.props.selectedPhoneNumber,
      timeMachineDate: `${year}/${month}/${day}`,
      timeMachinePhone: this.state.phoneNumber,
      timeMachineTime: `${hour}:${minutes}`,
      timeMachineTimeZone: this.state.timeZone
    }

    const reqData = {
      reqAction: 'timemachine',
      reqObject: this.props.selectedPhoneNumber,
      reqBody: obj,
    };

    const storeKey = 'timemachine';

    this.props.postCallHttp(reqData, storeKey);
  }

  render() {
    let countryList = [];

    if (this.props.timezones?.length) {
      countryList = this.props.timezones.map(item => {
        return ({ value: item.code, label: item.country })
      });
    }

    let timezonList = [];

    if (this.state.country && this.props.timezones) {
      const country = this.props.timezones.find(item => item.code===this.state.country);
      timezonList = country.zones;
      timezonList = timezonList.map(zone => zone.zone_name)
    }

    const { classes } = this.props;

    return (
      <Dialog
        modal={false}
        open={this.props.show}
        onRequestClose={this.props.onModalClose}
      >
        <DialogTitle>Time machine</DialogTitle>
        <DialogContent classes={{ root: classes.contentStyle }}>
          <div>
              <p>To test your routing configuration:</p><br />
              <p>Enter the desired date, time, time-zone and the phone number you wish to make the call from.</p>
              <TextInput
                disabled={false}
                type='text'
                onChange={(val) => this.onChange('phoneNumber', val)}
                value={this.state.phoneNumber}
                placeholder='External Phone'
              />
              <div className="picker" style={{ paddingLeft: '50px' }}>
                <DateTimePicker
                  format="YYYY/MM/DD hh:mm A"
                  autoOk
                  ampm={true}
                  onChange={date =>
                    this.onChange('date', date)
                  }
                  value={this.state.date }
                  label="Select Date & Time"
                />
              </div>
              <SelectInput
                onChange={(val) => this.onChange('country', val)}
                value={this.state.country}
                options={countryList}
                placeholder='Select Time Zone Country'
              />
              <SelectInput
                onChange={(val) => this.onChange('timeZone', val)}
                value={this.state.timeZone}
                options={timezonList}
                placeholder='Select time zone'
              />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            classes={{ root: classes.button }}
            color='primary'
            onClick={this.props.onModalClose}
          >Close</Button>
          <Button
            classes={{ root: classes.button }}
            color='primary'
            onClick={this.handleCall}
          >Call</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const bindActions = (dispatch) => ({
  postCallHttp: (reqData, storeKey) => dispatch(postCallHttp(reqData, storeKey)),
})

const mapStateToProps = state => ({
  timezones: state.http?.configurations?.timezones,
  selectedPhoneNumber: state.phoneNumbers.selectedPhoneNumber,
})

export default connect(mapStateToProps, bindActions)(withStyles(styles)(TimeMachine));