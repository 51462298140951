import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import StandardSettingsPage from 'presentational/StandardSettingsPage';
import TextInput from 'presentational/formControls/TextInput';
import SubmitButton from 'presentational/formControls/SubmitButton';
import CancelButton from 'presentational/formControls/CancelButton';

import { successNotification, errorNotification } from 'actions/notifications';

const style = () => ({
  header: {
      color: '#595959',
  },
});

class New extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorText: '',
            name: '',
        };
    }

    async submitForm() {
        const error = await this.props.submitForm(this.state.name);
        if (error) {

            console.error(error);
            errorNotification({
              title: 'Failed!',
              message: 'Unsuccessful creation of AutoAttendant.',
          });
            this.setState({
                disabled: false,
                errorText: error.summary,
            });
            return;
        }

        this.props.handleNewAutoAttendant(this.state.name);
    }

    render() {
        const { classes } = this.props;
        return (
          <StandardSettingsPage>
            <h2 className={classes.header}>Enter Auto Attendant Name</h2>
            <TextInput
              errorText={this.state.errorText}
              field="name"
              onChange={(name) => this.setState({ name })}
              placeholder="Name"
              value={this.state.name}
            />
            <div>
              <SubmitButton
                disabled={this.state.disabled || !this.state.name || this.props.autoAttendantNames.some(autoAttendant => autoAttendant.friendlyname == this.state.name || autoAttendant.name == this.state.name)}
                label="Create"
                onClick={() => {
                    this.setState({ disabled: true });
                    this.submitForm(this.state.name);
                }}
              />
              <CancelButton
                label="Cancel"
                onClick={() => this.props.selectAutoAttendant('')}
              />
            </div>
          </StandardSettingsPage>
        );
    }
}

const bindActions = (dispatch) => ({});

export default connect(null, bindActions)(withStyles(style)(New));
