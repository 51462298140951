import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import moment from 'moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import Modal from 'presentational/Modal';
import Footer from 'presentational/Footer';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import TickIcon from '@material-ui/icons/Done';
import CrossIcon from '@material-ui/icons/Clear';

import { getUserPortalHttp, putUserPortalHttp, deleteUserPortalHttp } from 'actions/httpRequest';
import { errorNotification } from 'actions/notifications';

const TAB_NAMES = ['Inbox', 'Sent', 'Company', 'Deleted'];
const PAGE_SIZE_OPTIONS = [10, 25, 50, 100];
let reactTableRef;

function FaxInbox(props) {
  const [faxPerPage, setFaxPerPage] = useState(10);
  const [selectedFaxes, setSelectedFaxes] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [tab, setTab] = useState(0);
  const [deletePressed, setDeletePressed] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortField, setSortField] = useState('timestamp');
  const [descSort, setDescSort] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);

  useEffect(() => {
    getFaxes();
  }, []);

  useEffect(() => {
    if (props.shouldUpdate) {
      changeFolder(TAB_NAMES[tab], faxPerPage);
    }
  }, [props.shouldUpdate]);

  useEffect(() => {
    changeFolder(TAB_NAMES[tab], faxPerPage, pageNumber, sortField, descSort);
  }, [faxPerPage, pageNumber, sortField, descSort])

  useEffect(() => {
    if (props.errorMsg) {
      errorNotification({
        title: 'Failed!', message: props.errorMsg,
      });
    }
  }, [props.errorMsg]);

  const getFaxes = () => {
    const queryString = 'folder=Inbox&offset=10&sortorder=DESC&sortfield=timestamp&pagenumber=1&wrapped=true';
    const reqData = {
      reqAction: 'fax',
      reqQuery: queryString,
    };

    props.getUserPortalHttp(reqData);
  }

  const changeFolder = (folder, offset, pageNumber = 1, sortField = 'timestamp', descSort = false) => {
    const timezone = props.timezone || 'US/Mountain';
    const sortOrder = descSort ? 'ASC' : 'DESC';
    const queryString = `folder=${folder}&offset=${offset}&sortorder=${sortOrder}&sortfield=${sortField}&pagenumber=${pageNumber}`+
                          `&wrapped=true&folders[]=Inbox&folders[]=Sent&folders[]=Company&folders[]=Deleted`+
                          `&offsets[]=10&offsets[]=25&offsets[]=50&offsets[]=100`+
                          `&pagecount=1&timezone=${timezone}`;
    const reqData = {
      reqAction: 'fax',
      reqQuery: queryString,
    };

    props.getUserPortalHttp(reqData);
  }

  const clearSelected = () => {
    setSelectedFaxes([]);
    setSelectAll(false);
    setShowMoveModal(false);
  }

  const toggleSelect = (fax) => {
    let newList;

    if (isSelected(fax)) {
      newList = selectedFaxes.filter(selectedFax => {
        return selectedFax.index !== fax.index;
      });

      if (selectAll) {
        setSelectAll(false);
      }
    } else {
      newList = [...selectedFaxes, fax];
    }

    setSelectedFaxes(newList);
  }

  const getVisibleData = () => {
    const { resolvedData, pageSize, page } = reactTableRef.getResolvedState();
    const visibleStart = page * pageSize;
    const visibleEnd = ((page + 1) * pageSize);
    const visible = resolvedData.slice(visibleStart, visibleEnd).map(item => item.checkbox);

    return visible;
  }

  const toggleSelectAll = () => {
    let selected = [];

    if (selectAll === false) {
      selected = getVisibleData();
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }

    setSelectedFaxes(selected);
  }

  const fetchNewData = (state) => {
    const { page, sorted, pageSize } = state;

    if (pageNumber !== page + 1) {
      setPageNumber(page + 1);
    }

    if (sorted.length > 0) {
      if (sorted[0].id !== sortField) {
        setSortField(sorted[0].id);
      }

      if (sorted[0].desc !== descSort) {
        setDescSort(sorted[0].desc)
      }
    }

    if (pageSize !== faxPerPage) {
      setFaxPerPage(pageSize);
    }
  }

  const buildDataTable = (data, columns, tab) => {
    const content = (
      <ReactTable
        data={data}
        columns={columns}
        styles={styles.column}
        ref={r => reactTableRef = r}
        manual
        pages={props.pages}
        onFetchData={fetchNewData}
        defaultPageSize={faxPerPage}
        loading={props.pendingRequest}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onPageChange={clearSelected}
        onPageSizeChange={clearSelected}
        onSortedChange={clearSelected}
        onFilteredChange={clearSelected}
        multiSort={false}
      />
    );

    return (
        <TabPanel key={tab}>
          {content}
        </TabPanel>
    )
  }

  const sendPutRequest = (object) => {
    const reqData = {
      reqAction: 'fax',
      reqObject: object.uniqueid,
      reqBody: object,
    };
    const storeKey = 'changefolder';

    props.putUserPortalHttp(reqData, storeKey);
  }

  const sendDeleteRequest = (faxObject) => {
    const reqData = {
      reqAction: 'fax',
      reqObject: faxObject.uniqueid,
    };
    let storeKey = 'deleteFax';

    props.deleteUserPortalHttp(reqData, storeKey);

    const putReqData = {
      reqAction: 'fax',
      reqObject: faxObject.uniqueid,
      reqBody: {...faxObject, deleted: 1}
    }
    storeKey = 'editafterdeletefax';

    props.putUserPortalHttp(putReqData, storeKey);
  }

  const requestToMoveFolder = (toFolder) => {
    if (toFolder !== TAB_NAMES[tab]) {
      selectedFaxes.forEach(fax => {
        const faxObject = fax;

        faxObject.folder = toFolder;

        if (toFolder === 'Company') {
          faxObject.name = 'company';
        } else {
          faxObject.name = props.token.extension;
        }

        sendPutRequest(faxObject);
      });
    }

    clearSelected();
  }

  const requestToDelete = () => {
    selectedFaxes.forEach(fax => sendDeleteRequest(fax));
    clearSelected();
    setDeletePressed(false);
  }

  const CheckBox = (props) => {
    return (
      <input
        type='checkbox'
        className='checkbox'
        checked={props.checked}
        onChange={props.onChange}
      />
    )
  }

  const isSelected = (fax) => {
    const selectedArray = selectedFaxes.filter(selectedFax => selectedFax.index === fax.index);
    return selectedArray.length > 0;
  }

  const dateSortingMethod = (date1, date2) => {
    const date1Time = new Date(date1).getTime();
    const date2Time = new Date(date2).getTime();

    if (date1Time < date2Time) {
      return 1;
    } else if(date1Time > date2Time) {
      return -1;
    }
    return 0;
  }

  const tabs = TAB_NAMES.map(name =>
    <Tab key={name}>{name}</Tab>
  );

  const columns = [
    {
      id: "checkbox",
      accessor: "",
      Cell: rowInfo => {
        return (
          <CheckBox
            checked={isSelected(rowInfo.value)}
            onChange={() => toggleSelect(rowInfo.value)}
          />
        );
      },
      Header: title => {
        return (
          <CheckBox
            checked={selectAll}
            onChange={() => toggleSelectAll()}
          />
        );
      },
      sortable: false,
      resizable: false,
      width: 45,
      headerStyle: { ...styles.header } ,
      style: { ...styles.column,
      alignItems: 'center',
      justifyContent: 'center', }
    },
    {
      accessor: 'timestamp',
      Header: 'Date',
      Cell: rowInfo => moment(new Date(rowInfo.value)).format('M/D/YY H:mm A'),
      sortable: true,
      resizable: true,
      style: { ...styles.column },
      headerStyle: { ...styles.header },
      sortMethod: dateSortingMethod,
    },
    { accessor: 'reference', Header: 'Reference', sortable: true, resizable: true, style: { ...styles.column }, headerStyle: { ...styles.header } },
    { accessor: 'callerid', Header: 'Caller ID', sortable: true, resizable: true, style: { ...styles.column }, headerStyle: { ...styles.header } },
    { accessor: 'did', Header: 'Send To', sortable:true, resizable: true, style: { ...styles.column }, headerStyle: { ...styles.header } },
    { accessor: 'pagecount', Header: 'Pages', resizable: false, sortable: true, style: { ...styles.column }, headerStyle: { ...styles.header }},
    { accessor: 'status', Header: 'Status', resizable: false, sortable: true, style: { ...styles.column }, headerStyle: { ...styles.header }},
    {
      id: 'link',
      accessor: '',
      Header: 'Fax',
      resizable: false,
      sortable: false,
      style: { ...styles.column },
      headerStyle: { ...styles.header },
      Cell: rowInfo => {
        const handleShowFax = async () => {
  
          const response = await remoteResourceInterface({
            nameSpace: "userportal",
            route: "fax",
            reqObject: rowInfo.value.uniqueid,
            fileType: "application/pdf",
          });

          const fileURL = response;
          window.open(fileURL, "_blank");

        }

        return (
          <a onClick={handleShowFax} style={{cursor: "pointer"}}> Show </a>
        );
      },
    },
  ];

  const dialogActionButtons = TAB_NAMES.map(name => (
    <Button onClick={() => requestToMoveFolder(name)} key={name}>{name}</Button>
  ));

  return (
    <>
      <div style={styles.container}>
        <div>
          <h1>Fax</h1>
          <h4>Read, email, and delete fax messages.</h4>
          <Tabs selectedIndex={tab} onSelect={tabIndex => { setTab(tabIndex); changeFolder(TAB_NAMES[tabIndex], faxPerPage); }}>
            <TabList>{tabs}</TabList>
            { tabs.map(tab => buildDataTable(props.faxes, columns, `${tab.key}data`)) }
          </Tabs>
        </div>
        <div style={styles.bottomSection}>
          <div style={styles.deleteButton}>
          {
            TAB_NAMES[tab] === 'Deleted' && <Button
              variant='contained'
              onClick={() => setDeletePressed(true)}
              color='primary'
            ><DeleteIcon/> Delete Selected</Button>
          }
          </div>
        </div>
        {deletePressed && <div style={styles.verifySection}>
          Are you sure?
          <div style={styles.bottomSection}>
            <Button
              variant='contained'
              onClick={() => requestToDelete()}
              color='primary'
            ><TickIcon/> Yes, Delete</Button>
            <Button
              onClick={() => setDeletePressed(false)}
              color='primary'
            ><CrossIcon/> Cancel</Button>
          </div>
          This faxes will be deleted forever.
        </div>}
      </div>
      <Footer
        fullWidth={true}
        singleButton={true}
        onClick={() => setShowMoveModal(!showMoveModal)}
        submitButtonText='Move'
      />
      <Modal
        dialogContentText='Select a Folder'
        dialogActionButtons={dialogActionButtons}
        show={showMoveModal}
        cancelModal={() => setShowMoveModal(false)}
      />
    </>
  );
}

const styles = {
  container:{
    width: '90%',
    marginLeft: '50px',
    marginTop: '10px',
    height: '100%',
    background: '#EFEFEF',
    paddingBottom: '100px',
    overflow: 'auto',
  },
  playback: {
    display: 'flex',
    float: 'right'
  },
  buttoncontainer:{
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '20px'
  },
  button:{
    margin: '10px'
  },
  emptyinbox:{
    textAlign:'center',
    margin: '40px'
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    margin: '0',
    padding: '.25em 1em',
    background: '#ffffff'
  },
  header: {
    background:'#eeeeee',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0',
  },
  bottomSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  deleteButton: {
    padding: '10px',
  },
  verifySection: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
  },
}
const mapStateToProps = state => ({
  token: state.token,
  faxes: state.faxInbox.faxes,
  errorMsg: state.faxInbox.errorMsg,
  pendingRequest: state.faxInbox.pendingRequest,
  shouldUpdate: state.faxInbox.shouldUpdate,
  pages: state.faxInbox.pages,
  timezone: state.faxInbox.timezone,
});

const bindActions = (dispatch) => ({
  getUserPortalHttp: (reqData) => dispatch(getUserPortalHttp(reqData)),
  putUserPortalHttp: (reqData, storeKey) => dispatch(putUserPortalHttp(reqData, storeKey)),
  deleteUserPortalHttp: (reqData, storeKey) => dispatch(deleteUserPortalHttp(reqData, storeKey)),
});

FaxInbox.propTypes = {
  token: PropTypes.oneOfType([() => null, PropTypes.object]),
  errorMsg: PropTypes.oneOfType([() => null, PropTypes.string]),
  faxes: PropTypes.array,
  pendingRequest: PropTypes.bool,
  shouldUpdate: PropTypes.bool,
  pages: PropTypes.number,
  timezone: PropTypes.string,
}

export default connect(mapStateToProps, bindActions)(FaxInbox);
