import {
  RESET_FLAG,
  SELECT_VOICEMAIL,
  UPDATE_REDUCER_DATA,
  SET_UPDATE_REQUESTED,
  RESET_GOTNEWDATA,
} from './typeConstants';

export const resetFlag = () => ({
  type: RESET_FLAG
});

export const selectVoicemail = (data) => ({
  type: SELECT_VOICEMAIL,
  payload: data,
});

export const updateReducerData = (data) => ({
  type: UPDATE_REDUCER_DATA,
  payload: data,
});

export const setUpdateRequested = (data) => ({
  type: SET_UPDATE_REQUESTED,
  payload: data,
});

export const resetGotNewData = () => ({
  type: RESET_GOTNEWDATA,
});