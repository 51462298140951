import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FormControlCard from 'presentational/FormControlCard';
import CountDownComponent from 'presentational/CountDownComponent';
import Footer from 'presentational/Footer';

import { postUserPortalHttp } from 'actions/httpRequest';
import { errorNotification } from 'actions/notifications';
import { isValid } from './voicemailDropUtils';

const initialForm = {
  name: '',
  feature_code: '',
  countdown_enabled: '0',
  countdown: '',
  file: null,
}

function Upload(props) {
  const [data, setData] = useState({...initialForm});

  const changeData = (field, info) => {
    if (info === true) {
      info = '1';
    } else if (info === false) {
      info = '0';
    }
    const newData = { ...data };
    newData[field] = info;
    setData(newData);
  }

  const isValidInput = () => {
    const error = isValid(data, props.voicemailDropData, false);

    if (error) {
      errorNotification(error);
      return false;
    }
    return true;
  }

  const saveFile = () => {
    const payload = new FormData();

    payload.append("file", data.file);
    payload.append("name", data.file.name);
    payload.append("vm_drop", 1);
    payload.append("feature_code", data.feature_code);
    payload.append("countdown", data.countdown);
    payload.append("countdown_enabled", data.countdown_enabled);

    const reqData =  {
      reqAction: 'voicemailrecording',
      reqBody: payload,
    }

    const storeKey = 'createvoicemailrecording';
    props.postUserPortalHttp(reqData, storeKey);
  }

  const submitForm = (cancel) => {
    if (cancel) {
      props.onCancel();
    } else {
      if (isValidInput()) {
        if (data.file) {
          saveFile()
        } else {
          errorNotification({
            title: 'Error!',
            message: 'There must be a file present to upload',
          });
        }
      }
    }
  }

  const featureCodeOptions = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const countdownOptions = ['3', '4', '5', '6', '7', '8', '9', '10'];

  return (
    <>
      <FormControlCard
        title='Upload'
        subtitle='The file must be smaller than 15MB.'
        formControl='UploadAudio'
        uploadGreeting={(e) => changeData('file', e.target.files[0])}
      />
      <FormControlCard
        form={data}
        field="feature_code"
        formControl="SelectInput"
        title="Feature Code"
        subtitle='Feature Code for your Voicemail Drop - You will push *99 + your feature code to send voicemail drop.'
        submitForm={changeData}
        options={featureCodeOptions}
      />
      <FormControlCard
        form={data}
        field="countdown_enabled"
        formControl="ToggleInput"
        title="Enable an Introduction Countdown"
        subtitle=""
        submitForm={changeData}
        label="No / Yes"
      />
      {data.countdown_enabled === '1' && 
        <>
          <FormControlCard
            form={data}
            field="countdown"
            formControl="SelectInput"
            title="Countdown"
            subtitle='This is the number of seconds you get to speak before the voicemail drop is sent.'
            submitForm={changeData}
            options={countdownOptions}
          />
          <CountDownComponent
            title='Test Countdown Introduction'
            value={data.countdown}
          />
        </>  
      }
      <Footer
        disabled={false}
        onClick={(cancel) => submitForm(cancel)}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  voicemailDropData: state.voicemailDrop.voicemailDropData,
});

const bindActions = (dispatch) => ({
  postUserPortalHttp: (reqData, storeKey) => dispatch(postUserPortalHttp(reqData, storeKey)),
});

Upload.propTypes = {
  onCancel: PropTypes.func,
  voicemailDropData: PropTypes.oneOfType([() => null, PropTypes.object]),
}

export default connect(mapStateToProps, bindActions)(Upload);
