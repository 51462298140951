import React, { Component } from 'react'
import { DatePicker } from "@material-ui/pickers";
import SubmitButton from 'presentational/formControls/SubmitButton';
import SelectInput from 'presentational/formControls/SelectInput';
import { formatDatePicker } from 'utils/misc';
import { connect } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
    errorNotification,
} from 'actions/notifications';

import { getTenantChanges } from 'actions/tenantChangesReport';

const style = {
    toolBar: {
        marginTop:'1em',
        display: 'flex',
        width:'100%',
        justifyContent:'center',
        flexWrap:'wrap',
        flexDirection:'column'
    },
    submitButton:{
        marginTop:'1em',
        marginBottom:'1em',
        display: 'flex',
        justifyContent:'center',
    },
    option: {
        width: '150px',
    },
    dateOption: {
        width: '200px',
        margin:'15px'
    },
    filterContainer: {
        display:'flex',
        justifyContent:'center',
        margin:'10px'
    },
    datePicker: {
        display:'flex',
        justifyContent: 'center',
        margin:'10px'
    }
};

class TenantChangesFilter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tenantTypes: []
        }
    }

    getDate(dateString){
        const noHyphens = dateString.replace(/-/g, "");

        //check to see if end date is before start date (yuck)
        const year        = noHyphens.substring(0,4);
        const month       = noHyphens.substring(4,6);
        const day         = noHyphens.substring(6,8);
    
        const parsedDate = `${month} ${day} ${year}`
        const unixTime = new Date(parsedDate).getTime();

        return unixTime;
    }

    generateReport(data){

        const { dateRangeBegin, dateRangeEnd } = data;

        const realBeginDate = this.getDate(dateRangeBegin);
        const realEndDate = this.getDate(dateRangeEnd);

        if (realBeginDate > realEndDate){
            return errorNotification({ message: 'Start date may not be after end date' });
        }

        data["tenantTypes"] = [...this.state.tenantTypes];

        const reqData = {
          reqAction: 'gettenantchangesreport',
          reqBody: data,
        };
    
        const storeKey = 'gettenantchangesreport';
        this.props.getTenantChanges(reqData, storeKey);
    }

    handleChange(type){
        let newTypes = [...this.state.tenantTypes];
        
        if (newTypes.includes(type)){
            const index = newTypes.indexOf(type);
            if (index > -1) {           
                newTypes.splice(index, 1);
            }
        } else {
            newTypes.push(type);
        }

        this.setState({ tenantTypes: newTypes });

    }

    renderCheckboxes() {
        const types = ["Live", "Demo", "Partner", "Development", "Onboarding", "Unknown",  "PartnerTenant"];
        const checkBoxes = types.map((type) =>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={this.state.tenantTypes.includes(type)}
                        onChange={()=>this.handleChange(type)}
                        value={type}
                        color="primary"
                    />
                }
                label={type}
            />        
        );

        return checkBoxes;
    }
      

    render() {

        const { dateRangeBegin, dateRangeEnd, changeType, changeTarget, subscription_level, tenant } = {...this.props.filterState};
        const { targets, typeOfChange, subscriptionLevels, tenants } = { ...this.props.filterOptions}

        const disabled = [...this.state.tenantTypes].length > 0 ? false : true;

        const filterState = {...this.props.filterState};

        return (
            <div>
                <div>
                    <div style={style.toolBar}>
                        <div style={style.datePicker}>
                            <DatePicker
                                style={style.dateOption}
                                autoOk
                                value={dateRangeBegin}
                                format="MMM DD, YYYY"
                                onChange={(startDate) => this.props.changeFilter({"dateRangeBegin": formatDatePicker(startDate._d) })}
                                label="Start Date (Midnight)"
                                required
                            />
                            <DatePicker
                                style={style.dateOption}
                                autoOk
                                required
                                value={dateRangeEnd}
                                format="MMM DD, YYYY"
                                onChange={(endDate) => this.props.changeFilter({"dateRangeEnd": formatDatePicker(endDate._d) })}
                                label="End Date (Midnight)"
                            />
                        </div>
                        <div style={style.filterContainer}>
                            {this.renderCheckboxes()}
                        </div>
                        <div style={style.filterContainer}>
                            <SubmitButton
                                disabled={disabled}
                                label={"Generate Report"}
                                onClick={() => this.generateReport(filterState)}
                            />
                        </div>
                        <div style={style.filterContainer}>
                            <SelectInput
                                style={style.option}
                                placeholder="Type of Change"
                                options={typeOfChange}
                                onChange={(val) => this.props.changeFilter({"changeType": val})}
                                value={changeType}
                            />
                            <SelectInput
                                style={style.option}
                                placeholder="Target"
                                options={targets}
                                onChange={(val) => this.props.changeFilter({"changeTarget": val})}
                                value={changeTarget}
                            />
                            <SelectInput
                                style={style.option}
                                placeholder="Subscription Level"
                                onChange={(val) => this.props.changeFilter({"subscription_level": val})}
                                options={subscriptionLevels}
                                value={subscription_level}
                            />
                            <SelectInput
                                style={style.option}
                                placeholder="Tenant"
                                onChange={(val) => this.props.changeFilter({"tenant": val})}
                                options={tenants}
                                value={tenant}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    tenants: app.token.get("tenants"),
})
  
const bindActions = (dispatch) => ({
    getTenantChanges: (reqData, storeKey) => dispatch(getTenantChanges(reqData, storeKey)),
});
  
export default connect(mapStateToProps, bindActions)(TenantChangesFilter);