import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

const styles = () => ({
  button: {
      fontSize: '100%',
  },
});

class PurchaseSuccess extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.purchaseSuccess}
        onClose={this.props.onModalClose}
        fullWidth={true}
      >
        <DialogTitle>Purchase Successful!</DialogTitle>
        <DialogContent>
          <div>
            <div>
                <p> Congrats on your new phone number(s)! </p>
                <p> New numbers will not work for inbound calls until properly routed. Don't forget to set up inbound call behavior for numbers. </p>
                <p> <strong> Please test any new number purchases prior to advertising! </strong> </p>
                <p> If you have any questions or issues with your new telephone number, 
                  please contact the support team at 303-462-5683 or <a href="mailto:support@fluentstream.com">support@fluentstream.com</a>. 
                </p>
                <br/><br/>
                <p> Thank you! </p>
              </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            classes={{ root: classes.button }}
            color='primary'
            onClick={this.props.onModalClose}
          >Close</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  purchaseSuccess: state.phoneNumbers.purchaseSuccess
})

export default connect(mapStateToProps)(withStyles(styles)(PurchaseSuccess));