import React from 'react';

import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import VerificationCode from './verificationCode';

import makeGlobalStyles from 'makeGlobalStyles';
import MasterView from 'presentational/MasterView';
import { tenantHelper, getWhitelabelPartner } from '../../utils/whitelistHelper';

const useStyles = makeStyles({
  helpContent: {
    padding: '1.5%',
  },
  helpPaper: {
    minWidth: '50%',
    position: 'absolute',
  },
  pageTitle: {
    fontWeight: '400',
  },
  helpText: {
    fontSize: '13px',
  },
  helpTextTitle: {
    color: '#0F4E8B',
    fontSize: '15px',
    padding: '0px 16px 0px 16px',
  },
  standardHelpBlurb: {
    padding: '1.5%',
    borderTop: '1px solid #E0E0E0',
    backgroundColor: '#FAFAFA',
  },
});

function UserPortal() {
  const classes = useStyles();
  const globalClasses = makeGlobalStyles();
  const partner = getWhitelabelPartner();
  let renderPhoneNumber = <div></div>

  if (tenantHelper[partner].en.contact.friendly_phone && tenantHelper[partner].en.contact.phone){
    renderPhoneNumber = <li>{`By calling ${tenantHelper[partner].en.contact.friendly_phone} (${tenantHelper[partner].en.contact.phone})`}</li>
  } else if (tenantHelper[partner].en.contact.phone){
    renderPhoneNumber = <li>{`By calling (${tenantHelper[partner].en.contact.phone})`}</li>
  }

  const renderList = () => {
    if (partner === "fluentstream") {
      return (
        <CardContent classes={{ root: classes.standardHelpBlurb }}>
          <CardContent>
            <h2 className={classes.helpTextTitle}>Need Help?</h2>
            <>
              <p className={classes.helpText}>The whole team at {tenantHelper[partner].en.name.short} is ready to help you. 24 hours a day, 365 days a year.
                You can reach us a number of ways.</p>
              <ul>
                {renderPhoneNumber}
                <li>By emailing <a href={`mailto:${tenantHelper[partner].en.contact.email}`}>{tenantHelper[partner].en.contact.email}</a></li>
                <li>By clicking the button below!</li>
              </ul>
            </>
            <p />
            <Button
              size='large'
              classes={{ root: globalClasses.helpButton }}
              href="http://help.fluentcloud.com"
              target="_blank"
            >Get Help From {tenantHelper[partner].en.name.short}</Button>
            <VerificationCode />
          </CardContent>
        </CardContent>
      );
    } else {
      return (
        <CardContent classes={{ root: classes.standardHelpBlurb }}>
          <CardContent>
            <h2 className={classes.helpTextTitle}>Need Help?</h2>
            <>
              <p className={classes.helpText}>The whole team at {tenantHelper[partner].en.name.short} is ready to help you. 24 hours a day, 365 days a year.
                You can reach us a number of ways.</p>
              <ul>
                {renderPhoneNumber}
                <li>By emailing <a href={`mailto:${tenantHelper[partner].en.contact.email}`}>{tenantHelper[partner].en.contact.email}</a></li>
              </ul>
            </>
            <p />
          </CardContent>
        </CardContent>
      );
    }
  }

  if (app.token.get("onboarding_view_only") === 0) {
    return (
      <MasterView contentCenter={true}>
        <Card classes={{ root: classes.helpPaper }} elevation={1}>
          <CardContent classes={{ root: classes.helpContent }}>
            <CardContent>
              <h2 className={classes.pageTitle}>User Portal</h2>
              <p className={classes.helpText}>
                Manage the settings that are most important to the end user of {tenantHelper[partner].en.name.portal}.
              </p>
            </CardContent>
            <CardContent classes={{ root: classes.helpTextTitle }}>
              About the Portal
            </CardContent>
            <CardContent>
              <>
                <p>This portal can be used for extension setting and efax management.</p>
                <p>You can set up call forwarding, manage voicemail settings, check call history, amongst many other features.</p>
                <p>Configure these features for all extensions, groups of extensions, or for each individual extension. 
                Customize as little or as much as you like.</p>
                <p>Also, send and receive efaxes here. The portal allows for easy management of every incoming and outgoing fax.</p>
              </>
            </CardContent>
          </CardContent>
          {renderList()}
        </Card>
      </MasterView>
    );
  } return null;
}

export default UserPortal;
