import { useSelector } from 'react-redux';

const useExtensionCheck = () => {
    const configurations = useSelector(state => (state.http ? state.http.configurations : []));

    const findExt = numberToCheck => {
        return App.Collections.Extensions.models.filter(
            ext => Object.values(ext)[1].extension == numberToCheck,
        )[0];
    };

    const findConferenceRoom = numberToCheck => {
        return configurations && configurations.conferenceRooms
            ? configurations.conferenceRooms.find(room => room.meet_extension == numberToCheck)
            : null;
    };

    const findParkingLot = numberToCheck => {
        const parkingNumbers = [
            '700',
            '701',
            '702',
            '703',
            '704',
            '705',
            '706',
            '707',
            '708',
            '709',
            '710',
        ];
        return parkingNumbers.includes(numberToCheck);
    };

    const findQueueExtension = numberToCheck => {
        return configurations && configurations.queues
            ? configurations.queues.find(queue => queue.extension == numberToCheck)
            : null;
    };

    const findCallGroupExtension = numberToCheck => {
        return configurations && configurations.callgroups
            ? configurations.callgroups.find(group => group.extension == numberToCheck)
            : null;
    };

    const checkN11numbers = numberToCheck => {
        const n11Numbers = [
            '011',
            '111',
            '211',
            '311',
            '411',
            '511',
            '611',
            '711',
            '811',
            '911',
        ];
        return n11Numbers.includes(numberToCheck);
    };

    return {
        checkN11numbers,
        findCallGroupExtension,
        findConferenceRoom,
        findExt,
        findParkingLot,
        findQueueExtension,
    };
};

export default useExtensionCheck;
