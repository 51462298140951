import React, { Component } from 'react';
import { connect } from 'react-redux';

import ChatWindowContainer from './chatWindowContainer';

import ConversationBubble from './conversationBubble';

import { formatDateForRecent, timeSrt } from 'utils/misc';

class ChatWindow extends Component {

    componentDidUpdate(prevProps, prevState) {

        // On update, lets handle scrolling to bottom of screen (chat window functionality)
        if (!prevProps.conversation) {

            return this.scrollToBottom();
        }

        if (this.props.conversation &&
            prevProps.conversation.hash != this.props.conversation.hash) {

            this.scrollToBottom();
        }

        // scroll to bottom on new message receive
        if (this.props.conversation &&
            prevProps.conversation) {

            this.scrollToBottom();
        }

        return false;
    }

    // Scrolls chat window to bottom
    scrollToBottom() {

        this.chatWindow.scrollTop = this.chatWindow.scrollHeight - this.chatWindow.clientHeight;
    }

    render() {

        const { conversation } = this.props;
        const sortedMessages = conversation && conversation.messages ?
            conversation.messages.sort(timeSrt('timestamp')) : null;

        var uniqueMessages = [];
        if(sortedMessages){    
        uniqueMessages = sortedMessages
          .map(msg =>msg.messageId)
          .map((msg, i, final) => final.indexOf(msg) === i && i)
          .filter(msg => sortedMessages[msg]).map(msg => sortedMessages[msg]);
        }

        return (
          <ChatWindowContainer>
            <div
              ref={(c) => { this.chatWindow = c; }}
              style={style.messagesWrapper}
            >
              {!conversation ?
                <div style={style.emptyWrapper}>
                  Please select a conversation
                </div> :
                uniqueMessages.map((message) => (
                  <ConversationBubble
                    key={message.messageId}
                    messageId={message.messageId}
                    message={message.message}
                    date={formatDateForRecent(message.timestamp)}
                    failed={message.failed}
                    avatar={this.props.participantAvatar.avatar || null}
                    incomingMessage={message.direction == 'incoming'}
                    sending={message.sending}
                  />
                 ))
              }
            </div>
          </ChatWindowContainer>
        );
    }
}

const style = {
    emptyWrapper: {
        alignItems: 'center',
        color: '#737373',
        display: 'flex',
        flex: 1,
        fontSize: 18,
        justifyContent: 'center',
        marginBottom: 100,
    },
    messagesWrapper: {
        padding: 20,
        overflow: 'auto',
        marginTop: 56,
    },
};

const mapStateToProps = (state) => ({
    selectedConversation: state.sms && state.sms.selectedConversation ? state.sms.selectedConversation : null,
    conversation: state.sms && state.sms.selectedConversation ? state.sms.selectedConversation.conversation : null,
    participantAvatar: state.sms ? state.sms.selectedConversation : null,
    deletingMessages: state.sms ? state.sms.deletingMessages : null,
    sms: state.sms ? state.sms : null,
});

export default connect(mapStateToProps)(ChatWindow);
