import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import deepClone from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';

import QuickGlanceIcon from '@material-ui/icons/RemoveRedEye';

import StandardSettingsPageWithQuickGlance from 'presentational/StandardSettingsPageWithQuickGlance';
import FormControlCard from 'presentational/FormControlCard';
import Footer from 'presentational/Footer';

import {
  changeEditPhonenumberFormData,
  revertEditPhonenumberFormData,
  deselectSelectedNumber,
} from 'actions/phoneNumbersManager';
import {
  putMasterHttp
} from 'actions/httpRequest';

import { isSuperDuperAdmin } from './phoneNumberManagerUtils';

function EditPhoneNumberForm(props) {
  const [clicked, setClicked] = useState(false);
  const [changed, setChanged] = useState(false);
  const tenantOptions = [{ label: 'No Tenant', value: 'none' }].concat(props.tenants.map(item => ({ 
    label: item.friendly_tenant_name ? item.friendly_tenant_name : item.tenant, 
    value: item.tenant 
  })));
  let carrierIndexes = [];
  let carrierOptions = [];
  props.carriers.forEach(item => {
    carrierIndexes.push(item.index);
    carrierOptions.push({ label: item.friendlyname, value: item.index });
  });
  const booleanOptions = [
    {label: 'Yes', value: '1'},
    {label: 'No', value: '0'}
  ];
  const noneOption = {
    value: 'none',
  };

  useEffect(() => {
    clickedElement();
    return cleanUpClicked;
  }, []);

  const handleClickedElement = () => {
    setClicked(true);
  }

  const cleanUpClicked = () => {
    const elements = document.querySelectorAll(".header");
    elements[0].removeEventListener("click", handleClickedElement);
  }

  const clickedElement = () => {
    const elements = document.querySelectorAll(".header");
    elements[0].addEventListener("click", handleClickedElement);
  }

  const changeFormValue = (field, value) => {
    const newData = deepClone(props.form);

    if (clicked) {
      setClicked(false);
    }

    // value none means '' as value
    if (value === 'none') {
      value= '';
    }

    newData[field] = value;
    setChanged(isEqual(props.selectedNumber, newData) === false);
    props.changeFormData(newData);
  }

  const onSubmit = (cancel) => {
    if (cancel) {
      props.revertFormData();
      props.deselectNumber();
      app.navigate('dids', {trigger: true});
    } else {
      const payload = deepClone(props.form);

      const reqData = {
        reqAction: 'globaldids',
        reqObject: payload.phonenumber,
        reqBody: payload,
      };
      const storeKey = 'editphonenumbersmanager';

      props.editPhoneNumber(reqData, storeKey);
      setClicked(false);
      setChanged(false);
    }
  }

  const noTenantOrUnassignedTenant = () => {
    const { tenant } = props.form;
    return tenant === '' || tenant === '(unassigned)';
  }

  const checkAssignment = () => {
    const {
      assigned,
      assigneduser,
      assignedroute,
      assignedmanaged,
      assignedconference,
      assignedcallforwarding,
      call_forwarding_number,
    } = props.form;
    const callForwardingValuesIfRouteAssigned = ['1', '2', '3', '4'];

    if (assigned === '0') {
      return 'Type: Not Assigned Value:n/a';
    } else {
      if(assigneduser){
        return `Type: Extension Value: ${assigneduser}`;
      }
      else if(assignedroute){
        return `Type: Route Value: ${assignedroute}`;
      }
      else if(assignedmanaged){
        return `Type: Managed Route Value: ${assignedmanaged}`;
      }
      else if(assignedconference){
        return `Type: Conference Room Value: ${assignedconference}`;
      }
      else if(assignedconference === '' && assignedcallforwarding === '0'){
        return 'Type: Conference Room Value: No Room';
      }
      else if(callForwardingValuesIfRouteAssigned.includes(assignedcallforwarding)){
        return `Type: Forwarding: ${call_forwarding_number}`;
      }
    }
  }

  const prepareQuickGlance = () => {
    const {
      date_purchased,
      date_updated,
      who_updated,
      emergencyroute,
      location,
      callrecording,
    } = props.form;
    return (
      <FormControlCard
        formControl='CustomComponents'
        field='quickGlance'
        icon={<QuickGlanceIcon />}
        title="Quick Glance"
      >
        <b>Number Assignment</b><br/>
        {checkAssignment()} <br/>
        <br/>
        <b>Date Purchased</b><br/>
        {date_purchased}<br/>
        <br/>
        <b>Date Updated</b><br/>
        {date_updated}<br/>
        <br/>
        <b>Who Updated</b><br/>
        {who_updated}<br/>
        <br/>
        <b>Emergency Route</b><br/>
        {emergencyroute}<br/>
        <br/>
        <b>Location</b><br/>
        {location}<br/>
        <br/>
        <b>Call Recording</b><br/>
        {callrecording}<br/>
      </FormControlCard>
    )
  }

  const { form, token } = props;
  const { assigned, voicecarrier, smscarrier } = form;

  return (
    <StandardSettingsPageWithQuickGlance
      quickGlance={prepareQuickGlance()}
    >
      <FormControlCard
        form={noTenantOrUnassignedTenant() ? noneOption : form}
        field={noTenantOrUnassignedTenant() ? 'value' : 'tenant'}
        disabled={assigned === '1'}
        formControl="SearchableSelectInput"
        closeModal={() => {}}
        searchableFormOption = 'TenantSearch'
        buttonClick={() => {}}
        title='Tenant'
        subtitle='The tenant for this number.'
        submitForm={(field, value) => changeFormValue('tenant', value)}
        options={tenantOptions}
      />
      <FormControlCard
        form={form}
        field="billable"
        formControl="SelectInput"
        title='Toll free number'
        subtitle='Is this number a toll free number?'
        submitForm={changeFormValue}
        options={booleanOptions}
      />
      <FormControlCard
        form={form}
        field="enabled"
        formControl="SelectInput"
        title='Enabled'
        subtitle='Is this number enabled or disabled on the system?'
        submitForm={changeFormValue}
        options={booleanOptions}
      />
      <FormControlCard
        form={form}
        field="smsenabled"
        formControl="SelectInput"
        title='SMS Enabled'
        subtitle='Can this number SMS?'
        submitForm={changeFormValue}
        options={booleanOptions}
      />
      <FormControlCard
        form={carrierIndexes.includes(voicecarrier) ? form : noneOption}
        disabled={isSuperDuperAdmin(token) === false}
        field={carrierIndexes.includes(voicecarrier) ? 'voicecarrier' : 'value'}
        formControl="SelectInput"
        title='Voice Carrier'
        subtitle='The carrier that handles the voice traffic'
        submitForm={(field, value) => changeFormValue('voicecarrier', value)}
        options={[{ label: 'No Voice Carrier', value: 'none' }].concat(carrierOptions)}
      />
      <FormControlCard
        form={carrierIndexes.includes(smscarrier) ? form : noneOption}
        disabled={isSuperDuperAdmin(token) === false}
        field={carrierIndexes.includes(smscarrier) ? 'smscarrier' : 'value'}
        formControl="SelectInput"
        title='SMS Carrier'
        subtitle='The carrier that handles the SMS traffic'
        submitForm={(field, value) => changeFormValue('smscarrier', value)}
        options={[{ label: 'No SMS Carrier', value: 'none' }].concat(carrierOptions)}
      />
      <FormControlCard
        form={form}
        field="porting_in_progress"
        formControl="SelectInput"
        title='Is Porting?'
        subtitle='Is this number currently being ported?'
        submitForm={changeFormValue}
        options={booleanOptions}
      />
      <FormControlCard
        form={form}
        field="is_domestic"
        formControl="SelectInput"
        title='Is Domestic'
        subtitle='Is this number domestic?'
        submitForm={changeFormValue}
        options={booleanOptions}
      />
      <Footer
        disabled={isEqual(props.selectedNumber, form)}
        onClick={onSubmit}
      />
    </StandardSettingsPageWithQuickGlance>
  )
}

const bindActions = (dispatch) => ({
  changeFormData: (data) => dispatch(changeEditPhonenumberFormData(data)),
  revertFormData: () => dispatch(revertEditPhonenumberFormData()),
  editPhoneNumber: (reqData, storeKey) => dispatch(putMasterHttp(reqData, storeKey)),
  deselectNumber: () => dispatch(deselectSelectedNumber()),
});

const mapStateToProps = state => ({
  selectedNumber: state.phoneNumbersManager.selectedNumber,
  form: state.phoneNumbersManager.editPhoneNumberForm,
  tenants: state.phoneNumbersManager.tenants,
  carriers: state.phoneNumbersManager.carriers,
  token: state.token,
});

EditPhoneNumberForm.propTypes = {
  selectedNumber: PropTypes.oneOfType([() => null, PropTypes.string]),
  form: PropTypes.oneOfType([() => null, PropTypes.string]),
  tenants: PropTypes.array.isRequired,
  carriers: PropTypes.array.isRequired,
  token: PropTypes.object,
}

export default connect(mapStateToProps, bindActions)(EditPhoneNumberForm);