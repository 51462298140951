import React from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import makeGlobalStyles from 'makeGlobalStyles';

function ZohoIntegrationButton(props) {
  const classes = makeGlobalStyles();
  return (
    <Button
      classes={{ root: classes.helpButton }}
      onClick={() => {
        props.toggleZoho();
      }}
    >
      {props.text}
    </Button>
  );
}

ZohoIntegrationButton.propTypes = {
  useStyles: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

export default ZohoIntegrationButton;
