import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';

//Presentational Components
import Footer from 'presentational/Footer';
import SelectInput from 'presentational/formControls/SelectInput';
import TextInput from 'presentational/formControls/TextInput';

//Material Components
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import DialogTitle from '@material-ui/core/DialogTitle';
import EmailIcon from '@material-ui/icons/Email';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Launch';
import SaveIcon from '@material-ui/icons/Save';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SmsIcon from '@material-ui/icons/Sms';

class WorkflowActionCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorText: '',
            form: {},
            mediaQuery: false,
            showingDeleteConfirmModal: false,
            submitButtonDisabled: true,
            rollbackForm: {},
            newChanges: false,
        };

    }

    componentDidMount = () => {
      document.addEventListener('mousedown', this.handleClick, false)
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
        if(this.props.actionData){
          this.setState({form: this.props.actionData}, ()=> this.createRollbackForm())
        }
    }

    componentWillUnmount = () => {
      document.removeEventListener('mousedown', this.handleClick, false)
    }

    // If the value has changed, let's update it here in state
    componentDidUpdate(prevProps) {
        if(this.props.form && Object.keys(this.props.form).length && !this.props.actionData && this.props.form !== prevProps.form){
            this.setState({form: this.props.form})
        }

        if(!this.props.addingAction && prevProps.actionData != this.props.actionData){
            this.setState({form: this.props.actionData})
        }
    }

    createRollbackForm = () => {
      const rollbackForm = {...this.state.form}
      this.setState({ rollbackForm })
    }

    disableButton( value = true){
      this.setState({ submitButtonDisabled: value})
    }

    getActionConfiguration = () => {
      //this will setstate to render a specific form control card
      var actionOptions = []
        if(this.props.configData && this.props.configData.timeconditions && this.props.configData.timeconditions.data){
          actionOptions = this.props.configData.timeconditions.data.map(obj =>{ 
            return { value: obj.name, label: obj.friendlyname || obj.name};
          })
        } 
        actionOptions = actionOptions.sort((a, b) =>(a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1)
        return actionOptions;
    }

    getWebhookJson = (event) => {
      
      var agentAutoPausedJsonValues = {'[[AgentID]]': 'Agent Id','[[AgentName]]': 'Agent Name','[[Date]]': 'Date','[[QueueName]]': 'Queue Name'};
      var callGroupExitJsonValues = {'[[CallerIDName]]':'Caller Name','[[CallerIDNum]]':'Caller Number','[[Date]]':'Date',
          '[[GroupName]]':'Group Name','[[Location]]':'Location'};
      var callTo911JsonValues = {'[[CallerIDName]]':'Caller Name','[[CallerIDNum]]':'Caller Number','[[Date]]':'Date',
          '[[FromExt]]':'Dialed From','[[Location]]':'Location'};
      var incomingCallJsonValues = {'[[CallerIDName]]':'Caller Name','[[CallerIDNum]]':'Caller Number','[[Date]]':'Date',
          '[[Location]]':'Location','[[ToNum]]':'Ext. Dialed',};
      var incomingSmsJsonValues = {'[[Date]]':'Date','[[FromNum]]':'From Number','[[SMSMessage]]':'SMS Message',
          '[[ToNum]]':'To Number',};
      var parkingAnsweredJsonValues = {'[[CallerIDName]]':'Caller Name','[[CallerIDNum]]':'Caller Number','[[Date]]':'Date',
          '[[Extension]]':'Ext. Answered','[[Location]]':'Location','[[LotNumber]]':'Lot Number'};
      var queueEnteredJsonValues = {'[[CallerIDName]]':'Caller Name','[[CallerIDNum]]':'Caller Number','[[Date]]':'Date',
          '[[Location]]':'Location','[[QueueName]]':'Queue Name'};
      var queueExitJsonValues = {'[[CallerIDName]]':'Caller Name','[[CallerIDNum]]':'Caller Number','[[Date]]':'Date',
          '[[Location]]':'Location','[[QueueName]]':'Queue Name'};
      var siteOfflineJsonValues = {'[[CallerIDName]]':'Caller Name','[[CallerIDNum]]':'Caller Number',
          '[[Date]]':'Date','[[EmergencyRouteName]]':'Emergency Route','[[Location]]':'Location'};
      var slaJsonValues = {'[[CallerIDNum]]':'Caller Number','[[Date]]':'Date','[[QueueName]]':'Queue Name',
          '[[ToNum]]':'Ext. Dialed','[[SLAValue]]':'SLA Value','[[SLAPerformanceValue]]':'Performance Value'};
      var postCallSurveyJsonValues = { '[[Agent]]':'Agent','[[Date]]':'Date','[[DialedNumber]]':'Dialed Number',
          '[[SurveyData]]':'Survey Data','[[SurveyID]]':'Survey ID','[[SurveyName]]':'Survey Name','[[SurveyQuestions]]':'Survey Results'};
      var voicemailInJsonValues = {'[[CallerIDName]]':'Caller Name','[[CallerIDNum]]':'Caller Number','[[Date]]':'Date',
          '[[MessageDurl]]':'Message Duration','[[VoicemailBox]]':'Mailbox Number'};

          switch(event) {

            case 'agent_autopaused':

                return this.appendWebhookJsonOptions(agentAutoPausedJsonValues);
                break;

            case 'voicemail_sentiment_analysis':
            case 'voicemail':

                return this.appendWebhookJsonOptions(voicemailInJsonValues);
                break;
            case '911':

                return this.appendWebhookJsonOptions(callTo911JsonValues);
                break;
            case 'incoming_sms':

                return this.appendWebhookJsonOptions(incomingSmsJsonValues);
                break;
            case 'incoming_call':

                return this.appendWebhookJsonOptions(incomingCallJsonValues);
                break;
            case 'queue_entered':

                return this.appendWebhookJsonOptions(queueEnteredJsonValues);
                break;
            case 'queue_exit':

                return this.appendWebhookJsonOptions(queueExitJsonValues);
                break;
            case 'service_load_agreement':

                return this.appendWebhookJsonOptions(slaJsonValues);
                break;
            case 'offline_site_location':

                return this.appendWebhookJsonOptions(siteOfflineJsonValues);
                break;
            case 'parking_lot':

                return this.appendWebhookJsonOptions(parkingAnsweredJsonValues);
                break;
            case "post_call_survey_question":
            case 'post_call_survey':

                return this.appendWebhookJsonOptions(postCallSurveyJsonValues);
                break;
            case 'call_group_exit':
              
              return this.appendWebhookJsonOptions(callGroupExitJsonValues);
                break;

            default:

                return this.appendWebhookJsonOptions({});
                break;
        }
    }

    appendWebhookJsonOptions = (eventArray) => {
      var jsonEventsArray = []
        for(var key in eventArray){
          jsonEventsArray.push({
            title: key,
            value: eventArray[key]
          })
        }
        return jsonEventsArray;
    }

    getActionValue = () => {
      let webhookOptions = this.getWebhookJson(this.props.eventType)
      let webhooks = [];
      webhookOptions.forEach((webhook) =>{
        webhooks.push(
            <Button
              style={{textTransform: 'none'}}
              key = {webhook.title}
              color = 'primary'
              disabled = {true}
              //onClick={(val) => this.onChange('hook_fields', webhook.title)}
            >{webhook.title}</Button>
        )
      })
      switch (this.state.form.type) {
          case 'sms':
            return (
                <Card>
                <CardHeader
                  avatar={<ScheduleIcon />}
                  subheader="Please select a time condition"
                  title="Time Condition"
                />
                <SelectInput
                disabled={this.props.disabled}
                field="time_condition"
                onChange={(val) => {
                    this.onChange('time_condition', val)
                }}
                value={this.state.form.time_condition}
                errorText={this.props.errorText}
                placeholder="Select time condition"
                options={this.getActionConfiguration()}
                />
                <CardHeader
                    avatar={<SmsIcon />}
                    subheader="Enter a valid phone number"
                    title="SMS Number"
                />
              <CardContent>
                <TextInput
                  field="type_value"
                  onChange={(val) => {
                    this.onChange('type_value', val)
                  }}
                  onBlur={(val) => {
                    this.onChange('type_value', val)

                  }}
                  value={this.state.form.type_value}
                  errorText={this.state.errorText}
                  placeholder="Enter a valid phone number"
                />
              </CardContent>
              </Card>
            );
          case 'email':
            return (
                <Card>
                  <CardHeader
                  avatar={<ScheduleIcon />}
                  subheader="Please select a time condition"
                  title="Time Condition"
                  />
                  <SelectInput
                  disabled={this.props.disabled}
                  field="time_condition"
                  onChange={(val) => {
                      this.onChange('time_condition', val)
                  }}
                  value={this.state.form.time_condition}
                  errorText={this.props.errorText}
                  placeholder="Select time condition"
                  options={this.getActionConfiguration()}
              />
                <CardHeader
                    avatar={<EmailIcon />}
                    subheader="Enter a valid email address"
                    title="Email"
                />
                <CardContent>
                <TextInput
                    field="type_value"
                    onChange={(val) => {
                    this.onChange('type_value', val)
                    }}
                    onBlur={(val) => {
                    this.onChange('type_value', val)
                    }}
                    value={this.state.form.type_value}
                    errorText={this.state.errorText}
                    placeholder="Enter a valid email address"
                />

                </CardContent>
                </Card>
            );
          case 'onesig':
            return (
                <Card>
                <CardHeader
                    avatar={<ScheduleIcon />}
                    subheader="Please select a time condition"
                    title="Time Condition"
                />
                <CardContent  >
                <SelectInput
                    disabled={this.props.disabled}
                    field={this.state.form.type}
                    onChange={(val) => {
                        this.onChange('time_condition', val)
                    }}
                    value={this.state.form.time_condition}
                    errorText={this.props.errorText}
                    placeholder="Time Condition"
                    options={this.getActionConfiguration(this.state.form.type)}
                />
                {this.props.linkAddress && this.state.controlValue
                    ? <a style={{ margin:'20px' }} href={`${this.props.linkAddress}/${this.state.controlValue}`} title="Go to route">
                        {this.state.controlValue}
                        <LinkIcon style={{height:'17px'}}/>
                    </a>
                    : null
                }
                </CardContent>
                </Card>
            );
          case 'webhook':
            const hookVerbs =
                [{value: 'GET', label:'GET'},
                {value: 'POST', label: 'POST'},
                {value: 'PUT', label:'PUT'}];
            return (
              <Card>
                  <CardHeader
                    avatar={<ScheduleIcon />}
                    subheader="Please select a time condition"
                    title="Time Condition"
                />
              <SelectInput
                  disabled={this.props.disabled}
                  field="time_condition"
                  onChange={(val) => {
                      this.onChange('time_condition', val)
                  }}
                  value={this.state.form.time_condition}
                  errorText={this.props.errorText}
                  placeholder="Select time condition"
                  options={this.getActionConfiguration()}
              />
              <CardHeader
                  avatar={<DeviceHubIcon />}
                  subheader="Please configure webhook"
                  title="Webhook"
              />
              <CardContent  >
              <SelectInput
                  disabled={this.props.disabled}
                  field="hook_verb"
                  onChange={(val) => {
                      this.onChange('hook_verb', val)
                  }}
                  onBlur={(val) => {
                    this.onChange('hook_verb', val)
                    }}
                  value={this.state.form.hook_verb}
                  errorText={this.props.errorText}
                  placeholder = "Select a request verb"
                  options={hookVerbs}
              />
              <TextInput
                  field="hook_value"
                  onChange={(val) => {
                  this.onChange('hook_value', val)
                  }}
                  onBlur={(val) => {
                  this.onChange('hook_value', val)
                  }}
                  value={this.state.form.hook_value}
                  errorText={this.state.errorText}
                  multiline = {true}
                  placeholder="Webhook URL"
              />
                <CardHeader
                    subheader=" The following values can be typed to populate your JSON object in the code editor below"
                />
                {webhooks}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: '5px',
                }}
                >
                <AceEditor
                  mode="json"
                  theme="twilight"
                  style={{
                    paddingLeft: '200px',
                    paddingRight: '200px'
                  }}
                  height='300px'
                  width = '300px'
                  onChange={(val) => {
                    this.onChange('hook_fields', val)
                  }}
                  name="reactaceWebhookEditor"
                  showGutter={false}
                  highlightActiveLine={true}
                  editorProps={{ $blockScrolling: true }}
                  value={this.state.form.hook_fields}
                  setOptions={{
                    showLineNumbers: true,
                    tabSize: 4,
                    autoScrollEditorIntoView: true,
                  }}
                />
                </div>
              </CardContent>
              </Card>
            );
        }
    }

    handleDeleteConfirmClose = (shouldDelete, form) => {
      shouldDelete && this.props.handleRemoveAction(form)
      this.setState({showingDeleteConfirmModal: false});
    }

    onCancel = () => {
      this.props.selectedNotificationWorkflow('')
    }

    handleNewFormSubmit = (cancel) => {
      if(cancel){
        this.onCancel();
      }
      this.props.submitForm(this.state.form, null, this.props.index);
      this.disableButton(true)
    }

    handleFormEdit = () => {
      this.props.submitForm(this.state.form, null, this.props.index);
      this.disableButton(true)
    }

    handleClick = (e) => {
      if (this.node) {
        if (!this.node.contains(e.target)){
          this.props.clickedNode();
        }
      }
    }

    checkNewValidAction = () => {
      if(this.props.newEvent || this.props.addingAction){
        if(this.props.validEvent){
          if(!this.state.form.type){
              return;
          }
          if(this.state.form.type == 'sms' || this.state.form.type == 'email'){
              if(this.state.form.type_value && this.state.form.time_condition){
                  this.disableButton(false)
              }
          }else if(this.state.form.type == "onesig"){
              if(this.state.form.time_condition){
                  this.disableButton(false)
              }
          }else if(this.state.form.type == "webhook"){
              if(this.state.form.hook_fields && this.state.form.hook_value && this.state.form.hook_verb && this.state.form.time_condition){
                  this.disableButton(false)
              }
          }
        }
      }
    }

    onChange = (field,data) => {
      let { form } = this.state
      const newForm = {...form}
      //clear the form before we add new values if the type has changed
      if(field=="type"){
        newForm.type_value =''
        newForm.hook_verb =''
        newForm.hook_value =''
        newForm.hook_fields =''
        newForm.time_condition =''
      }
      if(this.state.submitButtonDisabled && !this.props.newEvent){
        this.disableButton(false)
      }

      this.setState({
        form: {...newForm, [field]: data},
          newChanges: true,
        },()=> {this.checkNewValidAction(); this.props.submitForm(this.state.form, this.state.newChanges, this.props.index)} )
    }

    render = () => {
        const minWidth = this.state.mediaQuery.matches;
        const style = {
            card: {
                width: minWidth ? '50%' : '100%',
                minWidth: '400px',
            },
        };

        return (
          <div ref={node => this.node =node} style={style.card}>
            <Card >
              <CardHeader
                action={
                  <>
                  {this.props.newEvent || this.state.submitButtonDisabled ? '' :
                  <IconButton
                    onClick={() => this.handleFormEdit()}
                    disabled={this.state.submitButtonDisabled}
                  >
                    <SaveIcon />
                  </IconButton>
                  }
                  {this.props.disableDeleteAction || this.props.newEvent ? '' :
                    <IconButton
                        disabled={false}
                        onClick={() => {this.setState({showingDeleteConfirmModal: true});}}
                      >
                      <DeleteIcon color="error" />
                    </IconButton>
                  }
                  </>
                }
                avatar={this.props.icon}
                subheader={this.props.subtitle}
                title={this.props.title}
              />
                <CardContent  >
                    <SelectInput
                      field="type"
                      onChange={(val) => {
                        this.onChange('type', val);
                      }}
                      value={this.state.form.type}
                      errorText={this.errorText}
                      placeholder="Action"
                      options={this.props.options}
                    />
                  </CardContent>
            </Card>
            {this.getActionValue()}
            {this.props.newEvent?
              <Footer
                onClick={(cancel) => this.handleNewFormSubmit(cancel)}
                disabled = {this.state.submitButtonDisabled}
              />
              :
              ''
            }
            <Dialog
                    open={this.state.showingDeleteConfirmModal}
                    onClose={() => this.handleDeleteConfirmClose(false)}
                    fullWidth={true}
                >
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogActions>
                        <Button
                          color='primary'
                          onClick={() => this.handleDeleteConfirmClose(false)}
                        >Cancel</Button>
                        <Button
                          color='primary'
                          onClick={() => this.handleDeleteConfirmClose(true, this.state.form)}
                        >Delete</Button>
                    </DialogActions>
                    </Dialog>
          </div>
        );
    }
}


WorkflowActionCard.defaultProps = {
    form: {},
    label: '',
    subheader: '',
    title: '',
}

WorkflowActionCard.propTypes = {
    form: PropTypes.object,
    title: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]) ,
    subtitle: PropTypes.any,
    label: PropTypes.string,
};

export default WorkflowActionCard;
