import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Button from '@material-ui/core/Button';
import makeGlobalStyles from 'makeGlobalStyles';

export default function HelpContent(props) {
  const classes = props.useStyles();
  const globalClasses = makeGlobalStyles();
  return (
    <div className={classes.wrapper}>
      <Card classes={{ root: classes.helpPaper }} elevation={1}>
        <CardContent classes={{ root: classes.helpContent }}>
          <CardContent>
            <h2 className={classes.pageTitle}>Manage Dynamic Agents</h2>
            { tplHelper.get('label') =='yiptel' ?
              <p className={classes.helpText}>
                This section of the portal allows you to manage Dynamic Agents that can be placed in your queues.
              </p>
              :
              <p className={classes.helpText}>
                This section of My FluentCloud allows you to manage Dynamic Agents that can be placed in your queues.
              </p>
            }
          </CardContent>
          <CardContent classes={{ root: classes.helpTextTitle }}>
            About Dynamic Agents
          </CardContent>
          <CardContent>
            <p className={classes.helpText}>Dynamic Agents can be a useful way to manage a number of contact center-style workflows.</p>
            <p className={classes.helpText}>They offer several advantages over static agents in a number of contact center workflows.</p>
            <p className={classes.helpText}>If the following situations apply, you may be a good candidate for using Dynamic Agents:</p>
            <ul>
              <li>Agents predominantly answer inbound calls.</li>
              <li>Agents move between workstations, often using different phones or computers.</li>
              <li>Agent productivity - tracking login, logout, and time and reason for pauses is important.</li>
              <li>Reporting and performance analytics for the Agent and Agent skill are necessary.</li>
            </ul>
          </CardContent>
        </CardContent>
        <CardContent classes={{ root: classes.standardHelpBlurb }}>
          <CardContent>
            <h2 className={classes.helpTextTitle}>Need Help?</h2>
            { tplHelper.get('label') =='yiptel' ?
              <div>
                <p className={classes.helpText}>The whole team at YipTel is ready to help you. 24 hours a day, 365 (or 366!) days a year. You can reach us a number of ways.</p>
                <ul>
                  <li>By calling 800-752-6110</li>
                  <li>By emailing support@yiptel.com</li>
                  <li>By clicking the button below!</li>
                </ul>
              </div>
              :
              <div>
                <p className={classes.helpText}>The whole team at FluentStream is ready to help you. 24 hours a day, 365 (or 366!) days a year. You can reach us a number of ways.</p>
                <ul>
                  <li>By calling 303-GO-CLOUD (303-462-5683)</li>
                  <li>By emailing support@fluentstream.com</li>
                  <li>By clicking the button below!</li>
                </ul>
              </div>
            }
            {/*<FlatButton
              backgroundColor="#0F4E8B"
              label="Get help from FluentStream"
              labelStyle={{ color: "#FFF" }}
              href="#supportCallModal"
            />*/}
            <p />
            <Button
              size='large'
              classes={{ root: globalClasses.helpButton }}
              href="http://help.fluentcloud.com/support/solutions/articles/4000094602-static-and-dynamic-agents-in-queues"
              target="_blank"
            >How to manage agents</Button>
          </CardContent>
        </CardContent>
      </Card>
    </div>
  );
}