import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import LinearProgress from '@material-ui/core/LinearProgress';
import Loading from 'presentational/Loading';

import { getUserPortalHttp } from 'actions/httpRequest';
import { errorNotification } from 'actions/notifications';
import { resetFlag } from 'actions/createFax';

import StepOne from './stepOne';
import StepTwo from './stepTwo';
import StepThree from './stepThree';

function FaxCreate(props) {
  const [step, setStep] = useState(1);

  useEffect(() => {
    getFaxOptions();
  }, []);

  useEffect(() => {
    if (props.errorMsg) {
      errorNotification({
        title: 'Error!',
        message: props.errorMsg,
      })
      props.resetFlag();
    }
  }, [props.errorMsg]);

  const getFaxOptions = () => {
    const reqData = {
      reqAction: 'faxoptions',
    };
    props.getUserPortalHttp(reqData);
  }

  const renderContent = () => {
    if (props.requestPending) {
      return (
        <div style={styles.loading}>
          <Loading />
        </div>
      );
    }

    switch(step) {
      case 1:
        return <StepOne changeStep={setStep}/>;
      case 2:
        return <StepTwo changeStep={setStep}/>;
      case 3:
        return <StepThree changeStep={setStep}/>;
    }
  }

  return (
    <div className='container'>
      <h1>Create a Fax</h1>
      <div style={styles.rowWrap}>
        <div style={styles.columnWrap}>
          Step 1: Fax Details
        </div>
        <div style={styles.columnWrap}>
          Step 2: Upload Files
        </div>
        <div style={styles.columnWrap}>
          Step 3: Complete
        </div>
      </div>
      <LinearProgress variant='determinate' value={33.33 * step}/>
      {renderContent()}
    </div>
  );
}

const styles = {
  rowWrap: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
  },
  columnWrap: {
    width: '33%',
    textAlign: 'center',
  },
  loading: {
    marginTop: '30px',
  },
}

const bindActions = (dispatch) => ({
  getUserPortalHttp: (reqData) => dispatch(getUserPortalHttp(reqData)),
  resetFlag: () => dispatch(resetFlag()),
});

const mapStateToProps = (state) => ({
  errorMsg: state.createFax.errorMsg,
  requestPending: state.createFax.requestPending,
});

FaxCreate.propTypes = {
  errorMsg: PropTypes.string,
  requestPending: PropTypes.bool,
}

export default connect(mapStateToProps, bindActions)(FaxCreate);
