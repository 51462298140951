import get from 'lodash.get';
import isEqual from 'lodash.isequal';

import {
  VOICEMAILRECORDING_HTTP_SUCCESS,
  EDITVOICEMAILRECORDING_HTTP_SUCCESS,
  VOICEMAILRECORDING_HTTP_FAILURE,
  EDITVOICEMAILRECORDING_HTTP_FAILURE,
  RESET_FLAG,
  SELECT_VOICEMAIL,
  UPDATE_REDUCER_DATA,
  CREATEVOICEMAILRECORDING_HTTP_SUCCESS,
  CREATEVOICEMAILRECORDING_HTTP_FAILURE,
  DELETEVOICEMAILRECORDING_HTTP_SUCCESS,
  DELETEVOICEMAILRECORDING_HTTP_FAILURE,
  NAVIGATE,
  SET_UPDATE_REQUESTED,
  RESET_GOTNEWDATA,
} from '../actions/typeConstants';

import { parseError } from 'utils/misc';

const initialState = {
  voicemailDropData: { recordings: [] },
  successMsg: null,
  errorMsg: null,
  errorCode: null,
  selectedVoicemail: null,
  shouldUpdateData: false,
  shouldSelectVoicemail: null,
  fetchedVoicemail: false,
  edited: false,
  updateRequested: null,
  gotNewData: false,
}

export default function(state = initialState, action) {
  // console.log(action.type, action.payload);
  switch(action.type) {
    case VOICEMAILRECORDING_HTTP_SUCCESS:
      let gotNewData = true;
      if (isEqual(state.voicemailDropData, action.data)) {
        gotNewData = false;
      }
      return { ...state, voicemailDropData: action.data, errorMsg: null, errorCode: null, fetchedVoicemail: true, gotNewData }
    case VOICEMAILRECORDING_HTTP_FAILURE:
      return { ...state, errorMsg: parseError(action.error.response.text), successMsg: null, errorCode: action.error.response.status }
    case EDITVOICEMAILRECORDING_HTTP_SUCCESS:
      return { ...state, successMsg: 'Saved!', errorMsg: null, edited: true }
    case EDITVOICEMAILRECORDING_HTTP_FAILURE:
      return { ...state, errorMsg: parseError(action.error.response.text), successMsg: null, errorCode: action.error.response.status }
    case CREATEVOICEMAILRECORDING_HTTP_SUCCESS:
      return { ...state, shouldUpdateData: true, shouldSelectVoicemail: `${action.data.file}.wav`, successMsg: `${action.data.file}.wav created`, errorMsg: null, gotNewData: false }
    case CREATEVOICEMAILRECORDING_HTTP_FAILURE:
      return { ...state, errorMsg: parseError(action.error.response.text), successMsg: null, errorCode: action.error.response.status }
    case DELETEVOICEMAILRECORDING_HTTP_SUCCESS:
      return { ...state, shouldUpdateData: true, shouldSelectVoicemail: null, selectedVoicemail: null, successMsg: `Deleted!`, errorMsg: null, edited: false }
    case DELETEVOICEMAILRECORDING_HTTP_FAILURE:
      return { ...state, errorMsg: parseError(action.error.response.text), successMsg: null, errorCode: action.error.response.status }
    case RESET_FLAG:
      return { ...state, successMsg: null, errorMsg: null, errorCode: null, shouldUpdateData: false, edited: false, updateRequested: null }
    case SELECT_VOICEMAIL:
      return { ...state, selectedVoicemail: action.payload };
    case UPDATE_REDUCER_DATA:
      const { recordings } = state.voicemailDropData;
      const index = recordings.findIndex(record => record.name === action.payload.name);
      const nonModalUpdate = get(state, 'selectedVoicemail.name', null) === action.payload.name;
      recordings[index] = action.payload;
      return { ...state, selectedVoicemail: nonModalUpdate ? action.payload : state.selectedVoicemail, voicemailDropData: { recordings }}
    case SET_UPDATE_REQUESTED:
      return { ...state, updateRequested: action.payload }
    case RESET_GOTNEWDATA:
      return { ...state, shouldSelectVoicemail: null, gotNewData: false }
    case NAVIGATE:
        if (action.route !== 'voicemailrecording') {
          return { ...state, fetchedVoicemail: false }
        } else {
          return { ...state, selectedVoicemail: null }
        }
    default:
      return { ...state }
  }
}