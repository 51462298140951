import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import { getNextPage } from 'actions/httpRequest';
import { newInternalDidSelected,updatePageNumber } from 'actions/sms';

import SmsToolbar from './smsToolbar';
import Conversations from './conversations';

class SmsConversationsList extends Component {

    constructor() {
        super();
        this.state = {
            lastGetNextPage: Date.now(),
        }
    }

    // gets all sms enabled dids passed in via token
    smsDids() {

        const smsEnabledDids = [];

        // If we have dids from configurations
        if (this.props.dids && this.props.dids.phonenumbers && this.props.dids.phonenumbers.length > 0) {
            // Lets map through dids to get sms enabled ones
            this.props.dids.phonenumbers.map((did) => (
                did.smsenabled == '1' ? smsEnabledDids.push({ number: did.phonenumber, description: did.description }) : null
            ));

            // Let's use the user's dids (from token) otherwise
            // Lets map through dids to get sms enabled ones
            if (this.props.token && this.props.token.userdids && this.props.token.userdids.length > 0) {
                this.props.token.userdids.map((did) => (
                    did.smsenabled == '1' ? smsEnabledDids.push({ number: did.phonenumber, description: did.description }) : null
                ));
            }

            // lets return the updated array
            return smsEnabledDids;
        }

        // Let's use the user's dids (from token) otherwise
        // Lets map through dids to get sms enabled ones
        if (this.props.token && this.props.token.userdids && this.props.token.userdids.length > 0) {
            this.props.token.userdids.map((did) => (
                did.smsenabled == '1' ? smsEnabledDids.push({ number: did.phonenumber, description: did.description }) : null
            ));
            if (this.props.token.primarydid && this.props.token.primarydid.length) {
                smsEnabledDids.push({ number: this.props.token.primarydid, description: "", unread:  0 })
            }
        }

        // lets return the updated array
        return smsEnabledDids;
    }

    getNextPage() {
        const reqData = {
            reqAction: this.props.selectedInternalDid || this.props.token.primarydid
        };

        const storeKey = 'smsData';

        this.props.getNextPage(reqData, storeKey, this.props.page);
        this.setState({ lastGetNextPage: Date.now() });
    }

    handleScroll() {
        const scrollArea = document.getElementById('conversationsListContainer');
        const lastRunGap = Date.now() - this.state.lastGetNextPage;
        const minGap = 2000;

        if (lastRunGap > minGap && (scrollArea.clientHeight + scrollArea.scrollTop + 50 > scrollArea.scrollHeight)) {
            this.getNextPage()
        }
    }

    render() {
        return (
          <div style={wrapper}>
            <SmsToolbar
            label="Conversations"
            placeholder="Search"
            type="conversationSearch"
            dids={this.smsDids()}
            />
            <Conversations
                handleScroll={(e) => this.handleScroll(e) }
            />
          </div>
        );
    }
}

const wrapper = {
    display: 'flex',
    flexDirection: 'column',
    width: '25vw',
    minWidth: 298,
};

const mapStateToProps = (state) => ({
    dids: state.http  ? state.http.configurations : null,
    selectedInternalDid: state.sms ? state.sms.selectedInternalDid : null,
    token: state.token ? state.token : null,
    page: state.sms ? state.sms.page : null,
});

const bindActions = (dispatch) => ({
    getNextPage: (reqData, storeKey, page) => dispatch(getNextPage(reqData, storeKey, page)),
    newInternalDidSelected: (number) => dispatch(newInternalDidSelected(number)),
    updatePageNumber: (number) => dispatch(updatePageNumber(number)),
});

export default connect(mapStateToProps, bindActions)(SmsConversationsList);
