import { combineReducers } from 'redux';
import moment from 'moment';
import { formatDateAPI, twoDateTimeDifference } from 'utils/misc';

import {
    CHANGE_TENANT,
    INVALIDATE_HTTP_DATA,
} from 'actions/typeConstants';

const pending = (state = false, action) => {

    switch (action.type) {

        case CHANGE_TENANT:
        case INVALIDATE_HTTP_DATA:
            return null;

        case 'QUEUERECORDS_HTTP_PENDING':
            return true;

        case 'QUEUERECORDS_HTTP_FAILURE':
        case 'QUEUERECORDS_HTTP_SUCCESS':
            return false;

        default:
            return state;
    }
};

const data = (state = [], action) => {
    let currentState = Object.assign([], state);

    switch (action.type) {

        case CHANGE_TENANT:
        case INVALIDATE_HTTP_DATA:
            return null;
        case 'QUEUERECORDS_HTTP_SUCCESS':

            if (!action.data || !action.data.data) {
                return [];
            }
            currentState = []

            // Init data for fetching recordings
            let rootUrl = 'api/v1';
            const tenant = app.token.attributes.tenant;

            // Lets iterate over the queue calls and their data contents
            Object.entries(action.data.data).forEach(([queueCall, data]) => {
                currentState.push(data);
            });

            // Sort confs in order of start-time
            if (currentState.length >= 1) {

                currentState.reverse((a, b) => {
                    if (!a.enteredqueue || a.answered === 'addmember') {
                        a.enteredqueue = a.leftqueue;
                    } else if (!b.enteredqueue || b.answered === 'removemember') {
                        b.enteredqueue = b.leftqueue;
                    }
                    return moment(a.enteredqueue).diff(moment(b.enteredqueue));
                });
                // We have our sessions sorted, lets format the timestamp now
                currentState.map((s) => {
                    if (s.answered === 'addmember') {
                        s.enteredqueue = s.leftqueue;
                        s.leftqueue = null;
                        s.memberEvent = true;
                    } else if (s.answered === 'removemember') {
                        s.enteredqueue = null;
                        s.memberEvent = true;
                    }
                    s.duration = twoDateTimeDifference(s.enteredqueue, s.enddate);
                    s.enteredqueue = s && s.enteredqueue ? formatDateAPI(s.enteredqueue) : '';
                    s.leftqueue = s && s.leftqueue ? formatDateAPI(s.leftqueue) : '';

                    if (s.recording && s.recording.length) {
                        s.recording = s.recording.replace('.wav', '.opus')
                        s.recording = `${rootUrl}/reporting/${tenant}/callrecording/${s.recording}`
                    }
                    // Sort events within session by time
                    s.events.sort((a, b) => moment(a.time).diff(moment(b.time)));

                    // Don't return events with queuecallerentryinfo. This is for top level data only.
                    s.events.filter((event) => event.event !== 'queuecallerentryinfo');
                });
            };
            // Finally, return our state
            return currentState;
        default:
            return state;
    };
};

const queues = (state = [], action) => {
    let currentState = Object.assign([], state);

    switch (action.type) {

        case CHANGE_TENANT:
        case INVALIDATE_HTTP_DATA:
            return null;
        case 'QUEUERECORDS_HTTP_SUCCESS':

            if (!action.data || !action.data.data) {
                return [];
            }
            currentState = []

            // Lets get all queues we have data for
            Object.entries(action.data.data).forEach(([queueCall, data]) => {
                if (data.queuename && typeof data.queuename !== "undefined" && currentState.indexOf(data.queuename) === -1) {
                    currentState.push(data.queuename);
                }
            });
            return currentState;
        default:
            return state;
    };
};

const dialedNumbers = (state = [], action) => {
    let currentState = Object.assign([], state);

    switch (action.type) {

        case CHANGE_TENANT:
        case INVALIDATE_HTTP_DATA:
            return null;
        case 'QUEUERECORDS_HTTP_SUCCESS':

            if (!action.data || !action.data.data) {
                return [];
            }
            currentState = []

            // Get all dialed numbers from queue data
            Object.entries(action.data.data).forEach(([queueCall, data]) => {
                if (data.events && typeof data.events !== "undefined" && currentState.indexOf(data.events) === -1) {
                    data.events.forEach(queueEvent => {
                        if (queueEvent.event === 'queuecallerentryinfo' && queueEvent.dialednumber != "") {
                            if (currentState.length > 0) {
                                if (currentState.indexOf(queueEvent.dialednumber) === -1) {
                                    currentState.push(queueEvent.dialednumber);
                                }
                            } else {
                                currentState.push(queueEvent.dialednumber);
                            }

                        }
                    });
                }
            });
            return currentState;
        default:
            return state;
    }


}
export const queueRecords = combineReducers({
    data,
    dialedNumbers,
    pending,
    queues
});
