import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const validateForm = (data) => {
  if (data.custom_scheme === '1' && (data.color_scheme == null || data.color_scheme === 'none')) {
    return {
      title: 'Error!',
      message: 'You must enter a color scheme if custom scheme is turned on.',
    }
  }

  const testEmailExpression = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");
  
  if (data.emailaddress == null) {
    data.emailaddress = '';
  }

  if (data.emailaddress !== '' && testEmailExpression.test(data.emailaddress) === false) {
    return {
      title: 'Error!',
      message: 'You must enter a valid Email.',
    }
  }

  const parsedNumber = parsePhoneNumberFromString(data.primary_cell_phone);

  if (data.primary_cell_phone !== '' && (parsedNumber == null || !parsedNumber.isValid())) {
    return {
      title: 'Error!',
      message: 'You must enter a valid Phonenumber.',
    }
  }

  const pattern = /^\(\d{3}\)-\d{3}-\d{4}$|^\d{3}-\d{3}-\d{4}$|^\d{10}$/;

  if (data.did_for_sms && pattern.test(data.did_for_sms) === false) {
    return {
      title: 'Error!',
      message: 'You must enter a valid 10 digit Phonenumber for SMS.',
    }
  } 

  if (data.telegram_id == null) {
    data.telegram_id = '';
  }
  
  if (data.telegram_id.startsWith('@') === false && data.telegram_id !== '') {
    return {
      title: 'Error!',
      message: 'Telegram ID\'s must begin with an @.',
    }
  }

  return null;
}