import React from 'react';
import PropTypes from 'prop-types';
import Select, {createFilter} from 'react-select';
import Button from '@material-ui/core/Button';

class SearchableDropdownInput extends React.Component {
    constructor(props) {
            super(props);
            this.state = {
                selectedOption: null,
            };
    }

    componentDidMount(){
        if (this.props.searchableFormOption === 'TenantSearch') {
            const selectedOptionIndex = this.props.options.findIndex(item => item.value === this.props.value);
            if (selectedOptionIndex !== -1) {
                    this.setState({
                    selectedOption: this.props.options[selectedOptionIndex],
                });
            }
        }
    }

    componentDidUpdate(prevProps){
        //if the user updates an event we want to clear out the selectedOption
        if(
            this.props.searchableFormOption !== 'TenantSearch' &&
            this.props.options !== prevProps.options &&
            this.state.selectedOption !== null
        ){
            this.setState({selectedOption: null})
        }
    }

	handleChange = selectedOption => {
		if (selectedOption !== null) {
			this.setState({ selectedOption });
		}
		if(this.props.searchableFormOption !== "VoicemailTransfer"){
            this.props.onChange(selectedOption.value)
        }
	};

  getSearchableDropdown(searchType){
        const { selectedOption } = this.state;
        const customFilter = createFilter({ ignoreAccents: false});
        switch (searchType) {
            case 'VoicemailTransfer':
                let names= []
                this.props.options.forEach(contact => {
                    names.push({
                        label: `${contact.mailbox} - ${contact.fullname}`,
                        value: contact
                    })
                });
                return (
                    <div className = "outerDiv" style={{position:'relative'}}>
                        <Select
                            value={selectedOption}
                            menuPortalTarget={document.body}
                            onChange={this.handleChange}
                            styles={{ menuPortal: base => ({...base, zIndex:9999})}}
                            options={names}
                            name='forwardVMTo'
                            style={{position: 'absolute'}}
                        />
                        <Button
                            style={{ fontSize: '100%' }}
                            color='primary'
                            onClick={()=> this.submitAndClose(selectedOption.value)}
                            >Submit</Button>
                    </div>
                )
            break;
            case 'PhoneNumberSearch':
                return (
                    <div>
                        <Select
                            filterOption={customFilter}
                            menuPortalTarget={document.body}
                            onChange={this.handleChange}
                            options={this.props.options}
                            styles={{ menuPortal: base => ({...base, zIndex:9999})}}
                            value={selectedOption}
                        />
                    </div>
                )
            break;
            case 'TenantSearch':
                return (
                    <div style={{ marginLeft: '50px', marginRight: '50px' }}>
                        <Select
                            filterOption={customFilter}
                            menuPortalTarget={document.body}
                            onChange={this.handleChange}
                            options={this.props.options}
                            styles={{ menuPortal: base => ({...base, zIndex:9999})}}
                            value={selectedOption}
                        />
                    </div>
                )
            break;
            default:
                return(
                    <div> 
                    <Select
                       filterOption={customFilter}
                       menuPortalTarget={document.body}
                       onChange={this.handleChange}
                       options={this.props.options}
                       styles={{ menuPortal: base => ({...base, zIndex:9999})}}
                       value={selectedOption}
                   />
               </div>
                )    
            }
    };
        
    submitAndClose(data){
        this.props.buttonClick(data);
        this.props.closeModal();
    }

    render() {
        return (
            <div className='dropdownContainer'>
                {this.getSearchableDropdown(this.props.searchableFormOption)}
            </div>
        );
    };
    
};

SearchableDropdownInput.propTypes = {
    options: PropTypes.array,
    searchableFormOption: PropTypes.string,
    onChange: PropTypes.func,
    buttonClick: PropTypes.func,
    closeModal: PropTypes.func,
};

export default SearchableDropdownInput;
