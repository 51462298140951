import React, { Component } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';
import Column from 'presentational/Column';

const grid = 8;
class DragAndDrop extends Component {
  // Constructor builds initial state
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentWillMount(){
    if(this.props && this.props.data){
      this.setState({data : this.props.data})
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps && nextProps.data){
      this.setState({data: nextProps.data})
    }
  }

  handleChange(item, columnId){
    if(columnId == 'left'){
      this.props.handleSelectedAddition(item);
    } else if (columnId == 'right'){
      this.props.handleSelectedDeletion(item);
    }
  }

  onDragEnd(result) {
    console.log(result)
    const { destination, source, draggableId } = result;
    if(!destination){
      return;
    }
    if(destination.droppableId == source.droppableId && destination.index === source.index){
      return;
    }
    let { data } = this.state;

    const start = this.state.data.columns[source.droppableId];
    const finish = this.state.data.columns[destination.droppableId];
    if(start == finish){
      const newItemIds = Array.from(start.itemIds)
      newItemIds.splice(source.index, 1);
      newItemIds.splice(destination.index, 0, draggableId);
      const newColumn = {
        ...start,
        itemIds: newItemIds,
      }
      data.columns[newColumn.id] = newColumn;
      this.setState({data})
      this.props.updateOrder(data);
      return;
    }

    const startItemIds = Array.from(start.itemIds);
    const finishItemIds = Array.from(finish.itemIds);
    startItemIds.splice(source.index, 1);
    finishItemIds.splice(destination.index, 0, draggableId);
    const newStart = {
      ...start,
      itemIds: startItemIds,
    }
    const newFinish = {
      ...finish,
      itemIds: finishItemIds,
    }
    const newData = {
      ...this.state.data,
      columns:{
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      }
    }

    data = newData;
    this.setState({data});
    this.props.updateOrder(data);
  }

  render(){
    if(!this.props.data){
      return (
        <StandardSettingsPage>
          <Loading />
        </StandardSettingsPage>
      );
    }
    let { data } = this.state;

    let styles ={
      container: {
        'display': 'flex',
      }
    }

    return(
      <DragDropContext
        onDragEnd={(result) => this.onDragEnd(result)}
      >
      <div style={styles.container}>
      {
        data.columnOrder.map(columnId => {
          const column = data.columns[columnId];
          const items = column.itemIds.map(itemId => data.items[itemId]);
          return <Column
            key={column.id}
            placeholder={this.props.columnPlaceholder}
            column={column}
            items={items}
            handleChange={(item, columnId)=> this.handleChange(item, columnId)}
            placeholder={this.props.placeholder}
            isDragDisabled={this.props.isDragDisabled}
            searchDisable={this.props.searchDisable}
          />
        })
      }
      </div>
      </DragDropContext>
    );
  }
};

DragAndDrop.defaultProps = {
  placeholder: 'Search Users',
  isDragDisabled: false,
  searchDisable: false,
};

DragAndDrop.propTypes = {
  placeholder: PropTypes.string,
  isDragDisabled: PropTypes.bool,
  searchDisable: PropTypes.bool,
};

export default DragAndDrop;
