export const translateQueueEvents = event => {
    const events = {
        abandon: 'Abandon',
        addmember: 'Add Member',
        attendedtransfer: 'Attended Transfer',
        BRIDGE: 'Bridge',
        removemember: 'Remove Member',
        agentdump: 'Agent Dump',
        agentlogin: 'Agent Login',
        agentcallbacklogin: 'Agent Callback Login',
        agentlogoff: 'Agent Log Off',
        agentcallbacklogoff: 'Agent Callback Log Off',
        blindtransfer: 'Blind Transfer',
        completeagent: 'Complete Agent',
        completecaller: 'Complete Caller',
        configreload: 'Config Reload',
        connect: 'Connect',
        enterautoattendant: 'Enter Auto Attendant',
        autoattendantkey: 'Auto Attendant Key Press',
        entervoicemail: 'Enter Voicemail',
        exitvoicemailkey: 'Escape Voicemail with Key Press',
        enterqueue: 'Enter Queue',
        exitempty: 'Exit Empty',
        exitwithkey: 'Exit With Key',
        exitwithtimeout: 'Exit With Timeout',
        queuestart: 'Queue Start',
        ringnoanswer: 'Ring No Answer',
        syscompat: 'Syscompat',
        transfer: 'Transfer',
        pause: 'Pause',
        unpause: 'Unpause',
        pauseall: 'Pause All',
        unpauseall: 'Unpause All',
        queuecallerentryinfo: 'Queue Call Entry',
        0: 'No Answer',
        1: 'Answered',
    };
    return events[event];
};
export const translateApplication = application => {
    switch (application) {
        case 'answeringmachinedetection':
            return 'Answering Machine Detection';
        case 'callbackextensionfordid':
            return 'Callback Extension for Phone Number';
        case 'callflow':
            return 'Call Flow';
        case 'callforwarding':
            return 'Call Forwarding';
        case 'callgroup':
            return 'Call Group';
        case 'conferenceroom':
            return 'Conference Room';
        case 'directory':
            return 'Directory';
        case 'echo':
            return 'Echo Audio';
        case 'extension':
            return 'Extension';
        case 'extensionfollowme':
            return 'Extension w/ Follow Me Settings';
        case 'externalapplication':
            return 'External Application Service';
        case 'faxanswer':
            return 'Fax Answer';
        case 'faxdetect':
            return 'Fax Detection';
        case 'hangup':
            return 'Hangup Call';
        case 'markunanswered':
            return 'Mark Call Unanswered';
        case 'milliwatt':
            return 'Milliwatt Tone';
        case 'queue':
            return 'Queue';
        case 'recording':
            return 'Recording';
        case 'requestcallback':
            return 'Request a Callback';
        case 'route':
            return 'Route';
        case 'sendsms':
            return 'Send SMS';
        case 'setlanguage':
            return 'Set Caller Language';
        case 'spellstring':
            return 'Spell Alpha-Numeric String';
        case 'texttospeech':
            return 'Text To Speech';
        case 'voicemailbox':
            return 'Voicemail';
        case 'voicemailmenu':
            return 'Check Voicemail';
        case 'voicemenu':
            return 'Auto Attendant';
        case 'waitforsilence':
            return 'Wait For Silence';
        case 'webservicetovariable':
            return 'Web Service Call';
        case 'zipcoderouter':
            return 'Zip Code Router';

        default:
            return 'No application';
    }
};

export const translatePhoneTypeToFriendlyName = event => {
    const events = {
        location: 'Location',
        description: 'Description',
        prependcid: 'Prepend Caller ID',
        assignedto: 'Phone Number Destination',
        distinctivering: 'Unique Ring',
        emergencyroute:'Emergency Route',
        holidayroute:'Holiday Route',
        callrecording:'Call Recording',
        exclude_from_location_matching: 'Enable LAP',
        assignedroute: 'Route',
        assignedmanaged: 'Managed Route',
        assignedconference: 'Conference Room', 
        pinless_entry: 'Pinless Entry',
        enable_custom_conf_greeting: 'Conference Room Greeting',
        newRecording: 'Custom Greeting',
        call_forwarding_number:"Call Forwarding",
        callForwardingTransparency: 'Call Forwarding Transparency',
        callForwardingDialedDisplay: 'Display Dialed Number',
        faxenabled: "Fax Enabled",
        assigneduser: "Extension",
        privatefax: "Fax Type",
        fax_detection_inbound: "Inbound Detection",
        fax_detection_wait_time: "Fax Detection Wait Time",
        privatefaxuser: "Private Fax Destination",
        fax_minimum_rate:"Fax Minimum Rate",
        fax_maximum_rate: "Fax Maximum Rate",
        fax_enable_t38: "Enable T.38",
        fax_error_correction: "Error Correction Mode",
        porting_in_progress: "Porting in Progress",
        sms_posturl_override:"Incoming Text Message/ SMS Webhook url",
        temporary: "Temporary Number", 
        associated_permanent_numbers: "Associated Permanent Number", 
        acd_queue_identifier: "ACD Queue Identifier",
        acd_queue_entry_priority: "ACD Queue Entry Priority", 
        external_dispatch: "External Dispatch", 
        enable_room_level_e911:"Enable Room Level e911",
        disabled_address:"Address",
        room_e911_address_unit_type:"Unit Type",
        room_e911_address_unit_number: "Unit Number",
    };
    return events[event];
};
