import { 
    GETTENANTCHANGESREPORT_HTTP_FAILURE,
    GETTENANTCHANGESREPORT_HTTP_SUCCESS
} from '../actions/typeConstants';
import { combineReducers } from 'redux';


const initialState = [];

export const data = (state = initialState, action) => {
    switch (action.type) {

    case GETTENANTCHANGESREPORT_HTTP_FAILURE:
        return state;

    case GETTENANTCHANGESREPORT_HTTP_SUCCESS:
        return {...state, data: Object.values(action.data) };

    default:

        return {...state, data: []};
    }
};

export const tenantChangesReport = combineReducers({
    data,
});
