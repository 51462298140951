import {
    NEW_INCOMING_SMS,
    NEW_OUTGOING_SMS,
    POST_HTTP,
    SELECT_SMS_CONVERSATION,
    SMS_NAMESPACE } from '../actions/typeConstants';

// Needed to handle updating current conversation sms view
const smsUpdater = store => next => action => {

    // Pass all actions through by default
    next(action);

    // Lets get the current conversation
    const state = store.getState() && store.getState().sms ? store.getState().sms.selectedConversation : null;
    const currentConversation = Object.assign({}, state);

    switch (action.type) {

    case NEW_INCOMING_SMS:

        // NEEDED TO SUBMIT NEXT ACTION --- TODO: FIX THIS - HACKY

        return next({
            type: SELECT_SMS_CONVERSATION,
            avatar: currentConversation.avatar,
            contactInfo: currentConversation.contactInfo,
            conversation: currentConversation.conversation,
            keepCurrentInput: true,
        });

    case NEW_OUTGOING_SMS:

        if (currentConversation.conversation &&
            currentConversation.conversation.participant == action.data.to) {
            currentConversation.conversation.messages.push(action.data);
        }

        next({
            type: SELECT_SMS_CONVERSATION,
            avatar: currentConversation.avatar,
            contactInfo: currentConversation.contactInfo,
            conversation: currentConversation.conversation,
        });

        // Lets actually post the new message to the api
        store.dispatch({
            type: POST_HTTP,
            storeKey: 'outgoingSmsPosted',
            namespace: SMS_NAMESPACE,
            requestData: {
                reqAction: 'send',
                reqBody: {
                    to: action.data.to,
                    from: action.data.from,
                    message: action.data.message,
                    messageId: action.data.messageId,
                },
            },
        });
    }
};

export default smsUpdater;
