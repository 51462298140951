import React from 'react';
import PropTypes from 'prop-types';

import ContentAdd from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

const style = {
    buttonLabel: {

    },
    buttonWrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        margin: 20,
        flex: 1,
        color: '#737373',
        fontSize: 18,
    },
    wrapper: {
        alignItems: 'center',
        backgroundColor: '#EFEFEF',
        display: 'flex',
        minHeight: 70,
        paddingBottom: 10,
        paddingRight: 35,
        paddingTop: 15,
    },
};

const Title = (props) => (
  <div style={style.wrapper}>
    <div style={style.title}>{props.title}</div>

    {!props.noAdd && <div style={style.buttonWrapper}>
      <Fab
        size='small'
        color='primary'
        onClick={() => props.addNew()}
      >
        <ContentAdd />
      </Fab>
      <span style={style.buttonLabel}>Add New</span>
    </div>}
  </div>
);

Title.propTypes = {
    title: PropTypes.string.isRequired,
    noAdd: PropTypes.bool,
};

export default Title;
