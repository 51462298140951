import { combineReducers } from 'redux';

import {
    CHANGE_TENANT,
    INVALIDATE_HTTP_DATA,
} from 'actions/typeConstants';

const id = 'name';

const data = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case 'CONTACTS_HTTP_SUCCESS':

        if (action.auditHistory) {

            const newState = Object.assign([], state);

            const found = newState.findIndex(obj => obj[id] == action.reqObject);
            if (found == -1 || !newState[found]) {
                return state;
            }

            newState[found].auditHistory = action.data;

            return Object.assign([], newState);
        }

        if (action.reqObject) {

            const newState = Object.assign([], state);
            const found = newState.findIndex(obj => obj[id] == action.reqObject);

            if (!action.data) {
                delete newState[found];
            } else if (found === -1) {
                newState.push(action.data);
            } else {
                newState[found] = action.data;
            }

            return Object.assign([], newState);
        }

        if (!action.data) {
            return [];
        }


        return Object.assign([], action.data);
    default:
        return state;
    };
};

const pending = (state = false, action) => {

    switch (action.type) {

    case 'CONTACTS_HTTP_PENDING':
        return true;

    case 'CONTACTS_HTTP_FAILURE':
    case 'CONTACTS_HTTP_SUCCESS':
        return false;

    default:
        return state;
    }
};

export const contacts = combineReducers({
    data,
    pending,
});
