import React, { Component } from 'react';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import { connect } from 'react-redux';
import { navigate } from './actions/navigation';
import { getHandler } from './reactRouter';

const routes = [
    'autoattendant',
    'conferencesummary',
    'dynamicagents',
    'postcallsurveys',
    'notificationworkflows',
    'notificationworkflows/:id',
    'reactvoicemail',
    'sms',
    'mycallhistory',
    'loginmanagement',
    'callgroups',
    'queuerecords',
    'incomingcallfilters',
    'parkinglots',
    'conferencerooms',
    'multicastgroups',
    'voicemailboxes',
    'voicemailinbox',
    'voicemailinbox/:mailbox',
    'voicemailgreetings',
    'voicemailgreetings/:mailbox',
    'voicemailsettings',
    'voicemailsettings/:mailbox',
    'voicemailboxes/:mailbox',
    'voicemailboxes/:mailbox/inbox',
    'voicemailboxes/:mailbox/settings',
    'voicemailboxes/:mailbox/greetings',
    'voicemailboxes/:mailbox/history',
    'managedroutes',
    'managedroutes/:name',
    'conferencerooms/:confno',
    'phonenumbers',
    'phonenumbers/:number',
    'phoneoverrides',
    'callrecording',
    'zohointegration',
    'callblocking',
    'callscreening',
    'callforwarding',
    'userportal',
    'dids',
    'dids/:did',
    'ideasPortalLogin',
    'voicemailrecording',
    'voicemailrecording/:recordingId',
    'usersettings',
    'fax-create',
    'fax-inbox',
];
// This is the router that will be used for all of the react routes
class App extends Component {
    componentDidUpdate(prevProps) {
        if (routes.includes(this.props.route) !== true) {
            ReactDOM.unmountComponentAtNode(this.props.root);
        }
    }

    render() {
        return (
            <div>
                {getHandler(this.props.route)}
            </div>
        );
    }
}

const bindActions = dispatch => ({
    navigate: route => dispatch(navigate(route)),
});

const mapStateToProps = state => ({
    notifications: state.notifications,
    route: state.navigation.route,
});

export default connect(
    mapStateToProps,
    bindActions
)(App);
