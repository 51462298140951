import React, { Component } from 'react';
import ReactTable from 'react-table'
import "react-table/react-table.css";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/styles';

import Loading from 'presentational/Loading';
import AudioPlayer from 'presentational/formControls/AudioPlayer';
import { formatDateReportsService } from 'utils/misc';
import { formatNumber } from 'utils/phoneNumbers';


class ConferenceGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            rowInfo: null,
            downloadPrompt: false,
            dialogKey: 0,
        }
    }

    componentDidMount() {
        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
    }
    // Open subgrid on row click
    handleOpen(rowInfo) {
        this.setState({ rowInfo: rowInfo.original.confevents })
        this.setState({ open: true });
    };
    // Close subgrid
    handleClose() {
        this.setState({ open: false });
        this.setState({ rowInfo: null })
    };
    // Format subgrid events
    formatConferenceEvent(event) {
        switch (event) {
            case 'ADDUSERTOCONFERENCE':
                return 'Joined Conference';
            case 'REMOVEUSERFROMCONFERENCE':
                return 'Left Conference'
            default:
                return event;
        }
    }
    // Format internal key
    formatInternalKey(key) {
        switch (key) {
            case 1:
                return 'Internal';
            case 0:
                return 'External';
            default:
                return 'External';
        }
    }
    // Trigger snackbar on download
    triggerDownloadPrompt(e) {
        e && this.setState({ downloadPrompt: true });
    }
    // Handle snackbar close
    handleRequestClose() {
        this.state.downloadPrompt && this.setState({ downloadPrompt: false });
    }
    // On subgrid page size change, re-render dialog by changing the key prop for proper horizontal grid positioning
    updateDialogPositioning() {
        this.setState({ dialogKey: this.state.dialogKey++ })
    }

    render() {
        const { data, pending, classes } = this.props;
        if (!pending && !data) {
            return <Loading />;
        }
        if (pending && data === null) {
            return <Loading />;
        }
        { /* Initialie grid and subgrid columns */ }
        const columns = [{
            Header: 'Room',
            accessor: 'room',
            maxWidth: 65,
            Cell: props => <p style={style.column}>{props.value}</p>
        }, {
            Header: 'Description',
            accessor: 'name',
            Cell: props => <p style={style.column}>{props.value}</p>
        }, {
            Header: 'Start Date',
            accessor: 'startdate',
            maxWidth: 200,
            Cell: props => <p style={style.column}>{props.value}</p>
        }, {
            Header: 'End Date',
            accessor: 'enddate',
            maxWidth: 200,
            Cell: props => <p style={style.column}>{props.value}</p>
        }, {
            Header: 'Duration',
            accessor: 'duration',
            maxWidth: 85,
            Cell: props => <p style={style.column}>{props.value}</p>
        }, {
            Header: 'Recording',
            accessor: 'recording',
            Cell: props => {
                let recordingUrl;
                if(props.value){
                    //Some environments include the domain, others don't.
                    if (props.value.includes(window.location.origin)) {
                        console.log("[[INFO] conferencegrid.render] Recording URL includes origin");
                        recordingUrl = props.value.split(window.location.origin + window.location.pathname)[1].split('?')[0];
                    } else {
                        recordingUrl = props.value.substr(1).split('?')[0];
                    }

                    //these are a bit ugly but we needed a way to parse the url to match our interface
                    const recordingName = props.value.split('/').pop().split('?')[0];
                    const recordingParams = props.value.split('?')[1];
                    return (
                        <AudioPlayer
                            style={style.column}
                            recordingUrl={recordingUrl}
                            recordingName={recordingName}
                            recordingParams={recordingParams}
                            conf
                            showTime
                            showDownload
                            triggerDownload={(e) => this.triggerDownloadPrompt(e)}
                        />
                    )
                } else {
                    return (<br />)
                }
            }
        }]
        const subColumns = [{
            Header: 'Caller Number',
            accessor: 'caller',
            width: 200,
            Cell: props => <p style={style.column}>{formatNumber(props.value)}</p>
        }, {
            Header: 'Caller Name',
            accessor: 'callername',
            width: 200,
            Cell: props => <p style={style.column}>{props.value}</p>
        }, {
            Header: 'Event',
            accessor: 'event',
            width: 200,
            Cell: props => <p style={style.column}>{this.formatConferenceEvent(props.value)}</p>
        }, {
            Header: 'Internal',
            accessor: 'internal',
            maxWidth: 85,
            Cell: props => <p style={style.column}>{this.formatInternalKey(props.value)}</p>
        }, {
            Header: 'Timestamp',
            accessor: 'eventdate',
            width: 200,
            Cell: props => <p style={style.column}>{formatDateReportsService(props.value)}</p>
        }]
        return (
            <div style={style.grid}>
                <Snackbar
                    open={this.state.downloadPrompt}
                    message={<span>Request for recording pending.. Please stand by.</span>}
                    autoHideDuration={4000}
                    onClose={() => this.handleRequestClose()}
                />
                <ReactTable
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]).includes(filter.value)}
                    data={this.props.data}
                    columns={columns}
                    defaultPageSize={10}
                    styles={style.column}
                    className="-striped -highlight"
                    pageSizeOptions={[10, 25, 50]}
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: (e, handleOriginal) => {
                                if (rowInfo && column.id !== 'recording') {
                                    this.handleOpen(rowInfo);
                                }
                                return;
                            }
                        }
                    }}
                />
                <Dialog
                    key={this.state.dialogKey}
                    open={this.state.open}
                    onClose={() => this.handleClose()}
                    fullWidth
                >
                    <DialogContent classes={{ root: classes.subGrid }}>
                        <ReactTable
                            data={this.state.rowInfo || []}
                            columns={subColumns}
                            className="-striped -highlight"
                            onPageSizeChange={() => this.updateDialogPositioning()}
                            defaultPageSize={10}
                            pageSizeOptions={[10, 25, 50]}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

const classStyle = () => ({
    subGrid: {
        maxWidth: 'none',
    },
});

const style = {
    grid: {
        background: 'white',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        minWidth: '75%'
    },
    column: {
        textAlign: 'center',
        marginTop: '10px',
    },
}

export default withStyles(classStyle)(ConferenceGrid);
