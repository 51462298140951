import React, { Component } from 'react';

class StandardSettingsPage extends Component {

    constructor(props) {
        super(props);
        this.state = { mediaQuery: false };
    }

    componentDidMount() {

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
    }

    render() {

        const minWidth = this.state.mediaQuery.matches;

        const style = {
            wrapper: {
                display: 'flex',
                flex: 1,
                width: '100%',
                background: '#EFEFEF',
                flexDirection: 'column',
                paddingTop: minWidth ? '50px' : 0,
                alignItems: 'center',
                paddingBottom: minWidth ? '75px' : 0,
            },
        };

        return (
          <div style={style.wrapper}>
            {this.props.children}
          </div>
        );
    }
}

export default StandardSettingsPage;
