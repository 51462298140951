import { combineReducers } from 'redux';
import moment from 'moment';
import { formatDateReportsService, twoDateTimeDifference } from 'utils/misc';

import {
    CHANGE_TENANT,
    INVALIDATE_HTTP_DATA,
} from 'actions/typeConstants';

const pending = (state = false, action) => {

    switch (action.type) {

        case 'CONFERENCERECORDS_HTTP_PENDING':
            return true;

        case 'CONFERENCERECORDS_HTTP_FAILURE':
        case 'CONFERENCERECORDS_HTTP_SUCCESS':
            return false;

        default:
            return state;
    }
};


const data = (state = [], action) => {
    let currentState = Object.assign([], state);

    switch (action.type) {

        case CHANGE_TENANT:
        case INVALIDATE_HTTP_DATA:
            return '';
        case 'CONFERENCERECORDS_HTTP_SUCCESS':

            if (!action.data || !action.data.data) {
                return [];
            }
            currentState = []

            let rootUrl = '/api/v1';
            if (window.BASE_URL) {
                rootUrl = `${window.BASE_URL}/api/v1`;
            }
            const tenant = app.token.attributes.tenant;

            // Lets iterate over the conference sessions and their data contents
            Object.entries(action.data.data).forEach(([session, data]) => {

                if (!data['conf_completed']) {
                    return;
                }

                if (!data.startdate || !data.startdate.length) {
                    return;
                }
                if (!data.enddate && !data.enddate.length) {
                    return;
                }
                if (data.recording) {
                    data.recording = data.recording.replace('.wav', '.opus')
                    data.recording = `${rootUrl}/reporting/${tenant}/callrecording/${data.recording}?filedir=Conferences`
                }
                if (!data.recordingURL) {
                    data.recordingURL = null;
                }
                currentState.push(data);
            });

            // Sort confs in order of start-time
            if (currentState.length >= 1) {
                currentState.sort((a, b) => {
                    return moment(a.startdate).diff(moment(b.startdate));
                });
                // We have our sessions sorted, lets format the timestamp now
                currentState.map((s) => {
                    s.duration = twoDateTimeDifference(s.startdate, s.enddate);
                    s.startdate = formatDateReportsService(s.startdate);
                    s.enddate = formatDateReportsService(s.enddate);
                });
            };

            // Finally, return our state
            return currentState;
        default:
            return state;
    };
};

export const conferenceRecords = combineReducers({
    data,
    pending
});
