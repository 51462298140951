import { combineReducers } from 'redux';

import {
    CHANGE_TENANT,
    NEW_VOICEMAIL_BOX,
    INVALIDATE_HTTP_DATA
} from 'actions/typeConstants';

const id = 'name';

const data = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case 'CONFIGURATIONS_HTTP_SUCCESS':

        return Object.assign({}, state, action.data);

    case 'NEW_VOICEMAIL_BOX':
      
        return Object.assign({}, state, action.data);

    default:
        return state;
    };
};

const pending = (state = false, action) => {

    switch (action.type) {

    case 'CONFIGURATIONS_HTTP_PENDING':
        return true;

    case 'CONFIGURATIONS_HTTP_FAILURE':
    case 'CONFIGURATIONS_HTTP_SUCCESS':
        return false;

    default:
        return state;
    }
};

export const configurations = combineReducers({
    data,
    pending,
});
