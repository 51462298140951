import React from 'react';
import PropTypes from 'prop-types';

import ChipInputAutoSuggest from './chipInputAutosuggest';

const FilterChip = (props) => {

    const style = {

      chipInputContainer:{
        paddingLeft: '10px',
        paddingRight: '10px'
      },
      chipInput:{

      }
    }

    function validateChip(chip) {

        if (props.filterData.includes(chip)) {
            return true;
        }
    }

    if(props && props.style && props.style.override){
        Object.entries(props.style.override).forEach( (elementToOverride) => {
            if(style[elementToOverride[0]]){
                Object.keys(props.style.override[elementToOverride[0]]).forEach( (key)=>{
                    style[elementToOverride[0]][key] = props.style.override[elementToOverride[0]][key];
                })
            }
        })
    }

    return (
      <div style={style.chipInputContainer}>
        <ChipInputAutoSuggest
          resetFilters={props.resetFilters}
          placeholder={props.filtername}
          label={props.filtername}
          defaultValue={props.defaultValue}
          suggestions={props.filterData}
          onAdd={(value) => {props.handleChange(value)}}
          handleDelete = {(value) => {props.handleChange(value)}}
          values={props.values}
        />
      </div>
    );
};

const style = {

};

FilterChip.defaultProps = {
    filterName: 'Filter',
    filterData: ['apple', 'bannana', 'orange', 'pineapple', 'mango'],
    values: null,
};

FilterChip.propTypes = {
    filterName: PropTypes.string,
    filterData: PropTypes.array,
    defaultValue: PropTypes.array,
    handleChange: PropTypes.func,
    values: PropTypes.array,
};

export default FilterChip;

