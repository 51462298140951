import React, { Component } from 'react';

import StandardSettingsPage from 'presentational/StandardSettingsPage';
import TextInput from 'presentational/formControls/TextInput';
import SubmitButton from 'presentational/formControls/SubmitButton';
import CancelButton from 'presentational/formControls/CancelButton';

class NewCallGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorText: '',
      callgroupname: '',
      callgroupdescription: '',
    };
  }

  async submitForm(name, description) {
    const error = await this.props.submitForm(name, description);
    if (error) {

      console.error(error);
      this.setState({
        disabled: false,
        errorText: error.summary,
      });
      return;
    }

    this.props.selectCallGroup(this.state.callgroupname.replace(/[^A-Za-z0-9_]/g, ''));
  }

  render() {

    const style = {
      header: {
        color: '#595959',
      },

    };

    return (
      <StandardSettingsPage>
        <h2 style={style.header}>Enter Call Group Name</h2>
        <TextInput
          errorText={this.state.errorText}
          field="callgroupname"
          onChange={(callgroupname) => this.setState({ callgroupname })}
          placeholder="Call Group Name"
          value={this.state.callgroupname}
        />
        <TextInput
          errorText={this.state.errorText}
          field="callgroupdescription"
          onChange={(callgroupdescription) => this.setState({ callgroupdescription })}
          placeholder="Call Group Description"
          value={this.state.callgroupdescription}
        />
        <div>
          <SubmitButton
            disabled={this.state.disabled || !this.state.callgroupname || this.props.callGroups.some(callGroup => callGroup.friendlyname == this.state.callgroupname || callGroup.name == this.state.callgroupname)}
            label="Create"
            onClick={() => {
              this.setState({ disabled: true });
              this.submitForm(this.state.callgroupname, this.state.callgroupdescription);
            }}
          />
          <CancelButton
            label="Cancel"
            onClick={() => this.props.selectCallGroup('')}
          />
        </div>
      </StandardSettingsPage>
    );
  }
}

export default NewCallGroup;
