import React from 'react';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    height: 125,
    minWidth: 300,
    flexGrow: .5,
    marginTop: 20,
  },
  container: {
    position: 'relative',
  },
  input: {
    width: '100%',
    marginLeft: -15,
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    maxHeight: '200px',
    overflow: 'scroll'
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(1) * 2,
  },
});

function getSuggestions(suggestions, value) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? suggestions
    : suggestions.filter(suggestion => {
        const keep =
          suggestion.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
        return keep;
      });
}

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;
  return (
    <TextField
      InputLabelProps={{shrink: true}}
      size='large'
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
      }}
      {...other}
    />
  );
}

function getSuggestionValue(suggestion) {
  return suggestion;
}


function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion, query);
  const parts = parse(suggestion, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          ),
        )}
      </div>
    </MenuItem>
  );
}

function shouldRenderSuggestions(value) {
  return value.trim().length > -1;
}

class AutoComplete extends React.Component {
  state = {
    single: '',
    popper: '',
    suggestions: [],
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    const suggestionsList = this.props.suggestions.map(item => {
      if(item.text){
        return item.text
      }
      else if(item.label){
        return item.label;
      }
      else{
        return item;
      }
    });
    this.setState({
      suggestions: getSuggestions(suggestionsList, value),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = name => (event, { newValue }) => {
    this.setState({
      [name]: newValue,
    });
  };

  onSuggestionSelected = (event , data) => {
    this.props.onNewRequest(this.props.suggestions.find(item => {
      if(item.text){
        return item.text===data.suggestion
      }
      else if(item.label){
        return item.label===data.suggestion
      }
      else{
        return item===data.suggestion
      }
    }));
    if(!this.props.persist){
      this.handleChange('single')({}, { newValue: '' });
    }
  }

  render(){
    const { classes, title,  placeholder} = this.props;
    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion,
      shouldRenderSuggestions,
      onSuggestionSelected: this.onSuggestionSelected,
    };

    return(
      <div className={classes.root} style={{height: '150px'}}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            label: this.props.title,
            placeholder: this.props.placeholder,
            value: this.state.single,
            onChange: this.handleChange('single'),
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
            input: classes.input,
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
    )
  }

}

export default withStyles(styles)(AutoComplete);