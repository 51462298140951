import React, { Component } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import TextInput from 'presentational/formControls/TextInput';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';
import ColumnItem from 'presentational/ColumnItem';

class Column extends Component {
  // Constructor builds initial state
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      items: [],
    };
  }

  componentDidMount(){
    if(this.props && this.props.items){
      const items = this.props.items;
      this.setState({items});
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps && nextProps.items && nextProps.items !== this.props.items){
      const items = nextProps.items;
      this.setState({items});
    }
  }

  handleChange(item, columnId){
    let searchString = '';
    this.setState({searchString});
    this.props.handleChange(item, columnId)
  }

  filterUsers(val) {
    let searchString = val;
    this.setState({searchString})
    let items = [];
    this.props.items.map((item) => {
      const sessionCopy = JSON.stringify(item).toLowerCase();
      if (sessionCopy.includes(val.toLowerCase())) {
        items.push(item)
      };
    });
    this.setState({items});
  }

  render() {
    if (!this.props.items) {
      return (
        <StandardSettingsPage>
          <Loading />
        </StandardSettingsPage>
      );
    }

    return(
      <div style={styles.container}>
        <h3 style={styles.columnHeader}>{this.props.column.title}</h3>
        {!this.props.searchDisable && <TextInput
          field="searchString"
          placeholder={this.props.placeholder}
          onChange={(val) => this.filterUsers(val)}
          value={this.state.searchString}
        />}
        <Droppable droppableId={this.props.column.id} >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={styles.itemList}
            >
              {this.state.items.map((item, index) => (
              <ColumnItem key={item.id} item={item} index={index}
                columnId={this.props.column.id}
                handleClick={(item, columnId) => this.handleChange(item, this.props.column.id)}
                isDragDisabled={this.props.isDragDisabled}  
              />))}
              {provided.placeholder}
            </div>
          )}      
          </Droppable>
      </div>
    );
  }
};

const styles = {
  container: {
    'margin': '8px',
    'background': 'white',
    'border': '1px solid lightgrey',
    'borderRadius': '2px',
    'width': '385px',
    'height': '500px',
    'overflowY': 'scroll',
    'display': 'flex',
    'flexDirection': 'column'
  },
  columnHeader: {
    'padding': '8px',
  },
  itemList: {
    'padding': '8px',
    'flexGrow': '1',
    'minHeight': '100px',
  }
}

Column.defaultProps = {
  placeholder: 'Search Users',
  isDragDisabled: false,
  searchDisable: false,
};

Column.propTypes = {
  placeholder: PropTypes.string,
  isDragDisabled: PropTypes.bool,
  searchDisable: PropTypes.bool,
};

export default Column;
