import React from 'react';
import PropTypes from 'prop-types';

import Input from 'react-phone-input-intl';
import 'react-phone-input-intl/dist/style.css';

const PhoneInput = (props) => {
  const style = {
    wrapper: {
      paddingLeft: 65,
      paddingBottom: 30,
    },
    phoneInputContainerStyle: {
      width: 'auto',
    },
  };

  return (
    <div style={style.wrapper}>
      <Input
        placeHolder={props.placeHolder}
        defaultCountry={'us'}
        value={props.value}
        onChange={(val, data) => props.onChange(val, data)}
        getCountryCode={(countryCode) => props.getCountryCode('+' + countryCode)}
        containerStyle={style.phoneInputContainerStyle}
      />
    </div>
  );
}

PhoneInput.defaultProps = {
  placeholder: '',
  value: '',
  type: '',
  onChange: () => console.log('No onChange func provided for textinput'),
}

PhoneInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  getCountryCode: PropTypes.func,
  onChange: PropTypes.func,
}

export default PhoneInput;
