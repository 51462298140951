import { parseError } from '../utils/misc';
import {
    CREATESHORTCODES_HTTP_FAILURE,
    CREATESHORTCODES_HTTP_SUCCESS,
    DELETESHORTCODES_HTTP_FAILURE,
    DELETESHORTCODES_HTTP_SUCCESS,
    EDITSHORTCODES_HTTP_FAILURE,
    EDITSHORTCODES_HTTP_SUCCESS,
    RESET_FLAG,
    SHORTCODES_HTTP_SUCCESS,
    SHORTCODES_HTTP_FAILURE,
} from '../actions/typeConstants';

const initialState = {
    shortCodesData: [],
    pending: true,
    failureFlag: null,
    successFlag: null,
    createdCode: null,
};

const updatedCodeList = (code, list) => {
    return list.reduce((accumulator, item) => {
        const editedCode = code[0];
        const { prevCodeNum, codeNumber } = editedCode;
        if (prevCodeNum && prevCodeNum == item.codeNumber) {
            editedCode.codeNumber = codeNumber;
            delete editedCode.prevCodeNum;
            accumulator.push(editedCode);
            return accumulator;
        } else {
            accumulator.push(item);
            return accumulator;
        }
    }, []);
};

export default function(state = initialState, action) {
    switch (action.type) {
        case "CHANGE_TENANT":
            return { ... state, shortCodesData: [] };
        case CREATESHORTCODES_HTTP_FAILURE:
            const errorCode = JSON.parse(action.error.response.text);
            return {
                ...state,
                successFlag: null,
                failureFlag: errorCode.error.message,
            };
        case CREATESHORTCODES_HTTP_SUCCESS:
            if (action.reqObject == 'bulkupload') {
                let successFlagMessage = `Success! Bulk uploaded codes.`;
                let errorFlagMessage;
                // If we don't have any codes added, let's send the message
                if (!action.data.added.length) {
                    successFlagMessage = null;
                    errorFlagMessage = `No codes added, please make sure the numbers are formatted correctly and try again.`;
                } else {
                    // Otherwise, let's see if there were any errors
                    errorFlagMessage = action.data.notAdded.length
                        ? action.data.notAdded
                              .map(
                                  item =>
                                      `<li>Code: ${item.code} was not added for the following reason: ${item.reason}</li>`,
                              )
                              .join('')
                        : null;
                }
                return {
                    ...state,
                    createdCode: action.data.code,
                    failureFlag: null,
                    successFlag: successFlagMessage,
                    failureFlag: errorFlagMessage,
                    shortCodesData: [...state.shortCodesData, ...action.data.added],
                };
            }
            let newShortCodes;
            if (Array.isArray(action.data)) {
                newShortCodes = [...state.shortCodesData, ...action.data];
            } else {
                newShortCodes = [...state.shortCodesData, action.data];
            }
            return {
                ...state,
                createdCode: action.data,
                shortCodesDetails: action.data,
                failureFlag: null,
                successFlag: `Success! Created Short Code: ${action.data.codeNumber}.`,
                shortCodesData: newShortCodes,
            };
        case DELETESHORTCODES_HTTP_FAILURE:
            return {
                ...state,
                successFlag: null,
                failureFlag: parseError(action.error.response.text),
            };
        case DELETESHORTCODES_HTTP_SUCCESS:
            // eslint-disable-next-line
            const codeData = state.shortCodesData.filter(
                code => code.codeNumber !== action.reqObject,
            );
            return {
                ...state,
                shortCodesData: [...codeData],
                successFlag: `Deleted ${action.reqObject}`,
                failureFlag: null,
            };
        case EDITSHORTCODES_HTTP_FAILURE:
            return {
                ...state,
                successFlag: null,
                failureFlag: action.error.response.body.error.message,
            };
        case EDITSHORTCODES_HTTP_SUCCESS:
            const updatedList = updatedCodeList(action.data, state.shortCodesData);
            return {
                ...state,
                successFlag: `Succesfully Updated Short Code:${action.reqObject}`,
                failureFlag: null,
                shortCodesData: updatedList,
            };
        case SHORTCODES_HTTP_FAILURE:
            return {
                ...state,
                failureFlag: parseError(action.error.response.text),
                successFlag: null,
            };
        case SHORTCODES_HTTP_SUCCESS:
            if (action.reqObject) {
                return { ...state, pending: false };
            }
            const returnState = { ...state, shortCodesData: action.data, pending: false };
            return returnState;
        case RESET_FLAG:
            return { ...state, successFlag: null, failureFlag: null };
        default:
            return { ...state };
    }
}
