import React from 'react';
import PropTypes from 'prop-types';

import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';
import MinusIcon from '@material-ui/icons/ExposureNeg1';
import PlusIcon from '@material-ui/icons/ExposurePlus1';
import { makeStyles } from '@material-ui/styles';

const StepperInput = (props) => {

    const style = {
        wrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        plusBadge: {
            // marginLeft: '-22px',
        },
    };

    const classes = makeStyles({
      button: {
        margin: '18px',
      },
    })();

    return (
      <div>
        <h3>{props.label}</h3>
          <div style={style.wrapper}>
            <Fab
            color='primary'
              size='small'
              onClick={() => {
                  if (props.value -1 < props.min) {
                      return;
                  }
                  props.onChange(props.field, JSON.stringify(parseInt(props.value) - 1))
              }}
              classes={{ root: classes.button }}
            >
              <MinusIcon />
            </Fab>
            <h4>{props.value}</h4>
            <Fab
              color='primary'
              size='small'
              onClick={() => {

                  if (parseInt(props.value) + 1 > props.max) {
                      return;
                  }
                  props.onChange(props.field, JSON.stringify(parseInt(props.value) + 1))
              }}
              classes={{ root: classes.button }}
            >
              <PlusIcon />
            </Fab>
          </div>
      </div>
    );
};

StepperInput.defaultProps = {
    min: 1,
    max: 100,
    label: '',
    onChange: () => console.log('No onChange func provided for StepperInput'),
    value: '0',
};

StepperInput.propTypes = {
    field: PropTypes.string.isRequired,
    label: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default StepperInput;
