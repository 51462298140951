import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SubmitButton from 'presentational/formControls/SubmitButton';
import CancelButton from 'presentational/formControls/CancelButton';


class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = { mediaQuery: false };
  }

  componentDidMount() {

    // Media query for responsive UI
    const mq = window.matchMedia('(min-width: 800px)');
    this.setState({ mediaQuery: mq });
    mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
  }

  determineSubmitLabel() {

    if (this.props.submitButtonText) {
      return this.props.submitButtonText;
    } else if (this.props.newFlag) {
      return 'Save';
    } else if (this.props.multipleChanges){
      return 'Update All';
    }
    return 'Submit';
  }

  render() {

    const minWidth = this.state.mediaQuery.matches;

    const style = {
      footer:{
        width: this.props.fullWidth ? '100%' :'115%',
        height: '60px',
        background: 'white',
        position: 'fixed',
        zIndex: '1',
        display: 'flex',
        bottom: 0,
        left: 0,
        justifyContent: 'center',
        borderTop: '1px solid #e3e3e3',
        padding: '10px 0',
      }
    };

    return (
      <div style={style.footer}>
        {this.props.singleButton === false && <CancelButton
          label={this.props.cancelButtonText}
          onClick={() => this.props.onClick(true)}
        />}
        <SubmitButton
          label={this.determineSubmitLabel()}
          disabled={this.props.disabled}
          onClick={this.props.multipleChanges ? this.props.onUpdateAll : this.props.onClick}
        />
      </div>
    );
  }
}

Footer.defaultProps = {
  singleButton: false,
  submitButtonText: '',
  disabled: false,
  onClick: () => console.log('no onClick function is given to footer'),
  newFlag: false,
  fullWidth: false,
  cancelButtonText: 'Cancel',
}

Footer.propTypes = {
  newFlag: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  singleButton: PropTypes.bool,
  submitButtonText: PropTypes.string,
  fullWidth: PropTypes.bool,
  cancelButtonText: PropTypes.string,
}

export default Footer;
