import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/styles';

import AddIcon from '@material-ui/icons/Add';

const style = makeStyles({
    card: {
        width: '50%',
        margin: '25px',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        borderStyle: 'dashed',
        borderColor: 'rgb(42, 114, 185)',
        boxShadow: 0,
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '0px',
    },
    icon: {
        color: 'rgb(42, 114, 185)',
    },
    title: {
        color: 'rgb(42, 114, 185)',
        display: 'block',
        fontSize: '15px',
        marginLeft: '10px',
    },
});

const NewCardPlaceholder = (props) => {
  const classes = style();
  return (
    <Card classes={{ root: classes.card }} >
      <CardHeader
        title={
          <div
            className={classes.content}
            onClick={() => props.onClick()}
          >
            <AddIcon classes={{ root: classes.icon }} />
            <div className={classes.title}>{props.title}</div>
          </div>
        }
        onClick={() => props.onClick()}
      >
      </CardHeader>
    </Card>
  );
}

NewCardPlaceholder.defaultProps = {
    title: '',
};

NewCardPlaceholder.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

export default NewCardPlaceholder;
