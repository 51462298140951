import { translateQueueEvents } from 'utils/translations';

export const formatNumber = (number) => {
    if (!number || number.length < 1) {
        return
    }

    if (number[0].match(/[a-z]/i)) {
        return number
    }
    /*If the number isn't long enough to be a phone number, skip this part*/
    if (number.length < 7) {
        return number;
    }
    if (number.length == 10) {
        number = number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        return number;
    }

    // We really need to use a like libphonenumber-js to format phone numbers.
    // However, until we get to that point, a special case for e164 formatted numbers.
    // This is important because the new phone number ordering services stores the phone
    // numbers with it's country code.
    if((number.length > 10) && (number[0] === '+')) {
        number = number.replace(/(\+\d*)?(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
        return number.trim();
    }

    /*Take out the front 1, as well as other characters*/
    if (number.length <= 20) {

        number = number.replace(/[^0-9]/g , '');
        number = number.replace(/^1/ , '');
    }

    /*If the number is not 10 digits long, skip the formatting
    *TODO - International numbers will not be formatted correctly
    */

    return number;
}

// Strips number to 10 digit format ie: 6665554444
export const stripNumber = (number) => number.replace(/^1|^\+1|[^0-9]/g,"");

// Matches a phone number to a contact and returns the contact
export const matchContact = (contacts, number) => {

	let foundContact = false;
    // If we have contacts, lets search them
    if (contacts && contacts.length > 0) {
        // Lets map through the contacts, then each phoneNumber in that contact to find a match.
        foundContact = contacts.find((contact) => {

            if (contact.phoneNumbers && contact.phoneNumbers.length > 0) {

                return contact.phoneNumbers.some((contactNumber) =>
                    stripNumber(contactNumber.value) == number,
                );
            }
            return false;
        });
    }

    return foundContact;
};
