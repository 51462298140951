import { 
    UPDATE_TOKEN,     
    CHANGE_TENANT,
    INVALIDATE_HTTP_DATA, 
} from '../actions/typeConstants';

export const token = (state=[], action) => {

    switch(action.type) {

        case CHANGE_TENANT:
        case INVALIDATE_HTTP_DATA:
    
            return null;

        case UPDATE_TOKEN:

            return Object.assign({}, state, action.token);
        break;

        default:
        
            return state;
        break;
    }
}
