import { createStore, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk';

import promise from './middleware/promise';
import http from './middleware/http';
import sms from './middleware/sms';
import reducer from './reducers';

export default function configureStore(initalState) {

	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const enhancer = composeEnhancers(
        applyMiddleware(thunk, promise, http, sms),
    );

    const store = createStore(reducer, enhancer);

    return store;
}
