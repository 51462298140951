import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { connect } from 'react-redux';
import { formatNumber } from 'utils/phoneNumbers';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  tables:{
    height: '200px',
    overflowY: 'scroll'
  }
}

class GlobalconferenceNumbers extends Component {
  constructor(props){
    super(props);

    this.state = {
      fixedHeader: true,
      fixedFooter: true,
      stripedRows: false,
      showCheckboxes: false,
      showRowHover: true,
    }
  }
 
  render() {
    let tableRows = null;
    if(this.props.conferenceRoomData.config){
      tableRows = this.props.conferenceRoomData.config.global_numbers.map(item => {
        return( 
          <TableRow key={item.phonenumber}>
            <TableCell>{formatNumber(item.phonenumber)}</TableCell>
            <TableCell>{item.global_location}</TableCell>
          </TableRow>
        )
      })
    }

    return (
      <div>
        <Table height={this.state.height} >
          <TableHead>
            <TableRow>
              <TableCell>Number</TableCell>
              <TableCell>Location</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: this.props.classes.tables }}>
            {tableRows}
          </TableBody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  conferenceRoomData : state.conferenceRoom.conferenceRoomData
});

export default connect(mapStateToProps)(withStyles(styles)( GlobalconferenceNumbers));