import {
    CHANGE_TENANT,
    HTTP_PENDING,
    HTTP_SUCCESS,
    HTTP_FAILURE,
    INVALIDATE_HTTP_DATA,
    MARK_MESSAGES_UNREAD,
    NEW_INCOMING_SMS,
    NEW_OUTGOING_SMS_POSTED,
    REMOVE_MESSAGE_FROM_CONVERSATION,
    NEXT_PAGE_SUCCESS,
    UPDATE_CONFIGURATIONS,
    ALLTAGS_HTTP_SUCCESS,
    } from '../actions/typeConstants';

const initialState = {};

// httpData reducer holds all state for any data received by http
export const http = (state = initialState, action) => {

    // Allows us to override the key used to store data in the store
    const storeKey = action.storeKey || action.reqAction;

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case HTTP_PENDING:

        return Object.assign({}, state, { pending: { [storeKey]: true } });

    case HTTP_SUCCESS:

        return Object.assign({}, state, {
            failed: { [action.storeKey ]: false},
            pending: { [storeKey]: false },
            [storeKey]: action.data,
        });

    case NEXT_PAGE_SUCCESS:

        const oldData = Object.assign({}, state.smsData);

        if (!oldData || !oldData.data) {
            return state;
        }

        oldData.data.map((conversation) => {

            let index = _.findIndex(action.data.data, function(convo){
                return conversation.hash == convo.hash
            });

            if (index != -1) {
                conversation  = action.data.data[index];
                action.data.data.splice(index, 1);
            }

            return conversation;

        });

        let mergedData = oldData;

        mergedData.data = oldData.data.concat(action.data.data);
        action.data = mergedData;

        return Object.assign({}, state, {
            pending: { [storeKey]: false },
            [storeKey]: action.data,
        });

    case HTTP_FAILURE:

        console.log(`Error fetching data for: ${storeKey}`);
        console.log('Here is the error: ', action.error);
        console.log('Here is the response: ', action.res);

        return Object.assign({}, state, {
            failed: { [action.storeKey ]: true },
            pending: { [action.reqAction]: false },
        });

    // NEEDED HERE TO ADD TO OBJECT
    case NEW_INCOMING_SMS:

        const newObj = Object.assign({}, state.smsData);

        if (!newObj || !newObj.data) {
            return state;
        }

        newObj.data.map((conversation) => {

            if (conversation.participant == action.data.from) {
                conversation.messages.unshift(action.data);
                conversation.lastActivity = action.data.timestamp;
            }
        });

        return Object.assign({}, state, { smsData: newObj });

    // NEEDED HERE TO ADD TO OBJECT
    case NEW_OUTGOING_SMS_POSTED:

        // Lets create a new object of the conversations
        const newConversations = Object.assign({}, state.smsData);

        if (!newConversations || !newConversations.data) {
            return state;
        }

        // Lets find the index of the conversation we need to edit
        const index = newConversations.data.findIndex((convo) =>
            convo.participant == action.conversation.participant,
        );

        // If this is not a new conversation, lets replace
        // the converstation with the new one
        if (index != -1) {
            newConversations.data[index] = action.conversation;
        } else {
            newConversations.data.push(action.conversation);
        }

        // And return this new conversation list to state
        return Object.assign({}, state, { smsData: newConversations });

    case REMOVE_MESSAGE_FROM_CONVERSATION:

        const conversationsAfterDeletion = Object.assign({}, state.smsData);

        if (!conversationsAfterDeletion || !conversationsAfterDeletion.data) {
            return state;
        }

        const convoIndex = conversationsAfterDeletion.data.findIndex((convo) =>
            convo.participant == action.participant,
        );

        let unreadConvoIndex = null;
        if (conversationsAfterDeletion.unread) {
            unreadConvoIndex = conversationsAfterDeletion.unread.findIndex((convo) =>
                convo.participant == action.participant,
            );
        }

        const msgIndex = conversationsAfterDeletion.data[convoIndex].messages.findIndex((message) =>
            message.messageId == action.messageId,
        );

        let unreadMsgIndex = null;
        if (conversationsAfterDeletion.unread && conversationsAfterDeletion.unread[convoIndex]) {
            unreadMsgIndex = conversationsAfterDeletion.unread[convoIndex].messages.findIndex((message) =>
                message.messageId == action.messageId,
            );
        }

        conversationsAfterDeletion.data[convoIndex].messages.splice([msgIndex], 1);

        if(unreadConvoIndex && unreadMsgIndex && unreadConvoIndex > -1 && unreadMsgIndex > -1){
            conversationsAfterDeletion.unread[convoIndex].messages.splice([unreadMsgIndex], 1);
        }

        return Object.assign({}, state, { smsData: conversationsAfterDeletion });

    case MARK_MESSAGES_UNREAD:

        let conversationsAfterUpdate = Object.assign({}, state.smsData);

        if (!conversationsAfterUpdate || !conversationsAfterUpdate.data) {
            return state;
        }

        conversationsAfterUpdate.data.map((convo) => {
            if (convo.hash == action.conversation.hash) {
                convo.messages.map((message) =>
                    message.unread = false,
                );
            }
        });
        conversationsAfterUpdate.unread.map((convo) => {
            if (convo.hash == action.conversation.hash) {
                convo.messages.map((message) =>
                    message.unread = false,
                );
            }
        });

        return Object.assign({}, state, { smsData: conversationsAfterUpdate });

    case UPDATE_CONFIGURATIONS:

        return Object.assign({}, state, { configurations: action.configurations });

    case ALLTAGS_HTTP_SUCCESS:
        return Object.assign({}, state, { tags: action.data });

    default:

        return state;
    }
};
