import React, { Component } from 'react';
import { connect } from 'react-redux';

//Presentational Components
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import FormControlCard from 'presentational/FormControlCard';
import Loading from 'presentational/Loading';

//Material Components
import RecordIcon from '@material-ui/icons/RecordVoiceOver';

import { 
    getUserPortalHttp, 
    postUserPortalHttp,
    deleteUserPortalHttp,
    getCoreHttp,
} from 'actions/httpRequest';

import {
    errorNotification,
    successNotification,
} from 'actions/notifications';

class Greetings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            voicemailBox:'',
            settings:{},
            form:{},
            disabled:true,
            country:'',
            location:[],
            fetchedMailbox: false, 
            greetingPath:'',
            loading:true,
            voicemailBoxList:'',
            newRecording: '',
            notificationId: Math.round(Math.random() * 100),
        };
    }

    componentDidMount() {
        const extension = localStorage.getItem('userPortalExtension') || app.token.get("extension");
        let mailbox;
        if (this.props.adminView){
            mailbox = this.props.selectedVoicemailBox;
        } else {
            mailbox = this.props.routeParams ? this.props.routeParams.mailbox : app.token.get('mailbox');
        }

        this.setState({ 
            greetings: this.props.greetings,
            tenant: this.props.configurations.tenant,
            selectedVoicemailBox: this.props.selectedVoicemailBox,
            mailbox: mailbox,
        });

        if (this.state.mailbox || app.token.get("mailbox")){
            this.fetchVoicemailGreetings(app.token.get("mailbox"))
        }
    }

    componentWillReceiveProps(nextProps) {

        let mailbox; 
        let voicemailBoxList = [];
        if (nextProps.extensionData && !this.props.adminView){
            this.setState({ loading:false });
            mailbox = nextProps.extensionData.mailbox ? nextProps.extensionData.mailbox.split("@")[0] : "";
        }

        if (this.props.adminView){
            mailbox = this.props.selectedVoicemailBox;
        }

        if (!this.props.adminView || app.token.get("role") == "cloudusers"){
            mailbox = app.token.get("mailbox")
        }

        if (!this.props.adminView && nextProps && nextProps.configurations && nextProps.configurations.voicemailboxes){
            nextProps.configurations.voicemailboxes.forEach(mailbox => {
                voicemailBoxList.push(mailbox.mailbox)})
        }

        this.setState({ mailbox, voicemailBoxList });

        if (nextProps.reducerTarget == "voicemailboxes" && (nextProps.postSuccess || nextProps.deleteSuccess)){
                let greetingType;
                let greetingObject = nextProps.deleteSuccess.object ? nextProps.deleteSuccess.object : nextProps.postSuccess.object;
                
                switch (greetingObject){
                    case 'unavail':
                        greetingType = "unavailable";
                        break;
                    case 'busy':
                        greetingType = "busy";
                        break;
                    case 'greet':
                        greetingType = "name";
                        break;
                    case 'temp':
                        greetingType = 'temporary';
                        break;
                    default:
                        greetingType = '';
                        break;
                }

                if (nextProps.deleteSuccess.object || nextProps.postSuccess.object){
                    // Since we have to call the setTimeout, the notification gets called twice on
                    // the Settings -> VM page, the UserPortal -> VM page is fine.
                    // So let's make sure there is a success object and then see if there is a `pending`
                    // key in the nextProps object. Since we have already checked to see if there
                    // is a success object, we know the request made it through so we can pop
                    // the notification ASAP, in this case it's when pending is true.
                    if ("pending" in nextProps) {
                        if (nextProps.pending) {
                            this.renderSuccessMessage(`Successfully ${nextProps.deleteSuccess.object ? 'deleted' : 'created'} ${greetingType} greeting`)
                        }
                    } else {
                        this.renderSuccessMessage(`Successfully ${nextProps.deleteSuccess.object ? 'deleted' : 'created'} ${greetingType} greeting`)
                    }
                }

            }

        if (nextProps.greetings) {
            this.setState({
                tenant: nextProps.configurations ? nextProps.configurations.tenant : null,
                greetings: nextProps.greetings,
                selectedVoicemailBox: nextProps.selectedVoicemailBox,
            });
        }
    }

    componentWillMount(){
        let mailbox = app.token.get("mailbox");

        //If this is our admin view, we get mailbox from the parent component
        if (this.props.adminView){
            mailbox = this.props.selectedVoicemailBox; 
        }

        this.setState({ mailbox });

        if (!this.props.adminView){
            this.fetchExtensions();
        }
    }

    componentDidUpdate(prevProps, prevState){
        if (!this.props.adminView && this.state.mailbox !== prevState.mailbox){
            this.setState({ loading: true });
            this.fetchVoicemailGreetings(this.state.mailbox)
        }
    }

    fetchExtensions(){
        const reqData = {
            reqAction: 'extensions',
        };

        this.setState({ loading:true, fetchedMailbox: true });
        const storeKey = 'extensions';
        this.props.getUserPortalHttp(reqData, storeKey);
    }

    fetchVoicemailGreetings(voicemailBox){

        if (this.props.adminView && voicemailBox == app.token.get("mailbox")) {
            return;
        }

        const reqData={
            reqAction: 'voicemailgreetings',
            reqObject: voicemailBox,
        }

        const storeKey = "voicemailgreetings";

        if (voicemailBox){
            this.props.getUserPortalHttp(reqData, storeKey);
        };

        this.setState({ loading: false });
    }

    async submitForm(field, data) {

        if (this.state.form[field] == data) {
            console.log('No update');
            return;
        }

        if (this.state.disabled == true) {
            this.setState({disabled: false});
        }

        const { form } = this.state;
        const rollbackForm = Object.assign({}, form);

        // Lets validate our field
        const error = this.validateField(field, data);
        if (error) {
            this.setState({
                errors: { [field]: error },
                form: rollbackForm,
            });
            return;
        }

        // If no error, lets set the data
        // on the form and set it to state without errors
        form[field] = data;
        this.setState({
            form,
            errors: { [field]: '' },
        });
    }

    recordNewGreeting(greetingName){
        let oldGreetings = [];
        Object.keys(this.state.greetings).forEach((foundGreeting) => {
            if (!isNaN(foundGreeting)){
                oldGreetings.push(this.state.greetings[foundGreeting]);
            }
        });

        let greeting;
        switch (greetingName){
            case 'Unavailable':
                greeting = 'unavail';
                break;
            case 'Busy':
                greeting ='busy';
                break;
            case 'Name':
                greeting ="greet";
                break;
            case 'Temporary':
                greeting ="temp";
                break;
        }
        const newGreeting = {greeting, new: true};

        oldGreetings[greeting] = newGreeting;
        this.setState({
            greetings: oldGreetings,
            newRecording: greeting
        });
    }

    validateField(field, data) {

        // We want to make sure our wait
        // times are actually numbers
        // and greater than 0
        switch (field) {
        case 'responsewait':
        case 'keypresswait':
        case 'i_limit':
        case 'none_limit':
            if (data && Number.isInteger(parseInt(data)) && data >= 0) {
                return null;
            }
            return 'Please enter a valid number';

        default:
            return null;
        }
    }

    //Upload a new greeting from an audio file
    upload(greeting, greetingType){

        if (greeting && greeting.target) {
            this.setState({
                disabled: false, 
                greetingPath: greeting.target.files[0],
                greetingType,
                greetingData:null
            })
        } else {
            this.setState({
                disabled: false,
                greetingData: greeting,
                greetingType,
            })
        }
    }


    //Create New Audio Greeting
    createGreeting() {

        let formData;

        if (this.state.greetingData){
            let blob = this.state.greetingData.blob;

            let fileType = blob.type.split('/')[0] || 'audio';
            let fileName = (Math.random() * 1000).toString().replace('.', '');

            formData = new FormData();
            formData.append(fileType + '-filename', fileName);
            formData.append(fileType + '-blob', blob);

            let data = formData;
            data.append('file', formData);

        } else {

            formData = new FormData();
            let file = this.state.greetingPath;
            formData.append('file', file);
        }

        let { mailbox, greetingType, selectedVoicemailBox } = this.state;

        let shortGreeting = '';

        switch (greetingType) {
            case 'Unavailable':
                shortGreeting='unavail';
                break;
            case 'Busy':
                shortGreeting='busy';
                break;
            case 'Name':
                shortGreeting='greet';
                break;
            case 'Temporary':
                shortGreeting='temp';
                break;
        }

        const reqData =  {
            reqAction: 'voicemailgreetings',
            reqObject: shortGreeting,
            reqObject2: mailbox ? mailbox : selectedVoicemailBox,
            reqBody: formData
        }

        const storeKey = 'voicemailgreetings';
        this.props.postUserPortalHttp(reqData, storeKey);
    }

    //Delete a VM Greeting
    removeGreeting(greeting){
        let { mailbox } = this.state;
        let shortGreeting = '';

        switch (greeting) {
            case 'Unavailable':
                shortGreeting='unavail';
                break;
            case 'Busy':
                shortGreeting='busy';
                break;
            case 'Name':
                shortGreeting='greet';
                break;
            case 'Temporary':
                shortGreeting='temp';
                break;
        }

        const reqData =  {
            reqAction: 'voicemailgreetings',
            reqObject: shortGreeting,
            reqObject2: mailbox,
        }

        const storeKey = 'voicemailgreetings';

        this.props.deleteUserPortalHttp(reqData, storeKey)

    }

    renderErrorMessage(response){
        errorNotification({
            id: this.state.notificationId,
            title:'Error!',
            message: response
        });  
    }

    renderSuccessMessage(response){
        successNotification({
            id: this.state.notificationId,
            title:'Success!',
            message: response
        });
    }

    render() {
        const styles = {
            header: {
                color: '#595959',
            },
        }

        if ((!this.props.adminView && (!this.state || !this.state.greetings || this.props.pending || this.state.loading)) ||
            (this.props.adminView && !this.state.greetings && this.state.loading)) {
            return (
                <StandardSettingsPage>
                    <Loading />
                </StandardSettingsPage>
            );
        }

        if ((!this.props.adminView && !this.state.mailbox) ||  (!this.props.adminView && !this.state.loading && (app.token.get("mailbox") == "" ))){
            return (
                <StandardSettingsPage>
                    <h2>This extension does not have a mailbox.</h2>
                    <h4>Please contact support or your administrator.</h4>
                </StandardSettingsPage>
            )
        } 

        let greetingTypes = ["unavail", "busy", "greet", "temp"];
        let myGreetings = [];
        Object.keys(this.state.greetings).forEach((foundGreeting) => 
            myGreetings.push(this.state.greetings[foundGreeting].greeting)
        )
        let greetingComponents = [];

        greetingTypes.forEach(greeting => {
            let title;
            let newRecording = false;
            switch (greeting){
                case 'unavail':
                    title= 'Unavailable';
                    break;
                case 'busy':
                    title='Busy';
                    break;
                case 'greet':
                    title="Name";
                    break;
                case 'temp':
                    title="Temporary";
                    break;
            }
            if(this.state.newRecording === greeting){
                newRecording = true;
            }

            const tenant = this.state.tenant || app.token.get("tenant");
            const recordingUrl = `${window.BASE_URL ? window.BASE_URL : ''}/api/v1/userportal/${tenant}/${this.state.mailbox}/voicemailgreetings/${greeting}.wav`;

            if (myGreetings.includes(greeting) && !newRecording) {
                greetingComponents.push(
                    <FormControlCard
                        key={title}
                        field={greeting}
                        formControl = 'ExistingGreeting'
                        title={title}
                        recordingUrl={recordingUrl}
                        disabled={false}
                        icon={<RecordIcon />}
                        removeGreeting={(greeting) => this.removeGreeting(greeting)}
                    />
                )
            } else if(newRecording) {
                greetingComponents.push(
                    <FormControlCard 
                        key={title}
                        field={greeting}
                        formControl = 'NoGreeting'
                        title={title}
                        disabled={this.state.disabled}
                        icon={<RecordIcon />}
                        recordNewGreeting={(greeting) => this.recordNewGreeting(greeting)}
                        upload={(greeting, greetingType) => this.upload(greeting, greetingType)}
                        onChange={(val) => { this.setState({disabled: false })}}
                        cancel={()=> this.props.cancel()}
                        createRecording={(greeting, greetingType) => {
                            this.createGreeting(greeting, greetingType),
                            this.setState({ 
                                disabled:true,
                                newRecording: '',
                            })
                        }}
                    />
                );
            } else {
                greetingComponents.push(
                    <FormControlCard 
                        key={title}
                        field={greeting}
                        formControl = 'RecordNew'
                        title={title}
                        disabled={false}
                        icon={<RecordIcon />}
                        recordNewGreeting={(greeting) => this.recordNewGreeting(greeting)}
                    />
                );
            }
        })

        return (
            <div style={{
                display: 'flex',
                flex: 1,
                height: '100%',
                background: '#EFEFEF',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: '50px',
                overflow: 'auto',
            }}>
            <StandardSettingsPage style={styles.settingsContainer}>
                {greetingComponents}
            </StandardSettingsPage>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    greetings: state.voicemail.greetings,
    selectedVoicemailBox:state.voicemail.selectedMailbox,
    configurations:state.http ? state.http.configurations : null,
    deleteSuccess: state.voicemail.deleteSuccess,
    postSuccess: state.voicemail.postSuccess,
    reducerTarget: state.voicemail.reducerTarget,
    extensionData: state.voicemail.extensionData,
});

const bindActions = (dispatch) => ({
    getUserPortalHttp: (reqData, storeKey) => dispatch(getUserPortalHttp(reqData, storeKey)),
    getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
    postUserPortalHttp: (reqData, storeKey) => dispatch(postUserPortalHttp(reqData, storeKey)),
    deleteUserPortalHttp: (reqData, storeKey) => dispatch(deleteUserPortalHttp(reqData, storeKey)),
});

export default connect(mapStateToProps, bindActions)(Greetings);
