import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";

function RenderComponent(props) {
    const { moduleDef, sx } = props;

    const [refEl, setReferenceEl] = useState()
    const [lastData, setLastData] = useState({});

    useEffect(() => {
    
        let unmount = undefined;
        if(refEl != undefined && moduleDef.mount) {
            unmount = moduleDef.mount(refEl, App.Configurations.attributes);
        }
        setLastData({el: refEl, moduleDef})
        return () => {
            if(unmount) {
                unmount();
            }
        }
    }, [refEl, moduleDef])

    const onRef = useCallback((element) => {
        setReferenceEl(element)
    }, []);

    if(moduleDef.mount) {
        return <Box style={sx} className="module-root" ref={onRef} ></Box>
    }

    if(moduleDef.component) {
        return React.createElement(moduleDef.component);
    }

    return <div>Failed to load component</div>;
}

export default RenderComponent;