import React from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import makeGlobalStyles from 'makeGlobalStyles';

function CallBlockingButton(props) {
  const classes = makeGlobalStyles();
  return (
    <Button
      classes={{ root: classes.helpButton }}
      onClick={() => {
        props.toggleBlocking();
      }}
    >
      {props.text}
    </Button>
  );
}

CallBlockingButton.propTypes = {
  text: PropTypes.string.isRequired,
  toggleBlocking: PropTypes.func.isRequired,
}

export default CallBlockingButton;