import cloneDeep from 'lodash.clonedeep';
import {
  FAXOPTIONS_HTTP_SUCCESS,
  FAXOPTIONS_HTTP_FAILURE,
  CREATEFAX_HTTP_SUCCESS,
  CREATEFAX_HTTP_FAILURE,
  RESET_FLAG,
  CHANGE_FAX_FORM_DATA,
  SENDFAX_HTTP_SUCCESS,
  SENDFAX_HTTP_FAILURE,
  FAXOPTIONS_HTTP_PENDING,
} from '../actions/typeConstants';

import { parseError } from 'utils/misc';

const initialForm = {
  did: '',
  callerid: '',
  reference: '',
  files: [],
  uploaded: 0,
}

const initialState = {
  createFaxForm: cloneDeep(initialForm),
  faxOptions: {
    faxablenumbers: [],
  },
  errorCode: null,
  errorMsg: '',
  successMsg: '',
  faxCreated: false,
  sentFaxSuccess: false,
  sentFaxError: false,
  requestPending: false,
}

export default function(state = initialState, action) {
  switch(action.type) {
    case FAXOPTIONS_HTTP_SUCCESS:
      return {
        ... state,
        faxOptions: action.data,
        requestPending: false,
        createFaxForm: cloneDeep(initialForm),
      }
    case CREATEFAX_HTTP_SUCCESS:
      return {
        ...state,
        createFaxForm: {...state.createFaxForm, ...action.data},
        faxCreated: true,
        requestPending: false,
      }
    case CHANGE_FAX_FORM_DATA:
      return {
        ...state,
        createFaxForm: action.payload,
      }
    case SENDFAX_HTTP_SUCCESS:
      return {
        ...state,
        sentFaxSuccess: true,
        sentFaxError: false,
        createFaxForm: cloneDeep(initialForm),
        requestPending: false,
      }
    case SENDFAX_HTTP_FAILURE:
      return {
        ...state,
        sentFaxSuccess: false,
        sentFaxError: true,
        requestPending: false,
      }
    case FAXOPTIONS_HTTP_FAILURE:
    case CREATEFAX_HTTP_FAILURE:
      return {
        ...state,
        errorCode: action.error.response.status,
        errorMsg: parseError(action.error.response.text),
        successMsg: '',
      }
    case 'POST_HTTP':
    case FAXOPTIONS_HTTP_PENDING:
      return {
        ...state,
        requestPending: true,
      }
    case RESET_FLAG:
      return {
        ...state,
        errorCode: null,
        errorMsg: '',
        successMsg: '',
        faxCreated: false,
        sentFaxSuccess: false,
        sentFaxError: false,
        requestPending: false,
      }
    default:
      return { ...state }
  }
}