import {
    ADD_MESSAGE_TO_BE_DELETED,
    CANCEL_MESSAGE_TO_BE_DELETED,
    CURRENTLY_DELETING_MESSAGES,
    MARK_MESSAGES_UNREAD,
    NEW_INCOMING_SMS,
    NEW_OUTGOING_SMS,
    NEW_OUTGOING_SMS_POSTED,
    REMOVE_MESSAGE_FROM_CONVERSATION,
    REMOVE_MESSAGE_TO_BE_DELETED,
    SELECT_SMS_CONVERSATION,
    TOGGLE_ALL_MESSAGES_TO_BE_DELETED,
    UPDATE_SELECTED_DID_FOR_SMS_ADMIN,
    UPDATE_NEW_NUMBER_INPUT,
    UPDATE_TEXT_FOR_CONVERSATION_SEARCH,
    UPDATE_TEXT_FOR_CONTACT_SEARCH } from './typeConstants';

import { patchSmsHttp } from './httpRequest';

export const showingNewNumberInput = (showNewNumberInput) => {
    return {
        type: UPDATE_NEW_NUMBER_INPUT,
        showNewNumberInput,
    }
}
export const newInternalDidSelected = (did) => ({
    type: UPDATE_SELECTED_DID_FOR_SMS_ADMIN,
    did,
});

export const updateTextForConversationSearch = (text) => ({
    type: UPDATE_TEXT_FOR_CONVERSATION_SEARCH,
    text,
});

export const updateTextForContactSearch = (text) => ({
    type: UPDATE_TEXT_FOR_CONTACT_SEARCH,
    text,
});

export const selectConversation = (conversation, avatar, contactInfo) => ({
    type: SELECT_SMS_CONVERSATION,
    contactInfo,
    conversation,
    avatar,
});

export const newSmsMessage = (data) => (dispatch) => {

    const { direction } = data;

    // Lets set the conversation to have an additional message as well in configurations
    const configNumbers = Object.assign([], App.Configurations.get("phonenumbers"));
    let index = -1;

    if (direction == 'outgoing') {

        // Find index of conversation in phone numbers
        index = configNumbers.findIndex((item) => item.phonenumber == data.from);
    } else {

        // Find index of conversation in phone numbers
        index = configNumbers.findIndex((item) => item.phonenumber == data.to);
    }

    // Set unread count to 0 for this number
    configNumbers[index].unreadMessages = configNumbers[index].unreadMessages ?
        configNumbers[index].unreadMessages += 1 : 1;

    // Set to configurations
    App.Configurations.set("phonenumbers", configNumbers);
    App.Configurations.trigger('change', App.Configurations);

    const messageId = data.direction == 'incoming' ?
        `${data.internalNumber}${data.externalNumber}${data.timestamp}` : `${data.externalNumber}${data.internalNumber}${data.timestamp}`;

    //messages which are outgoing should not be rendered as a new incoming sms
     if(direction == "outgoing"){
         return;
     }else{
        return dispatch({
            type: NEW_INCOMING_SMS,
            data: {
                direction,
                from: direction == 'incoming' ? data.from : data.to,
                to: direction == 'incoming' ? data.to : data.from,
                message: data.message,
                messageId,
                tenant: null,
                timestamp: data.timestamp,
                unread: true,
            },
        });
    }
};

export const sendSmsMessage = (message) => ({
    type: NEW_OUTGOING_SMS,
    data: {
        direction: 'outgoing',
        from: message.from,
        to: message.to,
        message: message.message,
        messageId: `${message.to}${message.from}${message.timestamp}`.toString(),
        tenant: null,
        timestamp: message.timestamp,
        sending: true,
    },
});

export const newOutgoingSmsPosted = (conversation) => ({
    type: NEW_OUTGOING_SMS_POSTED,
    conversation,
});

export const deletingSmsMessages = (deleting) => ({
    type: CURRENTLY_DELETING_MESSAGES,
    deleting,
});

export const addMessageToBeDeleted = (messageId) => ({
    type: ADD_MESSAGE_TO_BE_DELETED,
    messageId,
});

export const removeMessageToBeDeleted = (messageId) => ({
    type: REMOVE_MESSAGE_TO_BE_DELETED,
    messageId,
});

export const cancelMessagesToBeDeleted = () => ({
    type: CANCEL_MESSAGE_TO_BE_DELETED,
});

export const toggleAllMessagesForDeletion = () => ({
    type: TOGGLE_ALL_MESSAGES_TO_BE_DELETED,
});

export const updatePageNumber = (page) => ({
    type: "UPDATE_PAGE_NUMBER",
    page
});

export const deleteMessageFromConversation = (participant, messageId) => ({
    type: REMOVE_MESSAGE_FROM_CONVERSATION,
    participant,
    messageId,
});

export const markMessagesUnread = (conversation) => dispatch => {

    // Prepare an http post
    const reqData = {
        reqAction: conversation.number,
        reqObject: conversation.participant,
        reqBody: { unread: false },
    };

    let messageCount = 0;

    // Lets map through the messages for this conversation
    // and if the message is unread, let's submit a patch to the api
    // to mark the message as unread
    conversation.messages.map((message) => {

        if (message.unread) {

            reqData.reqObject2 = message.messageId;
            messageCount++
            dispatch(patchSmsHttp(reqData));
        }
    });

    // Lets set the conversation as read as well in configurations
    const configNumbers = Object.assign([], App.Configurations.get("phonenumbers"));

    // Find index of conversation in phone numbers
    const index = configNumbers.findIndex( (item) => item.phonenumber == conversation.number);

    // Set unread count to 0 for this number
    if(index > -1){
        configNumbers[index].unreadMessages = configNumbers[index].unreadMessages - messageCount;
    }

    // Set to configurations
    App.Configurations.set("phonenumbers", configNumbers);

    App.Configurations.trigger('change', App.Configurations);

    dispatch({
        type: 'UPDATE_DID_CONFIGURATIONS',
        configNumbers,
    });

    // Lets dispatch the action for our reducer to handle the final steps
    return dispatch({
        type: MARK_MESSAGES_UNREAD,
        conversation,
    });
};
