import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import deepClone from 'lodash.clonedeep';

import StandardSettingsPage from 'presentational/StandardSettingsPage';
import FormControlCard from 'presentational/FormControlCard';
import Footer from 'presentational/Footer';

import {
  changePhonenumberFormData,
  clearPhonenumberFormData,
} from 'actions/phoneNumbersManager';

import { postMasterHttp } from 'actions/httpRequest';
import { isInvalidNumber, isSuperDuperAdmin } from './phoneNumberManagerUtils';
import { errorNotification } from 'actions/notifications';

function CreatePhoneNumberForm(props) {
  const tenantList = props.tenants.map(item => ({
    label: item.friendly_tenant_name ? item.friendly_tenant_name : item.tenant,
    value: item.tenant 
  }));
  const tenantOptions = [{ label: 'No Tenant', value: 'none' }].concat(tenantList);
  const carrierOptions = props.carriers.map(item => ({ label: item.friendlyname, value: item.index}));
  const booleanOptions = [
    {label: 'Yes', value: '1'},
    {label: 'No', value: '0'}
  ];
  const noneOption = {
    value: 'none',
  };

  const changeFormValue = (field, value) => {
    const newData = deepClone(props.form);

    // value none means '' as value
    if (value === 'none') {
      value= '';
    }

    newData[field] = value;
    props.changePhonenumberFormData(newData);
  }

  const onSubmit = (cancel) => {
    if (cancel) {
      props.clearPhonenumberFormData();
      app.navigate('dids', {trigger: true});
    } else {
      const payload = deepClone(props.form);
      const error = isInvalidNumber(payload.friendlyname);
      
      if (error) {
        errorNotification(error);
      } else {
        const reqData = {
          reqAction: 'globaldids',
          reqBody: payload,
        };
        const storeKey = 'createphonenumbers';
  
        props.postMasterHttp(reqData, storeKey);
      }
    }
  }
  
  return (
    <div
      style={styles.wrapper}
    >
      <StandardSettingsPage>
        <FormControlCard
          form={props.form}
          field='friendlyname'
          formControl="TextInput"
          title="New DID"
          type='number'
          subtitle="The DID"
          submitForm={changeFormValue}
        />
        <FormControlCard
          form={props.form.tenant === '' ? noneOption : props.form}
          field={props.form.tenant === '' ? 'value' : 'tenant'}
          formControl="SearchableSelectInput"
          closeModal={() => {}}
          searchableFormOption = 'TenantSearch'
          buttonClick={() => {}}
          title='Tenant'
          subtitle='The tenant for this number.'
          submitForm={(field, value) => changeFormValue('tenant', value)}
          options={tenantOptions}
        />
        <FormControlCard
          form={props.form}
          field="billable"
          formControl="SelectInput"
          title='Toll free number'
          subtitle='Is this number a toll free number?'
          submitForm={changeFormValue}
          options={booleanOptions}
        />
        <FormControlCard
          form={props.form}
          field="enabled"
          formControl="SelectInput"
          title='Enabled'
          subtitle='Is this number enabled or disabled on the system?'
          submitForm={changeFormValue}
          options={booleanOptions}
        />
        <FormControlCard
          form={props.form}
          field="smsenabled"
          formControl="SelectInput"
          title='SMS Enabled'
          subtitle='Can this number SMS?'
          submitForm={changeFormValue}
          options={booleanOptions}
        />
        <FormControlCard
          form={props.form.voicecarrier === '' ? noneOption : props.form}
          disabled={isSuperDuperAdmin(props.token) === false}
          field={props.form.voicecarrier === '' ? 'value' : 'voicecarrier'}
          formControl="SelectInput"
          title='Voice Carrier'
          subtitle='The carrier that handles the voice traffic'
          submitForm={(field, value) => changeFormValue('voicecarrier', value)}
          options={[{ label: 'No Voice Carrier', value: 'none' }].concat(carrierOptions)}
        />
        <FormControlCard
          form={props.form.smscarrier === '' ? noneOption : props.form}
          disabled={isSuperDuperAdmin(props.token) === false}
          field={props.form.smscarrier === '' ? 'value' : 'smscarrier'}
          formControl="SelectInput"
          title='SMS Carrier'
          subtitle='The carrier that handles the SMS traffic'
          submitForm={(field, value) => changeFormValue('smscarrier', value)}
          options={[{ label: 'No SMS Carrier', value: 'none' }].concat(carrierOptions)}
        />
        <FormControlCard
          form={props.form}
          field="porting_in_progress"
          formControl="SelectInput"
          title='Is Porting?'
          subtitle='Is this number currently being ported?'
          submitForm={changeFormValue}
          options={booleanOptions}
        />
        <FormControlCard
          form={props.form}
          field="is_domestic"
          formControl="SelectInput"
          title='Is Domestic'
          subtitle='Is this number domestic?'
          submitForm={changeFormValue}
          options={booleanOptions}
        />
        <Footer
          disabled={false}
          onClick={onSubmit}
        />
      </StandardSettingsPage>
    </div>
  )
}

const styles = {
  wrapper: {
    display: 'flex',
    flex: 1,
    height: '100%',
    background: '#EFEFEF',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '50px',
    overflow: 'auto',
  },
}

const bindActions = (dispatch) => ({
  postMasterHttp: (reqData, storeKey) => dispatch(postMasterHttp(reqData, storeKey)),
  changePhonenumberFormData: (data) => dispatch(changePhonenumberFormData(data)),
  clearPhonenumberFormData: () => dispatch(clearPhonenumberFormData()),
});

const mapStateToProps = state => ({
  form: state.phoneNumbersManager.newPhoneNumberForm,
  tenants: state.phoneNumbersManager.tenants,
  carriers: state.phoneNumbersManager.carriers,
  token: state.token,
});

CreatePhoneNumberForm.propTypes = {
  form: PropTypes.oneOfType([() => null, PropTypes.object]),
  tenants: PropTypes.array.isRequired,
  carriers: PropTypes.array.isRequired,
  token: PropTypes.object,
}

export default connect(mapStateToProps, bindActions)(CreatePhoneNumberForm);