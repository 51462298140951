import React, { useState, useEffect } from 'react';
import StandardSettingsPage from '../../presentational/StandardSettingsPage';
import Footer from '../../presentational/Footer';
import FluentReactTable from '../../presentational/reactTable/index';
import { useShortCodes } from './shortCodesContext';
import { csvHelper } from '../../utils/csvHelper';
import useShortCodesActions from './useShortCodesActions';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
const UploadShortCodes = () => {
    // state
    const [tableDataArray, setDataArray] = useState(null);
    const [uploadedTableData, setTableData] = useState(null);
    const [mediaQuery, setMediaQuery] = useState(false);
    // context
    const {
        bulkInputRef,
        buttonDisabled,
        createStoreKey,
        onCancel,
        setButtonDisabled,
        validateValues,
    } = useShortCodes();
    const { postCoreHttpHook } = useShortCodesActions();

    useEffect(() => {
        const mq = window.matchMedia('(min-width: 800px)');
        setMediaQuery(mq);
        const mediaQueryListener = query => setMediaQuery(query);
        mq.addListener(mediaQueryListener);
        return mq.removeListener(mediaQueryListener);
    }, []);

    const submitFullForm = cancel => {
        // let's only upload the data that is checked, and take out un-needed fields
        if (cancel) {
            onCancel();
        } else {
            const filteredData = tableDataArray.reduce((acc, code) => {
                const { checked, name, codeNumber, forwardingNumber } = code;
                checked ? acc.push({ codeNumber, name, forwardingNumber }) : null;
                return acc;
            }, []);
            const reqData = {
                reqAction: 'shortcodes',
                reqObject: 'bulkupload',
                reqBody: filteredData,
            };
            postCoreHttpHook(reqData, createStoreKey);
            bulkInputRef.current.value = null;
            setDataArray(null);
            setTableData(null);
            setButtonDisabled(true);
        }
    };

    const formatDataTableRows = data => {
        const formattedData = data.reduce(validateValues, {});
        const dataArray = Object.values(formattedData);
        setTableData(formattedData);
        setDataArray(dataArray);
        setButtonDisabled(false);
    };

    const check = e => {
        const idNum = e.target.id;
        const clicked = uploadedTableData[idNum];
        clicked.checked = !clicked.checked;
        clicked.checked
            ? (clicked.reason = 'Will be uploaded')
            : (clicked.reason = 'Will not be uploaded');
        setTableData({ ...uploadedTableData, [idNum]: clicked });
    };

    // the table headers for the uploaded data table
    const headers = [
        { Header: 'Name', accessor: 'name', maxWidth: 140 },
        { Header: 'Code', accessor: 'codeNumber', maxWidth: 55 },
        { Header: 'Forwarding #', accessor: 'forwardingNumber', maxWidth: 110 },
        { Header: 'Reason', accessor: 'reason' },
    ];

    const minWidth = mediaQuery.matches;
    const style = {
        card: {
            width: minWidth ? '50%' : '100%',
            minWidth: '400px',
        },
        cardContent: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        imageStyle: {
            height: '48px',
            align: 'left',
        },
        wrapper: {
            display: 'flex',
            flex: 1,
            height: '100%',
            background: '#EFEFEF',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '50px',
            overflow: 'auto',
        },
    };

    return (
        <div style={style.wrapper}>
            <StandardSettingsPage>
                <div style={style.card}>
                    <Card>
                        <CardHeader title="Bulk Upload Codes" />
                        <CardContent style={{ width: '100%' }}>
                            <div style={style.cardContent}>
                                <img
                                    className="import-logo"
                                    style={style.imageStyle}
                                    title="Google"
                                    src="../img/contacts-google.png"
                                    alt="google contact image"
                                ></img>
                                <article>
                                    <strong>"Import Short Codes from a CSV" </strong>
                                    <p>
                                        "Accepted format: Description, Code, Forwarding Number: +1
                                        (222) 444-5555"
                                    </p>
                                </article>
                                <input
                                    onChange={e =>
                                        csvHelper(
                                            e.target.files[0],
                                            3,
                                            setButtonDisabled,
                                            ['name', 'codeNumber', 'forwardingNumber'],
                                            formatDataTableRows,
                                        )
                                    }
                                    type="file"
                                    name="newFormUpload"
                                    id="upload-form"
                                    ref={bulkInputRef}
                                />
                            </div>
                            {tableDataArray ? (
                                <FluentReactTable
                                    tableTypeTitle={
                                        <Checkbox color="primary" checked={true} disabled={true} />
                                    }
                                    data={tableDataArray}
                                    tableType="checkbox"
                                    onChange={check}
                                    id="codeNumber"
                                    disabled="disabled"
                                    checked="checked"
                                    headers={headers}
                                    tableTypeTitleWidth={45}
                                />
                            ) : null}
                        </CardContent>
                    </Card>
                </div>
                <Footer onClick={cancel => submitFullForm(cancel)} disabled={buttonDisabled} />
            </StandardSettingsPage>
        </div>
    );
};

UploadShortCodes.defaultProps = {
    buttonDisabled: false,
    postData: () => null,
    createStoreKey: '',
    onCancel: () => null,
    postData: () => null,
    setButtonDisabled: () => null,
    validateValues: () => null,
};

UploadShortCodes.propTypes = {
    buttonDisabled: PropTypes.bool.isRequired,
    createStoreKey: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    setButtonDisabled: PropTypes.func.isRequired,
    validateValues: PropTypes.func.isRequired,
};

export default UploadShortCodes;
