import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Tooltip } from '@material-ui/core';

function ValidatedAddressIcon(props) {
    return (
        <Tooltip title="Address has been validated for 911.">
            <CheckCircleOutlineIcon color="primary" />
        </Tooltip>
    );
}

export default ValidatedAddressIcon;
