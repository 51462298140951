import { combineReducers } from 'redux';


import { HTTP_FAILURE, HTTP_PENDING, HTTP_SUCCESS } from '../actions/typeConstants';

import { autoAttendants } from './autoAttendants';
import { callGroups } from './callGroups';
import { conferenceRecords } from './conferenceRecords';
import { configurations } from './configurations';
import { contacts } from './contacts';
import { devices } from './devices';
import { dynamicAgents } from './dynamicAgents';
import { extensions } from './extensions';
import { http } from './http';
import { incomingCallFilters } from './incomingCallFilters';
import { locations } from './locations';
import { loginManagement } from './loginManagement';
import { multicastGroups } from './multicastGroups';
import { navigation } from './navigation';
import { phoneOverrides } from './phoneOverrides';
import { postCallSurveys } from './postCallSurveys';
import notificationWorkflow from './notificationWorkflows';
import { queueRecords } from './queueRecords';
import { queues } from './queues';
import { recordings } from './recordings';
import { reportsConfigurations } from './reportsConfigurations';
import { reportsData } from './reportsData';
import { sms } from './sms';
import { tenantChangesReport } from './tenantChangesReport';
import { token } from './token';
import { voicemail } from './voicemail';
import shortCodes from './shortCodes';
import conferenceRoom from './conferenceRoom';
import phoneNumbers from './phoneNumbers';
import callBlocking from './callBlocking';
import callForwarding from './callForwarding';
import callRecording from './callRecording';
import zohoIntegration from './zohoIntegration';
import callScreening from './callScreening';
import phoneNumbersManager from './phoneNumbersManager';
import voicemailDrop from './voicemailDrop';
import userSettings from './userSettings';
import createFax from './createFax';
import faxInbox from './faxInbox';

const appReducer = combineReducers({
    autoAttendants,
    callGroups,
    conferenceRecords,
    configurations,
    contacts,
    devices,
    dynamicAgents,
    extensions,
    http,
    locations,
    loginManagement,
    incomingCallFilters,
    multicastGroups,
    notificationWorkflow,
    navigation,
    phoneOverrides,
    postCallSurveys,
    queueRecords,
    queues,
    recordings,
    reportsConfigurations,
    reportsData,
    sms,
    token,
    tenantChangesReport,
    voicemail,
    conferenceRoom,
    phoneNumbers,
    callBlocking,
    callForwarding,
    callRecording,
    zohoIntegration,
    shortCodes,
    callScreening,
    phoneNumbersManager,
    voicemailDrop,
    userSettings,
    createFax,
    faxInbox,
});

// Root reducer to handle http state
const rootReducer = (state, action) => {

    // TODO: FIX SMS DATA TO USE NEW REDUX
    if (action && action.storeKey && action.storeKey.toUpperCase() === 'SMSDATA') {
        return appReducer(state, action);
    }

    switch (action.type) {

        case HTTP_SUCCESS:

            action.type = `${action.storeKey.toUpperCase()}_HTTP_SUCCESS`;
            return appReducer(state, action);

            break

        case HTTP_FAILURE:

            action.type = `${action.storeKey.toUpperCase()}_HTTP_FAILURE`;
            return appReducer(state, action);

            break

        case HTTP_PENDING:

            action.type = `${action.storeKey.toUpperCase()}_HTTP_PENDING`;
            return appReducer(state, action);

            break

        default:

            return appReducer(state, action);
    }
};

export default rootReducer;
