import {
    CHANGE_TENANT,
    INVALIDATE_HTTP_DATA,
} from 'actions/typeConstants';

const defaultState = {
    data:[],
    pending:false,
    failure:false,
}

export const devices = (state = defaultState, action) => {

    let newState = Object.assign({}, state);

    switch (action.type) {

        case action.type === "CHANGE_TENANT":
        case action.type === "INVALIDATE_HTTP_DATA":

            return null;

        case  'DEVICES_HTTP_FAILURE'  :

            newState.pending = false;
            newState.failure = true;

            return newState

        case  'DEVICES_HTTP_PENDING'  :

            newState.pending = true;
            newState.failure = false;

            return newState

        case 'DEVICES_HTTP_SUCCESS':

            newState.pending = false;
            newState.failure = false;


            if(action.reqType === "GET" && action.data){

                if( action.data.data ){
                    newState.data = action.data.data
                }else{
                    console.warn('unexpected return from devices api')
                }
            }

            if( action.reqType === "POST" && action.data){
                if(!newState.data.find( device => device.name === action.data.name )){
                    newState.data.push(action.data)
                }
            }

            if( action.reqType === "PUT" && action.data){

                let updatedItemIndex = newState.data.indexOf( device => device.name === action.data.name )

                if(updatedItemIndex > -1){
                    newState.data[updatedItemIndex] = action.data
                }else{
                    newState.data.push(action.data);
                }
            }

            if( action.reqType === "DELETE" && action.data){

                let updatedItemIndex = newState.data.indexOf( device => device.name === action.data.name )

                if(updatedItemIndex > -1){
                    delete newState.data[updatedItemIndex]
                }
            }

            break;

    };

    return newState
};
