import React, { Component } from 'react';
import { connect } from 'react-redux';

import ErrorCard from 'presentational/ErrorCard';
import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';
import Footer from 'presentational/Footer';
import Person from '@material-ui/icons/Person';
import PersonIcon from '@material-ui/icons/PersonOutline';
import PasswordIcon from '@material-ui/icons/Security';
import ContactPhone from '@material-ui/icons/ContactPhone';
import EmailIcon from '@material-ui/icons/Email';
import { getCoreHttp } from 'actions/httpRequest';

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            form: {},
            submitError: null,
            unique_username: false,
            userNameEdited: false
        };
    }
  
    componentDidMount() {

      if (this.props.data){
        let form = this.props.data;

        if(!this.props.newUser){
          form.dynamicagentid = this.getDynamicAgentId(this.props);
        }

        this.setState({ form });
      }
      let { newUser } = this.props;
      let { unique_username } = this.state;

      //* If we are updating an existing user, let's not let them update the username.
      if (!newUser && unique_username) {
        this.setState({ unique_username: false })
      }

    }

    componentDidUpdate () {
      let { newUser } = this.props;
      let { userNameEdited, unique_username } = this.state;

      //* If we Switched to a new user form, lets make sure we make the username match the 
      //* email unless they have made changes to the username.
      if (!userNameEdited && newUser && unique_username) {
        this.setState({ unique_username: false })
      }
      
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.data) {
            let form = nextProps.data;
            if (form.groups) {
              if (form.groups[0] == "cloudadmin") {
                form.groups = "Administrator"
              } else if (form.groups[0] == "cloudusers") {
                form.groups = "Cloud User"
              } else if (form.groups == "partneradmin" && (app.token.get("role") === "superduperadmin" || app.token.get("role") === "partneradmin")) {
                form.groups = "Partner Admin"
              }
            }
            if(!nextProps.newUser){
              form.dynamicagentid = this.getDynamicAgentId(nextProps);
            }

            this.setState({ form });
        }
    }

    getDynamicAgentId(props){

      console.log("[[INFO] loginmanagement.settings.getdynamicagentid] Getting Dynamic Agent ID");

      var dynamicagentid = "";

      if (props.configurations && props.configurations.dynamicagents) {
        props.configurations.dynamicagents.map(agent => {
          if (agent.username == props.data.userName) {
            if (dynamicagentid) {
              dynamicagentid += ", " + agent.agent_id;
            } else {
              dynamicagentid += agent.agent_id;
            }
          }
        })
      }

      return dynamicagentid;
    }

    submitForm(field, data) {

        if (this.state.form[field] == data) {
            console.log('No update');
            return;
        }

        const { form, userNameEdited, unique_username } = this.state;
        const { newUser } = this.props;
        const rollbackForm = Object.assign({}, form);

        // Lets validate our field
        const error = this.validateField(field, data);
        if (error) {
            this.setState({
                errors: { [field]: error },
                form: rollbackForm,
            });
            return;
        }

        if (field == 'mail' && !userNameEdited && newUser || field == 'mail' && !unique_username && newUser) {
          form['userName'] = data;
        }

        // If user changes userName so it no longer matches email set flag so changing email no longer updates userName
        if (field == 'userName') {
          this.setState({ 
            unique_username: true,
            userNameEdited: true
          });
        }

        // If no error, lets set the data
        // on the form and set it to state without errors
        form[field] = data;
        this.setState({
            form,
            errors: { [field]: '' },
        });
    }

    validateField(field, data) {
        // We want to make sure our wait
        // times are actually numbers
        // and greater than 0
        switch (field) {
        default:
            return null;
        }
    }

    submitFullForm(cancel){
      if(cancel){
        this.props.onCancel();
      } else{
        this.props.submitForm(this.state.form);
      }
    }


    render() {

        if (this.props.newUserBypass || !this.props.data || !this.state.form) {
            return (
              <StandardSettingsPage>
                <Loading />
              </StandardSettingsPage>
            );
        }

        const style = {
            submit: {
                marginTop: 20
            }
        }

        const twinForm = {
            firstField: this.props.data.firstName || '',
            secondField: this.props.data.lastName || ''
        }
        const passTitle = this.props.newUser ? "Password" : "Password Reset"
        

        let userRoles = ["Administrator", "Cloud User"];
        let userRoleValues = ["cloudadmin", "cloudusers"];
        
        if (app.token.get("role") === "superduperadmin" || app.token.get("role") === "partneradmin"){
          userRoles.unshift("Partner Admin");
          userRoleValues.unshift("partneradmin");
        }
        userRoleValues.forEach(role => {
          if (this.state.form.groups && this.state.form.groups !== role && !this.state.form.groups.includes(role) && !userRoleValues.includes(this.state.form.groups) && !userRoleValues.includes(this.state.form.groups[0]) && !userRoles.includes(this.state.form.groups)) {
            userRoles.push(this.state.form.groups)
          }
        });

        return (
          <div style={{
            display: 'flex',
            flex: 1,
            height: '100%',
            background: '#EFEFEF',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '50px',
            overflow: 'auto',
          }}>
          <StandardSettingsPage>
            {this.state.submitError ?
              <ErrorCard data={this.state.submitError} />
              : null
            }
            <FormControlCard
              form={this.state.form}
              field="twinForm"
              twinForm={twinForm}
              fieldOne="firstName"
              fieldTwo="lastName"
              formControl="TwoTextInput"
              title="Name"
              firstFieldTitle="First Name"
              secondFieldTitle="Last Name"
              subtitle=""
              icon={<PersonIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
            />
            {!this.state.form.New &&
            <FormControlCard
              form={this.state.form}
              field="mail"
              formControl="TextInput"
              title="Email"
              subtitle=""
              icon={<EmailIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
            />
            }
            <FormControlCard
              disabled={this.props.disableNameChange}
              form={this.state.form}
              field="userName"
              value = {this.state.form.userName}
              formControl="TextInput"
              title="Username"
              icon={<Person />}
              subtitle="Usernames must be your email and at least 6 characters long"
              submitForm={(field, data) => this.submitForm(field, data)}
            />
            <FormControlCard
              form={this.state.form}
              field="groups"
              formControl="SelectInput"
              title="User Group"
              subtitle="User group associated with the user"
              icon={<PersonIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
              options={userRoles}
            />
            <FormControlCard
              form={this.state.form}
              field="extension"
              formControl="TextInput"
              title="Primary Extension"
              subtitle=""
              icon={<ContactPhone />}
              submitForm={(field, data) => this.submitForm(field, data)}
            />
            {this.state.form.dynamicagentid && 
            <FormControlCard
              disabled={true}
              form={this.state.form}
              field="dynamicagentid"
              formControl="TextInput"
              title="Agent ID"
              subtitle=""
              icon={<PersonIcon />}
            />
            }
            <FormControlCard
              form={this.state.form}
              field="pass"
              newPassword="newPassword"
              confirmPassword="confirmPassword"
              sendEmail= "sendEmail"
              formControl="PasswordReset"
              newPasswordTitle="New Password"
              confirmPasswordTitle="Confirm Password"
              title={passTitle}
              subtitle="Use the form below to reset the user's password.
              Passwords must be at least 8 characters and contain 1 number."
              icon={<PasswordIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
              newUser={this.props.newUser}
            />
            <Footer
              onClick={(cancel) => this.submitFullForm(cancel)}
            />
          </StandardSettingsPage>
          </div>
        );
    }
}

const bindActions = (dispatch) => ({
    getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
});

const mapStateToProps = (state) => ({
  configurations: state.http.configurations,
});

export default connect(mapStateToProps, bindActions)(Settings);
