import React from 'react';
import { connect } from 'react-redux';

import ConversationInput from './conversationInput';
import ChatWindow from './chatWindow';


const SmsConversationView = (props) => {

    const conversationWrapper = {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        wordBreak: 'break-all',
        opacity: props.selectedInternalDid ? 1 : 0.2,
    };

    return (
      <div style={conversationWrapper}>
        <ChatWindow />
        <ConversationInput />
      </div>
    );
};

const mapStateToProps = (state) => ({
    selectedInternalDid: state.sms ? state.sms.selectedInternalDid : null,
});

export default connect(mapStateToProps)(SmsConversationView);
