import React from 'react';
import PropTypes from 'prop-types';
import StartIcon from '@material-ui/icons/Star';
import InfiniteScroll from 'react-infinite-scroll-component';

import { makeStyles } from '@material-ui/styles';

const noData = {
    textAlign: 'center',
    paddingTop: 50,
};

const LeftBarList = (props) => {

    if (!props.data) {
        return (
          <a
            href=""
            style={noData}
            onClick={(e) => {
                e.preventDefault();
                props.addNew();
            }}
          >
            Add new
          </a>
        );
    }

    const classes = makeStyles({
      list: {
        position: 'float',
        paddingBottom: 100,
        height: '65%',
        width: '100%',
        overflowY: 'scroll',
      },
    })();


    let listItems = [];
    listItems = props.data.map((item) => {
      let secondaryText = '';
      if(props.noDescription){
        secondaryText = '';
      }else if(item.subtitle){
        secondaryText = <span style={{ paddingTop: '0px', paddingBottom: '0px', opacity: '.6', fontSize: '80%' }}>{item.subtitle}</span>;
      } else {
        secondaryText = <span style={{ paddingTop: '0px', paddingBottom: '0px', opacity: '.6', fontSize: '80%' }}>No Description</span>;
      }
      if (item.tag) {
        switch (item.tag) {
          case 'user':
            secondaryText = (
                <span
                    style={{
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        color: 'white',
                        fontSize: '70%',
                    }}
                    className="label label-extension"
                >
                    {item.subtitle}
                </span>
            );
            break;
          case 'route':
            secondaryText = <span style={{ paddingTop: '0px', paddingBottom: '0px', color: 'white', fontSize: '70%' }} className="label label-route">{item.subtitle}</span>
            break;
          case 'managed':
            secondaryText = <span style={{ paddingTop: '0px', paddingBottom: '0px', color: 'white', fontSize: '70%' }} className="label label-managed-route">{item.subtitle}</span>
            break;
          case 'conference':
            secondaryText = <span style={{ paddingTop: '0px', paddingBottom: '0px', color: 'white', fontSize: '70%' }} className="label label-conference">{item.subtitle}</span>
            break;
          case 'callforwarding':
            secondaryText = <span style={{ paddingTop: '0px', paddingBottom: '0px', color: 'white', fontSize: '70%' }} className="label label-forwarding">{item.subtitle}</span>
            break;
        }
      }

      //If you want to enable multiselect (shift + click) on a new page,
      //you must pass selectedItem as an array, even if it's one value
      let selectedItems = [];
      let newNumbers = props.selectedItem;

      if (newNumbers){
        if (typeof props.selectedItem === "string"){
          selectedItems.push(props.selectedItem);
        } else {
          newNumbers.forEach((item) => {
            selectedItems.unshift(item);
          })
        }
      }


      return(
        <li
          key={item.id}
          style={
            selectedItems.includes(item.id) ?
              { userSelect:'none', cursor: 'pointer', backgroundColor: '#DFDFDF', padding: '15px 50px', fontSize: '120%' } : { userSelect:'none', cursor: 'pointer', padding: '15px 50px', fontSize: '120%' }
          }
          onClick={(e) => props.onSelect(item.id, e)}
        >
          <div>{item.title}{item.porting ? " (Porting)" : ""}{item.temporary ? " (Temporary)" : ""}{item.favorite ? <StartIcon style={{ color: 'gold', height: '17px' }} /> : null}</div>
          {secondaryText}
        </li>
      )
    });

    const { dataLength } = props;

    if (props.useInfiniteScroll) {
      return (
        <ul
          style={{ listStyle: 'none', overflowY: 'scroll', padding: 0, marginTop: 10 }}
          className="custom-scrollbar-react"
          id="infiniteScroll"
        >
          <InfiniteScroll
            dataLength={dataLength}
            next={props.loadMore}
            hasMore={listItems.length > dataLength}
            loader={<h4>{listItems.length === 0 ? '' : 'Loading...'}</h4>}
            scrollableTarget="infiniteScroll"
          >
            {listItems.slice(0, dataLength)}
          </InfiniteScroll>
        </ul>
      );
    }

    return (
      <ul
        style={{ listStyle: 'none', overflowY: 'scroll', padding: 0, marginTop: 10 }}
        className="custom-scrollbar-react"
      >
        {listItems}
      </ul>
    );
};

LeftBarList.defaultProps = {
  data: null,
  onSelect: () => console.log('No on select function passed'),
  addNew: () => console.log('No add new function passed'),
  selectedItem: null,
  noAdd: false,
  useInfiniteScroll: false,
  dataLength: 50,
  loadMore: () => console.log('No load more function passed'),
};

LeftBarList.propTypes = {
  data: PropTypes.array,
  onSelect: PropTypes.func,
  addNew: PropTypes.func,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  useInfiniteScroll: PropTypes.bool,
  dataLength: PropTypes.number,
  loadMore: PropTypes.func,
};


export default LeftBarList;
