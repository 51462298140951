import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import SaveChangesModal from 'presentational/SaveChangesModal';
import DragAndDrop from 'presentational/DragAndDrop';
import Footer from 'presentational/Footer';

import AddIcon from '@material-ui/icons/AddBox';
import CallerIDIcon from '@material-ui/icons/PhoneInTalk';
import PhoneLinkEraseIcon from '@material-ui/icons/PhonelinkErase';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import BlockIcon from '@material-ui/icons/Block';

import { errorNotification, successNotification } from 'actions/notifications';

const DEFAULT_COUNTRY_CODE = '+1';

class CallBlockingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        number: DEFAULT_COUNTRY_CODE,
        cbk_block_no_callerid: '0',
        cbk_block_methodology: 'sendtovm',
        cbk_numbers: [],
      },
      rollBackForm: null,
      changed: false,
      showingSaveChangesModal: false,
      countryCode: DEFAULT_COUNTRY_CODE,
    }
    this.addedUnsavedListener = false;
  }

  componentDidMount() {
    if(this.state.rollBackForm){
      this.setState({
        form: {...this.state.form, ...this.props.form},
      });
    } else {
      const rollBackForm = {...this.props.form};
      rollBackForm.cbk_numbers = [...this.props.form.cbk_numbers]
      this.setState({
        form: {...this.state.form, ...this.props.form},
        rollBackForm,
      });
    }
    if(this.addedUnsavedListener === false){
      this.clickedElement();
      this.addedUnsavedListener = true;
    }
  }

  clickedElement = () => {
    var elements = document.querySelectorAll(".header, .subnav");
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", () => {
        this.unsavedChanges();
      });
    }
  }

  unsavedChanges = () => {
    if (this.state.changed){
      this.setState({
        showingSaveChangesModal: true
      });
    }
  }

  addNumberToList = () => {
    const { form } = this.state;
    const index = form.cbk_numbers.findIndex(cbkNumber => cbkNumber === form.number);
    if (index === -1) {
      if (form.number == null || parsePhoneNumberFromString(form.number).isValid() === false) {
        errorNotification({
          title: 'Failed', message: 'Enter a valid number',
        });
      } else {
        form.cbk_numbers.push(form.number);
        form.number = this.state.countryCode;
        this.setState({ form, changed: true });
      }
    } else {
      errorNotification({
        title: 'Failed', message: 'This number already exist in the list',
      });
    }
  }

  removeNumber = (number) => {
    const { form } = this.state;
    const newList = form.cbk_numbers.filter((content) => content !== number);
    form.cbk_numbers = newList;
    this.setState({ form, changed: true });
  }

  formEdit = (field, data) => {
    const { form } = this.state;
    form[field] = data;
    this.setState({ form, changed: field !== 'number' });
  }

  onSubmit = (cancel) => {
    if (cancel) {
      app.navigate('#userportal', { trigger: true });
      return;
    } else {
      this.props.editRequest(this.state.form)
      const rollBackForm = {...this.state.form};
      delete rollBackForm.number;
      rollBackForm.cbk_numbers = [...this.state.form.cbk_numbers];
      this.setState({
        rollBackForm,
        showingSaveChangesModal: false,
        changed: false,
      });
    }
  }

  revertChanges = () => {
    const form = {...this.state.rollBackForm};
    form.cbk_numbers = [...this.state.rollBackForm.cbk_numbers];
    this.setState({
      form,
      showingSaveChangesModal: false,
      changed: false,
    });
  }

  updateCountry = (field, data, countryData) => {
    const { dialCode } = countryData;
    this.setState({ countryCode: `+${dialCode}` });
    this.formEdit(field, data);
  }

  renderBlockedList = (list) => {
    const numbers = list || [];
    const itemIds = [];
    const numbersObjects = numbers.map((number, position) => {
      itemIds.push(position);
      return {
        [position]: number,
        id: number,
        content: number,
      };
    });
    const initialData = {
      items: numbersObjects,
      columns: {
        'right': {
          id: 'right',
          title: 'Selected phone numbers',
          itemIds,
        }
      },
      columnOrder: ['right']
    };
    return (
      <DragAndDrop
        updateOrder={() => {}}
        handleSelectedDeletion={(item) => this.removeNumber(item.id)}
        handleSelectedAddition={() => {}}
        data={initialData}
        placeholder="Search number"
        isDragDisabled={true}
      />
    );
  }

  render() {
    const { rollBackForm, changed } = this.state;
    // Create a copy of the form and delete number field from it to compare with the rollBackForm to track any change
    const copyOfForm = {...this.state.form};
    delete copyOfForm.number;
    if (changed && isEqual(copyOfForm, rollBackForm)) {
      this.setState({
        changed: false,
      });
    }
    return (
      <StandardSettingsPage>
        <FormControlCard
          form={this.state.form}
          field="number"
          formControl="TextFieldWithButton"
          title="Block callers:"
          subtitle=""
          icon={<AddIcon />}
          submitForm={(field, data, countryData) => { this.updateCountry(field, data, countryData) }}
          buttonLabel={<AddCircleIcon style={{ color: 'rgb(42, 114, 185)' }}/>}
          onClick={() => this.addNumberToList()}
          getCountryCode={(countryCode) => {
            const { form } = this.state;
            form.number = countryCode;
            this.setState({ countryCode, form })
          }}
        />
        <FormControlCard
          form={{ content: this.renderBlockedList(this.state.form.cbk_numbers)}}
          field="content"
          formControl="blocknumberlist"
          title="Blocked Numbers"
          subtitle='Note: All callers on this list will be blocked'
          icon={<BlockIcon/>}
        />
        <FormControlCard
          form={this.state.form}
          field="cbk_block_no_callerid"
          icon={<CallerIDIcon/>}
          formControl="RadioInput"
          column={true}
          title="Callers without caller ID:"
          subtitle=""
          submitForm={(field, data) => { this.formEdit(field, data) }}
          options={["Allow", "Block"]}
          values={['0', '1',]}
        />
        <FormControlCard
          form={this.state.form}
          field="cbk_block_methodology"
          icon={<PhoneLinkEraseIcon/>}
          formControl="RadioInput"
          column={true}
          title="When blocking calls:"
          subtitle=""
          submitForm={(field, data) => { this.formEdit(field, data) }}
          options={[`Send to voicemail`, `Play "busy" to caller`, `Play "torture" to caller`]}
          values={['sendtovm', 'playbusy', 'torture']}
        />
        <Footer
          disabled={isEqual(copyOfForm, rollBackForm)}
          onClick={(cancel) => this.onSubmit(cancel)}
          fullWidth={true}
        />
        <SaveChangesModal
          show={this.state.showingSaveChangesModal}
          saveChange={() => this.onSubmit(false)}
          onClose={() => this.onSubmit(true)}
          discardChange={() => this.revertChanges()}
        />
      </StandardSettingsPage>
    );
  }
}

const bindActions = (dispatch) => ({});

const mapStateToProps = () => ({});

CallBlockingForm.propTypes = {
  form: PropTypes.object,
  editRequest: PropTypes.func,
}

export default connect(mapStateToProps, bindActions)(CallBlockingForm);
