import React, { Component } from 'react';
import { connect } from 'react-redux';

import AuditHistory from 'presentational/AuditHistory';
import Loading from 'presentational/Loading';
import StandardSettingsPage from 'presentational/StandardSettingsPage';

const History = props => {
  let bodyContent;
  if (!props.history) {
    bodyContent = <Loading />;
  }
  else if (props.history.length === 0) {
    bodyContent = <h2>There is no edit history yet.</h2>;
  }
  else{
    bodyContent = <AuditHistory data={props.history} />;
  }

  return (
    <div style={{
      display: 'flex',
      flex: 1,
      height: '100%',
      background: '#EFEFEF',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '50px',
      overflow: 'auto',
    }}>
    <StandardSettingsPage>
      {bodyContent}
    </StandardSettingsPage>
    </div>
  );
};

const mapStateToProps = state => ({
  history: state.phoneNumbers.phoneNumberHistory
})

export default connect(mapStateToProps)(History);
