import get from 'lodash.get';
import {
  FAX_HTTP_SUCCESS,
  FAX_HTTP_PENDING,
  FAX_HTTP_FAILURE,
  CHANGEFOLDER_HTTP_SUCCESS,
  CHANGEFOLDER_HTTP_FAILURE,
  DELETEFAX_HTTP_FAILURE,
  EDITAFTERDELETEFAX_HTTP_FAILURE,
  EDITAFTERDELETEFAX_HTTP_SUCCESS
} from '../actions/typeConstants';

const initialState = {
  faxes: [],
  successMsg: null,
  errorMsg: null,
  pendingRequest: false,
  shouldUpdate: false,
  pages: 0,
  timezone: '',
}

export default function(state = initialState, action) {
  switch(action.type) {
    case FAX_HTTP_SUCCESS:
      return {
        ...state,
        successMsg: null,
        errorMsg: null,
        errorCode: null,
        pendingRequest: false,
        faxes: action.data.data,
        timezone: action.data.timezone,
        pages: action.data.pagecount,
      }
    case FAX_HTTP_PENDING:
      return {
        ...state,
        successMsg: null,
        errorMsg: null,
        errorCode: null,
        pendingRequest: true,
        shouldUpdate: false,
      }
    case CHANGEFOLDER_HTTP_FAILURE:
    case DELETEFAX_HTTP_FAILURE:
    case EDITAFTERDELETEFAX_HTTP_FAILURE:
    case FAX_HTTP_FAILURE:
      const error = get(action, 'error.response.text', 'Couldn\'t successfully process request')
      return {
        ...state,
        successMsg: null,
        errorMsg: error,
        pendingRequest: false,
      }
    case CHANGEFOLDER_HTTP_SUCCESS:
    case EDITAFTERDELETEFAX_HTTP_SUCCESS:
      return {
        ...state,
        successMsg: null,
        errorMsg: null,
        shouldUpdate: true,
      }
    default:
      return { ...state }
  }
}