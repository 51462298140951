import {
  CHANGE_PHONENUMBER_FORM_DATA,
  CLEAR_PHONENUMBER_FORM_DATA,
  RESET_FLAG,
  UPDATE_EDITPHONENUMBER_FORM_DATA,
  REVERT_EDITPHONENUMBER_FORM_DATA,
  DESELECT_SELECTED_NUMBER,
} from './typeConstants';

export const changePhonenumberFormData = (data) => ({
  type: CHANGE_PHONENUMBER_FORM_DATA,
  payload: data,
});

export const clearPhonenumberFormData = () => ({
  type: CLEAR_PHONENUMBER_FORM_DATA,
});

export const resetFlag = () => ({
  type: RESET_FLAG
});

export const changeEditPhonenumberFormData = (data) => ({
  type: UPDATE_EDITPHONENUMBER_FORM_DATA,
  payload: data,
});

export const revertEditPhonenumberFormData = () => ({
  type: REVERT_EDITPHONENUMBER_FORM_DATA,
});

export const deselectSelectedNumber = () => ({
  type: DESELECT_SELECTED_NUMBER,
});