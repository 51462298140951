import React, { Component } from 'react';
import { connect } from 'react-redux';

//Presentational Components
import SelectInput from 'presentational/formControls/SelectInput';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';
import TextInput from 'presentational/formControls/TextInput';
import ToggleInput from 'presentational/formControls/ToggleInput';


class AddEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            voicemailBox:'',
            settings:{},
            form:{},
            newForm:{
                emailaddress:'',
                attachfile:0,
                fileformat:'',
                transcribefile:'',
                country:[],
                timezone:[],
            },
            disabled:true,
            timezones: 'Please select a country',
        };
    }

    componentDidMount() {

        this.setState({ 
            vmSettings: this.props.vmEmailSettings[this.props.selectedVMEmail],   
            form: this.props.vmEmailSettings[this.props.selectedVMEmail],
            timezones: this.props.timezones,    
        });

        if (this.props && this.props.vmEmailSettings && this.props.vmEmailSettings[0] && 
            this.props.vmEmailSettings[0].timezone && !this.props.newFlag){
            this.fetchCountry();
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.selectedVoicemailBox) {
            this.setState({ 
                additionalsettings: nextProps.voicemailEmail,
                fileFormatTypes: this.props.fileFormatTypes,         
                timezones: this.props.timezones,
            });
        }

        if (nextProps.voicemailEmail && nextProps.voicemailEmail.timezone){
            this.fetchCountry();
        }
    }

    async submitForm(field, data) {

        let form = this.state.form;
        let newForm = this.state.newForm;

        if (field === "country"){
            this.fetchTimezones(data);
        }

        if (this.props.newFlag){
            newForm[field] = data;
            this.setState({ newForm });
            return this.props.prepNewVMEmailForm(field, data);
        }

        form[field] = data;
        this.setState({ form });
        this.props.updateForm(field, data);
    }

    fetchTimezones = (selectedCountry) => {
        let timezones = this.props.timezones;
        let selectedTimezones;

        timezones.forEach(function(country) {
            if (selectedCountry === country["country"]) {
                selectedTimezones = country.zones
            }
        })

        this.setState({ selectedTimezones });
    }

    fetchCountry(){
        //We don't store country in our DB, though we still
        //need to populate the select, if we have a timezone
        let selectedTimezone;
        let selectedCountry;

        if (this.props.vmEmailSettings[this.props.selectedVMEmail] && 
            this.props.vmEmailSettings[this.props.selectedVMEmail].timezone) {
            selectedTimezone = this.props.vmEmailSettings[this.props.selectedVMEmail].timezone;
        }

        this.props.timezones.map(timezone => {
            timezone.zones.map(zone => {
                if (zone.zone_name === selectedTimezone){
                    selectedCountry = zone.country_code;
                };
            });

            if (selectedCountry === timezone.code){
                this.setState({ country: timezone.country });
            }
        });
    }

    render() {

        let { fileFormatTypes } = this.props;
        let code;
        let countries = this.props.timezones.map(timezone => timezone.country);
        let timeZonesList = ['Please select a country'];

        if (this.state && this.state.selectedTimezones){
           timeZonesList = this.state.selectedTimezones.map(zones => zones.zone_name)
        }

        //If we just selected this VM Box, and we have a saved timezone, 
        //let's fetchTimezones for the other timezones in the selected country
        if (this.state && this.state.vmSettings && this.state.vmSettings.timezone && !this.state.selectedTimezones){
            const selectedTimezone = this.props.vmEmailSettings[this.props.selectedVMEmail].timezone;
            this.props.timezones.map(timezone => {
                timezone.zones.map(zone => {
                    if (zone.zone_name === selectedTimezone){
                        code = zone.country_code;
                    }
                })
                if (timezone.code === code ){
                    this.fetchTimezones(timezone.country);
                }
            });
        }


        if (!this.state && !this.props.newFlag || !this.state.vmSettings && !this.props.newFlag){
            return (
                <StandardSettingsPage>
                    <Loading />
                </StandardSettingsPage>
            );
        }

        return (
            <div>
                <TextInput
                    field="emailaddress"
                    placeholder="Email Address"
                    onChange={(data) => this.submitForm('emailaddress', data)}
                    value={this.props.newFlag? this.state.newForm.emailaddress : this.state.vmSettings.emailaddress}
                />
                <ToggleInput
                    field="attachfile"
                    placeholder="Attach Voicemail to Email?"
                    onChange={(data) => this.submitForm('attachfile', data)}
                    value={this.props.newFlag? this.state.newForm.attachfile : this.state.vmSettings.attachfile}
                />
                <SelectInput
                    field="fileformat"
                    placeholder="File Format"
                    options= {fileFormatTypes}
                    value={this.props.newFlag? this.state.newForm.fileformat : this.state.vmSettings.fileformat}
                    onChange={(data) => this.submitForm('fileformat', data)}
                />
                <br />
                <br />
                <br />
                 <ToggleInput
                    field="transcribefile"
                    placeholder="Enabling Transcription is $5/month charge unless you're on the FluentCloud Complete Plan"
                    onChange={(data) => this.submitForm('transcribefile', data)}
                    value={this.props.newFlag? this.state.newForm.transcribefile : this.state.vmSettings.transcribefile}
                />
                <SelectInput
                    field="country"
                    placeholder="Country"
                    options= {countries}
                    value={this.props.newFlag? this.state.newForm.country : this.state.country}
                    onChange={(data) => this.submitForm('country', data)}
                />
                <SelectInput
                    field="timezone"
                    placeholder="Time Zone"
                    options= {timeZonesList}
                    value={this.props.newFlag? this.state.newForm.timezone : this.state.vmSettings.timezone}
                    onChange={(data) => this.submitForm('timezone', data)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
});

const bindActions = (dispatch) => ({
});

export default connect(mapStateToProps, bindActions)(AddEmail);
