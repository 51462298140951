import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Button from '@material-ui/core/Button';
import makeGlobalStyles from 'makeGlobalStyles';
import { tenantHelper, getWhitelabelPartner } from '../../utils/whitelistHelper';

function HelpContent(props) {
  const classes = props.useStyles();
  const globalClasses = makeGlobalStyles();
  const partner = getWhitelabelPartner()

  let renderPhoneNumber = <div></div>
  if (tenantHelper[partner].en.contact.friendly_phone && tenantHelper[partner].en.contact.phone){
    renderPhoneNumber = <li>{`By calling ${tenantHelper[partner].en.contact.friendly_phone} (${tenantHelper[partner].en.contact.phone})`}</li>
  } else if (tenantHelper[partner].en.contact.phone){
    renderPhoneNumber = <li>{`By calling (${tenantHelper[partner].en.contact.phone})`}</li>
  }

  return (
    <div className={classes.wrapper}>
        <Card classes={{ root: classes.helpPaper }} elevation={1}>
          <CardContent classes={{ root: classes.helpContent }}>
            <CardContent>
              <h2 className={classes.pageTitle}>Voicemail Drop</h2>
              <p className={classes.helpText}>
                Voicemail Drop is a feature utilized to preprogram outgoing voicemail messages.
              </p>
            </CardContent>
            <CardContent classes={{ root: classes.helpTextTitle }}>
              About Voicemail Drop
            </CardContent>
            <CardContent>
              <div>
                <p>
                  Voicemail Drop is most commonly used when team members are making 
                  a high volume of outbound calls while leaving the same message over 
                  and over again for the recipients of those calls. This feature enables 
                  each user to preconfigure a list of the messages that they are leaving 
                  most frequently and allow for them to select a specific one per call.
                </p>
                <p>
                  This interface will allow for the configuration of these preconfigured 
                  recordings. A user may activate the use of Voicemail Drop in an active 
                  call through the use of the {tenantHelper[partner].en.name.generic} WebPhone, ClicktoCall+, or a feature code.
                </p>
              </div>
            </CardContent>
          </CardContent>
          <CardContent classes={{ root: classes.standardHelpBlurb }}>
            <CardContent>
              <h2 className={classes.helpTextTitle}>Need Help?</h2>
              <div>
                <p className={classes.helpText}>The whole team at {tenantHelper[partner].en.name.short} is ready to help you. 24 hours a day, 365 days a year.
                    You can reach us a number of ways.</p>
                <ul>
                  {renderPhoneNumber}
                  <li>By emailing <a href={`mailto:${tenantHelper[partner].en.contact.email}`}>{tenantHelper[partner].en.contact.email}</a></li>
                  <li>By clicking the button below!</li>
                </ul>
              </div>
              <p />
              <Button
                size='large'
                classes={{ root: globalClasses.helpButton }}                
                href="http://help.fluentcloud.com/support/solutions/articles/4000101787-how-to-set-up-voicemail-drop-recordings"
                target="_blank"
              >How To Set Up Voicemail Drop</Button>
            </CardContent>
          </CardContent>
        </Card>
        
      </div>
  );
}

HelpContent.propTypes = {
  useStyles: PropTypes.func,
}

export default HelpContent;