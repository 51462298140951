import React, { useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useWindowDimensions from '../utils/useWindowDimensions.js';

function StandardSettingsPageWithQuickGlance(props) {

  useEffect(() => {
    // The quick glance has some wierd interactions with smaller screens,
    // this will make the background of the <MasterView/> grey 
    // only when this component is used so we can avoid
    // having white space show up in the background
    const mainView = document.querySelector('.MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded');
    mainView.style.background = '#EFEFEF'
    return () => mainView.style.background = null;
  },[])

  const { height, width } = useWindowDimensions();
  const minWidth = useMediaQuery('(min-width: 1150px)');
  // on smaller screens the quick glance can be cut off, lets change the padding for this
  let quickGlancePaddingTop = '130px';

  if (height <= 760){
    quickGlancePaddingTop = '80px';
    if (width <= 1541){
      quickGlancePaddingTop = '50px'
    }
  }
  if (height > 760 && width > 1541) {
    quickGlancePaddingTop = '130px'
  }

  const style = {
    wrapper: {
      display: 'flex',
      flex: 1,
      width: minWidth ? '80%' : '100%',
      flexDirection: minWidth ? 'row':'column',
      background: '#EFEFEF',
      paddingTop: minWidth ? '50px' : 0,
      alignItems: 'center',
      paddingBottom: minWidth ? '75px' : 0,
    },
    formWrapper: {
      display: 'flex',
      flex: 1,
      width: minWidth ? '75%' : '100%',
      background: '#EFEFEF',
      flexDirection: 'column',
      alignItems: 'center',
    },
    quickGlanceWrapper: {
      display: 'flex',
      flex: 1,
      paddingBottom: '75px',
      position: minWidth ? 'absolute' : '',
      right: minWidth ? '0px' : '',
      paddingTop: minWidth ? quickGlancePaddingTop : '',
      background: '#EFEFEF',
      height: minWidth ? '180vh' : '',
      maxWidth: minWidth ? '300px' : '',
      top: '50px',
    },
  };

  return (
    <div style={style.wrapper}>
      <div style={style.formWrapper}>
        {props.children}
      </div>
      <div style={style.quickGlanceWrapper}>
        {props.quickGlance}
      </div>
    </div>
  );
}

export default StandardSettingsPageWithQuickGlance
