import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

const DeleteModal = (props) => {
    const classes = makeStyles({
      button: {
        fontSize: '100%',
      },
    })();
    return (
      <Dialog
        onClose={() => props.cancel()}
        open={props.open}
        fullWidth={true}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>{props.content}</DialogContent>
        <DialogActions>
          <Button
            classes={{ root: classes.button }}
            color='primary'
            onClick={() => props.cancel()}
          >Cancel</Button>
          <Button
            classes={{ root: classes.button }}
            color='primary'
            onClick={() => {
              props.delete();
            }}
          >Delete</Button>
        </DialogActions>
      </Dialog>
    );
};

DeleteModal.defaultProps = {
  delete: () => console.log('No delete function passed into Delete Modal'),
  cancel: () => console.log('No cancel function passed into Delete Modal'),
  title: 'Are you sure?',
  content: '',
  open: false,
};

DeleteModal.propTypes = {
  delete: PropTypes.func,
  cancel: PropTypes.func,
  content: PropTypes.any,
  title: PropTypes.any,
  open: PropTypes.bool,
};

export default DeleteModal;