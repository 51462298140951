import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { withStyles, mergeClasses } from '@material-ui/styles';

const styles = () => ({
  button: {
    fontSize: '100%',
  },
});

class SaveChangesModal extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={Boolean(this.props.show)}
        onClose={this.props.cancelModal}
        fullWidth={true}
      >
        <DialogContent>
          <div style={{ marginTop:'5px' }}>Would you like to save or discard your changes?</div>
        </DialogContent>
        <DialogActions>
          <Button
            classes={{ root: classes.button }}
            color='primary'
            onClick={this.props.saveChange}
          >Save Changes</Button>
          <Button
            classes={{ root: classes.button }}
            color='secondary'
            onClick={this.props.discardChange}
          >Discard Changes</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(SaveChangesModal);