import React from 'react';
import { connect } from 'react-redux';

import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import {
    newInternalDidSelected,
    updateTextForConversationSearch,
    selectConversation } from 'actions/sms';

const style = () => ({
    groupWrapper: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    searchWrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 10,
    },
    searchBox: {
        marginLeft: 25,
    },
    searchIcon: {
        color: 'rgba(115, 115, 115, .8)',
        marginTop: 20,
        marginLeft: 5,
    },
    selectedInternalDid: {
        paddingLeft: 20,
        paddingTop: 10,
        marginTop: -25,
    },
    title: {
        color: '#737373',
        paddingLeft: 20,
        paddingTop: 20
    },
    toolbar: {
        paddingLeft: 5,
        paddingBottom: 20,
        width: 350,
        height: 160,
        flexDirection: 'column',
    },
    contactsToolbar: {
      paddingLeft: 5,
      paddingBottom: 20,
      width: 350,
      height: 50,
      flexDirection: 'column',
  },

});

export const SmsToolbar = (props) => {
  const { classes } = props;
  switch (props.type) {

  case "conversationSearch":
    return (
      <Toolbar classes={{ root: classes.toolbar }}>
        <div
          className={classes.groupWrapper}
        >
          <Typography classes={{ root: classes.title }}><Box fontSize={12} component='span'>Selected internal number</Box></Typography><br />
          <h4 className={classes.selectedInternalDid} >{props.selectedInternalDid}</h4>
          <div style={{ height: '100%' }}>
            <div className={classes.searchWrapper}>
              <TextField
                classes={{ root: classes.searchBox }}
                fullWidth={true}
                placeholder={props.placeholder}
                InputLabelProps={{shrink: true}}
                label={props.label}
                onChange={(e, text) => props.updateTextForConversationSearch(e.target.value)}
              />
              <SearchIcon classes={{ root: classes.searchIcon }} />
            </div>
          </div>
        </div>
      </Toolbar>
    );

  default:
    return (
      <Toolbar classes={{ root: classes.contactsToolbar }}>
        <div
          className={classes.groupWrapper}
        >
          <div style={{ height: '100%' }}>
            <div className={classes.searchWrapper}>
              <TextField
                classes={{ root: classes.searchBox }}
                fullWidth={true}
                placeholder={props.placeholder}
                InputLabelProps={{shrink: true}}
                label={props.label}
                onChange={(e) => props.onChange(e.target.value)}
              />
              <SearchIcon classes={{ root: classes.searchIcon }} />
            </div>
          </div>
        </div>
      </Toolbar>
    );
  }
};

const mapStateToProps = (state) => ({
    selectedInternalDid: state.sms ? state.sms.selectedInternalDid : null,
});

const bindActions = (dispatch) => ({
    newInternalDidSelected: (number) => dispatch(newInternalDidSelected(number)),
    updateTextForConversationSearch: (text) => dispatch(updateTextForConversationSearch(text)),
    selectConversation: (conversation) => dispatch(selectConversation(conversation)),
});

export default connect(mapStateToProps, bindActions)(withStyles(style)(SmsToolbar));
