import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

const SubmitButton = (props) => {
  const classes = makeStyles({
    button: {
      ...props.style,
      fontSize: '90%',
    },
  })();
  return (
    <Button
      disabled={props.disabled}
      classes={{ root: classes.button }}
      size='large' 
      variant="contained" 
      color='primary'
      onClick={() => props.onClick()}
    >{props.label}</Button>
  );
}

SubmitButton.defaultProps = {
    disabled: false,
    label: 'Submit',
    onClick: () => console.log('No onClick func passed into submit button'),
    style: null,
};

SubmitButton.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
};

export default SubmitButton;
