export const tenantHelper = {
    "fluentstream": {

        "en" : {
            "name" : {
                "short" : "FluentStream",
                "long" : "FluentStream",
                "possessive" : "FluentStream's",
                "generic": "FluentCloud",
                "portal": "MyFluentCloud"
            },

            "contact" : {
                "phone": "303-462-5683",
                "email": "support@fluentstream.com",
                "friendly_phone": "303-GO-CLOUD"
            },

            "general":{
                "settings": "Settings",
                "yes":"Yes",
                "no":"No",
                "custom":"Custom",
                "default":"Default",
                "block":"Block",
                "allow":"Allow",
                "save_changes":"Save Changes",
                "save": "Save",
                "delete": "Delete",
                "start": "Start",
                "cancel": "Cancel",
                "help": "Help",
                "account": "Account",
                "we": "We",
                "we_hope_you_love_us": "We hope you love us too,",
                "tenant": "Tenant",
                "turn": "Turn",
                "on": "On",
                "off": "Off",
                "call_blocking": "Call Blocking",
                "call_forwarding": "Call Forwarding",
                "call_recording": "Call Recording",
                "call_screening": "Call Screening",
                "zohointegration": "Zoho CRM Integration",
                "zohoclicktocall": "Click To Call for Zoho CRM",
                "lifestyle": "Lifestyle Call Attendant",
                "title": "Title",
                "clear": "Clear",
                "reset": "Reset",
                "play": "Play",
                "previous": "Previous",
                "next": "Next",
                "error": "Error",
                "to": "To:",
                "from": "From:",
                "send": "Send",
                "download": "Download",
                "show": "Show",
                "hide": "Hide",
                "close": "Close",
                "type": "Type",
                "email": "Email",
                "Inbox": "Inbox",
                "Sent": "Sent",
                "Company": "Company",
                "Deleted": "Deleted",
                "inbox": "Inbox",
                "old": "Old",
                "work": "Work",
                "family": "Family",
                "friends": "Friends",
                "deleted": "Deleted",
                "trash": "Trash",
                "date": "Date",
                "pages": "Pages",
                "reference": "Reference",
                "caller id": "Caller ID",
                "sent to": "Sent to",
                "status": "Status",
                "fax": "Fax",
                "Inbound": "Inbound",
                "Outbound": "Outbound",
                "Answered": "Answered",
                "No Answer": "No Answer",
                "Failed": "Failed",
                "Busy": "Busy",
                "Conference": "Conference",
                "External": "External",
                "Feature Code": "Feature Code",
                "Internal": "Internal",
                "International": "International",
                "Queue": "Queue",
                "Fax": "Fax",
                "form_fields" : {
                    "primary_cell_phone": "Primary Cell Phone Number",
                    "email_address": "Email Address",
                    "did_for_sms": "Primary Phone Number for SMS"
                }
            },

            "view": {
                "header_main": {
                    // wrench icon menu
                    "admin_helper_wrench": {
                        "account_information": "Account Information",
                        "primary_server": "Primary Server\:",
                        "failover_server": "Failover Server\:",
                        "tertiary_failover": "Tertiary Failover\:",
                        "account_password": "Account Password\:",
                        "subscription_level": "Subscription Level\:"
                    },
                    // lightning icon menu
                    "admin_helper_lightning": {
                        "tenant_manager": "Tenant Manager",
                        "support_agents": "Support Agents",
                        "phone_number_manager": "Phone Number Manager",
                        "tenant_changes_report": "Tenant Changes Report",
                        "tenant_extensions": "Tenant Extensions",
                        "tenant_billing": "Tenant Billing",
                        "alert_notifications_contacts": "Alert Notifications Contacts",
                        "send_alert_notifications": "Send Alert Notification",
                        "reload_proxy": "Reload Proxy",
                        "all_invoices": "Tenant and Client Invoices"
                    },
                    // globe icon menu
                    "admin_helper_globe": {
                        "my_account_details": "My Account Details",
                        "browser_ip": "Browser IP Address:",
                        "tenant_name": "Tenant Name:"
                    },
                    // help icon menu
                    "admin_helper_help": {},
                    // account icon menu
                    "admin_helper_account": {
                        "call_notification_settings": "Call Notification Settings",
                        "reset_password": "Reset Password",
                        "settings": "Settings",
                        "billing_and_statements": "Billing and Statements",
                        "sign_out": "Sign Out"
                    },
                    // main nav bar
                    "main_nav_bar": {
                        "settings": "Settings",
                        "extensions": "Extensions",
                        "locations": "Locations",
                        "phone_numbers": "Phone Numbers",
                        "roles": "Roles",
                        "routes": "Routes",
                        "tags": "Tags",
                        "user_management": "User Management",
                        "voicemail_boxes": "Voicemail Boxes",
                        "features": "Features",
                        "auto_attendant": "Auto Attendant",
                        "call_flows": "Call Flows",
                        "call_groups": "Call Groups",
                        "conference_rooms": "Conference Rooms",
                        "feature_codes": "Feature Codes",
                        "hold_music": "Hold Music",
                        "incoming_call_filters":"Incoming Call Filters",
                        "live_conference_view": "Live Conference View",
                        "managed_routes": "Managed Routes",
                        "multicast": "Multicast Groups",
                        "notification_workflows": "Notification Workflows",
                        "parking_lots": "Parking Lots",
                        "post_call_surveys": "Post Call Surveys",
                        "queues": "Queues",
                        "queue_agents": "Queue Agents",
                        "recordings": "Recordings",
                        "time_conditions": "Time Conditions",
                        "devices": "Devices",
                        "phones": "Phones",
                        "button_configurations": "Button Configurations",
                        "reports": "Reports",
                        "dashboard": "Dashboard",
                        "email_reports": "Email Reports",
                        "fc_reports": "FluentCloud Reports",
                        "key_press_reports": "Key Press Reports",
                        "legacy_reports": "Legacy Reports",
                        "portal": "Portal",
                        "salesforce": "Salesforce",
                        "phone_overrides": "Settings"
                    },
                    // sub nav bar
                    "sub_nav_bar": {
                        "user_portal": "User Portal",
                        "call_history": "Call History",
                        "call_management": "Call Management",
                        "blocking": "Blocking",
                        "call_recording": "Call Recording",
                        "forwarding": "Forwarding",
                        "lifestyle_call_attendant": "Lifestyle Call Attendant",
                        "screening": "Screening",
                        "voicemail_drop": "Voicemail Drop",
                        "zoho": "Zoho Integration",
                        "contacts": "Contacts",
                        "import": "Import",
                        "manage": "Manage",
                        "shared": "Shared",
                        "fax": "Fax",
                        "create": "Create",
                        "inbox": "Inbox",
                        "voicemail": "Voicemail",
                        "greetings": "Greetings",
                        "sms": "SMS",
                        "user_settings": "User Settings",
                        "my_status": "My Status"
                    },
                    // referrals
                    "earn_referrals": "Our business is based on referrals and we appreciate anyone you send our way. You can earn up to $500 for every new customer you refer.",
                    "greeting_name": "Kerrin Parker, Chief Customer Officer",
                },

                "my_status":{},

                // #userportal
                "userportal": {
                    "user_portal": "User Portal",
                    "manage_settings": "Manage the settings that are most important to the end user of My FluentCloud.",
                    "about": "About the Portal",
                    "portal_paragraph1": "This portal can be used for extension setting and efax management.",
                    "portal_paragraph2": "You can set up call forwarding, manage voicemail settings, check call history, amongst many other features.",
                    "portal_paragraph3": "Configure these features for all extensions, groups of extensions, or for each individual extension.  Customize as little or as much as you like.",
                    "portal_paragraph4": "Also, send and receive efaxes here.  The portal allows for easy management of every incoming and outgoing fax.",
                    "need_help": "Need Help?",
                    "the_whole_team": "The whole team at FluentStream is ready to help you.  24 hours a day, 365 days a year.  You can reach us a number of ways.",
                    "bycalling": "By calling 303-GO-CLOUD (303-462-5683)",
                    "byemailing": "By emailing",
                    "byclicking": "By clicking the button below!",
                    "link_href": "mailto:support@fluentstream.com",
                    "link": "support@fluentstream.com",
                    "button_text": "Get Help From FluentStream"
                },
                // #callhistory
                "callhistory": {
                    "header": "Call Detail Records For Extension",
                    "show_filters": "Show Filters",
                    "entries": "Entries",
                    "show_totals": "Show Totals",
                    "call_totals": "Call History Totals",
                    "hide_filters": "Hide Filters",
                    "time_date": "Time and Date",
                    "direction": "Call Direction",
                    "result": "Call Result",
                    "type": "Call Type",
                    "number": "Phone Number",
                    "apply": "Apply Filter",
                    "reset": "Reset Filter",
                    "start_date": "Start Date:",
                    "end_date": "End Date:",
                    "min_time": "Min. Call Time:",
                    "max_time": "Max Call Time:",
                    "num_search": "Phone Number Search",
                    "excel": "Excel",
                    "csv": "CSV",
                    "download": "Download Recording",
                    "show": "Show",
                    "no_call_data": "No Call Data! ",
                    "stats_loading": "Call Stats Loading...",
                    "total_number": "Total Number of Calls: ",
                    "total_talk": "Total Talk Time: ",
                    "avg_talk": "Average Talk Time: ",
                    "inbound": "Inbound: ",
                    "outbound": "Outbound: ",
                    "inbound_tab": "Inbound",
                    "outbound_tab": "Outbound",
                    "none": "None",
                    "calls": "Calls",
                    "minutes": "Minutes",
                    "add_phone": "Add phone number or extension"
                },
                // #callblocking
                "callblocking": {
                    "heading": "Call Blocking Options",
                    "description": "Block and allow callers",
                    "toggle_button": "Turn on",
                    "block_callers": "Block callers:",
                    "note": "Note: ",
                    "all_callers": "All callers on this list will be blocked",
                    "callers": "Callers",
                    "without": "without",
                    "caller_id": "caller ID:",
                    "when_blocking_calls": "When blocking calls:",
                    "send_to_voicemail": "Send to voicemail",
                    "play_busy": "Play \"busy\" to caller",
                    "play_caller": "Play \"torture\" to caller"
                },
                // #callrecording
                "callrecording": {
                    "heading": "Call Recording Options",
                    "settings": "Settings for recording calls.",
                    "options": "Call Recording Options:",
                    "always": "Always Record Calls",
                    "key_sequence": "Record On Key Sequence #9"
                },
                // #callforwarding
                "callforwarding": {
                    "heading": "Call Forwarding Options",
                    "other_numbers": "Forward calls to other numbers",
                    "always_forward": "Always Forward",
                    "busy": "Forward on Busy",
                    "follow": "Follow Me",
                    "always_number": "Always Number:",
                    "always_forward_to": "Always forward to this number",
                    "on_busy": "On Busy Application:",
                    "when_following": "When Following Me:",
                    "simultaneously": "Ring my phone and follow me numbers simultaneously.",
                    "duration": "Ring Duration(seconds):",
                    "ring_then_forward": "Ring my phone and then forward my call if not answered.",
                    "dont_ring": "Don't ring my phone, just forward.",
                    "how_to": "How to Forward Calls:",
                    "call_all": "Call all my Follow Me numbers at the same time",
                    "call_each": "Call each Follow Me number one right after another.",
                    "follow_me_numbers": "Follow Me Numbers:",
                    "these_will_be": "These numbers will be called in the order below.<br>" +
                    "Drag and drop numbers to reorder.",
                    "notify_caller": "Notify Caller:",
                    "let_callers_know": "Let callers know they are being forwarded",
                    "id": "Caller ID:",
                    "use_original": "Use original caller&#39;s Caller ID when forwarding",
                    "confirm": "Confirm Call:",
                    "ask_if_forwarded": "Ask if you would like to receive a forwarded call",
                    "external": "External Calls Only:",
                    "select_option": "Select option"
                },
                // #callscreening
                "callscreening": {
                    "heading": "Call Screening Options",
                    "settings": "Settings for screening calls.",
                    "callers_with": "Callers <em>with</em> caller ID:",
                    "ask_user": "Ask user to say name",
                    "caller_announce": "Caller Announcement:",
                    "do_not_screen": "Do not screen these callers:",
                    "all_callers": "<strong>Note: </strong>All callers on this list will be exempt from screening",
                    "callers_without": "Callers <em>without</em> caller ID:",
                    "ask_callers_name": "Ask callers to say name",
                    "ask_callers_number": "Ask callers to say number",
                    "caller_announcement": "Caller Announcement:",
                    "this_is_recording": "This is the recording that will play to callers to ask for their name or number.<br>" +
                    "If set to default it will play the standard recording."
                },

                //#zohointegration
                "zohointegration": {
                    "heading": "Zoho CRM Integration",
                    "settings": "Manage your Zoho CRM Integration",
                    "client_id": "Zoho CRM Client ID",
                    "client_id_help": "Client ID provided by Zoho",
                    "client_secret": "Zoho CRM Client Secret",
                    "client_secret_help": "Client Secret provided by Zoho",
                },
                //#usersettings
                "usersettings": {
                    "user_settings": "User Settings",
                    "telegram_id": "Telegram ID",
                    "slack_user_id": "Slack ID",
                    "sms_to_email": "SMS to Email",
                    "enable_proactive_chat_notifications": "Enable Proactive Chat Notifications",
                    "enable_local_area_presence": "Enable Local Area Presence",
                    "portal_color_scheme": "Custom Portal Color Scheme",
                    "match_caller_id_if_possible": "Enable Local Area Presence",
                    "update_settings": "Update User Settings",
                    "select_a_color_scheme": "Select a color scheme",
                    "cell_phone": "Cell Phone Number",
                    "email": "Email Address",
                    "telegram": "@Telegram ID",
                    "slack": "Slack User ID",
                    "did_for_sms": "Number for SMS"
                },
                //# helper for contact templates
                "contactHelper": {
                    "im": "Instant Messaging",
                    "custom": "Custom",
                    "name": "Name",
                    "prefix": "Prefix",
                    "first": "First",
                    "middle": "Middle",
                    "last": "Last",
                    "suffix": "Suffix",
                    "orgs": "Organizations",
                    "org": "Organization",
                    "addresses": "Addresses",
                    "address": "Address",
                    "urls": "URLs",
                    "phone_numbers": "Phone Numbers",
                    "phone": "Phone",
                    "emails": "Emails",
                    "email": "Email",
                    "update": "Update",
                    "delete": "Delete",
                    "create": "Create",
                    "create_new": "Create New",
                    "details": "Contact Details",
                    "none_found": "No Contacts Found",
                    "favorites_first": "Favorites First",
                    "favorite": "Favorite",
                    "shared": "Shared",
                    "add": "Add",
                    "email_addresses": "Email Addresses",
                    "events": "Events",
                    "im_clients": "IM Clients",
                    "notes": "Notes",
                    "route_to_cell": "Route to Cell Phone",
                    "route_through": "Route a call to ",
                    "number": "through your cell phone?",
                    // NOTE: this link needs to be set with a whitelabeled value in the template, change asap
                    "change_cell": "Your cell phone number can be changed in your",
                    "user_settings": "User Settings",
                    "call": "Call",
                    "exit": "Exit",
                    "contact_details": "Contact Details",
                    "added_by": "Added By",
                    "quick_glance": "Quick Glance"
                },
                // #contacts-import
                "contacts_import": {
                    "integrations": "Import Contacts",
                    // Google Contacts - Feature removed due to google authorization not completed here - https://console.developers.google.com/apis/credentials/consent?authuser=0&project=my-fluentcloud  >
                    // "integrations": "Contacts Integrations",
                    // "google": "<strong>Google Contacts</strong> <br>" +
                    //           "Import contacts from your GMail account.",
                    // "synced": "Synced: ",
                    // "sync": "Sync",
                    // "never": "Never",
                    "honorific_pre": "Honorific Prefix",
                    "honorific_suff": "Honorific Suffix",
                    "first": "First",
                    "middle": "Middle",
                    "last": "Last",
                    "upload": "<strong>Upload Contacts</strong> <br>" +
                              "Import contacts from a CSV <br>" +
                              "Accepted format: First Name, Last Name, Email Type, Email, Phone Type, Phone Number",
                },
                // #contacts-leftbar
                "contacts_leftbar": {
                    "create": "Create Contact",
                    "groups": "Groups"
                },
                // #fax-create
                "fax_create": {
                    "create_fax": "Create a Fax",
                    "step1": "Step 1: Fax Details",
                    "step2": "Step 2: Upload Files",
                    "step3": "Step 3: Complete",
                    "to_phone": "To Phone Number:",
                    "from_phone": "From Phone Number:",
                    "reference_code": "Reference Code: (Optional)",
                    "next": "Next Step: Upload Files",
                    "current": "Current Details:",
                    "to": "To:",
                    "from": "From:",
                    "reference": "Reference:",
                    "drag_drop": "Drag and drop files for the specified order. Feel free to delete files or cancel uploads.",
                    "upload_size": "The total file upload must be smaller than 50MB.",
                    "supported": "Supported file types: <i>doc</i>, <i>docx</i>, and <i>pdf</i>.",
                    "add_files": "Add Files",
                    "cancel_up": "Cancel Upload",
                    "try_line": "You can try to send the fax again, or go back and edit information and files.",
                    "go_back": "Go Back",
                    "try_again": "Try Again",
                    "working": "Working",
                    "please_wait": "Please wait a little bit while things get going.",
                    "converting": "The fax is in the process converting, then dialing the remote machine.",
                    "success": "Success",
                    "started": "Successfully started the fax.",
                    "being_sent": "The fax is being sent! If fax notifications are turned on for this account, you will receive an email with the status when complete.",
                    "send_another":"Send Another Fax"
                },
                // #fax-inbox
                "fax_inbox": {
                    "fax": "Fax",
                    "info": "Read, email, and delete fax messages.",
                    "show": "Show",
                    "faxes_per": "Faxes Per Page:",
                    "move_selected": "Move Selected",
                    "yes": "Yes, delete",
                    "deleted_forever": "These faxes will be deleted forever.",
                    "delete_selected": "Delete Selected",
                    "sure": "Are you sure?",
                    "send_all": "Send all selected faxes to an email address",
                    "subject": "Subject:",
                    "message": "Message"
                },
                // #voicemail-greetings
                "voicemail_greetings": {
                    "header": "Voicemail Greetings",
                    "customize": "Customize your voicemail greetings.",
                    "download": "Download",
                    "create_new_greeting": "Create a New",
                    "greeting": "Greeting",
                    "enable_temp": "Enable Temporary Voicemail",
                    "temp_greeting": "Temporary voicemail greeting will always be used when enabled.",
                    "instructions_header": "Instructions",
                    "instructions_paragraph1": "Pick up the handset, and dial *97, enter your password, then dial 0 for Mailbox Options.",
                    "instructions_paragraph2": "Select which voicemail greeting you would like to record. The available options are Unavailable, Busy, and Name.",
                    "instructions_paragraph3": "When you are finished recording, press the pound key (#) to end the recording. It then gives you the options to press 1 to keep the recording, press 2 to listen to the recording, and press 3 to re-record the message.",
                    "new_recording": "New Recording",
                    "create_via": "Create via Phone",
                    "record_from": "Record In Browser",
                    "upload_recording": "Upload Recording",
                    "start": "Start Recording",
                    "stop": "Stop Recording",
                    "save": "Save to Disk",
                    "upload_to": "Upload To Portal",
                    "upload_file": "Upload File",
                    "size": "The file must be smaller than 15MB.",
                    "create_new": "Create New",
                    "new_unavailable": "New Unavailable Recording",
                    "new_busy": "New Busy Recording",
                    "new_name": "New Name Recording",
                    "new_temp": "New Temporary Recording",
                    "note": "Note: This feature is only supported on Google Chrome and Firefox"
                },
                // #voicemail-inbox
                "voicemail_inbox": {
                    "header": "Voicemail Inbox",
                    "listen_delete": "Listen, delete, and email voicemails.",
                    "select_all": "Select All",
                    "pound_sign": "#",
                    "calleridname": "Caller ID Name",
                    "calleridnumber": "Caller ID Number",
                    "date": "Date",
                    "secs": "Secs",
                    "message": "Message",
                    "move_selected": "Move Selected",
                    "download_selected": "Download Selected",
                    "delete_selected": "Delete Selected",
                    "send_all": "Send all selected voicemails to an email address",
                    "to": "To:",
                    "subject": "Subject",
                    "support": "Your browser does not support audio playback."
                },
                // #voicemail-settings
                "voicemail_settings": {
                    "general": "General Mailbox Settings",
                    "pin": "PIN:",
                    "notify_email": "Notify by Email:",
                    "email_address": "Email Address:",
                    "attach_to": "Attach Voicemails to Email",
                    "delete_after": "Delete Voicemails After Delivery",
                    "notify_text": "Notify by Text:",
                    "cell_num": "Cell Number:",
                    "cell_carrier": "Cell Phone Carrier:",
                    "choose_carrier": "Choose your cell phone carrier to send texts to",
                    "location": "Location",
                    "the_location": "The location this mailbox is attached to.",
                    "exit_to_app": "Exit to Application:",
                    "select_app": "Select Application",
                    "select_option": "Select option",
                    "args": "Arguments",
                    "advanced_settings": "Advanced Mailbox Settings",
                    "say_id": "Say Caller ID",
                    "review": "Review",
                    "envelope": "Envelope",
                    "say_duration": "Say Duration",
                    "send_voicemail": "Send Voicemail",
                    "hide": "Hide from Directory",
                    "temp_warning": "Temporary Greeting Warning"
                },
                // #sms
                "sms": {
                    "new": "New Message",
                    "admin_sms": "Admin SMS",
                    "messages_for": "Messages for",
                    "loading_list": "Loading Conversations List",
                    "no_open": "You currently have no open conversations.",
                    "back": "Back",
                    "send": "Send",
                    "create": "Create Message",
                    "to": "To:",
                    "apology": "We apologize! Your extension is not currently SMS enabled.",
                    "apology2": "We apologize! You do not have any SMS enabled phone numbers on this account."
                },
                // #supportdesk
                "support_desk": {
                    "header": "Support Desk",
                    "knowledge": "Knowledgebase",
                    "new_tix": "New Ticket",
                    "date_created": "Date Created",
                    "priority": "Priority",
                    "due_date": "Due Date",
                    "all_tix": "All Tickets",
                    "pending": "Pending",
                    "resolved": "Resolved",
                    "closed": "Closed",
                    "or": "or",
                    "waiting_for": "Waiting For Your Response",
                    "open_new": "Open a New Ticket",
                    "open": "Open",
                    "x": "x"
                },
            }

        },


        // spanish
        "es" : {
            "name" : {
                "short" : "FluentStream",
                "long" : "FluentStream Technologies LLC",
                "possessive" : "FluentStream's",
                "generic": "FluentCloud",
                "portal": "MyFluentCloud"
            },

            "contact" : {
                "phone": "303-462-5683",
                "email": "support@fluentstream.com"
            },

            "general":{
                "settings": "Ajustes",
                "yes":"Si",
                "no":"No",
                "custom":"Personalizado",
                "default":"Defecto",
                "block":"Bloquear",
                "allow":"Permitir",
                "save_changes":"Guardar cambios",
                "save": "Salvar",
                "delete": "Borrar",
                "start": "Comienzo",
                "cancel": "Cancelar",
                "help": "Ayuda",
                "account": "Cuenta",
                "we": "Nosotros",
                "we_hope_you_love_us": "Esperamos que nos ames también,",
                "tenant": "Inquilino",
                "turn": "Giro",
                "on": "En",
                "off": "Apagado",
                "call_blocking": "Bloqueo de llamadas",
                "call_forwarding": "Reenvío de llamadas",
                "call_recording": "Grabacion de llamada",
                "call_screening": "Proyección de llamadas",
                "zohointegration": "Integración de Zoho CRM",
                "zohoclicktocall": "Haga clic para llamar para Zoho CRM",
                "lifestyle": "Asistente de llamada de estilo de vida",
                "title": "Título",
                "clear": "Claro",
                "reset": "Reiniciar",
                "play": "Jugar",
                "previous": "Anterior",
                "next": "Siguiente",
                "error": "Error",
                "to": "A:",
                "from": "De:",
                "send": "Enviar",
                "download": "Descargar",
                "show": "Espectáculo",
                "hide": "Esconder",
                "close": "Cerca",
                "type": "Tipo",
                "email": "Email",
                "Inbox": "Bandeja de Entrada",
                "Sent": "Expedido",
                "Company": "Empresa",
                "Deleted": "Eliminado",
                "inbox": "Bandeja de Entrada",
                "old": "Antiguo",
                "work": "Trabajo",
                "family": "Familia",
                "friends": "Amigos",
                "deleted": "Eliminado",
                "date": "Fecha",
                "pages": "Páginas",
                "reference": "Referencia",
                "caller id": "ID de llamadas",
                "sent to": "Enviado A",
                "status": "Estado",
                "fax": "Fax",
                "Inbound": "Entrante",
                "Outbound": "Saliente",
                "Answered": "Contestada",
                "No Answer": "Sin respuesta",
                "Failed": "Ha fallado",
                "Busy": "Ocupado",
                "Conference": "Conferencia",
                "External": "Externo",
                "Feature Code": "Código de función",
                "Internal": "Interno",
                "International": "Internacional",
                "Queue": "Cola",
                "Fax": "Fax",
                "form_fields" : {
                    "primary_cell_phone": "Teléfono celular principal",
                    "email_address": "Dirección de correo electrónico",
                    "did_for_sms": "Número de teléfono celular principal para sms"
                }
            },

            "view": {
                "header_main": {
                    // wrench icon menu
                    "admin_helper_wrench": {
                        "account_information": "Información de la cuenta",
                        "primary_server": "Servidor primario\:",
                        "failover_server": "Servidor secundario\:",
                        "tertiary_failover":"Tolerancia a Fallos Terciarios\:",
                        "account_password": "Contraseña de cuenta\:",
                        "subscription_level": "Nivel de suscripción\:"
                    },
                    // lightnin icon menu
                    "admin_helper_lightning": {
                        "tenant_manager": "Gerente de Inquilinos",
                        "support_agents": "Agentes de soporte",
                        "phone_number_manager": "Gerente de números de teléfono",
                        "tenant_extensions": "Extensiones de Inquilino",
                        "tenant_billing": "Facturación del Inquilino",
                        "alert_notifications_contacts": "Alertas Notificaciones Contactos",
                        "send_alert_notifications": "Enviar Notificación de Alerta",
                        "old_extensions": "Extensiones Antiguas",
                        "reload_proxy": "Recargar Proxy",
                        "all_invoices": "Facturas de Inquilinos y Clientes"
                    },
                    // globe icon menu
                    "admin_helper_globe": {
                        "my_account_details": "Detalles de mi cuenta",
                        "browser_ip": "Dirección IP del navegador:",
                        "tenant_name": "Nombre del inquilino:"
                    },
                    // help icon menu
                    "admin_helper_help": {

                    },
                    // account icon menu
                    "admin_helper_account": {
                        "call_notification_settings": "Configuración de notificación de llamadas",
                        "reset_password": "Restablecer la contraseña",
                        "settings": "Ajustes",
                        "billing_and_statements": "Facturación y declaraciones",
                        "sign_out": "Desconectar"
                    },
                    // main nav bar
                    "main_nav_bar": {
                        "settings": "Ajustes",
                        "extensions": "Extensiones",
                        "locations": "Ubicaciones",
                        "phone_numbers": "Números de teléfono",
                        "roles": "Roles",
                        "routes": "Rutas",
                        "tags": "Etiquetas",
                        "user_management": "Gestión de usuarios",
                        "voicemail_boxes": "Buzones de correo de voz",
                        "features": "Caracteristicas",
                        "auto_attendant": "Operador automático",
                        "call_flows": "Flujos de llamadas",
                        "call_groups": "Grupos de llamadas",
                        "conference_rooms": "Salas de conferencias",
                        "feature_codes": "Códigos de funciones",
                        "hold_music": "Retener música",
                        "live_conference_view": "Vista de la conferencia en directo",
                        "managed_routes": "Rutas gestionadas",
                        "notification_workflows": "Flujos de trabajo de notificaciones",
                        "parking_lots": "Estacionamientos",
                        "post_call_surveys": "Encuestas posteriores a la llamada",
                        "queues": "Colas",
                        "queue_agents": "Agentes de cola",
                        "recordings": "Grabaciones",
                        "time_conditions": "Condiciones de tiempo",
                        "devices": "Dispositivos",
                        "phones": "Teléfonos",
                        "button_configurations": "Configuraciones de botones",
                        "reports": "Informes",
                        "dashboard": "Tablero",
                        "email_reports": "Informes por correo electrónico",
                        "fc_reports": "Informes de FluentCloud",
                        "key_press_reports": "Informes de prensa",
                        "legacy_reports": "Informes legados",
                        "portal": "Portal",
                        "salesforce": "Salesforce"
                    },
                    // sub nav bar
                    "sub_nav_bar": {
                        "user_portal": "Portal de usuarios",
                        "call_history": "Historial de llamadas",
                        "call_management": "Gestión de llamadas",
                        "blocking": "Bloqueo",
                        "call_recording": "Grabacion de llamada",
                        "forwarding": "Reenvío",
                        "lifestyle_call_attendant": "Asistente de llamada de estilo de vida",
                        "screening": "Cribado",
                        "voicemail_drop": "Gota de correo de voz",
                        "zoho": "Zoho Integracion",
                        "contacts": "Contactos",
                        "import": "Importar",
                        "manage": "Gestionar",
                        "shared": "Compartido",
                        "fax": "Fax",
                        "create": "Crear",
                        "inbox": "Bandeja de entrada",
                        "voicemail": "Mensaje de voz",
                        "greetings": "Saludos",
                        "sms": "SMS",
                        "user_settings": "Ajustes de usuario"
                    },
                    // referrals
                    "earn_referrals": "Nuestra empresa se basa en referencias y apreciamos a cualquier persona que nos recomiende. Puede ganar hasta $ 500 por cada cliente nuevo que recomiende.",
                    "greeting_name": "Kerrin Parker, directora de atención al cliente",
                    "start_referring": "Empieza a referir hoy",
                },
                // #userportal
                "userportal": {
                    "user_portal": "Portal de usarios",
                    "manage_settings": "Administrar los ajustes que son más importantes para el usuario final de My FluentCloud.",
                    "about": "Portal del usuario",
                    "portal_paragraph1": "Este portal se puede utilizar para la configuración de extensiones y gestión de efax.",
                    "portal_paragraph2": "Puede configurar el desvío de llamadas, administrar la configuración del correo de voz, consultar el historial de llamadas, entre muchas otras funciones.",
                    "portal_paragraph3": "Configure estas características para todas las extensiones, grupos de extensiones o para cada extensión individual. Personaliza tan poco o tanto como quieras.",
                    "portal_paragraph4": "También envíe y reciba efaxes aquí. El portal permite una fácil gestión de todos los faxes entrantes y salientes.",
                    "need_help": "¿Necesitas ayuda?",
                    "the_whole_team": "Todo el equipo de FluentStream está listo para ayudarte. 24 horas al día, 365 días al año. Puede contactarnos de varias maneras.",
                    "bycalling": "Llamando 303-GO-CLOUD (303-462-5683)",
                    "byemailing": "Enviando un correo electrónico",
                    "byclicking": "Haciendo clic en el botón de abajo!",
                    "link_href": "mailto:support@fluentstream.com",
                    "link": "support@fluentstream.com",
                    "button_text": "Obtener ayuda de FluentStream"
                },
                // #callhistory
                "callhistory": {
                    "header": "Registro de detalles de llamadas para la extensión",
                    "show_filters": "Mostrar filtros",
                    "entries": "Entradas",
                    "show_totals": "Mostrar totales",
                    "call_totals": "Totales del historial de llamadas",
                    "hide_filters": "Ocultar filtros",
                    "time_date": "Hora y fecha",
                    "direction": "Dirección de llamada",
                    "result": "Resultado de la llamada",
                    "type": "Tipo de llamada",
                    "number": "Número de teléfono",
                    "apply": "Aplicar filtro",
                    "reset": "Restablecer filtro",
                    "start_date": "Fecha de inicio:",
                    "end_date": "Fecha final:",
                    "min_time": "Min. Tiempo de llamada:",
                    "max_time": "Tiempo máximo de llamada:",
                    "num_search": "Búsqueda por número de teléfono",
                    "excel": "Excel",
                    "csv": "CSV",
                    "download": "Descargar grabación",
                    "show": "Espectáculo",
                    "no_call_data": "No hay datos de llamada! ",
                    "stats_loading": "Estadísticas de llamadas Cargando...",
                    "total_number": "Número total de llamadas: ",
                    "total_talk": "Tiempo total de conversación: ",
                    "avg_talk": "Tiempo de conversación promedio: ",
                    "inbound": "Entrante: ",
                    "outbound": "Saliente: ",
                    "inbound_tab": "Entrante",
                    "outbound_tab": "Saliente",
                    "none": "Ninguna",
                    "calls": "Llamadas",
                    "minutes": "Minutos",
                    "add_phone": "Agregar número de teléfono o extensión"
                },
                // #callblocking
                "callblocking":{
                    "heading": "Opciones de bloqueo de llamadas",
                    "description": "Bloquear y permitir llamadas",
                    "toggle_button": "Encender",
                    "block_callers": "Bloquear llamadas:",
                    "note": "Nota: ",
                    "all_callers": "Todas las llamadas en esta lista serán bloqueadas",
                    "callers": "Llamadas",
                    "without": "sin",
                    "caller_id": "identificador de llamadas:",
                    "when_blocking_calls": "Al bloquear llamadas\:",
                    "send_to_voicemail":"Enviar al buzón de voz",
                    "play_busy": "Reproducir \"ocupado\" al llamador",
                    "play_caller": "Reproducir \"tortura\" al llamador"
                },
                // #callrecording
                "callrecording": {
                    "heading": "Opciones de grabación de llamadas",
                    "settings": "Ajustes para grabar llamadas.",
                    "options": "Opciones de grabación de llamadas:",
                    "always": "Registrar siempre llamadas",
                    "key_sequence": "Grabar en la secuencia de teclas #9"
                },
                // #callforwarding
                "callforwarding": {
                    "heading": "Opciones de reenvío de llamadas",
                    "other_numbers": "Reenviar llamadas a otros números",
                    "always_forward": "Siempre hacia adelante",
                    "busy": "Reenviar en ocupado",
                    "follow": "Sígueme",
                    "always_number": "Siempre número:",
                    "always_forward_to": "Enviar siempre a este número",
                    "on_busy": "En la aplicación ocupada:",
                    "when_following": "Cuando me sigue:",
                    "simultaneously": "Toque mi teléfono y sígame números simultáneamente.",
                    "duration": "Duración del anillo (segundos):",
                    "ring_then_forward": "Anular mi teléfono y luego reenviar mi llamada si no responde.",
                    "dont_ring": "No toque mi teléfono, solo adelante.",
                    "how_to": "Cómo reenviar llamadas:",
                    "call_all": "Llamar a todos mis números de Follow Me al mismo tiempo",
                    "call_each": "Llame a cada uno de ellos.",
                    "follow_me_numbers": "Sígame números:",
                    "these_will_be": "Estos números serán llamados en el siguiente orden.<br>" +
                    "Arrastrar y soltar números para reordenar.",
                    "notify_caller": "Notificar a la persona que llama:",
                    "let_callers_know": "Deje que las personas que llaman sepan que están siendo enviadas",
                    "id": "Identificador de llamadas:",
                    "use_original": "Utilizar el llamador original&#39;s Identificador de llamadas al reenviar",
                    "confirm": "Confirmar llamada:",
                    "ask_if_forwarded": "Preguntar si desea recibir una llamada reenviada",
                    "external": "Sólo llamadas externas:",
                    "select_option": "Seleccionar opción"
                },
                // #callscreening
                "callscreening": {
                    "heading": "Opciones de selección de llamadas",
                    "settings": "Ajustes para la selección de llamadas.",
                    "callers_with": "Llamadas <em>con</em> Identificador de llamadas:",
                    "ask_user": "Pida al usuario que diga nombre",
                    "caller_announce": "Anuncio de la persona que llama:",
                    "do_not_screen": "No filtrar a estos llamantes:",
                    "all_callers": "<strong>Nota: </strong>Todas las personas que llaman en esta lista estarán exentas",
                    "callers_without": "Llamadas <em>sin </em> identificador de llamada:",
                    "ask_callers_name": "Pida a los llamadores que digan nombre",
                    "ask_callers_number": "Pida a los llamadores que digan el número",
                    "caller_announcement": "Anuncio de llamador:",
                    "this_is_recording": "Esta es la grabación que se reproducirá a los llamadores para pedir su nombre o número.<br>" +
                    "Si está configurado como predeterminado, reproducirá la grabación estándar."
                },
                // #zohointegration
                "zohointegration": {
                    heading: "Opciones de integración de Zoho CRM",
                    settings: "Administre su integración de Zoho CRM",
                    "client_id": "ID de cliente de Zoho CRM",
                    "client_id_help": "ID de cliente proporcionado por Zoho",
                    "client_secret": "Secreto del cliente de Zoho CRM",
                    "client_secret_help": "Secreto del cliente provisto por Zoho",
                },
                // #usersettings
                "usersettings" : {
                    "user_settings": "Ajustes de Usuario",
                    "telegram_id": "Telegram ID",
                    "slack_user_id": "Slack ID",
                    "sms_to_email": "SMS a correo electrónico",
                    "enable_proactive_chat_notifications": "Habilitar Notificaciones Proactivas de Chat",
                    "enable_local_area_presence": "Habilitar Presencias en el area local",
                    "portal_color_scheme": "Esquema de color personalizado del portal",
                    "match_caller_id_if_possible": "Habilitar Presencias en el area local",
                    "update_settings": "Ajustes de Actualización",
                    "select_a_color_scheme": "Seleccionar un esquema de color",
                    "cell_phone": "Número Celular",
                    "email": "Dirección de correo electrónico",
                    "telegram": "@ID de telegrama",
                    "did_for_sms": "Numero primario"
                },
                //# helper for contact templates
                "contactHelper": {
                    "im": "Mensajería instantánea",
                    "custom": "Personalizado",
                    "name": "Nombre",
                    "prefix": "Prefijo",
                    "first": "Primero",
                    "middle": "Medio",
                    "last": "Último",
                    "suffix": "Sufijo",
                    "orgs": "Organizaciones",
                    "org": "Organizacione",
                    "addresses": "Direcciones",
                    "address": "Direccione",
                    "urls": "URLs",
                    "phone_numbers": "Números de teléfono",
                    "phone": "Número de teléfono",
                    "emails": "Correos electrónicos",
                    "email": "Correo electrónicos",
                    "update": "Actualizar",
                    "delete": "Borrar",
                    "create": "Crear",
                    "create_new": "Crear nuevo",
                    "details": "Detalles de contacto",
                    "none_found": "No se han encontrado contactos",
                    "favorites_first": "Favoritos primero",
                    "favorite": "Favorito",
                    "shared": "Compartido",
                    "add": "Añadir",
                    "email_addresses": "Correos electrónicos",
                    "events": "Eventos",
                    "im_clients": "IM Clientela",
                    "notes": "Notas",
                    "route_to_cell": "Ruta al teléfono celular",
                    "route_through": "Encaminar una llamada a ",
                    "number": "través de su teléfono celular?",
                    // NOTE: this link needs to be set with a whitelabeled value in the template, change asap
                    "change_cell": "Su número de teléfono celular se puede cambiar en su",
                    "user_settings": "Ajustes de usuario",
                    "call": "Llamada",
                    "exit": "Salida",
                    "contact_details": "Detalles de contacto",
                    "added_by": "Añadido por",
                    "quick_glance": "Vista rápida"
                },
                // #contacts-import
                "contacts_import": {
                    "integrations": "Importar Contactos",
                    // Google Contacts - Feature removed due to google authorization not completed here - https://console.developers.google.com/apis/credentials/consent?authuser=0&project=my-fluentcloud  >
                    // "integrations": "Contactos Integraciones",
                    // "google": "<strong>Contactos de Google</strong> <br>" +
                    // "Importar contactos de su cuenta de GMail.",
                    // "synced": "Sincronizado: ",
                    // "sync": "Sincronizar",
                    // "never": "Nunca",
                    "honorific_pre": "Prefijo honorífico",
                    "honorific_suff": "Sufijo honorífico",
                    "first": "Primero",
                    "middle": "Medio",
                    "last": "Último"
                },
                // #contacts-leftbar
                "contacts_leftbar": {
                    "create": "Crear contacto",
                    "groups": "Grupos"
                },
                // #fax-create
                "fax_create": {
                    "create_fax": "Crear un fax",
                    "step1": "Paso 1: Detalles del fax",
                    "step2": "Paso 2: Subir archivos",
                    "step3": "Paso 3: Completar",
                    "to_phone": "Al número de teléfono:",
                    "from_phone": "Del número de teléfono:",
                    "reference_code": "Código de referencia: (Opcional)",
                    "next": "Siguiente paso: subir archivos",
                    "current": "Datos actuales:",
                    "to": "A:",
                    "from": "De:",
                    "reference": "Referencia:",
                    "drag_drop": "Arrastre y suelte archivos para el orden especificado. Siéntase libre de eliminar archivos o cancelar subidas.",
                    "upload_size": "La carga total del archivo debe ser menor que 15MB.",
                    "supported": "Tipos de archivos compatibles: <i>doc</i>, <i>docx</i>, y <i>pdf</i>.",
                    "add_files": "Agregar archivos",
                    "cancel_up": "Cancelar la subida",
                    "try_line": "Puede intentar enviar el fax de nuevo, o volver atrás y editar información y archivos.",
                    "go_back": "Regresa",
                    "try_again": "Inténtalo de nuevo",
                    "working": "Trabajando",
                    "please_wait": "Por favor, espere un poco mientras las cosas van.",
                    "converting": "El fax está en proceso de conversión, luego marcando la máquina remota.",
                    "success": "Éxito",
                    "started": "Se inició correctamente el fax.",
                    "being_sent": "¡El fax está siendo enviado! Si las notificaciones de fax están activadas para esta cuenta, recibirá un correo electrónico con el estado cuando se complete.",
                    "send_another":"Enviar otro fax"
                },
                // #fax-inbox
                "fax_inbox": {
                    "fax": "Fax",
                    "info": "Leer, enviar por correo electrónico y eliminar mensajes de fax.",
                    "show": "Espectáculo",
                    "faxes_per": "Faxes por página:",
                    "move_selected": "Mover seleccionado",
                    "yes": "Sí, borrar",
                    "deleted_forever": "Estos faxes se borrarán para siempre.",
                    "delete_selected": "Eliminar seleccionado",
                    "sure": "¿Estás seguro?",
                    "send_all": "Enviar todos los faxes seleccionados a una dirección de correo electrónico",
                    "subject": "Tema:",
                    "message": "Mensaje"
                },
                // #voicemail-greetings
                "voicemail_greetings": {
                    "header": "Saludos del correo de voz",
                    "customize": "Personalice sus saludos de correo de voz.",
                    "download": "Descargar",
                    "create_new_greeting": "Crear un nuevo",
                    "greeting": "Saludo",
                    "enable_temp": "Habilitar correo de voz temporal",
                    "temp_greeting": "El saludo de correo de voz temporal siempre se utilizará cuando esté habilitado.",
                    "instructions_header": "Instrucciones",
                    "instructions_paragraph1": "Levante el auricular y marque * 97, introduzca su contraseña y luego marque 0 para Opciones de buzón.",
                    "instructions_paragraph2": "Seleccione el saludo de correo de voz que desea grabar. Las opciones disponibles son No disponible, Ocupado y Nombre.",
                    "instructions_paragraph3": "Cuando haya terminado de grabar, pulse la tecla de número (#) para finalizar la grabación. A continuación, le da las opciones para presionar 1 para mantener la grabación, presione 2 para escuchar la grabación, y presione 3 para volver a grabar el mensaje.",
                    "new_recording": "Nueva grabación",
                    "create_via": "Crear a través del teléfono",
                    "record_from": "Grabar desde el navegador",
                    "upload_recording": "Cargar la grabación",
                    "start": "Empezar a grabar",
                    "stop": "Para de grabar",
                    "save": "Guardar en el disco",
                    "upload_to": "Subir a Portal",
                    "upload_file": "Subir archivo",
                    "size": "El archivo debe ser más pequeño que 15MB.",
                    "create_new": "Crear nuevo",
                    "new_unavailable": "Nueva grabación no disponible",
                    "new_busy": "Nueva grabación ocupada",
                    "new_name": "Nueva grabación de nombres",
                    "new_temp": "Nueva grabación temporal",
                    "note": "Nota: esta función sólo es compatible con Google Chrome y Firefox"
                },
                // #voicemail-inbox
                "voicemail_inbox": {
                    "header": "Buzón de correo de voz",
                    "listen_delete": "Escucha, borra y envía mensajes de correo de voz.",
                    "select_all": "Seleccionar todo",
                    "pound_sign": "#",
                    "caller_id": "Identificador de llamadas",
                    "date": "Fecha",
                    "secs": "Segundos",
                    "message": "Mensaje",
                    "move_selected": "Mover seleccionado",
                    "delete_selected": "Eliminar seleccionado",
                    "send_all": "Enviar todos los mensajes de voz seleccionados a una dirección de correo electrónico",
                    "to": "A:",
                    "subject": "Tema",
                    "support": "Su navegador no admite reproducción de audio."
                },
                // #voicemail-settings
                "voicemail_settings": {
                    "general": "Configuración general del buzón",
                    "pin": "Alfiler:",
                    "notify_email": "Notificar por correo electrónico:",
                    "email_address": "Dirección de correo electrónico:",
                    "attach_to": "Adjuntar mensajes de correo de voz al correo electrónico",
                    "delete_after": "Eliminar mensajes de voz después de la entrega",
                    "notify_text": "Notificar por Texto:",
                    "cell_num": "Numero de celular:",
                    "cell_carrier": "Portador del teléfono celular:",
                    "choose_carrier": "Elija su proveedor de telefonía celular para enviar",
                    "location": "Ubicación",
                    "the_location": "La ubicación a la que está conectado este buzón.",
                    "exit_to_app": "Salir a la aplicación:",
                    "select_app": "Seleccionar Aplicación",
                    "select_option": "Seleccionar opción",
                    "args": "Argumentos",
                    "advanced_settings": "Configuración avanzada del buzón",
                    "say_id": "Identificador de llamadas",
                    "review": "revisión",
                    "envelope": "Sobre",
                    "say_duration": "Decir Duración",
                    "send_voicemail": "Enviar correo de voz",
                    "hide": "Ocultar del directorio",
                    "temp_warning": "Advertencia de saludo temporal"
                },
                // #sms
                "sms": {
                    "new": "Nuevo mensaje",
                    "admin_sms": "Sms de administración",
                    "messages_for": "Mensajes para",
                    "loading_list": "Cargando la lista de conversaciones",
                    "no_open": "Actualmente no tienes conversaciones abiertas.",
                    "back": "Espalda",
                    "send": "Enviar",
                    "create": "Crear un mensaje",
                    "to": "A:",
                    "apology": "¡Pedimos disculpas! Tu extensión no está habilitada para SMS.",
                    "apology2": "¡Pedimos disculpas! No tienes ningún número de teléfono habilitado para SMS en esta cuenta."
                },
                // #supportdesk
                "support_desk": {
                    "header": "Mesa de apoyo",
                    "knowledge": "Base de conocimiento",
                    "new_tix": "Nuevo boleto",
                    "date_created": "Fecha de creacion",
                    "priority": "Prioridad",
                    "due_date": "Fecha de vencimiento",
                    "all_tix": "Todas las entradas",
                    "pending": "Pendiente",
                    "resolved": "Resuelto",
                    "closed": "Cerrado",
                    "or": "o",
                    "waiting_for": "Esperando por tu respuesta",
                    "open_new": "Abrir un nuevo ticket",
                    "open": "Abierto",
                    "x": "x"
                },
            }
        }

    },

    "voiceignite": {

        "en" : {
            "name" : {
                "short" : "Voice Ignite",
                "long" : "Voice Ignite",
                "possessive" : "Voice Ignite's",
                "generic": "Voice Ignite",
                "portal": "Voice Ignite"
            },
            "contact" : {
                "phone": "415-212-8101",
                "email": "support@voiceignite.com"
            },
            "view": {
                "header_main": {
                    "main_nav_bar": {
                        "fc_reports": "Voice Ignite Reports"
                    },
                    "greeting_name": "Voice Ignite Customer Support Team"
                },
                "userportal": {
                    "manage_settings": "Manage the settings that are most important to the end user of Voice Ignite.",
                    "the_whole_team": "The whole team at Voice Ignite is ready to help you.  24 hours a day, 365 days a year.  You can reach us a number of ways.",
                    "bycalling": "By calling 415-212-8101",
                    "link_href": "mailto:support@voiceignite.com",
                    "link": "support@voiceignite.com",
                    "button_text": "Get Help From Voice Ignite"
                },
                "contactHelper": {
                    "change_cell": "<p>Your cell phone number can be changed in your <small><a href=\"https://my.fluentcloud.com/#usersettings\">User Settings</a></small>.</p>"
                },
            }
        },
        // spanish
        "es" : {
            "name" : {
                "short" : "Voice Ignite",
                "long" : "Voice Ignite",
                "possessive" : "Voice Ignite's",
                "generic": "Voice Ignite"

            },
            "contact" : {
                "phone": "415-212-8101",
                "email": "support@voiceignite.com"
            },
            "view": {
                "header_main": {
                    "main_nav_bar": {
                        "fc_reports": "Informes de Voice Ignite"
                    },
                    "greeting_name": "Equipo de asistencia al cliente de Voice Ignite"
                },
                "userportal": {
                    "manage_settings": "Administrar los ajustes que son más importantes para el usuario final de Voice Ignite.",
                    "the_whole_team": "Todo el equipo de Voice Ignite está listo para ayudarte. 24 horas al día, 365 días al año. Puede contactarnos de varias maneras.",
                    "bycalling": "Llamando 415-212-8101",
                    "link_href": "mailto:support@voiceignite.com",
                    "link": "support@voiceignite.com",
                    "button_text": "Obtener ayuda de Voice Ignite"
                },
                "contactHelper": {
                    "change_cell": "Su número de teléfono celular puede ser cambiado en su configuración de usuario"
                },
            }
        }
    },

    "sarsk": {
        "en" : {
            "name" : {
                "short" : "Sarsk",
                "long" : "Sarsk",
                "possessive" : "Sarsk's",
                "generic": "Sarsk",
                "portal": "Sarsk"
            },
            "contact" : {
                "email": "support@myvoip.sarsk.net"
            },
            "view": {
                "header_main": {
                    "main_nav_bar": {
                        "fc_reports": "Sarsk Reports"
                    },
                    "greeting_name": "Sarsk Customer Support Team"
                },
                "userportal": {
                    "manage_settings": "Manage the settings that are most important to the end user of Sarsk.",
                    "the_whole_team": "The whole team at Sarsk is ready to help you.  24 hours a day, 365 days a year.  You can reach us a number of ways.",
                    "link_href": "mailto:support@myvoip.sarsk.net",
                    "link": "support@myvoip.sarsk.net",
                    "button_text": "Get Help From Sarsk"
                },
                "contactHelper": {
                    "change_cell": "<p>Your cell phone number can be changed in your <small><a href=\"https://myvoip.sarsk.net/#usersettings\">User Settings</a></small>.</p>"
                },
            },
            "general":{
              //Duplicated for sarsk -mason
                "settings": "Settings",
                "yes":"Yes",
                "no":"No",
                "custom":"Custom",
                "default":"Default",
                "block":"Block",
                "allow":"Allow",
                "save_changes":"Save Changes",
                "save": "Save",
                "delete": "Delete",
                "start": "Start",
                "cancel": "Cancel",
                "help": "Help",
                "account": "Account",
                "we": "We",
                "we_hope_you_love_us": "We hope you love us too,",
                "tenant": "Tenant",
                "turn": "Turn",
                "on": "On",
                "off": "Off",
                "call_blocking": "Call Blocking",
                "call_forwarding": "Call Forwarding",
                "call_recording": "Call Recording",
                "call_screening": "Call Screening",
                "zohointegration": "Zoho CRM Integration",
                "lifestyle": "Lifestyle Call Attendant",
                "title": "Title",
                "clear": "Clear",
                "reset": "Reset",
                "play": "Play",
                "previous": "Previous",
                "next": "Next",
                "error": "Error",
                "to": "To:",
                "from": "From:",
                "send": "Send",
                "download": "Download",
                "show": "Show",
                "hide": "Hide",
                "close": "Close",
                "type": "Type",
                "email": "Email",
                "Inbox": "Inbox",
                "Sent": "Sent",
                "Company": "Company",
                "Deleted": "Deleted",
                "inbox": "Inbox",
                "old": "Old",
                "work": "Work",
                "family": "Family",
                "friends": "Friends",
                "deleted": "Deleted",
                "date": "Date",
                "pages": "Pages",
                "reference": "Reference",
                "caller id": "Caller ID",
                "sent to": "Sent to",
                "status": "Status",
                "fax": "Fax",
                "Inbound": "Inbound",
                "Outbound": "Outbound",
                "Answered": "Answered",
                "No Answer": "No Answer",
                "Failed": "Failed",
                "Busy": "Busy",
                "Conference": "Conference",
                "External": "External",
                "Feature Code": "Feature Code",
                "Internal": "Internal",
                "International": "International",
                "Queue": "Queue",
                "Fax": "Fax",
                "form_fields" : {
                    "primary_cell_phone": "Primary Cell Phone Number",
                    "email_address": "Email Address",
                    "did_for_sms": "Primary Phone Number for SMS"
                }
            },
        },
    },

    "yiptel": {

        "en" : {
            "name" : {
                "short" : "YipTel",
                "long" : "YipTel",
                "possessive" : "YipTel's",
                "generic": "YipTel"
            },
            "contact" : {
                "phone": "800-752-6110",
                "email": "support@yiptel.com"
            },
            "view": {
                "header_main": {
                    "main_nav_bar": {
                        "fc_reports": "YipTel Reports"
                    },
                    "greeting_name": "YipTel Customer Support Team"
                },
                "userportal": {
                    "manage_settings": "Manage the settings that are most important to the end user of YipTel.",
                    "the_whole_team": "The whole team at YipTel is ready to help you.  24 hours a day, 365 days a year.  You can reach us a number of ways.",
                    "bycalling": "By calling 800-752-6110",
                    "link_href": "mailto:support@yiptel.com",
                    "link": "support@yiptel.com",
                    "button_text": "Get Help From YipTel"
                },
                "contactHelper": {
                    "change_cell": "<p>Your cell phone number can be changed in your <small><a href=\"https://one.yiptel.com/#usersettings\">User Settings</a></small>.</p>"
                }
            }
        }
    }
};

export function getWhitelabelPartner(){

    const location = window.location.host;

    if (location.includes("sarsk")) {
        return "sarsk"
    }
    else if (location.includes("voiceignite")){
        return "voiceignite"
    }

    return 'fluentstream';
}