import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import makeGlobalStyles from 'makeGlobalStyles';

function CallScreeningButton(props) {
  const classes = makeGlobalStyles();
  
  return (
    <Button
      classes={{ root: classes.helpButton }}
      onClick={() => {
        props.toggleScreening();
      }}
    >
      {props.text}
    </Button>
  );
}

CallScreeningButton.propTypes = {
  text: PropTypes.string.isRequired,
  toggleScreening: PropTypes.func.isRequired,
}

export default CallScreeningButton;
