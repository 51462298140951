import {
  GLOBALDIDS_HTTP_SUCCESS,
  GLOBALDIDS_HTTP_FAILURE,
  TENANTS_HTTP_SUCCESS,
  TENANTS_HTTP_FAILURE,
  CARRIERS_HTTP_SUCCESS,
  CARRIERS_HTTP_FAILURE,
  SELECTPHONENUMBER_HTTP_SUCCESS,
  SELECTPHONENUMBER_HTTP_FAILURE,
  CHANGE_PHONENUMBER_FORM_DATA,
  CLEAR_PHONENUMBER_FORM_DATA,
  CREATEPHONENUMBERS_HTTP_SUCCESS,
  CREATEPHONENUMBERS_HTTP_FAILURE,
  RESET_FLAG,
  UPDATE_EDITPHONENUMBER_FORM_DATA,
  REVERT_EDITPHONENUMBER_FORM_DATA,
  EDITPHONENUMBERSMANAGER_HTTP_SUCCESS,
  EDITPHONENUMBERSMANAGER_HTTP_FAILURE,
  DELETEPHONENUMBERSFROMMANAGER_HTTP_SUCCESS,
  DELETEPHONENUMBERSFROMMANAGER_HTTP_FAILURE,
  DESELECT_SELECTED_NUMBER,
  NAVIGATE,
} from 'actions/typeConstants';
import { parseError } from 'utils/misc';

const initialNewForm = {
  assigned: '0',
  billable: '0',
  callrecording: '0',
  friendlyname: '',
  is_domestic: '1',
  porting_in_progress: '0',
  smscarrier: '',
  enabled: '1',
  smsenabled: '0',
  tenant: '',
  voicecarrier: '',
  assignedconference: "",
  assignedmanaged: "",
  assignedroute: "",
  assigneduser: "",
  date_purchased: "",
  date_updated: "",
  emergencyroute: "",
  external_dispatch: "",
  faxenabled: "0",
  location: "",
  who_updated: "",
};

const initialState = {
  phonenumbers: [],
  tenants: [],
  carriers: [],
  successMsg: null,
  errorMsg: null,
  errorCode: null,
  selectedNumber: null,
  newPhoneNumberForm: {...initialNewForm},
  redirectTo: null,
  editPhoneNumberForm: null,
  loadedDids: false,
  loadedTenants: false,
  loadedCarriers: false,
  carrierIndexes: null,
}

export default function(state = initialState, action){
      
  switch(action.type){
    case GLOBALDIDS_HTTP_SUCCESS:
      return {
        ...state,
        phonenumbers: action.data.data,
        loadedDids: true,
      }
    case GLOBALDIDS_HTTP_FAILURE:
      return {
        ...state,
        phonenumbers: [],
        errorMsg: parseError(action.error.response.text),
        successMsg: null,
        errorCode: action.error.response.status
      }
    case TENANTS_HTTP_SUCCESS:
      return {
        ...state,
        tenants: action.data,
        loadedTenants: true,
      }
    case TENANTS_HTTP_FAILURE:
      return {
        ...state,
        tenants: [],
        errorMsg: parseError(action.error.response.text),
        successMsg: null,
        errorCode: action.error.response.status
      }
    case CARRIERS_HTTP_SUCCESS:
      const sortedCarriers = action.data.data.sort((a, b) => {
        const carrierA = a.friendlyname.toUpperCase();
        const carrierB = b.friendlyname.toUpperCase();
        if (carrierA < carrierB) {
          return -1;
        }
        if (carrierA > carrierB) {
          return 1;
        }
        return 0;
      })
      return {
        ...state,
        carriers: sortedCarriers,
        loadedCarriers: true,
      }
    case CARRIERS_HTTP_FAILURE:
      return {
        ...state,
        carriers: [],
        errorMsg: parseError(action.error.response.text),
        successMsg: null,
        errorCode: action.error.response.status
      }
    case SELECTPHONENUMBER_HTTP_SUCCESS:
      if (action.reqObject === 'getCarrierNum') {
        return {...state, carrierIndexes: action.data}
      }
      return {
        ...state,
        selectedNumber: {...action.data},
        editPhoneNumberForm: {...action.data}
      }
    case SELECTPHONENUMBER_HTTP_FAILURE:
      return {
        ...state,
        selectedNumber: null,
        errorMsg: parseError(action.error.response.text),
        successMsg: null,
        errorCode: action.error.response.status
      }
    case CHANGE_PHONENUMBER_FORM_DATA:
      return {
        ...state,
        newPhoneNumberForm: {...action.payload}
      }
    case CLEAR_PHONENUMBER_FORM_DATA:
      return {
        ...state,
        newPhoneNumberForm: {...initialNewForm}
      }
    case CREATEPHONENUMBERS_HTTP_SUCCESS: 
      if (action.reqObject === 'bulkupload') {
        const { errors, success } = action.data;
        const mappedErrors = errors.length && errors.map((error) => error.reason);
        return {
            ...state,
          newPhoneNumberForm: {...initialNewForm},
          successMsg: success.length && 'Created!',
          errorCode: mappedErrors,
          errorMsg: mappedErrors,
          phonenumbers: [...state.phonenumbers, ...success],
        };
      } else {
        return {
          ...state,
          newPhoneNumberForm: {...initialNewForm},
          successMsg: `Created new phone number!`,
          errorCode: null,
          errorMsg: null,
          phonenumbers: [...state.phonenumbers, action.data],
          redirectTo: action.data.phonenumber
        };
      };
    case CREATEPHONENUMBERS_HTTP_FAILURE: 
    const errorMessage = JSON.parse(action.error.response.text).error.message;
      return {
        ...state,
        errorMsg: errorMessage,
        successMsg: null,
        errorCode: action.error.response.status
      }
    case RESET_FLAG:
      return {
        ...state,
        successMsg: null,
        errorCode: null,
        errorMsg: null,
        redirectTo: null
      }
    case UPDATE_EDITPHONENUMBER_FORM_DATA:
      return {
        ...state,
        editPhoneNumberForm: {...action.payload}
      }
    case REVERT_EDITPHONENUMBER_FORM_DATA:
      return {
        ...state,
        editPhoneNumberForm: {...state.selectedNumber}
      }
    case EDITPHONENUMBERSMANAGER_HTTP_SUCCESS:
      return {
        ...state,
        selectedNumber: {...action.data},
        editPhoneNumberForm: {...action.data},
        successMsg: `Saved phone number: ${action.data.phonenumber}`,
        errorMsg: null,
        errorCode: null
      }
    case EDITPHONENUMBERSMANAGER_HTTP_FAILURE:
      return {
        ...state,
        successMsg: null,
        errorMsg: parseError(action.error.response.text),
        errorCode: action.error.response.status
      }
    case DELETEPHONENUMBERSFROMMANAGER_HTTP_SUCCESS:
      const newPhoneNumbersList = state.phonenumbers.filter(item => item.phonenumber !== action.reqObject);
      return {
        ...state,
        selectedNumber: null,
        successMsg: `Deleted phone number: ${action.reqObject}`,
        errorMsg: null,
        errorCode: null,
        phonenumbers: newPhoneNumbersList,
        loadedDids: true,
      }
    case DELETEPHONENUMBERSFROMMANAGER_HTTP_FAILURE:
      return {
        ...state,
        successMsg: null,
        errorMsg: parseError(action.error.response.text),
        errorCode: action.error.response.status
      }
    case DESELECT_SELECTED_NUMBER:
      return {
        ...state,
        selectedNumber: null,
        editPhoneNumberForm: null
      }
    case NAVIGATE:
      if (action.route !== 'dids') {
        return {
          ...state,
          loadedDids: false,
        }
      }
    default:
      return {...state}
  }
}