import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import { connect } from 'react-redux'

import FormControlCard from 'presentational/FormControlCard'
import StandardSettingsPage from 'presentational/StandardSettingsPage'
import Footer from 'presentational/Footer'
import SaveChangesModal from 'presentational/SaveChangesModal'

import ForwardingIcon from '@material-ui/icons/CallMissed'
import NotificationIcon from '@material-ui/icons/NotificationsActive'
import CallerIDIcon from '@material-ui/icons/PhoneInTalk'
import ConfirmIcon from '@material-ui/icons/CheckBox'
import OnlyExternalIcon from '@material-ui/icons/PhoneForwarded'

import AlwaysForm from './always'
import BusyForm from './busy'
import FollowMeForm from './follow'

import { errorNotification, successNotification } from 'actions/notifications'

const DEFAULT_COUNTRY_CODE = '+1'

class CallForwardingForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      form: {},
      option: {
        cfw_enabled_selected: ''
      },
      rollBackForm: null,
      timeConditions: null,
      rollBackTimeConditions: null,
      cfw_busy_application_friendlyname: '',
      showingSaveChangesModal: false,
      countryCode: DEFAULT_COUNTRY_CODE
    }
    this.addedUnsavedListener = false
    this.storedFetchedData = false
  }

  componentDidMount () {
    if (this.state.rollBackForm == null) {
      let cfw_enabled_selected = ''
      const { form } = this.props

      if(form.cfw_busy_value == "" && form.cfw_busy_application === 'conferenceroom'){
        form.cfw_busy_value = "No Room"
      }

      if (form.cfw_always_enabled === '1') {
        cfw_enabled_selected = 'always'
      } else if (form.cfw_busy_enabled === '1') {
        cfw_enabled_selected = 'busy'
      } else if (form.cfw_follow_me === '1') {
        cfw_enabled_selected = 'follow'
      }
      const position = form.config.applications.findIndex(
        item => item.name === form.cfw_busy_application
      )
      const cfw_busy_application_friendlyname =
        form.config.applications[position].friendlyname

      // Used lodash.clonedeep (imported as cloneDeep) to clone the current values of the form to another variable rollBackForm
      // We don't want any object or array inside the rollBackForm to share same reference with form so we used this function which copies value recursively.
      const rollBackForm = cloneDeep(this.props.form)
      const rollBackTimeConditions = cloneDeep(this.props.timeConditions)
      if(rollBackTimeConditions && rollBackTimeConditions.occurrence === null){
        rollBackTimeConditions.occurrence = "all-times"
        rollBackTimeConditions.timezone_country = ""
        rollBackTimeConditions.timezone = ""
      }

      this.setState({
        form: this.props.form,
        option: { cfw_enabled_selected },
        cfw_busy_application_friendlyname,
        rollBackForm, 
        rollBackTimeConditions
      })
    }
    if (this.addedUnsavedListener === false) {
      this.clickedElement()
      this.addedUnsavedListener = true
    }
  }

  componentDidUpdate () {
    if (
      this.props.timeConditions &&
      !this.storedFetchedData
    ) {
      this.storedFetchedData = true
      this.setState({
        timeConditions: this.props.timeConditions,
      })
    }
  }

  clickedElement = () => {
    var elements = document.querySelectorAll('.header, .subnav')
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener('click', () => {
        this.unsavedChanges()
      })
    }
  }

  unsavedChanges = () => {
    if (
      isEqual(this.state.form, this.state.rollBackForm) === false ||
      this.props.change || isEqual(this.state.timeConditions, this.state.rollBackTimeConditions) === false
    ) {
      this.setState({
        showingSaveChangesModal: true
      })
    }
  }

  revertChanges = () => {
    // Used lodash.clonedeep (imported as cloneDeep) to clone the current values of the rollBackForm to another variable form
    // We don't want any object or array inside the rollBackForm to share same reference with form so we used this function which copies value recursively.
    const form = cloneDeep(this.state.rollBackForm)
    const timeConditions = cloneDeep(this.state.rollBackTimeConditions)
    this.setState({
      form,
      timeConditions,
      showingSaveChangesModal: false
    })
  }

  updateNumber = (field, data, countryData) => {
    if(countryData.dialCode === "1" && data.length === 10){
      this.changeCondition(field, `1${data}`)
    }else {
      this.changeCondition(field, data)
    }
  }

  changeCondition = (field, data) => {
    const { form } = this.state
    if (field === 'cfw_busy_value' && data === 'Select option') data = ''
    else if (
      field === 'cfw_busy_value' &&
      this.state.cfw_busy_application_friendlyname === 'Extension'
    ) {
      const position = app.token.attributes.users.findIndex(
        item => data === item.fullextension
      )
      form.cfw_busy_value = app.token.attributes.users[position].extension
      this.setState({ form })
    } else if (
      field === 'cfw_busy_value' &&
      this.state.cfw_busy_application_friendlyname === 'Voicemail'
    ) {
      const position = form.config.voicemailboxes.findIndex(
        item => data === `${item.mailbox} - ${item.fullname}`
      )
      form.cfw_busy_value = form.config.voicemailboxes[position].mailbox
      this.setState({ form })
    }else if (
      field === 'cfw_busy_value' &&
      this.state.cfw_busy_application_friendlyname === 'Conference Room'
    ) {
      if(data === "No Room"){
        data = ""
      }else{
        data = data.split(" -")[0]
      }
      form[field] = data;
      this.setState({ form })
    } else if (field === 'cfw_confirm_call' && data === false){
      
      // If we toggle off call confirm, also set 
      // the cfw_confirm_only_external_call to 0
      if (data === true) data = '1'
      else if (data === false) data = '0'
      form[field] = data
      form["cfw_confirm_only_external_call"] = '0';
      this.setState({ form })

    } else if (field === 'cfw_enabled_selected') {
      const { option } = this.state
      option.cfw_enabled_selected = data
      form.cfw_always_enabled = data === 'always' ? '1' : '0'
      form.cfw_busy_enabled = data === 'busy' ? '1' : '0'
      form.cfw_follow_me = data === 'follow' ? '1' : '0'
      this.setState({ option, form })
    } else {
      if (data === true) data = '1'
      else if (data === false) data = '0'
      form[field] = data
      this.setState({ form })
    }
    if (field ==='cfw_ring_extension' && data === 'simultaneous'){
      const ringSequential = '0';
      this.setState({ form: { ...form, cfw_ring_sequential: ringSequential}});
    }
  }

  changeTimeCondition = timeConditions => {
    this.setState({ timeConditions })
  }

  renderAdditionalFields = () => {
    const {form} = this.state
    switch (this.state.option.cfw_enabled_selected) {
      case 'always':
        return <AlwaysForm parentReference={this} />
      case 'busy':
        return <BusyForm parentReference={this} />
      case 'follow':
        return (
          <FollowMeForm
            parentReference={this}
            timezones={form.config.timezones}
          />
        )
      default:
        return null
    }
  }

  onSubmit = cancel => {
    if (cancel) {
      app.navigate('#userportal', { trigger: true })
    } else {
      if(this.state.timeConditions && this.state.timeConditions.occurrence === "all-times"){
        let timeConditions = {...this.state.timeConditions, timeConditions: [], timezone: "", timezone_country: ""}
        this.setState({timeConditions}, (form, timeConditions) => this.props.editRequest(this.state.form, this.state.timeConditions))
      }else{
        this.props.editRequest(this.state.form, this.state.timeConditions)
      }
      const { form } = this.state
      if(form.cfw_busy_application == "conferenceroom" && form.cfw_busy_value == "No Room"){
        form.cfw_busy_value = ""
      }
      // Used lodash.clonedeep (imported as cloneDeep) to clone the new values of the form to another variable rollBackForm
      // We don't want any object or array inside the rollBackForm to share same reference with form so we used this function which copies value recursively.
      const rollBackForm = cloneDeep(form)
      const rollBackTimeConditions = cloneDeep(this.state.timeConditions);
      this.setState({ form, rollBackForm, rollBackTimeConditions, showingSaveChangesModal: false })
    }
  }

  render () {
    return (
      <StandardSettingsPage>
        <FormControlCard
          form={this.state.option}
          field='cfw_enabled_selected'
          icon={<ForwardingIcon />}
          formControl='RadioInput'
          title='Forward Condition'
          subtitle=''
          submitForm={(field, data) => this.changeCondition(field, data)}
          options={['Always Forward', 'Forward on Busy', 'Follow Me']}
          values={['always', 'busy', 'follow']}
        />
        {this.renderAdditionalFields()}
        <FormControlCard
          form={this.state.form}
          field='cfw_notify_caller'
          formControl='ToggleInput'
          icon={<NotificationIcon />}
          title='Notify Caller:'
          subtitle='Let callers know they are being forwarded'
          submitForm={(field, data) => this.changeCondition(field, data)}
          label='No / Yes'
        />
        <FormControlCard
          form={this.state.form}
          field='cfw_send_original_caller_id'
          formControl='ToggleInput'
          icon={<CallerIDIcon />}
          title='Caller ID:'
          subtitle="Use original caller's Caller ID when forwarding"
          submitForm={(field, data) => this.changeCondition(field, data)}
          label='No / Yes'
        />
        <FormControlCard
          form={this.state.form}
          field='cfw_confirm_call'
          formControl='ToggleInput'
          icon={<ConfirmIcon />}
          title='Confirm Call:'
          subtitle='Ask if you would like to receive a forwarded call'
          submitForm={(field, data) => this.changeCondition(field, data)}
          label='No / Yes'
        />
        {this.state.form.cfw_confirm_call === '1' && (
          <FormControlCard
            form={this.state.form}
            field='cfw_confirm_only_external_call'
            icon={<OnlyExternalIcon />}
            formControl='SelectInput'
            title='External Calls Only:'
            submitForm={(field, data) => this.changeCondition(field, data)}
            options={[
              { label: 'Yes', value: '1' },
              { label: 'No', value: '0' }
            ]}
          />
        )}
        <div style={{ height: '30px' }} />
        <Footer
          disabled={
            isEqual(this.state.form, this.state.rollBackForm) &&
            !this.props.change && isEqual(this.state.timeConditions, this.state.rollBackTimeConditions)
          }
          onClick={cancel => this.onSubmit(cancel)}
          fullWidth
        />
        <SaveChangesModal
          show={this.state.showingSaveChangesModal}
          saveChange={() => this.onSubmit(false)}
          onClose={() => this.onSubmit(true)}
          discardChange={() => this.revertChanges()}
        />
      </StandardSettingsPage>
    )
  }
}

const bindActions = dispatch => ({})

const mapStateToProps = state => ({
  timeConditions: state.callForwarding.timeConditionsData,
})

CallForwardingForm.propTypes = {
  form: PropTypes.object,
  editRequest: PropTypes.func,
  change: PropTypes.bool.isRequired
}

export default connect(
  mapStateToProps,
  bindActions
)(CallForwardingForm)
