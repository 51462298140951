import moment from 'moment';

export const timeSrt = (key) => {

    return (a, b) => {
        if (moment.utc(moment(a[key])) > moment.utc(moment(b[key]))) return 1;
        if (moment.utc(moment(a[key])) < moment.utc(moment(b[key]))) return -1;
        return 0;
    };
};

export const keySrt = (key, asc) => {

    if (asc) {
        return (a, b) => {
            if (a[key] > b[key]) return 1;
            if (a[key] < b[key]) return -1;
            return 0;
        };
    }
    return (a, b) => {
        if (a[key] > b[key]) return -1;
        if (a[key] < b[key]) return 1;
        return 0;
    };
};

export const validateEmail = (email) => {
    let emailReg = new RegExp(/(^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)i)/g)
        if(emailReg.test(email)){
            return null;
        }
        return 'Please enter a valid email address';
}

export const nameSrt = (key, asc) => {

    if (asc) {
        return (a, b) => {
            if (a[key].toLowerCase() > b[key].toLowerCase()) return 1;
            if (a[key].toLowerCase()< b[key].toLowerCase()) return -1;
            return 0;
        };
    }
    return (a, b) => {
        if (a[key].toLowerCase() > b[key].toLowerCase()) return -1;
        if (a[key].toLowerCase() < b[key].toLowerCase()) return 1;
        return 0;
    };
};


export const nestedKeySrt = (key, nestedKey, asc) => {
    if (asc) {
        return (a, b) => (
            (a[key][nestedKey] > b[key][nestedKey]) ? 1 : -1
        );
    }

    return (a, b) => (
        (a[key][nestedKey] > b[key][nestedKey]) ? -1 : 1
    );
};

export const sortContactsByFirstName = (a, b) => {

    if (!a.names || a.names.length < 1 || !a.names[0].givenName) {
        return -1;
    }

    if (!b.names || b.names.length < 1 || !b.names[0].givenName) {
        return 1;
    }

    if (a.names[0].givenName > b.names[0].givenName) {
        return 1;
    } else {
        return -1;
    }
};

// formats date (for recent)
export const formatDateForRecent = (date) => {

    // get today
    const today = moment();

    // if <= 7 days, let's return the from now date
    if (today.diff(date, 'days') <= 7) {

        return moment(date).fromNow();
    }

    // otherwise return locale date
    return moment(date).format('llll');
};

// formats date MM-DD-YY HH:mm:ss
export const formatDateTime = (date) => {

    // if (includeTimezone) {

        return moment(new Date(date)).utc().format('llll')
    // }
};

export const formatDateReportsService = (date) => {
    return moment(date).format('llll')
}

export const formatDateAPI = (date) => {
    var date = new moment(date);
    return date.format('MM D YYYY, h:mm:ss a');
}

export const formatDateTimePicker = (date) => {
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

export const formatDatePicker = (date) => {
    return moment(date).format('YYYY-MM-DD')
}

// Calculate the HH:mm:ss difference between two dates
export const twoDateTimeDifference = (startdate, enddate) => {
    return moment.utc(moment(enddate).diff(moment(startdate), 'seconds')*1000).format('HH:mm:ss');
}

export const secondsToHours = (seconds) => {
    return moment.utc(seconds*1000).format('HH:mm:ss');
}

export const parseError = (error) => {
    const defaultError = 'Error occurred';
    try{
      let parsedError = JSON.parse(error);
      return get(parsedError, 'error.message', defaultError);
    } catch(error) {
      return defaultError;
    }
}
