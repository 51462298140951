import React, { Component } from 'react';

import { makeStyles } from '@material-ui/styles';

import HelpContent from './helpContent';
class NotificationWorkflowHelp extends Component {

    constructor(props) {
      super(props);
  
      this.state = { mediaQuery: false };
    }

    componentDidMount() {
      // Media query for responsive UI
      const mq = window.matchMedia('(min-width: 800px)');
      this.setState({ mediaQuery: mq });
      mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
    }

    render(){
      const minWidth = this.state.mediaQuery.matches;
      const useStyles = makeStyles({
        wrapper: {
          display: 'flex',
          flex: 1,
          height: '100%',
          background: 'linear-gradient(0deg, #0F4E8B 50%, #EFEFEF 50%)',
          paddingLeft: !minWidth ? 0 : '3vw',
          paddingRight: !minWidth ? 0 : '10vw',
          alignItems: 'center',
        },
        helpContent: {
          padding: '1.5%',
        },
        helpPaper: {
          width: !minWidth ? '100%' : '50%',
          height: !minWidth ? '100%' : null,
          minWidth: '50%',
          position: 'absolute',
          top: '80px',
        },
        pageTitle: {
          fontWeight: '400',
        },
        helpText: {
          fontSize: '13px',
        },
        helpTextTitle: {
          color: '#0F4E8B',
          fontSize: '15px',
          padding: '0px 16px 0px 16px',
        },
        standardHelpBlurb: {
          padding: '1.5%',
          borderTop: '1px solid #E0E0E0',
          backgroundColor: '#FAFAFA',
        },
    });

    return(
      <HelpContent useStyles={useStyles} />
    )
  }
}

export default NotificationWorkflowHelp;