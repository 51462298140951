import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const styles = {
    discardButton: {
        color: '#e93c2c',
        fontSize: '100%',
    },
    saveButton: {
        color: '#2a72b9',
        fontSize: '100%',
    },
    dialogContent: {
        marginTop: '5px',
    }
};

const ConfirmationModal = ({ classes, show, cancelModal, saveChange, discardChange }) => {
    return (
        <Dialog open={Boolean(show)} onClose={cancelModal} fullWidth={true}>
            <DialogContent>
                <div style={styles.dialogContent}>What would you like to do with your changes?</div>
            </DialogContent>
            <DialogActions>
                <Button classes={{ root: classes.saveButton }} color="primary" onClick={saveChange}>
                    Save Changes
                </Button>
                <Button
                    classes={{ root: classes.discardButton }}
                    color="primary"
                    onClick={discardChange}
                >
                    Discard Changes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const classesObj = {
    button: PropTypes.string,
};

ConfirmationModal.defaultProps = {
    cancelModal: () => null,
    discardChange: () => null,
    saveChange: () => null,
};

ConfirmationModal.propTypes = {
    cancelModal: PropTypes.func.isRequired,
    classes: PropTypes.shape(classesObj),
    discardChange: PropTypes.func.isRequired,
    saveChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ConfirmationModal);
