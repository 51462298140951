import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';

import { withStyles } from '@material-ui/styles';

import ErrorCard from 'presentational/ErrorCard';
import TextInput from 'presentational/formControls/TextInput';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';
import Footer from 'presentational/Footer';
import AddIcon from '@material-ui/icons/AddCircle';
import DragAndDrop from 'presentational/DragAndDrop';
import AutoComplete from 'presentational/autoComplete';
import { formatNumber } from 'utils/phoneNumbers';

const styles = () => ({
  root: {
    minWidth: 400, 
  },
  container:{
    display: 'grid',
    gridTemplateColumns: 'auto 30px',
    justifyContent: 'center',
    alignItems: 'center',
    width: 400,
  },
  button:{
    minWidth: '25px',
  },
  addIcon: {
    float: 'right',
    color: 'rgb(42, 114, 185)',
  },
});


import {
    errorNotification,
    successNotification,
} from 'actions/notifications';
import Button from '@material-ui/core/Button';

class Members extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: [],
            externalPhoneNumber: '',
            errors: {},
            disabled: true,
            form: {},
            rollbackForm: {},
            submitError: null,
            searchText: '',
            newChanges: false,
        };
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.handleClick, false)
      this.setState({ form: this.props.data }, ()=> this.createRollbackForm());
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data) {
            this.setState({ form: nextProps.data, disabled: true });
        }
    }

    userStringBuilder(arrayToJoin){
      let userString = '';
      arrayToJoin.map((user, index) => {
        if (index !== arrayToJoin.length - 1) {
          userString += `${user}&`;
        } else {
          userString += user;
        }
      });

      return userString;
    }

    createRollbackForm() {
      const rollbackForm = Object.assign({}, this.state.form);
      this.setState({ rollbackForm });
    }

    handleClick = (e) => {
      if (this.node) {
        if (!this.node.contains(e.target)){
          this.props.clickedNode();
        }
      }
    }

    async submitForm(field, data) {
      const { form } = this.state;

      await this.setState({
        newChanges:true,
        disabled:false
      }, ()=> this.props.madeChanges(this.state.newChanges, this.state.form))


      if (this.state.disabled == true) {
        this.setState({disabled: false});
      }

      // Lets validate our field
      const error = this.validateField(field, data);
      if (error) {
          this.setState({
              errors: { [field]: error },
              form: rollbackForm,
          });

          errorNotification({ message: 'Please fix errors on form' });
          return;
      }

      // If no error, lets set the data
      // on the form and set it to state without errors
      form[field] = data;
      this.setState({
          form,
          errors: { [field]: '' }})
    }


    validateField(field, data) {

        switch (field) {

        case 'externalPhoneNumber':

            // We only want to allow numbers
            if (data.match(/[^0-9]/) || data.length !== 10) {
              this.setState({phoneError: 'Please enter a 10 digit phone number'});
            } else if(this.props.data.value.includes(data)){
              this.setState({phoneError: `This phone number is already in call group ${this.props.data.friendlyname}`});
            } else {
              this.submitPhoneNumber(data)
              this.setState({phoneError: null});
            }
            break;
        case 'member':
            const found = this.props.data.config.users.findIndex(obj => obj.extension == data.value);
            if (found == -1) {
              this.setState({ memberError: 'No user found, please try your search again' })
            } else {
              this.setState({ memberError: null });
              this.submitPhoneNumber(data)
            }
            break;
        default:
            return null;
        }
    }

    handleSelectedAddition(item) {
      let updatedSelectedUsers = [];
      if(item.id){
        if (this.props.data.value && !this.props.data.value.includes(item.id)) {
          updatedSelectedUsers = this.props.data.value.split('&');
        }
        updatedSelectedUsers.push(item.id)
        let userString = this.userStringBuilder(updatedSelectedUsers);
        this.submitForm('value', userString)
      }
    }

    handleSelectedDeletion(item){
      let updatedSelectedUsers = [];
      if(item.id && this.props.data.value && this.props.data.value.includes(item.id)){
        updatedSelectedUsers = this.props.data.value.split('&');
        updatedSelectedUsers.map((user, index) => {
          if(user == item.id){
            updatedSelectedUsers.splice(index, 1);
          }
        });

        let userString = this.userStringBuilder(updatedSelectedUsers);
        this.submitForm('value', userString)
      }
    }

    addPhoneNumberToState(data){
      let externalPhoneNumber = this.state;
      externalPhoneNumber = data;
      this.setState({externalPhoneNumber})
    }

    submitPhoneNumber(req){
      let selectedUsers = [];
      if(this.props.data  && this.props.data.value){
        selectedUsers = this.props.data.value.split ('&');
      }
      if (req.value) {
        selectedUsers.push(req.value);
        this.setState({ searchText: '', });
      }else{
        selectedUsers.push(this.state.externalPhoneNumber)
        this.setState({ externalPhoneNumber: '' });
      };

      let userString = this.userStringBuilder(selectedUsers);
      this.setState({externalPhoneNumber: ''})
      this.submitForm('value', userString);
    }

    updateOrder(updatedUserData){
      let value = "";
      const { itemIds } = updatedUserData.columns.right;
      itemIds.map((user, index) => {
        if(index !== itemIds.length-1){
          value += `${user}&`;
        } else {
          value += user;
        }
      });
      this.submitForm('value', value);
    }

    handleRemoveFilter(updatedValues){
      let newValue = "";
      updatedValues.map((obj, index) =>{
        if(index !== updatedValues.length-1){
          newValue += `${obj.value}&`;
        } else{
          newValue += obj.value;
        }
      });
      this.submitForm('value', newValue)
    }

    handleUpdateInput(searchText){
      this.setState({ searchText, })
    }

    submitFullForm(cancel) {
      if (cancel) {
        this.props.cancel();
      } else {
        this.props.submitForm(this.state.form);
      }
    }

    render() {
      const { classes } = this.props;
      if (!this.props.data || !this.props.data.config || !this.state.form) {
        return (
          <StandardSettingsPage>
            <Loading />
          </StandardSettingsPage>
        );
      }

      let selectedUsers = [];
      if (this.props.data.value && this.props.data.value.length > 0) {
        selectedUsers = this.props.data.value.split("&");
        selectedUsers.map((number, index) => {
          let formattedNumber = number;
          if(number.length == 10){
            formattedNumber = formatNumber(number);
          }
          selectedUsers[index] = {value: number, label: formattedNumber}
        });
      }

      let users = [];

      if (this.props.data.config && this.props.data.config.users && this.props.data.config.users.length){
        this.props.data.config.users.map((user) => {
          let label = user.firstname || user.lastname ? `${user.extension}-${user.firstname} ${user.lastname}` : `${user.extension}`
          let userObj = {
            value: user.extension,
            label,
          };
          selectedUsers.map((selectedUser, index) =>{
            if(userObj.value == selectedUser.value){
              selectedUsers[index] = userObj;
              userObj = false;
            }
          });
          if(userObj){
            users.push(userObj);
          }
        });
      }

      let draggableSelectedUsers = {};
      let selectedUserIds = [];
      let allUsers = users.concat(selectedUsers);
      let items = {};
      let userSearchData = [];
      users.map(user => {
        userSearchData.push({ text: user.label, value: user.value })
      });
      userSearchData.sort((a, b) => (parseInt(a.value)) > parseInt(b.value) ? 1 : -1);

      selectedUsers.map(user => {
        draggableSelectedUsers[user.value] = { id: user.value, content: user.label };
        selectedUserIds.push(user.value)
      });
      allUsers.map(user => {
        items[user.value] = { id: user.value, content: user.label };
      });

      const initialData = {
        items,
        columns: {
          'right': {
            id: 'right',
            title: 'Selected Users and Numbers',
            itemIds: selectedUserIds,
          }
        },
        columnOrder: ['right']
      };

        return (
          <div ref={node => this.node = node} style={{
            display: 'flex',
            flex: 1,
            height: '100%',
            background: '#EFEFEF',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '50px',
            overflow: 'auto',
          }}>
            <StandardSettingsPage>
              {this.state.submitError ?
                <ErrorCard data={this.state.submitError} />
                : null
              }
              <div className={classes.container}>
              <TextInput
                field="externalPhonenumber"
                placeholder="Add External Phone Number"
                errorText={this.state.phoneError}
                style={style.numberInput}
                onChange={(val) => {
                  this.addPhoneNumberToState(val)
                  this.setState({ newChanges: true})}
                }
                value={this.state.externalPhoneNumber}
              />
              <Button
                classes={{ root: classes.button }}
                onClick={() => {
                  this.validateField('externalPhoneNumber', this.state.externalPhoneNumber)
                }}>
                <AddIcon classes={{ root: classes.addIcon }} />
              </Button>
              </div>
              <AutoComplete
                style={{marginLeft: '-31px'}}
                listStyle={{ maxHeight: 200, overflow: 'auto' }}
                floatingLabelText="Search Users to Select"
                filter={AutoComplete.fuzzyFilter}
                errorText={this.state.memberError}
                searchText={this.state.searchText}
                onUpdateInput={(searchText) => this.setState({ searchText, })}
                onNewRequest={(req) => this.validateField('member', req)}
                openOnFocus={true}
                suggestions={userSearchData}
                title="Search Users to Select"
              />
              <DragAndDrop
                updateOrder={(updatedUserList) => this.updateOrder(updatedUserList)}
                handleSelectedDeletion={(item) => this.handleSelectedDeletion(item)}
                handleSelectedAddition={(item) => this.handleSelectedAddition(item)}
                data={initialData}
                columnPlaceholder="Search Users"
              />
              <Footer
                disabled={this.state.disabled}
                onClick={(cancel) => this.submitFullForm(cancel)}
              />
            </StandardSettingsPage>
          </div>
        );
    }
}
const style= {
  numberInput:{
    padding: 0,
    width: 300,
  },
}
const bindActions = (dispatch) => ({});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, bindActions)(withStyles(styles)(Members));
