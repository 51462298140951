import React, { Component } from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/styles';

import Button from '@material-ui/core/Button';

import ErrorCard from 'presentational/ErrorCard';
import FormControlCard from 'presentational/FormControlCard';
import Loading from 'presentational/Loading';
import NewCardPlaceholder from 'presentational/NewCardPlaceholder';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import SelectInput from 'presentational/formControls/SelectInput';

import SkillCard from './skillCard';
import SkillForm from './skillForm';
import NewSkillForm from './newSkillForm';

import { getCoreHttp } from 'actions/httpRequest';

const style = () => ({
    dialogContent: {
        width: '500px',
    },
    dialogContentSmall: {
        width: '95%',
    },
    button: {
        fontSize: '100%',
    }
})

class Skills extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            form: [],
            submitError: null,
            showingModal: false,
            mediaQuery: false,
            skillRemoval: false,
            newSkillForm: {
                skill: '',
                level: '0',
            },
            formErrors: {
                skill: '',
            },
        };
    }

    componentDidMount() {

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));

            this.fetchQueues();


        // If we don't have skills, lets return
        if (!this.props.skills) {
            return;
        }

        this.setState({ form: this.props.skills });
    }

    componentWillReceiveProps(nextProps) {

        // If we don't have skills, lets return
        if (!nextProps.skills) {
            return;
        }

        this.setState({ form: nextProps.skills });
    }

    fetchQueues() {

        // Lets set up the request data to retreive auto attendants
        const reqData = {
            reqAction: 'queue',
        };

        const storeKey = 'queues';

        this.props.getCoreHttp(reqData, storeKey);
    }

    async submitForm(submittedForm) {

        const form = submittedForm || this.state.form;

        console.log('SUBMITTING FORM: ', form);
        this.props.skillRemovalStatus (this.state.skillRemoval);
        this.setState({skillRemoval: false});

        // If no error, lets set the data
        // on the form and set it to state without errors
        this.setState({
            form,
            errors: {},
        });

        const submitError = await this.props.submitForm(form);

        if (submitError) {
            this.setState({ submitError });
        } else {
            this.setState({ submitError: null });
        }
    }

    validateField(field, data) {

        return null;
    }

    updateForm(field, value) {

        const { newSkillForm } = this.state;

        newSkillForm[field] = value;

        this.setState({ newSkillForm });
    }

    async removeSkill(index) {

        this.state.form.splice(index, 1);
        await this.setState({skillRemoval: true});
        this.submitForm(this.state.form);
    }


    renderSkillCard(skillObj, index) {

        return (
            <SkillCard
              form={this.state.form}
              data={skillObj}
              key={skillObj.skill}
              index={index}
              submitForm={(form) => this.submitForm(form)}
              removeSkill={(index) => this.removeSkill(index)}
            />
        );
    }

    handleClose(submit) {

        if (submit) {

            const { newSkillForm, form } = this.state;
            this.setState({ formErrors: { skill: '' } });
            if (!newSkillForm.skill) {

                this.setState({ formErrors: { skill: 'Please select a skill' } });
                return;
            }
            newSkillForm.agent_id = this.props.data.agent_id;
            form.push(newSkillForm);
            this.submitForm(form);
        }

        this.setState({
            showingModal: false,
            newSkillForm: { skill: '', level: '0' },
        });
    }

    render() {

        if (!this.props.data || !this.props.skills || !this.props.queues || !this.state.form) {
            return (
              <StandardSettingsPage>
                <Loading />
              </StandardSettingsPage>
            );
        }


        const actions = [
            ,
        ];

        const { form } = this.state;
        const minWidth = this.state.mediaQuery.matches;
        const { classes } = this.props;

        return (
        <div style={{
            display: 'flex',
            flex: 1,
            height: '100%',
            background: '#EFEFEF',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '50px',
            overflow: 'auto',
        }}>
          <StandardSettingsPage>

            <NewCardPlaceholder
              title="Add new skill"
              onClick={() => this.setState({ showingModal: true })}
            />

            {this.state.submitError ?
              <ErrorCard data={this.state.submitError} />
              : null
            }

            {form.map((skill, i) => {
                return this.renderSkillCard(skill, i);
            })}


            <Dialog
              actions={actions}
              open={this.state.showingModal}
              onClose={() => this.handleClose(false)}
              fullWidth={true}
            >
                <DialogTitle>Add new skill for agent</DialogTitle>
                    <DialogContent classes={{ root: minWidth ? classes.dialogContent : classes.dialogContentSmall }}>
                        <NewSkillForm
                            usedSkills={form}
                            form={this.state.newSkillForm}
                            formErrors={this.state.formErrors}
                            updateForm={(field, value) => this.updateForm(field, value)}
                            tenant={this.props.data.tenant}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            classes={{ root: classes.button }}
                            color='primary'
                            onClick={() => this.handleClose(false)}
                        >Cancel</Button>
                        <Button
                            classes={{ root: classes.button }}
                            color='primary'
                            onClick={() => this.handleClose(true)}
                        >Submit</Button>
                    </DialogActions>
            </Dialog>

          </StandardSettingsPage>
          </div>
        );
    }
}

const bindActions = (dispatch) => ({
    getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
});

const mapStateToProps = (state) => ({
    queues: state.queues.data,
});

export default connect(mapStateToProps, bindActions)(withStyles(style)(Skills));
