import { ReactMic } from 'react-mic';
import React, {Component}          from 'react';
import FloatingActionButton        from '@material-ui/core/Fab';
import MicrophoneOn                from '@material-ui/icons/Mic';
import MicrophoneOff               from '@material-ui/icons/Stop';
import SaveIcon from '@material-ui/icons/Save';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { withStyles } from '@material-ui/core/styles';
import { save } from 'save-file';
import PropTypes from 'prop-types';

class ReactMicrophone extends Component {
  constructor(props){
    super(props);
    this.state = {
      blobObject: null,
      isRecording: false,
      isStopped:false,
      noRecording:true,
    }
  }

  startOrStopRecording= () => {
    const { isStopped, isRecording } = this.state

    if(isStopped) {
      this.setState({ isStopped: false })
    } else if(isRecording) {
      this.setState({ 
        isStopped: true,
        isRecording: false,
        noRecording: false})
    } else {
      this.setState({ isRecording: true })
    }
  }

  onStop= (blobObject) => {
    this.setState({ blobURL : blobObject.blobURL, isStopped: false, blobObject });
    this.props.upload(blobObject);
  }

  render() {
    const { blobURL, isRecording } = this.state;
    const { classes } = this.props;
    return(
      <div style={{display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center' }} >
          <ReactMic
            style={{width:'500px'}}
            className="oscilloscope"
            record={isRecording}
            width={500}
            backgroundColor="#2a72b9"
            visualSetting="sinewave"
            audioBitsPerSecond= {this.props.audioBitsPerSecond}
            onStop={this.onStop}
            onStart={this.onStart}
            onSave={this.onSave}
            onData={this.onData}
            strokeColor="#ffffff" />
            <div style={{display:'flex', flexDirection:'row', justifyContent:'center' }} >
            <FloatingActionButton
              classes={{ root: classes.floatingActionButton }}
              className="btn"
              onClick={this.startOrStopRecording}>
              { (isRecording) ? <MicrophoneOff /> : <MicrophoneOn /> }
            </FloatingActionButton>
            {!this.props.noSave && 
              <FloatingActionButton
                classes={{ root: classes.floatingActionButton }}
                className="btn"
                disabled={this.state.noRecording}
                onClick={() => {
                  this.props.createRecording()

                  if(this.props.clearInput) {
                    this.props.clearInput()
                  }
                }}>
                <SaveIcon />
              </FloatingActionButton>}
            {this.props.showDownloadButton && 
              <FloatingActionButton
                classes={{ root: classes.floatingActionButton }}
                className="btn"
                disabled={this.state.noRecording}
                onClick={() => {
                  const fileName = (Math.random() * 1000).toString().replace(".", "");
                  save(this.state.blobObject.blob, fileName);
                }}>
                <DownloadIcon />
              </FloatingActionButton>}
          </div>
          <audio style={{margin: '20px'}}ref="audioSource" controls preload="auto" src={blobURL}></audio>
        </div>
    );
  }
}

ReactMicrophone.defaultProps = {
  noSave: false,
  showDownloadButton: false,
  audioBitsPerSecond: 128000,
};

ReactMicrophone.propTypes = {
  noSave: PropTypes.bool,
  showDownloadButton: PropTypes.bool,
  audioBitsPerSecond: PropTypes.number,
  createRecording: PropTypes.func,
  upload: PropTypes.func,
  clearInput: PropTypes.func,
};

const style = () => ({
  floatingActionButton: {
    boxShadow: 'none',
    margin: '5px',
    backgroundColor: '#2a72b9',
    color: 'white',
    '&:hover': {
      backgroundColor: '#3078cf',
      color: 'white',
    },
  },
});

export default withStyles(style)(ReactMicrophone);
