import React from 'react';
import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InfiniteScroll from 'react-infinite-scroll-component';
import Divider from '@material-ui/core/Divider';

import {
    formatDateForRecent,
    keySrt,
    sortContactsByFirstName } from 'utils/misc';

import { formatNumber } from 'utils/phoneNumbers';

import { markMessagesUnread, selectConversation, updateTextForContactSearch } from 'actions/sms';

import ContactRow from 'presentational/ContactRow';
import withStyles from '@material-ui/styles/withStyles';
import SmsToolbar from './smsToolbar';

let style = {
    conversationsWrapper: {
        width: 350,
        borderRightWidth: 1,
        borderRightColor: '#DFDFDF',
        borderRightStyle: 'solid',
        position: 'absolute',
        bottom: 0,
        top: 135,
    },
    scrollSection: {
        overflow: 'auto',
        overflowX: 'hidden',
        maxHeight: '50vh',
    },
    noDidSelected: {
        textAlign: 'center',
        fontSize: 16,
        color: '#737373',
        fontWeight: 'bold',
        padding: 50,
    }
};

const matStyles = () => ({
    listStyle: {
        display: 'flex',
        flexDirection: 'column',
        listStyleType: 'none',
    },
});

const Conversations = (props) => {
    const [selectedDid, setSelectedDid] = React.useState()
    const [loadOffset, setloadOffset] = React.useState(15);
    const [totalContacts, setTotalContacts] = React.useState(0);
    const [totalUnread, setTotalUnread] = React.useState(0);
    const [totalConvo, setTotalConvo] = React.useState(0);
    const [currentContactsOffset, setcurrentContactsOffset] = React.useState(15);
    const [currentUnreadOffset, setcurrentUnreadOffset] = React.useState(15);
    const [currentConvoOffset, setcurrentConvoOffset] = React.useState(15);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(true);
    const [open3, setOpen3] = React.useState(true);

    if (!props.selectedInternalDid) {
        return <div style={style.noDidSelected}>Please select an internal number to view or send messages</div>;
    }
    //reset everything because it doesn't reset when you change DIDs
    if (selectedDid !== props.selectedInternalDid) {
        setSelectedDid(props.selectedInternalDid);
        setTotalContacts(0);
        setTotalUnread(0);
        setTotalConvo(0);
        setcurrentContactsOffset(15);
        setcurrentUnreadOffset(15);
        setcurrentConvoOffset(15);
        if (document.getElementById('contactsScroll')) {
            document.getElementById('contactsScroll').scrollTo({ top: 0, behavior: 'smooth' });
        }
        if (document.getElementById('unreadScroll')) {
            document.getElementById('unreadScroll').scrollTo({ top: 0, behavior: 'smooth' });
        }
        if (document.getElementById('convoScroll')) {
            document.getElementById('convoScroll').scrollTo({ top: 0 });
        }
    }


// If we are loading conversations, return a loader
    let loading = {
        position: 'relative',
        left:0,
        bottom:0,
        right:0,
        alignItems: 'center',
        alignSelf: "flex-end",
        display: 'flex',
        height: '80px',
        width: "100%",
        justifyContent: 'center',
        borderRightWidth: 1,
        borderRightColor: '#DFDFDF',
        borderRightStyle: 'solid',
        backgroundColor: "rgba(0,0,0,.1)",
        transition:"opacity .25s",
        opacity: "1"
    }
    if (props.loadingConversations || props.loadingContacts && !props.conversations) {
        //react complains about this, i suppose style should be an immutable prop? you don't say... gm 11/8/18
        loading.opacity = 1
    }else{
        loading.opacity = 0
    }
    // Lets order the conversations by lastActivity
    const orderedConversations = props && props.conversations && props.conversations.data ? props.conversations.data.sort( keySrt('lastActivity')  ) : [] ;
    const orderedUnreadConversations = props && props.conversations && props.conversations.unread ? props.conversations.unread.sort(keySrt('lastActivity')) : [];
    const orderedContacts = props && props.contacts ? props.contacts.sort(sortContactsByFirstName) : [];

    const contactsMap = (contacts = []) => {
        if (contacts.length < 1) {
            return []
        }
        return contacts.reduce((accum, contact) => {
            if (!contact.phoneNumbers || contact.phoneNumbers.length < 1) {
                return accum
            }
            contact.phoneNumbers.forEach(phoneNumber => {
                return accum[phoneNumber.value] = contact
            })
            return accum
        }, {})
    }

    const buildConversationList = (conversations, contacts) => {
        const allContacts = contactsMap(contacts)
        return conversations.map(convo => {
            convo.contact = {};
            if (allContacts[convo.participant]) {
                convo.hasContact = true;
                convo.contact = allContacts[convo.participant];
                convo.name = allContacts[convo.participant].names[0].givenName + " " + allContacts[convo.participant].names[0].familyName;
            }
            return convo
        });
    }

    const getConversation = (search = '', items, listName) => {
        let setLength;
        let currentLength = 0;
        let currentOffset = 0;
        if (listName === 'unread') {
            setLength = setTotalUnread;
            currentLength = totalUnread;
            currentOffset = currentUnreadOffset;
        }
        if (listName === 'conversations') {
            setLength = setTotalConvo;
            currentLength = totalConvo;
            currentOffset = currentConvoOffset;
        }
        search = search.toLowerCase();

        const filteredList = items.filter(item => {
            return ((item.participant && item.participant.toLowerCase().includes(search)) || (item.name && item.name.toLowerCase().includes(search))) && (item.messages && item.messages.length > 0)
        })

        if (currentLength !== filteredList.length) {
            setLength(filteredList.length)
        }

        const paginatedList = filteredList.slice(0, currentOffset)

        const selectedHash = props.selectedConversation && props.selectedConversation.conversation ?
        props.selectedConversation.conversation.hash : null

        return paginatedList.map((item) => {
            // find latest message
            let latest = [{message:''}];
            if (item.messages && item.messages.length > 0) {
                latest = item.messages.sort((a, b) => a.timestamp - b.timestamp);
                // return a row of a conversation
                return (
                  <ContactRow
                    key={item.hash}
                    selected={selectedHash == item.hash}
                    isContact={item.hasContact}
                    contact={item.contact}
                    number={formatNumber(item.participant)}
                    numberDisplay={item.hasContact ? formatNumber(item.participant) : null}
                    text={formatDateForRecent(item.lastActivity)}
                    secondaryText={`${latest[0].message.slice(0, 15)}...`}
                    rightIcon={getUnreadCount(item)}
                    selectRow={(avatar, contactInfo) => {
                        if (item.messages.some((message) => message.unread > 0)) {
                            props.markMessagesUnread(item);
                        }
                        return props.selectConversation(item, avatar, contactInfo);
                    }}
                  />
                );
            }
        })
    }

    const getContacts = (search = '', items) => {
        search = search.toLowerCase();
        const filteredList = items.filter(item => {
            item.names = item.names ? item.names : []
            item.phoneNumbers = item.phoneNumbers ? item.phoneNumbers : []
            const fullNames = item.names.reduce((accum, name) => {
               return accum + name.givenName + " " + name.familyName + " - "
            }, '').toLowerCase();
            const fullphoneNumbers = item.phoneNumbers.reduce((accum, phone) => {
                return accum +  phone.value + " " + phone.formatted + " - "
             }, '').toLowerCase();

            return fullNames.includes(search) || fullphoneNumbers.includes(search)
        });
        if (totalContacts !== filteredList.length) {
            setTotalContacts(filteredList.length)
        }

        const paginatedList = filteredList.slice(0, currentContactsOffset)

        return paginatedList.map((item) => {
            return (
                <ContactRow
                    key={item._id}
                    contact={item}
                    isContact
                />
            );
        });
    }

    function handleContactsClick(){
        if (open1) {
            setOpen1(false);
        } else {
            setOpen1(true);
            setOpen2(false);
            setOpen3(false);
        }
    }
    function handleUnreadClick(){
        if (open2) {
            setOpen2(false);
        } else {
            setOpen2(true);
            setOpen1(false);
            setOpen3(false);
        }
    }
    function handleConversationsClick(){
        if (open3) {
            setOpen3(false);
        } else {
            setOpen3(true);
            setOpen1(false);
            setOpen2(false);
        }
    }

    const loadMoreUnread = () => {
        setcurrentUnreadOffset(currentUnreadOffset + loadOffset)
    }
    const loadMoreConvo = () => {
        setcurrentConvoOffset(currentConvoOffset + loadOffset)
    }
    const loadMoreContacts = () => {
        setcurrentContactsOffset(currentContactsOffset + loadOffset)
    }

    return (
        <div
            id="conversationsListContainer"
            style={style.conversationsWrapper}
        >
        <List >
                <ListItem button onClick={handleContactsClick}>
                <ListItemText primary="Contacts" />
                {open1 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse in={open1} timeout="auto" unmountOnExit>
                <SmsToolbar
                    onChange = {(text) => props.updateTextForContactSearch(text)}
                    label="Contacts"
                    placeholder="Search Contacts"
                />
                <div id="contactsScroll" style={ style.scrollSection }>
                    <InfiniteScroll
                        dataLength={currentContactsOffset}
                        next={loadMoreContacts}
                        hasMore={totalContacts > currentContactsOffset}
                        loader={<h4>{totalContacts > currentContactsOffset ? '' : 'Loading...'}</h4>}
                        scrollableTarget="contactsScroll"
                    >
                        {getContacts(props.contactSearchText, orderedContacts)}
                    </InfiniteScroll>
                </div>
                </Collapse>
          <Divider />

          <ListItem button onClick={handleUnreadClick}>
            <ListItemText primary="Unread" secondary={orderedUnreadConversations.length > 0 ? '' : 'No Messages'} />
            {open2 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open2} timeout="auto" unmountOnExit>
          <div id="unreadScroll" style={ style.scrollSection }>
            <InfiniteScroll
                dataLength={currentUnreadOffset}
                next={loadMoreUnread}
                hasMore={totalUnread > currentUnreadOffset}
                loader={<h4>{totalUnread > currentUnreadOffset ? '' : 'Loading...'}</h4>}
                scrollableTarget="unreadScroll"
            >
                {getConversation(props.conversationSearchText, buildConversationList(orderedUnreadConversations, orderedContacts), 'unread')}
            </InfiniteScroll>
          </div>
          </Collapse>
          <Divider />

          <ListItem button onClick={handleConversationsClick}>
            <ListItemText primary="Conversations" secondary={orderedConversations.length > 0 ? '' : 'No Messages'} />
            {open3 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open3} timeout="auto" unmountOnExit>
          <div id="convoScroll" style={ style.scrollSection }>
            <InfiniteScroll
                dataLength={currentConvoOffset}
                next={loadMoreConvo}
                hasMore={totalConvo > currentConvoOffset}
                loader={<h4>{totalConvo > currentConvoOffset ? '' : 'Loading...'}</h4>}
                scrollableTarget="convoScroll"
            >
                {getConversation(props.conversationSearchText, buildConversationList(orderedConversations, orderedContacts), 'conversations')}
            </InfiniteScroll>
          </div>
          </Collapse>
          <div style={loading}>
            <CircularProgress size={50} thickness={4} />
          </div>
        </List>
      </div>
    );
};

const getUnreadCount = (conversation) => {

    let unreadCount = 0;
    // Map through messages, add one to unread count if unread
    // is true for that message
    conversation.messages.map((message) =>
        message.unread ? unreadCount++ : null,
    );

    // Set style for the counter
    let countStyle = {
        alignItems: 'center',
        borderRadius: 50,
        display: 'flex',
        fontSize: 12,
        height: 27,
        justifyContent: 'center',
        width: 27,
        backgroundColor: '#DFDFDF',
        color: '#737373',
    };

    if (unreadCount > 0) {

        countStyle = {
            alignItems: 'center',
            borderRadius: 50,
            display: 'flex',
            fontSize: 12,
            height: 27,
            justifyContent: 'center',
            width: 27,
            backgroundColor: 'rgba(22, 107, 192, .6)',
            color: '#FFF',
            fontWeight: 'bold',
        };
    }

    return <div style={countStyle}>{unreadCount}</div>;
};

const bindActions = (dispatch) => ({
    markMessagesUnread: (messages) => dispatch(markMessagesUnread(messages)),
    updateTextForContactSearch: (text) => dispatch(updateTextForContactSearch(text)),
    selectConversation: (conversation, avatar, contactInfo) =>
        dispatch(selectConversation(conversation, avatar, contactInfo)),
});

const mapStateToProps = (state) => ({
    contacts: state.contacts ? state.contacts.data : null,
    contactsLoading: state.contacts ? state.contacts.pending : null,
    conversations: state.http && state.http.smsData ? state.http.smsData : null,
    conversationSearchText: state.sms ? state.sms.conversationSearchText : null,
    contactSearchText: state.sms ? state.sms.contactSearchText : null,
    loadingConversations: state.http && state.http.pending ? state.http.pending.smsData : false,
    loadingContacts: state.http && state.http.pending ? state.http.pending.contacts2 : false,
    selectedConversation: state.sms ? state.sms.selectedConversation : null,
    selectedInternalDid: state.sms ? state.sms.selectedInternalDid : null,
});

export default connect(mapStateToProps, bindActions)(withStyles(matStyles)(Conversations));
