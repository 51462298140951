import React, { useEffect, useMemo, useState } from 'react';
import EmbeddedComponent from './embeddedComponent';
import EmbeddedService from './embeddedService';

function ExternalService(props) {
    const { module, masterView, rootSx } = props;

    const [, forceRefresh] = useState("CONFIG_NOT_READY");

    useEffect(() => {

        if(!app.configurationReady) {
            const onConfigReady = () => {
                forceRefresh("CONFIG_READY");
            }

            App.Events.once("Configuration:Ready", onConfigReady);

            return () => {
                App.Events.off("Configuration:Ready", onConfigReady);
            }
        }

        return () => {};
    })

    const serviceMetadata = useMemo(() => {
        const serviceMap = App.Configurations.get("externalservices");
        console.log({serviceMap});
        if(!serviceMap) {
            return undefined;
        }

        return serviceMap[module];
    }, [module, App.Configurations.get("externalservices")]);

    if(!serviceMetadata) {
        return (
            <> 
                {app.configurationReady && <div>Unable to find the module</div> } 
            </>
        )
    }

    if(serviceMetadata.type === "iframe") {
        return <EmbeddedService metadata={serviceMetadata} masterView={!!masterView} />
    }

    return <EmbeddedComponent name={module} extension={serviceMetadata} masterView={!!masterView} rootSx={rootSx} />
}

export default ExternalService;