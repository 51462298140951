import React, { Component } from 'react';

const style = {
    childrenWrapper: {
        alignItems: 'center',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        // height: '100%',
        width: '100%',
        overflow: 'none',
    },
    title: {
        fontWeight: '400',
        textAlign: 'left',
        color: '#737373',
        marginBottom: '25px',
    },
    wrapper: {
        display: 'flex',
        flex: 1,
        // height: '100%',
        background: '#EFEFEF',
        flexDirection: 'column',
        overflow: 'none',
    },
};

class ReportContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            toolbarOpen: false,
        };
    }

    onToolBarToggle(toolbarOpen) {

        this.setState({ toolbarOpen });
    }

    render() {

        const { children } = this.props;

        const childrenWithProps = React.Children.map(children, child =>
          React.cloneElement(child, {
            globalFilterToolbarOpen: this.state.toolbarOpen,
            globalOnFilterToolbarOpen: (open) => this.onToolBarToggle(open),
          }),
        );


        return (
          <div style={style.wrapper}>
            <div style={style.childrenWrapper}>
              {childrenWithProps}
            </div>
          </div>
        );
    }
}

export default ReportContainer;
