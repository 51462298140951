import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';

import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Footer from 'presentational/Footer';
import Loading from 'presentational/Loading';
import SaveChangesModal from 'presentational/SaveChangesModal';
import { tenantHelper, getWhitelabelPartner } from '../../utils/whitelistHelper';

import { getUserPortalHttp, putUserPortalHttp, getCoreHttp } from 'actions/httpRequest';
import { errorNotification, successNotification } from 'actions/notifications';
import { resetFlag } from 'actions/userSettings';
import { validateForm } from './userSettingsUtil';

const DEFAULT_COUNTRY_CODE = '+1';

function UserSettings(props) {
  const [userSettingsData, setUserSettingsData] = useState(null);
  const [countryCode, setCountryCode] = useState(DEFAULT_COUNTRY_CODE);
  const [rollBackData, setRollBackData] = useState(null);
  const [changed, setChanged] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [rollBackDirectoryStatus, setRollBackDirectoryStatus] = useState(null)

  // componentDidMount
  useEffect(() => {
    getUserSettings();
    clickedElement();
  }, []);

  // conponentDidUpdate
  useEffect(() => {
    if (isEqual(userSettingsData, props.userSettingsData) === false) {
      const newdata = cloneDeep(props.userSettingsData.data);
      const rollBack = cloneDeep(newdata);

      // If previously saved data doesn't have expected format time to parse them
      if (newdata.primary_cell_phone == null || newdata.primary_cell_phone === '') {
        // If no phone number is given then the default country code will be rendered
        newdata.primary_cell_phone = DEFAULT_COUNTRY_CODE;
      } else if (newdata.primary_cell_phone.startsWith('+') === false) {
        // Previous implementation didn't save the additional +1 with the US phone numbers
        // Time to fix them
        newdata.primary_cell_phone = `+1${newdata.primary_cell_phone}`;
      }

      setUserSettingsData(newdata);
      setRollBackData(rollBack);
    }
  }, [props.userSettingsData]);

  useEffect(() => {
    if (props.successMsg) {
      props.resetFlag();
      successNotification({
        title: 'Success!', message: props.successMsg,
      })
    }

    if (props.errorMsg) {
      if (props.errorCode === 404) {
        props.resetFlag();
        app.navigate('#userportal', { trigger : true });
      } else {
        props.resetFlag();
        errorNotification({
          title: 'Failed!', message: props.errorMsg,
        });
      }
    }
  }, [props.successMsg, props.errorMsg]);

  useEffect(() => {
    // This useEffect is used to determine if there is any kind of change in value in the form
    if (userSettingsData && rollBackData) {
      // As we are showing country code if we don't find any number in primary_cell_phone
      // We need to improvise the change detection method
      const copyOfUserSettingsData = cloneDeep(userSettingsData);
      const copyOfRollBackData = cloneDeep(rollBackData);
      let rollBackNumber = copyOfRollBackData.primary_cell_phone;
      const currentNumber = copyOfUserSettingsData.primary_cell_phone;

      if (rollBackNumber == null) {
        rollBackNumber = '';
      }

      delete copyOfUserSettingsData.primary_cell_phone;
      delete copyOfRollBackData.primary_cell_phone;

      if (isEqual(copyOfRollBackData, copyOfUserSettingsData)) {
        // if other fields are equal let's compare number field
        if (rollBackNumber === currentNumber ||
            (rollBackNumber === '' && currentNumber === countryCode) ||
            currentNumber === `+1${rollBackNumber}`
        ) {
          setChanged(false);
        } else {
          setChanged(true);
        }
      } else {
        setChanged(true);
      }
    }
  }, [userSettingsData, rollBackData]);

  const clickedElement = () => {
    const elements = document.querySelectorAll(".header, .subnav");
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", () => {
        setClicked(true);
      });
    }
  }

  const getUserSettings = () => {
    const reqData = {
      reqAction: 'usersettings',
    };
    props.getUserPortalHttp(reqData);
  }

  const putUserSettings = (payload) => {
    const reqData = {
      reqAction: 'usersettings',
      reqBody: payload,
    };
    const storeKey = 'editusersettings';
    props.putUserPortalHttp(reqData, storeKey);
  }
  const editUserSettingsData = (field, data) => {
    const newData = cloneDeep(userSettingsData);

    // True/False are '0'/'1' in the payloads
    if (data === false) {
      data = '0';
    } else if (data === true) {
      data = '1';
    }

    // Handle dummy none value for select inputs
    if ((field === 'extension_presence_state' || field === 'color_scheme') && data === 'none') {
      data = '';
    }

    newData[field] = data;
    setUserSettingsData(newData);
  }

  const onSubmit = (cancel) => {
    if (cancel) {
      app.navigate('userportal', { trigger: true });
      setChanged(false);
      setClicked(false);
    } else {
      const payload = cloneDeep(userSettingsData);

      // Only countryCode means no number is given
      if (payload.primary_cell_phone === countryCode) {
        payload.primary_cell_phone = '';
      }

      const error = validateForm(payload);

      if (error) {
        errorNotification(error);
      } else {
        putUserSettings(payload);
        setChanged(false);
      }
      setClicked(false);
    }
  }

  const revertChanges = () => {
    // Create different object with rollBackData values
    const newdata = cloneDeep(rollBackData);
    const newDirectoryStatus = cloneDeep(rollBackDirectoryStatus);

    // If previously saved data doesn't have expected format time to parse them
    if (newdata.primary_cell_phone == null || newdata.primary_cell_phone === '') {
      // If no phone number is given then the default country code will be rendered
      newdata.primary_cell_phone = DEFAULT_COUNTRY_CODE;
    } else if (newdata.primary_cell_phone.startsWith('+') === false) {
      // Previous implementation didn't save the additional +1 with the US phone numbers
      // Time to fix them
      newdata.primary_cell_phone = `+1${newdata.primary_cell_phone}`;
    }

    setUserSettingsData(newdata);
    setUserDirectoryStatus(newDirectoryStatus);
    setChanged(false);
    setClicked(false);
  }

  if (userSettingsData == null) {
    return <Loading />;
  }

  const statusOptions = [
    { label: 'Select A Status', value: 'none' },
    { label: 'Available', value: 'Available' },
    { label: 'Busy', value: 'Busy' },
    { label: 'Do Not Disturb', value: 'Do Not Disturb' },
    { label: 'Invisible', value: 'Invisible' },
    { label: 'Out of Office', value: 'Out of Office' },
  ];

  const colorSchemeOptions = [
    { value: 'none', label: 'Select a color scheme' },
    { value: 'broncos', label: 'Go Broncos!' },
    { value: 'green', label: 'Green' },
    { value: 'orange_gray', label: 'Orange' },
    { value: 'pink', label: 'Pink' },
    { value: 'purple_gray', label: 'Purple' },
    { value: 'st_pattys', label: 'Happy St. Patty\'s Day!' },
  ];

  const partner = getWhitelabelPartner();

  return (
    <div className='container' onClick={() => setClicked(false)}>
      <StandardSettingsPage>
        <h2>Directory Status</h2>
        <FormControlCard
          form={userSettingsData}
          field='extension_presence_state'
          formControl='SelectInput'
          title='Directory Status'
          submitForm={editUserSettingsData}
          options={statusOptions}
        />
        <FormControlCard
          form={userSettingsData}
          field='extension_presence_status'
          formControl='TextInput'
          title='Status Message'
          submitForm={editUserSettingsData}
        />
        <h2>User Settings</h2>
        <FormControlCard
          form={userSettingsData}
          field='primary_cell_phone'
          formControl='PhoneInput'
          title='Primary Cell Phone Number'
          submitForm={(field, data) => {
            if (data.startsWith('+') === false) {
              editUserSettingsData(field, `+${data}`);
            } else {
              editUserSettingsData(field, data);
            }
          }}
          getCountryCode={(countryCode) => {
            setCountryCode(countryCode);

            if (loaded) {
              editUserSettingsData('primary_cell_phone', countryCode);
            } else {
              setLoaded(true);
            }
          }}
        />
        <FormControlCard
          form={userSettingsData}
          field='emailaddress'
          formControl='TextInput'
          title='Email Address'
          submitForm={editUserSettingsData}
        />
        <FormControlCard
          form={userSettingsData}
          field='did_for_sms'
          formControl='TextInput'
          type='number'
          title='Primary Phone Number for SMS'
          submitForm={editUserSettingsData}
        />
        <FormControlCard
          form={userSettingsData}
          field='sms_to_email'
          formControl='ToggleInput'
          title='SMS to Email'
          submitForm={editUserSettingsData}
          label='No / Yes'
          subtitle='When this setting is turned on, you have the ability to send/receive text messages to your email.'
        />
        {userSettingsData.extension_local_presence_enabled === '1' && <FormControlCard
          form={userSettingsData}
          field='match_calling_callerid_if_possible'
          formControl='ToggleInput'
          title='Enable Local Area Presence'
          submitForm={editUserSettingsData}
          label='No / Yes'
          subtitle="Once enabled, outbound calls will use the closest area code and city to the number you are dialing as your outbound
          caller ID number. Please note, your account's phone numbers also need to be enabled for this feature to work. Please contact
          your administrator for additional information on the list of phone numbers for this feature."
        />}
        <FormControlCard
          form={userSettingsData}
          field='custom_scheme'
          formControl='ToggleInput'
          title='Custom Portal Color Scheme'
          submitForm={editUserSettingsData}
          label='No / Yes'
        />
        {userSettingsData.custom_scheme === '1' &&
          <FormControlCard
            form={userSettingsData}
            field='color_scheme'
            formControl='SelectInput'
            title='Color Scheme'
            submitForm={editUserSettingsData}
            options={colorSchemeOptions}
          />
        }
        <Footer
          disabled={!changed}
          onClick={onSubmit}
          fullWidth={true}
        />
        <SaveChangesModal
          show={changed && clicked}
          saveChange={() => { onSubmit(false) }}
          onClose={() => { onSubmit(true) }}
          discardChange={revertChanges}
        />
      </StandardSettingsPage>
    </div>
  )
}

const bindActions = (dispatch) => ({
  getUserPortalHttp: (reqData) => dispatch(getUserPortalHttp(reqData)),
  putUserPortalHttp: (reqData, storeKey) => dispatch(putUserPortalHttp(reqData, storeKey)),
  resetFlag: () => dispatch(resetFlag()),
  getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
});

const mapStateToProps = (state) => ({
  userSettingsData: state.userSettings.userSettingsData,
  successMsg: state.userSettings.successMsg,
  errorMsg: state.userSettings.errorMsg,
  errorCode: state.userSettings.errorCode,
});

UserSettings.propTypes = {
  userSettingsData: PropTypes.oneOfType([() => null, PropTypes.object]),
  successMsg: PropTypes.oneOfType([() => null, PropTypes.string]),
  errorMsg: PropTypes.oneOfType([() => null, PropTypes.string]),
  errorCode: PropTypes.oneOfType([() => null, PropTypes.number]),
}

export default connect(mapStateToProps, bindActions)(UserSettings);
