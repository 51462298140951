import React, { Component } from 'react';
import { connect } from 'react-redux';

import MasterHistory from 'presentational/MasterHistory';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';

class History extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {

    this.setState({ 
        history: this.props.history 
    });
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.history) {
      this.setState({ 
        history: nextProps.history,
      });
    }
  }

  render() {

    const { history, selectedVoicemailBox } = this.props;

    const styles = {
      header: {
          color: '#595959',
      }
    }

    if (selectedVoicemailBox === 'New'){
      return (
        <StandardSettingsPage>
          <h2 style={styles.header}>Please select a voicemail box</h2>
        </StandardSettingsPage>
      )
    }

    if (!this.props || !history) {
      return (
        <StandardSettingsPage>
          <Loading />
        </StandardSettingsPage>
      );
    }

    let data = [];
    if(Object.keys(history).length > 0){
      Object.keys(history).map(key =>{
        data.push(history[key]);
      })
    }

    return (
      <MasterHistory data={data} />
    );
  }
}

const bindActions = (dispatch) => ({
});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, bindActions)(History);
