import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import { useTheme } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

export const TITLE = "Cannot be used for room-level 911. The address has not been validated.";

function InvalidAddressIcon(props) {
    const theme = useTheme();

    return (
        <Tooltip title={TITLE}>
            <ErrorIcon color="error" />
        </Tooltip>
    );
}

export default InvalidAddressIcon;
