import React, { useState, useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Loading from '../../presentational/Loading';
import DetailContainer from '../../presentational/DetailContainer';
import LeftBar from '../../presentational/LeftBar';
import MasterView from '../../presentational/MasterView';
import ConfirmationModal from './confirmationModal';
import { ShortCodesProvider, useShortCodes } from './shortCodesContext';
import useShortCodesActions from './useShortCodesActions';
import ShortCodesHelp from './help';
import ShortCodesForm from './shortCodesForm';
import UploadShortCodes from './bulkUpload';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import DeleteModal from 'presentational/DeleteModal';

const deleteStoreKey = 'deleteshortcodes';

const ShortCodesNoContext = props => {
    // state
    const [closeMenu, setCloseMenu] = useState(false);
    const [showingDeleteConfirmModal, setShowingDeleteConfirmModal] = useState(false);

    // redux state
    const shortCodesData = useSelector(state => state.shortCodes.shortCodesData);
    const pending = useSelector(state => state.shortCodes.pending, shallowEqual);
    const {
        buttonDisabled,
        discardChange,
        leftBarData,
        reqAction,
        route,
        saveChange,
        select,
        selectedCode,
        setDetails,
        setRoute,
        setSelectedCode,
        setTheForms,
        setUnsavedChanges,
        unsavedChanges,
    } = useShortCodes();

    // redux actions
    const { deleteCoreHttpHook } = useShortCodesActions();

    // begin click on header with unsaved changes
    const shortCodesNode = useRef(buttonDisabled);

    /*  
        When we try and pass state to the event listener, it only recognizes the initial state.
        The solution was to make the className the state, and since we are only adding the 
        event listener to the header, all we need to do is check if we have unsaved changes.
    */
    const handleClickedElement = () => {
        if (shortCodesNode.current.className == 'false') {
            setUnsavedChanges('away');
        }
    };

    const clickedElement = () => {
        const elements = document.querySelectorAll('.header, .detailContainerDiv');
        elements.forEach(element => {
            element.addEventListener('click', handleClickedElement);
        });
    };

    // This will make sure we remove the event listeners when we leave the page
    const cleanupClicked = () => {
        const elements = document.querySelectorAll('.header, .detailContainerDiv');
        elements.forEach(element => {
            element.removeEventListener('click', handleClickedElement);
        });
    };

    useEffect(() => {
        // add the event listeners
        clickedElement();
        // we're leaving, let's clean up
        return cleanupClicked;
    }, []);

    const handleAddNewClick = () => {
        const blankForm = {
            codeNumber: '',
            name: '',
            forwardingNumber: '1',
        };
        setSelectedCode('New Short Code');
        setRoute('new');
        setDetails(blankForm);
        setTheForms(blankForm);
        app.navigate(`shortcodes/new`, { trigger: false }); // eslint-disable-line no-undef
    };

    const handleDeleteShortCode = () => {
        const reqData = {
            reqAction,
            reqObject: selectedCode,
        };
        deleteCoreHttpHook(reqData, deleteStoreKey);
        setRoute(null);
        setSelectedCode(null);
    };

    const handleDeleteConfirmClose = shouldDelete => {
        if (shouldDelete) {
            handleDeleteShortCode();
            app.navigate(`shortcodes`, { trigger: true }); // eslint-disable-line no-undef
        }
        setShowingDeleteConfirmModal(false);
    };

    const openCloseMenu = () => {
        if (closeMenu) {
            setCloseMenu(false);
        }
        setCloseMenu(true);
    };

    const goToHelp = () => {
        const win = window.open('http://help.fluentcloud.com/support/solutions', '_blank');
        win.focus();
    };

    const bulkUpload = () => {
        app.navigate(`shortcodes/bulkupload`, { trigger: true });
    };

    const handleBack = () => {
        // app.navigate(`shortcodes`, { trigger: true })
        if (unsavedChanges) {
            setUnsavedChanges('back');
        } else {
            app.navigate(`shortcodes`, { trigger: true });
        }
    };

    const renderContent = () => {
        switch (route) {
            case 'new':
                return <ShortCodesForm key="new" formKey="new" />;
            case null:
                return <ShortCodesHelp />;
            case 'bulkupload':
                return <UploadShortCodes />;
            default:
                return <ShortCodesForm key={route} formKey={route} />;
        }
    };

    const contextMenuOptions = [
        <MenuItem
            disabled={!selectedCode || selectedCode === 'new' || selectedCode === 'New Short Code'}
            classes={{ root: 'fontSize: "100%"' }}
            onClick={() => {
                setShowingDeleteConfirmModal(true);
                openCloseMenu();
            }}
            key={0}
        >
            Delete
        </MenuItem>,
        <MenuItem
            onClick={() => {
                goToHelp();
                openCloseMenu();
            }}
            classes={{ root: 'fontSize: "100%"' }}
            key={1}
        >
            Help
        </MenuItem>,
        <MenuItem onClick={bulkUpload} classes={{ root: 'fontSize: "100%"' }} key={2}>
            Bulk Upload
        </MenuItem>,
    ];

    if (props.routeParams && props.routeParams.code !== 'new' && props.routeParams.code !== route) {
        return <Loading />;
    }
    const detailTitle = () => {
        switch (route) {
            case 'new':
                return { title: '   ', detailRoute: 'New Short Code' };
            case 'bulkupload':
                return { title: '   ', detailRoute: 'Bulk Upload Codes' };
            default:
                if (route !== null) {
                    return { title: 'Short Code', detailRoute: route };
                }
                return { title: null, detailRoute: null };
        }
    }
    const { detailRoute, title } = detailTitle();
    return (
        <MasterView>
            <ConfirmationModal
                show={unsavedChanges}
                discardChange={discardChange}
                saveChange={saveChange}
                cancelModal={() => setUnsavedChanges(false)}
            />
            <DetailContainer
                leftBar={
                    <LeftBar // eslint-disable-line react/jsx-wrap-multilines
                        addNew={handleAddNewClick}
                        data={leftBarData}
                        pending={pending || !shortCodesData}
                        selected={route}
                        select={select}
                        title="Short Codes"
                    />
                }
                route={detailRoute}
                return={handleBack}
                title={title}
                contextMenuOptions={contextMenuOptions}
                closeMenu={closeMenu}
            >
                <div
                    className={buttonDisabled.toString()}
                    style={{ display: 'contents' }}
                    ref={shortCodesNode}
                >
                    {renderContent()}
                </div>
            </DetailContainer>
            <DeleteModal
                open={showingDeleteConfirmModal}
                title='Are you sure?'
                delete={() => handleDeleteConfirmClose(true)}
                cancel={() => handleDeleteConfirmClose(false)}
            />
        </MasterView>
    );
};

const ShortCodes = () => {
    return (
        <ShortCodesProvider>
            <ShortCodesNoContext />
        </ShortCodesProvider>
    );
};

ShortCodes.defaultProps = {
    deleteCoreHttpHook: () => null,
    discardChange: () => null,
    getCoreHttpHook: () => null,
    leftBarData: [],
    prepareDataForLeftBar: () => null,
    resetFlag: () => null,
    route: '',
    saveChange: () => null,
    select: () => null,
    selectedCode: '',
    setRoute: () => null,
    setSelectedCode: () => null,
    setUnsavedChanges: () => null,
    submitFullForm: () => null,
    unsavedChanges: false,
};

const leftBarDataObj = PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
});

ShortCodes.propTypes = {
    deleteCoreHttpHook: PropTypes.func.isRequired,
    discardChange: PropTypes.func.isRequired,
    getCoreHttpHook: PropTypes.func.isRequired,
    leftBarData: PropTypes.arrayOf(leftBarDataObj),
    prepareDataForLeftBar: PropTypes.func.isRequired,
    resetFlag: PropTypes.func.isRequired,
    route: PropTypes.string.isRequired,
    saveChange: PropTypes.func.isRequired,
    select: PropTypes.func.isRequired,
    selectedCode: PropTypes.string.isRequired,
    setRoute: PropTypes.func.isRequired,
    setSelectedCode: PropTypes.func.isRequired,
    setUnsavedChanges: PropTypes.func.isRequired,
    submitFullForm: PropTypes.func.isRequired,
    unsavedChanges: PropTypes.bool.isRequired,
};
export default ShortCodes;
