import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReactTable from 'react-table';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import moment, { utc } from 'moment';
import AudioPlayer from 'presentational/formControls/AudioPlayer';
import Dialog from '@material-ui/core/Dialog';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';
import Snackbar from '@material-ui/core/Snackbar';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import VoicemailFooter from 'presentational/VoicemailFooter';

import ForwardIcon from '@material-ui/icons/PhoneForwarded'
import DialogContent from '@material-ui/core/DialogContent';

import FormControlCard from '../../presentational/FormControlCard'

import { 
    getUserPortalHttp, 
    patchUserPortalHttp,
    postUserPortalHttp,
    getCoreHttp,
} from 'actions/httpRequest';

import {
    errorNotification,
    successNotification,
} from 'actions/notifications';

class VoicemailInbox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mediaQuery: false,
            currentFolder:'',
            folder:'',
            mailbox:'',
            voicemails:null,
            rows:'',
            selectedIndexes:[],
            disabled: true,
            tenant:'',
            loading: true,
            loadingSettings: true,
            loadingVmBoxes: true,
            messageids:[],
            currentTab:'',
            tabIndex: 0,
            folderDialogOpen: false,
            permDeleteConfrimationModal: false,
            playbackRate: "1.0",
            downloadPrompt: false,
            snackbarTime: null,
            rowData:[],
            voicemailBoxList:[],
            form: null,
            selectAll: false,
            forwardVMContact: [],
            showForwardModal: false,
            notificationId: Math.round(Math.random() * 100),
        };

        this.playbackChange = this.playbackChange.bind(this);
    }

    componentDidMount() {
        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        let tenant;
        let mailbox;
        let voicemails;

        if  (this.props.adminView){
            tenant = this.props.tenant;
            mailbox = this.props.selectedVoicemailBox;
            voicemails = this.props.voicemails;
            this.fetchVoicemailSettings(mailbox);
            this.setState({ 
                adminView: true,
                tenant, 
                mediaQuery: mq,
                mailbox,
                voicemails })
        
        } else {
            tenant = app.token.get("tenant");
            this.fetchVoicemailSettings(app.token.get("mailbox"));
            mailbox = app.token.get("mailbox")
            this.setState({ 
                adminView : false,
                mailbox,
                mediaQuery: mq,
                tenant,
            });

        }

        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));

        const extension = localStorage.getItem('userPortalExtension') || app.token.get("extension");

        if(this.state.mailbox || app.token.get("mailbox")){
            this.fetchVoicemails(app.token.get("mailbox"));
        } else if (!this.props.adminView){
            this.fetchExtensions();
        }
        this.fetchVoicemailBoxes()
    }

    componentWillReceiveProps(nextProps) {
        let mailbox;
        
        if (nextProps.inbox) {
            if (this.state.voicemails !== nextProps.inbox.data){
                let voicemails = [];
                voicemails = nextProps.voicemails
                this.setState({ voicemails })
            }
        }

        if (nextProps.postSuccess.success && nextProps.postSuccess.storeKey == "user"){
            this.renderSuccessMessage('Voicemail Message(s) Transferred');
            this.setState({
                disabled: true,
                messageids: [],
                selectedIndexes: [],
            });
            this.fetchVoicemails(app.token.get("mailbox"));
        }

        if(this.state.sortColumn && this.state.sortDirection){
            this.handleGridSort(this.state.sortColumn, this.state.sortDirection)
        }

        let found;

        if (!nextProps.adminView && this.state.mailbox && nextProps.extensionData && !nextProps.extensionData[-1]){
            found = nextProps.extensionData.findIndex(extension => extension.mailbox == this.state.mailbox);
        }

        if (nextProps && nextProps.extensionData && nextProps.extensionData[-1]){
            this.setState({ 
                form: nextProps.extensionData[-1], 
                loadingSettings: false,
            })
        } else if (!nextProps.adminView && nextProps.extensionData && found) {
            //If we return the larger extensions object, let's find our particular form
            this.setState({
                form: nextProps.extensionData[found],
                loadingSettings: false,
            })
        }

        if (nextProps.extensionData && !this.props.adminView){
            this.setState({ loading: false });
            mailbox = nextProps.extensionData.mailbox ? nextProps.extensionData.mailbox.split("@")[0] : "";
        }

        if (!nextProps.adminView){
            mailbox = app.token.get("mailbox")
        }

        if (this.props.adminView){
            mailbox = this.props.selectedVoicemailBox;
        }

        if (!this.props.adminView && mailbox && this.state.mailbox !== mailbox){
            this.fetchVoicemails(mailbox)
        }

        if (nextProps.patchSuccess && nextProps.reducerTarget == "voicemailboxes"){
            if (nextProps.patchSuccess.object === 'permDelete') {
               this.renderSuccessMessage(`Successfully Deleted Selected Voicemails`);
               this.setState({messageids: []})
            } 
            else {
                this.renderSuccessMessage(`Successfully moved voicemails to your ${nextProps.patchSuccess.object} folder`);              
            }
        }

        if (nextProps.error){
            this.renderErrorMessage(`${nextProps.error}`)
        }

        if (nextProps.voicemails){
            this.setState({
                voicemails: nextProps.voicemails,
            });
        } else if (nextProps && nextProps.inbox && nextProps.inbox.data) {
            this.setState({
                voicemails: nextProps.inbox.data,
            });
        }

        let nextPropsCallForwarding = nextProps.callforwarding.callforwarding;
        if (!this.state.loadingVmBoxes && this.state.voicemailBoxList.length <1
            && nextPropsCallForwarding && nextPropsCallForwarding.voicemailboxes && nextPropsCallForwarding.voicemailboxes.length !== 0){
            this.storeVoicemailBoxes(nextPropsCallForwarding.voicemailboxes)
        }

        this.setState({mailbox});

    }

    fetchVoicemailSettings(voicemailbox){

        if (this.props.new){return};

        const reqData = {
            reqAction: 'voicemailsettings',
            reqObject: voicemailbox
        };

        const storeKey = 'voicemailsettings';

        if (voicemailbox){
            this.props.getUserPortalHttp(reqData, storeKey);
        }
    }

    fetchExtensions(){
        const reqData = {
            reqAction: 'extensions',
        };
        const storeKey = 'extensions';
        this.props.getUserPortalHttp(reqData, storeKey);
    }

    fetchVoicemailBoxes(){

        const reqData = { reqAction: 'callforwarding' };
        const storeKey = 'callforwarding';
        this.props.getUserPortalHttp(reqData, storeKey);
        this.setState({loadingVmBoxes: false});
    }

    storeVoicemailBoxes(vmboxes){
        let vmBoxList = [];
        vmboxes.forEach(mailbox => {
            vmBoxList.push(mailbox)
        });

        vmBoxList.sort((a,b) => a.mailbox-b.mailbox);
        this.setState({voicemailBoxList: vmBoxList});
    }

    //Get all voicemail messages
    fetchVoicemails(voicemailbox) {

        if (this.props.adminView && voicemailbox == app.token.get("mailbox")) {
            return;
        }

        const reqData = {
            reqAction: 'voicemailmessages',
            reqObject: voicemailbox
        };

        const storeKey = 'voicemailmessages';
        this.setState({ loading:false });
        if (voicemailbox){
            this.props.getUserPortalHttp(reqData, storeKey);
        };
    }

    toggleSelect(messageid) {
        let messageids = [];
        if(this.state.messageids.includes(messageid)) {
            messageids = this.state.messageids.filter(message => {
                return messageid !== message;
            });
        } else {
            messageids = [...this.state.messageids, messageid];
        }
        this.setState({ messageids, selectAll: false });
    }

    toggleSelectAll(messages) {
        const { resolvedData, pageSize, page } = this.reactTable.getResolvedState();
        const visibleStart = page * pageSize;
        const visibleEnd = ((page + 1) * pageSize);
        const visible = resolvedData.slice(visibleStart, visibleEnd).map(message => message.checkbox.key);
    
        let messageids = [];        
        if (!this.state.selectAll) {
            messageids = visible;
        }
        this.setState({
            messageids,
            selectAll: !this.state.selectAll
        });
    }

    moveFolder(foldername) {

        let folderName = foldername.toLowerCase();

        let {
            currentFolder,
            messageids,
            mailbox,
            rowData,
        } = this.state;

        rowData.forEach((row) => {
            if (messageids.includes(row.messageids)) {
                row.folder = folderName;
            } 
        });

        const reqBody = {
            currentFolder: currentFolder,
            folder: folderName,
            mailbox:mailbox,
            messages: messageids
        }

        const reqData = {
            reqAction: 'voicemailmessagesbatch',
            reqObject2: mailbox,
            reqObject: folderName,
            reqBody,
        };

        const storeKey = 'user';
        
        this.props.patchUserPortalHttp(reqData, storeKey);

        this.setState({
            selectedIndexes: [],
            folderDialogOpen: false,
            downloadPrompt: false,
            disabled:true,
            rowData,
            messageids: [],
            selectAll: false,
        })
    }

    playing(playingNow){
        this.setState({ currentlyPlaying: playingNow});
    }

    downloadVoicemail() {

        console.log("[[INFO] voicemailinbox.downloadvoicemails] Downloading Voicemails in bulk.");

        let {
            messageids,
            voicemails
        } = this.state;

        //let's loop over our voicemails and 
        //search for the ones we've selected
        let allVoicemails = Object.values(voicemails);

        allVoicemails.forEach(async (voicemail) => {
            if (messageids.includes(voicemail.id)) {

                await remoteResourceInterface({
                    nameSpace: "userportal",
                    route: "voicemailmessages",
                    reqObject: `${voicemail.id}.wav`,
                    fileType: "audio/wav",
                    shouldDownload: true
                });
            }
        });

        console.log("[[INFO] voicemailinbox.downloadvoicemails] Finished downloading voicemails.");

        this.setState({ 
            downloadPrompt: true,
            selectedIndexes: [],
            snackbarTime: i*2,
            disabled: true,
        });
    }

    permDeleteVm() {

        let voicemailbox = this.state.mailbox;
        let messageids = this.state.messageids;
        let permDeleteConfrimationModal = false;

        const reqBody = {
            messageids,
            mailbox: voicemailbox
        };

        const reqData = {
            reqAction: 'voicemailmessages',
            reqBody: reqBody,
            reqObject: 'permDelete'
        };

        const storeKey = 'voicemailmessages';
        if (voicemailbox){
            this.props.patchUserPortalHttp(reqData, storeKey);
        };

        this.setState({permDeleteConfrimationModal});
    }

    deleteVoicemail() {
        let selectedIndexes = [];
        
        if (this.state.tabIndex == 4){
                this.setState({permDeleteConfrimationModal: true});
                return
        }

        let {
            currentFolder,
            messageids,
            mailbox
            } = this.state;

        const reqBody = {
            currentFolder: currentFolder,
            folder: 'trash',
            mailbox:mailbox,
            messages: messageids
        }
        
        const reqData = {
            reqAction: 'voicemailmessagesbatch',
            reqBody,
            reqObject2: mailbox,
            reqObject: 'trash'
        };

        const storeKey = 'user';

        this.props.patchUserPortalHttp(reqData, storeKey);

        this.setState({
            downloadPrompt: false,
            selectedIndexes,
            messageids:[],
            disabled: true,
            selectAll: false,
        });

    }
    
    forwardVoicemail(recipient){
        const { messageids } = this.state;
        let messageArray = [];
        let recievingVMbox = recipient.mailbox;

        if (!recipient){
            return;
        } else {
            this.setState({forwardVMContact: recipient.mailbox});
        }

        // NOW THAT WE HAVE THE MESSAGEIDS AND THE VOICEMAIL BOX TO TRANSFER TO, LETS LOOP THROUGH AND COPY METADATA AND URLS
        messageids.forEach(messageid => {
            messageArray.push(messageid);
        })

        let dataObject = [];

        messageArray.forEach(function (val, index) {
            dataObject.push({
                messageid: messageArray[index],
                recievingVMbox: recievingVMbox
            })
        })

        const reqBody = {
            currentMailbox: this.state.form.mailbox,
            deleteafterdelivery: this.state.form.deleteafterdelivery,
            destinationMailbox: recipient.mailbox,
            messages: messageArray,
            transcribefile: this.state.form.transcribefile,
        }

        const reqData = {
            reqAction: 'voicemailtransfer',
            reqObject2: recipient.mailbox,
            reqBody,
        };
        const storeKey = 'user';

        this.props.postUserPortalHttp(reqData, storeKey);
    }
    
    closeForwardModal = () => {
        this.setState({ showForwardModal: false })
    }

    handleClose(e){
        this.setState({folderDialogOpen: false})
    }

    openForwardModal(){
        this.setState({ showForwardModal: true })
    }

    playbackChange(e){
        this.setState({ playbackRate: e.target.value})
    }

    renderErrorMessage(response){
        errorNotification({
            id: this.state.notificationId,
            title:'Error!',
            message: response
        });  
    }

    renderSuccessMessage(response){
        successNotification({
            id: this.state.notificationId,
            title:'Success!',
            message: response
        });
    }

    clearSelected() {
        this.setState({
            messageids: [],
            selectAll: false
        });
    }

    buildDataTable(data, columns, style, tab) {
       const content =  data.length > 0 ? (
            <ReactTable
                data={data}
                columns={columns}
                styles={style.column}
                ref={(r)=>this.reactTable=r}
                defaultPageSize={10}
                defaultSorted={[ {id:'time', desc: false} ]}
                pageSizeOptions={[10, 25, 50]}
                onPageChange={() => {this.clearSelected()}}
                onPageSizeChange={() => {this.clearSelected()}}
                onSortedChange={() => {this.clearSelected()}}
                onFilteredChange={() => {this.clearSelected()}}
            />
        ) : (   
            <h3 style={style.emptyinbox}>No voicemails</h3>
        )
        return (
            <TabPanel key={tab}>
                {content}
            </TabPanel>
        )
    }


    renderDeleteModal(){
       
        return (
            <Dialog
                open={this.state.permDeleteConfrimationModal}
                onClose={() => this.handleClose(false)}
                fullWidth={true}
            >
                <DialogTitle>Are you sure you want to permanently delete the selected {this.state.messageids.length > 1 ? 'voicemails' : 'voicemail'}?</DialogTitle>
                <DialogActions>
                    <Button
                        primary='true'
                        onClick={ () => this.permDeleteVm() }
                    >Yes</Button>
                    <Button
                        primary='true'
                        onClick={ () => this.setState({permDeleteConfrimationModal: false}) }
                    >No</Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        let currentTab='';
        let tabs = [];
        let finalData = [];
        let { tabIndex, loading, loadingSettings, loadingVmBoxes } = this.state;

        let voicemails = this.props.inbox ? this.props.inbox.data : this.props.inbox

        let inboxNum = 0, workNum = 0, familyNum = 0, friendNum = 0, trashNum = 0;

        if( voicemails ) {    
            voicemails.forEach(voicemail => {
                if (voicemail.folder == "inbox") {inboxNum++}
                if (voicemail.folder == "work") {workNum++}
                if (voicemail.folder == "family") {familyNum++}
                if (voicemail.folder == "friends") {friendNum++}
                if (voicemail.folder == "trash") {trashNum++}
            });
        }

        const voicemailFolders = [`Inbox (${inboxNum})`, `Work (${workNum})`, `Family (${familyNum})`, `Friends (${friendNum})`, `Trash (${trashNum})`];

        const style= {
            container:{
                width: '90%',
                marginTop: '10px',
                marginLeft: '50px',
                height: '100%',
                background: '#EFEFEF',
                paddingBottom: '50px',
                overflow: 'auto',
            },
            playback: { 
                display: 'flex', 
                float: 'right'
            },
            buttoncontainer:{
                justifyContent: 'center',
                textAlign: 'center',
                marginTop: '20px'
            },
            button:{
                margin: '10px'
            },
            emptyinbox:{
                textAlign:'center',
                margin: '40px'
            },
            column: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                margin: '0',
                padding: '.25em 1em',
                background: '#ffffff'
            },
            header: {
                background:'#eeeeee',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0',
            }
        }

        switch(tabIndex) {
            case 0:
                currentTab='inbox';
                break;
            case 1:
                currentTab='work';
                break;
            case 2:
                currentTab='family';
                break;
            case 3:
                currentTab='friends';
                break;
            case 4:
                currentTab='trash';
                break;
        }


        voicemailFolders.forEach((name, i)=> {
            tabs.push(
                <Tab key={name}>{name}</Tab>
            )
        })


        const rows = [];
        let i =0
        if (voicemails) {
            voicemails.forEach((voicemail) => {
                let utcSeconds = voicemail.time;
                let day = moment.unix(utcSeconds).format("M/DD/YY h:mm a");
                let phoneNumber = voicemail.calleridnumber;
                let duration;
                let mailbox = this.state.mailbox ? this.state.mailbox : app.token.get("mailbox")

                if (phoneNumber && phoneNumber.length === 10 && phoneNumber !== 'Restricted') {
                    let cleaned = ('' + phoneNumber).replace(/\D/g, '');
                    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                    if (match){
                        phoneNumber =  '(' + match[1] + ') ' + match[2] + '-' + match[3];
                    }
                };

                //calculate our time field because
                //moment.js is being fiesty
                let totalseconds = voicemail.duration;
                let d = Number(totalseconds);
                let m = Math.floor(d % 3600 / 60);
                let s = Math.floor(d % 3600 % 60);
                s < 10 ? s= "0" +s : s;
                duration= m + ":"+ s;

                const playurl = `${voicemail.filepath}.wav`;
                const recordingName = `${voicemail.id}.wav`;

                const style = {
                    messageContainer: {
                        display:'flex',
                        alignItems:'center',
                        width: '100%',
                    },
                    audioElement: {
                        width: '100%',
                        float: "left"
                    },
                    playback: {
                        textAlign: 'right',
                    }
                }

                const voicemailInfo = {
                    calleridname: voicemail.calleridname,
                    calleridnumber: phoneNumber,
                    time: day,
                    transcription: voicemail.transcription
                }

                const allowTranscription = this.state.form && (this.state.form.transcribefile == "1" || (this.state.form.voicemailEmails && this.state.form.voicemailEmails.length > 0 && this.state.form.voicemailEmails.some(email => email.transcribefile == "1")));

                let message =
                    <div style={style.messageContainer}>
                        <div style={style.audioElement}>
                            <AudioPlayer 
                                greeting={true}
                                key={i}
                                playbackRate={this.state.playbackRate}
                                playing={(playing) => this.playing(playing)}
                                recordingUrl={playurl}
                                recordingName={recordingName}
                                showDownload
                                showTime
                                showTranscribe={allowTranscription}
                                style={{width: '50%'}}
                                voicemailID= {this.state}
                                voicemailInfo={voicemailInfo}
                            />
                        </div>
                    </div>

                const row = {
                    calleridname: voicemail.calleridname,
                    calleridnumber: phoneNumber,
                    time: day,
                    key:voicemail.id,
                    folder: voicemail.folder,
                    duration: duration,
                    message: message,
                    messageids: voicemail.id,
                }

                rows.push(row);
                i++;
            });
        };
        rows.forEach((row) => {
            if (row.folder === currentTab){
                finalData.push(row);
            }
        });

        let disableSelect = this.state.currentlyPlaying == true ? true : false;
        let disabledStyle;

        if (this.state.currentlyPlaying){
            disabledStyle = {backgroundColor: 'lightgray'};
        }

        if ((!this.props.adminView && loading && loadingSettings && loadingVmBoxes) ||
        (this.props.adminView && !this.props.inbox)){
            return (
                <StandardSettingsPage>
                    <Loading />
                </StandardSettingsPage>        
            )
        }

        if ((!this.props.adminView && !this.state.mailbox && app.token.get("mailbox")== "" && !loading && !loadingSettings) || (!this.props.adminView && (app.token.get("mailbox") == "" ))){
            return (
                <StandardSettingsPage>
                    <h2>This extension does not have a mailbox.</h2>
                    <h4>Please contact support or your administrator.</h4>
                </StandardSettingsPage>
            )
        }

        const columns = [
            {
                id: "checkbox",
                accessor: "",
                Cell: rowInfo => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.messageids.includes(rowInfo.value.key)}
                            onChange={() => this.toggleSelect(rowInfo.value.key)}
                        />
                    );
                },
                Header: title => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selectAll}
                            onChange={() => this.toggleSelectAll(this.visibleMessages)}
                        />
                    );
                },
                sortable: false,
                resizable: false,
                width: 45,
                headerStyle: { ...style.header } ,
                style: { ...style.column, 
                    alignItems: 'center',
                    justifyContent: 'center', }
            },
            { accessor: 'calleridname', Header: 'Caller ID Name', sortable: true, resizable: true, style: { ...style.column }, headerStyle: { ...style.header } },
            { accessor: 'calleridnumber', Header: 'Caller ID Number', sortable:true, resizable: true, style: { ...style.column }, headerStyle: { ...style.header } },
            { 
                accessor: 'time', 
                Header: 'Date', 
                sortable: true, 
                resizable: true, 
                style: { ...style.column }, 
                headerStyle: { ...style.header },
                sortMethod: (a, b) => {
                    var aTime = new Date(a).getTime();
                    var bTime = new Date(b).getTime();
                    if (aTime<bTime) {
                        return 1;
                    } else if(aTime>bTime) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            },
            { accessor: 'duration', Header: 'Time', sortable: true, resizable: true, style: { ...style.column }, headerStyle: { ...style.header } },
            { accessor: 'message', Header: 'Message', resizable: false, sortable: false, style: { ...style.column }, headerStyle: { ...style.header }, width: 300 },
        ];

        return (
            <div style={style.container}>
                <div>
                    <div style={style.playback}>
                        <h5>Voicemail Playback Speed:   </h5>
                            <select disabled={disableSelect} style={disabledStyle} value={this.state.playbackRate} onChange={this.playbackChange}>
                                <option value="0.5">0.5</option>
                                <option value="1.0">1.0</option>
                                <option value="2.0">2.0</option>
                            </select>
                    </div>
                    <h3>Voicemail Inbox</h3>
                    <h5>Listen, delete, and email voicemails.</h5>
                        <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({selectedIndexes:[], messageids: [], disabled:true, tabIndex})}>
                            <TabList>{tabs}</TabList>
                            { tabs.map((tab) => this.buildDataTable(finalData, columns, style, `${tab.key}data`)) }
                        </Tabs>
                        <div style={style.buttoncontainer}>
                            <Snackbar
                                open={this.state.downloadPrompt}
                                message={<span>One moment, your voicemails are downloading.</span>}
                                autoHideDuration={this.state.snackbarTime}
                            />
                        </div>
                    </div>
                    <Dialog
                        open={this.state.folderDialogOpen}
                        onClose={() => this.handleClose(false)}
                        fullWidth={true}
                    >
                        <DialogTitle>Select a Folder</DialogTitle>
                        <DialogActions>
                            <Button
                                primary='true'
                                onClick={() => this.moveFolder('Inbox')}
                            >Inbox</Button>
                            <Button
                                primary='true'
                                onClick={() => this.moveFolder('Work')}
                            >Work</Button>
                            <Button
                                primary='true'
                                onClick={() => this.moveFolder('Family')}
                            >Family</Button>
                            <Button
                                primary='true'
                                onClick={() => this.moveFolder('Friends')}
                            >Friends</Button>
                            <Button
                                primary='true'
                                onClick={() => this.moveFolder('Trash')}
                            >Trash</Button>
                        </DialogActions>
                    </Dialog>
                    <VoicemailFooter
                        disabled={this.state.messageids.length < 1}
                        forwardVM={this.state.form ? this.state.form.attach : null}
                        deleteVoicemail={()=>this.deleteVoicemail()}
                        downloadVoicemail={()=>this.downloadVoicemail()}
                        forwardVoicemail={()=>this.openForwardModal()}
                        move={() => this.setState({folderDialogOpen: true})}
                    />
                    <React.Fragment>
                        <Dialog onClose={this.closeForwardModal} open={this.state.showForwardModal}>
                            <DialogContent onClose={this.closeForwardModal} style={{ textAlign: 'center' }}>
                                <FormControlCard
                                    buttonClick={(selectedOption)=>this.forwardVoicemail(selectedOption)}
                                    closeModal= {()=>this.closeForwardModal()}
                                    field='transfervoicemailtovmbox'
                                    formControl='SearchableSelectInput'
                                    icon={<ForwardIcon />}
                                    options={this.state.voicemailBoxList}
                                    searchableFormOption={'VoicemailTransfer'}
                                    subtitle='Enter or select a recipient to transfer this voicemail to'
                                    title="Transfer Voicemail"
                                    />
                            </DialogContent>
                        </Dialog>
                    </ React.Fragment>

                    {this.renderDeleteModal()}

                </div>
            );
        }
    }

const bindActions = (dispatch) => ({
    getUserPortalHttp: (reqData, storeKey) => dispatch(getUserPortalHttp(reqData, storeKey)),
    getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
    patchUserPortalHttp: (reqData, storeKey) => dispatch(patchUserPortalHttp(reqData, storeKey)),
    postUserPortalHttp: (reqData, storeKey) => dispatch(postUserPortalHttp(reqData, storeKey)),
});

const mapStateToProps = (state) => ({
    configurations:state.http ? state.http.configurations : null,
    inbox: state.voicemail.inbox,
    error: state.voicemail.error,
    patchSuccess: state.voicemail.patchSuccess,
    postSuccess: state.voicemail.postSuccess,
    reducerTarget: state.voicemail.reducerTarget,
    extensionData: state.voicemail.extensionData,
    callforwarding:state.voicemail,
});

export default connect(mapStateToProps, bindActions)(VoicemailInbox);
