import React, { Component } from 'react';
import { connect } from 'react-redux';

import ErrorCard from 'presentational/ErrorCard';
import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';
import Footer from 'presentational/Footer';

import { getOptionsForApplication } from '../autoAttendants/applicationObject';

import TimeIcon from '@material-ui/icons/Alarm';
import DescriptionIcon from '@material-ui/icons/Description';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ArgumentIcon from '@material-ui/icons/RecordVoiceOver';
import RemoveIcon from '@material-ui/icons/HighlightOff';

import {
    errorNotification,
    successNotification,
} from 'actions/notifications';

class Failover extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            errors: {},
            form: {},
            rollbackForm: {},
            submitError: null,
            newChanges:false,
        };
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.handleClick, false)
      this.prepareApplicationField();
      this.prepareSelectionField(this.props.data[`failover_application`]);
      if(this.props.data.failover_application === "conferenceroom" && this.props.data.failover_value === ""){
        this.props.data.failover_value = "No Room"
      } 
      this.setState({ form: this.props.data }, ()=> this.createRollbackForm());
    }
    
    // applicationField is filled in based
    // on the configurations
    prepareApplicationField() {
      const applicationOptions = []; 
      if (this.props.data && this.props.data.config && this.props.data.config.applications && this.props.data.config.applications.length > 0) {
        let filteredOptions = this.props.data.config.applications.filter((app) =>{
          return (app.name !== "setlanguage") && (app.name !== "faxanswer") && (app.name !== "faxdetect"); 
         })
        
        filteredOptions.map((app) => {
          const application = {
            value: app.name,
            label: app.friendlyname
          };
          applicationOptions.push(application)
        });
      }
      this.setState({ applicationOptions });

    }

    // Selection is filled in based
    // on the application selected
    prepareSelectionField(selectedApplication) {

      // If we do not have configurations,
      // we cannot fill out the select box
      if (!this.props.data.config) {
        return;
      }

      if(selectedApplication == 'callforwarding'){
        let selectionOptions = [];
        this.setState({ selectionOptions, selectedApplication });
        return;
      }

      const selectionOptions = getOptionsForApplication(selectedApplication, this.props.data.config, this.props.data.config.autoattendant);      

      if (selectedApplication === "conferenceroom"){
        selectionOptions.unshift({value:"No Room", label: "No Room"});
      }

      this.setState({ selectionOptions, selectedApplication });

      if (selectedApplication === 'voicemailbox') {

        //If we select voicemailbox as an application, let's create an array of arguments to pick from 
        const argumentList = [
          { value: 'd', label: 'Play instructions' },
          { value: 's', label: 'Play nothing.' },
          { value: 'su', label: 'Play unavailable message.' },
          { value: 'sb', label: 'Play busy message.' },
          { value: 'u', label: 'Play unavailable message, then instructions.' },
          { value: 'b', label: 'Play busy message, then instructions.' },
        ];

        this.setState({ argumentList });

      } else {
        //if we dont select an application, lets just set this to an empty string
        return;
      }

    }

    async submitForm(field, data) {

      if (this.state.rollbackForm[field] == data) {
          console.log('No update');
          return;
      } else {
        this.setState({
          newChanges: true,
          disabled: false}, ()=>
        this.props.madeChanges(this.state.newChanges, this.state.form))
      }

      if (this.state.disabled == true) {
        this.setState({disabled: false});
      }

      const { form } = this.state;

      // Lets validate our field
      const error = this.validateField(field, data);
      if (error) {

          this.setState({
              errors: { [field]: error },
              form: rollbackForm,
          });

          errorNotification({ message: 'Please fix errors on form' });
          return;
      }

      // If no error, lets set the data
      // on the form and set it to state without errors
      form[field] = data;
      this.setState({
          form,
          errors: { [field]: '' },
      });

    }

    validateField(field, data) {
        // We want to make sure our wait
        // times are actually numbers
        // and greater than 0
        switch (field) {

        case 'duration':
            // We only want to allow numbers
            if (parseInt(data) < 1 || parseInt(data) > 100) {
              return 'Please enter an integer between 1-100';
            } else {
              return null;
            }

        default:
            return null;
        }
    }

    createRollbackForm() {
      const rollbackForm = Object.assign({}, this.state.form);
      this.setState({ rollbackForm });
    }

    handleClick = (e) => {
      if (this.node) {
        if (this.node.contains(e.target)) {
          this.props.clickedNode();
        }
      }
    }

    submitFullForm(cancel){
      if(cancel){
        this.props.cancel();
      } else{
        this.setState({ disabled: true });
        this.props.submitForm(this.state.form);
      }
    }

    render() {

        if (!this.props.data || !this.props.data.config || !this.state.form || !this.state.applicationOptions) {
            return (
              <StandardSettingsPage>
                <Loading />
              </StandardSettingsPage>
            );
        }

      let { form, selectionOptions } = this.state;

      const selectedVoicemail = (this.state.selectedApplication === 'voicemailbox');
      const selectedCallForwarding = (this.state.selectedApplication === 'callforwarding');

      //This differentiates numeric values from text values so we can sort them as such
      switch (this.state.selectedApplication){
        case "conferenceroom":
          let newOptions = [];
          selectionOptions.forEach((item)=> {
            if (item.value == "No Room"){
              newOptions.unshift(item);
            }
          })
          selectionOptions = newOptions;
        case "extension":
        case "extensionfollowme":
        case "voicemailbox":
            selectionOptions.sort((a, b) => (parseInt(a.value)) > parseInt(b.value) ? 1 : -1);
            break;
        default:
            selectionOptions.sort((a, b) => (a.value.toLowerCase() > b.value.toLowerCase()) ? 1 : -1)
      }

      return (
        <div ref={node => this.node = node} style={{
          display: 'flex',
          flex: 1,
          height: '100%',
          background: '#EFEFEF',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: '50px',
          overflow: 'auto',
        }}>
          <StandardSettingsPage>
            {this.state.submitError ?
              <ErrorCard data={this.state.submitError} />
              : null
            }
            <FormControlCard
              form={this.state.form}
              field="duration"
              formControl="TextInput"
              title="Duration"
              type='number'
              inputProps= {{ min: 0, max: 10 }}
              errorText={this.state.errors.duration}
              subtitle="Enter a number between 1 & 100 to specify how long this call group should ring."
              icon={<TimeIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
            />
            <FormControlCard
              form={this.state.form}
              field="failover_application"
              formControl="SelectInput"
              title="Application"
              subtitle=""
              icon={<DescriptionIcon />}
              submitForm={(field, data) => {
                this.prepareSelectionField(data);
                this.submitForm(field, data);
                form['failover_value'] = '';
                this.setState({form})
              }}
              options={this.state.applicationOptions}
            />
            {selectedCallForwarding ?
              <FormControlCard
                form={this.state.form}
                field="failover_value"
                formControl="TextInput"
                title="Call Forwarding Number"
                subtitle=""
                icon={<CheckIcon />}
                submitForm={(field, data) => this.submitForm(field, data)}
              />
              :
              <FormControlCard
                form={this.state.form}
                field="failover_value"
                formControl="SelectInput"
                title="Value"
                subtitle=""
                icon={<CheckIcon />}
                submitForm={(field, data) => {
                  if(data === "No Room"){
                    data = ''
                  } 
                  this.submitForm(field, data)}}
                options={this.state.selectionOptions}
              />
            }
            {selectedVoicemail ?
              <FormControlCard
                form={this.state.form}
                field="failover_argument"
                formControl="SelectInput"
                title="Argument"
                subtitle=""
                icon={<ArgumentIcon />}
                submitForm={(field, data) => {
                  this.submitForm(field, data)
                }}
                options={this.state.argumentList}
              />
              :
              <FormControlCard
                form={this.state.form}
                field="failover_argument"
                formControl="TextInput"
                title="Argument"
                subtitle=""
                icon={<ArgumentIcon />}
                submitForm={(field, data) => this.submitForm(field, data)}
              />
            }
            <FormControlCard
              form={this.state.form}
              field="failover_markunanswered"
              formControl="ToggleInput"
              title="Mark Unanswered"
              subtitle="Mark Unanswered will over ride the disposition in reporting and mark the call as No Answer if the failover is reached."
              icon={<RemoveIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
              label="No / Yes"
            />
            <Footer
              disabled={this.state.disabled}
              onClick={(cancel) => this.submitFullForm(cancel)}
            />
          </StandardSettingsPage>
        </div>
        );
    }
}

const bindActions = (dispatch) => ({});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, bindActions)(Failover);
