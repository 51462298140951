import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RightArrow from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/styles';

import DetailContainerRenderer from './DetailContainerRenderer';

class DetailContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            leftBarOpen: false,
            mediaQuery: false,
            anchorEl: null
        };
    }

    componentDidMount() {

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
    }

    componentDidUpdate(prevProps){
        if(prevProps && prevProps.closeMenu !==  this.props.closeMenu){
            this.setState({anchorEl: null});
        }
    }

    render() {

        const minWidth = this.state.mediaQuery.matches;

        const style = {
          titleWrapper: {
            display: 'flex',
            alignItems: 'center',
          },
        }

        const useStyles = makeStyles({
            backButton: {
                margin: 0,
                marginLeft: '19px',
                minWidth: '60px',
            },
            menuButton: {
                marginLeft: this.state.leftBarOpen ? 360 : 10,
            },
            wrapper: {
                display: 'flex',
                flex: 1,
                marginLeft: minWidth ? 350 : 0,
                flexDirection: 'column',
            },
            toolbar: {
                paddingLeft: '15px',
                backgroundColor: '#e8e8e8',
                zIndex: 1,
            },
            toolbarTitle: {
                marginLeft: '10px',
            },
            titleGroup: {
                justifyContent: 'flex-start',
                // minWidth: '400px',
            },
            chevronRight: {
                color: 'rgba(0, 0, 0, 0.4)',
            },
        });

        let {title, route, description} = this.props;
        
        if(!title && route && description) {
            title = (
                <div style={style.titleWrapper}>
                 {description}
                </div>
              );
        }
        else if (title && route) {
            title = (
              <div style={style.titleWrapper}>
                {title} <RightArrow style={style.chevronRight} /> {route}
              </div>
            );
        }

        const leftBarWithProps = React.Children.map(this.props.leftBar,
            (child) => {

                const toggleLeftBar = () => {

                    if (!minWidth) {
                        this.setState({ leftBarOpen: !this.state.leftBarOpen });
                    }
                }

                if (!minWidth) {
                    return React.cloneElement(child, { leftBarOpen: this.state.leftBarOpen, toggleDrawer: () => toggleLeftBar() });
                }

                return React.cloneElement(child, { leftBarOpen: true, toggleDrawer: () => toggleLeftBar() });
            },
        );
        return (
          <DetailContainerRenderer
            parent={this}
            useStyles={useStyles}
            leftBarWithProps={leftBarWithProps}
            minWidth={minWidth}
            title={title}
          />
        );
    }
}

DetailContainer.defaultProps = {
    title: '',
    description: '',
    contextMenuOptions: [],
    backButtonWithoutRoute: false,
};

DetailContainer.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
    leftBar: PropTypes.element.isRequired,
    contextMenuOptions: PropTypes.array, // array of material-ui MenuItems
    backButtonWithoutRoute: PropTypes.bool,
};

export default DetailContainer;
