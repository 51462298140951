import React from 'react';
import Button from '@material-ui/core/Button';

function ShowAdvance(props) {
  return (
    <div>
      <Button
        variant="contained"
        onClick={props.onChange}
        color="primary"
      >
        {props.show ? 'Hide Advanced' : 'Show Advanced'}
      </Button>
    </div>
  );
}

export default ShowAdvance;
