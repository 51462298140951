import React from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import makeGlobalStyles from 'makeGlobalStyles';

export default function HelpContent(props) {
  const classes = props.useStyles();
  const globalClasses = makeGlobalStyles();
  return (
    <div className={classes.wrapper}>
            <Card classes={{ root: classes.helpPaper }} elevation={1}>
              <CardContent classes={{ root: classes.helpContent }}>
                <div>
                  <h2 className={classes.pageTitle}>Manage Voicemail Boxes</h2>
                  { tplHelper.get('label') =='yiptel' ?
                  <p className={classes.helpText}>
                    This section of the portal allows you to configure your voicemail boxes,
                    listen to voicemails, customize greetings, and view changes to your voicemail boxes. 
                  </p>
                  :
                  <p className={classes.helpText}>
                    This section of My FluentCloud allows you to configure your voicemail boxes,
                    listen to voicemails, customize greetings, and view changes to your voicemail box
                  </p>
                  }
                </div>
                <div className={classes.helpTextTitle}>
                  About Voicemail Boxes
                </div>
                <div>
                  <p className={classes.helpText}>Much like a typical voicemail box, this section will allow you to listen to voicemails, and listen to your custom greetings you've configured. 
                  In addition to the basic functionality you're used to, we allow for a variety of custom settings for you to personalize the voicemail box experience.</p>
                  <p className={classes.helpText}>Once you have created your voicemail box, you will want to create or modify extensions in order to use this functionality.</p>
                </div>
              </CardContent>
              <CardContent classes={{ root: classes.standardHelpBlurb }}>
                <div>
                  <h2 className={classes.helpTextTitle}>Need Help?</h2>
                  { tplHelper.get('label') =='yiptel' ?
                  <div>
                  <p className={classes.helpText}>The whole team at YipTel is ready to help you. 24 hours a day, 365 (or 366!) days a year. You can reach us a number of ways.</p>
                  <ul>
                    <li>By calling 800-752-6110</li>
                    <li>By emailing support@yiptel.com</li>
                    <li>By clicking the button below!</li>
                  </ul>
                  </div>
                  :
                  <div>
                  <p className={classes.helpText}>The whole team at FluentStream is ready to help you. 24 hours a day, 365 (or 366!) days a year. You can reach us a number of ways.</p>
                  <ul>
                    <li>By calling 303-GO-CLOUD (303-462-5683)</li>
                    <li>By emailing support@fluentstream.com</li>
                    <li>By clicking the button below!</li>
                  </ul>
                  </div>
                  }
                  <p />
                </div>
                <Button
                    size='large'
                    classes={{ root: globalClasses.helpButton }}
                    href="http://help.fluentcloud.com/support/solutions/articles/4000149240-how-to-create-and-maintain-a-voicemail-box"
                    target="_blank"
                  >How To Manage Voicemail Boxes</Button>
              </CardContent>
            </Card>
          </div>
  );
}