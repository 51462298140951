import React, { Component } from 'react';
import { connect } from 'react-redux';

//Presentational Components
import MasterView from 'presentational/MasterView';
import DetailContainer from 'presentational/DetailContainer';
import LeftBar from 'presentational/LeftBar';
import StandardDetailsPage from 'presentational/StandardDetailsPage';
import ActionCard from 'presentational/ActionCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';
import SaveChangesModal from 'presentational/SaveChangesModal';
import DeleteModal from 'presentational/DeleteModal';

//Material Components
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';

//Components
import MailboxSettings from '../voicemailBoxes/settings';
import VoicemailInbox from '../voicemailBoxes/voicemailInbox';
import History from './history';
import Greetings from './greetings';
import VoicemailHelp from './help';

import { selectMailbox } from 'actions/voicemail';

//Requests
import { 
    getUserPortalHttp, 
    getCoreHttp,
    putCoreHttp,
    postCoreHttp,
    patchUserPortalHttp,
    postUserPortalHttp,
    deleteCoreHttp,
    deleteUserPortalHttp,
} from 'actions/httpRequest';

import {
    errorNotification,
    successNotification,
} from 'actions/notifications';

const styles = () => ({
    button: {
        fontSize: '100%',
    },
  });

class VoicemailBoxes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            leftBarData: [],
            selectedVoicemailBox:'',
            pending:true,
            route: null,
            showingDeleteConfirmModal:false,
            showingCopyVoicemailBoxModal: false,
            greetingType:'',
            clickedSurroundingElements: false,
            clickedInsideElements: false,
            newChanges: false,
            closeMenu: false,
            childFormData: {},
            copyVoicemailBoxNumber: '',
            copyVoicemailBoxName: '',
            notificationId: Math.round(Math.random() * 100),
        };
    }

    componentDidMount() {
        this.clickedElement();
        const reqData = {
          reqAction: 'voicemail',
        };

        if (window.location.hash.includes("/")) {
            let extension = window.location.hash.split("/")[1];
            this.leftBarSelect(extension);
        }

        const storeKey = 'voicemail';

        this.fetchConfigurations();
        this.fetchAutoAttendantData();
        this.props.getCoreHttp(reqData, storeKey);

    }

    componentWillReceiveProps(nextProps){
        if (nextProps.error && nextProps.reducerTarget == "voicemailboxes"){
            this.renderErrorMessage(nextProps.error);
        } 


        if (nextProps.postSuccess && nextProps.reducerTarget == "voicemailboxes"){
            if (nextProps.postSuccess.storeKey == 'voicemailgreetings'){
                //TODO: fix this
                this.setState({ pendingGreetings: true});
                setTimeout(() => {
                    this.setState({ pendingGreetings: false});
                    this.fetchVoicemailGreetings(this.state.selectedVoicemailBox);
                }, 10000);
            } else if (nextProps.postSuccess.storeKey !== 'user') {
                this.leftBarSelect(nextProps.postSuccess.object);
                this.renderSuccessMessage(`Successfully created mailbox ${nextProps.postSuccess.object}`)
            }
        }

        if (nextProps.deleteSuccess && nextProps.reducerTarget == "voicemailboxes" && Number(nextProps.deleteSuccess.object)){
            //TODO: fix this
            this.setState({ pendingGreetings: true});
                setTimeout(() => {
                    this.setState({ pendingGreetings: false});
                }, 10000);
            this.renderSuccessMessage(`Successfully deleted mailbox ${nextProps.deleteSuccess.object}`)
        }

        this.setState({
            greetings: nextProps.greetings,
            voicemailBoxes: nextProps.voicemailBoxes,
        }, () => {
            if (nextProps){
                this.prepareDataForLeftBar();
            }
        })
    }

    //Get all voicemail messages
    fetchVoicemails(voicemailbox) {

        const reqData = {
            reqAction: 'voicemailmessages',
            reqObject: voicemailbox
        };

        const storeKey = 'voicemailmessages';

        this.props.getUserPortalHttp(reqData, storeKey);
    }

    //fetch configurations
    fetchConfigurations(){
        const reqData = {
            reqAction: 'configurations',
        }

        this.props.getCoreHttp(reqData);
    }


    fetchAutoAttendantData() {

        // Lets set up the request data to retreive auto attendants
        const reqData = {
            reqAction: 'autoattendant',
        };

        const storeKey = 'autoAttendants';

        this.props.getCoreHttp(reqData, storeKey);

    }

    //Get voicemail history
    fetchVoicemailHistory(voicemailBox) {

        const reqData = {
            auditHistory: true,
            reqAction: 'voicemail',
            reqObject: voicemailBox,
        };

        const storeKey = 'voicemailhistory';

        this.props.getCoreHttp(reqData, storeKey);
    }

    //Get voicemail greetings
    fetchVoicemailGreetings(voicemailBox){

        const reqData={
            reqAction: 'voicemailgreetings',
            reqObject: voicemailBox,
        }

        const storeKey = "voicemailgreetings";

        this.props.getUserPortalHttp(reqData, storeKey);
    }

    //Upload a new greeting from an audio file
    upload(greeting, greetingType){

        if (greeting && greeting.target) {
            this.setState({ 
                greetingPath: greeting.target.files[0],
                greetingType,
                greetingData:null
            })
        } else {
            this.setState({
                greetingData: greeting,
                greetingType,
            })
        }
    }

    //Create New Audio Greeting
    createGreeting() {

        let formData;

        if (this.state.greetingData){
            let blob = this.state.greetingData.blob;

            let fileType = blob.type.split('/')[0] || 'audio';
            let fileName = (Math.random() * 1000).toString().replace('.', '');

            formData = new FormData();
            formData.append(fileType + '-filename', fileName);
            formData.append(fileType + '-blob', blob);
    
            let data = formData;
            data.append('file', formData);

        } else {

            formData = new FormData();
            let file = this.state.greetingPath;
            formData.append('file', file);
        }

        let { selectedVoicemailBox, greetingType } = this.state;
    
        let shortGreeting = '';

        switch (greetingType) {
            case 'Unavailable':
                shortGreeting='unavail';
                break;
            case 'Busy':
                shortGreeting='busy';
                break;
            case 'Name':
                shortGreeting='greet';
                break;
            case 'Temporary':
                shortGreeting='temp';
                break;
        }

        const reqData =  {
            reqAction: 'voicemailgreetings',
            reqObject: shortGreeting,
            reqObject2: selectedVoicemailBox,
            reqBody: formData
        }

        const storeKey = 'voicemailgreetings';

        this.props.postUserPortalHttp(reqData, storeKey);
    }

    //Edit VM Settings
    editVMBox(data){

        var reg = /^\d+$/;
        if (!reg.test(data.password)) {
            return this.renderErrorMessage("Password must be numeric");
        }

        let { selectedVoicemailBox } = this.state;

        const reqData = {
            reqAction: 'voicemail',
            reqBody: data,
            reqObject: selectedVoicemailBox,
        }

        const storeKey = 'voicemail';

        this.setState({ newChanges: false});
        this.props.putCoreHttp(reqData, storeKey);
    }

    //Create VM Box

    async createNewVoicemailBox(form){

        /* This function checks to see if mailboxnumber already exists & makes sure voicemailBoxes is an array, which is just
        an object after the first Voicemailbox is created. I had to do this to have access to the some() method */
        const doesMailboxExist = () => {
            let voicemailBoxes = this.state.voicemailBoxes

            if(voicemailBoxes && Array.isArray(voicemailBoxes)) {
                return voicemailBoxes.some(e => e.mailbox === form.friendlyname)
            } else if (voicemailBoxes) {
                return Object.values(voicemailBoxes).some(e => e.mailbox === form.friendlyname)
            }
        }

        if (!form.friendlyname || !form.location || !form.password){
            return this.renderErrorMessage("Please complete all required fields");
        }

        if (form.friendlyname.length > 10){
            return this.renderErrorMessage("Mailbox must not be longer than 10 characters");
        }

        if(doesMailboxExist()){
            return this.renderErrorMessage("That mailbox number already exist");
        }

        //let's make sure our password and mailbox are numeric
        var reg = /^\d+$/;
        if (!reg.test(form.friendlyname)) {
            return this.renderErrorMessage("Mailbox must be numeric");
        }
        if (form.password && !reg.test(form.password)) {
            return this.renderErrorMessage("Password must be numeric");
        }

        if (!Number.isInteger(form.password)) {
            form.password = parseInt(form.password, 10)
        }

        const reqData = {
            reqAction: 'voicemail',
            reqBody: form
        }

        const storeKey = 'voicemail';

        this.navigate('');
        this.setState({ newChanges: false});
        await this.props.postCoreHttp(reqData, storeKey);
    }

    //Move voicemail from one folder to another 
    moveFolder(reqData,storeKey){
        this.props.patchUserPortalHttp(reqData, storeKey);
    }

    //Delete VM Box
    handleDeleteVoicemailBox(){
        let {selectedVoicemailBox, leftBarData} = this.state;

        //Remove our left bar item
        leftBarData = leftBarData.filter(dataItem => dataItem.id != selectedVoicemailBox)

        const reqData = {
            reqAction:'voicemail',
            reqObject: selectedVoicemailBox
        }

        const storeKey="voicemail";

        this.props.deleteCoreHttp(reqData, storeKey);

        this.setState({
            route:'',
            selectedVoicemailBox:null,
            showingDeleteConfirmModal: false,
            leftBarData
        });
    }

    //Delete a VM Greeting
    removeGreeting(greeting){
        let { selectedVoicemailBox } = this.state;
        let shortGreeting = '';

        switch (greeting) {
            case 'Unavailable':
                shortGreeting='unavail';
                break;
            case 'Busy':
                shortGreeting='busy';
                break;
            case 'Name':
                shortGreeting='greet';
                break;
            case 'Temporary':
                shortGreeting='temp';
                break;
        }

        const reqData =  {
            reqAction: 'voicemailgreetings',
            reqObject: shortGreeting,
            reqObject2: selectedVoicemailBox,
        }

        const storeKey = 'voicemailgreetings';

        this.props.deleteUserPortalHttp(reqData, storeKey)

    }

    prepareDataForLeftBar(){
        if (!this.state || !this.state.voicemailBoxes){
            this.setState({ leftBarData: [], pending: false });
            return console.log("No voicemailboxes returned");
        }

        const leftBarData= [];
        const newData = this.state.voicemailBoxes;

        if (newData){
            Object.keys(newData).map(function(key, index) {
                const title=newData[key].fullname ? newData[key].fullname : newData[key].mailbox; 
                const subtitle= newData[key].mailbox;
                const id = newData[key].mailbox;
                return leftBarData.push({ id, title, subtitle})
            })
        }

        return this.setState({ 
            pending:false,
            leftBarData 
        });
    }

    leftBarSelect(voicemailBox) {

        this.setState({
            selectedVoicemailBox: voicemailBox,
        });

        const reqData = {
            reqAction: 'voicemail',
            reqObject: voicemailBox
        };

        const storeKey = 'voicemail';

        this.navigate('');
        this.props.selectMailbox(voicemailBox);
        this.props.getCoreHttp(reqData, storeKey);
        this.fetchVoicemailHistory(voicemailBox);
        this.fetchVoicemails(voicemailBox); 
        this.fetchVoicemailGreetings(voicemailBox);
        app.navigate(`voicemailboxes/${voicemailBox}`);
    }

    handleDeleteConfirmClose(shouldDelete) {

        shouldDelete && this.handleDeleteVoicemailBox();
        this.setState({ showingDeleteConfirmModal: false });
    }

    handleCopyConfirmClose(shouldCopy) {
        if (shouldCopy) {
            const targetVoicemailBoxObj = Object.values(this.props.voicemailBoxes).find(voicemailBoxObj => voicemailBoxObj && voicemailBoxObj.mailbox == this.props.selectedMailbox);
            const copiedVoicemailBoxObj = Object.assign({}, targetVoicemailBoxObj);

            delete copiedVoicemailBoxObj.index;
            copiedVoicemailBoxObj.busy_greeting = '';
            copiedVoicemailBoxObj.greet_greeting = '';
            copiedVoicemailBoxObj.temp_greeting = '';
            copiedVoicemailBoxObj.unavail_greeting = '';            
            copiedVoicemailBoxObj.mailbox = this.state.copyVoicemailBoxNumber;
            copiedVoicemailBoxObj.friendlyname = this.state.copyVoicemailBoxNumber;
            copiedVoicemailBoxObj.fullname = this.state.copyVoicemailBoxName ? this.state.copyVoicemailBoxName : null;

            this.createNewVoicemailBox(copiedVoicemailBoxObj);
        }
        this.setState({ showingCopyVoicemailBoxModal: false });
    }

    handleDialogContentChange = (e, value) => {
        if (e.target.name == 'copyVoicemailBoxNumber') {
            this.setState({ copyVoicemailBoxNumber: e.target.value });
        } else if (e.target.name == 'copyVoicemailBoxName') {
            this.setState({ copyVoicemailBoxName: e.target.value });
        } else if (e.target.name == 'copyGreetingsBoolean') {
            this.setState({ copyGreetingsBoolean: value })
        }
    }

    renderErrorMessage(response){
        errorNotification({
            id: this.state.notificationId,
            title:'Error!',
            message: response
        });  
    }

    renderSuccessMessage(response){
        successNotification({
            id: this.state.notificationId,
            title:'Success!',
            message: response
        });
    }

    voicemailCards(){
        return([
            <ActionCard
                key="settings"
                title="Settings"
                subtitle="Settings for customizing how you and others 
                interact with your voicemail."
                action={()=> this.navigate('Settings')}
            />,
            <ActionCard
                key="greetings"
                title="Greetings"
                subtitle="Record and change your voicemail greetings."
                action={()=> this.navigate('Greetings')}
            />,
            <ActionCard
                key="inbox"
                title="Inbox"
                subtitle="Listen to, move and delete your voicemails."
                action={()=> this.navigate('Inbox')}
            />,
            <ActionCard
                key="history"
                title="Edit History"
                subtitle="View the changes made to your voicemail settings."
                action={()=> this.navigate('History')}
            />
        ])
    }


    navigate(route){
        this.setState({ route });
    }

    goToHelp() {
        const win = window.open('http://help.fluentcloud.com/support/solutions', '_blank');
        win.focus();
    }

    openCloseMenu() {
        this.setState({ closeMenu: !this.state.closeMenu });
    }

    renderContent(){
        let selectedVMBoxSettings;

        if (this.props && this.props.voicemailBoxes){
            Object.values(this.props.voicemailBoxes).forEach((voicemailBox) => {
                if (this.state.selectedVoicemailBox === voicemailBox.mailbox) {
                    selectedVMBoxSettings = voicemailBox;
                }
            })   
        };

        const { route, selectedVoicemailBox } = this.state;
        if (!this.props.configurations){
            return;
        }
        const { locations, timezones, applications, tenant } = this.props.configurations;
        const { history, inbox, greetings, configurations, pending, autoAttendants} = this.props;

        if (!selectedVoicemailBox){
            return <VoicemailHelp />;
        }

        if (this.state.selectedVoicemailBox && !this.props.inbox && this.state.route != "New"){
            return (
                <StandardSettingsPage>
                    <Loading />;
                </StandardSettingsPage>
            )
        };

        switch (route) {
            case 'New':
                return (
                    <MailboxSettings
                        createMailbox={(data) => this.createNewVoicemailBox(data)}
                        selectedVoicemailBox={(data) => this.props.newVoicemailbox(data)}
                        locations={locations}
                        applications={applications}
                        configurations={configurations}
                        autoAttendants={autoAttendants}
                        new={true}
                        tenant={tenant}
                        adminView={true}
                        timezones= {timezones}
                        cancel={()=>this.navigate('')}
                        madeChanges={(answer, data) => this.madeChanges(answer, data)}
                        clickedNode={() => this.clickedNode()}
                        renderErrorMessage={(response) => this.renderErrorMessage(response)}
                    />
                );
            case 'Settings':
                return (
                    <MailboxSettings  
                        settingsData={selectedVMBoxSettings}
                        selectedVoicemailBox={selectedVoicemailBox}
                        locations={locations}
                        tenant= {tenant}
                        adminView={true}
                        applications={applications}
                        cancel={()=> this.navigate('')}
                        selectedVoicemailBox={selectedVoicemailBox}
                        submitForm={data => this.editVMBox(data)}
                        applications={applications}
                        autoAttendants={autoAttendants}
                        configurations= {configurations}
                        timezones= {timezones}
                        cancel={()=> this.resetChanges()}
                        madeChanges={(answer, data) => this.madeChanges(answer, data)}
                        clickedNode={() => this.clickedNode()}
                        renderErrorMessage={(response) => this.renderErrorMessage(response)}
                    />
                );
            case 'Greetings':
                return (
                    <Greetings 
                        greetings = {greetings}
                        tenant = {tenant}
                        adminView={true}
                        selectedVoicemailBox = {selectedVoicemailBox}
                        pending = {this.state.pendingGreetings}
                        removeGreeting={(greeting) => this.removeGreeting(greeting)}
                        upload={(greeting, greetingType) => this.upload(greeting, greetingType)}
                        createNewGreeting={() => this.createGreeting()}
                        cancel={()=> this.navigate('')}
                    />
                );
            case 'Inbox':
                return (
                    <VoicemailInbox
                        voicemails = {inbox.data}
                        adminView={true} 
                        pending = {pending}
                        selectedVoicemailBox ={selectedVoicemailBox}
                        settingsData={selectedVMBoxSettings}
                        tenant={tenant}
                        moveFolder={(reqData, storeKey)=> this.moveFolder(reqData, storeKey)}
                    />
                );
            case 'History':
                return (
                    <History 
                        history={history}
                        selectedVoicemailBox = {selectedVoicemailBox}
                    />
                )
            default:
                return (
                    <StandardDetailsPage cards={this.voicemailCards()}/>
                )
        }
    }

    resetChanges() {
        this.setState({
            clickedInsideElements: false,
            clickedSurroundingElements: false,
            newChanges: false
        }, () => this.navigate(''))
    }

    clickedElement = () => {
        var elements = document.querySelectorAll(".header, .leftBarDiv, .detailContainerDiv");
        for (var i = 0; i < elements.length; i++) {
          elements[i].addEventListener("click", () => {
            this.unsavedChanges();
          });
        }
      }
    
    unsavedChanges() {
        const { clickedInsideElements, newChanges } = this.state
            if (clickedInsideElements && newChanges) {
                this.openModal()
            }
    }
    
    openModal() {
        this.setState({ showingSaveChangesModal: true })
    }
    
    clickedNode(){
        this.setState({ clickedInsideElements: true })
    }
    
    madeChanges(answer, data) {
        this.setState({
            newChanges: answer,
            childFormData: data,
        });
    }

    saveChanges() {
        this.setState({
            showingSaveChangesModal: false,
            newChanges: false,
            clickedInsideElements: false,
            clickedSurroundingElements: false,
        });
        this.editVMBox(this.state.childFormData);
        this.navigate('');
    }

    discardChanges() {
        this.setState({
            showingSaveChangesModal: false,
            newChanges: false,
            clickedInsideElements: false,
            clickedSurroundingElements: false,
        }, () => this.navigate(''));
    }

    render() {
        let { leftBarData, selectedVoicemailBox, pending, route } = this.state;
        const title = selectedVoicemailBox;
        const { classes } = this.props;

        const contextMenuOptions = [
            <MenuItem
              disabled={!selectedVoicemailBox}
              onClick={() => {this.setState({ showingDeleteConfirmModal: true }); this.openCloseMenu();}}
              key={0}
            >Delete</MenuItem>,
            <MenuItem
                onClick={() => {this.setState({ showingCopyVoicemailBoxModal: true }); this.openCloseMenu();}}
                classes={{ root: classes.menuItem }}
                disabled={!selectedVoicemailBox}
                key={1}
                >
            Copy
            </MenuItem>,
            <MenuItem
              onClick={() => {this.goToHelp(); this.openCloseMenu();}}
              key={2}
            >Help</MenuItem>,
          ];

        return (
            <div>
                <MasterView>
                    <DetailContainer
                        leftBar={
                            <LeftBar
                                addNew={() => {
                                    this.navigate('New'),
                                    this.setState({ selectedVoicemailBox: 'New'})
                            }}
                                data={leftBarData}
                                pending={pending}
                                selected={selectedVoicemailBox}
                                select={(id) => this.leftBarSelect(id)}
                                title="Voicemail Boxes"
                            />
                        }
                        title={title}
                        contextMenuOptions={contextMenuOptions}
                        closeMenu={this.state.closeMenu}
                        route={route != 'New' ? route : null}
                        return={() => this.setState({ route: null })}
                    >
                        {this.renderContent()}
                    </DetailContainer>
                    <DeleteModal
                        open={this.state.showingDeleteConfirmModal}
                        title='Are you sure?'
                        content={<p>Do you really want to delete this voicemail? This action is irreversible.</p>}
                        delete={() => this.handleDeleteConfirmClose(true)}
                        cancel={() => this.handleDeleteConfirmClose(false)}
                    />
                    <Dialog
                        open={this.state.showingCopyVoicemailBoxModal}
                        onEnter={() => this.setState({ copyVoicemailBoxNumber: '', copyVoicemailBoxName: '' })}
                        onClose={() => this.handleCopyConfirmClose(false)}
                        fullWidth={true}
                    >
                    <DialogTitle>Voicemail Box Copy</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To make a copy of this voicemail box, please enter a unique mailbox number and an optional name. Greetings will not be copied over.
                        </DialogContentText>
                        <TextField 
                            autoFocus
                            fullWidth={true}
                            margin='dense'
                            name='copyVoicemailBoxNumber'
                            label='Mailbox Number'
                            type='text'
                            value={this.state.copyVoicemailBoxNumber}
                            onChange={this.handleDialogContentChange}
                        />
                        <TextField 
                            autoFocus
                            fullWidth={true}
                            margin='dense'
                            name='copyVoicemailBoxName'
                            label='Mailbox Name'
                            type='text'
                            value={this.state.copyVoicemailBoxName}
                            onChange={this.handleDialogContentChange}
                        />
                    </DialogContent>
                    <DialogActions classes={{ root: classes.actions }}>
                        <Button
                            classes={{ root: classes.button }}
                            color='primary'
                            onClick={() => this.handleCopyConfirmClose(false)}
                        >Cancel</Button>
                        <Button
                            disabled={!this.state.copyVoicemailBoxNumber || Object.values(this.props.voicemailBoxes).some(voicemailBoxObj => voicemailBoxObj && voicemailBoxObj.mailbox == this.state.copyVoicemailBoxNumber)}
                            classes={{ root: classes.button }}
                            color='primary'
                            onClick={() => this.handleCopyConfirmClose(true)}
                        >Copy</Button>
                    </DialogActions>
                </Dialog>
                </MasterView>
            </div>
        );
    }
}

const bindActions = (dispatch) => ({
    getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
    getUserPortalHttp: (reqData, storeKey) => dispatch(getUserPortalHttp(reqData, storeKey)),
    putCoreHttp: (reqData, storeKey) => dispatch(putCoreHttp(reqData, storeKey)),
    deleteCoreHttp: (reqData, storeKey) => dispatch(deleteCoreHttp(reqData, storeKey)),
    postUserPortalHttp: (reqData, storeKey) => dispatch(postUserPortalHttp(reqData, storeKey)),
    deleteUserPortalHttp: (reqData, storeKey) => dispatch(deleteUserPortalHttp(reqData, storeKey)),
    patchUserPortalHttp: (reqData, storeKey) => dispatch(patchUserPortalHttp(reqData, storeKey)),
    postCoreHttp: (reqData, storeKey) => dispatch(postCoreHttp(reqData, storeKey)),
    selectMailbox: (data) => dispatch(selectMailbox(data)),
});

const mapStateToProps = (state) => ({
    autoAttendants: state.autoAttendants.data,
    configurations:state.http ? state.http.configurations : null,
    selectedVoicemailBox:state.voicemail.selectedVoicemailBox,
    voicemailBoxes:state.voicemail.data,
    history: state.voicemail.history,
    inbox: state.voicemail.inbox,
    greetings: state.voicemail.greetings,
    pending: state.voicemail.pending,
    error: state.voicemail.error,
    putSuccess: state.voicemail.putSuccess,
    postSuccess: state.voicemail.postSuccess,
    patchSuccess: state.voicemail.patchSuccess,
    deleteSuccess: state.voicemail.deleteSuccess,
    selectedMailbox: state.voicemail.selectedMailbox,
    reducerTarget: state.voicemail.reducerTarget,
});

export default connect(mapStateToProps, bindActions)(withStyles(styles)(VoicemailBoxes));
