import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import GoIcon from '@material-ui/icons/NavigateNext';

import IconButton from '@material-ui/core/IconButton';

export default function ActionCardRenderer(props) {
  const classes = props.useStyle();
  const { parent } = props;
  return (
    <Card
      onClick={parent.props.action}
      classes={{ root: classes.card }}
    >
      <CardHeader 
        title={parent.props.title}
        classes={{ root: classes.title }}
        action={
          <IconButton
            onClick={parent.props.action}
          >
            <GoIcon />
          </IconButton>
        }>
      </CardHeader>
      {props.minWidth ?
        <CardHeader subheader={parent.props.subtitle} />
        : null
      }
    </Card>
  );
}
