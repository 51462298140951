import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Loading from 'presentational/Loading';
import Footer from 'presentational/Footer';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

import { postUserPortalHttp } from 'actions/httpRequest';

function StepThree(props) {
  const submit = (goBack) => {
    if (goBack) {
      props.changeStep(2);
    } else {
      const { form } = props;
      const { files, callerid, did, reference } = form;
      const payload = new FormData();

      files.forEach((element, idx) => {
        payload.append(`file${idx + 1}`, element);
      });

      const reqData = {
        reqAction: 'sendfax',
        reqObject: form.uniqueid,
        reqQuery: `callerid=${callerid}&did=${did}&reference=${reference}`,
        reqBody: payload,
      }

      props.sendFax(reqData);
    }
  }

  const renderSuccess = () => {
    return (
      <div style={styles.container}>
        <h1>Success <ThumbUpIcon/></h1>
        <h4>Successfully started the fax.</h4>
        <br/>
        <p>
          The fax is being sent! If fax notifications are turned on for this account, you will receive an email with the status when complete.
        </p>
        <Footer
          fullWidth={true}
          singleButton={true}
          onClick={() => props.changeStep(1)}
          submitButtonText='Send Another Fax'
        />
      </div>
    );
  }

  const renderError = () => {
    return (
      <div style={styles.container}>
        <h1>Error <ThumbDownIcon/></h1>
        <br/>
        <p>
          You can try to send the fax again or go back and edit information or file.
        </p>
        <Footer
          fullWidth={true}
          onClick={submit}
          submitButtonText='Try Again'
          cancelButtonText='Go Back'
        />
      </div>
    );
  }

  if (props.sentFaxSuccess) {
    return <>{renderSuccess()}</>;
  } else if (props.sentFaxError) {
    return <>{renderError()}</>;
  } else {
    return <Loading />;
  }
}

const bindActions = (dispatch) => ({
  resetFlag: () => dispatch(resetFlag()),
  sendFax: (data) => dispatch(postUserPortalHttp(data)),
});

const mapStateToProps = (state) => ({
  form: state.createFax.createFaxForm,
  sentFaxSuccess: state.createFax.sentFaxSuccess,
  sentFaxError: state.createFax.sentFaxError,
});

const styles = {
  container: {
    paddingTop: '20px',
  }
}

StepThree.propTypes = {
  form: PropTypes.object,
  sentFaxSuccess: PropTypes.bool,
  sentFaxError: PropTypes.bool,
}

export default connect(mapStateToProps, bindActions)(StepThree);
