import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

export default function CancelButton(props) {
  const classes = makeStyles({
    button: {
      ...props.style,
      fontSize: '90%',
    },
  })();
  return (
    <Button
      onClick={() => props.onClick()}
      classes={{ root: classes.button }}
      size='large' 
      color='primary'
    >{props.label}</Button>
  )
}

CancelButton.defaultProps = {
    label: 'Cancel',
    onClick: () => console.log('No onClick func passed into cancel button'),
};

CancelButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
};
