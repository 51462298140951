import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import makeGlobalStyles from 'makeGlobalStyles';

function CallForwardingButton(props) {
  const classes = makeGlobalStyles();
  return (
    <Button
      classes={{ root: classes.helpButton }}
      onClick={() => {
        props.toggleForwarding();
      }}
    >
      {props.text}
    </Button>
  );
}

CallForwardingButton.propTypes = {
  text: PropTypes.string.isRequired,
  toggleForwarding: PropTypes.func.isRequired,
}

export default CallForwardingButton;
