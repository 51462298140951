import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles'

import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

const style = {
    searchWrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '25px',
        paddingRight: 20,
        marginBottom: 5,
        marginTop: 20,
    },
    textField: {
        marginLeft: 25
    },
    searchIcon: {
        color: 'rgba(115, 115, 115, .8)',
        marginTop: 10,
        marginLeft: 5,
    },
};

const SearchBox = (props) => {
    //allow parent to override styles
    if(props && props.style && props.style.override){

        Object.entries(props.style.override).forEach( (elementToOverride) => {

            if(style[elementToOverride[0]]){

                Object.keys(props.style.override[elementToOverride[0]]).forEach( (key)=>{
                    style[elementToOverride[0]][key] = props.style.override[elementToOverride[0]][key];
                })
            }
        })
    }

    const classes = makeStyles(style)();
    return (
      <div className={classes.searchWrapper}>
        <TextField
          id={`leftbar_search`}
          classes={{ root: classes.textField}}
          placeholder={props.label}
          onChange={(e, text) => props.onSearch(e.target.value)}
          value={props.value}
        />
        <SearchIcon classes={{ root: classes.searchIcon}} />
      </div>
    );
};

SearchBox.defaultProps = {
    label: 'Search',
    value: undefined,
};

SearchBox.propTypes = {
    onSearch: PropTypes.func.isRequired,
    label: PropTypes.string,
    value: PropTypes.string,
};

export default SearchBox;
