import React, { Component } from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';

import ErrorCard from 'presentational/ErrorCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';

import CallFilterCard from './callFilterCard';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';

const style = () => ({
    header:{
        color: '#595959',
    },
    button: {
        margin: '5 px',
        fontSize: '90%'
    }
});

class CallFilters extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            revertedForm: {},
            submitError: null,
            showingModal: false,
            selectableNewKeys: [],
            filterGroupName: '',
            selectableCallFilter: [],
            description: '',
            selectedNewKeyCode: '',
            mediaQuery: false,
        };
    }

    componentDidMount() {

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));

    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.data && nextProps.data.config) {
            let selectableCallFilter = [];
            selectableCallFilter = nextProps.data.config

            this.setState({ callFilter: nextProps.data, selectableCallFilter });
        }
    }

    async submitForm(submittedForm, filterIndex) {
        const callFilterElement = this.state.callFilter.config.find(element => {
            return element.index === filterIndex;
        });
        callFilterElement.incoming_phonenumber = submittedForm.incomingPhoneNumber;
        callFilterElement.assigned_route = submittedForm.assignedRoute;
        callFilterElement.incoming_area_code = submittedForm.incomingAreaCode;

        this.props.submitSingleFilter(callFilterElement, this.state.callFilter.filter_group_name);
    }

    newFilter(){

        let filterGroupName;
        let callFilterIndex;

        if (this.state.callFilter){
            filterGroupName = this.state.callFilter.filter_group_name;
            callFilterIndex = this.state.callFilter.index;
        } else {
            filterGroupName = this.props.data.filter_group_name;
            callFilterIndex = this.props.data.index;
        }

        const selectableCallFilter = this.state.selectableCallFilter

        selectableCallFilter.push({
            incoming_filter_groups_index: callFilterIndex,
            incoming_phonenumber:'',
            assigned_route:'',
            incoming_area_code: null,
        })

        this.props.addNewFilter(filterGroupName);

        this.setState({ selectableCallFilter });
    }

    storeForm(callFilter){
        this.setState({revertedForm: Object.assign({}, callFilter)});
    }

    revertForm(){
        this.setState({callFilter: this.state.revertedForm});
    }

    removeCallFilter(index) {

        let callFilter = this.state.callFilter;
        let configArray = callFilter.config;
        const elementIndex = parseInt(configArray[index]['index']);
        const filterGroupName = callFilter.filter_group_name;

        configArray.splice(index, 1);

        this.setState({ callFilter });
        this.props.handleDeleteElement(elementIndex, filterGroupName);
    }

    renderCallFilterCard(formData, index) {
        const filterType= this.props.data.filter_type
        let key = formData[0];
        const cardData = formData;

        return (
            <CallFilterCard
              filterType = {filterType}
              callFilter={cardData}
              number={key}
              key={index}
              index={index}
              updateForm={(field, value) => this.updateForm(field, value)}
              submitForm={(selectableCallFilter, filterIndex) => this.submitForm(selectableCallFilter, filterIndex)}
              removeCallFilter={(index) => this.removeCallFilter(index)}
              storeForm={() => this.storeForm(this.state.selectableCallFilter)}
              revertForm={() => this.revertForm()}
            />
        );
    }

    async handleClick() {
        const callFilter = Object.assign([], this.state.incomingPhoneNumbers)


        callFilter.push({
            incoming_phonenumber:'',
            assigned_route:'',
        });

        await this.setState({ callFilter });

    }

    handleClose(submit) {

        let formErrors = {
            application: '',
            number: '',
            value: '',
        };

        this.setState({ formErrors });
        if (submit) {

            if (Object.keys(formErrors).some(err => formErrors[err])) {

                this.setState({ formErrors });
                return;
            }

            this.props.submitForm(this.state.callFilter);
        }

        this.setState({ showingModal: false, selectedNewKeyCode: '' });
    }

    render() {

        if (!this.props.data || !this.props.data.config) {

            return (
                <StandardSettingsPage>
                    <Loading />
                </StandardSettingsPage>
            );
        }

        const minWidth = this.state.mediaQuery.matches;
        const { classes } = this.props;

        return (
            <StandardSettingsPage>

                <Button
                    size='large'
                    variant="contained"
                    onClick={(field, value) => this.newFilter(field, value)}
                    color='primary'
                    classes={{ root: classes.button }}
                >Add New Call Filter</Button>

                {this.state.submitError ?
                <ErrorCard data={this.state.submitError} />
                : null
                }

                {this.state.selectableCallFilter.map((key, index) => {
                    return this.renderCallFilterCard(key, index);
                })}

                <Dialog
                    open={this.state.showingModal}
                    onClose={() => this.handleClose(false)}
                    fullWidth={true}
                ><div></div></Dialog>

            </StandardSettingsPage>
        );
    }
}

const bindActions = (dispatch) => ({

});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, bindActions)(withStyles(style)(CallFilters));
