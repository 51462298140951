import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCoreHttp, getReportsHttp } from 'actions/httpRequest';

import ConferenceGrid from './conferenceGrid';
import Search from 'presentational/Search';
import TimeIntervalSelect from 'presentational/reports/TimeIntervalSelect';
import Loading from 'presentational/Loading';

import { formatDateTimePicker } from 'utils/misc';
import { DateTimePicker } from "@material-ui/pickers";
import LinearProgress from '@material-ui/core/LinearProgress';

import { errorNotification } from 'actions/notifications';

class ConferenceRecords extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mediaQuery: false,
            dateTimes: 'today',
            reqQuery: null,
            startDate: null,
            endDate: null,
            timeInterval: 'today',
            search: '',
        };
    }

    componentDidMount() {
        this.fetchConfigurations();
        this.fetchConferenceRecords();
        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
    }
    // Build the query based off provided filters
    buildReqQuery() {
        let dateTimes = this.state.timeInterval;
        const startDate = this.state.startDate;
        const endDate = this.state.endDate || moment().endOf('day').format('MM DD YYYY hh:mm a');
        const queryEndDate = this.state.endDate || moment().endOf('day').format('MM DD YYYY hh:mm');
        let query = '';
        if (dateTimes == 'custom' && startDate.length && endDate.length) {
            query += `&startdate=${moment(startDate).format('YYYY-MM-DD HH:mm:ss')}`;
            query += `&enddate=${moment(queryEndDate).format('YYYY-MM-DD HH:mm:ss')}`;
        } else if (dateTimes && dateTimes.length > 0) {
            this.setState({ timeInterval: dateTimes });
            query += `&timeinterval=${dateTimes}`;
        }

        this.setState({ reqQuery: query });
        return query;
    }
    // Change the ReportDates filter option
    async changeDateTimeInverval(dateTime) {
        if (dateTime != 'custom') {
            this.setState({ startDate: null });
            this.setState({ endDate: null });
            await this.setState({ timeInterval: dateTime });
            this.fetchConferenceRecords();
        } else {
            await this.setState({ timeInterval: dateTime });
        }
    }
    // Change the custom startdate
    async changeStartDate(startDate) {
        const startD = formatDateTimePicker(startDate);
        await this.setState({ startDate: startD });
        this.fetchConferenceRecords();
    }
    // Change the custom endDate
    async changeEndDate(endDate) {
        const endD = formatDateTimePicker(endDate);
        await this.setState({ endDate: endD });
        this.fetchConferenceRecords();
    }
    fetchConfigurations() {
        console.log('Fetching configurations for conference summary');
        // Lets set up the request data to retreive conference summary
        const reqData = {
            reqAction: 'configurations',
        };
        this.props.getCoreHttp(reqData);
    }
    // Lets set up the request data to retreive conference summary data
    fetchConferenceRecords() {
        const reqData = {
            reqAction: 'conferencerecords',
            reqQuery: this.buildReqQuery(),
        };
        const storeKey = 'conferenceRecords';
        this.props.getReportsHttp(reqData, storeKey);
    }

    // Recceive term to filter with
    async searchRecords(term) {
        await this.setState({ search: term });
        this.filterData();
    }
    // Filter dataset with term and return or return default data
    filterData() {
        const term = this.state.search;
        const data = this.props.conferenceRecords;
        const dataCopy = [];
        let name = null;
        if (!term) {
            if (this.props.conferenceRooms) {
                data.map((session) => {
                    // Get room name
                    this.props.conferenceRooms.filter((conf) => {
                        if (conf.confno == session.room) {
                            name = conf.name;
                        }
                    });
                    session.name = name;
                    dataCopy.push(session);
                });
                return dataCopy
            } else {
                return data
            }
        }
        if (!data || data.length) {
            return data;
        }
        data.map((session) => {
            const sessionCopy = JSON.stringify(session).toLowerCase();
            if (sessionCopy.includes(term.toLowerCase())) {
                if (this.props.conferenceRooms) {
                    // Get room name
                    this.props.conferenceRooms.filter((conf) => {
                        if (conf.confno == session.room) {
                            name = conf.name;
                        }
                    });
                    session.name = name;
                }
                dataCopy.push(session)
            };
        });
        return dataCopy
    }

    render() {
        const { conferenceRooms , conferenceRecords, pending } = this.props;
        if (!conferenceRooms || !conferenceRecords) {
            return <Loading />;
        }
        let customStartDate = moment().startOf('day').format('MM DD YYYY hh:mm a');
        let customEndDate = moment().endOf('day').format('MM DD YYYY hh:mm a');
        if (this.state.startDate && this.state.startDate.length > 5) {
            customStartDate = moment(this.state.startDate).format('MM DD YYYY hh:mm a');
        }
        if (this.state.endDate && this.state.endDate.length > 5) {
            customEndDate = moment(this.state.endDate).format('MM DD YYYY hh:mm a');
        }

        const gridData = pending ? [] : this.filterData();
        return (
            <div style={style.wrapper}>
                <div style={style.toolBar}>
                    <div style={style.search}>
                        <Search
                            label="Filter by Caller"
                            onSearch={(term) => this.searchRecords(term)}
                            value={this.state.search}
                        />
                    </div>
                    <div style={style.interval}>
                        <TimeIntervalSelect
                            timeIntervals={timeIntervals}
                            timeinterval={this.state.timeInterval}
                            onChange={(dateTime) => this.changeDateTimeInverval(dateTime)}
                        />
                    </div>
                    {this.state.timeInterval == 'custom' &&
                        <div style={style.datePicker}>
                            <DateTimePicker
                                ampm={true}
                                value={customStartDate}
                                format='MMM DD, YYYY hh:mm A'
                                onChange={(startDate) => this.changeStartDate(startDate)}
                                label="Start Date"
                            />
                        </div>
                    }
                    {this.state.timeInterval == 'custom' &&
                        <div style={style.datePicker}>
                            <DateTimePicker
                                ampm={true}
                                value={customEndDate}
                                format='MMM DD, YYYY hh:mm A'
                                onChange={(endDate) => this.changeEndDate(endDate)}
                                label="End Date"
                            />
                        </div>
                    }
                </div>
                {pending && <LinearProgress />}
                <ConferenceGrid
                    data={gridData}
                    pending={this.props.pending}
                />
            </div>
        );
    }

}
const bindActions = (dispatch) => ({

    getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
    getReportsHttp: (reqData, storeKey) => dispatch(getReportsHttp(reqData, storeKey)),
});
const mapStateToProps = (state) => ({
    conferenceRooms: state.configurations && state.configurations.data && state.configurations.data.conferenceRooms ? state.configurations.data.conferenceRooms : null,
    conferenceRecords: state.conferenceRecords && state.conferenceRecords.data ? state.conferenceRecords.data : null,
    pending: state.conferenceRecords.pending ? state.conferenceRecords.pending : false,
});

const timeIntervals = [
    { value: 'today', label: 'Today' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'thisfullweek', label: 'This Week' },
    { value: 'thismonth', label: 'This Month' },
    { value: 'custom', label: 'Custom' },
];
const style = {
    wrapper: {
        marginTop: '-20px',
        height: '97vh',
        background: 'linear-gradient(0deg, #0F4E8B 50%, #EFEFEF 50%)',
        alignItems: 'center',
    },
    toolBar: {
        display: 'grid',
        paddingTop: '20px',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))'
    },
    interval: {
        marginBottom: '-5px',
        marginLeft: '-25px'
    },
    search: {
        marginTop: '-10px',
        marginLeft: '-25px'
    },
    datePicker: {
        marginBottom: '5px',
        paddingLeft: '25px'
    },
    loadingIndicator: {
        height: 10
    }
}
export default connect(mapStateToProps, bindActions)(ConferenceRecords);
