import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Footer from 'presentational/Footer';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

import { changeFormData } from 'actions/createFax';
import { postUserPortalHttp } from 'actions/httpRequest';
import { errorNotification } from 'actions/notifications';

function StepTwo(props) {
  const [typeError, setTypeError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [fileInputRef, setFileInputRef] = useState(null);

  const classes = makeStyles({
    nameCell: {
      wordBreak: 'break-word',
    }
  })();

  const builldErrorMessage = () => {
    return (
      <>
        {typeError && <li>Filetype not supported</li>}
        {sizeError && <li>File is too big</li>}
      </>
    );
  }

  const submit = (previous) => {
    if (previous) {
      props.changeStep(1);
    } else {
      if (typeError || sizeError) {
        errorNotification({
          title: 'Fax can not be sent for the following reasons',
          message: builldErrorMessage(),
        });
      } else {
        requestToSendFax();
        props.changeStep(3);
      }
    }
  }

  const requestToSendFax = () => {
    const { form } = props;
    const { files, callerid, did, reference } = form;
    const payload = new FormData();

    files.forEach((element, idx) => {
      payload.append(`file${idx + 1}`, element);
    });

    const reqData = {
      reqAction: 'sendfax',
      reqObject: form.uniqueid,
      reqQuery: `callerid=${callerid}&did=${did}&reference=${reference}`,
      reqBody: payload,
    }

    props.sendFax(reqData);
  }

  const addFile = (event) => {
    let target;

    // Handle drag and drop with event.dataTransfer
    if (event.dataTransfer) {
      target = event.dataTransfer;
    } else {
      target = event.target;
    }

    const filesObject = target.files;
    const filesArray = props.form.files;
    const newForm = cloneDeep(props.form);

    for (let i = 0; i < filesObject.length; i += 1) {
      filesArray.push(filesObject[i]);
    }

    newForm.files = filesArray;
    props.changeFormData(newForm);
  }

  const sizeParser = (size) => {
    let parsedSize = size;

    if (size >= 1000000) {
      parsedSize = size / 1000000;
      return `${parsedSize.toFixed(2)} MB`
    } else if (size >= 1000) {
      parsedSize = size / 1000;
      return `${parsedSize.toFixed(2)} KB`
    }
    return `${parsedSize.toFixed(2)} Bytes`
  }

  const removeFile = (index) => {
    const newForm = cloneDeep(props.form);

    newForm.files.splice(index, 1);
    props.changeFormData(newForm);
    setSizeError(false);
    setTypeError(false);
  }

  const errorParser = (name, size) => {
    if ((name.endsWith('.PDF') || name.endsWith('.pdf') || name.endsWith('.DOC') || name.endsWith('.doc') || name.endsWith('.DOCX') || name.endsWith('.docx')) === false) {
      if (typeError === false) {
        setTypeError(true);
      }

      if (size > 50000000 && sizeError === false) {
        setSizeError(true);
      }

      return (
        <div>
          <span style={styles.error}>Error</span> Filetype not allowed
        </div>
      );
    }

    if (size > 50000000) {
      if (sizeError === false) {
        setSizeError(true);
      }

      return (
        <div>
          <span style={styles.error}>Error</span> The Files must be less than 50MB
        </div>
      );
    }

    return '';
  }

  const dragOperations = (e) => {
    e.preventDefault();
  }

  const handleFileDrop = (e) => {
    e.preventDefault();
    addFile(e)
  }


  const renderFileInput = () => {
    return (
      <>
        <div
          style={styles.wrapper}
          onDragEnter={dragOperations}
          onDragLeave={dragOperations}
          onDragOver={dragOperations}
          onDrop={handleFileDrop}
          id='formattedButton'
        >
          <button
            onClick={() => fileInputRef.click()}
          >Choose File</button>
        </div>
        <input 
          type='file'
          name='files'
          multiple
          accept='.doc, .docx, .pdf'
          onChange={addFile}
          ref={(ref) => setFileInputRef(ref)}
          style={styles.input}
        />
      </>
    )
  }

  const buildFaxInfoContent = () => {
    const { did, callerid, reference, files } = props.form;

    return (
      <div>
        To: {did}<br/>
        From: {callerid}<br/>
        Reference: {reference}<br/>
        <br/>
        <p>
          Drag and drop files for the specified order. Feel free to delete files or cancel uploads.<br/>
          The total file upload must be smaller than 50MB.<br/>
          Supported file types: doc, docx, and pdf.
        </p>
        <br/>
        {renderFileInput()}
        <br/>
        <Table>
          <TableBody>
            {files.map((element, idx) => {
              return (
                <TableRow key={idx}>
                  <TableCell classes={{ root: classes.nameCell }}>{element.name}</TableCell>
                  <TableCell>{sizeParser(element.size)}</TableCell>
                  <TableCell>{errorParser(element.name, element.size)}</TableCell>
                  <TableCell>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => removeFile(idx)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }

  return (
    <StandardSettingsPage>
      <FormControlCard
        form={{ content: buildFaxInfoContent() }}
        field='content'
        formControl='FaxInformation'
        title='Current Details:'
        subtitle=''
      />
      <Footer
        fullWidth={true}
        onClick={submit}
        submitButtonText='Next'
        cancelButtonText='Previous'
      />
    </StandardSettingsPage>
  )
}

const bindActions = (dispatch) => ({
  changeFormData: (data) => dispatch(changeFormData(data)),
  sendFax: (data) => dispatch(postUserPortalHttp(data)),
});

const mapStateToProps = (state) => ({
  form: state.createFax.createFaxForm
});

const styles = {
  error: {
    color: 'white',
    backgroundColor: '#FF0000',
    padding: '2px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  input: {
      opacity: 0,
      height: '0px',
      width: '0px',
  },
}

StepTwo.propTypes = {
  form: PropTypes.object,
}

export default connect(mapStateToProps, bindActions)(StepTwo);