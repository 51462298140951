import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function CountDownComponent(props) {
  const [value, setValue] = useState(props.value);
  const [remaining, setRemaining] = useState(props.value);
  const [percentage, setPercentage] = useState(0);
  const [disableButton, setDisableButton] = useState(false)

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
      setRemaining(props.value);
    }
  }, [props.value])

  const startTest = () => {
    let remainingTime = remaining;

    setDisableButton(true);
    const intId = setInterval(() => {
      setPercentage(((value - remainingTime) / value) * 100.0);
      setRemaining(remainingTime);
      if (remainingTime === -1) {
        setDisableButton(false);
        setRemaining(value);
        setPercentage(0);
        clearInterval(intId);
      }
      remainingTime -= 1;
    }, 1000);
  }

  const minWidth = useMediaQuery('(min-width: 800px)');
  const style = {
    card: {
      width: minWidth ? '50%' : '100%',
      minWidth: '400px',
    },
    content: {
      width: '50%',
      margin: 'auto',
    },
    button: {
      margin: '5px',
    },
  }

  return (
    <div style={style.card}>
      <Card>
        <CardHeader
          avatar={props.icon}
          subheader={props.subtitle}
          title={props.title}
        />
        <CardContent>
          <div style={style.content}>
            <Button
              size='large' 
              variant="contained" 
              color='primary'
              style={style.button}
              onClick={startTest}
              disabled={disableButton}
            >
              Test 
            </Button>
            <LinearProgress variant="determinate" value={percentage} />
            <div>{`${remaining} Seconds Left`}</div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

CountDownComponent.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([() => null, PropTypes.number]),
}

export default CountDownComponent;
