import React, { Component } from 'react';
import { connect } from 'react-redux';

import request from 'superagent';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/styles';

import ActionCard from 'presentational/ActionCard';
import DetailContainer from 'presentational/DetailContainer';
import LeftBar from 'presentational/LeftBar';
import MasterHistory from 'presentational/MasterHistory';
import MasterView from 'presentational/MasterView';
import StandardDetailsPage from 'presentational/StandardDetailsPage';
import Loading from 'presentational/Loading';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import SaveChangesModal from 'presentational/SaveChangesModal';
import DeleteModal from 'presentational/DeleteModal';

import AutoAttendantHelp from './help';
import AutoAttendantKeyCodes from './keyCodes';
import AutoAttendantNew from './new';
import AutoAttendantSettings from './settings';

import {
    deleteCoreHttp,
    getCoreHttp,
    postCoreHttp,
    putCoreHttp,
} from 'actions/httpRequest';

import { selectAutoAttendant } from 'actions/autoAttendants';

import { successNotification, errorNotification } from 'actions/notifications';

const styles = () => ({
    button: {
        fontSize: '100%',
    },
    menuItem: {
        fontSize: '100%',
    },
});

class AutoAttendant extends Component {

    constructor(props) {
        super(props);
        this.state = {
            autoAttendantFetchError: null,
            clickedInsideElements: false,
            clickedSurroundingElements: false,
            childFormData: {},
            inUse:false,
            inUseArray: [],
            leftBarData: null,
            newChanges: false,
            route: null,
            selectedAutoAttendant: null,
            showingDeleteConfirmModal: false,
            showingCopyAutoAttendantModal: false,
            showingSaveChangesModal: false,
            closeMenu: false,
            newAutoAttendantName: '',
            autoAttendantHistory: null,
        };

        this.saveChange = this.saveChange.bind(this);
        this.discardChange = this.discardChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            inUse: false,
            inUseArray: [],
            route: null,
            selectedAutoAttendant: null,
        });

        this.props.selectAutoAttendant('');
        this.clickedElement();

        if (!this.props.autoAttendants) {

            this.fetchAutoAttendantData();

        } else {

            this.prepareDataForLeftBar(this.props.autoAttendants);
        }

        this.fetchConfigurations();

    }

    /*I had to use componentDidUpdate so I could conditionally update 
    state based on prevState. This is the correct use case and pattern per React for a class based component. 
    The reason I had to store the selectedAutoAttendant.auditHistory in its own piece of state is bc redux 
    sends an undefined value for auditHistory resetting the value and causing it to not update correctly. 
    This is the best solution considering the amount of refactoring the majority of this component and 
    it's state. -CWise */
    componentDidUpdate(_, prevState) {
        const { selectedAutoAttendant, auditHistory } = this.state;
        /* This will set auditHistory state back to null if we switch autoAttendants, otherwise we will 
        briefly see the previous audit history displayed. */
        if (prevState.selectedAutoAttendant && prevState.selectedAutoAttendant.name !== selectedAutoAttendant.name) {
            this.setState({ auditHistory: null });
        }

        /* This will set the auditHistory state to the updated audithistory object. For the correct auto
        attendant. */
        if (selectedAutoAttendant &&
            selectedAutoAttendant.auditHistory &&
            (selectedAutoAttendant.auditHistory !== auditHistory ||
                prevState.selectedAutoAttendant && prevState.selectedAutoAttendant.name !== selectedAutoAttendant.name) 
            ) {
                this.setState({ auditHistory: selectedAutoAttendant.auditHistory })
        };
    }

    componentWillUnmount() {

        this.setState({
            route: null,
            selectedAutoAttendant: null,
        });
        this.props.selectAutoAttendant('');
    }

    componentWillReceiveProps(nextProps) {

        if (this.props.navigation && nextProps.navigation &&
            this.props.navigation.route != nextProps.navigation.route) {

            this.fetchConfigurations();
        }

        // If the new autoAttendants object is different (or if we
        // didn't have one before) lets get the data ready for leftbar
        if (nextProps.autoAttendants && this.props.autoAttendants !== nextProps.autoAttendants) {

            this.prepareDataForLeftBar(nextProps.autoAttendants);
        }

        if (this.props.autoAttendants && nextProps.autoAttendants && this.props.autoAttendants.length !== nextProps.autoAttendants.length) {

            this.props.selectAutoAttendant(this.state.newAutoAttendantName.replace(/[^A-Za-z0-9_]/g, ''));
        }

        if (nextProps.selectedAutoAttendantName && nextProps.autoAttendants) {

            const selectedAutoAttendant = nextProps.autoAttendants.find(
                (obj) => obj && obj.name == nextProps.selectedAutoAttendantName,
            );

            //Lets find all the applications that might be used by this autoattendant
            //push them to an array and set that array to state
            let inUseArray = [];
            if(selectedAutoAttendant && selectedAutoAttendant.usage){
                Object.keys(selectedAutoAttendant.usage).map(key=>{
                    selectedAutoAttendant.usage[key].map(applicationObj =>{
                        Object.keys(applicationObj).map(application =>{
                            if(selectedAutoAttendant.name !== applicationObj[application]){
                                let applicationRoute = `#${key}/${applicationObj[application]}`
                                inUseArray.push(applicationRoute);
                            }
                        })
                    })
                })
            }    

            this.setState({inUseArray});

            // If we have a new auto attendant, handle this here
            if (nextProps.selectedAutoAttendantName == 'New Auto Attendant') {
                this.setState({ selectedAutoAttendant: { name: 'New Auto Attendant' } });
                return;
            }

            if (!selectedAutoAttendant) {
                console.error('No auto attendant found by the name of: ', nextProps.selectedAutoAttendantName);
                return;
            }

            // If the name has changed, lets reset the route here
            if (nextProps.selectedAutoAttendantName !== this.props.selectedAutoAttendantName || nextProps.autoAttendants.length !== this.props.autoAttendants.length) {
                this.setState({
                    selectedAutoAttendant,
                    route: '',
                });
            } else {
                this.setState({ selectedAutoAttendant });
            }
        }
    }

    // fetchAutoAttendantData acquires auto attendant data via redux api
    fetchAutoAttendantData() {

        // Lets set up the request data to retreive auto attendants
        const reqData = {
            reqAction: 'autoattendant',
        };
    
        const storeKey = 'autoAttendants';

        this.props.getCoreHttp(reqData, storeKey);
    }

    fetchConfigurations() {

        // Lets set up the request data to retreive auto attendants
        const reqData = {
            reqAction: 'configurations',
        };

        this.props.getCoreHttp(reqData);        
    }

    // prepareDataForLeftBar maps through all autoattendants received
    // via http and formats data objects to be consumed by the leftBar
    prepareDataForLeftBar(data) {

        // return on no data
        if (!data) {
            return console.log('No auto attendants returned');
        }

        // array we will push our new object into
        const leftBarData = [];
        data.map((item) => {

            // Set the title to friendly name if we have it, otherwise just the name
            const title = item.friendlyname ? item.friendlyname : item.name;
            const subtitle = item.description;
            const id = item.name;

            // push the data into the leftBar array
            return leftBarData.push({ id, title, subtitle });
        });

        this.setState({ leftBarData: null });

        // finally, lets set the leftBarData to state to update leftbar
        return this.setState({ leftBarData });
    }

    leftBarSelect(autoAttendantName) {

        this.setState({ route: null });

        this.props.selectAutoAttendant(autoAttendantName);

        this.getAutoAttendantDetails(autoAttendantName);
        this.getAutoAttendantAuditHistory(autoAttendantName);
        this.handleDeleteConfirmClose(false)
    }

    getAutoAttendantDetails(autoAttendantName) {

        const reqData = {
            reqAction: 'autoattendant',
            reqObject: autoAttendantName,
        };

        const storeKey = 'autoAttendants';

        this.props.getCoreHttp(reqData, storeKey);
    }

    getAutoAttendantAuditHistory(autoAttendantName) {

        const reqData = {
            auditHistory: true,
            reqAction: 'autoattendant',
            reqObject: autoAttendantName,
        };

        const storeKey = 'autoAttendants';

        this.props.getCoreHttp(reqData, storeKey);
    }

    handleAddNewClick() {

        this.props.selectAutoAttendant('New Auto Attendant');
        this.navigate('New');
    }

    handleCancel(){
        this.leftBarSelect(this.state.selectedAutoAttendant.name);
        this.setState({showingSaveChangesModal: false});
        this.setState({newChanges: false})
    }

    handleDeleteConfirmClose(shouldDelete) {

        this.setState({ showingDeleteConfirmModal: false, newAutoAttendantName: '' }, () => {shouldDelete && this.handleDeleteAutoAttendant()});
    }

    async handleCopyConfirmClose(shouldCopy) {
        if (shouldCopy) {
            const targetAutoAttendantObj = this.props.autoAttendants.find(autoAttendantObj => autoAttendantObj && autoAttendantObj.name == this.props.selectedAutoAttendantName);
            const copiedAutoAttendantObj = Object.assign({}, targetAutoAttendantObj);
            copiedAutoAttendantObj.name = this.state.newAutoAttendantName;
            copiedAutoAttendantObj.friendlyname = this.state.newAutoAttendantName;

            const error = await this.createNewAutoAttendant(this.state.newAutoAttendantName, copiedAutoAttendantObj);
            if (error) {
                console.error(error);
                errorNotification({
                    title: 'Failed!',
                    message: 'Unsuccessful copying of AutoAttendant.',
                });
                return;
            }
        }
        this.setState({ showingCopyAutoAttendantModal: false });
    }

    handleTextFieldChange = (e) => {
        if (e.target.name == 'newAutoAttendantName') {
            this.setState({ newAutoAttendantName: e.target.value });
        }
    }

    handleNewAutoAttendant = (name) => {
        this.setState({ newAutoAttendantName: name });
    }

    handleDeleteAutoAttendant() {

        if (!this.state.selectedAutoAttendant) {
            console.log('No auto attendant selected');
            return;
        }

        const { name } = this.state.selectedAutoAttendant;

        const reqData = {
            reqAction: 'autoattendant',
            reqObject: name,
        };

        const storeKey = 'autoAttendants';

        this.props.deleteCoreHttp(reqData, storeKey);

        // Lets set state to clear the route and selected auto attendant
        this.setState({
            route: '',
            selectedAutoAttendant: null,
        });

        // Lets trigger a notification
        successNotification({
            title: 'Success!',
            message: `Deleted ${name}`,
        });
    }

    handleInUse(){
        if(this.state.inUseArray.length > 0){
            this.setState({inUse: true})
        }else{
            this.setState({inUse: false})
        }
    }

    clickedElement = () => {
        var elements = document.querySelectorAll(".header, .leftBarDiv, .detailContainerDiv");
        for (var i = 0; i < elements.length; i++) {
            elements[i].addEventListener("click", () => {
              this.unsavedChanges();
            });
          }
    };

    unsavedChanges() {
        const { clickedInsideElements, clickedSurroundingElements, newChanges } = this.state;
        if (clickedInsideElements && clickedSurroundingElements && newChanges) {
            this.openModal()
        }
    }

    openModal() {
        this.setState({ showingSaveChangesModal: true })
    }

    clickedNode(){
        this.setState({ clickedInsideElements: true })
    }
    
    madeChanges(answer, data) {
        this.setState({
            newChanges: answer,
            childFormData: data,
        });
    }

    goToHelp() {
        const win = window.open('http://help.fluentcloud.com/support/solutions', '_blank');
        win.focus();
    }

    createNewAutoAttendant(newName, copiedAutoAttendantObj) {

        const reqData = {
            reqAction: 'autoattendant',
            reqObject: newName,
            reqBody: copiedAutoAttendantObj ? copiedAutoAttendantObj : { name: newName, friendlyname: newName },
        };

        const storeKey = 'autoAttendants';

        this.props.postCoreHttp(reqData, storeKey);
    }

    async submitSettingsForm(data) {
        if (!data) {
            return
        }

        const reqData = {
            reqAction: 'autoattendant',
            reqObject: data.name,
            reqBody: data,
        };

        const storeKey = 'autoAttendants';

        this.props.putCoreHttp(reqData, storeKey);

        successNotification({ title: 'Auto Attendant Updated!', message: `Successfully updated auto attendant ${data.name}` });

        this.setState({
            clickedInsideElements: false,
            clickedSurroundingElements: false,
            newChanges: false,
        })
    }

    autoAttendantRoutes() {

        return ([
          <ActionCard
            key="Settings"
            title="Settings"
            subtitle="Manage settings for auto attendant"
            action={() => this.navigate('Settings')}
          />,
          <ActionCard
            key="Key codes"
            title="Key Codes"
            subtitle="Manage auto attendant behavior"
            action={() => this.navigate('Key codes')}
          />,
          <ActionCard
            key="History"
            title="History"
            subtitle="See who made changes to this auto attendant"
            action={() => this.navigate('History')}
          />,
        ]);
    }

    navigate(route) {

        this.setState({ route });
    }
   
    openCloseMenu(){
        this.setState({closeMenu: !this.state.closeMenu});
    }

    renderInUse() {
        if(this.state.inUse) {
            const inUseArray = this.state.inUseArray;
            return(
                <div>This Auto Attendant is currently being used by:
                    <ul>
                        {inUseArray.map(element => {
                            var parsedElement = element.substring(element.indexOf("/")+1);
                            var application = element.slice(0, element.indexOf("/"));
                            var uppercaseApplication = application.charAt(1).toUpperCase() + application.slice(2)
                            if(application === "#autoattendant"){
                                return (
                                    <li key={element}>
                                        <a onClick={ () => this.leftBarSelect(parsedElement)} >{uppercaseApplication} - {parsedElement}</a>
                                    </li>
                                );
                            } else if(application === '#featurecodes' || application === "#parkinglots" || application === "#callgroups" || application === "#voicemailboxes") {
                                return (
                                    <li key={element}>
                                        <a href={application}>{uppercaseApplication} - {parsedElement}</a>
                                    </li>
                                );
                            } else {
                                return (
                                    <li key={element}>
                                        <a href={element}>{uppercaseApplication} - {parsedElement}</a>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </div>
            );
        } else {
            return;
        }
    }

    renderContent() {

        const {
            autoAttendants,
            selectedAutoAttendantName,
        } = this.props;

        const { 
            route,
            auditHistory
        } = this.state;

        if (!selectedAutoAttendantName || !autoAttendants) {
            return <AutoAttendantHelp />;
        }

        const selectedAutoAttendant = autoAttendants.find((obj) => obj && selectedAutoAttendantName == obj.name);

        if(route !== "New"){
            if(selectedAutoAttendant && !selectedAutoAttendant.index){
                return (
                    <StandardSettingsPage>
                        <Loading />
                    </StandardSettingsPage>
                );
            }
        }

        switch (route) {

        case 'New':
            return (
              <AutoAttendantNew
                submitForm={(data) => this.createNewAutoAttendant(data)}
                selectAutoAttendant={(name) => this.props.selectAutoAttendant(name)}
                handleNewAutoAttendant={this.handleNewAutoAttendant}
                autoAttendantNames={this.props.autoAttendants}
              />
            );

        case 'Settings':
            return (
              <AutoAttendantSettings
                submitForm={(data) => this.submitSettingsForm(data)}
                data={selectedAutoAttendant}
                config={this.props.metaData.config}
                cancel={() => this.handleCancel()}
                clickedNode={() => this.clickedNode()}
                madeChanges={(answer, data) => this.madeChanges(answer, data)}
              />
            );

        case 'Key codes':
            return (
              <AutoAttendantKeyCodes
                submitForm={(data) => this.submitSettingsForm(data)}
                data={selectedAutoAttendant}
              />
            );

        case 'History':
            if (!Array.isArray(auditHistory) && auditHistory) {
                let historyArray = [];

                Object.values(auditHistory).map((item) => {
                    item.user && historyArray.push({_id: item._id, user: item.user, timestamp: item.timestamp })
                })

                return <MasterHistory data={historyArray} />;
            }
            return <MasterHistory data={auditHistory} />;

        default:
            return <StandardDetailsPage cards={this.autoAttendantRoutes()} />;
        }
    }

    saveChange() {
        this.setState({
            showingSaveChangesModal: false,
            newChanges: false,
            clickedInsideElements: false,
            clickedSurroundingElements: false,
        });
        this.submitSettingsForm(this.state.childFormData);
        this.handleCancel();
    }

    discardChange() {
        this.setState({
            showingSaveChangesModal: false,
            newChanges: false,
            clickedInsideElements: false,
            clickedSurroundingElements: false,
        }, () => this.handleCancel());
    }

    render() {
        const inUse = this.state.inUse;
        const { classes } = this.props;
        const {
            autoAttendants,
            pending,
            selectedAutoAttendantName,
        } = this.props;
        
        const { leftBarData, route, selectedAutoAttendant } = this.state;

        let title = '';

        if (autoAttendants && autoAttendants) {

            if (selectedAutoAttendant && selectedAutoAttendant.friendlyname) {
                title = selectedAutoAttendant.friendlyname;
            } else if (selectedAutoAttendant && selectedAutoAttendant.name) {
                title = selectedAutoAttendant.name;
            }
        }

        const contextMenuOptions = [
          <MenuItem
            classes={{ root: classes.menuItem }}
            disabled={!selectedAutoAttendant}
            onClick={() =>{ 
                this.setState({ showingDeleteConfirmModal: true })
                this.handleInUse();
                this.openCloseMenu();
            }}
            key={0}
          >
          Delete
          </MenuItem>,
          <MenuItem
            onClick={() => {this.setState({ showingCopyAutoAttendantModal: true }); this.openCloseMenu();}}
            classes={{ root: classes.menuItem }}
            disabled={!selectedAutoAttendant}
            key={1}
            >
          Copy
          </MenuItem>,
          <MenuItem
            onClick={() => {this.goToHelp(); this.openCloseMenu();}}
            classes={{ root: classes.menuItem }}
            key={2}
          >
          Help
          </MenuItem>,
        ];

        return (
          <MasterView>
            <DetailContainer
              leftBar={
                <LeftBar
                  addNew={() => this.handleAddNewClick()}
                  data={leftBarData}
                  pending={pending}
                  selected={selectedAutoAttendantName}
                  select={(id) => this.leftBarSelect(id)}
                  title="Auto Attendants"
                />
              }
              title={title}
              route={route != 'New' ? route : null}
              return={() => this.setState({ route: null })}
              contextMenuOptions={contextMenuOptions}
              closeMenu={this.state.closeMenu}
            >
              {this.renderContent()}
            </DetailContainer>
            <DeleteModal
                open={this.state.showingDeleteConfirmModal}
                title='Are you sure?'
                content={this.renderInUse()}
                delete={() => this.handleDeleteConfirmClose(true)}
                cancel={() => this.handleDeleteConfirmClose(false)}
            />
            <Dialog
                open={this.state.showingCopyAutoAttendantModal}
                onEnter={() => this.setState({ newAutoAttendantName: '' })}
                onClose={() => this.handleCopyConfirmClose(false)}
                fullWidth={true}
            >
                <DialogTitle>AutoAttendant Copy</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To make a copy of this autoattendant, please enter a unique name here.
                    </DialogContentText>
                    <TextField 
                        autoFocus
                        fullWidth={true}
                        margin='dense'
                        name='newAutoAttendantName'
                        label='New AutoAttendant Name'
                        type='text'
                        value={this.state.newAutoAttendantName}
                        onChange={this.handleTextFieldChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        classes={{ root: classes.button }}
                        color='primary'
                        onClick={() => this.handleCopyConfirmClose(false)}
                    >Cancel</Button>
                    <Button
                        disabled={!this.state.newAutoAttendantName || this.props.autoAttendants.some(autoAttendant => autoAttendant.friendlyname == this.state.newAutoAttendantName || autoAttendant.name == this.state.newAutoAttendantName)}
                        classes={{ root: classes.button }}
                        color='primary'
                        onClick={() => this.handleCopyConfirmClose(true)}
                    >Copy</Button>
                </DialogActions>
            </Dialog>
            <SaveChangesModal
                saveChange={() => this.saveChange()}
                discardChange={() => this.discardChange()}
                cancelModal={() =>this.discardChange()}
                show={this.state.showingSaveChangesModal}
            />
          </MasterView>
        );
    }
}

const bindActions = (dispatch) => ({
    deleteCoreHttp: (reqData, storeKey) => dispatch(deleteCoreHttp(reqData, storeKey)),
    getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
    postCoreHttp: (reqData, storeKey) => dispatch(postCoreHttp(reqData, storeKey)),
    putCoreHttp: (reqData, storeKey) => dispatch(putCoreHttp(reqData, storeKey)),
    selectAutoAttendant: (autoAttendant) => dispatch(selectAutoAttendant(autoAttendant)),
    
    
});

const mapStateToProps = (state) => ({
    autoAttendants: state.autoAttendants.data,
    configurations: state.configurations.data,
    metaData: state.autoAttendants.metaData,
    navigation: state.navigation,
    pending: state.autoAttendants.pending,
    selectedAutoAttendantName: state.autoAttendants.selected,
});

export default connect(mapStateToProps, bindActions)(withStyles(styles)(AutoAttendant));
