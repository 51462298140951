import request from 'superagent';

// STUPID SIMPLE HTTP REQUEST BUILDER FOR WHEN REDUX ISN'T NECESSARY
const buildURL = (
    nameSpace,
    route,
    reqObject,
    reqObject2,
    reqQuery,
    auditHistory
) => {

     //Change tenant and extension here if working in localdev 
    const tenant = app.token.get('tenant');
    const extension = app.token.get('extension');

    let url = '/api/v1';
    if (window.BASE_URL) {
        url = `${window.BASE_URL}/api/v1`;
    }

     //Uncomment if working in localdev 
    // if (window.origin.includes('localhost')){
    //     url = 'https://fluentapis.com/v1';
    // }

    switch (nameSpace) {
        case 'userportal':
            return `${url}/${nameSpace}/${tenant}/${extension}${
                route ? `/${route}` : ''
            }${reqObject ? `/${reqObject}` : ''}${
                reqObject2 ? `/${reqObject2}` : ''
            }?${reqQuery ? `${reqQuery}` : ''}${
                auditHistory ? '&audithistory=true' : ''
            }`;
        case 'core':
        case 'master':
            return `${url}/${nameSpace}/${tenant}${route ? `/${route}` : ''}${
                reqObject ? `/${reqObject}` : ''
            }${reqObject2 ? `/${reqObject2}` : ''}?${
                reqQuery ? `${reqQuery}` : ''
            }&${auditHistory ? '&audithistory=true' : ''}`;
    }
};

export const createJWT = ()=> {
    const token = app.token.get('token');
    return token && token.length > 40 ? `bearer ${token}` : `session ${token}`;
}

export const deleteHTTP = async (nameSpace, route, reqObject) => {
    const url = buildURL(nameSpace, route, reqObject);
    const JWT = createJWT();
    let resp = {
        error: null,
        data: null,
    };
    await request
        .delete(url)
        .set('Authorization', JWT)
        .use(async (req) => {
            var tokenUpdated = await app.shouldUpdateToken();
            if(tokenUpdated){
                console.log("[INFO]simplehttp.request] token has been updated for: DELETE_HTTP")
                req.set('Authorization', `bearer ${app.token.get('token')}`);
            }
          })
        .withCredentials()
        .then(res => {
            resp.data = res.body;
        })
        .catch(err => {
            resp.error = err.response.body.error;
        });
    return resp;
};

export const getHTTP = async (nameSpace, route, reqObject) => {
    const url = buildURL(nameSpace, route, reqObject);
    const JWT = createJWT();
    let resp = {
        error: null,
        data: null,
    };
    await request
        .get(url)
        .set('Authorization', JWT)
        .use(async (req) => {
            var tokenUpdated = await app.shouldUpdateToken();
            if(tokenUpdated){
                console.log("[INFO]simplehttp.request] token has been updated for: getHTTP")
                req.set('Authorization', `bearer ${app.token.get('token')}`);
            }
          })
        .withCredentials()
        .then(res => {
            resp.data = res.body;
        })
        .catch(err => {
            resp.error = err.response.body.error;
        });
    return resp;
};

export const putHTTP = async (nameSpace, route, data, reqObject) => {
    const url = buildURL(nameSpace, route, reqObject);
    const JWT = createJWT();
    let resp = {
        error: null,
        data: null,
    };
    await request
        .put(url)
        .set('Authorization', JWT)
        .use(async (req) => {
            var tokenUpdated = await app.shouldUpdateToken();
            if(tokenUpdated){
                console.log("[INFO]simplehttp.request] token has been updated for: putHTTP")
                req.set('Authorization', `bearer ${app.token.get('token')}`);
            }
        })
        .withCredentials()
        .send(data)
        .then(res => {
            resp.data = res;
        })
        .catch(err => {
            resp.error = err.response.body.error;
        });
    return resp;
};

export const postHTTP = async (nameSpace, route, data, reqObject) => {
    const url = buildURL(nameSpace, route, reqObject);
    const JWT = createJWT();
    let resp = {
        error: null,
        data: null,
    };
    await request
        .post(url)
        .set('Authorization', JWT)
        .withCredentials()
        .use(async (req) => {
            var tokenUpdated = await app.shouldUpdateToken();
            if(tokenUpdated){
                console.log("[INFO]simplehttp.request] token has been updated for: postHTTP")
                req.set('Authorization', `bearer ${app.token.get('token')}`);
            }
          })
        .send(data)
        .then(res => {
            resp.data = res;
        })
        .catch(err => {
            resp.error = err.response.body.error;
        });
    return resp;
};
