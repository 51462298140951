import React, { Component } from 'react';
import { connect } from 'react-redux';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import LinearProgress from '@material-ui/core/LinearProgress';

import Loading from 'presentational/Loading';
import Search from 'presentational/Search';
import TimeIntervalSelect from 'presentational/reports/TimeIntervalSelect';
import FilterChip from 'presentational/FilterChip';
import SubmitButton from 'presentational/formControls/SubmitButton';
import { DateTimePicker } from "@material-ui/pickers";
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FilterIcon from '@material-ui/icons/FilterList';


class ReportContainerToolbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mediaQuery: false,
            applyButtonDisabled: false,
            resetButtonDisabled: false,
            filtersOpen: false,
            resetFilters: false,
            minDuration: '',
            maxDuration: '',
            key: 0,
        };
    }

    componentDidMount() {

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
    }

    async setMinDuration(e, val) {

        await this.setState({ minDuration: e.target.value });

        const duration = {
            min: this.state.minDuration,
            max: this.state.maxDuration
        }

        this.props.setDuration(duration);

    }

    async setMaxDuration(e, val) {
        await this.setState({ maxDuration: e.target.value });

        const duration = {
            min: this.state.minDuration,
            max: this.state.maxDuration
        }

        this.props.setDuration(duration);

    }

    async handleApplyFilter() {

        this.setState({ applyButtonDisabled: true, filtersOpen: false });
        setTimeout(() => this.setState({ applyButtonDisabled: false }), 2000);

        this.props.filterData();
    }

    handleResetFilters() {
        this.setState({ resetFilters: true });
        // Update the key on the textField div to trigger a reload and set value to ''
        this.setState({
            key: +1,
            resetButtonDisabled: true,
            minDuration: '',
            maxDuration: '',
            resetFilters: true,
        });
        setTimeout(() => this.setState({ resetButtonDisabled: false, resetFilters: false }), 2000);

        this.props.resetFilters();
    }

    handleExtensionChangeFilter(extension, extensions){
      //Let's check whether the user has selected 'All' or has manually selected all extensions
      if (extension.length == extensions.length && !extension.includes("All") || extension.includes("All")){
        this.props.onChangeExtensionsFilter(extensions, true);
      } else{
        this.props.onChangeExtensionsFilter(extension);
      }
    }

    renderToolbar() {

        const minWidth = '800px';

        const style = {
            collapsedToolbar: {
                width: '100%',
                minHeight: '70px',
                alignItems: 'center',
            },
            smallCollapsedToolbar: {
                width: '100%',
                minHeight: '150px',
                alignItems: 'center',
            },
            expandedToolbar: {
                color: 'red',
                width: '100%',
                minHeight: '405px',
                height: 'flex',
                alignItems: 'center',
            },
            showFiltersToggle: {
                color: 'rgba(115, 115, 115, .8)',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
            },
            hideFiltersToggle: {
                color: 'rgba(115, 115, 115, .8)',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
            },
            toolbarFields: {
                marginLeft: 'auto',
                marginRight: 'auto',
            },
            filterButton: {
                marginBottom: '15px',
            },
            timeIntervalsMobile: {
                marginLeft: '-25px !important',
            },
            applyButton: {
                marginRight: 10,
                minWidth: 140,
            },
            applyButtonWrapper: {
                display: 'flex',
                // width: '100%',
                justifyContent: 'center',
                paddingBottom: 12,
                marginTop: 15,
            },
            collapsedToolbar: {
                // width: '100%',
                minHeight: 135,
                alignItems: 'center',
                justifyContent: 'space-evenly',
                display: 'flex',
                flexDirection: minWidth ? 'row' : 'column',
            },
            duration: {
                marginLeft: 10,
                width: 160,
                fontSize: 12
            },
            loading: {
                height: 4,
            },
            timePicker: {
              margin: '5px'
            },
            resetButton: {
                marginLeft: 10,
                minWidth: 140,
            },
            timeIntervalsMobile: {
                marginLeft: '-25px !important',
            },
            showFilters: {
             marginLeft: 30,
             marginTop: 20
            },
            override:{
              selectFieldWrapper:{
                paddingLeft:10,
                paddingRight:10
              },
              searchWrapper:{
                marginTop:0,
                marginBottom:0
              },
              textField:{
                width:140,
                fontSize:12
              },
              selectField:{
                width:140,
                fontSize:12
              },
              chipInput:{
                width:140,
                fontSize:12
              },
              textArea:{
                width:140,
                fontSize:12
              },
              input:{
                width:140,
                fontSize:12
              },
            }
        };

        if (!this.props.configurations && !this.props.configurations.users) {

            return <Loading />;
        }

        const permittedCDRRoles = ["2", "3","6",'7','8','15'];

        //first we get permissions from the props.app.token.permissions
        const cdrPermissions = this.props.token.permissions.filter( (permission)=>{

            return permission.cdr_object !== null &&
                permittedCDRRoles.includes(permission.cdr_object_role) &&
                permission.cdr_object_type == "tag";
        })

        //get tags from props.http.configurations.tags
        let permittedTags = [];
        if(this.props.allTags){
            permittedTags = this.props.allTags.filter((tag) => {

                return tag.name !== null &&
                    cdrPermissions.map( (perm) => {return perm.cdr_object} ).includes(tag.name);
            })
        }

        //get permitted extension from permitted tags
        const permittedExtensions = permittedTags.map((tag)=>{
            return tag.items
        })
        .reduce( (accumulator, extensionsArray) => { return accumulator.concat(extensionsArray)  }, [] )
        .filter( (extension) => {return extension.type == "extension"})
        .map( (extension) => {return extension.item});

        let extensions = [];
        let extensionsForList = [];
        if (this.props.token.users){
            extensions = this.props.token.users.map((extension) => {
                const value = extension.extension;
                const label = `${extension.extension}-${extension.firstname} ${extension.lastname}`;

                return { label, value };
            })
            .filter( (extension) =>{
                return permittedExtensions.includes(extension.value);
            });

            extensionsForList = JSON.parse(JSON.stringify(extensions));

            if(this.props.filterExtensions && this.props.filterExtensions.length){
              extensionsForList = extensions.filter(x => !this.props.filterExtensions.includes(x.label));
            }

            if(!this.props.allExtensionFilter){
              extensionsForList.unshift({ value: extensions, label: 'All' });
            } else {
              extensionsForList = [];
            }
          }

        const phoneNumbers = [];

        this.props.configurations.phonenumbers.map((phoneNumber) => {

            const value = phoneNumber.phonenumber;
            const label = phoneNumber.phonenumber;

            return phoneNumbers.push({ label, value });
        });

        const directions = [
            { value: 'Inbound', label: 'Inbound' },
            { value: 'Outbound', label: 'Outbound' },
        ];

        const tags = [];

        store.getState().token.permissions.map((tag) => {
            if(tag['cdr_object_type'] == "tag" && tag['cdr_object']){
                const value = tag.cdr_object
                const label = tag.cdr_object
                return tags.push({ label, value });
            }
        });


        const timeIntervals = [
            { value: 'today', label: 'Today' },
            { value: 'yesterday', label: 'Yesterday' },
            { value: 'thisfullweek', label: 'This Week' },
            { value: 'thismonth', label: 'This Month' },
            { value: 'lastmonth', label: 'Last Month' },
            { value: 'custom', label: 'Custom' },
        ];


        let customStartDate = moment().startOf('day').format('MM DD YYYY hh:mm a');

        let customEndDate = moment().endOf('day').format('MM DD YYYY hh:mm a');

        if (this.props.startDate && this.props.startDate.length > 5) {
            customStartDate = moment(this.props.startDate).format('MM DD YYYY hh:mm a');
        }

        if (this.props.endDate && this.props.endDate.length > 5) {
            customEndDate = moment(this.props.endDate).format('MM DD YYYY hh:mm a');
        }

        const callTypes = [
            { value: 'conference', label: 'Conference' },
            { value: 'external', label: 'External' },
            { value: 'fax', label: 'Fax' },
            { value: 'feature', label: 'Feature' },
            { value: 'internal', label: 'Internal' },
            { value: 'international', label: 'International' },
            { value: 'queue', label: 'Queue' },
        ];

        const dispositions = [
            { value: 'ANSWERED', label: 'Answer' },
            { value: 'NO ANSWER', label: 'No Answer' },
        ];

        const {
            filterCallTypes,
            filterDirections,
            filterExtensions,
            filterPhoneNumbers,
            filterDispositions,
            filterMinDuration,
            filterMaxDuration,
            filterTags,
            timeinterval,
            searchTerms,
        } = this.props;

        let extFilter = filterExtensions;
        if (this.props.allExtensionFilter){
          extFilter = ["All"];
        }

        if (this.state.filtersOpen) {

            return (
              <div style={style.expandedToolbar}>
                <Toolbar style={style.expandedToolbar}>
                  <div style={style.toolbarFields}>
                    <FilterChip
                      filtername='Call Direction'
                      defaultValue={filterDirections}
                      label={directions.label}
                      value={directions.value}
                      resetFilters={this.state.resetFilters}
                      filterData={directions}
                      values={filterDirections || []}
                      handleChange={(direction) => this.props.onChangeDirectionsFilter(direction)}
                    />
                    <FilterChip
                      filtername={'Call Types'}
                      defaultValue={filterCallTypes}
                      label={callTypes.label}
                      value={callTypes.value}
                      resetFilters={this.state.resetFilters}
                      filterData={callTypes}
                      values={filterCallTypes || []}
                      handleChange={(callType) => this.props.onChangeCallTypesFilter(callType)}
                    />
                    {
                    extensions.length > 1 ?
                    <FilterChip
                      filtername={'Extensions'}
                      defaultValue={extFilter}
                      label={extensions.label}
                      value={extensions.value}
                      resetFilters={this.state.resetFilters}
                      filterData={extensionsForList}
                      values={extFilter || []}
                      handleChange={(extension) => {this.handleExtensionChangeFilter(extension, extensions)}}
                    />
                    : null
                    }
                    <FilterChip
                      filtername={'Disposition'}
                      defaultValue={filterDispositions}
                      label={dispositions.label}
                      value={dispositions.value}
                      resetFilters={this.state.resetFilters}
                      filterData={dispositions}
                      values={filterDispositions || []}
                      handleChange={(disposition) => this.props.onChangeDispositionsFilter(disposition)}
                    />
                    <div key={this.state.key}>
                        <TextField
                        style={style.duration}
                        defaultValue={this.state.minDuration}
                        placeholder="Min Call Duration (Minutes)"
                        onChange={(e, val) => this.setMinDuration(e, val)}
                        type="number"
                        />
                        <TextField
                        style={style.duration}
                        defaultValue={this.state.maxDuration}
                        placeholder="Max Call Duration (Minutes)"
                        onChange={(e, val) => this.setMaxDuration(e, val)}
                        type="number"
                        />
                    </div>
                    {/* <FilterChip
                    style={style}
                    defaultValue={this.props.filterPhoneNumbers}
                    label={phoneNumbers.label}
                    value={phoneNumbers.value}
                    resetFilters={this.state.resetFilters}
                    filterData={phoneNumbers}
                    filterName={'Phone Numbers'}
                    handleChange={(phoneNumber) => this.props.onChangePhoneNumbersFilter(phoneNumber)}
                    values={this.props.filterPhoneNumbers || []}
                    /> */}
                    <div style={style.applyButtonWrapper}>
                        <SubmitButton
                          label="Apply Filters"
                          onClick={() => this.handleApplyFilter()}
                          disabled={this.state.applyButtonDisabled}
                          style={style.applyButton}
                        />
                        <SubmitButton
                          label="Reset Filters"
                          onClick={() => this.handleResetFilters()}
                          disabled={(!filterCallTypes || !filterCallTypes.length) &&
                              (!filterDirections || !filterDirections.length) &&
                              (!filterExtensions || !filterExtensions.length) &&
                              (!filterPhoneNumbers || !filterPhoneNumbers.length) &&
                              (!filterDispositions || !filterDispositions.length) &&
                              (!filterTags || !filterTags.length) &&
                              (!this.state.minDuration) &&
                              (!this.state.maxDuration) &&
                              (!searchTerms) &&
                              (timeinterval == 'today')}
                          style={style.resetButton}
                        />
                    </div>
                    {/* {this.props.loading ? <LinearProgress mode="indeterminate" style={style.loading} /> : <div style={style.loading} />} */}
                  </div>
                </Toolbar>
                <IconButton
                  style={style.hideFiltersToggle}
                  onClick={() => { this.setState({ filtersOpen: !this.state.filtersOpen }); }}
                >
                  <ExpandLessIcon style={style.hideFiltersToggle} />
                </IconButton>
              </div>
            );
        }

        return (

            <div
              onKeyDown={(e) => e.keyCode == 13 && this.handleApplyFilter()}
              style={style.collapsedToolbar}
            >
              <Search
                label="Phone Number Search"
                style={style}
                onSearch={(term) => this.props.searchInput(term)}
                value={searchTerms}
              />
              <TimeIntervalSelect

                style={style}
                timeIntervals={timeIntervals}
                timeinterval={this.props.timeinterval}
                onChange={(dateTime) => this.props.onChangeDateTimeInterval(dateTime)}
              />
              {this.props.timeinterval == 'custom' &&
                <Typography style={style} component={'span'}>
                    <DateTimePicker
                      ampm={true}
                      autoOk
                      style={style.timePicker}
                      value={customStartDate}
                      format='MMM DD, YYYY hh:mm A'
                      onChange={(startDate) => this.props.changeStartDate(startDate)}
                      label="Start Date"
                      // showCurrentDateByDefault
                    />
                    <DateTimePicker
                      autoOk
                      ampm={true}
                      style={style.timePicker}
                      value={customEndDate}
                      format='MMM DD, YYYY hh:mm A'
                      onChange={(endDate) => this.props.changeEndDate(endDate)}
                      label="End Date"
                      // showCurrentDateByDefault
                    />
                </Typography>
              }
              <Button
                style={{...style.showFilters, fontSize: 12}}
                variant='contained'
                size='large'
                color='primary'
                onClick={() => { this.setState({ filtersOpen: !this.state.filtersOpen }); }}
               >Filters {<FilterIcon />}</Button>
            </div>
        );

    }

    render() {

        return this.renderToolbar();
    }
}

export default ReportContainerToolbar;
