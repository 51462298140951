import React, { Component } from 'react';
import { connect } from 'react-redux';

import ActionCard from 'presentational/ActionCard';
import LeftBar from 'presentational/LeftBar';
import Loading from 'presentational/Loading';
import MasterView from 'presentational/MasterView';
import Report from './report';
import ReportsContainer from './reportsContainer';
import ReportsList from './reportsList';
import ReportsHelp from './help';

import { getUserPortalHttp } from 'actions/httpRequest';

import { selectReportsConfigurations } from 'actions/reportsConfigurations';

class Reports extends Component {

    // constructor will set up the Reports class with initial state
    constructor(props) {
        super(props);
        this.state = {
            reportsListData: null,
        };
    }

    // componentDidMount will set up anything that needs to be set up after the component is on the dom
    componentDidMount() {

        // For now we will set the state of the route and report to null.
        // TODO TODO TODO: Consider defaulting a report here. If we default here also do in componentWillUnmount
        this.setState({
            route: null,
            selectedReport: null,
        });

        // TODO TODO TODO: Same as above, consider having a default report set here
        this.props.selectReportsConfigurations('');

        // At this point we will want to get any of the custom reports and report data for this user
        // TODO TODO TODO: Implement me
        // if (!this.props.reportsConfigurations) {

        //     return this.fetchReportsConfigurations();
        // }

        this.prepareDataForReportsList(this.props.reportsConfigurations);
        this.props.selectReportsConfigurations('CallDetailRecords');

        // TODO TODO TODO: Fetch configurations?
    }

    // componentWillUnmount will set all of the state for this component back to default
    componentWillUnmount() {

        // Lets reset the state here the same as if if were in the componentDidMount
        this.setState({
            route: null,
            selectedReport: null
        });

        this.props.selectReportsConfigurations('');
    }

    componentWillReceiveProps(nextProps) {

        //Check to see if we have data vs not having data at all
        if(nextProps.reportsConfigurations && this.props.reportsConfigurations != nextProps.reportsConfigurations){

            //Our data has been loaded here finally so lets set that up
            this.prepareDataForReportsList(nextProps.reportsConfigurations);
        }
    }

    // fetchReportsConfigurations will get all of the reports this user is allowed to see
    fetchReportsConfigurations() {

        //Set up the request data to get the users reports
        const reqData = {
            reqAction: 'reportsconfigurations',
        };

        const storeKey = 'reportsConfigurations';

        this.props.getUserPortalHttp(reqData, storeKey);
    }

    // prepareDataForReportsList will set the state for the reports list
    prepareDataForReportsList(data) {

        if(!data){

            console.log('No reports configurations returned');
            return;
        }

        const reportsListData = [];

        data.map((item) =>{

            // Set the title to friendly name if we have it, otherwise just the name
            const title = item.friendlyname || item.reportname;
            const subtitle = item.description;
            const id = item.reportname;

            // push the data into the leftBar array
            return reportsListData.push({ id, title, subtitle });
        });

        return this.setState({ reportsListData });
    }

    // reportsConfigurationSelect will change the selected reports configuration
    reportsConfigurationSelect(reportName) {

        this.setState({ route: null });

        this.props.selectReportsConfigurations(reportName);
    }

    renderReport(){

        if (!this.props.reportsConfigurations || !this.props.selectedReportsConfigurationsName) {

            return <ReportsHelp />;
        }

        const reportName = this.props.selectedReportsConfigurationsName;
        const reportConfiguration = this.props.reportsConfigurations.find((report) => report.reportname == reportName);

        if (!reportConfiguration) {

            console.log('Could not find report configuration, something bad is happening here: ', reportName, this.props.reportsConfigurations);
            return null;
        }

        return <Report configuration={reportConfiguration} />;
    }

    render() {

        const { pending } = this.props;

        const { reportsListData } = this.state;

        let reportName;

        const style = {
            reports: {
                height: '100%',
                width:'100%'
            },
            reportsContainer:{
                width:'100%'
            }
        }

        if(this.props.reportsConfigurations){
            const reportConfiguration = this.props.reportsConfigurations.find((report) => report.reportname == this.props.selectedReportsConfigurationsName);
            if (reportConfiguration) {
                reportName = reportConfiguration.friendlyname || reportConfiguration.reportname;
            }
        }

        return (
          <MasterView style={style.reports}>
            <ReportsContainer
                style= {style.reportsContainer}
                title="Call Detail Records"
                leftBar={
                    <ReportsList
                        // addNew={() => this.handleAddNewClick()}
                        data={reportsListData}
                        pending={pending}
                        // selected={}
                        select={(id) => this.reportsConfigurationSelect(id)}
                        title="Reports"
                    />
                }
            >
              {this.renderReport()}
            </ReportsContainer>
          </MasterView>
        );
    }
}

const bindActions = (dispatch) => ({
    // getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
    getUserPortalHttp: (reqData, storeKey) => dispatch(getUserPortalHttp(reqData, storeKey)),
    // httpSuccess: (data, storeKey) => dispatch(httpSuccess(data, storeKey)),
    selectReportsConfigurations: (reportsConfigurations) => dispatch(selectReportsConfigurations(reportsConfigurations)),
});

const mapStateToProps = (state) => ({
    reportsConfigurations: state.reportsConfigurations.data,
    selectedReportsConfigurationsName: state.reportsConfigurations.selected,
    pending: state.reportsConfigurations.pending,
});

export default connect(mapStateToProps, bindActions)(Reports);
