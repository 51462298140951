// Configurations
export const UPDATE_CONFIGURATIONS = 'UPDATE_CONFIGURATIONS';

// HTTP REQ
export const GET_HTTP = 'GET_HTTP';
export const POST_HTTP = 'POST_HTTP';
export const PATCH_HTTP = 'PATCH_HTTP';
export const PUT_HTTP = 'PUT_HTTP';
export const DELETE_HTTP = 'DELETE_HTTP';
export const INVALIDATE_HTTP_DATA = 'INVALIDATE_HTTP_DATA';
export const CHANGE_TENANT = 'CHANGE_TENANT';

export const HTTP_FAILURE = 'FETCH_HTTP_FAILURE';
export const HTTP_SUCCESS = 'FETCH_HTTP_SUCCESS';
export const HTTP_PENDING = 'FETCH_HTTP_PENDING';

// Namespaces
export const CORE_NAMESPACE = 'core';
export const MASTER_NAMESPACE = 'master';
export const REPORTS_NAMESPACE_V2 = 'reports';
export const REPORTS_NAMESPACE = 'legacy_reports';
export const SMS_NAMESPACE = 'sms';
export const USERPORTAL_NAMESPACE = 'userportal';
export const CALL_NAMESPACE = 'call';

// Navigation
export const NAVIGATE = 'NAVIGATE';

// Sms
export const ADD_MESSAGE_TO_BE_DELETED = 'ADD_MESSAGE_TO_BE_DELETED';
export const CANCEL_MESSAGE_TO_BE_DELETED = 'CANCEL_MESSAGE_TO_BE_DELETED';
export const REMOVE_MESSAGE_TO_BE_DELETED = 'REMOVE_MESSAGE_TO_BE_DELETED';
export const CURRENTLY_DELETING_MESSAGES = 'CURRENTLY_DELETING_MESSAGES';
export const MARK_MESSAGES_UNREAD = 'MARK_MESSAGES_UNREAD';
export const NEW_INCOMING_SMS = 'NEW_INCOMING_SMS';
export const NEW_OUTGOING_SMS = 'NEW_OUTGOING_SMS';
export const NEW_OUTGOING_SMS_POSTED = 'NEW_OUTGOING_SMS_POSTED';
export const REMOVE_MESSAGE_FROM_CONVERSATION = 'REMOVE_MESSAGE_FROM_CONVERSATION';
export const SELECT_SMS_CONVERSATION = 'SELECT_SMS_CONVERSATION';
export const TOGGLE_ALL_MESSAGES_TO_BE_DELETED = 'TOGGLE_ALL_MESSAGES_TO_BE_DELETED';
export const UPDATE_NEW_NUMBER_INPUT = 'UPDATE_NEW_NUMBER_INPUT';
export const UPDATE_SELECTED_DID_FOR_SMS_ADMIN = 'UPDATE_SELECTED_DID_FOR_SMS_ADMIN';
export const UPDATE_TEXT_FOR_CONVERSATION_SEARCH = 'UPDATE_TEXT_FOR_CONVERSATION_SEARCH';
export const UPDATE_TEXT_FOR_CONTACT_SEARCH = 'UPDATE_TEXT_FOR_CONTACT_SEARCH';
export const NEXT_PAGE_SUCCESS = 'NEXT_PAGE_SUCCESS';
export const GET_NEXT_PAGE = 'GET_NEXT_PAGE';
export const UPDATE_PAGE_NUMBER = 'UPDATE_PAGE_NUMBER';
export const SMS_SEND_FAILURE = 'SMS_SEND_FAILURE';
export const SMS_SEND_SUCCESS = 'SMS_SEND_SUCCESS';
export const ALLTAGS_HTTP_SUCCESS = 'ALLTAGS_HTTP_SUCCESS';

// Token
export const UPDATE_TOKEN = 'UPDATE_TOKEN';

// Voicemail
export const SHOW_VOICEMAIL_DETAIL = 'SHOW_VOICEMAIL_DETAIL';
export const NEW_VOICEMAIL_BOX = 'NEW_VOICEMAIL_BOX';
export const SELECT_MAILBOX = "SELECT_MAILBOX";
export const TRANSFER_VOICEMAILS = 'TRANSFER_VOICEMAILS';

// Auto attendants
export const SELECT_AUTOATTENDANT = 'SELECT_AUTOATTENDANT';

// Dynamic Agents
export const SELECT_DYNAMIC_AGENT = 'SELECT_DYNAMIC_AGENT';

//Incoming call filters
export const SELECT_CALLFILTER = 'SELECT_CALLFILTER';

// Post Call Surveys
export const SELECT_POST_CALL_SURVEY = 'SELECT_POST_CALL_SURVEY';

// Call Groups
export const SELECT_CALL_GROUP = 'SELECT_CALL_GROUP';

// Reports
export const SELECT_REPORTS_CONFIGURATION = 'SELECT_REPORTS_CONFIGURATION';
export const SEARCH_INPUT = 'SEARCH_INPUT';
export const CHANGE_FILTER_DATETIMES = 'CHANGE_FILTER_DATETIMES';
export const CHANGE_FILTER_DIRECTIONS = 'CHANGE_FILTER_DIRECTIONS';
export const CHANGE_FILTER_CALLTYPES = 'CHANGE_FILTER_CALLTYPES';
export const CHANGE_FILTER_EXTENSIONS = 'CHANGE_FILTER_EXTENSIONS';
export const CHANGE_FILTER_DISPOSITIONS = 'CHANGE_FILTER_DISPOSITIONS';
export const CHANGE_FILTER_PHONENUMBERS = 'CHANGE_FILTER_PHONENUMBERS';
export const CHANGE_FILTER_TAGS = 'CHANGE_FILTER_TAGS';
export const CHANGE_FILTER_AGENTS = 'CHANGE_FILTER_AGENTS';
export const CHANGE_FILTER_DURATIONS = 'CHANGE_FILTER_DURATIONS';
export const FETCH_RECORDINGS = 'FETCH_RECORDINGS';
export const GETTENANTCHANGESREPORT_HTTP_SUCCESS = "GETTENANTCHANGESREPORT_HTTP_SUCCESS";
export const GETTENANTCHANGESREPORT_HTTP_FAILURE = "GETTENANTCHANGESREPORT_HTTP_FAILURE";

// Multicast groups
export const SELECT_MULTICAST_GROUP = 'SELECT_MULTICAST_GROUP';

// Login Management
export const SELECT_LOGIN = 'SELECT_LOGIN';

// Conference Recordings
export const FETCH_CONFERENCE_RECORDINGS = 'FETCH_CONFERENCE_RECORDINGS';
export const FETCH_CONFERENCE_RECORDINGS_SUCCESS = 'FETCH_CONFERENCE_RECORDINGS_SUCCESS';

//Conference data
export const REMOVE_CONFERENCE_ROOM_DETAILS = 'REMOVE_CONFERENCE_ROOM_DETAILS';
export const FILTER_CONFERENCE_DATA = 'FILTER_CONFERENCE_DATA';
export const SELECTED_CONFERENCE_DATA = 'SELECTED_CONFERENCE_DATA';
export const RESET_FLAG = 'RESET_FLAG';
export const RESET_CREATED_ROOM = 'RESET_CREATED_ROOM';

//Notification Workflows
export const SELECTED_NOTIFICATION_WORKFLOW_DATA = 'SELECTED_NOTIFICATION_WORKFLOW_DATA';
export const RESET_CREATED_WORKFLOW = 'RESET_CREATED_WORKFLOW';

//Phone Numbers
export const SELECT_PHONE_NUMBER = 'SELECT_PHONE_NUMBER';
export const PHONENO_LOADING_RESET = 'PHONENO_LOADING_RESET';
export const RELOAD_DETAILS = 'RELOAD_DETAILS';
export const RELOAD_NUMBERS = 'RELOAD_NUMBERS';
export const RESET_LOCATION = 'RESET_LOCATION';

//Locations
export const SELECT_LOCATION = 'SELECT_LOCATION';

export const LEFT_BAR_DATA_PREPARE = 'LEFT_BAR_DATA_PREPARE';
export const SET_TENANT = 'SET_TENANT';

//Call Blocking
export const CALLBLOCKING_HTTP_SUCCESS = 'CALLBLOCKING_HTTP_SUCCESS';
export const EDITCALLBLOCKING_HTTP_SUCCESS = 'EDITCALLBLOCKING_HTTP_SUCCESS';
export const CALLBLOCKING_HTTP_FAILURE = 'CALLBLOCKING_HTTP_FAILURE'
export const EDITCALLBLOCKING_HTTP_FAILURE = 'EDITCALLBLOCKING_HTTP_FAILURE'

//Call Recording
export const CALLRECORDING_HTTP_SUCCESS = 'CALLRECORDING_HTTP_SUCCESS';
export const EDITCALLRECORDING_HTTP_SUCCESS = 'EDITCALLRECORDING_HTTP_SUCCESS';
export const CALLRECORDING_HTTP_FAILURE = 'CALLRECORDING_HTTP_FAILURE'
export const EDITCALLRECORDING_HTTP_FAILURE = 'EDITCALLRECORDING_HTTP_FAILURE'

//Call Forwarding
export const CALLFORWARDING_HTTP_SUCCESS = 'CALLFORWARDING_HTTP_SUCCESS';
export const EDITCALLFORWARDING_HTTP_SUCCESS = 'EDITCALLFORWARDING_HTTP_SUCCESS';
export const CALLFORWARDING_HTTP_FAILURE = 'CALLFORWARDING_HTTP_FAILURE'
export const EDITCALLFORWARDING_HTTP_FAILURE = 'EDITCALLFORWARDING_HTTP_FAILURE';
export const USERPORTALTIMECONDITIONS_HTTP_SUCCESS = 'USERPORTALTIMECONDITIONS_HTTP_SUCCESS';
export const USERPORTALTIMECONDITIONS_HTTP_FAILURE = 'USERPORTALTIMECONDITIONS_HTTP_FAILURE';
export const EDITUSERPORTALTIMECONDITIONS_HTTP_SUCCESS = 'EDITUSERPORTALTIMECONDITIONS_HTTP_SUCCESS';
export const EDITUSERPORTALTIMECONDITIONS_HTTP_FAILURE = 'EDITUSERPORTALTIMECONDITIONS_HTTP_FAILURE';

//Zoho Integration
export const ZOHOINTEGRATION_HTTP_SUCCESS = 'ZOHOINTEGRATION_HTTP_SUCCESS';
export const EDITZOHOINTEGRATION_HTTP_SUCCESS = 'EDITZOHOINTEGRATION_HTTP_SUCCESS';
export const ZOHOINTEGRATION_HTTP_FAILURE = 'ZOHOINTEGRATION_HTTP_FAILURE';
export const EDITZOHOINTEGRATION_HTTP_FAILURE = 'EDITZOHOINTEGRATION_HTTP_FAILURE';
export const ZOHOINTEGRATIONAUTH_HTTP_SUCCESS = 'ZOHOINTEGRATIONAUTH_HTTP_SUCCESS';
export const ZOHOINTEGRATIONAUTH_HTTP_FAILURE = 'ZOHOINTEGRATIONAUTH_HTTP_FAILURE';

// Short Codes
export const CREATESHORTCODES_HTTP_FAILURE = 'CREATESHORTCODES_HTTP_FAILURE';
export const CREATESHORTCODES_HTTP_SUCCESS = 'CREATESHORTCODES_HTTP_SUCCESS';
export const DELETESHORTCODES_HTTP_FAILURE = 'DELETESHORTCODES_HTTP_FAILURE';
export const DELETESHORTCODES_HTTP_SUCCESS = 'DELETESHORTCODES_HTTP_SUCCESS';
export const EDITSHORTCODES_HTTP_FAILURE = 'EDITSHORTCODES_HTTP_FAILURE';
export const EDITSHORTCODES_HTTP_SUCCESS = 'EDITSHORTCODES_HTTP_SUCCESS';
export const SHORTCODES_HTTP_SUCCESS = 'SHORTCODES_HTTP_SUCCESS';
export const SHORTCODES_HTTP_FAILURE = 'SHORTCODES_HTTP_FAILURE';

//User Settings
export const USERSETTINGS_HTTP_SUCCESS = 'USERSETTINGS_HTTP_SUCCESS';
export const USERSETTINGS_HTTP_FAILURE = 'USERSETTINGS_HTTP_FAILURE';
export const EDITUSERSETTINGS_HTTP_SUCCESS = 'EDITUSERSETTINGS_HTTP_SUCCESS';
export const EDITUSERSETTINGS_HTTP_FAILURE = 'EDITUSERSETTINGS_HTTP_FAILURE';

//Call Screening
export const CALLSCREENING_HTTP_SUCCESS = 'CALLSCREENING_HTTP_SUCCESS';
export const EDITCALLSCREENING_HTTP_SUCCESS = 'EDITCALLSCREENING_HTTP_SUCCESS';
export const CALLSCREENING_HTTP_FAILURE = 'CALLSCREENING_HTTP_FAILURE';
export const EDITCALLSCREENING_HTTP_FAILURE = 'EDITCALLSCREENING_HTTP_FAILURE';
export const UPDATE_DO_NOT_SCREEN_NUMBER = 'UPDATE_DO_NOT_SCREEN_NUMBER';

//Phonen numbers manager
export const GLOBALDIDS_HTTP_SUCCESS = 'GLOBALDIDS_HTTP_SUCCESS';
export const GLOBALDIDS_HTTP_FAILURE = 'GLOBALDIDS_HTTP_FAILURE';
export const TENANTS_HTTP_SUCCESS = 'TENANTS_HTTP_SUCCESS';
export const TENANTS_HTTP_FAILURE = 'TENANTS_HTTP_FAILURE';
export const CARRIERS_HTTP_SUCCESS = 'CARRIERS_HTTP_SUCCESS';
export const CARRIERS_HTTP_FAILURE = 'CARRIERS_HTTP_FAILURE';
export const SELECTPHONENUMBER_HTTP_SUCCESS = 'SELECTPHONENUMBER_HTTP_SUCCESS';
export const SELECTPHONENUMBER_HTTP_FAILURE = 'SELECTPHONENUMBER_HTTP_FAILURE';
export const CHANGE_PHONENUMBER_FORM_DATA = 'CHANGE_PHONENUMBER_FORM_DATA';
export const CLEAR_PHONENUMBER_FORM_DATA = 'CLEAR_PHONENUMBER_FORM';
export const CREATEPHONENUMBERS_HTTP_SUCCESS = 'CREATEPHONENUMBERS_HTTP_SUCCESS';
export const CREATEPHONENUMBERS_HTTP_FAILURE = 'CREATEPHONENUMBERS_HTTP_FAILURE';
export const EDITPHONENUMBERSMANAGER_HTTP_SUCCESS = 'EDITPHONENUMBERSMANAGER_HTTP_SUCCESS';
export const EDITPHONENUMBERSMANAGER_HTTP_FAILURE = 'EDITPHONENUMBERSMANAGER_HTTP_FAILURE';
export const UPDATE_EDITPHONENUMBER_FORM_DATA = 'UPDATE_EDITPHONENUMBER_FORM_DATA';
export const REVERT_EDITPHONENUMBER_FORM_DATA = 'REVERT_EDITPHONENUMBER_FORM_DATA';
export const DELETEPHONENUMBERSFROMMANAGER_HTTP_SUCCESS = 'DELETEPHONENUMBERSFROMMANAGER_HTTP_SUCCESS';
export const DELETEPHONENUMBERSFROMMANAGER_HTTP_FAILURE = 'DELETEPHONENUMBERSFROMMANAGER_HTTP_FAILURE';
export const DESELECT_SELECTED_NUMBER = 'DESELECT_SELECTED_NUMBER';

//Voicemail Drop
export const VOICEMAILRECORDING_HTTP_SUCCESS = 'VOICEMAILRECORDING_HTTP_SUCCESS';
export const EDITVOICEMAILRECORDING_HTTP_SUCCESS = 'EDITVOICEMAILRECORDING_HTTP_SUCCESS';
export const CREATEVOICEMAILRECORDING_HTTP_SUCCESS = 'CREATEVOICEMAILRECORDING_HTTP_SUCCESS';
export const DELETEVOICEMAILRECORDING_HTTP_SUCCESS = 'DELETEVOICEMAILRECORDING_HTTP_SUCCESS';
export const VOICEMAILRECORDING_HTTP_FAILURE = 'VOICEMAILRECORDING_HTTP_FAILURE';
export const EDITVOICEMAILRECORDING_HTTP_FAILURE = 'EDITVOICEMAILRECORDING_HTTP_FAILURE';
export const CREATEVOICEMAILRECORDING_HTTP_FAILURE = 'CREATEVOICEMAILRECORDING_HTTP_FAILURE';
export const DELETEVOICEMAILRECORDING_HTTP_FAILURE = 'DELETEVOICEMAILRECORDING_HTTP_FAILURE';
export const SELECT_VOICEMAIL = 'SELECT_VOICEMAIL';
export const UPDATE_REDUCER_DATA = 'UPDATE_REDUCER_DATA';
export const SET_UPDATE_REQUESTED = 'SET_UPDATE_REQUESTED';
export const RESET_GOTNEWDATA = 'RESET_GOTNEWDATA';

//Create Fax
export const FAXOPTIONS_HTTP_SUCCESS = 'FAXOPTIONS_HTTP_SUCCESS';
export const FAXOPTIONS_HTTP_FAILURE = 'FAXOPTIONS_HTTP_FAILURE';
export const CREATEFAX_HTTP_SUCCESS = 'CREATEFAX_HTTP_SUCCESS';
export const CREATEFAX_HTTP_FAILURE = 'CREATEFAX_HTTP_FAILURE';
export const CHANGE_FAX_FORM_DATA = 'CHANGE_FAX_FORM_DATA';
export const SENDFAX_HTTP_SUCCESS = 'SENDFAX_HTTP_SUCCESS';
export const SENDFAX_HTTP_FAILURE = 'SENDFAX_HTTP_FAILURE';
export const FAXOPTIONS_HTTP_PENDING = 'FAXOPTIONS_HTTP_PENDING';

// Fax Inbox
export const FAX_HTTP_SUCCESS = 'FAX_HTTP_SUCCESS';
export const FAX_HTTP_FAILURE = 'FAX_HTTP_FAILURE';
export const FAX_HTTP_PENDING = 'FAX_HTTP_PENDING';
export const CHANGEFOLDER_HTTP_SUCCESS = 'CHANGEFOLDER_HTTP_SUCCESS';
export const CHANGEFOLDER_HTTP_FAILURE = 'CHANGEFOLDER_HTTP_FAILURE';
export const DELETEFAX_HTTP_FAILURE = 'DELETEFAX_HTTP_FAILURE';
export const EDITAFTERDELETEFAX_HTTP_FAILURE = 'EDITAFTERDELETEFAX_HTTP_FAILURE';
export const EDITAFTERDELETEFAX_HTTP_SUCCESS = 'EDITAFTERDELETEFAX_HTTP_SUCCESS';
