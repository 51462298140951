import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';

const AreaCodeInput = (props) => {

let ourValue = props.value || "";
const classes = makeStyles({ root: props.style });

return (
    <div style={props.style}>
        <TextField
          classes={{ root: classes.root }}
          InputLabelProps={{ shrink: true }}
          inputProps = {{ maxLength: 3 }}
          onChange={(e) => props.onChange(e.target.value)}
          placeholder={props.placeholder}
          value={ourValue}
          type='number'
        />
    </div>
  );
}

AreaCodeInput.defaultProps = {
  placeholder: '',
  value: '',
  onChange: () => console.log('No onChange func provided for textfield'),
}

AreaCodeInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default AreaCodeInput;
