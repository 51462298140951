import { combineReducers } from 'redux';

import {
    CHANGE_TENANT,
    INVALIDATE_HTTP_DATA,
    SELECT_REPORTS_CONFIGURATION,
    SEARCH_INPUT,
    CHANGE_FILTER_DATETIMES,
    CHANGE_FILTER_DIRECTIONS,
    CHANGE_FILTER_CALLTYPES,
    CHANGE_FILTER_EXTENSIONS,
    CHANGE_FILTER_DISPOSITIONS,
    CHANGE_FILTER_PHONENUMBERS,
    CHANGE_FILTER_TAGS,
    CHANGE_FILTER_AGENTS,
    CHANGE_FILTER_DURATIONS,
} from '../actions/typeConstants';

const id = 'name';

const auditHistory = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case 'REPORTSCONFIGURATIONS_HTTP_SUCCESS':

        if (!action.auditHistory) {
            return state;
        }

        // If this is for an object, return
        if (action.reqObject) {
            return state;
        }

        return Object.assign([], state, action.data);
    default:
        return state;
    }
};

const data = (state = [{ reportname: 'CallDetailRecords' }], action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return [{ reportname: 'CallDetailRecords' }];

    case 'REPORTSCONFIGURATIONS_HTTP_SUCCESS':

        if (action.auditHistory) {

            const newState = Object.assign([], state);

            const found = state.findIndex(obj => obj[id] == action.reqObject);
            if (found == -1 || !newState[found]) {
                return state;
            }

            newState[found].auditHistory = action.data;

            return Object.assign([], newState);
        }

        if (action.reqObject) {

            const newState = Object.assign([], state);
            const found = state.findIndex(obj => obj[id] == action.reqObject);

            if (!action.data) {
                delete newState[found];
            } else if (found === -1) {
                newState.push(action.data);
            } else {
                newState[found] = action.data;
            }

            return Object.assign([], newState);
        }

        return Object.assign([], state, action.data.data);
    default:
        return state;
    }
};

const metaData = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case 'REPORTSCONFIGURATIONS_HTTP_SUCCESS':

        if (!action.data || !action.data.metaData || action.reqObect) {
            return state;
        }

        return action.data.metaData;
    default:
        return state;
    }
};

const pending = (state = false, action) => {

    switch (action.type) {

    case 'REPORTSCONFIGURATIONS_HTTP_PENDING':
        return true;

    case 'REPORTSCONFIGURATIONS_HTTP_FAILURE':
    case 'REPORTSCONFIGURATIONS_HTTP_SUCCESS':
        return false;

    default:
        return state;
    }
};

const selected = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case SELECT_REPORTS_CONFIGURATION:
        return action.data;

    default:
        return state;
    }
};

const searchTerms = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case SEARCH_INPUT:
        return action.data;

    default:
        return state;
    }
};


const dateTimes = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case CHANGE_FILTER_DATETIMES:
        return action.data;

    default:
        return state;
    }
};

const directions = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case CHANGE_FILTER_DIRECTIONS:
        return Object.assign([], action.data);

    default:
        return state;
    }
};

const callTypes = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case CHANGE_FILTER_CALLTYPES:
        return Object.assign([], action.data);

    default:
        return state;
    }
};

const extensions = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case CHANGE_FILTER_EXTENSIONS:
        if(action && action.data && action.data[0] && action.data[0].label == "All"){
            return Object.assign([], action.data[0].value);            
        }
        const data = action.data.map(extension => extension.label || extension);
        return Object.assign([], data);

    default:
        return state;
    }
};

const dispositions = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case CHANGE_FILTER_DISPOSITIONS:
        return Object.assign([], action.data);

    default:
        return state;
    }
};

const phoneNumbers = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case CHANGE_FILTER_PHONENUMBERS:
        return Object.assign([], action.data);

    default:
        return state;
    }
};

const tags = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case CHANGE_FILTER_TAGS:
        return Object.assign([], action.data);

    default:
        return state;
    }
};


const agents = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case CHANGE_FILTER_AGENTS:
        return Object.assign([], action.data);

    default:
        return state;
    }
};

const durations = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case CHANGE_FILTER_DURATIONS:
        return Object.assign([], action.data);

    default:
        return state;
    }
};

export const reportsConfigurations = combineReducers({
    auditHistory,
    data,
    metaData,
    pending,
    selected,
    searchTerms,
    dateTimes,
    directions,
    callTypes,
    extensions,
    dispositions,
    phoneNumbers,
    tags,
    agents,
    durations,
});
