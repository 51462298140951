import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

const style = {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    paddingBottom: 160,
    borderRightWidth: 1,
    borderRightColor: '#DFDFDF',
    borderRightStyle: 'solid',
};

const Loading = () => (
  <div style={style}>
    <CircularProgress size={50} thickness={4} />
  </div>
);

export default Loading;
