import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector, shallowEqual } from 'react-redux';

import StandardSettingsPage from '../../presentational/StandardSettingsPage';
import Footer from '../../presentational/Footer';
import FluentReactTable from '../../presentational/reactTable/index';
import PageNotFound from '../../presentational/PageNotFound';

import Checkbox from '@material-ui/core/Checkbox';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';

import PropTypes from 'prop-types';
import { csvHelper } from '../../utils/csvHelper';
import useWindowDimensions from '../../utils/useWindowDimensions.js';
import { postMasterHttp } from 'actions/httpRequest';
import { isSuperDuperAdmin } from './phoneNumberManagerUtils';
import { clearPhonenumberFormData } from 'actions/phoneNumbersManager';
import { errorNotification } from 'actions/notifications';

const UploadPhoneNumbers = props => {
    // state
    const [tableDataArray, setDataArrayState] = useState(null);
    const [hashedTableData, setTableDataState] = useState(null);
    const [mediaQuery, setMediaQueryState] = useState(false);
    const [buttonDisabled, setButtonDisabledState] = useState(true);

    // redux
    const token = useSelector(state => state.token, shallowEqual);
    const carrierIndexes = useSelector(state => state.phoneNumbersManager.carrierIndexes, shallowEqual);

    const { width } = useWindowDimensions();
    const bulkInputRef = useRef();

    useEffect(() => {
        const mq = window.matchMedia('(min-width: 800px)');
        setMediaQueryState(mq);
        const mediaQueryListener = query => setMediaQueryState(query);
        mq.addListener(mediaQueryListener);
        props.requestToMaster('globaldids', 'getCarrierNum');
        return mq.removeListener(mediaQueryListener);
    }, []);

    const submitFullForm = cancel => {
        // let's only upload the data that is checked, and take out un-needed fields
        if (cancel) {
            props.clearPhonenumberFormData();
            app.navigate('dids', { trigger: true });
        } else {
            const filteredData = tableDataArray.reduce((acc, phoneNumber) => {
                const {
                    checked,
                    friendlyname,
                    tenant,
                    billable,
                    enabled,
                    smsenabled,
                    voicecarrier,
                    smscarrier,
                    porting_in_progress,
                    is_domestic,
                    exclude_from_location_matching,
                } = phoneNumber;

                checked &&
                    acc.push({
                        assigned: '0',
                        assignedconference: '',
                        assignedmanaged: '',
                        assignedroute: '',
                        assigneduser: '',
                        billable: billable.toLowerCase() == 'yes' ? '1' : '0',
                        callrecording: '0',
                        date_purchased: '',
                        date_updated: '',
                        emergencyroute: '',
                        enabled: enabled.toLowerCase() == 'yes' ? '1' : '0',
                        external_dispatch: '',
                        exclude_from_location_matching: exclude_from_location_matching.toLowerCase() == 'yes' ? '0' : '1',
                        faxenabled: '0',
                        friendlyname,
                        is_domestic: is_domestic.toLowerCase() == 'yes' ? '1' : '0',
                        location: '',
                        porting_in_progress: porting_in_progress.toLowerCase() == 'yes' ? '1' : '0',
                        smsenabled: smsenabled.toLowerCase() == 'yes' ? '1' : '0',
                        smscarrier,
                        tenant,
                        voicecarrier,
                        who_updated: '',
                    });
                return acc;
            }, []);

            const reqData = {
                reqAction: 'globaldids',
                reqObject: 'bulkupload',
                reqBody: filteredData,
            };

            bulkInputRef.current.value = null;
            setDataArrayState(null);
            setTableDataState(null);
            setButtonDisabledState(true);
            props.postMasterHttp(reqData, 'createphonenumbers');
        }
    };

    const getCarrierIndex = friendlyName => {
        const formattedName = friendlyName.replace(/[^\w\s]/gi, '').toLowerCase();
        let foundCarrier = carrierIndexes.find(carrier => carrier.name.replace(/[^\w\s]/gi, '').toLowerCase() == formattedName);
        return foundCarrier ? foundCarrier.index : foundCarrier;
    }

    const fieldIsValid = field => {
        if (!field || (field.toLowerCase() !== 'yes' && field.toLowerCase() !== 'no')) {
            return false;
        }
        return true;
    }

    const validateValues = (accumulator, data) => {
        let {
                friendlyname,
                friendlySmscarrier,
                friendlyVoicecarrier,
                exclude_from_location_matching,
                billable,
                enabled,
                smsenabled,
                porting_in_progress,
                is_domestic,
            } = data;
   
        const smsCarrierIndex = getCarrierIndex(friendlySmscarrier);
        const voiceCarrierIndex = getCarrierIndex(friendlyVoicecarrier);
        // our CSV helper will have the string of 'undefined' so we need to check against that value
        const undefinedText = 'undefined';
        data.checked = true;
        data.disabled = false;
        data.reason = 'Will be uploaded.';
        data.smscarrier = smsCarrierIndex;
        data.voicecarrier = voiceCarrierIndex;
        /* REGEX to remove any special characters */
        data.friendlyname = friendlyname.replace(/[^0-9]/g, '');
        if (friendlyname.length < 10 || friendlyname.length > 15) {
            data.checked = false;
            data.disabled = true;
            data.reason = 'Phone number must be between 10 - 15 characters.';
        }
        if (!smsCarrierIndex) {
            data.checked = false;
            data.reason = `You may upload, however, the SMS carrier, "${friendlySmscarrier}", was not supplied or entered incorrectly`;
        }
        if (!voiceCarrierIndex) {
            data.checked = false;
            data.reason = `You may upload, however, the voice carrier, "${friendlyVoicecarrier}", was not supplied or entered incorrectly`;
        }
        if (!smsCarrierIndex && !voiceCarrierIndex) {
            data.checked = false;
            data.friendlySmscarrier = '';
            data.friendlyVoicecarrier = '';
            data.reason = `You may upload, however, the carriers were not supplied or entered incorrectly`;
        }
        if (!fieldIsValid(billable)) {
            data.checked = false;
            data.billable = "No"
            data.reason = `You may upload, however, "Toll Free" was not supplied or entered incorrectly`;
        }
        if (!fieldIsValid(enabled)) {
            data.checked = false;
            data.enabled = "No"
            data.reason = `You may upload, however, "Enabled" was not supplied or entered incorrectly`;
        }
        if (!fieldIsValid(smsenabled)) {
            data.checked = false;
            data.smsenabled = "No"
            data.reason = `You may upload, however, SMS "Enabled" was not supplied or entered incorrectly`;
        }
        if (!fieldIsValid(porting_in_progress)) {
            data.checked = false;
            data.porting_in_progress = "No"
            data.reason = `You may upload, however, "Is Porting" was not supplied or entered incorrectly`;
        }
        if (!fieldIsValid(is_domestic)) {
            data.checked = false;
            data.is_domestic = "No"
            data.reason = `You may upload, however, "Is Domestic" was not supplied or entered incorrectly`;
        }
        if (!fieldIsValid(exclude_from_location_matching)) {
            data.checked = false;
            data.exclude_from_location_matching = "No";
            data.reason = `You may upload, however, "LAP Enabled" was not supplied or entered incorrectly`;
        }
        if (
            !friendlyname &&
            data.tenant == undefinedText &&
            friendlySmscarrier == undefinedText &&
            friendlyVoicecarrier == undefinedText &&
            exclude_from_location_matching == undefinedText
        ) {
            // if all of these are not filled out, we can assume that this is an empty line that some linux users may input
            return accumulator;
        }
        accumulator[data.friendlyname] = data;
        return accumulator;
    };

    const formatDataTableRows = data => {
        const formattedData = data.reduce(validateValues, {});
        const dataArray = Object.values(formattedData);
        setTableDataState(formattedData);
        setDataArrayState(dataArray);
        setButtonDisabledState(false);
    };

    const check = e => {
        const idNum = e.target.id;
        const clicked = hashedTableData[idNum];
        clicked.checked = !clicked.checked;
        clicked.checked
            ? (clicked.reason = 'Will be uploaded')
            : (clicked.reason = 'Will not be uploaded');
        setTableDataState({ ...hashedTableData, [idNum]: clicked });
    };

    // the table headers for the uploaded data table
    const headers = [
        { Header: 'New DID #', accessor: 'friendlyname' },
        { Header: 'Reason', accessor: 'reason' },
        { Header: 'Tenant', accessor: 'tenant' },
        { Header: 'Toll Free Number', accessor: 'billable', maxWidth: 65 },
        { Header: 'Enabled', accessor: 'enabled', maxWidth: 65 },
        { Header: 'SMS Enabled', accessor: 'smsenabled', maxWidth: 82 },
        { Header: 'Voice Carrier', accessor: 'friendlyVoicecarrier' },
        { Header: 'SMS Carrier', accessor: 'friendlySmscarrier' },
        { Header: 'Is Porting?', accessor: 'porting_in_progress', maxWidth: 75 },
        { Header: 'Is Domestic', accessor: 'is_domestic', maxWidth: 75 },
        { Header: 'LAP Enabled', accessor: 'exclude_from_location_matching', maxWidth: 65 },
    ];

    const csvKeys = [
        'friendlyname',
        'tenant',
        'billable',
        'enabled',
        'smsenabled',
        'friendlyVoicecarrier',
        'friendlySmscarrier',
        'porting_in_progress',
        'is_domestic',
        'exclude_from_location_matching',
        'disabled',
        'checked',
    ];

    const minWidth = mediaQuery.matches;
    const style = {
        card: {
            width: minWidth ? '85%' : '100%',
            minWidth: '400px',
        },
        cardContent: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            maxWidth: '70%',
            marginLeft: '15%',
        },
        cardHeader: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: '5px',
        },
        imageStyle: {
            height: '48px',
            align: 'left',
        },
        notFound: {
            width: '50%',
            minWidth: '400px',
        },
        wrapper: {
            display: 'flex',
            flex: 1,
            height: '100%',
            background: '#EFEFEF',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '50px',
            overflow: 'auto',
        },
    };
    // Extra check to make sure the user isn't a superduperadmin in case they put "/bulkupload" in the URL
    if (!isSuperDuperAdmin(token)) {
        return <PageNotFound backUrl="dids" />;
    }

    if (width <= 1425) {
        const noDupNotificationId = 4;
        errorNotification({
            title: 'Warning!',
            time: false,
            message: 'Your window may be too small to see some of the Bulk Upload Table. Please maximize your window to see all of the columns.',
            id: noDupNotificationId
        })
    }
    return (
        <div style={style.wrapper}>
            <StandardSettingsPage>
                <div style={style.card}>
                    <Card>
                        <div style={style.cardHeader}>
                            <CardHeader title="Bulk Upload Phone Numbers" />
                        </div>
                        <CardContent style={{ width: '100%' }}>
                            <div style={style.cardContent}>
                                <ContactPhoneIcon />
                                <article>
                                    <strong>Import Phone Numbers from a CSV </strong>
                                    <p>
                                        Please use this Google Sheet as your template:{' '}
                                        <a href="https://docs.google.com/spreadsheets/d/1fX62jeFM5zMHJEZuw2CkvumD9i88ckHyODyBe3TQQjQ/edit?usp=sharing" target="_blank">
                                            Phone Upload Template
                                        </a>
                                    </p>
                                </article>
                                <input
                                    onChange={e =>
                                        csvHelper(
                                            e.target.files[0],
                                            10,
                                            setButtonDisabledState,
                                            csvKeys,
                                            formatDataTableRows,
                                        )
                                    }
                                    type="file"
                                    name="newFormUpload"
                                    id="upload-form"
                                    ref={bulkInputRef}
                                />
                            </div>
                        </CardContent>
                        {tableDataArray ? (
                            <div style={{ background: 'white' }}>
                                <FluentReactTable
                                    tableTypeTitle={
                                        <Checkbox color="primary" checked={true} disabled={true} />
                                    }
                                    data={tableDataArray}
                                    tableType="checkbox"
                                    onChange={check}
                                    id="friendlyname"
                                    disabled="disabled"
                                    checked="checked"
                                    headers={headers}
                                    tableTypeTitleWidth={45}
                                />
                            </div>
                        ) : null}
                    </Card>
                </div>
                <Footer onClick={cancel => submitFullForm(cancel)} disabled={buttonDisabled} />
            </StandardSettingsPage>
        </div>
    );
};

UploadPhoneNumbers.defaultProps = {
    buttonDisabled: false,
    checked: true,
    postData: () => null,
    createStoreKey: '',
    onCancel: () => null,
    postData: () => null,
    setButtonDisabledState: () => null,
    validateValues: () => null,
};

UploadPhoneNumbers.propTypes = {
    buttonDisabled: PropTypes.bool.isRequired,
    createStoreKey: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    postData: PropTypes.func.isRequired,
    setButtonDisabledState: PropTypes.func.isRequired,
    validateValues: PropTypes.func.isRequired,
};

const bindActions = dispatch => ({
    postMasterHttp: (reqData, storeKey) => dispatch(postMasterHttp(reqData, storeKey)),
    clearPhonenumberFormData: () => dispatch(clearPhonenumberFormData()),
});

export default connect(null, bindActions)(UploadPhoneNumbers);
