export const reportsConfig = {

    colHeaders: {
        'extension': 'Extension',
        'calldate': 'Call Date / Time',
        // 'clid': 'Caller ID',
        'calleridname': 'Caller ID Name',
        'calleridnumber': 'Caller ID Number',
        'dialednumber': 'Number Dialed',
        'dialedfrom': 'Dialed From',
        'direction': 'Direction',
        'calltype': 'Call Type',
        'billhour': 'Duration',
        'disposition': 'Disposition',
        'recordingUrl': 'Recording',
    },

    ladderColHeaders: {
        'extension': 'Extension',
        'calldate': 'Call Date / Time',
        // 'clid': 'Caller ID',
        'calleridname': 'Caller ID Name',
        'calleridnumber': 'Caller ID Number',
        'dialednumber': 'Number Dialed',
        'dialedfrom': 'Dialed From',
        'billhour': 'Duration',
        'calltype': 'Call Type',
        'disposition': 'Disposition',
    },

    ladderColWidth: {
        // 'calldate': 155,
        // 'dialednumber': 125,
        // 'dialedfrom': 125,
        // 'direction': 80,
        'billhour': 75,
        // 'calltype': 90,
        // 'disposition': 100,
        'extension': 75,
    },

    colWidth: {
    //     'calldate': 155,
    //     'dialednumber': 130,
    //     'dialedfrom': 130,
    //     'direction': 85,
    //     'billsec': 75,
    //     'extension': 75,
    },

    gridConfig: {
        sortable: true,
        filterable: true,
        resizable: true,
        checkbox: true,
    },

    gridConfigChild: {
        sortable: true,
        filterable: true,
        resizable: true,
        checkbox: true,
    },

};
