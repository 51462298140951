import React, { Component } from 'react';
import ReactTable from 'react-table'
import "react-table/react-table.css";
import { CSVLink } from "react-csv";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/styles';

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import AudioPlayer from 'presentational/formControls/AudioPlayer';
import Loading from 'presentational/Loading';
import { translateQueueEvents } from 'utils/translations';
import { formatDateAPI, formatDateReportsService, secondsToHours } from 'utils/misc';
import { formatNumber } from 'utils/phoneNumbers';


class QueueGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            rowInfo: null,
            downloadPrompt: false,
            dialogKey: 0,
        }
    }

    componentDidMount() {
        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
    }
    // Open subgrid on row click
    handleOpen(rowInfo) {
        this.setState({ rowInfo: rowInfo.original.events })
        this.setState({ open: true });
    };
    // Close subgrid
    handleClose() {
        this.setState({ open: false });
        this.setState({ rowInfo: null })
    };
    // Trigger snackbar on download
    triggerDownloadPrompt(e) {
        e && this.setState({ downloadPrompt: true });
    }
    // Handle snackbar close
    handleRequestClose() {
        this.state.downloadPrompt && this.setState({ downloadPrompt: false });
    }
    // On subgrid page size change, re-render dialog by changing the key prop for proper horizontal grid positioning
    updateDialogPositioning() {
        this.setState({dialogKey: this.state.dialogKey++})
    }
    formatAgent(agent) {
        if (agent && agent.length && agent.includes('@')) {
            return agent.split('@')[0];
        }
        return agent;
    }

    // Format data using filters for exporting csv file
    formatDataForCSV() {
        const { data } = this.props;
        const csvData = []; // all CSV data for export
        
        data.forEach(record => { //loop through all queue entries based on filters
            csvData.push({
                callid: record.callid,
                queuename: record.queuename,
                callerid: record.callerid,
                dialednumber: record.dialednumber,
                callername: record.callername,
                answered: record.answered,
                agent: record.agent,
                holdtime: record.holdtime,
                calltime: record.calltime,
                enteredqueue: record.enteredqueue,
                leftqueue: record.leftqueue,
            });
            record.events.forEach(event => {
                csvData.push({
                    callid: record.callid,
                    time: event.time,
                    event: event.event,
                    destination: event.destination,
                });
            });  
        }); 

        return csvData;
    }

    // Format date ranges for csv download filename
    findDateRange(csvData) {
        const allTimes = []
        csvData.forEach(time => {
            if (time.time){
                allTimes.push(new Date(time.time))
            }
        });
        var maxLongDate=new Date(Math.max.apply(null,allTimes)); //Returns format: Tue May 07 2019 06:39:49 GMT-0600 (Mountain Daylight Time)
        var minLongDate=new Date(Math.min.apply(null,allTimes));
        var minYYYY = minLongDate.getFullYear() // Returns format: 2019
        var maxYYYY = maxLongDate.getFullYear()
        var minMM = minLongDate.getMonth()+1 // Returns format: 1-12
        var maxMM = maxLongDate.getMonth()+1
        var minDD = minLongDate.getDate()
        var maxDD = maxLongDate.getDate()
        if (csvData) {
            if (minMM < 10) {minMM = '0'+minMM} // Returns format: 05 instead of 5 for single digit months
            if (maxMM < 10) {maxMM = '0'+maxMM}
            if (minDD < 10) {minDD = '0'+minDD} // Returns format: 07 instead of 7 for single digit dates
            if (maxDD < 10) {maxDD = '0'+maxDD}
        }
        var dateRange = `${minYYYY}${minMM}${minDD}-${maxYYYY}${maxMM}${maxDD}`;
        var fileExportName = `QueueLogs${dateRange}.csv`

        if (!Number.isNaN(minYYYY)) {
            return fileExportName;
        }
    }

    render() {
        const { data, pending } = this.props;
        let csvData = [];
        let exportFileName = '';
        let recordingName;
        if (typeof data == 'object' && data.length > 0 ) {
            csvData = this.formatDataForCSV();
            exportFileName = this.findDateRange(csvData);
        }

        // Headers used to format column titles in CSV
        const headers = [
            {label: 'Call ID', key: 'callid'},
            {label: 'Queue', key: 'queuename'},
            {label: 'Caller Number', key: 'callerid'},
            {label: 'Dialed Number', key: 'dialednumber'},
            {label: 'Caller Name', key: 'callername'},
            {label: 'Event/ Disposition', key: 'answered'},
            {label: 'Agent', key: 'agent'},
            {label: 'Hold-Duration', key: 'holdtime'},
            {label: 'Call-Duration', key: 'calltime'},
            {label: 'Entered Queue', key: 'enteredqueue'},
            {label: 'Left Queue', key: 'leftqueue'},
            {label: 'Time', key: 'time'},
            {label: 'Event', key: 'event'},
            {label: 'Destination', key: 'destination'},
        ]

        if (!pending && !data) {
            return <Loading />;
        }
        if (pending && data === null) {
            return <Loading />;
        }
        { /* Initialie grid and subgrid columns */ }
        const columns = [{
            Header: 'Queue',
            accessor: 'queuename' ,
            Cell: props => <p style={style.column}>{props.value}</p>
        }, {
            Header: 'Caller Number',
            accessor: 'callerid',
            Cell: props => <p style={style.column}>{formatNumber(props.value)}</p>
        }, {
            Header: 'Dialed Number',
            accessor: 'dialednumber',
            Cell: props => <p style={style.column}>{formatNumber(props.value)}</p>
        }, {
            Header: 'Caller Name',
            accessor: 'callername',
            Cell: props => <p style={style.column}>{formatNumber(props.value)}</p>
        }, {
            Header: 'Event / Disposition',
            accessor: 'answered',
            Cell: props => <p style={style.column}>{translateQueueEvents(props.value)}</p>
        }, {
            Header: 'Agent',
            accessor: 'agent',
            Cell: props => <p style={style.column}>{this.formatAgent(props.value)}</p>
        }, {
            Header: 'Hold Duration (hh:mm:ss)',
            accessor: 'holdtime',
            Cell: props => <p style={style.column}>{props.value ? secondsToHours(props.value) : ''}</p>
        }, {
           Header: 'Call Duration (hh:mm:ss)',
           accessor: 'calltime', 
           Cell: props => <p style={style.column}>{props.value ? secondsToHours(props.value) : ''}</p>
        }, {
            Header: 'Entered Queue',
            accessor: 'enteredqueue',
            minWidth: 150,
            Cell: props => <p style={style.column}>{props.value}</p>
        }, {
            Header: 'Left Queue',
            accessor: 'leftqueue',
            minWidth: 150,
            Cell: props => <p style={style.column}>{props.value}</p>
        }, {
            Header: 'Recording', 
            accessor: 'recording',
            Cell: props => {
                if (props.value) {
                    const recordingName = props.value.split('/').pop();
                    return (
                        <AudioPlayer 
                            style={style.column} 
                            recordingUrl={props.value} 
                            callRecording
                            recordingName = {recordingName}
                            showTime 
                            showDownload
                            triggerDownload={(e) => this.triggerDownloadPrompt(e)}  
                        /> 
                    )
                } else {
                    return(<br />)
                }
            }
        }]
        const subColumns = [{
            Header: 'Queue',
            accessor: 'queuename' ,
            Cell: props => <p style={style.column}>{formatQueueName(props.value)}</p>
        }, {
            Header: 'Time',
            accessor: 'time',
            width: 200,
            Cell: props => <p style={style.column}>{formatDateReportsService(props.value)}</p>
        }, {
            Header: 'Agent',
            accessor: 'agent',
            Cell: props => <p style={style.column}>{props.value !== 'NONE' ? this.formatAgent(props.value) : ''}</p>
        }, {
            Header: 'Hold Duration (hh:mm:ss)',
            accessor: 'holdtime',
            Cell: props => <p style={style.column}>{props.value ? secondsToHours(props.value): ''}</p>
        }, {
            Header: 'Call Duration (hh:mm:ss)',
            accessor: 'calltime',
            Cell: props => <p style={style.column}>{props.value ? secondsToHours(props.value): ''}</p>
        }, {
            Header: 'Event',
            accessor: 'event',
            minWidth: 150,
            Cell: props => <p style={style.column}>{translateQueueEvents(props.value)}</p>
        }, {
            Header: 'Destination',
            accessor: 'destination',
            Cell: props => <p style={style.column}>{formatNumber(props.value)}</p>
        } ]
        const { classes } = this.props;
        return (
            <div style={style.grid}>
                <Snackbar
                    open={this.state.downloadPrompt}
                    message={<span>Request for recording pending.. Please stand by.</span>}
                    autoHideDuration={4000}
                    onClose={() => this.handleRequestClose()}
                />
                <ReactTable
                    data={this.props.data}
                    columns={columns}
                    defaultPageSize={10}
                    defaultSorted={[ {id:'queuename', desc: false} ]}
                    styles={style.column}
                    className="-striped -highlight"
                    pageSizeOptions={[10, 25, 50]}
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: (e, handleOriginal) => {
                                if (rowInfo && column.id !== 'recording') {
                                    this.handleOpen(rowInfo);
                                }
                                return;
                            }   
                        }
                    }}
                />
                {exportFileName && <CSVLink data={csvData} headers={headers} filename={exportFileName}>
                    <Button classes={{ root: classes.downloadButton }}>Export CSV</Button>
                </CSVLink>}
                 <Dialog
                    key={this.state.dialogKey}
                    open={this.state.open}
                    onClose={() => this.handleClose()}
                    fullWidth={true}
                    maxWidth = {'xl'}
                >
                    <DialogContent>
                        <ReactTable
                            style={{"textAlign":"center"}}
                            data={this.state.rowInfo || []}
                            columns={subColumns}
                            className="-striped -highlight"
                            onPageSizeChange={() => this.updateDialogPositioning()}
                            defaultPageSize={10}
                            pageSizeOptions={[10, 25, 50]}
                        />
                    </DialogContent>
                </Dialog>     
            </div>
        );      
    }   
}

const formatQueueName = (queue) => {
    if (queue === "NONE") {
        return ''
    }
    return queue;
}

const style = {
    grid: {
        background: 'white',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        minWidth: '75%'
    },
    column: {
        textAlign: 'center',
        marginTop: '10px',
    },
}

const matStyle = () => ({
    downloadButton: {
        position: 'relative',
        marginTop: '10px',
        marginBottom: '10px',
        paddingLeft: '16px',
        paddingRight: '16px',
        verticalAlign: 'middle',
        letterSpacing: '0px',
        textTransform: 'uppercase',
        fontWeight: '500',
        fontSize: '14px',
    },
});

export default withStyles(matStyle)(QueueGrid);
