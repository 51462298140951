import React from 'react'
import Drawer from '@material-ui/core/Drawer';


export default function Clipdrawer(props) {
  const classes = props.useStyle();
  return (
    <Drawer
      docked='true'
      open={props.open}
      variant='persistent'
      transitionDuration={0}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      {props.children}
    </Drawer>
  );
}
