import React, { Component } from 'react'
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';

import { makeStyles } from '@material-ui/styles';

import Loading from 'presentational/Loading';
import SaveChangesModal from 'presentational/SaveChangesModal';

import { getUserPortalHttp, putUserPortalHttp } from 'actions/httpRequest';

import CallRecordingForm from './callRecordingForm';
import { resetFlag } from 'actions/callRecording';
import { errorNotification, successNotification } from 'actions/notifications';

import CallRecordingButton from './callRecordingButton';

class CallRecording extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      changed: false,
      rollbackData: null,
      showingSaveChangesModal: false,
    }
    this.storedFetchedData = false;
    this.updateSettings = this.updateSettings.bind(this);
    this.changeData = this.changeData.bind(this);
    this.clickedElement = this.clickedElement.bind(this);
    this.unsavedChanges = this.unsavedChanges.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.madeChange = this.madeChange.bind(this);
  }

  componentDidMount() {
    const reqData = {
      reqAction: 'callrecording',
    };
    this.props.getUserPortalHttp(reqData);
  }

  componentDidUpdate() {
    if (this.props.callRecordingData && !this.storedFetchedData) {
      this.storedFetchedData = true;
      const rollbackData = Object.assign({}, this.props.callRecordingData);
      this.setState({ data: Object.assign({}, this.props.callRecordingData), rollbackData });
      this.clickedElement();
      return;
    }

    if (this.props.successMsg) {
      this.props.resetFlag();
      this.setState({ changed: false });
      successNotification({
        title: 'Success!', message: this.props.successMsg
      })
    }
    else if (this.props.errorMsg) {
      if (this.props.errorCode === 404) {
        this.props.resetFlag();
        app.navigate('#userportal', { trigger : true });
      } else {
        this.props.resetFlag();
        errorNotification({
          title: 'Failed!', message: this.props.errorMsg,
        });
      }
    }
  }

  clickedElement() {
    var elements = document.querySelectorAll(".header");
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", () => {
        this.unsavedChanges();
      });
    }
  }

  unsavedChanges() {
    if (this.state.changed) {
      this.setState({
        showingSaveChangesModal: true
      });
    }
  }

  updateSettings(data) {
    const rollbackData = Object.assign({}, data);
    data.id = app.token.attributes.extension;
    data.tenant = app.token.attributes.tenant;
    const reqData = {
      reqAction: 'callrecording',
      reqBody: data,
    };
    const storeKey = 'editcallrecording';
    this.props.putUserPortalHttp(reqData, storeKey);
    this.setState({ rollbackData, changed: false });
  }


  changeData() {
    const { data } = this.state;
    if (data.crd_enabled === '1'){
      data.crd_enabled = '0';
      this.updateSettings(data);
    } else {
      data.crd_enabled = '1';
    }
    this.setState({ data, changed: true });
  }

  onSubmit(cancel) {
    if (cancel) {
      this.setState({
        changed: false,
        showingSaveChangesModal: false,
        data: Object.assign({}, this.state.rollbackData),
       });
    } else {
      this.setState({
        changed: false,
        showingSaveChangesModal: false,
        rollbackData: Object.assign({}, this.state.data),
      });
      this.updateSettings(this.state.data);
    }
  }

  madeChange(data) {
    const tempData = data;
    if (tempData.id) delete tempData.id;
    if (tempData.tenant) delete tempData.tenant;
    this.setState({
      data,
      changed: !isEqual(data, this.state.rollbackData),
    });
  }

  render() {
    if (!this.state.data) {
      return <Loading/>;
    }
    const { data } = this.state;
    const text = `Turn ${data.crd_enabled === '1' ? 'off' : 'on'} Call Recording`;
    
    return (
      <div className='container'>
        <h1>Call Recording Options</h1>
        <h4>Settings for recording calls.</h4>
        <CallRecordingButton text={text} toggleRecording={this.changeData}/>
        {data.crd_enabled === '1' &&
          <CallRecordingForm
            form={data}
            editRequest={this.updateSettings}
            madeChange={this.madeChange}
            changed={this.state.changed}
          />}
        <SaveChangesModal
          show={this.state.showingSaveChangesModal}
          saveChange={() => this.onSubmit(false)}
          onClose={() => this.onSubmit(true)}
          discardChange={() => this.onSubmit(true)}
        />
      </div>
    );
  }
}

const bindActions = (dispatch) => ({
  getUserPortalHttp: (reqData) => dispatch(getUserPortalHttp(reqData)),
  putUserPortalHttp: (reqData, storeKey) => dispatch(putUserPortalHttp(reqData, storeKey)),
  resetFlag: () => dispatch(resetFlag()),
});

const mapStateToProps = (state) => ({
  callRecordingData: state.callRecording.callRecordingData,
  successMsg: state.callRecording.successMsg,
  errorMsg: state.callRecording.errorMsg,
  errorCode: state.callRecording.errorCode,
});


export default connect(mapStateToProps, bindActions)(CallRecording);