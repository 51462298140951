
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import LinkIcon from '@material-ui/icons/Launch';
import TextInput from 'presentational/formControls/TextInput';
import SelectInput from 'presentational/formControls/SelectInput';
import ToggleInput from 'presentational/formControls/ToggleInput';
import SliderInput from 'presentational/formControls/SliderInput';

import TimeIcon from '@material-ui/icons/Timer'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import DeleteIcon from '@material-ui/icons/Delete'

import TimePickerInput from 'presentational/formControls/TimePickerInput'
import DiscreteSlider from 'presentational/formControls/DiscreteSlider';
import RangeSlider from 'presentational/formControls/RangeSlider';
import AudioPlayerInput from 'presentational/formControls/AudioPlayerInput'
import RadioInput from 'presentational/formControls/RadioInput'
import SendReportsList from 'presentational/sendReportsList'

import SubmitButton from 'presentational/formControls/SubmitButton'
import AudioPlayer from 'presentational/formControls/AudioPlayer'
import FileUploadButton from 'presentational/formControls/FileUploadButton'
import CancelButton from 'presentational/formControls/CancelButton'
import ReactMicrophone from 'presentational/ReactMicrophone'
import TextFieldWithButton from 'presentational/formControls/TextFieldWithButton'
import PhoneInput from 'presentational/formControls/PhoneInput'
import PhoneInputForForwarding from 'presentational/formControls/PhoneInputForForwarding'
import PhoneInputForForwardingNoAdd from 'presentational/formControls/PhoneInputForForwardingNoAdd'

import MultiSelectInput from 'presentational/formControls/MultiSelectInput';
import SearchableDropdownInput from 'presentational/formControls/SearchableDropdownInput.js'
import ShowAdvance from 'presentational/ShowAdvance';

import Button from '@material-ui/core/Button'
import TimeCondition from '../containers/callForwarding/timeConditions';

class FormControlCard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      controlValue: props.form[props.field],
      passControlValue: {
        newPassword: '',
        confirmPassword: ''
        // sendEmail: false,
      },
      sendEmail: false,
      twinFormValues: {
        fieldOne: '',
        fieldTwo: ''
      },
      mediaQuery: false,
      tab: 0
    }
  }

  componentDidMount () {
    // Media query for responsive UI
    const mq = window.matchMedia('(min-width: 800px)')
    this.setState({ mediaQuery: mq })
    mq.addListener(mediaQuery => this.setState({ mediaQuery }))

    // Lets set the control value state here
    this.setState({ controlValue: this.props.form[this.props.field] })
    if (this.props.formControl == 'TwoTextInput') {
      let twinFormValues = {...this.state.twinFormValues}
      twinFormValues.fieldOne = this.props.twinForm.firstField
      twinFormValues.fieldTwo = this.props.twinForm.secondField
      this.setState({ twinFormValues })
    }else if(this.props.formControl == "VoicemailSentimentSlider") {
      let twinRangeValues = {...this.state.twinRangeValues}
      twinRangeValues.rangeMax = this.props.twinForm.maxValue;
      twinRangeValues.rangeMin = this.props.twinForm.minValue;
      this.setState({ twinRangeValues });
    }

  }

    componentDidUpdate(props) {
      if ((props.formControl === 'RadioInput' || props.formControl === 'PhoneInput' ) && props.form[props.field] != this.state.controlValue) {
        this.setState({ controlValue: this.props.form[this.props.field]});
      }
    }

  // If the value has changed, let's update it here in state
  componentWillReceiveProps (nextProps) {
    if (
      nextProps.formControl != 'PasswordReset' &&
      nextProps.formControl != 'TwoTextInput' &&
      this.props.formControl != 'VoicemailSentimentSlider' &&
      this.props.formControl != 'PasswordReset' &&
      this.props.formControl != 'TwoTextInput'
    ) {
      const { field, form } = this.props

      if (field && form && form[field] != nextProps.form[nextProps.field]) {
        this.setState({ controlValue: nextProps.form[nextProps.field] })
      }
    } else if (nextProps.formControl == 'TwoTextInput') {
      const { form } = this.props
      let twinFormValues = Object.assign({}, this.state.twinFormValues)

      if (form && form.firstName != nextProps.form.firstName) {
        twinFormValues.fieldOne = nextProps.form.firstName
      }

      if (form && form.lastName != nextProps.form.lastName) {
        twinFormValues.fieldTwo = nextProps.form.lastName
      }
      this.setState({ twinFormValues })
    } else if (nextProps.formControl == 'VoicemailSentimentSlider') {

      const { form } = this.props;
      let twinRangeValues = Object.assign({}, this.state.twinRangeValues);

      if (form && form.max_voicemail_value != nextProps.form.max_voicemail_value) {
          twinRangeValues.rangeMax =  nextProps.form.max_voicemail_value;
      }

      if (form && form.min_voicemail_value != nextProps.form.min_voicemail_value) {
          twinRangeValues.rangeMin =  nextProps.form.min_voicemail_value;
      }
      this.setState({ twinRangeValues });
    } else if (nextProps.formControl == 'PasswordReset') {
      const { form } = this.props
      let passControlValue = Object.assign({}, this.state.passControlValue)
      if (form && form.newPassword != nextProps.form.newPassword) {
        passControlValue.newPassword = nextProps.form.newPassword
      }
      if (form && form.confirmPassword != nextProps.form.confirmPassword) {
        passControlValue.confirmPassword = nextProps.form.confirmPassword
      }
      if (form && form.sendEmail != nextProps.form.sendEmail) {
        passControlValue.sendEmail = nextProps.form.sendEmail
      }
      if (!nextProps.form.newPassword) {
        passControlValue.newPassword = ''
      }
      if (!nextProps.form.confirmPassword) {
        passControlValue.confirmPassword = ''
      }
      if (!nextProps.form.sendEmail) {
        passControlValue.sendEmail = false
      }
      this.setState({ passControlValue })
    }
  }

  changeTab (event, value) {
    this.setState({ tab: value })
  }

  clearInput = () => {
    this.setState({recordingName: ""})
  }

  getFormControl (formControl, classes) {
    switch (formControl) {
      case 'MailboxPassInput':
        return (
          <CardContent>
            <TextInput
              disabled={this.props.disabled}
              type={this.props.type}
              field={this.props.field}
              onChange={val => {
                this.setState({ controlValue: val }, () =>
                  this.props.submitForm(
                    this.props.field,
                    this.state.controlValue
                  )
                )
              }}
              onBlur={val => {
                this.setState({ controlValue: val }, () => {
                  let functionToCall =
                    this.props.onBlur || this.props.submitForm
                  functionToCall(this.props.field, this.state.controlValue)
                })
              }}
              value={this.props.value || this.state.controlValue}
              errorText={this.props.errorText}
              title={this.props.title}
              placeholder={this.props.title}
            />
          </CardContent>
        )
      case 'TextInput':
        return (
          <CardContent>
            <TextInput
              disabled={this.props.disabled}
              type={this.props.type}
              field={this.props.field}
              onChange={val => {
                this.setState({ controlValue: val }, () =>
                  this.props.submitForm(
                    this.props.field,
                    this.state.controlValue
                  )
                )
              }}
              onBlur={val => {
                this.setState({ controlValue: val }, () => {
                  let functionToCall =
                    this.props.onBlur || this.props.submitForm
                  functionToCall(this.props.field, this.state.controlValue)
                })
              }}
              value={this.props.value || this.state.controlValue}
              errorText={this.props.errorText}
              placeholder={this.props.title}
            />
          </CardContent>
        )
      case 'TextFieldWithButton':
        return (
          <CardContent>
            <TextFieldWithButton
              disabled={this.props.disabled}
              type={this.props.type}
              field={this.props.field}
              onChange={(val, data) => {
                this.setState({ controlValue: val }, () =>
                  this.props.submitForm(
                    this.props.field,
                    this.state.controlValue,
                    data
                  )
                )
              }}
              onBlur={val => {
                this.setState({ controlValue: val }, () =>
                  this.props.submitForm(
                    this.props.field,
                    this.state.controlValue
                  )
                )
              }}
              value={this.state.controlValue}
              errorText={this.props.errorText}
              placeholder={this.props.title}
              buttonLabel={this.props.buttonLabel}
              onClick={this.props.onClick}
              getCountryCode={countryCode =>
                this.props.getCountryCode(countryCode)
              }
            />
          </CardContent>
        )
      case 'PhoneInputForForwarding':
        return (
          <CardContent>
            <PhoneInputForForwarding
              disabled={this.props.disabled}
              type={this.props.type}
              field={this.props.field}
              value={this.state.controlValue}
              errorText={this.props.errorText}
              placeholder={this.props.title}
              buttonLabel={this.props.buttonLabel}
              onClick={this.props.onClick}
              usSelected={this.props.usSelected}
            />
          </CardContent>
        )
      case 'PhoneInputForForwardingNoAdd':
        return (
          <PhoneInputForForwardingNoAdd
            onChange={(val, countryData) => {
              this.setState({ controlValue: val }, () =>
                this.props.submitForm(
                  this.props.field,
                  this.state.controlValue,
                  countryData
                )
              )
            }}
            value={this.state.controlValue}
            placeholder={this.props.title}
            onClick={this.props.onClick}
            getCountryCode={countryCode =>
              this.props.getCountryCode(countryCode)
            }
          />
        )
      case 'blocknumberlist':
        return (
          <CardContent>
            <div
              style={{
                paddingLeft: '55px'
              }}
            >
              {this.state.controlValue}
            </div>
          </CardContent>
        )
      case 'AddAnother':
        return (
          <CardContent>
            <TextInput
              disabled={this.props.disabled}
              type={this.props.type}
              field={this.props.field}
              onChange={val => {
                this.setState({ controlValue: val }, () =>
                  this.props.submitForm(
                    this.props.field,
                    this.state.controlValue
                  )
                )
              }}
              onBlur={val => {
                this.setState({ controlValue: val }, () =>
                  this.props.submitForm(
                    this.props.field,
                    this.state.controlValue
                  )
                )
              }}
              value={this.state.controlValue}
              errorText={this.props.errorText}
              placeholder={this.props.title}
            />
            <div className={classes.additionalEmailCard}>
              {app.token.get('role') != 'cloudusers' ? (
                <SubmitButton
                  label='Add Another Email'
                  style={styles.buttons}
                  onClick={() => this.props.addNew()}
                />
              ) : (
                ''
              )}
            </div>
          </CardContent>
        )
      case 'TwoTextInput':
        return (
          <CardContent>
            <TextInput
              disabled={this.props.disabled}
              field={this.props.fieldOne}
              onChange={val => {
                let twinFormValues = Object.assign(
                  {},
                  this.state.twinFormValues
                )
                twinFormValues.fieldOne = val
                this.setState({ twinFormValues })
              }}
              onBlur={val =>
                this.props.submitForm(
                  this.props.fieldOne,
                  this.state.twinFormValues.fieldOne
                )
              }
              value={this.state.twinFormValues.fieldOne}
              errorText={this.props.errorText}
              placeholder={this.props.firstFieldTitle}
            />
            <TextInput
              disabled={this.props.disabled}
              field={this.props.fieldTwo}
              onChange={val => {
                let twinFormValues = Object.assign(
                  {},
                  this.state.twinFormValues
                )
                twinFormValues.fieldTwo = val
                this.setState({ twinFormValues })
              }}
              onBlur={val =>
                this.props.submitForm(
                  this.props.fieldTwo,
                  this.state.twinFormValues.fieldTwo
                )
              }
              value={this.state.twinFormValues.fieldTwo}
              errorText={this.props.errorText}
              placeholder={this.props.secondFieldTitle}
            />
          </CardContent>
        )
      case "VoicemailSentimentSlider":
          const rangeMarks = [
            {
              value: -1,
              label: '-1',
            },
            {
              value: -.5,
              label: '-.5',
            },
            {
              value: 0,
              label: '0',
            },
            {
              value: .5,
              label: '.5',
            },
            {
              value: 1,
              label: '1',
            },
            
          ];

        const magMarks =[
          {
            value: 0,
            label: '0',
          },
          {
            value: 3,
            label: '3',
          },
          {
            value: 6,
            label: '6',
          },
          {
            value: 9,
            label: '9',
          },
          {
            value: 12,
            label: '12',
          },
        ];

        return(
          <CardContent>
          <SearchableDropdownInput
            onChange={(val) => {
              this.props.submitForm(this.props.field, val);
              this.setState({ controlValue: val });
            }}
            options={this.props.options}
          />
          <RangeSlider
            marks = {rangeMarks} 
            onChange={(values) => {
              let twinRangeValues = Object.assign({}, values);
                twinRangeValues.rangeMin =  values[0]
                twinRangeValues.rangeMax = values[1]
                this.setState({ twinRangeValues });
            }}
            onMinRangeSlide={(val)=> this.props.submitForm("min_voicemail_value", val[0])}
            onMaxRangeSlide={(val)=> this.props.submitForm("max_voicemail_value", val[1])}
            placeholder={this.props.placeholder1}
            value={this.state.twinRangeValues}
          />
          <DiscreteSlider 
            marks={magMarks}
            onChange={(val)=>{
              this.props.submitForm("max_sentiment_magnitude", val)
            }}
            placeholder={this.props.placeholder2}
          />
        </CardContent>
        );  
      case 'PasswordReset':
        return (
          <CardContent>
            <TextInput
              disabled={this.props.disabled}
              field={this.props.newPassword}
              onChange={val => {
                let passControlValue = Object.assign(
                  {},
                  this.state.passControlValue
                )
                passControlValue.newPassword = val
                this.setState({ passControlValue })
              }}
              onBlur={val =>
                this.props.submitForm(
                  this.props.newPassword,
                  this.state.passControlValue.newPassword
                )
              }
              value={this.state.passControlValue.newPassword}
              errorText={this.props.errorText}
              placeholder={this.props.newPasswordTitle}
              type='password'
            />
            <TextInput
              disabled={this.props.disabled}
              field={this.props.confirmPassword}
              onChange={val => {
                let passControlValue = Object.assign(
                  {},
                  this.state.passControlValue
                )
                passControlValue.confirmPassword = val
                this.setState({ passControlValue })
              }}
              onBlur={val =>
                this.props.submitForm(
                  this.props.confirmPassword,
                  this.state.passControlValue.confirmPassword
                )
              }
              value={this.state.passControlValue.confirmPassword}
              errorText={this.props.errorText}
              placeholder={this.props.confirmPasswordTitle}
              type='password'
            />
            {!this.props.newUser && (
              <ToggleInput
                field='sendEmail'
                key={this.state.sendEmail}
                onChange={val => {
                  this.setState({ sendEmail: val })
                  this.props.submitForm(this.props.sendEmail, val)
                }}
                value={this.state.sendEmail}
                // errorText={this.props.errorText}
                placeholder='Send Credential Email'
              />
            )}
          </CardContent>
        )
      case 'AudioPlayerInput':
        return (
          <CardContent>
            <AudioPlayerInput
              disabled={this.props.disabled}
              field={this.props.field}
              onChange={val => {
                this.props.submitForm(this.props.field, val)
                this.setState({ controlValue: val })
              }}
              placeholder={this.props.placeholder}
              recordingUrl={this.props.recordingUrl}
              value={this.state.controlValue}
              options={this.props.options}
            />
          </CardContent>
        )

      case 'SearchableSelectInput':
        let { buttonClick, closeModal, field, searchableFormOption, submitForm, options, voicemailID} = this.props;
          return (
          <CardContent >
            <SearchableDropdownInput
              onChange={(val) => {
                submitForm(field, val)
              }}
              buttonClick={(selectedOption)=>buttonClick(selectedOption)}
              closeModal= {()=>closeModal()}
              searchableFormOption = {searchableFormOption}
              options={options}
              voicemailID= {voicemailID}
              value={get(this.props, `form.${this.props.field}`, null)}
            />
          </CardContent>
          );

      case 'SelectInput':
        return (
          <CardContent>
            <SelectInput
              disabled={this.props.disabled}
              field={this.props.field}
              onChange={val => {
                this.props.submitForm(this.props.field, val)
                this.setState({ controlValue: val })
              }}
              value={
                this.props.field === 'failover_value' &&
                this.state.controlValue === ''
                  ? 'No Room'
                  : this.state.controlValue
              }
              errorText={this.props.errorText}
              placeholder={this.props.title}
              options={this.props.options}
            />
            {this.props.linkAddress && this.state.controlValue ? (
              <a
                style={{ margin: '20px' }}
                href={`${this.props.linkAddress}/${this.state.controlValue}`}
                title='Go to route'
              >
                {this.state.controlValue}
                <LinkIcon style={{ height: '17px' }} />
              </a>
            ) : null}
          </CardContent>
        )

      case 'SelectInputMode':
        return (
          <CardContent expandable='true'>
            <SelectInput
              disabled={this.props.disabled}
              field={this.props.field}
              onChange={val => {
                this.props.submitForm(this.props.field, val)
                this.setState({ controlValue: val })
              }}
              value={this.props.values || this.props.unselectedMsg}
              errorText={this.props.errorText}
              placeholder={this.props.title}
              options={this.props.options}
            />
            {this.props.linkAddress && this.props.values ? (
              <a
                style={{ margin: '20px' }}
                href={`${this.props.linkAddress}/${this.props.values}`}
                title='Go to route'
              >
                {this.props.values}
                <LinkIcon style={{ height: '17px' }} />
              </a>
            ) : null}
          </CardContent>
        )

      case 'ToggleInput':
        return (
          <CardContent>
            <ToggleInput
              disabled={this.props.disabled}
              field={this.props.field}
              onChange={val => {
                this.setState({ controlValue: val })
                this.props.submitForm(this.props.field, val)
              }}
              value={this.props.occurrence === "weekly" ? true : this.state.controlValue}
              errorText={this.props.errorText}
              placeholder={this.props.label}
            />
          </CardContent>
        )
      case 'MultipleSelectCheckbox':

              let {data} = this.props;
              
              const permanentNumbers = [];
              data.phoneNumberData.data.forEach(phonenumber => {
                //we don't want to see this number if it's temporary, 
                // OR associated with a different temp number, unless it's the selected temporary number
               if (phonenumber.temporary === "0" && (phonenumber.associated_temporary_number === "" || this.props.form.phonenumber === phonenumber.associated_temporary_number) &&
                (this.props.form.phonenumber !== phonenumber.phonenumber) 
               ){
                  permanentNumbers.push(phonenumber.phonenumber)
                };
              });

              return (
                <MultiSelectInput
                  label={this.props.label}
                  names={permanentNumbers}
                  values={this.props.selected}
                  handleChange={values => this.props.handleChange(values)}
                />
              );

            case 'SliderInput':
                return (
                  <CardContent  >
                    <SliderInput
                      disabled={this.props.disabled}
                      field={this.props.field}
                      onChange={(val) => {this.props.submitForm(this.props.field, val), this.setState({ controlValue: val })}}
                      value={this.state.controlValue}
                      errorText={this.props.errorText}
                      placeholder={this.props.label}
                      min={this.props.min}
                      max={this.props.max}
                    />
                  </CardContent>

                );
            case 'ExistingGreeting':
              return (
                <div className={classes.greetingCard}>
                  <CardContent>
                    <AudioPlayer 
                      style={{width: '50%'}} 
                      recordingUrl={this.props.recordingUrl} 
                      recordingName={this.props.recordingName}
                      showTime 
                      greeting={true}
                      showDownload
                    />
                      <SubmitButton
                        label="remove greeting"
                        style={styles.buttons}
                        disabled={this.state.disabled}
                        onClick={() => this.props.removeGreeting(this.props.title)}
                      />
                  </CardContent>
                </div>
              );
            case 'PlayRecord':
              return (
                <div style={{
                  width: '50%',
                  margin: 'auto',
                }}>
                  <CardContent>
                    <AudioPlayer
                      greeting={true}
                      recordingUrl={this.props.recordingUrl}
                      recordingName={this.props.recordingName}
                      showTime
                      showDownload
                    />
                  </CardContent>
                </div>
              );
            case 'RecordNew':
              return (
                <div className={classes.greetingCard}>
                  <CardContent>
                      <SubmitButton
                        label={this.props.field == 'newrecording' ? this.props.title : `Record New `+ this.props.title +` Greeting`}
                        style={styles.buttons}
                        disabled={this.state.disabled}
                        onClick={() => this.props.recordNewGreeting(this.props.title)}
                      />
                  </CardContent>
                </div>
              );
            case 'NoGreeting':
              const { tab } = this.state;
              return (
                    <CardContent className={classes.tabBarCard}>
                      <div className={classes.tabBar}>
                        <Tabs value={tab} onChange={this.changeTab.bind(this)} classes={{ root: classes.tabsRoot }}>
                          <Tab label="Create via Phone" classes={{ root: classes.tabRoot }} />
                          <Tab label="Record In Browser" classes={{ root: classes.tabRoot }} />
                          <Tab label="Upload Recording" classes={{ root: classes.tabRoot }}  />
                        </Tabs>
                        {tab === 0 &&
                          <Typography component="div">
                            <div className={classes.header}>
                              <h3>Instructions</h3>
                              <ol>
                                <li>Pick up the handset, and dial *97, enter your password, then dial 0 for Mailbox Options.</li>
                                <li>Select which voicemail greeting you would like to record. The available options are Unavailable, Busy, and Name.</li>
                                <li>When you are finished recording, press the pound key (#) to end the recording. 
                                It then gives you the options to press 1 to keep the recording, press 2 to listen to the recording, and press 3 to re-record the message.</li>
                              </ol>
                            </div>
                          </Typography>}
                        {tab === 1 &&
                          <Typography component="div">
                            <div>
                              <ReactMicrophone
                                upload ={(recording)=> this.props.upload(recording, this.props.title)}
                                createRecording ={() => this.props.createRecording()}
                              />
                            </div>
                          </Typography>}
                        {tab === 2 &&
                        <Typography component="div">
                          <h3 style={{ margin: '20px' }}>Upload File</h3>
                          <div className={classes.upload}>
                            <FileUploadButton 
                              onChange={(e) => this.props.upload(e, this.props.title)}
                            />
                            <p style={styles.header}>The file must be smaller than 15 MB</p>
                            <div>
                              <SubmitButton
                                label='Create New'
                                disabled={this.props.disabled}
                                style={styles.buttons}
                                onClick={() => this.props.createRecording()}
                              />
                              <CancelButton
                                label="cancel"
                                onClick={() => this.props.cancel()}
                              />
                            </div>
                          </div>
                        </Typography>}
                      </div>
                    </CardContent>
              );

            case 'newRecording': 
              return (
                    <CardContent className={classes.tabBarCard}>
                      <div className={classes.tabBar}>
                        <Tabs value={this.state.tab} onChange={this.changeTab.bind(this)} classes={{ root: classes.tabsRoot }}>
                          <Tab label="Record In Browser" classes={{ root: classes.tabWidth }} />
                          <Tab label="Upload Recording" classes={{ root: classes.tabWidth }}  />
                        </Tabs>
                        {this.state.tab === 0 &&
                          <Typography component="div">
                            <div>
                              <ReactMicrophone
                                upload ={(recording)=> this.props.upload(recording, this.props.title)}
                                clearInput = {() => this.clearInput()}
                                createRecording ={() => this.props.createRecording()}
                              />
                              <TextInput
                                onChange={(val) => {
                                  this.props.recordingName(val)
                                  this.setState({recordingName: val})
                                }}
                                value = {this.state.recordingName}                    
                                placeholder= "Recording Name"

                    />
                            </div>
                          </Typography>}
                        {this.state.tab === 1 &&
                        <Typography component="div">
                          <h3 style={{ margin: '20px' }}>Upload File</h3>
                          <div className={classes.upload}>
                            <FileUploadButton 
                              onChange={(e) => this.props.upload(e)}
                            />
                            <p style={styles.header}>The file must be smaller than 15 MB</p>
                            <div>
                              <SubmitButton
                                label='Create New'
                                disabled={this.props.disabled}
                                style={styles.buttons}
                                onClick={() => {
                                  this.props.createRecording();
                                }}
                              />
                              <CancelButton
                                label="cancel"
                                onClick={() => this.props.onCancel()}
                              />
                            </div>
                          </div>
                        </Typography>}
                      </div>
                    </CardContent>
              );
            case 'RadioInput':
                return (
                  <CardContent  >
                    <RadioInput
                      field={this.props.field}
                      onChange={(val) => this.setState({ controlValue: val }, () => this.props.submitForm(this.props.field, this.state.controlValue)) }
                      onBlur={(val) => this.props.submitForm(this.props.field, this.state.controlValue)}
                      value={this.state.controlValue}
                      options={this.props.options}
                      values={this.props.values}
                      disabledOptions={this.props.disabledOptions || []}
                    />
                  </CardContent>
                );
            case 'AdditonalEmail':
              return(
                <CardContent classes={{ root: classes.additionalEmailCard }} expandable="true">
                  <div className={classes.additionalEmailCardDiv}>
                    <h5>{`Email: `+ this.props.data.emailaddress}</h5>
                    <h5>{`Attach File: `}{this.props.data.attachfile == 1 ? "Yes" : "No"}</h5>
                    <h5>{`File Format: `+ this.props.data.fileformat}</h5>
                    <h5>{`Time Zone: `+ this.props.data.timezone}</h5>
                    <h5>{`Transcribe File: `}{this.props.data.transcribefile == 1 ? "Yes" : "No"}</h5>
                  </div>
                  <div>
                  {app.token.get("role") !== 'cloudusers' ? (
                      <div>
                    <Button
                      variant="contained"
                      onClick={() => this.props.onEdit(this.props.index)}
                      color="primary"
                      classes={{ containedPrimary: classes.buttons}}
                    >Edit</Button>
                    <Button
                      variant="contained"
                      onClick={() => this.props.onDelete(this.props.index)}
                      color="primary"
                      classes={{ containedPrimary: classes.buttons}}
                    >Delete</Button>
                    </div>) : ""}
                  </div>
              </CardContent>
        )
      case 'RadioInput':
        return (
          <CardContent>
            <RadioInput
              field={this.props.field}
              onChange={val =>
                this.setState({ controlValue: val }, () =>
                  this.props.submitForm(
                    this.props.field,
                    this.state.controlValue
                  )
                )
              }
              onBlur={val =>
                this.props.submitForm(this.props.field, this.state.controlValue)
              }
              value={this.state.controlValue}
              options={this.props.options}
              values={this.props.values}
              disabledOptions={this.props.disabledOptions || []}
              column={this.props.column || false}
            />
          </CardContent>
        )
      case 'AdditonalEmail':
        return (
          <CardContent
            classes={{ root: classes.additionalEmailCard }}
            expandable='true'
          >
            <div className={classes.additionalEmailCardDiv}>
              <h5>{`Email: ` + this.props.data.emailaddress}</h5>
              <h5>
                {`Attach File: `}
                {this.props.data.attachfile == 1 ? 'Yes' : 'No'}
              </h5>
              <h5>{`File Format: ` + this.props.data.fileformat}</h5>
              <h5>{`Time Zone: ` + this.props.data.timezone}</h5>
              <h5>
                {`Transcribe File: `}
                {this.props.data.transcribefile == 1 ? 'Yes' : 'No'}
              </h5>
            </div>
            <div>
              {app.token.get('role') !== 'cloudusers' ? (
                <div>
                  <Button
                    variant='contained'
                    onClick={() => this.props.onEdit(this.props.index)}
                    color='primary'
                    classes={{ containedPrimary: classes.buttons }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant='contained'
                    onClick={() => this.props.onDelete(this.props.index)}
                    color='primary'
                    classes={{ containedPrimary: classes.buttons }}
                  >
                    Delete
                  </Button>
                </div>
              ) : (
                ''
              )}
            </div>
          </CardContent>
        )
      case 'TimeConditions':
        const timeConditions = []
        if(this.props.timeConditions.timeConditions && this.props.timeConditions.timeConditions.length > 0){
          this.props.timeConditions.timeConditions.map((timeCondition, index) => {
           const TC = (<TimeCondition 
            key= {index}
            submitForm = {this.props.submitForm}
            index = {index}
            deleteTC = {this.props.deleteTC}
            timeCondition = {timeCondition}
           />)
                timeConditions.push(TC)

          })
        }else{
             timeConditions.push(<TimeCondition 
              submitForm = {this.props.submitForm}
              index = {0}
              timeCondition = {[]}/>
             )
        }
        return (
          <CardContent>
            {timeConditions}
            <Button
              variant='contained'
              // onClick={() => this.props.onEdit(this.props.index)}
              color='primary'
              classes={{ containedPrimary: classes.buttons }}
              onClick={() => this.props.addAnother()}
            >
              Add Another
            </Button>
          </CardContent>
        )
      case 'EmailList':
        return (
          <CardContent>
            <SendReportsList
              onChange={val =>
                this.setState({ controlValue: val }, () =>
                  this.props.submitForm(
                    this.props.field,
                    this.state.controlValue
                  )
                )
              }
              onBlur={val =>
                this.props.submitForm(this.props.field, this.state.controlValue)
              }
              field={this.props.field}
              value={this.state.controlValue}
            />
          </CardContent>
        )
      case 'meet_url':
        const url = this.state.controlValue
          ? `https://meet.fluentcloud.com/${this.state.controlValue}`
          : `https://meet.fluentcloud.com`
        return (
          <CardContent>
            <div style={styles.container}>
              <a href={url}>{url}</a>
            </div>
          </CardContent>
        )
      case 'PhonenumbersList':
        return (
          <CardContent
            style={{
              height: '100%',
              width: '100%'
            }}
            expandable='true'
          >
            <div style={styles.container}>{this.state.controlValue}</div>
          </CardContent>
        )
      case 'FileInput':
        return (
          <CardContent>
            <div style={{ marginLeft: '50px', marginBottom: '20px' }}>
              {this.props.show ? (
                <input
                  onChange={e => this.props.submitForm(e.target.files[0])}
                  type='file'
                  name='newRecording'
                  id='newFile'
                />
              ) : null}
              {this.props.fileUploading ? (
                <span>{this.props.fileUploading}</span>
              ) : null}
              {this.props.extra}
            </div>
          </CardContent>
        )
      case 'PhoneInput':
        return (
          <PhoneInput
            onChange={(val, countryData) => {
              this.setState({ controlValue: val }, () =>
                this.props.submitForm(
                  this.props.field,
                  this.state.controlValue,
                  countryData
                )
              )
            }}
            value={this.state.controlValue}
            placeholder={this.props.title}
            onClick={this.props.onClick}
            getCountryCode={countryCode =>
              this.props.getCountryCode(countryCode)
            }
          />
        )
        case 'PhoneNumberInput':
          return (
            <CardContent
              style={this.props.expandCard ? {height: '275px'} : null}
            >
              <div style={{ marginLeft: '50px', marginBottom: '20px' }}>
                {this.props.phoneInput}
              </div>
            </CardContent>
          );
        case 'FormUpload':
          return (
            <CardContent
              style={{width: '100%'}}
            >
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <img className='import-logo' height='48' width='48' align='left' title='Google' src='../img/contacts-google.png' alt='google contact image'></img>
                <article>
                  <strong>{this.props.subtitleText} </strong><p>{this.props.acceptedFormat}</p>
                </article>
                <input
                  onChange={this.props.chooseFile}
                  type='file'
                  name='newFormUpload'
                  id='upload-form'
                />
              </div>
                {this.props.uploadedTable}
            </CardContent>
          )
      case 'CustomComponents':
        return (
          <div style={{ marginLeft: '20px', paddingBottom: '30px' }}>
            {this.props.children}
          </div>
        );
      case 'RecordAudio':
        return(
          <CardContent>
            <ReactMicrophone
              upload ={(recording)=> this.props.uploadGreeting(recording, this.props.title)}
              createRecording ={() => this.props.createNewGreeting()}
              audioBitsPerSecond ={this.props.audioBitsPerSecond}
              noSave={this.props.noSave || false}
              showDownloadButton={this.props.showDownloadButton || false}
            />
          </CardContent>
        );
      case 'UploadAudio':
        return(
          <div className={classes.upload}>
            <FileUploadButton 
              onChange={(e) => this.props.uploadGreeting(e, this.props.title)}
            />
          </div>
        );
      case 'ShowAdvance':
        return(
          <CardContent>
            <ShowAdvance
              show={this.props.show}
              onChange={this.props.onChange}
            />
             </CardContent>
        );
      case 'FaxInformation':
        return (
          <CardContent
            style={{width: '100%'}}
          >
            {this.props.form[this.props.field]}
          </CardContent>
        );
      default:
        return <div>No input provided</div>
    }
  }

  render () {
    const minWidth = this.state.mediaQuery.matches
    const { classes } = this.props
    const style = {
      card: {
        width: minWidth ? '50%' : '100%',
        minWidth: '400px'
      }
    }

    let cardStyle = classes.materialCard
    const phoneInputCardStylesOn = [
      'TextFieldWithButton',
      'PhoneInputForForwardingNoAdd',
      'PhoneInputForForwarding',
      'PhoneInput'
    ];

    if (phoneInputCardStylesOn.includes(this.props.formControl) || this.props.overflowVisible) {
      cardStyle = classes.phoneInputCard
    }

    return (
      <div style={style.card}>
        <Card classes={{ root: cardStyle }}>
          <CardHeader
            avatar={this.props.icon}
            subheader={this.props.subtitle}
            title={this.props.title}
          />
          {this.getFormControl(this.props.formControl, classes)}
        </Card>
      </div>
    )
  }
}

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '20px',
    alignItems: 'center'
  }
}

const style = () => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto 30px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  submitButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  buttons: {
    margin: '10px'
  },
  upload: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  header: {
    margin: '20px'
  },
  microphone: {
    width: '200px'
  },
  tabBar: {
    justifyContent: 'center'
  },
  tabBarCard: {
    padding: 0
  },
  additionalEmailCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  additionalEmailCardDiv: {
    alignItems: 'left'
  },
  tabsRoot: {
    backgroundColor: '#2a72b9',
    color: '#FFFFFF',
    fontWeight: 'bolder'
  },
  tabRoot: {
    width: '33.33%'
  },
  greetingCard:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    margin:'20px',
    alignItems: 'center',
  },
  materialCard: {
    overflow: 'hidden'
  },
  phoneInputCard: {
    overflow: 'visible'
  }
})

FormControlCard.defaultProps = {
  disabled: false,
  form: {},
  label: '',
  max: '10',
  min: '0',
  subheader: '',
  title: '',
  recordingUrl: ''
}

FormControlCard.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object,
  field: PropTypes.string.isRequired,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  recordingUrl: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.any,
  label: PropTypes.string
}

export default withStyles(style)(FormControlCard)
