import {
    ADD_MESSAGE_TO_BE_DELETED,
    CANCEL_MESSAGE_TO_BE_DELETED,
    CHANGE_TENANT,
    CURRENTLY_DELETING_MESSAGES,
    INVALIDATE_HTTP_DATA, 
    REMOVE_MESSAGE_TO_BE_DELETED,
    SELECT_SMS_CONVERSATION,
    SMS_SEND_FAILURE,
    SMS_SEND_SUCCESS,
    TOGGLE_ALL_MESSAGES_TO_BE_DELETED,
    UPDATE_SELECTED_DID_FOR_SMS_ADMIN,
    UPDATE_NEW_NUMBER_INPUT,
    UPDATE_TEXT_FOR_CONVERSATION_SEARCH,
    UPDATE_TEXT_FOR_CONTACT_SEARCH  } from '../actions/typeConstants';

const initialState = {page: 1};

export const sms = (state = initialState, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:
        return null;

    case SMS_SEND_FAILURE:

        const newState = Object.assign({}, state);

        const messageI = state.selectedConversation.conversation.messages.findIndex((obj) => obj.messageId === action.action.requestData.reqBody.messageId);

        state.selectedConversation.conversation.messages[messageI].failed = true;

        return newState;

    case SMS_SEND_SUCCESS:

        const newSuccessState = Object.assign({}, state);

        const successMessageI = state.selectedConversation.conversation.messages.findIndex((obj) => obj.messageId === action.action.requestData.reqBody.messageId);

        state.selectedConversation.conversation.messages[successMessageI].sending = false;

        return newSuccessState;

    case UPDATE_NEW_NUMBER_INPUT:
        return Object.assign({}, state, { showNewNumberInput: action.showNewNumberInput })

    case UPDATE_SELECTED_DID_FOR_SMS_ADMIN:

        return Object.assign({}, state, {
            selectedInternalDid: action.did,
            deletingMessages: false,
            messagesToBeDeleted: [],
            selectedAllMessages: false,
            selectedConversation: null,
        });

    case SELECT_SMS_CONVERSATION:

        // Clear any messages to be deleted as well
        return Object.assign({}, state, {
            deletingMessages: false,
            messagesToBeDeleted: [],
            selectedAllMessages: false,
            selectedConversation: {
                avatar: action.avatar,
                contactInfo: action.contactInfo,
                conversation: action.conversation,
            },
        });

    case UPDATE_TEXT_FOR_CONVERSATION_SEARCH:

        return Object.assign({}, state, { conversationSearchText: action.text });
    
    case UPDATE_TEXT_FOR_CONTACT_SEARCH:

        return Object.assign({}, state, { contactSearchText: action.text });

    case CURRENTLY_DELETING_MESSAGES:

        if (action.deleting) {
            // sets bool on whether we are currently deleting
            return Object.assign({}, state, {
                deletingMessages: true,
                messagesToBeDeleted: [],
            });
        }
        return Object.assign({}, state, {
            deletingMessages: false,
            messagesToBeDeleted: [],
            selectedAllMessages: false,
        });

    case ADD_MESSAGE_TO_BE_DELETED:

        // Creates new object of messages to be deleted,
        // and pushes the new messageId to the array
         const newObj = state.messagesToBeDeleted ?
            Array.from(state.messagesToBeDeleted) : [];

        newObj.push(action.messageId);

        return Object.assign({}, state, { messagesToBeDeleted: newObj });

    case REMOVE_MESSAGE_TO_BE_DELETED:

        // Creates new object of messages to be deleted,
        // finds the message id to remove it from array, and splices
        const newObject = state.messagesToBeDeleted ?
            Array.from(state.messagesToBeDeleted) : [];

        const index = newObject.indexOf(action.messageId);
        newObject.splice(index, 1);

        return Object.assign({}, state, { messagesToBeDeleted: newObject });

    case CANCEL_MESSAGE_TO_BE_DELETED:

        return Object.assign({}, state, {
            messagesToBeDeleted: [],
            selectedAllMessages: false,
        });

    case TOGGLE_ALL_MESSAGES_TO_BE_DELETED:

        return Object.assign({}, state, { selectedAllMessages: !state.selectedAllMessages });

    case "UPDATE_PAGE_NUMBER":

        return Object.assign({}, state, { page: action.page });

    default:

        return state;
    }
};
