import { combineReducers } from 'redux';

import {
    SELECT_LOGIN,
    CHANGE_TENANT,
    INVALIDATE_HTTP_DATA,
    UPDATE_TOKEN,
} from '../actions/typeConstants';

const loginData = (state = null, action) => {
    switch (action.type) {
        case CHANGE_TENANT:
        case INVALIDATE_HTTP_DATA:
            return null;
        case 'LOGINMANAGEMENT_HTTP_SUCCESS':
            // Store data for the specific login selected post request
            if (action.loginData) {
                action.data.groups = action.data.groups[0];
                if (action.data.groups == 'cloudadmin') {
                    action.data.groups = 'Administrator';
                } else if (action.data.groups == 'cloudusers') {
                    action.data.groups = 'Cloud User';
                }
                return Object.assign({}, state, action.data);
            }
            // If this is for an object, return
            if (action.reqObject) {
                return state;
            }
        default:
            return state;
    }
};
const data = (state = null, action) => {
    switch (action.type) {
        case CHANGE_TENANT:
        case INVALIDATE_HTTP_DATA:
            return null;
        case 'LOGINMANAGEMENT_HTTP_SUCCESS':
            if (action.reqObject) {
                const newState = Object.assign([], state);
                let found;
                newState.forEach((item, i) => {
                    if (item.userName && item.userName == action.reqObject) {
                        found = i;
                    }
                });

                //Add a new user
                if (action.reqType == 'POST') {
                    action.data.groups = action.data.groups[0];

                    if (action.data.groups == 'cloudadmin') {
                        action.data.groups = 'Administrator';
                    } else if (action.data.groups == 'cloudusers') {
                        action.data.groups = 'Cloud User';
                    }

                    newState[newState.length] = action.data;
                }

                //looks like this is the delete case
                if (!action.data) {
                    delete newState[found];
                }
                return Object.assign([], newState);
            }
            return Object.assign([], state, action.data);
        default:
            return state;
    }
};
const httpStatus = (state = {}, action) => {
    switch (action.type) {
        case 'UPDATE_TOKEN':
            return Object.assign({}, state, {
                failed: false,
                error: null,
                pending: false,
                success: false,
                reqType: null,
            });

        case 'LOGINMANAGEMENT_HTTP_PENDING':
            return Object.assign({}, state, {
                failed: false,
                error: null,
                pending: true,
                success: false,
                reqType: null,
            });

        case 'LOGINMANAGEMENT_HTTP_FAILURE':
            if (action.error) {
                let errorText;
                if (action.error && action.error.response && action.error.response.text) {
                    let errorObj = JSON.parse(action.error.response.text);
                    if (errorObj.error && errorObj.error.message) {
                        errorText = errorObj.error.message;
                    } else {
                        errorText = 'Unspecified error, please try again or contact support.';
                    }
                } else {
                    errorText = 'Unspecified error, please try again or contact support.';
                }
                return Object.assign({}, state, {
                    failed: true,
                    error: errorText,
                    pending: false,
                    success: true,
                    reqType: action.reqType === 'POST' ? action.reqType : null,
                });
            }
        case 'LOGINMANAGEMENT_HTTP_SUCCESS':
            if (action.data) {
                return Object.assign({}, state, {
                    failed: false,
                    error: null,
                    pending: false,
                    success: true,
                    reqType: action.reqType === 'POST' ? action.reqType : null,
                });
            }
        default:
            return state;
    }
};
const selected = (state = null, action) => {
    switch (action.type) {
        case CHANGE_TENANT:
        case INVALIDATE_HTTP_DATA:
            return null;
        case SELECT_LOGIN:
            return action.data;

        default:
            return state;
    }
};
export const loginManagement = combineReducers({
    loginData,
    data,
    httpStatus,
    selected,
});
