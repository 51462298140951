import React, { Component } from 'react';
import { Provider } from 'react-redux';
import ReactDOM, { unmountComponentAtNode, createPortal } from 'react-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'; 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import createStore from './createStore';

import ReactRouter from './reactRouter';
import App from './app';

import { navigate } from './actions/navigation';
import { updateToken } from './actions/token';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Lets create the store here
const store = createStore()
const portalContainer = document.getElementById('content');
// for debugging
window.store = store;
const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: '#2a72b9',
            dark: '#3078cf',
            contrastText: '#fff',
        },
        secondary: {
            main: '#e93c2c',
        },
        action: {
            hover: 'rgba(0, 0, 0, 0.1)',
            hoverOpacity: 0.1,
        },
    }
});
class Root extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            store: store,
            root: document.getElementById('content'),
        };
    
    }
 
    render() { 
        toast.configure();
        const Setup = () => (
            <Provider store={this.state.store || store}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <MuiThemeProvider theme={theme}>
                        <App root={this.state.root} store={this.state.store || store} />
                    </MuiThemeProvider>
                </MuiPickersUtilsProvider>
            </Provider>
        );
        return createPortal(
            <div><Setup /></div>,
            document.getElementById('content')
        )
    }
}

function parseParams(name, paramValues) {
    const paramNamesWithColon = name.match(/:[A-z]+/g);
    if(paramNamesWithColon) {
        const paramNames = paramNamesWithColon.map(param => param.replace(':', ''));
        
        return paramNames.reduce((acc, paramName, index) => {
            acc[paramName] = paramValues[index];
            return acc;
        }, {});
    }

    return null;
}


$(document).ready(() => {

    const reactRouter = new ReactRouter();
    reactRouter.on('route', (name, paramValues) => {
        const params = parseParams(name, paramValues);
        
        $('#leftbar').html('');
        $('#content').removeClass('col-md-10');
        $('#content').removeClass('col-md-12');
        $('.mfc-container').css('padding-bottom', '0px');

        // All nav items in clouduser portal
        const subItems = [
            'sms',
            'mycallhistory',
            'voicemailinbox',
            'voicemailgreetings',
            'voicemailsettings',
            'callrecording',
            'zohointegration',
            'callblocking',
            'callscreening',
            'callforwarding',
            'userportal',
            'voicemailrecording',
            'voicemailrecording/:recordingId',
            'usersettings',
            'fax-create',
            'fax-inbox',
        ];
        store.getState().navigation.subItems = subItems;

        // needed to show subnav FOR NOW
        name = name.split('/')[0];
        const $elem = $('.subnav');
        if($.inArray(name, subItems) >= 0 ) {
            $elem.show();
        } else {
            $elem.hide();
        }

        // When configurations loads, let's get them in the store
        window.App.Configurations.on('change', () => {

            return store.dispatch({ type: 'UPDATE_CONFIGURATIONS', configurations: window.App.Configurations.attributes });
        });

        store.dispatch({ type: 'UPDATE_TOKEN', token: app.token.attributes });
        store.dispatch({ type: 'UPDATE_CONFIGURATIONS', configurations: window.App.Configurations.attributes });
        store.dispatch(navigate(name, params));

        
        ReactDOM.render(
            <div><Root /></div>,
            document.getElementById('content'),
        );
    });
});
