import React, { Component } from 'react';
import { connect } from 'react-redux';

import MasterHistory from 'presentational/MasterHistory';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';

class AuditHistory extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.props.data || !this.props.data.config) {
      return (
        <StandardSettingsPage>
          <Loading />
        </StandardSettingsPage>
      );
    }
    let data = [];
    if(Object.keys(this.props.data.config.log).length > 0){
      Object.keys(this.props.data.config.log).map(key =>{
        data.push(this.props.data.config.log[key]);
      })
    }

    return (
      <MasterHistory data={data} />
    );
  }
}

const bindActions = (dispatch) => ({
});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, bindActions)(AuditHistory);
