import React, { useRef } from "react";

const urlCache = new Set();
const urlPending = new Set();

function useDynamicScript(url) {
    const [ready, setReady] = React.useState(false);
    const [errorLoading, setErrorLoading] = React.useState(false);

    React.useEffect(() => {
        if (!url) return;

        if (urlCache.has(url)) {
            setReady(true);
            setErrorLoading(false);
            return;
        }

        setReady(false);
        setErrorLoading(false);

        const element = document.createElement('script');

        element.src = url;
        element.type = 'text/javascript';
        element.async = true;

        element.onload = () => {            
            urlCache.add(url);
            setReady(true);
        };

        element.onerror = () => {
            setReady(false);
            setErrorLoading(true);
        };

        document.head.appendChild(element);
        
        return () => {
            // When a component is unloaded, you need to cancel subscriptions and remove listeners.
            // HTML elements do not have a remove listener method.  This is as close as I can make it.
            element.onload = undefined;
            element.onerror = undefined;

            // Only remove the element if it has not be loaded.  Since we are no longer listening to 
            // the onload event, it will not be added to the urlCache anyway.
            if (!urlCache.has(url)) {
                document.head.removeChild(element);
            }
        }
    }, [url]);

    return {
        errorLoading,
        ready,
    };
};

export default useDynamicScript;