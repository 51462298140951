import {
  CALLBLOCKING_HTTP_SUCCESS,
  EDITCALLBLOCKING_HTTP_SUCCESS,
  CALLBLOCKING_HTTP_FAILURE,
  EDITCALLBLOCKING_HTTP_FAILURE,
  RESET_FLAG,
} from '../actions/typeConstants';

import { parseError } from 'utils/misc';

const initialState = {
  callBlockingData: null,
  successMsg: null,
  errorMsg: null,
  errorCode: null,
}

export default function(state = initialState, action) {
  switch(action.type) {
    case CALLBLOCKING_HTTP_SUCCESS:
      return { ...state, callBlockingData: action.data, errorMsg: null, errorCode: null }
    case CALLBLOCKING_HTTP_FAILURE:
      return { ...state, errorMsg: parseError(action.error.response.text), successMsg: null, errorCode: action.error.response.status }
    case EDITCALLBLOCKING_HTTP_SUCCESS:
      return { ...state, callBlockingData: action.data, successMsg: 'Saved!', errorMsg: null, errorCode: null }
    case EDITCALLBLOCKING_HTTP_FAILURE:
      return { ...state, errorMsg: parseError(action.error.response.text), successMsg: null, errorCode: action.error.response.status }
    case RESET_FLAG:
      return {...state, successMsg: null, errorMsg: null, errorCode: null }
    default:
      return { ...state }
  }
}