import React, { Component } from 'react';
import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/PersonOutline';
import RecordIcon from '@material-ui/icons/RecordVoiceOver';
import PrintIcon from '@material-ui/icons/LocalPrintshop';
import TimerIcon from '@material-ui/icons/AvTimer';
import Timer from '@material-ui/icons/Timer';
import MenuIcon from '@material-ui/icons/Menu';
import IncomingIcon from '@material-ui/icons/CallReceived';
import { connect } from 'react-redux';
import { putCoreHttp } from 'actions/httpRequest';
import Loading from 'presentational/Loading';
import Footer from 'presentational/Footer';
import { resetReloadDetails } from 'actions/phoneNumbers';
import DialogModal from 'presentational/DialogModal';
import { translatePhoneTypeToFriendlyName } from 'utils/translations';

class FaxForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      error: null,
      submitError: null,
      sectionDisplay: '0',
      isModalOpen: false,
      abbrevForm: {},
      changedValues: {},
      loading: false,
    }
    this.usingapiData = false;
  }

  componentWillUnmount() {
    if (!this.props.buttonDisabled) {
      this.props.checkSubmit(this.state.form);
      this.props.disableButton(true);
    }
    this.props.resetReloadDetails('store');
  }

  onSubmit = (cancel) => {
    if (cancel) {
      this.props.onCancel();
      return;
    }
    this.props.disableButton(true);
    this.editNumber(this.state.form)
  }

  submitForm = (field, data) => {
    if (this.state.form[field] === data) {
      console.log('No update');
      return;
    } else {
      this.props.updateAbbrevForm(field, data)
      this.props.madeChanges(true, this.state.form);
    }

    if (this.props.buttonDisabled) {
      this.props.disableButton(false)
    }

    let dataToSend = data;
    if (typeof (data) === 'boolean') {
      dataToSend = data === false
        ? '0'
        : '1';
    }
    else if (data === 'Select A Wait Time') {
      dataToSend = '';
    }

    let changedValues = { ...this.state.changedValues };
    changedValues[field] = `${translatePhoneTypeToFriendlyName(field)}: ${data}`;

    let abbrevForm = { ...this.state.abbrevForm };
    abbrevForm[field] = data;

    this.setState({
      form: {
        ...this.state.form,
        [field]: dataToSend
      },
      abbrevForm,
      changedValues
    })
  }


  editNumber = (data) => {

    let newData = data;

    // if we disable fax, we need to be sure to 
    // disable inbound detection
    if (newData.faxenabled == "0") {
      newData.fax_detection_inbound = "0"
    }

    const reqData = {
      reqAction: 'phonenumbers',
      reqObject: encodeURIComponent(data.phonenumber),
      reqBody: data,
    };

    const storeKey = 'editphonenumbers';
    this.props.putCoreHttp(reqData, storeKey);
  }

  submitFaxSettings = (data) => {
    let { abbrevForm } = { ...this.state };

    abbrevForm.phonenumber = this.props.selectedPhoneNumber;

    const reqData = {
      reqAction: 'bulkeditphonenumber',
      reqObject: 'bulkeditphonenumber',
      reqBody: abbrevForm,
    };

    const storeKey = 'bulkeditphonenumber';
    this.props.putCoreHttp(reqData, storeKey);
  }

  componentDidMount() {
    this.updateDetails();
  }

  handleClick = (e) => {
    if (this.node) {
      if (this.node.contains(e.target)) {
        this.props.clickedNode();
      }
    }
  }

  updateDetails = () => {
    if (this.props.reloadDetails === 'store' && this.props.phoneNumberDetails) {
      this.props.resetReloadDetails();

      this.setState({
        form: this.props.phoneNumberDetails,
        loading: this.props.loading,
      });
    }
  }

  componentDidUpdate() {
    this.updateDetails();

    if (this.state.form.faxenabled && this.state.sectionDisplay !== this.state.form.faxenabled) {
      this.setState({ sectionDisplay: this.state.form.faxenabled });
    }
  }

  render() {

    let { isModalOpen, changedValues, loading } = { ...this.state };
    const { selectedPhoneNumber } = this.props;

    if (this.props.loading === false) {
      loading = false;
    }

    if (!this.props.phoneNumberDetails || loading) {
      return (<Loading />);
    }
    const privatefaxOptions = [{ value: '2', label: 'company' }, { value: '1', label: 'private' }];

    const privatefaxDestinations = this.props.users
      /* create the list of fax destinations, sort by Int value, return obj with value as a string
       containing leading zeros, and the label as a string. */
      .map(user => ({ numValue: parseInt(user.extension), extension: user.extension, label: `${user.extension} - ${user.firstname} ${user.lastname}` }))
      .sort((a, b) => a.numValue > b.numValue ? 1 : a.numValue < b.numValue ? -1 : 0)
      .map(user => ({ value: user.extension, label: user.label }))

    privatefaxDestinations.unshift({ value: 'company', label: 'Company' });

    const privatefaxDestinationsInput = this.state.form.privatefax && this.state.form.privatefax === '1'
      ? <FormControlCard
        form={this.state.form}
        field="privatefaxuser"
        formControl="SelectInput"
        title="Private Fax Destination"
        submitForm={this.submitForm}
        options={privatefaxDestinations}
      />
      : null;

    let fax_minimum_rate = [];
    let fax_maximum_rate = [];
    let fax_detection_wait_time = [];

    if (this.props.phoneNumberDetails) {
      fax_minimum_rate = this.props.phoneNumberDetails.config.fax_minimum_rate;
      fax_maximum_rate = [...this.props.phoneNumberDetails.config.fax_maximum_rate].reverse();
      fax_detection_wait_time = this.props.phoneNumberDetails.config.fax_detection_wait_time;
    }
    return (
      <div
        ref={node => this.node = node}
        style={{
          display: 'flex',
          flex: 1,
          height: '100%',
          background: '#EFEFEF',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: '50px',
          overflow: 'auto',
        }}
        onMouseDown={this.handleClick}
      >
        <StandardSettingsPage>
          <div style={style.card}>
            <Card>
              <CardHeader
                avatar={<DescriptionIcon />}
                subtitle=''
                title='Fax Options'
              />
              <CardContent>
                <div style={{ margin: '20px', paddingRight: '30px' }}>
                  <p>Assigning a phone number to a route will scan for Electronic Fax Settings then follow all time
                    conditions and applications within the defined route.
                    An emergency route may be defined in the event that all of the extensions at the defined location are offline.
                  </p>
                </div>
              </CardContent>
            </Card>
          </div>
          <FormControlCard
            form={this.state.form}
            field="faxenabled"
            formControl="ToggleInput"
            icon={<PrintIcon />}
            title="Enable Fax"
            subtitle=""
            submitForm={this.submitForm}
            label="No / Yes"
          />
          {this.dependentInputs(this.state.sectionDisplay, privatefaxOptions, fax_minimum_rate, fax_maximum_rate, fax_detection_wait_time, privatefaxDestinationsInput)}
          <Footer
            multipleChanges={this.props.selectedPhoneNumber.length > 1 ? true : false}
            onClick={(cancel) => this.onSubmit(cancel)}
            disabled={this.props.buttonDisabled}
            onUpdateAll={() => this.setState({ isModalOpen: true })}
          />
        </StandardSettingsPage>
        {/* Apply all changes modal */}
        <DialogModal
          show={isModalOpen}
          affirmativeResponse={() => { this.submitFaxSettings(this.state.form), this.props.disableButton(true), this.setState({ isModalOpen: false, loading: true }) }}
          negativeResponse={() => { this.props.disableButton(true), this.setState({ isModalOpen: false }) }}
          title={`Are you sure you want to apply the following changes to all selected phone numbers?`}
          content={Object.values(changedValues)}
        />
      </div>
    )
  }

  dependentInputs = (state, privatefaxOptions, fax_minimum_rate, fax_maximum_rate, fax_detection_wait_time, privatefaxDestinationsInput) => (
    state === '0'
      ? null
      : <>
        <FormControlCard
          form={this.state.form}
          field="privatefax"
          icon={<PersonIcon />}
          formControl="SelectInput"
          title="Fax Type"
          submitForm={this.submitForm}
          options={privatefaxOptions}
        />
        {privatefaxDestinationsInput}
        <FormControlCard
          form={this.state.form}
          field="fax_detection_inbound"
          formControl="ToggleInput"
          icon={<IncomingIcon />}
          title="Inbound Detection"
          subtitle=""
          submitForm={this.submitForm}
          label="No / Yes"
        />
        <FormControlCard
          form={this.state.form}
          field="fax_detection_wait_time"
          icon={<TimerIcon />}
          formControl="SelectInput"
          title="Fax Detection Wait Time"
          submitForm={this.submitForm}
          options={['Select A Wait Time', ...fax_detection_wait_time]}
        />
        <FormControlCard
          form={this.state.form}
          field="fax_minimum_rate"
          icon={<Timer />}
          formControl="SelectInput"
          title="Fax Minimum Rate"
          submitForm={this.submitForm}
          options={fax_minimum_rate}
        />
        <FormControlCard
          form={this.state.form}
          field="fax_maximum_rate"
          icon={<Timer />}
          formControl="SelectInput"
          title="Fax Maximum Rate"
          submitForm={this.submitForm}
          options={fax_maximum_rate}
        />
        <FormControlCard
          form={this.state.form}
          field="fax_enable_t38"
          formControl="ToggleInput"
          icon={<RecordIcon />}
          title="Enable T.38"
          subtitle=""
          submitForm={this.submitForm}
          label="No / Yes"
        />
        <FormControlCard
          form={this.state.form}
          field="fax_error_correction"
          formControl="ToggleInput"
          icon={<MenuIcon />}
          title="Error Correction Mode"
          subtitle=""
          submitForm={this.submitForm}
          label="No / Yes"
        />
      </>
  )

}

const style = {
  card: {
    width: '50%',
    minWidth: '400px',
  },
};


const bindActions = (dispatch) => ({
  putCoreHttp: (reqData, storeKey) => dispatch(putCoreHttp(reqData, storeKey)),
  resetReloadDetails: (data) => dispatch(resetReloadDetails(data))
})

const mapStateToProps = state => ({
  phoneNumberDetails: state.phoneNumbers.phoneNumberDetails,
  users: state.http.configurations.users,
  reloadDetails: state.phoneNumbers.reloadDetails,
  loading: state.phoneNumbers.loading,
});

export default connect(mapStateToProps, bindActions)(FaxForm);
