import React, { useState } from 'react';
import PropTypes from 'prop-types';

const FileUploadButton = (props) => {
    const [fileName, setFileName] = useState('No File Chosen');
    const [fileInputRef, setFileInputRef] = useState(null);

    const validateFileName = (name) => {
        const path = name.split(/(\\|\/)/g);
        const filename = path[path.length-1];
        let fileNameNoExt;
        
        if (filename.includes('.')) {
            fileNameNoExt = filename.substring(0,filename.lastIndexOf('.'));
        } else {
            fileNameNoExt = filename;
        }

        //replacing all special characters except '-' and '_'
        const formattedFileName = fileNameNoExt.replace(/[^a-zA-Z0-9-_]/g, '');
        
        return formattedFileName;
    }

    const onChange = (e, propsOnChange) => {
        let target, value;
        const path = 'C:\\fakepath\\';

        if (e.dataTransfer) {
            target = e.dataTransfer;
            value =  `${path}${e.dataTransfer.files[0].name}`;
        } else {
            target = e.target;
            value = target.value;
        }

        const oldFile = target.files[0];
        const fileSuffix = `.${oldFile.type.slice(oldFile.type.indexOf('/') + 1)}`;
        const changedFileName = validateFileName(value);

        setFileName(`${changedFileName}${fileSuffix}`);

        if (e.dataTransfer) {
            const event = {
                target: {
                    files: [oldFile],
                },
            }
            propsOnChange(event);
        } else {
            propsOnChange(e);
        }
    }

    const dragOperations = (e) => {
        e.preventDefault();
    }

    const handleFileDrop = (e, propsOnChange) => {
        e.preventDefault();
        onChange(e, propsOnChange)
    }

    return (
        <>
            <div
                style={styles.wrapper}
                onDragEnter={dragOperations}
                onDragLeave={dragOperations}
                onDragOver={dragOperations}
                onDrop={(e) => handleFileDrop(e, props.onChange)}
                id='formattedButton'
            >
                <button
                    onClick={() => { 
                        fileInputRef.click();
                    }}
                >Choose File</button>
                <span style={styles.span}>{fileName}</span>
            </div>
            <input 
                type='file' 
                accept='audio/*'
                ref={(ref) => setFileInputRef(ref)}
                onChange={(e) => onChange(e, props.onChange)}
                style={styles.input}
            />
        </>
    );
}

const styles = {
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    input: {
        opacity: 0,
        height: '0px',
        width: '0px',
    },
    span: {
        marginLeft: '5px',
    },
}


FileUploadButton.defaultProps = {
    disabled: false,
    label: 'Submit',
    onClick: () => console.log('No onClick func passed into submit button'),
    style: null,
};

FileUploadButton.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
};

export default FileUploadButton;
