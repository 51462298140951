import { REMOVE_CONFERENCE_ROOM_DETAILS, FILTER_CONFERENCE_DATA, SELECTED_CONFERENCE_DATA, RESET_FLAG, RESET_CREATED_ROOM } from './typeConstants';

export const removeConferenceRoomDetails = () => ({
  type: REMOVE_CONFERENCE_ROOM_DETAILS
})

export const setFilteredConferenceRooms = (data) => ({
  type: FILTER_CONFERENCE_DATA,
  payload: data
})

export const selectedConferenceRoom = (data) => ({
  type: SELECTED_CONFERENCE_DATA,
  payload: data
})

export const resetFlag = () => ({
  type: RESET_FLAG
})

export const resetCreatedRoom = () => ({
  type: RESET_CREATED_ROOM
})