import {
    GET_HTTP,
    POST_HTTP,
    PATCH_HTTP,
    PUT_HTTP,
    DELETE_HTTP,

    HTTP_SUCCESS,

    USERPORTAL_NAMESPACE,
    CORE_NAMESPACE,
    MASTER_NAMESPACE,
    REPORTS_NAMESPACE_V2,
    SMS_NAMESPACE,
    CALL_NAMESPACE,
    UPDATE_CONFIGURATIONS} from './typeConstants';

export const getUserPortalHttp = (requestData, storeKey) => ({
    type: GET_HTTP,
    namespace: USERPORTAL_NAMESPACE,
    requestData,
    storeKey,
});

export const postUserPortalHttp = (requestData, storeKey) => ({
    type: POST_HTTP,
    namespace: USERPORTAL_NAMESPACE,
    requestData,
    storeKey,
});

export const patchUserPortalHttp = (requestData, storeKey) => ({
    type: PATCH_HTTP,
    namespace: USERPORTAL_NAMESPACE,
    requestData,
    storeKey,
});

export const putUserPortalHttp = (requestData, storeKey) => ({
    type: PUT_HTTP,
    namespace: USERPORTAL_NAMESPACE,
    requestData,
    storeKey,
});

export const deleteUserPortalHttp = (requestData, storeKey) => ({
    type: DELETE_HTTP,
    namespace: USERPORTAL_NAMESPACE,
    requestData,
    storeKey,
});

export const getCoreHttp = (requestData, storeKey) => ({
    type: GET_HTTP,
    namespace: CORE_NAMESPACE,
    requestData,
    storeKey,
});

export const postCoreHttp = (requestData, storeKey) => ({
    type: POST_HTTP,
    namespace: CORE_NAMESPACE,
    requestData,
    storeKey,
});

export const patchCoreHttp = (requestData, storeKey) => ({
    type: PATCH_HTTP,
    namespace: CORE_NAMESPACE,
    requestData,
    storeKey,
});

export const putCoreHttp = (requestData, storeKey) => ({
    type: PUT_HTTP,
    namespace: CORE_NAMESPACE,
    requestData,
    storeKey,
});

export const deleteCoreHttp = (requestData, storeKey) => ({
    type: DELETE_HTTP,
    namespace: CORE_NAMESPACE,
    requestData,
    storeKey,
});

export const getMasterHttp = (requestData, storeKey) => ({
    type: GET_HTTP,
    namespace: MASTER_NAMESPACE,
    requestData,
    storeKey,
});

export const postMasterHttp = (requestData, storeKey) => ({
    type: POST_HTTP,
    namespace: MASTER_NAMESPACE,
    requestData,
    storeKey,
});

export const patchMasterHttp = (requestData, storeKey) => ({
    type: PATCH_HTTP,
    namespace: MASTER_NAMESPACE,
    requestData,
    storeKey,
});

export const putMasterHttp = (requestData, storeKey) => ({
    type: PUT_HTTP,
    namespace: MASTER_NAMESPACE,
    requestData,
    storeKey,
});

export const deleteMasterHttp = (requestData, storeKey) => ({
    type: DELETE_HTTP,
    namespace: MASTER_NAMESPACE,
    requestData,
    storeKey,
});

export const getReportsHttp = (requestData, storeKey, nameSpace) => ({
    type: GET_HTTP,
    namespace: nameSpace && nameSpace.length ? nameSpace : REPORTS_NAMESPACE_V2,
    requestData,
    storeKey,
});

export const postReportsHttp = (requestData, storeKey, nameSpace=REPORTS_NAMESPACE_V2) => ({
    type: POST_HTTP,
    namespace: nameSpace && nameSpace.length ? nameSpace : REPORTS_NAMESPACE_V2,
    requestData,
    storeKey,
});

export const patchReportsHttp = (requestData, storeKey, nameSpace=REPORTS_NAMESPACE_V2) => ({
    type: PATCH_HTTP,
    namespace: nameSpace && nameSpace.length ? nameSpace : REPORTS_NAMESPACE_V2,
    requestData,
    storeKey,
});

export const putReportsHttp = (requestData, storeKey, nameSpace=REPORTS_NAMESPACE_V2) => ({
    type: PUT_HTTP,
    namespace: nameSpace && nameSpace.length ? nameSpace : REPORTS_NAMESPACE_V2,
    requestData,
    storeKey,
});

export const deleteReportsHttp = (requestData, storeKey, nameSpace=REPORTS_NAMESPACE_V2) => ({
    type: DELETE_HTTP,
    namespace: nameSpace && nameSpace.length ? nameSpace : REPORTS_NAMESPACE_V2,
    requestData,
    storeKey,
});

export const getSmsHttp = (requestData, storeKey, page) => ({
    type: GET_HTTP,
    namespace: SMS_NAMESPACE,
    requestData,
    storeKey,
});

export const getNextPage = (requestData, storeKey, page) => ({
    type: "GET_NEXT_PAGE",
    namespace: SMS_NAMESPACE,
    page,
    requestData,
    storeKey,
});

export const postSmsHttp = (requestData, storeKey) => ({
    type: POST_HTTP,
    namespace: SMS_NAMESPACE,
    requestData,
    storeKey,
});

export const patchSmsHttp = (requestData, storeKey) => ({
    type: PATCH_HTTP,
    namespace: SMS_NAMESPACE,
    requestData,
    storeKey,
});

export const putSmsHttp = (requestData, storeKey) => ({
    type: PUT_HTTP,
    namespace: SMS_NAMESPACE,
    requestData,
    storeKey,
});

export const deleteSmsHttp = (requestData, storeKey) => ({
    type: DELETE_HTTP,
    namespace: SMS_NAMESPACE,
    requestData,
    storeKey,
});

export const httpSuccess = (data, storeKey) => ({
    type: HTTP_SUCCESS,
    data,
    storeKey,
});

export const updateConfigurations = (configurations) => ({
    type: UPDATE_CONFIGURATIONS,
    configurations,
});

export const postCallHttp = (requestData, storeKey) => ({
    type: POST_HTTP,
    namespace: CALL_NAMESPACE,
    requestData,
    storeKey,
});
