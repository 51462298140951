import React, { Component } from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/styles';

import CallerQuestionForm from './callerQuestionForm';
import AgentQuestionForm from './agentQuestionForm';

import { translateApplication } from 'utils/translations';

const styles = () => ({
  button: {
      fontSize: '100%',
  },
  card: {
    width: '50%',
    minWidth: '400px',
  },
  cardSmall: {
    width: '100%',
  },
  deleteButton: {
      color: 'red',
  },
});

class QuestionCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showingModal: false,
            mediaQuery: false,
            form: props.form,
            initialForm: {},
            formErrors: {
                question_name: '',
                question_text: '',
                rating_min: '',
                rating_max: '',
            },
        };
    }

    componentDidMount() {

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
    }

    handleClose(submit) {

        const { context, data, id } = this.props;

        if (submit) {

            this.setState({ formErrors: {} });

            const formErrors = this.validateForm();

            if (Object.keys(formErrors).some(err => formErrors[err])) {

                this.setState({ formErrors })
                return;
            }


            this.props.submitForm(this.state.form);
            return this.setState({ showingModal: false });
        }

        this.setState({ showingModal: false, form: this.state.initialForm });
    }

    validateForm() {
        const { context, form, id } = this.props;

        const foundIndex = this.state.form.questions[context].findIndex(
            (obj) => obj.question_number == id,
        );

        const currentQuestionForm = this.state.form.questions[context][foundIndex];

        let formErrors = {};
        const max = parseInt(currentQuestionForm['rating_max']) || parseInt(currentQuestionForm['dialpad_max']);
        const min = parseInt(currentQuestionForm['rating_min']) || parseInt(currentQuestionForm['dialpad_min']);

        if (min >= max) {
            formErrors['dialpad_max'] = 'Maximum rating must be higher than minimum rating';
        }

        return formErrors;
    }

    updateForm(field, value) {
        const { form } = this.state;

        const { context, id } = this.props;

        const foundIndex = this.state.form.questions[context].findIndex(
            (obj) => obj.question_number == id,
        );

        console.log('UPDATING FORM: ', foundIndex, form.questions[context][foundIndex][field])

        form.questions[context][foundIndex][field] = value;
        this.setState({ form });
    }

    render() {

        const { context, data, id } = this.props;
         const { form } = this.state;
        const minWidth = this.state.mediaQuery.matches;

        const modalTitle = `Edit question for ${form.name}`;

        const foundIndex = this.state.form.questions[context].findIndex(
            (obj) => obj.question_number == id,
        );

        const currentQuestionForm = form.questions[context][foundIndex];
        const {classes} = this.props;

        return (
          <Card classes={{ root: minWidth ? classes.card : classes.cardSmall }}>
            <CardHeader
              onClick={() => {
                    const initialForm = Object.assign({}, form);
                    this.setState({ initialForm, showingModal: true });
                }}
              title={data.question_name}
              subheader={`Question Number ${data.question_number}`}
              action={
                <>
                  <IconButton
                    onClick={() => {
                      const initialForm = Object.assign({}, form);
                      this.setState({ initialForm, showingModal: true });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    classes={{ root: classes.deleteButton }}
                    onClick={() => this.props.removeQuestion(id)}
                  >
                    <DeleteIcon/>
                  </IconButton>
                </>
              }
            >
            </CardHeader>
            <Dialog
              open={this.state.showingModal}
              onClose={() => this.handleClose(false)}
              fullWidth={true}
            >
              <DialogTitle>{modalTitle}</DialogTitle>
              <DialogContent>
                {context === "call" ?
                  <CallerQuestionForm
                    updateForm={(field, value) => this.updateForm(field, value)}
                    form={currentQuestionForm}
                    formErrors={this.state.formErrors}
                  />
                  :
                  <AgentQuestionForm
                    updateForm={(field, value) => this.updateForm(field, value)}
                    form={currentQuestionForm}
                    formErrors={this.state.formErrors}
                  />
                }
              </DialogContent>
              <DialogActions>
                <Button
                  classes={{ root: classes.button }}
                  color='primary'
                  onClick={() => this.handleClose(false)}
                >Cancel</Button>
                <Button
                  classes={{ root: classes.button }}
                  color='primary'
                  onClick={() => this.handleClose(true)}
                >Submit</Button>
              </DialogActions>
            </Dialog>
          </Card>
        );
    }
}

export default withStyles(styles)(QuestionCard);
