import React from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import TickIcon from '@material-ui/icons/Done'

const style = {
    selectFieldWrapper: {
        paddingLeft: 50,
        paddingRight: 50,
    },
};

const useStyle = makeStyles({
    select: {
        fontSize: '100%',
    },
    menuItem: {
        fontSize: '100%',
    },
    tickIcon: {
        verticalAlign: 'middle',
    },
    selectedOption: {
        display: 'flex',
        flexDirection: 'row',
    },
    selectedOptionName: {
        color: '#2a72b9',
        paddingLeft: '10px',
    },
    deselectedOption: {
        marginLeft: '34px',
    },
});


const menuItems = (names, values, classes) => {
    return names.map(name => {
        const selected = values && values.includes(name);
        return (
            <MenuItem
                key={name}
                classes={{ root: classes.menuItem }}
                defaultChecked={selected}
                value={name}
            >
                {selected ?
                    <div className={classes.selectedOption}>
                        <SvgIcon classes={{ root: classes.tickIcon }}><TickIcon/></SvgIcon>
                        <div className={classes.selectedOptionName}>{name}</div>
                    </div>
                    :
                    <div className={classes.deselectedOption}>
                        {name}
                    </div> 
                }
            </MenuItem>
        );
    });
};

const MultiSelectInput = props => {
    const classes = useStyle();
    const values = props.values.filter(value => props.names.includes(value))
    return (
        <div style={style.selectFieldWrapper}>
            <FormControl error={props.errorText ? true : false} fullWidth={true}>
                <InputLabel>{props.label}</InputLabel>
                <Select
                    multiple={true}
                    classes={{ root: classes.select }}
                    value={props.values}
                    onChange={(event) => props.handleChange(event.target.value)}
                    disabled={props.disabled}
                    renderValue={() => values.join(', ')}
                >
                    {menuItems(props.names, props.values, classes)}
                </Select>
                <FormHelperText>{props.errorText}</FormHelperText>
            </FormControl>
        </div>
    );
};

MultiSelectInput.defaultProps = {
    errorText: '',
    hintText: '',
    placeholder: '',
    onChange: () => console.log('No onChange func provided for textinput'),
    values: ['No value'],
    paddingBottom: 30,
    multiple: false,
    names: ['No value'],
    label: '',
};

MultiSelectInput.propTypes = {
    errorText: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    names: PropTypes.array,
    values: PropTypes.array,
    paddingBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default MultiSelectInput;
