import React, { Component } from 'react';
import { connect } from 'react-redux';

import StepperInput from 'presentational/formControls/StepperInput';

const style={

};

class SkillForm extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { form, updateForm } = this.props;
        return (
          <div>
            <h4>Manage the level this agent has in this queue</h4>
            <h6 style={{color: "red"}}>Changes to skill level will not take effect until the subsequent sign-in.</h6>
            <h6>Agent skills define queue memberships for each individual dynamic agent, as well as the skill level, or priority in queue, for the agent. Adding an agent skill to the agent will place the agent in queue to receive calls for that skill queue. Setting a level will determine that agent's priority in the queue. The default and lowest level is 0. Setting a lower level reduces the agent's priority or ranking in the delivery of callers to the agent.</h6>
              <StepperInput
              field="level"
              min={0}
              max={1000}
              onChange={(field, value) => updateForm(field, value)}
              value={form['level']}
            />
          </div>
        );
    }
}

const mapStateToProps = (state) => ({
    configurations: state.http.configurations,
});

export default connect(mapStateToProps)(SkillForm);
