import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import ErrorIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/styles';

const ErrorCard = (props) => {
  const classes = makeStyles({
    card: {
      width: '50%',
      minWidth: '400px',
    },
    title: {
      color: '#F00',
    },
  })();
  return (
    <Card
      classes={{ root: classes.card }}
    >
      <CardHeader
        avatar={<ErrorIcon color="#F00" />}
        subtitle={props.data.summary}
        title="Error"
        classes={{ root: classes.title }}
      />
      <CardContent >
        {props.data.detail}
      </CardContent>
    </Card>
  );
}

ErrorCard.defaultProps = {
    data: {
        detail: '',
        title: '',
        summary: '',
    }
};

ErrorCard.propTypes = {
    data: PropTypes.object,
};

export default ErrorCard;
