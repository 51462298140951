import React, { Component } from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import SkillForm from './skillForm';

export default function SkillCardRenderer(props) {
  const classes = props.useStyle();
  const { parent } = props;
  const queueName = parent.getQueueName(parent.props.data.skill);
  const modalTitle = `Edit skill for ${queueName}`;
  return (
    <Card classes={{ root: classes.card }}>
      <CardHeader
        onClick={() => {
          const initialForm = Object.assign({}, parent.props.form[parent.props.index]);
          parent.setState({ initialForm, showingModal: true });
        }}
        title={queueName}
        subheader={`Skill level: ${parent.props.form[parent.props.index].level}`}
        action={
          <>
            <IconButton
              onClick={() => {
                const initialForm = Object.assign({}, parent.props.form[parent.props.index]);
                parent.setState({ initialForm, showingModal: true });
              }}
              classes={{ root: classes.editButton }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              classes={{ root: classes.deleteButton }}
              onClick={() => parent.props.removeSkill(parent.props.index)}
            >
              <DeleteIcon/>
            </IconButton>
          </>
        }
      >
      </CardHeader>
      <Dialog
        open={parent.state.showingModal}
        onClose={() => parent.handleClose(false)}
        fullWidth={true}
      >
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent classes={{ root: classes.modal }}>
          <SkillForm
            updateForm={(field, value) => parent.updateForm(field, value)}
            form={parent.state.form[parent.props.index]}
          />
        </DialogContent>
        <DialogActions>
          <Button
            classes={{ root: classes.button }}
            color='primary'
            onClick={() => parent.handleClose(false)}
          >Cancel</Button>
          <Button
            classes={{ root: classes.button }}
            color='primary'
            onClick={() => parent.handleClose(true)}
          >Submit</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}