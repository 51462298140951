import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import React from 'react';
import PropTypes from 'prop-types';


function DialogModal(props){

    return (
        <Dialog
            open={props.show}
            fullWidth={true}
        >
            <DialogTitle>{props.title}</DialogTitle>
                {props.content && props.content.length > 0 && (
                    <ul>
                        {props.content.map((item) => <li>{item}</li>)}
                    </ul>)}
                <DialogActions>
                    <Button
                        color='primary'
                        onClick={props.negativeResponse}
                    >No</Button>
                    <Button
                        color='primary'
                        onClick={props.affirmativeResponse}
                    >Yes</Button>
                </DialogActions>
        </Dialog>
    )
}

DialogModal.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string.isRequired,
    content: PropTypes.array.isRequired,
    affirmativeResponse: PropTypes.func.isRequired,
    negativeResponse: PropTypes.func.isRequired,
}

export default DialogModal;