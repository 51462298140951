/* This function can be used to format csv data in the shape of [{row1name: row1data, row2name: row2data, row3name: row3data, ect... }]
   The callback function can be used to capture the data.
   Use Like: <input type="file" onChange={e => csvHelper(e, 3, setButtonDisabled, ['name', 'another', 'name2'], callBack)}
   The columnNames will be the keys in the return object, EX: [{name: '', another: '', name2: ''}]
*/

export const csvHelper = (e, columnsToValidate, setSubmitButtonDisabled, columnNames, callback) => {
    const fileExtension = e.name.split('.').pop();
    const reader = new FileReader();
    reader.readAsText(e);
    reader.onload = evt => {
        const csvFirstLine = evt.target.result.split('\n')[0];
        const numColumns = csvFirstLine.split(',').length;
        if (fileExtension != 'csv') {
            app.notifications.add({
                type: 'danger',
                dismiss: true,
                message: 'Please choose a .csv file',
            });
            setSubmitButtonDisabled(true);
            return;
        } else if (fileExtension == 'csv' && numColumns != columnsToValidate) {
            app.notifications.add({
                type: 'danger',
                dismiss: false,
                message: `This file does not appear to have ${columnsToValidate} columns.`,
            });
            setSubmitButtonDisabled(true);
            return;
        } else {
            const data = evt.target.result.split('\n').slice(1);
            const dataRows = data.reduce((acc, row) => {
                const rowData = row.split(',');
                const obj = columnNames.reduce((accumulator, name, index) => {
                    accumulator[name] = `${rowData[index]}`.trim();
                    return accumulator;
                }, {});
                acc.push(obj);
                return acc;
            }, []);
            callback(dataRows);
        }
    };
};
