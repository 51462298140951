import React from 'react';
import PropTypes from 'prop-types';

import PhoneInput from 'react-phone-input-intl';
import 'react-phone-input-intl/dist/style.css';

import Button from '@material-ui/core/Button';

const TextFieldWithButton = (props) => {

  const wrapper = {
    paddingLeft: 100,
    paddingBottom: 30,
    display: 'flex',
    flexDirection: 'row',
  };

  const mergedStyle = {...wrapper, ...props.style};

  const style = {
    wrapper: mergedStyle,
    phoneInputContainerStyle: {
      width: 'auto',
    },
  };

  return (
    <div style={style.wrapper}>
      <PhoneInput
        placeholder={props.placeholder}
        defaultCountry={'us'}
        value={props.value}
        onChange={(val, data) => props.onChange(val, data)}
        getCountryCode={(countryCode) => props.getCountryCode("+" + countryCode)}
        containerStyle={style.phoneInputContainerStyle}
        onEnterKeyPress={props.onClick}
      />
      <Button
        style={{ minWidth: '25px' }}
        onClick={props.onClick}
      >
        {props.buttonLabel}
      </Button>
    </div>
  );
};

TextFieldWithButton.defaultProps = {
  disabled: false,
  placeholder: '',
  value: '',
  type: '',
  onChange: () => console.log('No onChange func provided for textinput'),
  onBlur: () => console.log('No onBlur func provided for textinput'),
  onClick: () => console.log('No onClick func provided for button'),
};

TextFieldWithButton.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  buttonLabel: PropTypes.any.isRequired,
  style: PropTypes.object,
  getCountryCode: PropTypes.func,
};

export default TextFieldWithButton;
