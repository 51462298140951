import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';

const style = {
    controlsWrapper: {
        display: 'flex',
        flex: 1,
    },
    wrapper: {
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 30,

    },
    textfield: {
        // height: 40,
    },
    errorText: {
        fontSize: 12,
        color: 'red',
    },
};

const SliderInput = (props) => {
  const classes = makeStyles({
    sliderStyle: {
      flex: 1,
      paddingLeft: '100px',
      paddingRight: '10px',
    },
  })();

  let sliderValue = get(props, 'value', 0);
  sliderValue = Math.min(parseInt(sliderValue), 10);

  return (
    <div style={style.wrapper}>
      <div style={style.controlsWrapper}>
        <TextField
          label={props.placeholder}
          placeholder={props.placeholder}
          id={`slider_input_${props.field}`}
          value={props.value ? props.value : ''}
          name={props.field}
          onChange={(e, text) => props.onChange(e.target.value)}
          onBlur={(e) => props.onBlur(e.target.value)}
          error={Boolean(props.errorText)}
          helperText={props.errorText ? props.errorText : null}
        />
        <Slider
          name={`${props.field}-slider`}
          onChange={(e, value) => props.onChange(value)}
          onBlur={(e) => props.onBlur(e.target.value)}
          value={sliderValue}
          min={parseInt(props.min)}
          max={parseInt(props.max)}
          step={1}
          classes={{ root: classes.sliderStyle }}
          marks = {props.marks}
        />
      </div>
    </div>
  )
};

SliderInput.defaultProps = {
    errorText: '',
    placeholder: '',
    onBlur: () => console.log('No onBlur func provided for sliderinput'),
    onChange: () => console.log('No onChange func provided for sliderinput'),
};

SliderInput.propTypes = {
    errorText: PropTypes.any,
    field: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

export default SliderInput;
