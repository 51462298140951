import React, { Component } from 'react';
import { connect } from 'react-redux';
import AceEditor from 'react-ace';

import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/theme-twilight";

import MasterView from 'presentational/MasterView';
import DetailContainer from 'presentational/DetailContainer';
import LeftBar from 'presentational/LeftBar';

import Button from '@material-ui/core/Button';

import { getCoreHttp, putCoreHttp } from 'actions/httpRequest';
import { successNotification, errorNotification } from 'actions/notifications';

const leftBarData = [
    // {
    //     id: 'Yealink',
    //     subtitle: 'Phone Overrides for Yealink Devices',
    //     title: 'Yealink',
    // },
    {
        id: 'Polycom',
        subtitle: 'Phone Overrides for Polycom Devices',
        title: 'Polycom',
    },
    // {
    //     id: 'Mitel',
    //     subtitle: 'Phone Overrides for Mitel Devices',
    //     title: 'Mitel',
    // },
    // {
    //     id: 'Cisco',
    //     subtitle: 'Phone Overrides for Cisco Devices',
    //     title: 'Cisco',
    // },
];

class PhoneOverrides extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            manufacturer: '',
            detailContainerTitle: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.phoneOverrides != prevProps.phoneOverrides) {
            this.setState({ value: this.props.phoneOverrides });
        }
        if (this.props.phoneOverridesGetSuccess != prevProps.phoneOverridesGetSuccess) {
            this.getSuccessNotification();
        }
        if (this.props.phoneOverridesPutSuccess !== null && prevProps.phoneOverridesPutSuccess !== this.props.phoneOverridesPutSuccess) {
            this.putSuccessNotification();
        }
    }

    getSuccessNotification = () => {
        if (this.props.phoneOverridesGetSuccess) {
            successNotification({
                message: `Successfully retrieved configuration file`,
            });
        } else if (this.props.phoneOverridesGetSuccess === false) {
            errorNotification({
                time: 3,
                message: 'There is no configuration file associated with this account',
            });
        }
    }

    putSuccessNotification = () => {
        if (this.props.phoneOverridesPutSuccess) {
            successNotification({
                title: 'Phone Config File Updated!',
                message: 'Successfully updated phone configuration file',
            });
        } else {
            errorNotification({
                time: 3,
                message: 'Please contact support to enable the editability of this file',
            });
        }
    }

    fetchPhoneOverridesFileData = () => {
        const reqData = {
            reqAction: 'phoneoverrides',
            reqObject: this.state.manufacturer,
        };

        const storeKey = 'phoneOverrides';

        this.props.getCoreHttp(reqData, storeKey);
    };

    leftBarSelect = async phoneOverrideName => {
        switch (phoneOverrideName) {
            // case 'Yealink':
            //     await this.setState({
            //         manufacturer: 'yealink',
            //         value: '',
            //     });
            //     this.fetchPhoneOverridesFileData();
            //     break;
            case 'Polycom':
                await this.setState({
                    manufacturer: 'polycom',
                });
                this.fetchPhoneOverridesFileData();
                break;
            // case 'Mitel':
            //     await this.setState({
            //         manufacturer: 'mitel',
            //         value: '',
            //     });
            //     this.fetchPhoneOverridesFileData();
            //     break;
            // case 'Cisco':
            //     await this.setState({
            //         manufacturer: 'cisco',
            //         value: '',
            //     });
            //     this.fetchPhoneOverridesFileData();
            //     break;
            default:
                await this.setState({
                    manufacturer: '',
                    value: '',
                });
                break;
        }

        this.setState({
            detailContainerTitle: `Phone Overrides for ${phoneOverrideName} Devices`,
        });
    };

    onChange = newValue => {
        this.setState({ value: newValue });
    };

    onSave = newValue => {
        const reqData = {
            reqAction: 'phoneoverrides',
            reqObject: 'save',
            reqBody: { data: newValue, manufacturer: this.state.manufacturer },
        };

        const storeKey = 'phoneOverrides';

        this.props.putCoreHttp(reqData, storeKey);
    };

    render() {
        return (
            <MasterView>
                <DetailContainer
                    leftBar={
                        <LeftBar
                            data={leftBarData}
                            noOrdering={true}
                            selected={''}
                            select={id => this.leftBarSelect(id)}
                            title="Phone Overrides"
                            noAdd={true}
                        />
                    }
                    title={this.state.detailContainerTitle}
                    route={''}
                    return={() => this.setState({ route: null })}
                >
                    {this.state.manufacturer && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100%',
                                marginTop: '15px',
                            }}
                        >
                            <AceEditor
                                mode="xml"
                                theme="twilight"
                                onChange={this.onChange}
                                name="reactaceEditor"
                                showGutter={true}
                                highlightActiveLine={true}
                                editorProps={{ $blockScrolling: true }}
                                value={this.state.value}
                                setOptions={{
                                    showLineNumbers: true,
                                    tabSize: 4,
                                }}
                            />
                            {this.props.phoneOverrides && <Button
                                primary
                                onClick={() => this.onSave(this.state.value)}
                            >Save</Button>}
                        </div>
                    )}
                </DetailContainer>
            </MasterView>
        );
    }
}

const bindActions = dispatch => ({
    getCoreHttp: (reqData, storeKey) =>
        dispatch(getCoreHttp(reqData, storeKey)),
    putCoreHttp: (reqData, storeKey) =>
        dispatch(putCoreHttp(reqData, storeKey)),
});

const mapStateToProps = state => ({
    phoneOverrides: state.phoneOverrides.data,
    phoneOverridesGetSuccess: state.phoneOverrides.pending.getSuccess,
    phoneOverridesPutSuccess: state.phoneOverrides.pending.putSuccess,
    phoneOverridesEditFile: state.phoneOverrides.pending.editFile,
});

export default connect(
    mapStateToProps,
    bindActions
)(PhoneOverrides);
