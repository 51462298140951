import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';

import HelpContent from '../../presentational/helpContent';

const PhoneNumberManagerHelp = () => {
    const [mediaQuery, setMediaQuery] = useState(false);

    // componendDidMount()
    useEffect(() => {
        const mq = window.matchMedia('(min-width: 800px)');
        setMediaQuery(mq);
        mq.addListener(query => setMediaQuery(query));
    }, []);

    const minWidth = mediaQuery && mediaQuery.matches;
    const useStyles = makeStyles({
        wrapper: {
            display: 'flex',
            flex: 1,
            height: '100%',
            background: 'linear-gradient(0deg, #0F4E8B 50%, #EFEFEF 50%)',
            paddingLeft: !minWidth ? 0 : '10%',
            paddingRight: !minWidth ? 0 : '33%',
            alignItems: 'center',
        },
        helpContent: {
            padding: '1.5%',
        },
        helpPaper: {
            width: !minWidth ? '100%' : '50%',
            height: !minWidth ? '100%' : null,
            minWidth: '50%',
            position: 'absolute',
            top: '80px',
        },
        pageTitle: {
            fontWeight: '400',
        },
        helpText: {
            fontSize: '13px',
        },
        helpTextTitle: {
            color: '#0F4E8B',
            fontSize: '15px',
            padding: '0px 16px 0px 16px',
        },
        standardHelpBlurb: {
            padding: '1.5%',
            borderTop: '1px solid #E0E0E0',
            backgroundColor: '#FAFAFA',
        },
    });

    const yiptelText = 'This section of the portal allows you to manage and add new Phone Numbers.';
    const fsText = 'This section of My FluentCloud allows you to manage and add new Phone Numbers.';
    const shortCodesHelpURL = 'http://help.fluentcloud.com/support/solutions/';
    const shortCodesHelpText = 'How to manage phone numbers';
    const shortCodesAboutText = (
        <div>
        </div>
    );

    return (
        <HelpContent
            useStyles={useStyles}
            aboutText={shortCodesAboutText}
            featureName={'Phone Numbers'}
            fsHelpText={fsText}
            helpButtonText={shortCodesHelpText}
            helpURL={shortCodesHelpURL}
            yiptelHelpText={yiptelText}
        />
    );
};

export default PhoneNumberManagerHelp;
