import React, { Component } from 'react';
import { connect } from 'react-redux';

import AuditHistory from 'presentational/AuditHistory';
import Loading from 'presentational/Loading';
import StandardSettingsPage from 'presentational/StandardSettingsPage';

const styles = {
  header: {
      color: '#595959',
  }
}

const History = (props) => {
    if (!props.data) {
        return (
          <StandardSettingsPage>
            <Loading />
          </StandardSettingsPage>
        );
    }

    if (props.data.length == 0) {
      return (
        <StandardSettingsPage>
          <h2 style={styles.header}>No edit history, yet</h2>
        </StandardSettingsPage>
      )
    }

    return (
      <StandardSettingsPage>
        <AuditHistory data={props.data} />
      </StandardSettingsPage>
    );
};

export default History;
