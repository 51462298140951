import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

import Loading from 'presentational/Loading';

import { getUserPortalHttp, putUserPortalHttp } from 'actions/httpRequest';
import { errorNotification, successNotification } from 'actions/notifications';
import { resetFlag } from 'actions/callScreening';

import CallScreeningButton from './callScreeningButton';
import CallScreeningForm from './callScreeningForm';

function Screening(props) {
  const [data, setData] = useState(null);
  const [config, setConfig] = useState(null);
  const [changed, setChanged] = useState(false);

  // componentDidMount
  
  useEffect(() => {
    const reqData = {
      reqAction: 'callscreening',
    };
    props.getUserPortalHttp(reqData);
  }, []);

  
  //componentDidUpdate starts
  
  useEffect(() => {
    if (props.successMsg) {
      props.resetFlag();
      successNotification({
        title: 'Success!', message: props.successMsg,
      });
    }
  }, [props.successMsg]);

  useEffect(() => {
    if (props.errorMsg) {
      if (props.errorCode === 404) {
        props.resetFlag();
        app.navigate('#userportal', { trigger : true });
      } else {
        props.resetFlag();
        errorNotification({
          title: 'Failed!', message: props.errorMsg,
        });
      }
    }
  }, [props.errorMsg])


  useEffect(() => {
    if (props.callScreeningData) {
      const newData = cloneDeep(props.callScreeningData);

      setData(newData);
      if (!config) {
        const newConfig = cloneDeep(newData.config);
        setConfig(newConfig);
      }
    }
  }, [props.callScreeningData]);

  //componentDidUpdate ends

  const updateSettings = (data) => {
    if (data.config) {
      delete data.config;
    }

    data.id = app.token.attributes.extension;
    data.tenant = app.token.attributes.tenant;

    const reqData = {
      reqAction: 'callscreening',
      reqBody: data,
    };
    const storeKey = 'editcallscreening';

    props.putUserPortalHttp(reqData, storeKey);
    setChanged(false);
  }



  if (!data || !config) {
    return <Loading />;
  }

  const toggleScreening = () => {
    const newData = cloneDeep(data);

    if (data.csc_enabled === '1') {
      newData.csc_enabled = '0';
      updateSettings(newData);
    } else {
      newData.csc_enabled = '1';
      setChanged(true);
    }
    setData(newData);
  }

  const onOrOff = data.csc_enabled === '1' ? 'off' : 'on';
  const text = `Turn ${onOrOff} Call Screening`;
  const { recordings } = config;

  return (
    <div className='container'>
      <h1>Call Screening Options</h1>
      <h4>Settings for screening calls.</h4>
      <p />
      <CallScreeningButton text={text} toggleScreening={toggleScreening}/>
      {data.csc_enabled === '1' && <CallScreeningForm form={data} editRequest={updateSettings} recordings={recordings} changed={changed}/>}
    </div>
  )
}
const bindActions = (dispatch) => ({
  getUserPortalHttp: (reqData) => dispatch(getUserPortalHttp(reqData)),
  putUserPortalHttp: (reqData, storeKey) => dispatch(putUserPortalHttp(reqData, storeKey)),
  resetFlag: () => dispatch(resetFlag()),
});

const mapStateToProps = (state) => ({
  callScreeningData: state.callScreening.callScreeningData,
  successMsg: state.callScreening.successMsg,
  errorMsg: state.callScreening.errorMsg,
  errorCode: state.callScreening.errorCode,
});

export default connect(mapStateToProps, bindActions)(Screening);
