import {
  RESET_FLAG,
  CHANGE_FAX_FORM_DATA,
} from './typeConstants';

export const resetFlag = () => ({
  type: RESET_FLAG
});

export const changeFormData = (data) => ({
  type: CHANGE_FAX_FORM_DATA,
  payload: data,
});