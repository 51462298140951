import { RESET_FLAG, UPDATE_DO_NOT_SCREEN_NUMBER } from './typeConstants';

export const resetFlag = () => ({
  type: RESET_FLAG
})

export const updateDoNotScreenNumbers = (number) => ({
  type: UPDATE_DO_NOT_SCREEN_NUMBER,
  payload: number,
})
