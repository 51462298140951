import { REMOVE_CONFERENCE_ROOM_DETAILS, FILTER_CONFERENCE_DATA, SELECTED_CONFERENCE_DATA, RESET_FLAG, RESET_CREATED_ROOM } from '../actions/typeConstants';
import get from 'lodash.get';

const initialState = {
  conferenceRoomData: {data: []},
  pending: true,
  conferenceRoomSelected: '',
  failureFlag: null,
  successFlag: null,
  conferenceRoomDetails: null,
  createdRoom: null
}

import { parseError } from 'utils/misc';

function updatedRoomList(room, list){
  return list.map(item => {
    if(item.confno === room.confno){
      return room;
    }
    return item;
  })
}

export default function(state = initialState, action){
  switch(action.type){
    case 'CONFERENCEROOMS_HTTP_SUCCESS':
      if(action.reqObject) {
        return {...state, conferenceRoomDetails: action.data}
      }
      return {...state, conferenceRoomData: action.data, pending: false}
    case 'CONFERENCEROOMS_HTTP_FAILURE':
      return {...state, failureFlag: parseErrorparseError(action.error.response.text), successFlag: null}
    case REMOVE_CONFERENCE_ROOM_DETAILS:
      return {...state, conferenceRoomDetails: null, createdRoom: null}
    case FILTER_CONFERENCE_DATA:
      return {...state, filteredConferenceRooms: action.payload};
    case 'EDITCONFERENCEROOMS_HTTP_SUCCESS':
      return {...state, conferenceRoomDetails: action.data, successFlag: `Conference Room Updated! Succesfully Updated Conference Room:${action.reqObject}`, failureFlag: null,
      conferenceRoomData: {...state.conferenceRoomData, data: updatedRoomList(action.data, state.conferenceRoomData.data) }}
    case 'EDITCONFERENCEROOMS_HTTP_FAILURE':
      return {...state, successFlag: null, failureFlag: parseError(action.error.response.text)}
    case 'DELETECONFERENCEROOMS_HTTP_SUCCESS':
      const roomData = state.conferenceRoomData.data.filter(room => room.confno !== action.reqObject);
      return {...state, conferenceRoomData:{...state.conferenceRoomData, data: roomData} , successFlag: `Deleted ${action.reqObject}`,
        failureFlag: null, conferenceRoomSelected: ''}
    case 'DELETECONFERENCEROOMS_HTTP_FAILURE':
      return {...state, successFlag: null, failureFlag: parseError(action.error.response.text)}
    case 'CREATECONFERENCEROOMS_HTTP_SUCCESS':
      return {...state, conferenceRoomDetails: action.data, createdRoom: action.data.confno, failureFlag: null, successFlag: `Success! Created Conference Room.`, 
        conferenceRoomDetails: action.data, conferenceRoomSelected: action.data.confno, 
        conferenceRoomData: {...state.conferenceRoomData, data: [...state.conferenceRoomData.data, action.data] }}
    case 'CREATECONFERENCEROOMS_HTTP_FAILURE':
      return {...state, successFlag: null, failureFlag: parseError(action.error.response.text)}
    case SELECTED_CONFERENCE_DATA:
      if(action.payload === "new"){
        return {...state, conferenceRoomSelected: action.payload, conferenceRoomDetails: null}  
      }
      return {...state, conferenceRoomSelected: action.payload}
    case RESET_FLAG:
      return {...state, successFlag: null, failureFlag: null}
    case RESET_CREATED_ROOM:
      return {...state, createdRoom: null}
    default:
      return {...state};
  }
}