import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Button from '@material-ui/core/Button';
import makeGlobalStyles from 'makeGlobalStyles';

export default function HelpContent(props) {
  const classes = props.useStyles();
  const globalClasses = makeGlobalStyles();
  return (
    <div className={classes.wrapper}>
            <Card classes={{ root: classes.helpPaper }} elevation={1}>
              <CardContent classes={{ root: classes.helpContent }}>
                <CardContent>
                  <h2 className={classes.pageTitle}>Manage My FluentCloud Users and Logins</h2>
                  { tplHelper.get('label') =='yiptel' ?
                  <p className={classes.helpText}>
                    Use the User Manager to give your users access to the portal. You can also set permissions and reset passwords.
                  </p>
                  :
                  <p className={classes.helpText}>
                    Use the User Manager to give your users access to My FluentCloud. You can also set permissions and reset passwords.
                  </p>
                  }
                </CardContent>
                <CardContent classes={{ root: classes.helpTextTitle }}>
                  About User Management
                </CardContent>
                <CardContent>
                  <p className={classes.helpText}>User Management allows you to add, remove, and reset passwords for users of My FluentCloud.</p>
                  <p className={classes.helpText}>You may create two kinds of users - administrative and end users. Administrative users have permissions to all areas of the portal and the account. Regular users are just able to modify their own User Portal settings.</p>
                </CardContent>
              </CardContent>
              <CardContent classes={{ root: classes.standardHelpBlurb }}>
                <CardContent>
                  <h2 className={classes.helpTextTitle}>Need Help?</h2>
                  { tplHelper.get('label') =='yiptel' ?
                  <div>
                  <p className={classes.helpText}>The whole team at YipTel is ready to help you. 24 hours a day, 365 (or 366!) days a year. You can reach us a number of ways.</p>
                  <ul>
                    <li>By calling 800-752-6110</li>
                    <li>By emailing support@yiptel.com</li>
                    <li>By clicking the button below!</li>
                  </ul>
                  </div>
                  :
                  <div>
                  <p className={classes.helpText}>The whole team at FluentStream is ready to help you. 24 hours a day, 365 (or 366!) days a year. You can reach us a number of ways.</p>
                  <ul>
                    <li>By calling 303-GO-CLOUD (303-462-5683)</li>
                    <li>By emailing support@fluentstream.com</li>
                    <li>By clicking the button below!</li>
                  </ul>
                  </div>
                  }
                  {/*<FlatButton
                    backgroundColor="#0F4E8B"
                    label="Get help from FluentStream"
                    labelStyle={{ color: "#FFF" }}
                    href="#supportCallModal"
                  />*/}
                  <p />
                  <Button
                    size='large'
                    classes={{ root: globalClasses.helpButton }}
                    href="http://help.fluentcloud.com/support/solutions/articles/4000011015-how-to-create-or-edit-user-credentials-cloud-vs-admin-user"
                    target="_blank"
                  >How to manage Users and Logins</Button>
                </CardContent>
              </CardContent>
            </Card>
          </div>
  );
}