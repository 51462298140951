import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from '@material-ui/core/styles';

import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import PauseIcon from '@material-ui/icons/Pause';
import PlayIcon from '@material-ui/icons/PlayArrow';

class AudioPlayerInput extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentlyPlaying: false,
            audio: null,
        };
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.value != this.props.value && this.state.audio) {
            this.state.audio.pause();
            this.setState({ audio: null, currentlyPlaying: false });
        }
    }

    pauseRecording() {

        this.setState({ currentlyPlaying: false });
        this.state.audio.pause();
    }

    playRecording(playUrl) {

        const audio = new Audio(playUrl);

        this.setState({ audio, currentlyPlaying: true });
        audio.load();
        audio.onloadeddata = audio.play();
        audio.onended = () => this.setState({ audio: null, currentlyPlaying: false });
    }

    async fetchRecording(shouldPlayRecording=true) {
      const routeDetailsArray = this.routeBuilder();

      const playURL = await remoteResourceInterface({
        nameSpace: routeDetailsArray[0],
        route: routeDetailsArray[1],
        reqObject: `${this.props.value}.wav`,
        fileType: "audio/wav",
      });
      if(!playURL){
        console.warn("[[WARN] audioplayer.fetchrecording.fail] playURL is incorrect")
        return false;
      }
      if(shouldPlayRecording){
        this.playRecording(playURL);
      } else {
        return playURL;
      }
    }

    routeBuilder(){
      console.log(`[[INFO] audioplayerinput.routebuilder] this.props.recordingUrl: ${this.props.recordingUrl}`)
      var recordingUrl = this.props.recordingUrl;

      if(recordingUrl.startsWith('/')){
        recordingUrl = recordingUrl.substring(1);
      }
      const urlArray = recordingUrl.split("/");
      const namespace = urlArray[2];
      let route;
      if (namespace === "userportal") {
        route = urlArray[5];
      }else if(namespace === "core" || namespace ==="reporting"){
        route = urlArray[4];
      }
      
      return [namespace, route]; 
       
    }

    render() {
        const { classes } = this.props;
        return (
          <div className={classes.wrapper}>
            <FormControl classes={{ root: classes.select }} error={this.props.errorText ? true : false} fullWidth={true}>
              <InputLabel>{this.props.placeholder}</InputLabel>
              <Select
                id={`slider_input_${this.props.field}`}
                name={this.props.field}
                onChange={(e, i, text) => this.props.onChange(e.target.value)}
                value={this.props.value || ''}
                multiple={this.props.multiple}
              >
                {this.props.options.map((option) => {
                  // If we have a property of value, but no value or label,
                  // we don't want to use this option
                  if (option.hasOwnProperty('value') && (!option.value || !option.label)) {
                    return null;
                  }

                  if (option.hasOwnProperty('value') && option.value && option.label) {
                    return (
                      <MenuItem
                        value={option.value}
                        classes={{ root: classes.menuItem }}
                        key={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  }

                  return (
                    <MenuItem
                      value={option}
                      classes={{ root: classes.menuItem }}
                      key={option}
                    >
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{this.props.errorText}</FormHelperText>
            </FormControl>

            {!this.state.currentlyPlaying ?
              <IconButton
                disabled={!this.props.value}
                classes={{ root: classes.iconButton }}
                onClick={() => this.fetchRecording()}
              >
                <PlayIcon classes={{ root: classes.playIcon }} />
              </IconButton>
            :
              <IconButton
                classes={{ root: classes.iconButton }}
                onClick={() => this.pauseRecording()}
              >
                <PauseIcon classes={{ root: classes.pauseIcon }} />
              </IconButton>
            }
          </div>
        );
    }
}

const style = () => ({
    loadingRecording: {
        bottom: 12,
        marginLeft: 22,
    },
    wrapper: {
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 30,
    },
    textfield: {
        // height: 40,
    },
    playIcon: {
        width: 28,
        height: 28,
        color: '#0F4E8B',
    },
    pauseIcon: {
        width: 26,
        height: 26,
        color: '#0F4E8B',
    },
    iconButton: {
        bottom: -5,
        marginLeft: 10,
        height: 48,
        width: 48,
        '&:hover': {
          backgroundColor: 'transparent',
        },
    },
    menuItem: {
      fontSize: '100%'
    },
    select: {
      width: '60%',
    }
});

AudioPlayerInput.defaultProps = {
    errorText: '',
    hintText: '',
    placeholder: '',
    onChange: () => console.log('No onChange func provided for textinput'),
    options: [{ label: 'No value', value: '' }],
    recordingUrl: '',
};

AudioPlayerInput.propTypes = {
    errorText: PropTypes.string,
    field: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    recordingUrl: PropTypes.string,
    options: PropTypes.array,
};

export default withStyles(style)(AudioPlayerInput);
