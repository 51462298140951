import {
  USERSETTINGS_HTTP_SUCCESS,
  USERSETTINGS_HTTP_FAILURE,
  EDITUSERSETTINGS_HTTP_SUCCESS,
  EDITUSERSETTINGS_HTTP_FAILURE,
  RESET_FLAG,
} from 'actions/typeConstants';

import { parseError } from 'utils/misc';

const initialState = {
  userSettingsData: null,
  succesMsg: null,
  errorMsg: null,
  errorCode: null,
}

export default function(state = initialState, action) {
  switch(action.type) {
    case USERSETTINGS_HTTP_SUCCESS:
      return { ...state, userSettingsData: action.data, errorMsg: null, errorCode: null }
    case USERSETTINGS_HTTP_FAILURE:
      return { ...state, errorMsg: parseError(action.error.response.text), successMsg: null, errorCode: action.error.response.status }
    case EDITUSERSETTINGS_HTTP_SUCCESS:
      return { ...state, userSettingsData: action.data, successMsg: 'Saved!', errorMsg: null}
    case EDITUSERSETTINGS_HTTP_FAILURE:
      return { ...state, errorMsg: parseError(action.error.response.text), successMsg: null, errorCode: action.error.response.status }
    case RESET_FLAG:
      return {...state, successMsg: null, errorMsg: null, errorCode: null}
    default:
      return { ...state }
  }
}