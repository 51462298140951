import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReportContainer from 'presentational/reports/ReportContainer';
import ReportCard from 'presentational/reports/ReportCard';
import ReportContainerToolbar from 'presentational/reports/ReportContainerToolbar';
import UserCallSummary from 'presentational/reports/UserCallSummary';
import { reportsConfig } from '../../reportsConfig';
import { getReportsHttp, getUserPortalHttp } from 'actions/httpRequest';
import {
    searchInput,
    changeFilterDateTime,
    changeFilterDirections,
    changeFilterCallTypes,
    changeFilterExtensions,
    changeFilterDispositions,
    changeFilterAgents,
    changeFilterDurations,
    changeFilterPhoneNumbers,
    changeFilterTags,
    fetchRecordings,
} from 'actions/reportsConfigurations';

import { formatDateTime, formatDateTimePicker } from 'utils/misc';

const defaultFilters = {
    duration: [],
    direction: [],
    callTypes: [],
    phoneNumbers: [],
    tags: [],
    dateTimes: 'today',
    searchTerms: '',
};

class CallDetailRecords extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mediaQuery: false,
            callDetailCards: [],
            currentPage: 1,
            reqQuery: null,
            timeInterval: '',
            startDate: '',
            endDate: '',
            allExtensionFilter: false,
            displayAllRecordings: this.props.token.reports_multi_recording == "1" ? true : false,
        };
    }

    componentDidMount() {
        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));

        if (!this.props.reportsData) {
            this.resetFilters();
            this.props.changeFilterDateTime('today');
            this.fetchReportData();
            this.fetchCallSummaryData();
            this.getTags();
        }
    }

    componentWillUnmount(){
        this.resetFilters();
        this.buildReqQuery(true);
    }

    resetFilters() {
        let allExtensionFilter = false;
        this.setState({allExtensionFilter});

        this.changeDateTimeInverval('today');
        this.changeDirectionFilter(defaultFilters.direction);
        this.changeCallTypeFilter([]);
        this.changeExtensionsFilter([]);
        this.changeDispositionsFilter([]);
        this.changePhoneNumbersFilter([]);
        this.setDurations([]);
        this.searchInput('');

        this.filterData(true);
    }

    buildReqQuery(reset) {
        let minDuration = 0;
        let maxDuration = 999999;
        let searchTerms = this.props.searchTerms;
        let dateTimes = this.props.dateTimes;
        let directions = this.props.directions;
        let callTypes = this.props.callTypes;
        let extensions = this.props.extensions;
        let dispositions = this.props.dispositions;
        let phoneNumbers = this.props.phoneNumbers;
        let durations = this.props.durations;
        let tags = this.props.allTags;
        let summaryTimeInterval = '';
        const startDate = this.state.startDate;
        const endDate = this.state.endDate || moment().endOf('day').format('MM DD YYYY hh:mm a');
        const queryEndDate = this.state.endDate || moment().endOf('day').format('MM DD YYYY hh:mm');

        if (durations && durations.min && durations.min.length) {
            minDuration = durations.min;
        }

        if (durations && durations.max && durations.max.length) {
            maxDuration = durations.max;
        }

        if (reset) {

            searchTerms = defaultFilters.searchTerms;
            dateTimes = defaultFilters.dateTimes;
            directions = defaultFilters.directions;
            callTypes = defaultFilters.callTypes;
            extensions = defaultFilters.extensions;
            dispositions = defaultFilters.dispositions;
            phoneNumbers = defaultFilters.phoneNumbers;
            durations = defaultFilters.durations;
            tags = defaultFilters.tags;
        }

        let query = '';

        if (searchTerms) {
            query += `&fuzzysearch=${searchTerms.replace(/\D/g,'')}`;
        } else if (searchTerms < 1) {
            query = '';
        }
        if (dateTimes == 'custom' && startDate.length && endDate.length) {
            query += `&startdate=${moment(startDate).format('YYYY-MM-DD HH:mm:ss')}`;
            query += `&enddate=${moment(queryEndDate).format('YYYY-MM-DD HH:mm:ss')}`;
            this.setState({ currentPage: 1 });
            this.state.endDate ?
            summaryTimeInterval = `${formatDateTime(startDate)} to ${formatDateTime(endDate)}` :
            summaryTimeInterval = `${formatDateTime(startDate)} to ${endDate}`;
        } else if (dateTimes && dateTimes.length > 0) {
            this.setState({ timeInterval: dateTimes });
            this.setState({ currentPage: 1 });
            query += `&timeinterval=${dateTimes}`;
        }
        if (directions && directions.length > 0) {
            query += `&direction=${directions.join(',')}`;
        }
        if (callTypes && callTypes.length > 0) {
            query += `&calltype=${callTypes.join(',')}`;
        }
        if (extensions && extensions.length > 0) {
            const extensionsArr = extensions.map(extension => extension.split('-')[0]);
            query += `&extension=${extensionsArr.join(',')}`;
        }
        if (dispositions && dispositions.length > 0) {

            let dispositionsArr = [];
            dispositions.forEach(disposition =>{
                if(disposition == "Answer"){
                    dispositionsArr.push("ANSWERED")
                } else if (disposition == "No Answer"){
                    dispositionsArr.push("NO ANSWER")
                }
            });
            query += `&disposition=${dispositionsArr.join(',')}`;
        }
        if (phoneNumbers && phoneNumbers.length > 0) {
            const phoneNumbersArr = phoneNumbers.map(phonenumber => phonenumber.value);
            query += `&phonenumber=${phoneNumbers.join(',')}`;
        }

        if (minDuration && minDuration.length > 0) {
            const { minutes, seconds } = this.breakUpTime(minDuration);
            if(minutes) {
                query += `&minminutes=${minutes}`;
            }
            if(seconds) {
                query += `&minseconds=${seconds}`;
            }
        }
        if (maxDuration && maxDuration.length > 0) {
            const { minutes, seconds } = this.breakUpTime(maxDuration);
            if(minutes) {
                query += `&maxminutes=${minutes}`;
            }
            if(seconds) {
                query += `&maxseconds=${seconds}`;
            }
        }

        this.setState({ reqQuery: query });
        return query;
    }

    // The duration is specified by a float "1.1".  However the API execepts two integers
    // minutes, and seconds.  So, I need to break the number into its parts, and then 
    // calculate the number of seconds. Again, the seconds need to be a whole number.
    breakUpTime(duration) {
        const parts = duration.split('.');
        let minutes = undefined;
        let seconds = undefined;
        
        if(parts.length >= 1 && Number.isInteger(Number(parts[0]))) {
            minutes = parts[0];
        }

        if(parts.length === 2 && Number.isInteger(Number(parts[1]))) {
            const fraction = Number(`.${parts[1]}`);
            seconds = Number(60 * fraction).toFixed();
        }

        return {minutes, seconds};
    }

    fetchReportData(currentPage, reset) {

        // Lets set up the request data to retreive auto attendants
        const reqData = {
            reqAction: 'calldetailrecords',
            reportPage: currentPage || this.state.currentPage,
            reqQuery: this.buildReqQuery(reset),
            reportName: 'cdr',
        };

        const storeKey = 'reportsData';

        this.props.getReportsHttp(reqData, storeKey);
    }

    async fetchCallSummaryData(currentPage, reset) {

        // Lets set up the request data to retreive auto attendants
        const reqData = {
            reqAction: 'usercallsummary',
            reportPage: currentPage || this.state.currentPage,
            reqQuery: this.buildReqQuery(reset),
            reportName: 'callSummary',
        };

        const storeKey = 'reportsData';
        this.props.getReportsHttp(reqData, storeKey);
    }


    fetchSpecificCallRecord(callid, extension) {

        const reqData = {
            reqAction: 'calldetailrecords',
            reqObject: callid,
            reqQuery: `timeinterval=${this.props.dateTimes}`,
            reportName: 'cdr',
            reqObject3: extension || '',
        };

        const storeKey = 'ladder';

        this.props.getReportsHttp(reqData, storeKey);
    }

    getTags(){
        const reqData = {
            reqAction: 'tags',
        };

        const storeKey = 'allTags';

        this.props.getUserPortalHttp(reqData, storeKey);
    }

    // Get selected row index and push index to state to render ladder card
    getRowSelect(linkedid) {

        this.fetchSpecificCallRecord(linkedid);
        const foundIndex = this.props.reportData.findIndex((obj) => obj.linkedid === linkedid);
        const callDetailCards = Object.assign([], this.state.callDetailCards);
        const existingIndex = callDetailCards.indexOf(foundIndex);

        if (existingIndex === -1) {
            callDetailCards.push(foundIndex);
            this.setState({ callDetailCards });
        }
    }

    // Here we will delete the ladder card
    removeLadderCard(linkedid) {

        const foundIndex = this.props.reportData.findIndex((obj) => obj.linkedid === linkedid);
        const callDetailCards = Object.assign([], this.state.callDetailCards);
        const existingIndex = callDetailCards.indexOf(foundIndex);
        callDetailCards.splice(existingIndex, 1);
        this.setState({ callDetailCards });
    }

    handleLoadMore() {

        if (!this.props.reportMetaData) {

            return;
        }

        const currentPage = this.state.currentPage + 1;
        let loadedRecords = this.state.currentPage * 50;
        // Prevent creating another API call if all records are loaded
        if (loadedRecords >= this.props.reportMetaData.totalrecords) {
            loadedRecords = this.props.reportMetaData.totalrecords;
        }
        if (loadedRecords != this.props.reportMetaData.totalrecords) {
            this.setState({ currentPage });
            this.fetchReportData(currentPage);
        }
    }

    totalRecords() {

        if (!this.props.reportMetaData) {

            return;
        }

        return this.props.reportMetaData.totalrecords;

        let loadedRecords = this.state.currentPage * 50;
        if (loadedRecords > this.props.reportMetaData.totalrecords) {
            loadedRecords = this.props.reportMetaData.totalrecords;
        }
        if (!this.props.reportData || !this.props.reportMetaData) {
            loadedRecords = 0;
        }
        return loadedRecords;
    }

    changeDateTimeInverval(dateTime) {

        this.props.changeFilterDateTime(dateTime);
    }

    changeDirectionFilter(direction) {
        this.props.changeFilterDirections(direction);
    }

    changeCallTypeFilter(callType) {

        this.props.changeFilterCallTypes(callType);
    }

    changeExtensionsFilter(extension, allExtensions) {
        let found;
        let filteredExtensions = [];
        found = extension.findIndex(obj => obj.label == 'All');
        if(allExtensions){
            let allExtensionFilter = true;
            this.setState({ allExtensionFilter });
            extension.forEach((obj, index) => {if(index !== found){filteredExtensions.push(obj)}});
            this.props.changeFilterExtensions(filteredExtensions);
        } else {
            let allExtensionFilter = false;
            this.setState({ allExtensionFilter });
            this.props.changeFilterExtensions(extension);
        }
    }

    changeDispositionsFilter(disposition) {

        this.props.changeFilterDispositions(disposition);
    }

    changePhoneNumbersFilter(phoneNumber) {

        this.props.changeFilterPhoneNumbers(phoneNumber);
    }

    // Change the ReportDates filter option
    async changeDateTimeInverval(dateTime) {
        if (dateTime != 'custom') {
            this.setState({ startDate: null });
            this.setState({ endDate: null });
            this.setState({ timeinterval: dateTime });
            await this.props.changeFilterDateTime(dateTime);
            this.filterData();
        } else {
            this.props.changeFilterDateTime(dateTime);
            this.resetStartEndDate();
        }
    }

    // Change the custom startdate
    async changeStartDate(startDate) {
        const startD = formatDateTimePicker(startDate);
        await this.setState({ startDate: startD });
        this.filterData();
    }

    // change the custom endDate
    async changeEndDate(endDate) {
        const endD = formatDateTimePicker(endDate);
        await this.setState({ endDate: endD });
        this.filterData();
    }

    // Reset the custom time state to today when switching from non custom intervals
    resetStartEndDate() {
        this.setState({ startDate: moment().startOf('day').format('MM DD YYYY hh:mm a') });
        this.setState({ endDate: moment().endOf('day').format('MM DD YYYY hh:mm a') });
    }


    changeDispositionsFilter(disposition) {

        this.props.changeFilterDispositions(disposition);
    }

    async setDurations(duration) {
        await this.props.changeFilterDurations(duration)
    }


    async searchInput(searchTerm) {

        await this.props.searchInput(searchTerm);
        this.filterData();
    }

    filterData(reset) {

        this.fetchReportData(null, reset);
        this.fetchCallSummaryData(null, reset);

    }

    render() {

        const colHeaders = reportsConfig.colHeaders;
        const ladderColHeaders = reportsConfig.ladderColHeaders;
        const colWidth = reportsConfig.colWidth;
        const ladderColWidth = reportsConfig.ladderColWidth;
        const gridConfig = reportsConfig.gridConfig;
        const gridConfigChild = reportsConfig.gridConfigChild;

        const selectedIndexes = Object.assign([], this.state.callDetailCards);

        const {
            configuration,
            configurations,
            dateTimes,
            reportData,
            reportMetaData,
            directions,
            callTypes,
            durations,
            extensions,
            dispositions,
            phoneNumbers,
            tags,
            searchTerms,
            allTags
        } = this.props;

        let minDuration = '';
        let maxDuration= '';
        if (durations && durations.length && durations.min && durations.min.length) {
            minDuration = durations.min;
        }

        if (durations && durations.length && durations.max && durations.max.length) {
            maxDuration = durations.max;
        }

        return (
          <ReportContainer title={configuration.friendlyname || configuration.reportname} >
            <ReportContainerToolbar
              timeinterval={dateTimes}
              configurations={configurations}
              allExtensionFilter={this.state.allExtensionFilter}
              allTags={allTags}
              onChangeDateTimeInterval={(dateTime) => this.changeDateTimeInverval(dateTime)}
              onChangeDirectionsFilter={(direction) => this.changeDirectionFilter(direction)}
              onChangeCallTypesFilter={(callType) => this.changeCallTypeFilter(callType)}
              onChangeExtensionsFilter={(extension, allExtensionFilter) => this.changeExtensionsFilter(extension, allExtensionFilter)}
              onChangeDispositionsFilter={(disposition) => this.changeDispositionsFilter(disposition)}
              onChangePhoneNumbersFilter={(phoneNumber) => this.changePhoneNumbersFilter(phoneNumber)}
              changeStartDate={(startDate) => this.changeStartDate(startDate)}
              changeEndDate={(endDate) => this.changeEndDate(endDate)}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              setDuration={(duration) => this.setDurations(duration)}
              filterDirections={directions}
              filterCallTypes={callTypes}
              filterExtensions={extensions}
              filterDispositions={dispositions}
              filterPhoneNumbers={phoneNumbers}
              filterMinDuration={minDuration}
              filterMaxDuration={maxDuration}
              filterTags ={tags}
              filterData={() => this.filterData()}
              resetFilters={() => this.resetFilters()}
              searchInput={(searchTerm) => this.searchInput(searchTerm)}
              searchTerms={searchTerms || ''}
              token={this.props.token}
              loading={this.props.reportDataPending || this.props.userCallSummaryPending}
            />
            <UserCallSummary
              currentlyLoading={this.props.userCallSummaryPending}
              data={this.props.callSummaryData}
              reportDate={this.state.summaryTimeInterval}
              title={`Extension Call Summary`}
            />
            <ReportCard
              colHeaders={colHeaders}
              colWidth={colWidth}
              currentlyLoading={this.props.reportDataPending}
              data={reportData}
              metaData={reportMetaData}
              gridConfig={gridConfig}
              handleLoadMore={() => this.handleLoadMore()}
              fetchSpecificCallRecord={(linkedid, extension) => this.fetchSpecificCallRecord(linkedid, extension)}
              handleRowSelect={(linkedid) => this.getRowSelect(linkedid)}
              loadedRecords={() => this.totalRecords()}
              reqQuery={this.state.reqQuery}
              selectedIndexes={selectedIndexes}
              renderAllRecordings={this.state.displayAllRecordings}
            />
          </ReportContainer>
        );
    }
}

const bindActions = (dispatch) => ({
    getReportsHttp: (reqData, storeKey) => dispatch(getReportsHttp(reqData, storeKey)),
    getUserPortalHttp: (reqData, storeKey) => dispatch(getUserPortalHttp(reqData, storeKey)),
    searchInput: (searchTerm) => dispatch(searchInput(searchTerm)),
    changeFilterDateTime: (dateTimes) => dispatch(changeFilterDateTime(dateTimes)),
    changeFilterDirections: (directions) => dispatch(changeFilterDirections(directions)),
    changeFilterCallTypes: (callTypes) => dispatch(changeFilterCallTypes(callTypes)),
    changeFilterExtensions: (extensionNumbers) => dispatch(changeFilterExtensions(extensionNumbers)),
    changeFilterDispositions: (dispositions) => dispatch(changeFilterDispositions(dispositions)),
    changeFilterPhoneNumbers: (phoneNumbers) => dispatch(changeFilterPhoneNumbers(phoneNumbers)),
    changeFilterTags: (tags) => dispatch(changeFilterTags(tags)),
    changeFilterAgents: (agents) => dispatch(changeFilterAgents(agents)),
    changeFilterDurations: (durations) => dispatch(changeFilterDurations(durations)),
    fetchRecordings:() => dispatch(fetchRecordings()),
});

const mapStateToProps = (state) => ({
    userCallSummaryPending: state.reportsData.callSummary ? state.reportsData.callSummary.pending : true,
    reportData: state.reportsData.cdr ? state.reportsData.cdr.data : null,
    reportDataPending: state.reportsData.cdr ? state.reportsData.cdr.pending : false,
    callSummaryData: state.reportsData.callSummary ? state.reportsData.callSummary.data : null,
    allReports: state.reportsData,
    allTags: state.http.tags ? state.http.tags : null,
    configurations: state.http.configurations,
    searchTerms: state.reportsConfigurations.searchTerms ? state.reportsConfigurations.searchTerms : null,
    dateTimes: state.reportsConfigurations.dateTimes ? state.reportsConfigurations.dateTimes : null,
    directions: state.reportsConfigurations.directions ? state.reportsConfigurations.directions : null,
    callTypes: state.reportsConfigurations.callTypes ? state.reportsConfigurations.callTypes : null,
    extensions: state.reportsConfigurations.extensions ? state.reportsConfigurations.extensions : null,
    dispositions: state.reportsConfigurations.dispositions ? state.reportsConfigurations.dispositions : null,
    phoneNumbers: state.reportsConfigurations.phoneNumbers ? state.reportsConfigurations.phoneNumbers : null,
    agents: state.reportsConfigurations.agents ? state.reportsConfigurations.agents : null,
    durations: state.reportsConfigurations.durations ? state.reportsConfigurations.durations : null,
    reportMetaData: state.reportsData.cdr ? state.reportsData.cdr.metadata : null,
    token: state.token,
});

export default connect(mapStateToProps, bindActions)(CallDetailRecords);
