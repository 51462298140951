import React, { Component } from 'react';
import PropTypes from 'prop-types';

class StandardDetailsPage extends Component {

    constructor(props) {
        super(props);
        this.state = { mediaQuery: false };
    }

    componentDidMount() {

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
    }

    render() {
        const minWidth = this.state.mediaQuery.matches;

        const style = {
            wrapper: {
                display: 'flex',
                flexDirection: minWidth ? 'row' : 'column',
                alignItems: minWidth ? 'center' : 'stretch',
                justifyContent: minWidth ? 'center' : 'stretch',
                flex: 1,
                height: '100%',
                background: minWidth ? 'linear-gradient(0deg, #0F4E8B 50%, #EFEFEF 50%)' : '#EFEFEF',
                paddingLeft: minWidth ? '1vw' : 0,
                alignItems: 'center',
            },
        };  

        return (
          <div style={style.wrapper}>
            {this.props.cards.map((card) => card)}
          </div>
        );
    }
}

StandardDetailsPage.defaultProps = {
    cards: [],
};

StandardDetailsPage.propTypes = {
    cards: PropTypes.array,
};

export default StandardDetailsPage;
