/*
    This component is used as the context provider for the Short Codes components.
    It holds the shared state and functions for the different Short Codes components. If the component 
    has state that only it cares about, it will be handled within that component.  
*/
import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import useShortCodesValidation from './shortCodesValidationObj';
import useExtensionCheck from '../../utils/extensionCheck';
import useShortCodesActions from './useShortCodesActions';
import { successNotification, errorNotification } from 'actions/notifications';

const reqAction = 'shortcodes';
const createStoreKey = 'createshortcodes';
const editStoreKey = 'editshortcodes';
const ShortCodesContext = createContext();

const ShortCodesProvider = props => {
    // redux state
    const createdCode = useSelector(state => state.shortCodes.createdCode, shallowEqual);
    const routeParams = useSelector(state => state.navigation.params, shallowEqual);
    const shortCodesData = useSelector(state => state.shortCodes.shortCodesData, shallowEqual);
    const successFlag = useSelector(state => state.shortCodes.successFlag, shallowEqual);
    const failureFlag = useSelector(state => state.shortCodes.failureFlag, shallowEqual);
    const shortCodeSelected = useSelector(
        state => state.shortCodes.shortCodeSelected,
        shallowEqual,
    );
    // state
    const [countryCodeState, setCountryCode] = useState();
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [leftBarData, setLeftBarData] = useState(null);
    const [oldForm, setOldForm] = useState(null);
    const [route, setRoute] = useState(routeParams ? routeParams.code : null);
    const [selectedCode, setSelectedCode] = useState(null);
    const [shortCodeDetails, setDetails] = useState(null);
    const [shortCodesForm, setShortCodesForm] = useState({
        codeNumber: '',
        name: '',
        forwardingNumber: '1',
    });

    // redux actions
    const {
        getCoreHttpHook,
        postCoreHttpHook,
        putCoreHttpHook,
        resetFlag,
    } = useShortCodesActions();

    const {
        checkN11numbers,
        findCallGroupExtension,
        findConferenceRoom,
        findExt,
        findParkingLot,
        findQueueExtension,
    } = useExtensionCheck();

    const { errorObj, setButtonDisabled, buttonDisabled } = useShortCodesValidation();

    const bulkInputRef = useRef();

    // componentDidMount
    useEffect(() => {
        const reqData = { reqAction };
        getCoreHttpHook(reqData);
    }, []);

    // componentDidUpdates

    const setTheForms = details => {
        setShortCodesForm({
            codeNumber: `${details.codeNumber.replace(/[^0-9]/g, '')}`,
            name: details.name,
            forwardingNumber: `${details.forwardingNumber}`,
        });
        setOldForm({
            codeNumber: `${details.codeNumber.replace(/[^0-9]/g, '')}`,
            name: details.name,
            forwardingNumber: `${details.forwardingNumber}`,
        });
    };
    // used for populating the form
    useEffect(() => {
        if (shortCodeDetails) {
            setTheForms(shortCodeDetails);
        }
    }, [shortCodeDetails]);

    useEffect(() => {
        if (createdCode && route === 'new') {
            setRoute(createdCode.codeNumber);
            setSelectedCode(createdCode.codeNumber);
            prepareDataForLeftBar(shortCodesData);
        }
    }, [createdCode]);

    const findCodeByNumber = number => {
        return shortCodesData.filter(code => code.codeNumber == number)[0];
    };

    useEffect(() => {
        if (shortCodesData) {
            prepareDataForLeftBar(shortCodesData);
            const currentCode = findCodeByNumber(route);
            if (currentCode) {
                setTheForms(currentCode);
                setDetails(currentCode);
                setSelectedCode(currentCode.codeNumber);
            }
        }
    }, [shortCodesData]);

    useEffect(() => {
        if (successFlag) {
            resetFlag();
            successNotification({
                title: 'Success!',
                message: successFlag,
            });
            if (successFlag == 'Success! Bulk uploaded codes.') {
                if (failureFlag) {
                    errorNotification({
                        dismiss: false,
                        title: 'Failed!',
                        message: failureFlag,
                    });
                    return;
                }
                app.navigate(`shortcodes`, { trigger: true });
            }
            if (route == 'new' && shortCodeSelected) {
                app.navigate(`shortcodes/${shortCodeSelected}`, { trigger: true });
            }
        }
    }, [successFlag]);

    useEffect(() => {
        if (failureFlag) {
            resetFlag();
            errorNotification({
                dismiss: false,
                title: 'Failed!',
                message: failureFlag,
            });
            // If we get an error on all of the bulkUpload data, let's reset the input
            if (route === 'bulkupload') {
                bulkInputRef.current.value = null;
            }
        }
    }, [failureFlag]);

    // This function allows us to set all the states we need to navigate without calling a get request and re-rendering the component
    const navWithoutRequest = id => {
        const foundCodeObj = findCodeByNumber(id);
        setTheForms(foundCodeObj);
        setSelectedCode(id);
        setDetails(foundCodeObj);
        setRoute(id);
    };

    // unsaved changes modal functions
    const discardChange = () => {
        const id = unsavedChanges;
        if (id === selectedCode) {
            if (id == 'new') {
                setUnsavedChanges(false);
                setButtonDisabled(true);
                return;
            }
            setUnsavedChanges(false);
            setButtonDisabled(true);
            setRoute(id);
            setShortCodesForm(shortCodeDetails);
        } else {
            setUnsavedChanges(false);
            setButtonDisabled(true);
            // away is used when the user clickes the header
            if (id == 'away') {
                setShortCodesForm(oldForm);
                return;
            } else if (id == 'back') {
                setShortCodesForm(oldForm);
                app.navigate(`shortcodes`, { trigger: true });
            } else {
                navWithoutRequest(id);
                app.navigate(`shortcodes/${id}`, { trigger: false });
            }
        }
    };

    const select = id => {
        if (route && !buttonDisabled) {
            setUnsavedChanges(id);
            return;
        }
        if (id === selectedCode) {
            setRoute(id);
        } else {
            navWithoutRequest(id);
            app.navigate(`shortcodes/${id}`, { trigger: false });
        }
    };

    const saveChange = () => {
        const id = unsavedChanges;
        if (id === selectedCode) {
            submitFullForm();
            setUnsavedChanges(false);
        } else {
            setUnsavedChanges(false);
            submitFullForm(false, false);
            if (id == 'away') {
                return;
            } else if (id == 'back') {
                app.navigate(`shortcodes`, { trigger: true });
                return;
            }
            navWithoutRequest(id);
            app.navigate(`shortcodes/${id}`, { trigger: false });
        }
    };

    // format the data for the left bar
    const prepareDataForLeftBar = data => {
        if (!data) {
            return console.warn('No Codes to prepare');
        }
        const leftBarDataArray = [];
        data.map(item => {
            const title = item.codeNumber;
            const subtitle = item.name;
            const id = item.codeNumber;
            return leftBarDataArray.push({ id, title, subtitle });
        });
        setLeftBarData(null);
        setLeftBarData(leftBarDataArray);
    };

    // footer cancel button function
    const onCancel = () => {
        app.navigate(`shortcodes`, { trigger: true });
    };

    const createNewCode = async data => {
        const reqData = {
            reqAction,
            reqBody: data,
        };
        await postCoreHttpHook(reqData, createStoreKey);
        return data;
    };

    const editCode = async data => {
        const reqData = {
            reqAction,
            reqObject: selectedCode,
            reqBody: data,
        };

        await putCoreHttpHook(reqData, editStoreKey);
    };

    /*
        The validateValues function uses the error object in shortCodesValidationObj.js. This function
        can be used to check a single short code, or a list of short codes (in the case of a bulk upload). 
        Because of this versatility, the 'single' value is used to throw a notification error, and the iterating
        value is used inside of a reduce function. 
    */

    const checkCurrentCodes = (numberToCheck, shortCodesData) => {
        return shortCodesData.find(code => code.codeNumber == numberToCheck);
    };

    const duplicateKeyCheck = (accumulator, index, codeNumber) => {
        if (accumulator[codeNumber]) {
            return `Duplicate Code Number ${codeNumber} on row ${index + 1} of the CSV was found.`;
        } else {
            return codeNumber;
        }
    }

    const validateValues = (accumulator, form, iterating = undefined, navigate = true) => {
        const pattern = /[!@#$%^&*()+=\[\]{};':"\\|,.<>\/?]/;
        const {
            callGroup,
            codeLength,
            conferenceRoom,
            currentCode,
            extension,
            forwardingNumCountryCode,
            forwardingNumNumber,
            n11Nums,
            numberCheck,
            nameLength,
            parkingLot,
            queueExt,
            specialCharacters,
            undefinedFcn,
        } = errorObj;

        const { codeNumber, name, forwardingNumber } = form;
        const findLikeExtension = findExt(codeNumber);
        const findLikeConfrenceRoom = findConferenceRoom(codeNumber);
        const findLikeParkingLot = findParkingLot(codeNumber);
        const findLikeQueueExt = findQueueExtension(codeNumber);
        const findLikeCallGroupExt = findCallGroupExtension(codeNumber);
        const findLikeCurrentCode = checkCurrentCodes(codeNumber, shortCodesData);
        const findN11numbers = checkN11numbers(codeNumber);
        const strippedForwardingNum = forwardingNumber.replace(/[^0-9]/g, '');
        form.prevCodeNum = selectedCode;
        // Are the fields filled out?
        if (!codeNumber || !name || forwardingNumber == '1') {
            if (iterating >= 0) {
                const row = undefinedFcn.iterating(form);
                const key = duplicateKeyCheck(accumulator, iterating, 'undefined');
                accumulator[key] = row;
                return accumulator;
            } else {
                undefinedFcn.single();
                return;
            }
        }
        // Are there any extensions with the same number?
        if (findLikeExtension) {
            if (iterating >= 0) {
                const row = extension.iterating(findLikeExtension, form);
                const key = duplicateKeyCheck(accumulator, iterating, codeNumber);
                accumulator[key] = row;
                return accumulator;
            } else {
                extension.single(codeNumber);
                return;
            }
        }
        // Are there any Conference Rooms with the same number?
        if (findLikeConfrenceRoom) {
            if (iterating >= 0) {
                const row = conferenceRoom.iterating(findLikeConfrenceRoom, form);
                const key = duplicateKeyCheck(accumulator, iterating, codeNumber);
                accumulator[key] = row;
                return accumulator;
            } else {
                conferenceRoom.single(findLikeConfrenceRoom);
                return;
            }
        }
        // Does the number interfere with any parking lots?
        if (findLikeParkingLot) {
            if (iterating >= 0) {
                const row = parkingLot.iterating(codeNumber, form);
                const key = duplicateKeyCheck(accumulator, iterating, codeNumber);
                accumulator[key] = row;
                return accumulator;
            } else {
                parkingLot.single(codeNumber);
                return;
            }
        }
        // Are there any Queue Extensions with the same number?
        if (findLikeQueueExt) {
            if (iterating >= 0) {
                const row = queueExt.iterating(findLikeQueueExt, form);
                const key = duplicateKeyCheck(accumulator, iterating, codeNumber);
                accumulator[key] = row;
                return accumulator;
            } else {
                queueExt.single(findLikeQueueExt);
                return;
            }
        }
        // Are there any Call Groups with the same extension?
        if (findLikeCallGroupExt) {
            if (iterating >= 0) {
                const row = callGroup.iterating(findLikeCallGroupExt, form);
                const key = duplicateKeyCheck(accumulator, iterating, codeNumber);
                accumulator[key] = row;
                return accumulator;
            } else {
                callGroup.single(findLikeCallGroupExt);
                return;
            }
        }
        // Does a Short Code already exist?
        if (
            findLikeCurrentCode &&
            findLikeCurrentCode.codeNumber != route &&
            form.prevCodeNum !== codeNumber
        ) {
            if (iterating >= 0) {
                const row = currentCode.iterating(findLikeCurrentCode, form);
                const key = duplicateKeyCheck(accumulator, iterating, codeNumber);
                accumulator[key] = row;
                return accumulator;
            } else {
                currentCode.single(codeNumber);
                return;
            }
        }
        // Does the number interfere with any 311, 411... 911 numbers?
        if (findN11numbers) {
            if (iterating >= 0) {
                const row = n11Nums.iterating(codeNumber, form);
                const key = duplicateKeyCheck(accumulator, iterating, codeNumber);
                accumulator[key] = row;
                return accumulator;
            } else {
                n11Nums.single(codeNumber);
                return;
            }
        }
        // Does the code or name have any special characters?
        if (pattern.test(codeNumber) || pattern.test(name)) {
            if (iterating >= 0) {
                const row = specialCharacters.iterating(codeNumber, form);
                const key = duplicateKeyCheck(accumulator, iterating, codeNumber);
                accumulator[key] = row;
                return accumulator;
            } else {
                specialCharacters.single();
                return;
            }
        }
        if (
            codeNumber == '0' ||
            codeNumber == '00' ||
            codeNumber == '000' ||
            codeNumber == '0000'
        ) {
            if (iterating >= 0) {
                form.id = codeNumber;
                form.disabled = true;
                form.checked = false;
                form.reason = (
                    <p>Code Number can not be '0', '00', '000', or '0000'. Please Try again.</p>
                );
                const key = duplicateKeyCheck(accumulator, iterating, codeNumber);
                accumulator[key] = form;
                return accumulator;
            } else {
                errorNotification({
                    dismiss: false,
                    title: `Code ${codeNumber} Not Saved.`,
                    message: `Code Number can not be '0', '00', '000', or '0000'. Please Try again.`,
                });
                setButtonDisabled(true);
                return;
            }
        }
        // Is the code number a number?
        if (!Number(codeNumber)) {
            if (iterating >= 0) {
                const row = numberCheck.iterating(codeNumber, form);
                const key = duplicateKeyCheck(accumulator, iterating, codeNumber);
                accumulator[key] = row;
                return accumulator;
            } else {
                numberCheck.single();
                return;
            }
        }
        // Is the code the correct length?
        if (codeNumber.length < 2 || codeNumber.length > 4) {
            if (iterating >= 0) {
                const row = codeLength.iterating(codeNumber, form);
                const key = duplicateKeyCheck(accumulator, iterating, codeNumber);
                accumulator[key] = row;
                return accumulator;
            } else {
                codeLength.single();
                return;
            }
        }
        // Is the name filled in?
        if (name.length <= 0) {
            if (iterating >= 0) {
                const row = nameLength.iterating(codeNumber, form);
                const key = duplicateKeyCheck(accumulator, iterating, codeNumber);
                accumulator[key] = row;
                return accumulator;
            } else {
                nameLength.single();
                return;
            }
        }
        // Is the stripped forwarding number a number?
        if (!Number(strippedForwardingNum)) {
            if (iterating >= 0) {
                const row = forwardingNumNumber.iterating(codeNumber, form);
                const key = duplicateKeyCheck(accumulator, iterating, codeNumber);
                accumulator[key] = row;
                return accumulator;
            } else {
                forwardingNumNumber.single(forwardingNumber);
                return;
            }
        }
        // Is the forwarding number the correct length?
        if (strippedForwardingNum.length <= 10) {
            if (iterating >= 0) {
                const row = forwardingNumCountryCode.iterating(codeNumber, form);
                const key = duplicateKeyCheck(accumulator, iterating, codeNumber);
                accumulator[key] = row;
                return accumulator;
            } else {
                forwardingNumCountryCode.single();
                return;
            }
        }
        // We have passed all the tests for bulk uploading
        // Are we in a reduce function? if so let's add the good short code
        // information to the accumulator
        if (iterating >= 0) {
            const key = duplicateKeyCheck(accumulator, iterating, codeNumber);
            if (key === codeNumber) {
                form.id = codeNumber;
                form.disabled = false;
                form.checked = true;
                form.reason = `Will be uploaded.`;
                form.forwardingNumber = '+' + strippedForwardingNum;    
            } else {
                form.id = key;
                form.disabled = true;
                form.checked = false;
                form.reason = key;
                form.forwardingNumber = strippedForwardingNum;    
            }
            accumulator[key] = form;
            return accumulator;
        } else {
            // Since we have access to the country code when we add a single code,
            // we can check to see if the forwarding number without the country code
            // is the correct length.
            // Is it too short?
            if (strippedForwardingNum.length - countryCodeState.length < 10) {
                errorNotification({
                    dismiss: false,
                    title: `Code ${selectedCode} Not Saved.`,
                    message: `The Forwarding Number isn't long enough.`,
                });
                setButtonDisabled(true);
                return;
            }
            // Is the forwarding number too long?
            if (strippedForwardingNum.length - countryCodeState.length > 10) {
                errorNotification({
                    dismiss: false,
                    title: `Code ${selectedCode} Not Saved.`,
                    message: `The Forwarding Number is too long.`,
                });
                setButtonDisabled(true);
                return;
            }
            // We passed all the tests for adding a single number!
            setButtonDisabled(true);
            // Add the '+' back onto the forwarding number
            form.forwardingNumber = '+' + strippedForwardingNum;
            form.codeNumber = form.codeNumber.replace(/[^0-9]/g, '');
            // We are creating a new code
            if (route == 'new') {
                createNewCode(form)
                    .then(res => {
                        app.navigate(`shortcodes/${res.codeNumber}`, { trigger: false });
                        return res;
                    })
                    .catch(err => {
                        errorNotification({
                            title: `Code ${form.codeNumber} Not Created.`,
                            message: `Error while trying to create new code.`,
                        });
                        console.warn('Error Editing:', err);
                    });
            } else {
                // We are editing a code, let's add a prevCodeNum in case we are changing
                // the code number. This will allow us to update the correct code on the API side
                editCode(form)
                    .then(res => {
                        // We can tell this function if we want to navigate
                        // away from our current page after an edit. This is helpfull because
                        // we might have edited the code number and therefore, the URL param we
                        // had before is no longer correct.
                        if (navigate) {
                            app.navigate(`shortcodes/${form.codeNumber}`, { trigger: true });
                        }
                        return res;
                    })
                    .catch(res => {
                        errorNotification({
                            title: `Code ${form.codeNumber} Not Saved.`,
                            message: `Error while trying to save code.`,
                        });
                        console.warn('Error Editing:', res);
                    });
            }
        }
    };
    const submitFullForm = (cancel, navigate = true) => {
        if (cancel) {
            onCancel();
        } else {
            validateValues(null, shortCodesForm, undefined, navigate);
        }
    };

    const value = {
        buttonDisabled,
        bulkInputRef,
        countryCodeState,
        createStoreKey,
        discardChange,
        findCodeByNumber,
        leftBarData,
        onCancel,
        prepareDataForLeftBar,
        reqAction,
        route,
        saveChange,
        select,
        selectedCode,
        setButtonDisabled,
        setCountryCode,
        setDetails,
        setRoute,
        setSelectedCode,
        setShortCodesForm,
        setTheForms,
        setUnsavedChanges,
        shortCodeDetails,
        shortCodesForm,
        submitFullForm,
        unsavedChanges,
        validateValues,
    };
    // We are returning any fucntions and state we want to pass to the children of this provider
    return <ShortCodesContext.Provider value={value} {...props} />;
};

/* 
    This function will actually allow us to reuse all of the state and functions we created.
    It also makes sure we are calling these hooks within the appropriate provider.
    This is usefull because we don't want our state to be the default values we gave in the provider.
    It also allows us to consolidate the state and dispatch events
*/
const useShortCodes = () => {
    const context = useContext(ShortCodesContext);
    if (!context) {
        throw new Error('useShortCodes must be within a provider');
    }
    const {
        buttonDisabled,
        bulkInputRef,
        countryCodeState,
        createStoreKey,
        discardChange,
        findCodeByNumber,
        leftBarData,
        onCancel,
        prepareDataForLeftBar,
        reqAction,
        route,
        saveChange,
        select,
        selectedCode,
        setButtonDisabled,
        setCountryCode,
        setDetails,
        setRoute,
        setSelectedCode,
        setShortCodesForm,
        setTheForms,
        setUnsavedChanges,
        shortCodeDetails,
        shortCodesForm,
        submitFullForm,
        unsavedChanges,
        validateValues,
    } = context;
    return {
        buttonDisabled,
        bulkInputRef,
        countryCodeState,
        createStoreKey,
        discardChange,
        findCodeByNumber,
        leftBarData,
        onCancel,
        prepareDataForLeftBar,
        reqAction,
        route,
        saveChange,
        select,
        selectedCode,
        setButtonDisabled,
        setCountryCode,
        setDetails,
        setRoute,
        setSelectedCode,
        setShortCodesForm,
        setTheForms,
        setUnsavedChanges,
        shortCodeDetails,
        shortCodesForm,
        submitFullForm,
        unsavedChanges,
        validateValues,
    };
};

export { ShortCodesProvider, useShortCodes };
