import React, { Component } from 'react';
import { connect } from 'react-redux';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CheckedBox from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import NewIcon from '@material-ui/icons/Add';
import UncheckedBox from '@material-ui/icons/RadioButtonUnchecked';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import VerifyDialog from 'presentational/VerifyDialog';

import { formatNumber } from 'utils/phoneNumbers';
import {
    addMessageToBeDeleted,
    cancelMessagesToBeDeleted,
    deleteMessageFromConversation,
    deletingSmsMessages,
    removeMessageToBeDeleted,
    selectConversation,
    toggleAllMessagesForDeletion,
    showingNewNumberInput, } from '../../actions/sms';

import { successNotification, errorNotification } from '../../actions/notifications';

import { deleteSmsHttp } from '../../actions/httpRequest';

const styles = () => ({
    toolbar: {
        position: 'absolute',
        top: 0,
        width: '100%',
        paddingLeft: 20,
    },
    wrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position: 'relative',
        height: '90%',
    },
    startNewButton: {
        fontSize: '90%',
        marginRight:'20px',
        minWidth: 150,
    },
    typography: {
        minWidth: 150,
    },
    icons: {
      marginBottom: 4,
    },
    verifiedIcon: {
      height: 30,
      width:30,
      cursor: "pointer",
      marginTop: '5px',
      marginLeft: '5px',
      color: '#2a72b9'
    },
    disabledIcon: {
      height: 30,
      width:30,
      cursor: "pointer",
      marginTop: '5px',
      marginLeft: '5px',
      color: '#dfdfdf'
    },
    deleteButton: {
      fontSize: '90%',
      backgroundColor: '#e93c2c',
    },
    deleteIconButton: {
      fontSize: '90%',
      backgroundColor: '#e93c2c',
      marginRight: 20,
    },
    button: {
      fontSize: '90%',
    },
    addCircleIcon: {
      height: 40,
      width: 40
    },
});

class ChatWindowContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showNewNumberInput: false,
            newNumberInput: '',
            numberInputError: '',
            showDeleteDialog: false,
            verified: false,
        };
    }

    showNewNumberInput(showNewNumberInput) {

        this.props.deletingSmsMessages(false);
        this.setState({ showNewNumberInput });
    }

    validNumber() {

      if (this.state.newNumberInput.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/) == null 
        || this.state.newNumberInput.length < 10) {
          this.setState({
            numberInputError: 'Please enter valid number',
            verified: false,
          });
          return false;
        }
        this.setState({
          numberInputError: ' ',
          verified: true,
        });
        return true;
    }

    handleSubmit(e) {
        const { classes } = this.props;
        const { verified } = this.state;
        if (e) {
            e.preventDefault();
        }

        if (this.validNumber() && verified ) {

            // Lets see if there already is a conversation for this user
            const match = this.props.conversations.data.find((convo) =>
                formatNumber(this.state.newNumberInput) == formatNumber(convo.participant),
            );

            this.setState({
                newNumberInput: '',
                numberInputError: ' ',
                showNewNumberInput: false,
                verified: false,
            });

            this.props.showingNewNumberInput(false)
            // Lets return the match if it exists, or start a new conversation
            return this.props.selectConversation(
                match || {
                    messages: [],
                    participant: formatNumber(this.state.newNumberInput),
                    number: this.props.selectedInternalDid,
                },
                <AccountCircleIcon classes={{ root: classes.addCircleIcon }} />,
                formatNumber(this.state.newNumberInput),
            );
        }
        this.props.showingNewNumberInput(true);
    }

    // Toggles selecting all messages in the currently selected conversation
    toggleSelectAllMessages() {

        // If state of selected messages is false, let's select all messages
        if (!this.props.selectedAllMessages) {

            this.props.cancelMessagesToBeDeleted();

            this.props.conversation.conversation.messages.map((message) =>
                this.props.addMessageToBeDeleted(message.messageId),
            );

            return this.props.toggleAllMessagesForDeletion();
        // otherwise, let's deselect all messages
        }

        return this.props.cancelMessagesToBeDeleted();
    }

    deleteMessages() {

        const {
            deleteSmsHttp,
            messagesToBeDeleted,
            conversation,
            success } = this.props;
        if (!messagesToBeDeleted || messagesToBeDeleted.length == 0) {

            success({
                title: 'Error deleting messages',
                message: 'No messages to delete',
            });

            return;
        }

        // Lets map through all the messages to be deleted
        messagesToBeDeleted.map((messageId) => {

            deleteSmsHttp(
                {
                    reqAction: conversation.conversation.number,
                    reqObject: conversation.conversation.participant,
                    reqObject2: messageId,
                },
                'messageDelete',
            );

            this.props.deleteMessageFromConversation(conversation.conversation.participant, messageId);

        });
            return this.props.selectConversation(
                this.props.conversation.conversation,
                this.props.selectedConversation.avatar,
                this.props.selectedConversation.contactInfo,
            );
    }

    render() {
        const { classes } = this.props;
        return (
          <div className={classes.wrapper}>
            {this.props.conversation ?
              <Toolbar classes={{ root: classes.toolbar }}>

                <div >
                  {this.state.showNewNumberInput ?
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                      <TextField
                        autoFocus
                        value={this.state.newNumberInput}
                        InputLabelProps={{shrink: true}}
                        placeholder="Enter Number"
                        error={Boolean(!this.state.verified)}
                        helperText={this.state.numberInputError ? this.state.numberInputError : ' '}
                        onChange={(e, text) => this.setState({ newNumberInput: e.target.value }, () => this.validNumber())}
                        onBlur={() => {
                            if (!this.validNumber()) {
                                return this.setState({
                                    newNumberInput: '',
                                    numberInputError: '',
                                    showNewNumberInput: false,
                                    verified: false,
                                });
                            }

                            return this.handleSubmit();
                        }}
                      />
                     {this.state.verified ?
                        <CheckedBox
                          classes={{ root: classes.verifiedIcon }}
                          onClick={() => this.handleSubmit()}
                        />
                      : 
                      null
                      }
                    </form> :
                    <Typography variant='h6' >{`Conversation with ${this.props.participantContact.contactInfo}`}</Typography>
                  }
                </div>
                  <div style={{flexGrow:'1'}}/>
                <div style={{ display: 'flex' }}>
                  <Button
                    classes={{ root: classes.startNewButton }}
                    variant="contained" 
                    color='primary'
                    onClick={() => this.showNewNumberInput(true)}
                  >Start new{<NewIcon classes={{ root: classes.icons }} />}</Button>
                  {!this.props.deletingMessages ?
                    <Button
                      classes={{ root: classes.deleteButton }}
                      variant="contained" 
                      color='secondary'
                      onClick={() => this.props.deletingSmsMessages(true)}
                    >{<DeleteIcon classes={{ root: classes.icons }} />}</Button> :
                    <div>
                      <Button
                        classes={{ root: classes.deleteIconButton }}
                        size='large' 
                        variant="contained" 
                        color='secondary'
                        disabled={!this.props.messagesToBeDeleted ||
                            this.props.messagesToBeDeleted.length == 0
                        }
                        onClick={() => this.setState({ showDeleteDialog: true })}
                      >Delete {<DeleteIcon classes={{ root: classes.icons }} />}</Button>
                      <Button
                        classes={{ root: classes.button }}
                        size='large' 
                        variant="contained" 
                        color='primary'
                        onClick={() => this.toggleSelectAllMessages()}
                      >{this.props.selectedAllMessages ?
                        <CheckedBox classes={{ root: classes.icons }} /> :
                        <UncheckedBox classes={{ root: classes.icons }} />
                      } Select all</Button>
                      <Button
                        classes={{ root: classes.button }}
                        size='large' 
                        onClick={() => this.props.deletingSmsMessages(false)}
                      >Cancel</Button>
                    </div>
                    }
                </div>

              </Toolbar>
            :
              <Toolbar classes={{ root: classes.toolbar }}>

                <div>
                  {this.state.showNewNumberInput ?
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                      <TextField
                        autoFocus
                        InputLabelProps={{shrink: true}}
                        value={this.state.newNumberInput}
                        placeholder="Enter Number"
                        error={Boolean(!this.state.verified)}
                        helperText={this.state.numberInputError ? this.state.numberInputError : ' '}
                        onChange={(e, text) => this.setState({ newNumberInput: e.target.value }, () => this.validNumber())}
                        onBlur={() => {
                            if (!this.validNumber()) {
                                return this.setState({
                                    newNumberInput: '',
                                    numberInputError: '',
                                    showNewNumberInput: false,
                                    verified: false,
                                });
                            }

                            return this.handleSubmit();
                        }}
                      />
                     {this.state.verified ?
                        <CheckedBox
                          classes={{ root: classes.verifiedIcon }}
                          onClick={() => this.handleSubmit()}
                        />
                      : 
                      null
                      }
                    </form> :
                    <Typography variant='h6' className={classes.typography}>SMS</Typography>
                  }
                </div>
                  <div style={{flexGrow:'1'}} />
                <Typography variant='button'>
                  <Button
                    classes={{ root: classes.startNewButton }}
                    variant="contained" 
                    color='primary'
                    onClick={() => {
                     this.showNewNumberInput(true);
                     this.props.showingNewNumberInput(true);
                    }}
                  >Start new{<NewIcon />}</Button>

                </Typography>

              </Toolbar>
            }
            {this.props.children}
            {this.state.showDeleteDialog ?
              <VerifyDialog
                accepted={() => {
                    this.setState({ showDeleteDialog: false });
                    this.deleteMessages();
                }}
                declined={() => this.setState({ showDeleteDialog: false })}
                text="Delete messages?"
                confirmText="Delete"
              /> : null}
          </div>
        );
    }
}

const mapStateToProps = (state) => ({
    conversation: state.sms  ? state.sms.selectedConversation : null,
    conversations: state.http && state.http.smsData ? state.http.smsData : null,
    deletingMessages: state.sms ? state.sms.deletingMessages : null,
    pendingHttp: state.http ? state.http.pending : null,
    failedHttp: state.http ? state.http.failed : null,
    messagesToBeDeleted: state.sms ? state.sms.messagesToBeDeleted : null,
    participantContact: state.sms ? state.sms.selectedConversation : null,
    selectedAllMessages: state.sms ? state.sms.selectedAllMessages : null,
    selectedConversation: state.sms ? state.sms.selectedConversation : null,
    selectedInternalDid: state.sms ? state.sms.selectedInternalDid : null,
    showNewNumberInput: state.sms? state.sms.showNewNumberInput : null,
});

const bindActions = (dispatch) => ({
    addMessageToBeDeleted: (messageId) => dispatch(addMessageToBeDeleted(messageId)),
    cancelMessagesToBeDeleted: () => dispatch(cancelMessagesToBeDeleted()),
    deleteMessageFromConversation: (participant, messageId) =>
        dispatch(deleteMessageFromConversation(participant, messageId)),
    deleteSmsHttp: (reqData, storeKey) => dispatch(deleteSmsHttp(reqData, storeKey)),
    deletingSmsMessages: (deleting) => dispatch(deletingSmsMessages(deleting)),
    removeMessageToBeDeleted: (messageId) => dispatch(removeMessageToBeDeleted(messageId)),
    showingNewNumberInput: (showNewNumberInput) => dispatch(showingNewNumberInput(showNewNumberInput)),
    selectConversation: (conversation, avatar, contactInfo) =>
        dispatch(selectConversation(conversation, avatar, contactInfo)),
    toggleAllMessagesForDeletion: () => dispatch(toggleAllMessagesForDeletion()),
});

export default connect(mapStateToProps, bindActions)(withStyles(styles)(ChatWindowContainer));
