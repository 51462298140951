import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

export default class DescriptionCard extends Component {
  render() {
    let description = null;

    if (this.props.title === 'Not Assigned') {
      description = <p>This phone number is currently unassigned and will not ring the FluentStream system.
         You may assign your phone number to one of the following options.</p>
    }
    else if (this.props.title === 'User') {
      description = <p>Assigning a phone number directly to a user will scan for Electronic Fax Settings,
         ring the defined extension (ring time can be controlled in extension settings) and then direct to the user's voicemail box. Any FollowMe settings on the extension will also be followed.</p>
    }
    else if (this.props.title === 'Route') {
      description = <p>Assigning a phone number to a route will scan for Electronic Fax Settings then follow all time conditions and applications within the defined route.
         An emergency route may be defined in the event that all of the extensions at the defined location are offline.</p>
    }
    else if (this.props.title === 'Managed Route') {
      description = <p>Assigning a phone number to a managed route will scan for Electronic Fax Settings then follow Managed Route toggles.
         Click <a href="#managedroutes">here</a> to view the managed routes manager.
          An emergency route may be defined in the event that all of the extensions at the defined location are offline.</p>
    }
    else if (this.props.title === 'Conference Room') {
      description = <p>Assigning a phone number to a conference room will route the number directly to the conference system.
         You may also set a custom greeting for the phone number. The room itself will define if callers will need to enter room numbers,
          PINS, and other settings. Click <a href="#conferencerooms">here</a> to view the Conference Room manager.</p>
    }
    else if (this.props.title === 'Call Forwarding') {
      description = <p>Setting a phone number to call forwarding will directly forward the call to the defined phone number.
         This is recommended for use only for outside phone numbers.</p>
    }
    else if(this.props.title === 'Assign A Route'){
      description = <p>Assigning a phone number to a route will scan for Electronic Fax Settings then follow all time conditions and
         applications within the defined route. An emergency route may be defined in the event that all of the extensions at the defined 
         location are offline.</p>
    }
    else if(this.props.title === 'Assign A Managed Route'){
      description = <p>Assigning a phone number to a managed route will scan for Electronic Fax Settings then follow Managed Route toggles.
         Click <a href="#managedroutes">here</a> to view the managed routes manager.
          An emergency route may be defined in the event that all of the extensions at the defined location are offline.</p>
    }
    else if(this.props.title === 'Assign A Conference Room'){
      description = <p>Assigning a phone number to a conference room will route the number directly to the conference system.
         You may also set a custom greeting for the phone number. 
         The room itself will define if callers will need to enter room numbers, PINS, and other settings.
          Click <a href="#conferencerooms">here</a> to view the Conference Room manager.</p>
    }
    else if(this.props.title === 'Assign Call Forwarding'){
      description = <p>Setting a phone number to call forwarding will directly forward the call to the defined phone number.
         This is recommended for use only for outside phone numbers.</p>
    }
    else if(this.props.title === 'Assign To User'){
      description = <p>Assigning a phone number directly to a user will scan for Electronic Fax Settings,
         ring the defined extension (ring time can be controlled in extension settings) and then direct to the user's voicemail box.
          Any FollowMe settings on the extension will also be followed.</p>
    }
    else if(this.props.title === 'e911 Settings'){
      description = <p>Manage room level e911 here. 
        Once enabled, you can provide a unit type (apartment, suite, room) and a unit number that will direct 911 services to a specific room. 
        This room is added to the address in which the location this phone number is assigned to. </p>
    }

    return (
      <div style={style.card}>
        <Card >
          <CardHeader
            title={this.props.title}
          />
          <CardContent>
            {description}
          </CardContent>
        </Card>
      </div>
    )
  }
}

const style = {
  card: {
      width: '50%',
      minWidth: '400px',
  },
};