import {
  CALLFORWARDING_HTTP_SUCCESS,
  EDITCALLFORWARDING_HTTP_SUCCESS,
  CALLFORWARDING_HTTP_FAILURE,
  EDITCALLFORWARDING_HTTP_FAILURE,
  USERPORTALTIMECONDITIONS_HTTP_SUCCESS,
  USERPORTALTIMECONDITIONS_HTTP_FAILURE,
  EDITUSERPORTALTIMECONDITIONS_HTTP_SUCCESS,
  EDITUSERPORTALTIMECONDITIONS_HTTP_FAILURE,
  RESET_FLAG,
} from '../actions/typeConstants';

import { parseError } from 'utils/misc';

const initialState = {
  callForwardingData: null,
  timeConditionsData: null, 
  successMsg: null,
  errorMsg: null,
  errorCode: null,
}

export default function(state = initialState, action) {
  switch(action.type) {
    case CALLFORWARDING_HTTP_SUCCESS:
      return { ...state, callForwardingData: action.data, errorMsg: null, errorCode: null }
    case USERPORTALTIMECONDITIONS_HTTP_SUCCESS:
      return { ...state, timeConditionsData: action.data, errorMsg: null, errorCode: null }
    case CALLFORWARDING_HTTP_FAILURE:
      return { ...state, errorMsg: parseError(action.error.response.text), successMsg: null, errorCode: action.error.response.status }
    case USERPORTALTIMECONDITIONS_HTTP_FAILURE: 
      return { ...state, errorMsg: parseError(action.error.response.text), successMsg: null, errorCode: action.error.response.status }
    case EDITUSERPORTALTIMECONDITIONS_HTTP_SUCCESS: 
      return { ...state, timeConditionsData: action.data, successMsg: 'Saved!', errorMsg: null, errorCode: null }
    case EDITUSERPORTALTIMECONDITIONS_HTTP_FAILURE:
      return { ...state, errorMsg: parseError(action.error.response.text), successMsg: null, errorCode: action.error.response.status }
    case EDITCALLFORWARDING_HTTP_SUCCESS:
      return { ...state, callForwardingData: action.data, successMsg: 'Saved!', errorMsg: null, errorCode: null }
    case EDITCALLFORWARDING_HTTP_FAILURE:
      return { ...state, errorMsg: parseError(action.error.response.text), successMsg: null, errorCode: action.error.response.status }
    case RESET_FLAG:
      return {...state, successMsg: null, errorMsg: null, errorCode: null}
    default:
      return { ...state }
  }
}
