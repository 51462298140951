import React, { Component } from 'react';
import { connect } from 'react-redux';

import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/styles';

import DeleteModal from 'presentational/DeleteModal';
import Loading from 'presentational/Loading';
import DetailContainer from 'presentational/DetailContainer';
import LeftBar from 'presentational/LeftBar';
import MasterView from 'presentational/MasterView';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import {
  deleteCoreHttp,
  getCoreHttp,
  postCoreHttp,
  putCoreHttp,
} from 'actions/httpRequest';
import { selectedConferenceRoom, resetFlag, resetCreatedRoom } from 'actions/conferenceRoom';

import ConferenceRoomHelp from './help';
import ConferenceRoomForm from './conferenceRoomForm';
import { successNotification, errorNotification } from 'actions/notifications';

const styles = () => ({
  button: {
      fontSize: '100%',
  },
  menuItem: {
      fontSize: '100%',
  },
});

class ConferenceRoom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leftBarData: null,
      route: null,
      selectedConferenceRoom: null,
      showingDeleteConfirmModal: false,
      closeMenu: false,
    }

    this.storedFetchedData = false;
    this.onCancel = this.onCancel.bind(this);
    this.select = this.select.bind(this);
    this.handleDeleteConferenceRoom = this.handleDeleteConferenceRoom.bind(this);
  }

  onCancel(room) {
    this.setState({ route: null });
    this.props.selectedConferenceRoom(room)
  }

  select(id){
    app.navigate(`conferencerooms/${id}`);
    this.leftBarSelect(id);
  }

  onBackButtonPress = () => {
    app.navigate(`conferencerooms`);
    this.leftBarSelect(null);
  }

  componentDidMount() {
    if (this.props.conferenceRoomData && !this.storedFetchedData) {
      this.prepareDataForLeftBar(this.props.conferenceRoomData.data);
      this.storedFetchedData = true;
    }

    const reqData = {
      reqAction: 'conferencerooms',
    };
    this.props.getCoreHttp(reqData);
  }

  fetchDetails(id) {

    const reqData = {
      reqAction: 'conferencerooms',
      reqObject: id
    }

    const storeKey = 'conferencerooms';
    this.props.getCoreHttp(reqData, storeKey);
  }

  createNewRoom(data) {
    const reqData = {
      reqAction: 'conferencerooms',
      reqBody: data,
    };

    const storeKey = 'createconferencerooms';

    this.props.postCoreHttp(reqData, storeKey);
  }

  componentDidUpdate(prevProps) {
    if(this.props.routeParams && this.props.routeParams.confno ==='No Room'){
      app.navigate(`conferencerooms`,{trigger: true});
    }
    if(this.props.routeParams && this.props.routeParams.confno !== this.state.route){
      this.leftBarSelect(this.props.routeParams.confno);
      return;
    }
    if(this.props.routeParams && this.props.routeParams.confno !== this.props.conferenceRoomSelected){
      app.navigate(`conferencerooms/${this.props.conferenceRoomSelected}`, {trigger: true});
    }

    if (this.props.conferenceRoomData && !this.storedFetchedData) {
      this.prepareDataForLeftBar(this.props.conferenceRoomData.data);
      this.storedFetchedData = true;
      return;
    }

    if(this.props.createdRoom && this.state.route==='new'){
      this.setState({ route: this.props.createdRoom, selectedConferenceRoom: this.props.createdRoom });
      this.props.resetCreatedRoom();
      this.prepareDataForLeftBar(this.props.conferenceRoomData.data);
      App.Configurations.set("conferenceRooms", this.props.conferenceRoomData.data);
      App.Configurations.trigger('change', App.Configurations);
      return;
    }

    const prevData = get(prevProps, 'conferenceRoomData.data');
    const currData = get(this.props, 'conferenceRoomData.data', 1);

    if(!isEqual(prevData, currData)){
      this.prepareDataForLeftBar(this.props.conferenceRoomData.data);
    }

    if (this.props.successFlag) {
      this.props.resetFlag();
      successNotification({
        title: 'Success!', message: this.props.successFlag
      })
    }
    else if (this.props.failureFlag) {
      this.props.resetFlag();
      errorNotification({
        title: 'Failed!', message: this.props.failureFlag
      })
    }
  }

  goToHelp() {
    const win = window.open('http://help.fluentcloud.com/support/solutions', '_blank');
    win.focus();
  }

  leftBarSelect(conferenceId) {
    this.setState({ route: conferenceId, selectedConferenceRoom: conferenceId });
    this.props.selectedConferenceRoom(conferenceId==='new' ? 'new' : conferenceId);
    if(conferenceId!=='new'){
      this.fetchDetails(conferenceId);
    }
  }

  openCloseMenu() {
    this.setState({ closeMenu: !this.state.closeMenu });
  }

  async editRoom(data){
    const reqData = {
      reqAction: 'conferencerooms',
      reqObject: data.confno,
      reqBody: data,
    };

    const storeKey = 'editconferencerooms';
    await this.props.putCoreHttp(reqData, storeKey);
  }

  renderContent() {
    const { route } = this.state;
    switch (route) {
      case 'new':
        return <ConferenceRoomForm
          key={'new'}
          formKey={'new'}
          selectedConferenceRoom={room => this.onCancel(room)}
          submitForm={data => this.createNewRoom(data)} />;
      case null:
        return <ConferenceRoomHelp />;
      default:
        return <ConferenceRoomForm
          key={this.state.route}
          formKey={this.state.route}
          selectedConferenceRoom={room => this.onCancel(room)}
          submitForm={data => this.editRoom(data)} />;

    }
  }

  navigate(route) {
    this.setState({ route: route })
  }

  handleAddNewClick() {
    this.props.selectedConferenceRoom('new');
    app.navigate(`conferencerooms/new`, {trigger: true});
  }

  prepareDataForLeftBar(data) {
    if (!data) {
      return console.log('No room returned');
    }

    const leftBarData = [];
    data.map((item) => {
      const title = item.confno;
      const subtitle = item.name;
      const id = item.confno;
      return leftBarData.push({ id, title, subtitle });
    });

    this.setState({ leftBarData: null });

    return this.setState({ leftBarData });
  }


  handleDeleteConfirmClose(shouldDelete) {
    shouldDelete && this.handleDeleteConferenceRoom();
    this.setState({ showingDeleteConfirmModal: false}, app.navigate(`conferencerooms`, {trigger: true}));
  }

  handleDeleteConferenceRoom() {
    const roomId = this.state.selectedConferenceRoom;

    const reqData = {
      reqAction: 'conferencerooms',
      reqObject: roomId,
    };
    const storeKey = 'deleteconferencerooms';

    this.props.deleteCoreHttp(reqData, storeKey);

    this.setState({
      route: null,
      selectedConferenceRoom: null,
    });
    this.props.selectedConferenceRoom('');
  }

  render() {
    if(this.props.routeParams && this.props.routeParams.confno!=='new' && this.props.routeParams.confno!==this.state.route){
      return <Loading />;
    }

    const { pending, selectedConferenceName } = this.props;
    const { leftBarData, route, selectedConferenceRoom } = this.state;
    const { classes } = this.props;
    
    const contextMenuOptions = [
      <MenuItem
        disabled={!selectedConferenceRoom || selectedConferenceRoom==='new'}
        classes={{ root: classes.menuItem }}
        onClick={() => {this.setState({ showingDeleteConfirmModal: true }); this.openCloseMenu();}}
        key={0}
      >
        Delete
      </MenuItem>,
      <MenuItem
        onClick={() => {this.goToHelp(); this.openCloseMenu();}}
        classes={{ root: classes.menuItem }}
        key={1}
      >
        Help
      </MenuItem>,
    ];

    return (
      <MasterView>
        <DetailContainer
          leftBar={
            <LeftBar
              addNew={() => this.handleAddNewClick()}
              data={leftBarData}
              pending={pending || !this.props.conferenceRoomData}
              selected={selectedConferenceName}
              select={(id) => this.select(id)}
              title="Conference Rooms"
            />
          }
          title={this.state.route === 'new'? 'New Conference Room' : this.state.selectedConferenceRoom}
          route={null}
          return={this.onBackButtonPress}
          contextMenuOptions={contextMenuOptions}
          closeMenu={this.state.closeMenu}
          backButtonWithoutRoute={true}
        >
          {this.renderContent()}
        </DetailContainer>
        <DeleteModal
          open={this.state.showingDeleteConfirmModal}
          title='Are you sure?'
          delete={() => this.handleDeleteConfirmClose(true)}
          cancel={() => this.handleDeleteConfirmClose(false)}
        />
      </MasterView>
    )
  }
}

const mapStateToProps = state => ({
  conferenceRoomData: state.conferenceRoom.conferenceRoomData,
  pending: state.conferenceRoom.pending,
  failureFlag: state.conferenceRoom.failureFlag,
  successFlag: state.conferenceRoom.successFlag,
  createdRoom: state.conferenceRoom.createdRoom,
  routeParams: state.navigation.params,
  conferenceRoomSelected: state.conferenceRoom.conferenceRoomSelected
})

const bindActions = (dispatch) => ({
  deleteCoreHttp: (reqData, storeKey) => dispatch(deleteCoreHttp(reqData, storeKey)),
  getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
  postCoreHttp: (reqData, storeKey) => dispatch(postCoreHttp(reqData, storeKey)),
  putCoreHttp: (reqData, storeKey) => dispatch(putCoreHttp(reqData, storeKey)),
  selectedConferenceRoom: (data) => dispatch(selectedConferenceRoom(data)),
  resetFlag: () => dispatch(resetFlag()),
  resetCreatedRoom: () => dispatch(resetCreatedRoom())
});

export default connect(mapStateToProps, bindActions)(withStyles(styles)(ConferenceRoom));
