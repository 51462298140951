import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  textField: {
    width: '100%'
  }
})

class ChipInputAutoSuggest extends React.Component {
  constructor(props){
    super(props);
  }

  handleChange = (event, newValue ) => {
    this.props.onAdd(newValue)
  }

  render () {
    return (
      <Autocomplete
        multiple
        id={`${this.props.label}-autocomplete`}
        options={this.props.suggestions.map(option => option.label)}
        onChange={this.handleChange}
        filterSelectedOptions={true}
        value={this.props.values}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={this.props.label}
          />
        )}
      />
    )
  }
}

export default withStyles(styles)(ChipInputAutoSuggest);
