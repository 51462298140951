import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const Modal = (props) => {
  return (
    <Dialog
      open={props.show}
      onClose={props.cancelModal}
      fullWidth={true}
    >
      <DialogContent>
        <div style={{ marginTop:'5px' }}>{props.dialogContentText}</div>
      </DialogContent>
      <DialogActions>
        {props.dialogActionButtons}
      </DialogActions>
    </Dialog>
  )
}

Modal.propTypes = {
  dialogContentText: PropTypes.string,
  dialogActionButtons: PropTypes.array,
  show: PropTypes.bool,
  cancelModal: PropTypes.func,
}

export default Modal;