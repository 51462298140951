import React from 'react';
// import PropTypes from 'prop-types';

import SelectInput from 'presentational/formControls/SelectInput';

const TimeIntervalSelect = (props) => {

    return (
      <SelectInput 
        style={props.style}
        paddingBottom={0}
        options={props.timeIntervals}
        placeholder="Report Dates"
        value={props.timeinterval}
        field="timeInterval"
        onChange={(dateTime) => props.onChange(dateTime)}
      />
    );
};

export default TimeIntervalSelect;
