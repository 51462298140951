import React from 'react';
import PropTypes from 'prop-types';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/styles';

const style = {
    wrapper: {
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 30,
    },
    textfield: {
        // height: 40,
    },
    errorText: {
        fontSize: 12,
        color: 'red',
    },
};

const getValue = (value) => {

    switch (value) {
    case 0:
    case '0':
    case 'no':
    case 'No':
    case false:
        return false;

    case 1:
    case '1':
    case 'yes':
    case 'Yes':
    case true:
        return true;

    default:
        return false;
    }
};

const ToggleInput = (props) => {
    const classes = makeStyles({
        label: {
            fontSize: 14,
            fontWeight: '700',
            color: 'rgba(0, 0, 0, 0.3)',
        },
    })();
    return(
        <div style={(props.style ? props.style : style.wrapper)}>
            <FormControlLabel
                classes={{ root: classes.label }}
                label={props.placeholder}
                labelPlacement='start'
                control={
                    <Switch
                        disabled={props.disabled}
                        id={`toggle_input_${props.field}`}
                        name={props.field}
                        onChange={(e, value) => props.onChange(value)}
                        checked={getValue(props.value)}
                        color='primary'
                    />
                }

            />
            <p style={style.errorText}>{props.errorText}</p>
        </div>
    );
}

ToggleInput.defaultProps = {
    disabled: false,
    errorText: '',
    placeholder: '',
    onChange: () => console.log('No onChange func provided for textinput'),
    value: false,
};

ToggleInput.propTypes = {
    disabled: PropTypes.bool,
    errorText: PropTypes.string,
    field: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
    ]),
};

export default ToggleInput;
