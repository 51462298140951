import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles'; 

import TextField from '@material-ui/core/TextField';

const TextInput = (props) => {

    const wrapper = {
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 30,
    };

    const mergedStyle = Object.assign({}, wrapper, props.style);

    const style = {
        wrapper: mergedStyle,
    };

    let ourValue = props.value || "";
    const classes = makeStyles({ root: props.style });
    
    return (
      <div style={style.wrapper}>
        <TextField
          multiline = {props.multiline ? props.multiline : null}
          fullWidth
          classes={{ root: classes.root }}
          error={Boolean(props.errorText)}
          helperText={props.errorText ? props.errorText : null}
          id={`text_input_${props.field}`}
          name={props.field}
          onChange={(e, text) => props.onChange(e.target.value)}
          label={props.placeholder}
          placeholder={props.placeholder}
          value={ourValue}
          onBlur={(e) => props.onBlur(e.target.value)}
          disabled={props.disabled}
          type={props.type}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            autoComplete: "new-password",
            ["data-lpignore"]: "true",
            maxLength: props.maxLength ? props.maxLength : null
          }}
        />
      </div>
    );
};

TextInput.defaultProps = {
    disabled: false,
    errorText: '',
    hintText: '',
    placeholder: '',
    value: '',
    type: '',
    onChange: () => console.log('No onChange func provided for textinput'),
    onBlur: () => console.log('No onBlur func provided for textinput'),
    style: {}
};

TextInput.propTypes = {
    disabled: PropTypes.bool,
    errorText: PropTypes.string,
    field: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    maxLength: PropTypes.number
};

export default TextInput;
