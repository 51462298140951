import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

const style = {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
};

const Loading = (props) => (
  <div style={style}>
    <CircularProgress
      size={props.size || 50}
      thickness={4}
    />
  </div>
);

export default Loading;
