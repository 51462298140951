import React, { Suspense, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary'
import MasterView from 'presentational/MasterView';
import useFederatedComponent from './hooks/useFederatedComponent';
import { CircularProgress } from '@material-ui/core';

function ErrorFallback(props) {
    const { error, resetErrorBoundary, masterView } = props;

    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
      </div>
    )
  }

function EmbeddedComponent(props) {
    const { extension, masterView, rootSx } = props;

    const {Component: FederatedComponent, errorLoading} = useFederatedComponent(extension.url, extension.scope, extension.module, rootSx);

    const errorHandler = useCallback((error, info) => {
        console.log(error);        
    }, []);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
            <Suspense fallback={<CircularProgress />}>
              {masterView && 
                <MasterView>
                  <div className="component-wrapper" style={{flex: "1"}}>
                    {errorLoading
                        ? `Error loading module "${module}"`
                        : FederatedComponent 
                    }
                  </div>
                </MasterView>
              }
              {!masterView && 
                <div className="component-wrapper" style={{flex: "1"}}>
                  {errorLoading
                      ? `Error loading module "${module}"`
                      : FederatedComponent 
                  }
                </div>
              }
            </Suspense>
        </ErrorBoundary>
    );
}

EmbeddedComponent.defaultProps = {
  masterView: false
}

export default EmbeddedComponent;