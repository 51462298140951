import React, { Component } from 'react';
import { connect } from 'react-redux';

import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/styles';


import DetailContainer from 'presentational/DetailContainer';
import LeftBar from 'presentational/LeftBar';
import MasterView from 'presentational/MasterView';
import DeleteModal from 'presentational/DeleteModal';

import CallFilters from './callfilters';
import New from './new';

import {
    deleteCoreHttp,
    getCoreHttp,
    postCoreHttp,
    putCoreHttp,
} from 'actions/httpRequest';

import { selectCallFilter } from 'actions/incomingCallFilters';
import { successNotification, errorNotification } from 'actions/notifications';
import IncomingCallFilterHelp from './help';
import callfilters from './callfilters';

const styles = () => ({
    button: {
        fontSize: '100%',
    },
    menuItem: {
        fontSize: '100%',
    },
  });

class IncomingCallFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            callFilters: [],
            autoAttendantFetchError: null,
            leftBarData: null,
            route: null,
            selectedCallFilterGroup: null,
            showingDeleteConfirmModal: false,
            closeMenu: false,
            selectedFilterType: null,
        };
    }

    componentDidMount() {
        this.setState({
            route: null,
            selectedCallFilterGroup: null,
        });

        this.props.selectCallFilter('');

        if (!this.props.callFilters) {
            this.fetchIncomingCallFilters();
        } else {
            this.prepareDataForLeftBar(this.props.callFilters);
        }

        this.fetchConfigurations();
    }

    componentWillUnmount() {
        this.setState({
            route: null,
            selectedCallFilterGroup: null,
        });
        this.props.selectCallFilter('');
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (
            this.props.navigation &&
            nextProps.navigation &&
            this.props.navigation.route != nextProps.navigation.route
        ) {
            this.fetchConfigurations();
        }

        let callFilters = null;

        // If the new callFilters object is different (or if we
        // didn't have one before) lets get the data ready for leftbar
        if (
            nextProps.callFilters &&
            this.props.callFilters !== nextProps.callFilters
        ) {
            callFilters = nextProps.callFilters;

            this.setState({ callFilters });
            this.prepareDataForLeftBar(nextProps.callFilters);
        }

        if (
            nextProps.httpStatus.error !== this.props.httpStatus.error &&
            nextProps.httpStatus.error
        ) {
            errorNotification({
                title: 'Request Failed',
                message: nextProps.httpStatus.error,
            });
        }

        if (nextProps.selectedCallFilterName && nextProps.callFilters) {
            const selectedCallFilterGroup = nextProps.callFilters.find(
                obj => obj.filter_group_name == nextProps.selectedCallFilterName
            );

            //if we get a new callFilter, we won't have the .config yet,
            // so let's do a quick "GET" for that
            if (
                this.props.callFilters.length !== nextProps.callFilters.length
            ) {
                setTimeout(() => {
                    try {
                        this.getCallFilterDetails(
                            nextProps.selectedCallFilterName
                        );
                    } catch (error) {
                        console.error(
                            'No call group found by the id of: ',
                            nextProps.selectedCallFilterName
                        );
                    }
                }, 500);
                return;
            }

            // If we have a new call filter, handle this here
            if (nextProps.selectedCallFilterName == 'New Call Filter') {
                this.setState({
                    selectedCallFilterGroup: { name: 'New Call Filter' },
                });
                return;
            }

            // If the name has changed, lets reset the route here
            if (
                nextProps.selectedCallFilterName !==
                    this.props.selectedCallFilterName ||
                nextProps.callFilters.length !== this.props.callFilters.length
            ) {
                this.setState({
                    selectedCallFilterGroup,
                });
            } else {
                this.setState({ selectedCallFilterGroup });
            }
        }
    }

    // fetchIncomingCallFilters acquires call filter data via redux api
    fetchIncomingCallFilters() {
        const reqData = {
            reqAction: 'incomingfilter',
        };

        this.props.getCoreHttp(reqData);
    }

    fetchConfigurations() {
        console.log('Fetching configurations for incoming call filters');

        // Lets set up the request data to retreive auto attendants
        const reqData = {
            reqAction: 'configurations',
        };

        this.props.getCoreHttp(reqData);
    }

    // prepareDataForLeftBar maps through all callFilters received
    // via http and formats data objects to be consumed by the leftBar
    prepareDataForLeftBar(data) {
        // return on no data
        if (!data) {
            return console.log('No call filters returned');
        }

        // array we will push our new object into
        const leftBarData = [];
        data.map(filterGroup => {
            // Set the title to friendly name if we have it, otherwise just the name
            const title = filterGroup.filter_group_name;
            const subtitle = filterGroup.description;
            const id = filterGroup.filter_group_name;
            const filterType = filterGroup.filterType;

            // push the data into the leftBar array
            return leftBarData.push({ id, title, subtitle, filterType });
        });

        this.setState({ leftBarData: null });

        // finally, lets set the leftBarData to state to update leftbar
        return this.setState({ leftBarData });
    }

    leftBarSelect(callerGroupName) {
        this.setState({ selectedCallFilterGroup: callerGroupName });

        this.props.selectCallFilter(callerGroupName);

        this.navigate('Call filters');

        this.getCallFilterDetails(callerGroupName);
    }

    getCallFilterDetails(callerGroupName) {
        const reqData = {
            reqAction: 'incomingfilter',
            reqObject: callerGroupName,
        };

        this.props.getCoreHttp(reqData);
    }

    addNewFilter(filterGroupName) {
        this.getCallFilterDetails(filterGroupName);

        const data = {
            incoming_phonenumber: '',
            assigned_route: '',
            incoming_area_code: '',
        };

        const reqData = {
            reqAction: 'incomingfilter',
            reqObject: `${filterGroupName}`,
            reqBody: data,
        };

        this.props.postCoreHttp(reqData);

        successNotification({
            title: 'Updated Call Filters!',
            message: `Successfully updated Incoming Call Filter`,
        });
    }

    handleAddNewClick() {
        this.props.selectCallFilter('New Call Filter');
        this.navigate('New');
    }
    handleCancelClick() {
        this.navigate('Call Filters');
        this.props.selectCallFilter('');
    }
    handleDeleteConfirmClose(shouldDelete) {
        shouldDelete && this.handleDeleteCallFilterGroup();
        this.setState({ showingDeleteConfirmModal: false });
    }

    handleDeleteCallFilterGroup() {
        if (!this.state.selectedCallFilterGroup) {
            console.log('No call filter selected');
            return;
        }

        const {
            selectedCallFilterGroup,
            leftBarData,
            callFilters,
        } = this.state;

        const CFindex = callFilters.findIndex(
            x =>
                x.filter_group_name == selectedCallFilterGroup.filter_group_name
        );
        const LBFindex = leftBarData.findIndex(
            x => x.title == selectedCallFilterGroup.filter_group_name
        );

        callFilters.splice(CFindex, 1);
        leftBarData.splice(LBFindex, 1);

        // Lets set state to clear the route and  selected call filters
        this.setState({
            selectedCallFilterGroup: null,
            route: '',
            callFilters,
            leftBarData,
        });

        const reqData = {
            reqAction: 'incomingfilter',
            reqObject: selectedCallFilterGroup.filter_group_name,
        };

        this.props.deleteCoreHttp(reqData);

        // Lets trigger a notification
        successNotification({
            title: 'Deleted!',
            message: `You've deleted your call filter group`,
        });
    }
    handleDeleteElement(index, name) {
        const reqData = {
            reqAction: 'incomingfilter',
            reqObject: `${name}/${index}`,
        };

        this.props.deleteCoreHttp(reqData);
        successNotification({
            title: 'Updated Call Filters!',
            message: `Successfully Deleted Incoming Call Filter`,
        });
    }

    createNewFilterGroup(form) {
        let selectedCallFilterGroup = this.state.selectedCallFilterGroup;

        selectedCallFilterGroup = form.name;
        let selectedFilterType = form.filterType;

        // Lets set state to clear the route
        // and  selected call filters
        this.setState({
            route: '',
        });

        const data = {
            filter_group_name: form.name,
            description: '',
            filter_type: form.filterType,
        };

        const reqData = {
            reqAction: 'incomingfilter',
            reqBody: data,
        };

        this.props.postCoreHttp(reqData);

        successNotification({
            title: 'Updated Call Filters!',
            message: `Successfully updated Incoming Call Filter`,
        });

        this.navigate('Call filters');

        this.setState({
            selectedCallFilterGroup,
            selectedFilterType
        });
        this.prepareDataForLeftBar(this.state.callFilters);
    }

    submitForm(data) {
        const reqData = {
            reqAction: 'incomingfilter',
            reqObject: data.filter_group_name,
            reqBody: data,
        };

        this.props.putCoreHttp(reqData);
    }

    submitSingleFilter(updatedCallFilter, filterGroupName) {
        const reqData = {
            reqAction: 'incomingfilter',
            reqObject: `${filterGroupName}/${updatedCallFilter.index}`,
            reqBody: updatedCallFilter,
        };

        this.props.putCoreHttp(reqData);

        successNotification({
            title: 'Updated Call Filters!',
            message: `Successfully updated Incoming Call Filter ${filterGroupName}`,
        });
    }

    navigate(route) {
        this.setState({ route });
    }

    openCloseMenu() {
        this.setState({ closeMenu: !this.state.closeMenu });
    }

    renderContent() {
        const { callFilters } = this.state;

        const { selectedCallFilterName } = this.props;

        const { route } = this.state;

        let callFilterData = {};
        if (callFilters) {
            callFilters.map(callFilterGroup => {
                if (
                    callFilterGroup.filter_group_name == selectedCallFilterName
                ) {
                    callFilterData = callFilterGroup;
                }
            });
        }

        // const selectedCallFilter = callFilters.find((obj) => selectedCallFilterGroup == obj.name);

        switch (route) {
            case 'New':
                return (
                    <New
                        submitForm={form => this.createNewFilterGroup(form)}
                        selectCallFilter={name =>
                            this.props.selectCallFilter(name)
                        }
                        handleCancelClick={() => this.handleCancelClick(name)}
                    />
                );

            case 'Call filters':
                return (
                    <CallFilters
                        submitForm={data => this.submitForm(data)}
                        submitSingleFilter={(data, filterName) =>
                            this.submitSingleFilter(data, filterName)
                        }
                        addNewFilter={filterGroupName =>
                            this.addNewFilter(filterGroupName)
                        }
                        data={callFilterData}
                        error={this.props.error}
                        handleDeleteElement={(index, name) =>
                            this.handleDeleteElement(index, name)
                        }
                    />
                );

            default:
                return <IncomingCallFilterHelp />;
        }
    }

    render() {
        const { callFilters, httpStatus, classes } = this.props;

        const { leftBarData, route, selectedCallFilterGroup } = this.state;

        let title = '';

        if (callFilters) {
            if (
                selectedCallFilterGroup &&
                selectedCallFilterGroup.filter_group_name
            ) {
                title = selectedCallFilterGroup.filter_group_name;
            }
        }

        const contextMenuOptions = [
            <MenuItem
                disabled={!selectedCallFilterGroup}
                classes={{ root: classes.menuItem }}                
                onClick={() =>{
                    this.setState({ showingDeleteConfirmModal: true });
                    this.openCloseMenu();
                }}
                key={0}
            >
                Delete
            </MenuItem>,
        ];

        const index = this.state.callFilters.index;

        return (
            <MasterView>
                <DetailContainer
                    leftBar={
                        <LeftBar
                            addNew={() => this.handleAddNewClick()}
                            data={leftBarData}
                            key={index}
                            secondaryText="fdsafdas"
                            action={() => this.navigate('Call filters')}
                            pending={httpStatus.pending}
                            select={id => this.leftBarSelect(id)}
                            selected={title}
                            title="Incoming Call Filters"
                        />
                    }
                    title={title}
                    route={route != 'New' ? route : null}
                    return={() => this.setState({ route: null })}
                    contextMenuOptions={contextMenuOptions}
                    closeMenu={this.state.closeMenu}
                >
                    {this.renderContent()}
                </DetailContainer>
                <DeleteModal
                    open={this.state.showingDeleteConfirmModal}
                    title='Are you sure?'
                    delete={() => this.handleDeleteConfirmClose(true)}
                    cancel={() => this.handleDeleteConfirmClose(false)}
                />
            </MasterView>
        );
    }
}

const bindActions = dispatch => ({
    deleteCoreHttp: (reqData, storeKey) =>
        dispatch(deleteCoreHttp(reqData, storeKey)),
    getCoreHttp: (reqData, storeKey) =>
        dispatch(getCoreHttp(reqData, storeKey)),
    postCoreHttp: (reqData, storeKey) =>
        dispatch(postCoreHttp(reqData, storeKey)),
    putCoreHttp: (reqData, storeKey) =>
        dispatch(putCoreHttp(reqData, storeKey)),
    selectCallFilter: callFilterName =>
        dispatch(selectCallFilter(callFilterName)),
});

const mapStateToProps = state => ({
    callFilters: state.incomingCallFilters.data,
    configurations: state.configurations.data,
    metaData: null,
    navigation: state.navigation,
    httpStatus: state.incomingCallFilters.httpStatus,
    selectedCallFilterName: state.incomingCallFilters.selected,
    error: state.incomingCallFilters.error,
});

export default connect(
    mapStateToProps,
    bindActions
)(withStyles(styles)(IncomingCallFilters));
