import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';
import Footer from 'presentational/Footer';
import CountDownComponent from 'presentational/CountDownComponent';

import { getUserPortalHttp, putUserPortalHttp } from 'actions/httpRequest';
import {
  resetFlag,
  selectVoicemail,
  updateReducerData,
  setUpdateRequested,
} from 'actions/voicemailDrop';
import { errorNotification } from 'actions/notifications';
import { isValid } from './voicemailDropUtils';

function VoicemailDropForm(props) {
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(props.selectedVoicemail);
  }, [props.selectedVoicemail]);

  useEffect(() => {
    if (props.successMsg) {
      if (props.edited) {
        props.updateReducerData(props.updateRequested);
      }
    }
  }, [props.successMsg]);

  if (!data) {
    return <Loading />;
  }

  const changeData = (field, info) => {
    if (info === true) {
      info = '1';
    } else if (info === false) {
      info = '0';
    }
    const newData = { ...data };
    newData[field] = info;
    setData(newData);
    const changed = !isEqual(props.selectedVoicemail, newData);
    props.makeChanges(changed, { ...newData });
  }

  const isValidInput = () => {
    const error = isValid(data, props.voiceMails, false);

    if (error) {
      errorNotification(error);
      return false;
    }
    return true;
  }

  const editRequest = () => {
    const { countdown_enabled, countdown, feature_code } = data;
    const payload = {
      countdown,
      countdown_enabled,
      feature_code,
      vm_drop: 1,
    };

    props.setUpdateRequested(data);

    const reqData = {
      reqAction: 'voicemailrecording',
      reqObject: data.name,
      reqBody: payload,
    };
    const storeKey = 'editvoicemailrecording';

    props.putUserPortalHttp(reqData, storeKey);
  }

  const submitForm = (cancel) => {
    if (cancel) {
      props.onCancel();
      props.makeChanges(false, { ...props.selectedVoicemail });
    } else {
      if (isValidInput()) {
        editRequest();
      }
      props.makeChanges(false, { ...data });
    }
  }

  const tenant = app.token.get('tenant');
  const extension = localStorage.getItem('userPortalExtension') || app.token.get('extension');
  const recordingUrl = `${window.BASE_URL ? window.BASE_URL : ''}/api/v1/userportal/${tenant}/${extension}/playvoicemailrecording/${data.name}`
  const recordingName = data.name
  const featureCodeOptions = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const countdownOptions = ['3', '4', '5', '6', '7', '8', '9', '10'];

  return (
    <div style={{
      display: 'flex',
      flex: 1,
      height: '100%',
      background: '#EFEFEF',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '50px',
      overflow: 'auto',
    }}>
      <StandardSettingsPage>
        <FormControlCard
          disabled={true}
          form={data}
          field="friendlyname"
          formControl="TextInput"
          title="Voicemail Drop Name"
          type="text"
          subtitle='The name of your recording'
        />
        <FormControlCard
          form={data}
          field="feature_code"
          formControl="SelectInput"
          title="Feature Code"
          subtitle=""
          submitForm={changeData}
          options={featureCodeOptions}
        />
        <FormControlCard
          form={data}
          field="countdown_enabled"
          formControl="ToggleInput"
          title="Enable an Introduction Countdown"
          subtitle=""
          submitForm={changeData}
          label="No / Yes"
        />
        {data.countdown_enabled === '1' && 
          <>
            <FormControlCard
              form={data}
              field="countdown"
              formControl="SelectInput"
              title="Countdown"
              subtitle='This is the number of seconds you get to speak before the voicemail drop is sent.'
              submitForm={changeData}
              options={countdownOptions}
            />
            <CountDownComponent
              title='Test Countdown Introduction'
              value={data.countdown}
            />
          </>  
        }
        <FormControlCard
          formControl='PlayRecord'
          field='playrecord'
          recordingName={recordingName}
          recordingUrl={recordingUrl}
          title="Play & Download Recording"
          subtitle='Listen to & Download your recording here'
        />
        <Footer
          disabled={isEqual(props.selectedVoicemail, data)}
          onClick={(cancel) => submitForm(cancel)}
        />
      </StandardSettingsPage>
    </div>
  );
}

const bindActions = (dispatch) => ({
  getUserPortalHttp: (reqData) => dispatch(getUserPortalHttp(reqData)),
  putUserPortalHttp: (reqData, storeKey) => dispatch(putUserPortalHttp(reqData, storeKey)),
  resetFlag: () => dispatch(resetFlag()),
  selectVoicemail: (data) => dispatch(selectVoicemail(data)),
  updateReducerData: (data) => dispatch(updateReducerData(data)),
  setUpdateRequested: (data) => dispatch(setUpdateRequested(data)),
});

const mapStateToProps = (state) => ({
  selectedVoicemail: state.voicemailDrop.selectedVoicemail,
  successMsg: state.voicemailDrop.successMsg,
  errorMsg: state.voicemailDrop.errorMsg,
  errorCode: state.voicemailDrop.errorCode,
  routeParams: state.navigation.params,
  voiceMails: state.voicemailDrop.voicemailDropData,
  edited: state.voicemailDrop.edited,
  updateRequested: state.voicemailDrop.updateRequested,
});

VoicemailDropForm.propTypes = {
  voicemails: PropTypes.oneOfType([() => null, PropTypes.object]),
  successMsg: PropTypes.oneOfType([() => null, PropTypes.string]),
  errorMsg: PropTypes.oneOfType([() => null, PropTypes.string]),
  errorCode: PropTypes.oneOfType([() => null, PropTypes.number]),
  routeParams: PropTypes.oneOfType([() => null, PropTypes.string]),
  selectedVoicemail: PropTypes.oneOfType([() => null, PropTypes.object]),
  onCancel: PropTypes.func,
  makeChanges: PropTypes.func,
  edited: PropTypes.bool,
  updateRequested: PropTypes.oneOfType([() => null, PropTypes.object]),
}

export default connect(mapStateToProps, bindActions)(VoicemailDropForm);