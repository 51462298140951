// getOptionsForApplication takes an application and returns the
// selected options for that based on current configurations
export const getOptionsForApplication = (
    application,
    configurations,
    autoAttendants
) => {
    const options = [];
    switch (application) {
        case 'callflow':
            configurations.callflows.map(obj =>
                options.push({
                    value: obj.name,
                    label: obj.friendlyname || obj.name,
                })
            );
            break;
        case 'callgroup':
            configurations.callgroups.map(obj =>
                options.push({
                    value: obj.name,
                    label: obj.friendlyname || obj.name,
                })
            );
            break;
        case 'conferenceroom':
            configurations.conferenceRooms.map(obj =>
                options.push({
                    value: obj.confno,
                    label: obj.name
                        ? obj.confno + ' - ' + obj.name
                        : obj.confno,
                })
            );
            break;
        case 'extension':
        case 'extensionfollowme':
            configurations.users.map(obj =>
                options.push({
                    value: obj.extension,
                    label: `${obj.extension} - ${obj.firstname} ${
                        obj.lastname
                    }`,
                })
            );
            break;
        case 'queue':
            configurations.queues.map(obj =>
                options.push({
                    value: obj.name,
                    label: obj.friendlyname || obj.name,
                })
            );
            break;
        case 'recording':
            configurations.recordings.map(obj =>
                options.push({
                    value: obj.name,
                    label: obj.friendlyname || obj.name,
                })
            );
            break;
        case 'requestcallback':
            options.push({ value: 'Email:', label: 'Email' });
            break;
        case 'route':
            configurations.routes.map(obj =>
                options.push({
                    value: obj.name,
                    label: obj.friendlyname || obj.name,
                })
            );
            break;
        case 'voicemailbox':
            configurations.voicemailboxes.map(obj =>
                options.push({
                    value: obj.mailbox,
                    label: `${obj.mailbox}${
                        obj.fullname ? ' - ' + obj.fullname : ''
                    }`,
                })
            );
            break;
        case 'voicemenu':
            if (!autoAttendants) {
                break;
            }
            autoAttendants.map(obj =>
                options.push({
                    value: obj.name,
                    label: obj.friendlyname || obj.name,
                })
            );
            break;
        case 'zipcoderouter':
            configurations.recordings.map(obj =>
                options.push({ value: obj.name, label: obj.name })
            );
            break;

        default:
            options.push({ value: 'none', label: 'N/A' });
            break;
    }

    return options;
};
