import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import KeyCodeForm from './keyCodeForm';

import { translateApplication } from 'utils/translations';

export default function KeyCodeCardBuilder(props) {
    const classes = props.useStyles();
    const { parent } = props;
    const modalTitle = `Edit application for ${parent.getTitle(parent.props.number)} key`;
    return (
    <Card classes={{ root: classes.card }}>
      <CardHeader
        action={
          <>
            <IconButton
              onClick={() => {
                parent.setState({ showingModal: true });
                parent.props.storeForm();
              }}
              classes={{ root: classes.editButton }}
            >
              <EditIcon />
              </IconButton>
              <IconButton
                classes={{ root: classes.deleteButton }}
                onClick={() => parent.props.removeKeyCode(parent.props.number)}
              >
              <DeleteIcon color="error" />
            </IconButton>
          </>
        }
        onClick={() => {
          parent.setState({ showingModal: true })
          parent.props.storeForm();
        }}
        subheader={`${translateApplication(parent.props.application)} ${(parent.props.value && parent.props.value != 'none') ? ' - '+parent.props.value : ''}`}
        title={parent.getTitle(parent.props.number)}
      >
      </CardHeader>
      <Dialog
        open={parent.state.showingModal}
        onClose={() => parent.handleClose(false)}
        fullWidth={true}
      >
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent><KeyCodeForm {...parent.props} formErrors={parent.state.formErrors} /></DialogContent>
        <DialogActions>
          <Button
            classes={{ root: classes.button }}
            size='large'
            color='primary'
            onClick={() => parent.handleClose(false)}
          >Cancel</Button>
          <Button
            classes={{ root: classes.button }}
            size='large'
            color='primary'
            onClick={() => parent.handleClose(true)}
          >Submit</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
