import React, { Component } from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep'

import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/styles';

import ActionCard from 'presentational/ActionCard';
import DetailContainer from 'presentational/DetailContainer';
import LeftBar from 'presentational/LeftBar';
import MasterView from 'presentational/MasterView';
import MasterHistory from 'presentational/MasterHistory';
import StandardDetailsPage from 'presentational/StandardDetailsPage';
import Loading from 'presentational/Loading';
import DeleteModal from 'presentational/DeleteModal';

import { getCoreHttp } from 'actions/httpRequest';

import { deleteHTTP, getHTTP, postHTTP, putHTTP } from '../../simpleHTTP';

import ManagedRoutesHelp from './help';
import Settings from './settings';
import SaveChangesModal from 'presentational/SaveChangesModal';

import { successNotification, errorNotification } from 'actions/notifications';

const styles = () => ({
    button: {
        fontSize: '100%',
    },
    menuItem: {
        fontSize: '100%',
    },
});

class ManagedRoutes extends Component {
    state = {
        leftBarData: null,
        route: null,
        routeName: '',
        selectedRoute: null,
        fetched: false,
        pending: false,
        config: null,
        showingDeleteConfirmModal: false,
        clickedInsideElements: false,
        showingSaveChangesModal: false,
        newChanges: false,
        childFormData: {},
        newPressed: false,
        selectPressed: false,
        pressedRoute: null,
        newRouteForm: {
            description: '',
            friendlyname: '',
            mode: '',
            modes: [
                { mode: 'day', route: '' },
                { mode: 'night', route: '' },
                { mode: 'temp', route: '' },
            ],
            password: '1234',
        },
        rollBackForm: null,
    };

    clickedElement() {
        var elements = document.querySelectorAll(".header, .leftBarDiv, .detailContainerDiv");
        for (var i = 0; i < elements.length; i++) {
          elements[i].addEventListener("click", () => {
            this.unsavedChanges();
          });
        }
      }

    unsavedChanges() {
        const { clickedInsideElements, newChanges } = this.state;
        if (clickedInsideElements && newChanges) {
            this.setState({ showingSaveChangesModal: true });
        }
      }

    componentDidMount() {
        const { fetched } = this.state;
        if (!fetched) {
            this.setState({ pending: true });
            this.fetchConfigurations();
            this.fetchManagedRoutes();
        }
        if (this.props.routeParams && this.props.routeParams.name) {
            this.leftBarSelect(this.props.routeParams.name);
        }
    }

    componentWillUnmount() {
        this.setState({
            route: null,
            routeName: null,
            selectedRoute: null,
            leftBarData: null,
            fetched: false,
            pending: false,
            submitPending: true,
            config: null,
        });
    }

    fetchConfigurations() {
        const reqData = {
            reqAction: 'configurations',
        };
        this.props.getCoreHttp(reqData);
    }
    async fetchManagedRoutes() {
        this.setState({ fetched: true });
        let managedRoutes = await getHTTP('core', 'managedroute');
        if (managedRoutes.error) {
            this.setState({ pending: false });
            const error = error.error;
            return;
        }
        this.setState({ pending: false, leftBarData: null });
        managedRoutes = managedRoutes.data.data;
        this.prepareDataForLeftBar(managedRoutes);
    }

    async prepareDataForLeftBar(managedRoutes) {
        this.setState({ leftBarData: null });
        // return on no data
        if (!managedRoutes) {
            return console.log('No routes returned');
        }
        const leftBarData = [];
        managedRoutes.map(item => {
            const title = item.friendlyname;
            const subtitle = '';
            const id = item.name;
            // push the data into the leftBar array
            return leftBarData.push({ id, title, subtitle });
        });
        await this.setState({ leftBarData });
    }

    select(route) {
        if (!this.state.showingSaveChangesModal) {
            app.navigate(`managedroutes/${route}`);
            this.leftBarSelect(route);
        } else {
            this.setState({ selectPressed: true, pressedRoute: route });
        }
    }

    async leftBarSelect(route) {
        if (route.toLowerCase() === 'new') {
            this.navigate('new');
            return;
        }
        this.setState({ pending: true });
        let managedRoute = await getHTTP('core', 'managedroute', route);
        if (managedRoute.error) {
            this.setState({ pending: false });
            const error = error.error;
            return;
        }
        managedRoute = managedRoute.data.data;
        const rollBackForm = cloneDeep(managedRoute);
        this.setState({ pending: false });
        this.clickedElement();
        this.setState({
            selectedRoute: managedRoute,
            rollBackForm,
            route: null,
            routeName: route,
        });
    }

    handleAddNewClick() {
        if (!this.state.showingSaveChangesModal) {
            app.navigate(`managedroutes/new`, {trigger: true});
        } else {
            this.setState({ newPressed: true })
        }
    }

    async deleteManagedRoute() {
        const reqObject = this.state.routeName;
        this.setState({ submitPending: true });
        const submit = await deleteHTTP('core', 'managedroute', reqObject);
        if (submit.error) {
            const error = submit.error;
            errorNotification({
                title: 'Error Deleting Managed Route',
                message: error.message,
            });
            this.setState({ submitPending: false });
            return;
        }
        // Remove from leftbar after successful delete
        let { leftBarData } = this.state;
        leftBarData = leftBarData.filter(route => {
            return route.title !== this.state.routeName;
        });
        this.setState({
            leftBarData,
            route: null,
            showingDeleteConfirmModal: false,
            routeName: '',
        });
        app.navigate('managedroutes', { trigger: true });
        successNotification({
            title: 'Success!',
            message: `Deleted Managed Route`,
        });
    }

    async submitSettingsForm(form) {
        const reqObject = this.state.routeName;
        this.setState({ submitPending: true });
        const submit = await putHTTP('core', 'managedroute', form, reqObject);
        if (submit.error) {
            const error = submit.error;
            errorNotification({
                title: 'Error Updating Managed Route',
                message: error.message,
            });
            this.setState({ submitPending: false });
            return;
        }
        successNotification({
            title: 'Success!',
            message: `Updated Managed Route`,
        });
        this.setState({
            submitPending: false,
        });
    }

    async submitNewManagedRoute(form) {
        const reqObject = this.state.routeName;
        this.setState({ submitPending: true });
        const submit = await postHTTP('core', 'managedroute', form, reqObject);
        if (submit.error) {
            const error = submit.error;
            errorNotification({
                title: 'Error Updating Managed Route',
                message: error.message,
            });
            this.setState({ submitPending: false });
            return;
        }
        this.setState({ submitPending: false });
        let { leftBarData } = this.state;
        const newSidebarRoute = {
            id: submit.data.body.name,
            title: form.friendlyname,
            subtitle: '',
        };
        leftBarData.push(newSidebarRoute);
        this.setState({
            leftBarData,
            submitPending: false,
            route: null,
        });
        this.select(submit.data.body.name);
        successNotification({
            title: 'Success!',
            message: `Created Managed Route`,
        });
    }

    goToHelp() {
        const win = window.open(
            'http://help.fluentcloud.com/support/solutions',
            '_blank'
        );
        win.focus();
    }

    navigate(route) {
        if (route === 'new') {
            this.setState({ selectedRoute: '', routeName: '' });
        }
        this.setState({ route });
    }

    managedRouteOptions() {
        return [
            <ActionCard
                key="Settings"
                title="Settings"
                subtitle="Manage settings for managed route"
                action={() => this.navigate('Settings')}
            />,
            <ActionCard
                key="History"
                title="History"
                subtitle="See who made changes to this managed route"
                action={() => this.navigate('History')}
            />,
        ];
    }

    clickedNode() {
        this.setState({ clickedInsideElements: true })
    }

    madeChanges(data) {
        this.setState({
          newChanges: true,
          childFormData: data,
        });
      }


    handleDeleteModal(open) {
        this.setState({
            showingDeleteConfirmModal: open,
        });
    }

    onCancel() {
        if (this.state.route === 'new') {
            const newRouteForm= {
                description: '',
                friendlyname: '',
                mode: '',
                modes: [
                    { mode: 'day', route: '' },
                    { mode: 'night', route: '' },
                    { mode: 'temp', route: '' },
                ],
                password: '1234',
            };
            this.setState({
                newRouteForm,
                route: null,
            });
            app.navigate('#managedroutes', { trigger: true });
        } else {
            this.setState({
                route: this.state.selectedRoute.name,
                selectedRoute: cloneDeep(this.state.rollBackForm),
            });
        }
    }

    renderContent() {
        const { route, selectedRoute, pending } = this.state;
        const { routes, phonenumbers } = this.props;
        if (pending) {
            return <Loading />;
        }
        if (!route && !selectedRoute) {
            return <ManagedRoutesHelp />;
        }
        switch (route) {
            case 'new':
                return (
                    <Settings
                        submitSettingsForm={form => {
                            this.setState({
                                showingSaveChangesModal: false,
                                newChanges: false,
                                clickedInsideElements: false,
                                clickedSurroundingElements: false,
                                newPressed: false,
                                selectPressed: false,
                            });
                            this.submitNewManagedRoute(form)
                        }}
                        data={this.state.newRouteForm}
                        routes={routes}
                        newRoute={true}
                        clickedNode={() => this.clickedNode()}
                        madeChanges={(data) => this.madeChanges(data)}
                        onCancel={() => this.onCancel()}
                    />
                );
            case 'History':
                return <MasterHistory data={selectedRoute.config.log} />;
            case 'Settings':
                return (
                    <Settings
                        submitSettingsForm={form => {
                            this.setState({
                                showingSaveChangesModal: false,
                                newChanges: false,
                                clickedInsideElements: false,
                                clickedSurroundingElements: false,
                                newPressed: false,
                                selectPressed: false,
                            });
                            this.submitSettingsForm(form)
                        }}
                        data={selectedRoute}
                        routes={routes}
                        newRoute={false}
                        phoneNumbers={phonenumbers}
                        clickedNode={() => this.clickedNode()}
                        madeChanges={(data) => this.madeChanges(data)}
                        onCancel={() => this.onCancel()}
                    />
                );
            default:
                return (
                    <StandardDetailsPage cards={this.managedRouteOptions()} />
                );
        }
    }

    saveChanges() {
        let redirect = this.props.routeParams.name;
          if (this.state.newPressed) {
              redirect = 'new';
          } else if (this.state.selectPressed) {
              redirect = this.state.pressedRoute;
          }
          this.setState({
            showingSaveChangesModal: false,
            newChanges: false,
            clickedInsideElements: false,
            clickedSurroundingElements: false,
            newPressed: false,
            selectPressed: false,
          });
          this.submitSettingsForm(this.state.childFormData);
          app.navigate(`managedroutes/${redirect}`, {trigger: true});
    }

    discardChanges() {
        let redirect = this.props.routeParams.name;
          if (this.state.newPressed) {
              redirect = 'new';
          } else if (this.state.selectPressed) {
              redirect = this.state.pressedRoute;
          }
          this.setState({
            showingSaveChangesModal: false,
            newChanges: false,
            clickedInsideElements: false,
            clickedSurroundingElements: false,
            newPressed: false,
            selectPressed: false,
          }, () => app.navigate(`managedroutes/${redirect}`, {trigger: true}));
    }

    onClose() {
        this.leftBarSelect(this.props.routeParams.name);
    }

    render() {
        const { routes, classes } = this.props;
        if (!routes) {
            return <Loading />;
        }
        const { leftBarData, route, routeName, pending } = this.state;
        const contextMenuOptions = [
            <MenuItem
                disabled={!routeName}
                classes={{ root: classes.menuItem }}
                onClick={() => this.handleDeleteModal(true)}
                key={0}
            >Delete</MenuItem>,
            <MenuItem
                onClick={() => this.goToHelp()}
                classes={{ root: classes.menuItem }}
                key={1}
            >Help</MenuItem>,
        ];

        return (
            <MasterView>
                <DetailContainer
                    leftBar={
                        <LeftBar
                            addNew={() => this.handleAddNewClick()}
                            data={leftBarData}
                            pending={pending}
                            noDescription
                            selected={routeName}
                            select={id => this.select(id)}
                            title="Managed Routes"
                        />
                    }
                    title={routeName || ''}
                    route={route != 'new' ? route : null}
                    return={() => {
                        this.setState({ route: null });
                    }}
                    contextMenuOptions={contextMenuOptions}
                >
                    {this.renderContent()}
                </DetailContainer>
                <DeleteModal
                    open={this.state.showingDeleteConfirmModal}
                    title='Are you sure?'
                    content={<p>Do you really want to delete this managed route? This action is irreversible.</p>}
                    delete={() => this.deleteManagedRoute()}
                    cancel={() => this.handleDeleteModal(false)}
                />
                <SaveChangesModal
                    saveChange={()=>this.saveChanges()}
                    discardChange={() =>this.discardChanges()}
                    cancelModal={() =>this.onClose()}
                    show={this.state.showingSaveChangesModal}
                />
            </MasterView>
        );
    }
}

const bindActions = dispatch => ({
    getCoreHttp: (reqData, storeKey) =>
        dispatch(getCoreHttp(reqData, storeKey)),
});

const mapStateToProps = state => ({
    routes:
        state.configurations &&
        state.configurations.data &&
        state.configurations.data.routes
            ? state.configurations.data.routes
            : null,
    phonenumbers: 
        state.configurations &&
        state.configurations.data &&
        state.configurations.data.phonenumbers
            ? state.configurations.data.phonenumbers
            : null,
    routeParams: state.navigation.params,
});

export default connect(
    mapStateToProps,
    bindActions
)(withStyles(styles)(ManagedRoutes));
