import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import StandardSettingsPage from 'presentational/StandardSettingsPage';

import Record from './record';
import Upload from './upload';

function CreateVoicemailDropForms(props) {
  const [form, setForm] = useState({ tab: 0 });
  const [mediaQuery, setMediaQuery] = useState(null);

  useEffect(() => {
    const mq = window.matchMedia('(min-width: 800px)');
    setMediaQuery(mq);
    mq.addListener((query) => setMediaQuery(query));
  }, []);

  const changeOption = (field, info) => {
    const newForm = {...form};
    newForm[field] = info;
    setForm(newForm);
  }

  const minWidth = mediaQuery && mediaQuery.matches;
  const classes = makeStyles({
    tabsRoot:{
      backgroundColor: '#2a72b9',
      color: '#FFFFFF',
      fontWeight: 'bolder',
      width: minWidth ? '50%' : '100%',
      minWidth: '400px',
    },
    tabRoot:{
      width:'50%',
      maxWidth:'none',
    },
  })();

  return (
    <div style={{
      display: 'flex',
      flex: 1,
      height: '100%',
      background: '#EFEFEF',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '50px',
      overflow: 'auto',
    }}>
      <StandardSettingsPage>
        <Tabs value={form.tab} onChange={(e, value) => changeOption('tab', value)} classes={{ root: classes.tabsRoot }}>
          <Tab label="Record from Browser" classes={{ root: classes.tabRoot }} />
          <Tab label="Upload A Recording" classes={{ root: classes.tabRoot }}  />
        </Tabs>
        {form.tab === 0 && <Record onCancel={props.onCancel}/>}
        {form.tab === 1 && <Upload onCancel={props.onCancel}/>}
      </StandardSettingsPage>
    </div>
  )
}

CreateVoicemailDropForms.propTypes = {
  onCancel: PropTypes.func,
}

export default CreateVoicemailDropForms;