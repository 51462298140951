import React from 'react';
import PropTypes from 'prop-types';

const PageNotFound = ({ backUrl = '' }) => {

    return (
        <div style={style.wrapper}>
            <StandardSettingsPage>
                <div style={style.notFound}>
                    <Card>
                        <CardHeader title="404 Page Not Found" />
                        <CardContent style={{ width: '100%' }}>
                            <p>
                                Oh no! It looks like you tried to go to a page that doesn't
                                exist! Click{' '}
                                <a href={`#${backUrl}`}>
                                    <strong>here</strong>
                                </a>{' '}
                                to go back.
                            </p>
                        </CardContent>
                    </Card>
                </div>
            </StandardSettingsPage>
        </div>
    );
}

const style = {
    notFound: {
        width: '50%',
        minWidth: '400px',
    },
    wrapper: {
        display: 'flex',
        flex: 1,
        height: '100%',
        background: '#EFEFEF',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '50px',
        overflow: 'auto',
    },
}

PageNotFound.defaultProps = {
    backUrl: ''
};

PageNotFound.propTypes = {
    backUrl: PropTypes.string.isRequired,
};

export default PageNotFound;