import React from 'react';

import ContentAdd from '@material-ui/icons/Add';

import ContextMenuIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';

const Title = (props) =>{
  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
  <div style={style.wrapper}>

      <IconButton onClick={handleClick}>
        <ContextMenuIcon />
      </IconButton>

    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
      {props.contextMenuOptions.map((item) => item)}
    </Menu>

    <div style={style.title}>{props.title}</div>
  </div>
);}

const style = {
    button: {

    },
    buttonLabel: {

    },
    buttonWrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        opacity: 0.6,
    },
    title: {
        margin: 20,
        flex: 1,
        color: '#737373',
        fontSize: 18,
    },
    wrapper: {
        alignItems: 'center',
        backgroundColor: '#EFEFEF',
        display: 'flex',
        minHeight: 70,
        paddingBottom: 10,
        paddingRight: 35,
        paddingTop: 15,
    },
};

export default Title;
