import React, { Component } from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import ErrorCard from 'presentational/ErrorCard';
import Loading from 'presentational/Loading';
import NewCardPlaceholder from 'presentational/NewCardPlaceholder';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import SelectInput from 'presentational/formControls/SelectInput';

import KeyCodeCard from './keyCodeCard';
import KeyCodeForm from './keyCodeForm';

class KeyCodes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            form: {},
            revertedForm: {},
            submitError: null,
            showingModal: false,
            selectableNewKeys: [],
            selectedNewKeyCode: '',
            mediaQuery: false,
            formErrors: {
                application: '',
                number: '',
                value: '',
            }
        };
    }

    componentDidMount() {

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));

        if (!this.props.data) {
            return;
        }

        const selectableNewKeys = [];
        Object.keys(this.props.data).map((field) => {
            if (this.isKeyCodeField(field)) {
                if (!this.props.data[field]) {
                    selectableNewKeys.push({ value: field[0], label: this.getKeyCodeLabel(field[0]) });
                }
            }
        });

        this.setState({ form: this.props.data, selectableNewKeys, revertedForm: this.props.data });
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.data) {

            const selectableNewKeys = [];
            Object.keys(nextProps.data).map((field) => {
                if (this.isKeyCodeField(field)) {
                    if (!nextProps.data[field]) {
                        selectableNewKeys.push({ value: field[0], label: this.getKeyCodeLabel(field[0]) });
                    }
                }
            });

            this.setState({ form: nextProps.data, selectableNewKeys });
        }
    }

    async submitForm(submittedForm) {


        const form = submittedForm || this.state.form;
        Object.keys(form).forEach(field =>{
            if(field.includes('application') && form[field] == "conferenceroom"){
                const keyNumber = field.slice(0,1);
                if(form[`${keyNumber}_value`] == "No Room"){
                    form[`${keyNumber}_value`] = "";
                }
            }
        });
        const rollbackForm = Object.assign({}, form);

        // If no error, lets set the data
        // on the form and set it to state without errors
        this.setState({
            form,
            errors: {},
        });

        const submitError = await this.props.submitForm(form);

        if (submitError) {
            this.setState({ submitError });
        } else {
            this.setState({ submitError: null });
        }

        const selectableNewKeys = [];
        Object.keys(this.state.form).map((field) => {
            if (this.isKeyCodeField(field)) {
                if (!this.state.form[field]) {
                    selectableNewKeys.push({ value: field[0], label: this.getKeyCodeLabel(field[0]) });
                }
            }
        });

        this.setState({ selectableNewKeys });
    }

    validateField(field, data) {

        // We want to make sure our wait
        // times are actually numbers
        // and greater than 0
        switch (field) {
        case 'responsewait':
        case 'keypresswait':
        case 'i_limit':
        case 'none_limit':
        default:
            return null;
        }
    }

    updateForm(field, value) {
        const form = Object.assign({}, this.state.form);

        if (!form.hasOwnProperty(field)) {
            console.error('Unable to update form, field not valid: ', field);
            return;
        }

        //We got the field and the value, lets set that to the form now
        form[field] = value;

        //If we are setting/resetting the application, lets make sure we dont
        //carry over old arguments/values
        if(field.includes("application")){
            const key = field.split("_")[0];
            form[`${key}_arguments`] = "";
            form[`${key}_value`] = "";
        }

        this.setState({ form });
    }

    storeForm(form){
        this.setState({revertedForm: Object.assign({}, form)});
    }

    revertForm(){
        this.setState({form: this.state.revertedForm});
    }

    removeKeyCode(number) {

        if (number == 'n') {
            number = 'none';
        }

        const form = Object.assign({}, this.state.form);
        form[`${number}_application`] = '',
        form[`${number}_value`] = '',
        form[`${number}_arguments`] = '',
        form[`${number}_prepend-callerid`] = '',

        this.submitForm(form);
    }

    isKeyCodeField(formData) {
        switch (formData) {
        case '0_application':
        case '1_application':
        case '2_application':
        case '3_application':
        case '4_application':
        case '5_application':
        case '6_application':
        case '7_application':
        case '8_application':
        case '9_application':
        case '0_application':
        case 'i_application':
        case 't_application':
        case 'none_application':
        case 'pound_application':
        case 'star_application':
            return true;

        default:
            return null;
        }
    }

    isKeyCodeFieldForRender(formData) {
        switch (formData) {
        case '0_application':
        case '1_application':
        case '2_application':
        case '3_application':
        case '4_application':
        case '5_application':
        case '6_application':
        case '7_application':
        case '8_application':
        case '9_application':
        case '0_application':
        case 'i_application':
        case 't_application':
        case 'none_application':
        case 'pound_application':
        case 'star_application':
            return this.renderKeyCodeCard(formData);

        default:
            return null;
        }
    }

    getKeyCodeLabel(key) {

        switch (key) {
        case 'i':
            return 'Invalid';
        case 't':
            return 'Timeout';
        case 'n':
            return 'None';
        case 'p':
            return '#';
        case 's':
            return '*';
        default:
            return key;
        }
    }

    renderKeyCodeCard(formData) {

        const { form } = this.state;
        let key = formData[0];

        switch (key) {
        case '':
        case 'n':
            key = 'none';
            break;
        case 'p':
            key = 'pound';
            break;
        case 's':
            key = 'star';
            break;
        }

        if (!form[formData]) {
            return null;
        }

        // The key we are working
        // with is the first digit
        // of the form field
        return (
            <KeyCodeCard
              application={form[key+'_application']}
              value={form[key+'_value']}
              arguments={form[key+'_arguments']}
              form={form}
              number={key}
              key={key}
              updateForm={(field, value) => this.updateForm(field, value)}
              submitForm={(form) => this.submitForm(form)}
              removeKeyCode={(number) => this.removeKeyCode(number)}
              storeForm={() => this.storeForm(this.state.form)}
              revertForm={() => this.revertForm()}
            />
        );
    }

    selectNewNumberInput() {

        return (
          <div>
            <h3>Add new key code</h3>

            <SelectInput
              errorText={this.state.formErrors.number}
              field="keyCode"
              placeholder="Key"
              options={this.state.selectableNewKeys}
              onChange={(val) => this.setState({ selectedNewKeyCode: val })}
              value={this.state.selectedNewKeyCode}
            />
          </div>
        );
    }

    handleClose(submit) {

        let formErrors = {
            application: '',
            number: '',
            value: '',
        };

        this.setState({ formErrors });
        if (submit) {

            formErrors = this.validateForm();
            if (Object.keys(formErrors).some(err => formErrors[err])) {

                this.setState({ formErrors });
                return;
            }

            this.props.submitForm(this.state.form);
            this.setState({showingModal: false, revertedForm: this.state.form})
            return;
        }

        this.revertForm();
        this.setState({ showingModal: false, selectedNewKeyCode: '' });
    }

    validateForm() {

        const { form } = this.state;

        let formErrors = {};

        if (!this.state.selectedNewKeyCode) {
            formErrors.number = 'Please select valid entry';
            return formErrors;
        }

        let key = this.state.selectedNewKeyCode;

        switch (key) {
        case 'n':
            key = 'none';
        break;
        case 'p':
            key = 'pound';
        break;
        case 's':
            key = 'star';
        break;
        }

        const application = `${key}_application`;
        const value = `${key}_value`;

        if (!form[application]) {
            formErrors.application = 'Application required';
        }

        let needsArgument = false;
        switch (form[application]) {
            case 'callflow':
            case 'callgroup':
            case 'extension':
            case 'extensionfollowme':
            case 'queue':
            case 'requestcallback':
            case 'route':
            case 'voicemailbox':
            case 'voicemenu':
            case 'zipcoderouter':
                needsArgument = true;
                break;
            default:
                needsArgument = false;
                break;
        }

        if (needsArgument && !form[value]) {

            formErrors.value = 'Value required for this application';
        }

        return formErrors;
    }

    render() {

        if (!this.props.data || !this.state.form) {
            return (
              <StandardSettingsPage>
                <Loading />
              </StandardSettingsPage>
            );
        }

        const { form } = this.state;

        const minWidth = this.state.mediaQuery.matches;

        return (
          <StandardSettingsPage>

            <NewCardPlaceholder
              title="Add new key code"
              onClick={() => this.setState({ showingModal: true })}
            />

            {this.state.submitError ?
              <ErrorCard data={this.state.submitError} />
              : null
            }

            {Object.keys(this.state.form).map((key) => {
                return this.isKeyCodeFieldForRender(key);
            })}


            <Dialog
              open={this.state.showingModal}
              onClose={() => this.handleClose(false)}
              fullWidth={true}
              scroll='body'
            >
                <DialogTitle>{this.selectNewNumberInput()}</DialogTitle>
                <DialogContent>
                    <KeyCodeForm
                        form={form}
                        formErrors={this.state.formErrors}
                        updateForm={(field, value) => this.updateForm(field, value)}
                        number={this.state.selectedNewKeyCode}
                    />
                </DialogContent>
                    <DialogActions>
                        <Button
                            style={{ fontSize: '100%' }}
                            color='primary'
                            size='large'
                            onClick={() => this.handleClose(false)}
                        >Cancel</Button>
                        <Button
                            style={{ fontSize: '100%' }}
                            color='primary'
                            size='large'
                            onClick={() => this.handleClose(true)}
                        >Submit</Button>
                    </DialogActions>
                </Dialog>           

          </StandardSettingsPage>
        );
    }
}

const bindActions = (dispatch) => ({

});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, bindActions)(KeyCodes);
