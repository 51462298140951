import React, { useState } from 'react';
import FormControlCard from '../../presentational/FormControlCard';
import StandardSettingsPage from '../../presentational/StandardSettingsPage';
import Loading from '../../presentational/Loading';
import Footer from '../../presentational/Footer';
import { useShortCodes } from './shortCodesContext';
import PhoneInput from 'react-phone-input-intl';
import 'react-phone-input-intl/dist/style.css';
import DescriptionIcon from '@material-ui/icons/Description';
import DialIcon from '@material-ui/icons/Dialpad';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';

const styles = {
    container: {
        display: 'flex',
        flex: 1,
        height: '100%',
        background: '#EFEFEF',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '50px',
        overflow: 'auto',
    },
};

const ShortCodesForm = ({ formKey }) => {
    // state
    const [dropdownOpen, setDropdownOpen] = useState(false);

    // context
    const {
        buttonDisabled,
        setButtonDisabled,
        setCountryCode,
        setShortCodesForm,
        shortCodesForm,
        shortCodeDetails,
        submitFullForm,
    } = useShortCodes();

    const forwardingChange = e => {
        let numberInput = e[0] == '+' ? e.slice(1) : e;
        setShortCodesForm({ ...shortCodesForm, forwardingNumber: numberInput });
        buttonDisabled ? setButtonDisabled(false) : null;
    };

    const codeEntry = (field, e) => {
        setShortCodesForm({ ...shortCodesForm, codeNumber: e });
        buttonDisabled ? setButtonDisabled(false) : null;
    };
    const descriptionEntry = (field, e) => {
        setShortCodesForm({ ...shortCodesForm, name: e });
        buttonDisabled ? setButtonDisabled(false) : null;
    };

    if (formKey !== 'new') {
        if (!shortCodeDetails) {
            return <Loading />;
        }
    }
    return (
        <div style={styles.container}>
            <StandardSettingsPage>
                <FormControlCard
                    form={shortCodesForm}
                    field="name"
                    icon={<DescriptionIcon />}
                    formControl="TextInput"
                    title="Description"
                    type="text"
                    subtitle="The Description for This Code"
                    submitForm={descriptionEntry}
                />
                <FormControlCard
                    form={shortCodesForm}
                    field="codeNumber"
                    icon={<DialIcon />}
                    inputProps={{ maxLength: '4' }}
                    formControl="TextInput"
                    title="Code Number"
                    type="number"
                    subtitle="2 - 4 Digit Number for This Code"
                    submitForm={codeEntry}
                />
                <FormControlCard
                    form={shortCodesForm}
                    field="forwardingNumber"
                    formControl="PhoneNumberInput"
                    title="Forwarding Number"
                    subtitle="The Phone Number you would like to directly forward the call to."
                    icon={<PhoneForwardedIcon />}
                    expandCard={dropdownOpen}
                    phoneInput={
                        <PhoneInput
                            placeholder={'Set Forwarding Number'}
                            defaultCountry={'us'}
                            value={`${shortCodesForm.forwardingNumber.replace(/[^0-9]/g, '')}`}
                            onChange={forwardingChange}
                            getCountryCode={setCountryCode}
                            countryCodeEditable={false}
                            isDropdownShowing={setDropdownOpen}
                            autoFormat={false}
                        />
                    }
                />

                <Footer onClick={submitFullForm} disabled={buttonDisabled} />
            </StandardSettingsPage>
        </div>
    );
};

export default ShortCodesForm;
