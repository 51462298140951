import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';

import Loading from 'presentational/Loading';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import FormControlCard from 'presentational/FormControlCard';
import Footer from 'presentational/Footer';

import { formatNumber } from 'utils/phoneNumbers';
import { errorNotification } from 'actions/notifications';
import { nameSrt } from 'utils/misc';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import NameIcon from '@material-ui/icons/Input';
import DescriptionIcon from '@material-ui/icons/Description';
import ModeIcon from '@material-ui/icons/Settings';
import PasswordIcon from '@material-ui/icons/Lock';
import DayModeIcon from '@material-ui/icons/WbSunny';
import NightModeIcon from '@material-ui/icons/Brightness3';
import TempModeIcon from '@material-ui/icons/Cloud';
import PhoneIcon from '@material-ui/icons/Phone';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            form: {},
            rollbackForm: {},
            submitError: null,
            newChanges: false,
        };
        this.phonenumbersFilter = this.phonenumbersFilter.bind(this);
        this.renderPhoneNumberCard = this.renderPhoneNumberCard.bind(this);
        this.renderNoNumberCard = this.renderNoNumberCard.bind(this);
		this.renderNumbersListCard = this.renderNumbersListCard.bind(this);
		this.checkErrors = this.checkErrors.bind(this);
        this.checkModesSelected = this.checkModesSelected.bind(this);
        this.createRollBackForm = this.createRollBackForm.bind(this);
        this.handleNodeClick = this.handleNodeClick.bind(this);
        this.processModeOptions = this.processModeOptions.bind(this);
    }

    componentDidMount() {
        let form = cloneDeep(this.props.data);
        delete form.config;
        form.pin = this.props.data.password;
        this.setState({ form }, () => this.createRollBackForm());
        document.addEventListener('mousedown', this.handleNodeClick, false);
    }

    createRollBackForm() {
        const rollbackForm = cloneDeep(this.props.data);
        rollbackForm.pin = this.props.data.password;
        this.setState({ rollbackForm });
    }

    handleNodeClick(e) {
        if (this.node) {
            if (this.node.contains(e.target)) {
                this.props.clickedNode();
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.data !== state.form) {
            let form = props.data;
            form.pin = props.data.password;
            return {
                form,
            };
        }
        return null;
    }

    submitForm(field, data) {
        const { form } = this.state;
        if(field == 'pin'){
            form['password'] = data;
        }
        if (field === '0' || field === '1' || field === '2') {
            if (this.state.rollbackForm.modes[field] && this.state.rollbackForm.modes[field].route == data) {
                console.log('No update');
                return;
            }
            if (data === 'Select a route') {
                data = '';
                if ((field === '0' && form.mode === 'day')
                    || (field === '1' && form.mode === 'night')
                    || (field === '2' && form.mode === 'temp')) {
                        form.mode = '';
                }
            }
            else if (form.mode === '') {
                if (field === '0') form.mode = 'day';
                if (field === '1') form.mode = 'night';
                if (field === '2') form.mode = 'temp';
            }
			if (!form['modes'][field]) {
				let mode = {};
				if (field === '0') mode = { mode: 'day', route: data };
				if (field === '1') mode = { mode: 'night', route: data };
				if (field === '2') mode = { mode: 'temp', route: data };
				form['modes'][field] = mode;
			}
			else {
				form['modes'][field].route = data;
            }
            if (form.mode === '') {
                form.modes.forEach((mode) => {
                    if (mode.route !== '' && form.mode === '') {
                        form.mode = mode.mode;
                    }
                });
            }
        } else {
            form[field] = data;
        }
        this.setState({
            form,
            newChanges: true,
            errors: { [field]: '' },
        }, () =>this.props.madeChanges(form));
    }

	checkModesSelected() {
		let selected = false;
		this.state.form.modes.forEach(mode => {
			if(mode.route !== '') selected = true;
		});
		console.log(selected);
		return selected;
	}
	
	checkErrors() {
		let errorOccured = false;
		let error = this.validateField('description', this.state.form.description);
		if (error) {
			errorNotification({title: 'Error!', message: error});
			errorOccured = true;
		}
		error = this.validateField('friendlyname', this.state.form.friendlyname);
		if (error) {
			errorNotification({title: 'Error!', message: error});
			errorOccured = true;
		}
		error = this.validateField('password', this.state.form.pin);
		if (error) {
			errorNotification({title: 'Error!', message: error});
			errorOccured = true;
		}
		const selected = this.checkModesSelected()
		if (!selected) {
			errorNotification({title: 'Error!', message: 'You must have at least one mode routed'});
			errorOccured = true;
		}
		return errorOccured;
	}

    validateField(field, data) {
        switch (field) {
            case 'description':
                if (data.length > 50) {
                    return 'Description exceeds 50 characters';
                } else {
                    return null;
                }
            case 'friendlyname':
                if (data.length > 30) {
                    return 'Name exceeds 30 characters';
                } else if(data.length === 0) {
					return 'Managed Route must have a name';
				} else {
                    return null;
				}
			case 'password':
				if (data === '') {
					return 'Password cannot be blank';
				} else if(isNaN(data)) {
					return 'Password must be digits only';
				} else {
					return null;
				}
            default:
                return null;
        }
    }

    phonenumbersFilter(managedRouteName, numbers) {
        const filteredNumbers = [];
        numbers.forEach((number) => {
            if (number.assignedmanaged === managedRouteName) {
                filteredNumbers.push(
				    <a href={`#phonenumbers/${number.phonenumber}`}>
                        {formatNumber(number.phonenumber)}
                        <i className="icon-external-link"></i>
                    </a>
				);
            }
        });
        const rows = [];
        const length = filteredNumbers.length;
        for (let i=0; i < length; i+=3) {
            rows.push(
                <TableRow key={i}>
                    <TableCell>{filteredNumbers[i]}</TableCell>
                    <TableCell>{i+1 < length ? filteredNumbers[i+1] : null}</TableCell>
                    <TableCell>{i+2 < length ? filteredNumbers[i+2] : null}</TableCell>
                </TableRow>
            );
        }
        return rows;
    }

    renderPhoneNumberCard(phonenumbers) {
        if (phonenumbers.length) {
            return this.renderNumbersListCard(phonenumbers);
        }
        return this.renderNoNumberCard();
    }

    renderNumbersListCard(phonenumbers) {
        const height = phonenumbers.length > 8 ? '400px' : 'auto';
        const content = (
            <Table height={height}>
                <TableBody>
                    {phonenumbers}
                </TableBody>
            </Table>
        );
        return (
			<FormControlCard
                form={{ content }}
                field="content"
                formControl="PhonenumbersList"
                title="Phone Numbers"
                subtitle=''
                icon={<PhoneIcon/>}
            />
        );
    }

    renderNoNumberCard() {
        const content = (
            <div style={{ margin: '20px' }}>
                <p>
                    No numbers attached to this managed route. <a href="#phonenumbers">Attach one now!<i className="icon-external-link"></i></a>
                </p>
            </div>
        );
        return (
            <FormControlCard
                form={{ content }}
                field="content"
                formControl="PhonenumbersList"
                title="Phone Numbers"
                subtitle=''
                icon={<PhoneIcon/>}
            />
        );
    }

    processModeOptions(routes) {
        const sortedRoutes = routes.sort(nameSrt('name', true));
        const sorteRoutesObjectArray = sortedRoutes.map(route => { return {label: route.friendlyname || route.name, value: route.name } })
        return [{label: 'Select a route', value: 'Select a route'}].concat(sorteRoutesObjectArray);
    }

    submitFullForm(cancel){
        if(cancel){
            this.setState({ form: cloneDeep(this.state.rollbackForm) });
            this.props.onCancel()
        }else{
            const error = this.checkErrors();
            if (!error) {
                this.props.submitSettingsForm(this.state.form);
                this.setState({ rollbackForm: cloneDeep(this.state.form) });
            }
        }
    }

    render() {
        const { errors, form } = this.state;
        const {
            data,
            submitSettingsForm,
            pending,
            routes,
            newRoute,
            phoneNumbers,
        } = this.props;
        if (!form || !data) {
            return <Loading />;
        }
        let phonenumbers = [];
        if (!newRoute) {
            phonenumbers = this.phonenumbersFilter(this.state.form.friendlyname, phoneNumbers);
		}
		const modeDisable = [];
		form.modes[0] && form.modes[0].route ? modeDisable.push(false) : modeDisable.push(true)
		form.modes[1] && form.modes[1].route ? modeDisable.push(false) : modeDisable.push(true)
        form.modes[2] && form.modes[2].route ? modeDisable.push(false) : modeDisable.push(true)

        const modeOptions = this.processModeOptions(routes);
        return (
            <div ref={node => this.node = node} style={{
                display: 'flex',
                flex: 1,
                height: '100%',
                background: '#EFEFEF',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: '50px',
                overflow: 'auto',
            }}>
                <StandardSettingsPage>
                    {newRoute && (
                        <FormControlCard
                            form={this.state.form}
                            field="friendlyname"
                            formControl="TextInput"
                            title="Name"
                            subtitle="Name of the new managed route"
                            icon={<NameIcon />}
                            submitForm={(field, data) => this.submitForm(field, data)}
                        />
                    )}
                    <FormControlCard
                        form={this.state.form}
                        field="description"
                        formControl="TextInput"
                        title="Description"
                        subtitle="Summary of the managed route"
                        icon={<DescriptionIcon />}
                        submitForm={(field, data) => this.submitForm(field, data)}
                    />
                    {!newRoute && this.renderPhoneNumberCard(phonenumbers)}
                    {!newRoute && <FormControlCard
                        form={this.state.form}
                        field="mode"
                        icon={<ModeIcon/>}
                        formControl="RadioInput"
                        title="Current Mode"
                        subtitle=""
                        submitForm={(field, data) => this.submitForm(field, data)}
                        options={["Day", "Night", "Temp"]}
                        values={["day", "night", "temp"]}
                        disabledOptions={modeDisable}
                    />}
                    <FormControlCard
                        form={this.state.form}
                        field="pin"
                        formControl="TextInput"
                        title="PIN"
                        subtitle=""
                        icon={<PasswordIcon />}
                        submitForm={(field, data) => this.submitForm(field, data)}
                    />
                    <FormControlCard
                        form={this.state.form}
                        field="modes"
                        icon={<DayModeIcon/>}
                        formControl="SelectInputMode"
                        title="Day Mode"
                        subtitle=""
                        submitForm={(field, data) => this.submitForm('0', data)}
                        options={modeOptions}
                        values={
                            form.modes[0] && form.modes[0].route
                                ? form.modes[0].route
                                : ''
                        }
                        unselectedMsg="Select a route"
                        linkAddress="#routes"
                    />
                    <FormControlCard
                        form={this.state.form}
                        field="modes"
                        icon={<NightModeIcon/>}
                        formControl="SelectInputMode"
                        title="Night Mode"
                        subtitle=""
                        submitForm={(field, data) => this.submitForm('1', data)}
                        options={modeOptions}
                        values={
                            form.modes[1] && form.modes[1].route
                                ? form.modes[1].route
                                : ''
                        }
                        unselectedMsg="Select a route"
                        linkAddress="#routes"
                    />
                    <FormControlCard
                        form={this.state.form}
                        field="modes"
                        last="120px"
                        icon={<TempModeIcon/>}
                        formControl="SelectInputMode"
                        title="Temp Mode"
                        subtitle=""
                        submitForm={(field, data) => this.submitForm('2', data)}
                        options={modeOptions}
                        style={{marginBottom: '120px'}}
                        values={
                            form.modes[2] && form.modes[2].route
                                ? form.modes[2].route
                                : ''
                        }
                        unselectedMsg="Select a route"
                        linkAddress="#routes"
                    />
                    <Footer
                        onClick={(cancel) => this.submitFullForm(cancel)}
                        disabled={pending || isEqual(this.state.rollbackForm, form)}
                    />
                </StandardSettingsPage>
            </div>
        );
    }
}

const bindActions = dispatch => ({});

const mapStateToProps = () => ({});

const style = {
    dropdown: {
        width: 350,
    },
    submit: {
        position: 'fixed',
        align: 'center',
        top: '95vh',
        zIndex: '5',
    },
};

export default connect(mapStateToProps, bindActions)(Settings);
