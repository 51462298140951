import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PhoneInput from 'react-phone-input-intl';
import 'react-phone-input-intl/dist/style.css';

import Button from '@material-ui/core/Button';

const PhoneInputForForwarding = (props) => {
  const [value, setValue] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [changeCountry, setChangeCountry] = useState(false);

  const wrapper = {
    paddingLeft: window.matchMedia('(min-width: 800px)').matches ? 50 : 20,
    paddingBottom: 30,
    display: 'flex',
    flexDirection: 'row',
  };

  const mergedStyle = {...wrapper, ...props.style};

  const style = {
    wrapper: mergedStyle,
    phoneInputContainerStyle: {
      width: 'auto',
    },
  };

  const onChange = (val, data) => {
    if (changeCountry) {
      if (data.dialCode === '1') {
        setValue('');
      } else {
        setValue('+' + data.dialCode);
      }
      setChangeCountry(false);
    } else if (data.dialCode === '1' || val.startsWith('+') || val === '') {
      setValue(val);
    } else {
      setValue('+' + val);
    }

    if (selectedCountryCode !== '+' + data.dialCode) {
      if (data.dialCode !== '1') {
        setSelectedCountryCode('+' + data.dialCode);
      } else if (selectedCountryCode !== '') {
        setSelectedCountryCode('');
      }
    }
  }

  const changeCountryCode = (countryCode) => {
    if (countryCode === '1') {
      if (value !== '') {
        setValue('');
        setChangeCountry(true);
      }
    } else {
      setValue("+" + countryCode);
      setChangeCountry(true);
    }
  }

  const onSubmit = () => {
    props.onClick(value);
    setValue(selectedCountryCode);
  }

  return (
    <div style={style.wrapper}>
      <PhoneInput
        placeholder={props.placeholder}
        value={value}
        defaultCountry={'us'}
        autoFormat={false}
        disableCountryCode={true}
        onChange={onChange}
        getCountryCode={changeCountryCode}
        containerStyle={style.phoneInputContainerStyle}
        onEnterKeyPress={onSubmit}
      />
      <Button
        style={{ minWidth: '25px' }}
        onClick={onSubmit}
      >
        {props.buttonLabel}
      </Button>
    </div>
  );
};

PhoneInputForForwarding.defaultProps = {
  disabled: false,
  placeholder: '',
  value: '',
  type: '',
  onChange: () => console.log('No onChange func provided for textinput'),
  onBlur: () => console.log('No onBlur func provided for textinput'),
  onClick: () => console.log('No onClick func provided for button'),
};

PhoneInputForForwarding.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  buttonLabel: PropTypes.any.isRequired,
  style: PropTypes.object,
  getCountryCode: PropTypes.func,
};

export default PhoneInputForForwarding;