import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import PriorityIcon from '@material-ui/icons/FormatListNumbered';
import EmptyIcon from '@material-ui/icons/RadioButtonUnchecked';
import { putCoreHttp, getCoreHttp, postCoreHttp,deleteCoreHttp } from 'actions/httpRequest';
import { errorNotification } from 'actions/notifications';
import DescriptionCard from './descriptionCard';
import PhoneInput from 'react-phone-input-intl'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import LinkIcon from '@material-ui/icons/Launch';
import 'react-phone-input-intl/dist/style.css';
import TextInput from 'presentational/formControls/TextInput';
import SelectInput from 'presentational/formControls/SelectInput';
import Loading from 'presentational/Loading';
import Footer from 'presentational/Footer';
import DialogModal from 'presentational/DialogModal';
import Button from '@material-ui/core/Button';
import { resetReloadDetails } from 'actions/phoneNumbers';
import get from 'lodash.get';
import { translatePhoneTypeToFriendlyName } from 'utils/translations';
import ValidatedAddressIcon from './ValidatedAddressIcon';
import InvalidAddressIcon, {TITLE as InvalidMessage} from './InvalidAddressIcon';
import InvalidCountryIcon, {TITLE as InvalidCountryMessage} from './InvalidCountryIcon';
import { isAllowedTenant } from './limitedRollout';

class RoutingSetting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      error: null,
      submitError: null,
      assignedOptions: null,
      controlValue: '',
      showConfirmationModal: false,
      fileUploading: '',
      mediaQuery: false,
      forwardExpanded: false,
      countryCode: '',
      isModalOpen: false,
      abbrevForm: {},
      changedValues:{},
      loading: false,
    }
    this.assignOptions = [
      { label: 'Extension', value: 'user' },
      { label: 'Route', value: 'route' },
      { label: 'Managed Route', value: 'managedroute' },
      { label: 'Conference Room', value: 'conferenceroom' },
      { label: 'Call Forwarding External', value: 'callforwarding' },
      { label: 'Call Forwarding Internal', value: 'callforwardinginternal' },
      { label: 'Not Assigned', value: 'notassigned' },
    ];
    this.assignedInitial = false;
    this.neutralValue = ['No','No Extension Assigned', 'No Route Assigned', 'No Managed Route', 'No Room',
    'No Location', 'No locations', 'No Distinctive Ring', 'No Emergency Route', 'No Holiday Route'];
  }

  componentWillUnmount(){
    if(!this.props.buttonDisabled){
      const data = this.prepareDataForAPI();
      this.props.checkSubmit(data);
    }
    this.props.resetReloadDetails('store');
  }

  componentDidMount() {
    if(this.props.reloadDetails === 'store' && this.props.phoneNumberDetails){
      this.props.resetReloadDetails();
      this.setState({ 
        form: this.props.phoneNumberDetails, 
        loading: this.props.loading,
      },() =>  this.adjustValue());
    }

    const reqData = {
      reqAction: 'extensions',
    };

    // Media query for responsive UI
    const mq = window.matchMedia('(min-width: 800px)');
    this.setState({ mediaQuery: mq });
    mq.addListener((mediaQuery) => this.setState({ mediaQuery }));

    this.props.getCoreHttp(reqData);
  }

  handleClick = (e) => {
    if(this.node) {
      if(this.node.contains(e.target)) {
        this.props.clickedNode();
      }
    }
  }

  adjustValue = (inEdit) => {
    let callForwardingTransparency = this.state.form.callForwardingTransparency ? this.state.form.callForwardingTransparency : '';
    let callForwardingDialedDisplay = this.state.form.callForwardingDialedDisplay ? this.state.form.callForwardingDialedDisplay : '';
    if(!inEdit){
      if (this.state.form.assignedcallforwarding === "1") {
        callForwardingTransparency = 0;
        callForwardingDialedDisplay = 0;
      } else if (this.state.form.assignedcallforwarding === "2") {
        callForwardingTransparency = 1;
        callForwardingDialedDisplay = 0;
      } else if (this.state.form.assignedcallforwarding === "3") {
        callForwardingTransparency = 1;
        callForwardingDialedDisplay = 1;
      } else if (this.state.form.assignedcallforwarding === "4") {
        callForwardingTransparency = 0;
        callForwardingDialedDisplay = 1;
      }
    }

    const modifiedValues = {
      assignedmanaged: this.state.form.assignedmanaged === '' ? 'No Managed Route' : this.state.form.assignedmanaged,
      assignedconference: this.state.form.assignedconference === '' ? 'No Room' : this.state.form.assignedconference,
      assignedroute: this.state.form.assignedroute === '' ? 'No Route Assigned' : this.state.form.assignedroute,
      assigneduser: this.state.form.assigneduser === '' ? 'No Extension Assigned' : this.state.form.assigneduser,
      distinctivering: this.state.form.distinctivering === '' ? 'No Distinctive Ring' : this.state.form.distinctivering,
      emergencyroute: this.state.form.emergencyroute === '' ? 'No Emergency Route' : this.state.form.emergencyroute,
      holidayroute: this.state.form.holidayroute==='' ?  'No Holiday Route' : this.state.form.holidayroute,
      callForwardingTransparency,
      callForwardingDialedDisplay,
    }

    this.setState({
      form: {
        ...this.state.form,
        ...modifiedValues,
      }
    });

    if(this.state.form.assigned==='0'){
      this.setState({ form: {...this.state.form, assignedto: 'notassigned'}, assignedOptions: 'notassigned'}, );
    }

    if( !this.assignedInitial){
      if(this.state.form.assigned==='1'){
        if(this.state.form.assigneduser){
          this.setState({ form: {...this.state.form, ...modifiedValues, assignedto: 'user'}, assignedOptions: 'user' });
        }
        else if(this.state.form.assignedroute){
          this.setState({ form: {...this.state.form, ...modifiedValues, assignedto: 'route'}, assignedOptions: 'route' });
        }
        else if(this.state.form.assignedmanaged){
          this.setState({ form: {...this.state.form, ...modifiedValues, assignedto: 'managedroute'}, assignedOptions: 'managedroute' });
        }
        else if(this.state.form.assignedconference){
          this.setState({ form: {...this.state.form, ...modifiedValues , assignedto: 'conferenceroom'}, assignedOptions: 'conferenceroom' });
        }
        else if(this.state.form.assignedconference==='' && this.state.form.assigned==='1' && this.state.form.assignedcallforwarding==='0'){
          this.setState({ form: {...this.state.form, ...modifiedValues, assignedto: 'conferenceroom'}, assignedOptions: 'conferenceroom' });
        }
        else if(
          this.state.form.assignedcallforwarding==='1' ||
          this.state.form.assignedcallforwarding==='2' ||
          this.state.form.assignedcallforwarding==='3' ||
          this.state.form.assignedcallforwarding==='4'
        ){
          if (this.state.form['call_forwarding_number'].length < 8){
            this.setState({ form:{...this.state.form, ...modifiedValues, assignedto: 'callforwardinginternal'}, assignedOptions: 'callforwardinginternal' })
          }else{
            this.setState({ form:{...this.state.form, ...modifiedValues, assignedto: 'callforwarding'}, assignedOptions: 'callforwarding' })
          }
        }
      }

      this.assignedInitial = true;
    }
  }

  componentDidUpdate() {
    if(this.props.reloadDetails === 'store' && this.props.phoneNumberDetails){
      this.props.resetReloadDetails();
      this.assignedInitial = false;
      this.setState({ form: this.props.phoneNumberDetails },() =>  this.adjustValue());
    }

    if(this.state.form && this.state.form.custom_conf_greeting_file && this.state.fileUploading==='Uploading...'){
      this.setState({ fileUploading: '' });
    }
    else if(this.state.form && !this.state.form.custom_conf_greeting_file && this.state.fileUploading==='Deleting...'){
      this.setState({ fileUploading: '' });
    }

  }

  setData = (data, submitData) => {
    const field = Object.keys(data)[0];
    const changedValueData = Object.values(data)[0];
    let assignedcallforwarding = 0;

    if (field === "call_forwarding_number" && changedValueData !== ""){
      assignedcallforwarding = 1;
    }

    // These values are mutually exclusive. If we 
    // change one of them, all others must be erased
    let newAssignmentData = {
      assigned:"1",
      assigneduser: "",
      assignedroute: "",
      assignedmanaged: "",
      assignedconference: "",
      assignedcallforwarding: assignedcallforwarding,
      call_forwarding_number:"",
    };

    //If we're using newAssignmentData, we'll still
    //need data we had in abbrevForm, before we 
    // utilized the setData function
    let abbrevForm = {...this.state.abbrevForm};
    Object.keys(abbrevForm).forEach(value => {
      newAssignmentData[value] = abbrevForm[value];
    });

    const clearAssignmentValues = Object.keys(newAssignmentData);

    if (clearAssignmentValues.includes(field)){
      newAssignmentData[field] = changedValueData;
    }

    const abbrevFormData = clearAssignmentValues.includes(field) ? newAssignmentData : data;

    let changedValues = {...this.state.changedValues};
    changedValues[field] = `${translatePhoneTypeToFriendlyName(field)}: ${changedValueData}`;

    const defaultForm = { ...this.state.form, assigneduser:'', assigned:'1', assignedroute:'', assignedmanaged:'', assignedconference:'', assignedcallforwarding:0, call_forwarding_number: ''};
    this.setState({form: {...this.state.form, ...defaultForm, ...data}, changedValues, abbrevForm: abbrevFormData, assignedOptions: submitData ? submitData : this.state.assignedOptions },
      () => this.adjustValue());
  }

  submitForm = (field, data) => {
    if (this.state.form[field] === data) {
      console.log('No update');
      return;
    } else {
      this.props.updateAbbrevForm(field, data)
      this.props.madeChanges(true, this.state.form);
    }

    if(this.props.buttonDisabled){
      this.props.disableButton(false)
    }

    let changedValueData = data;

    // This is non-intuitive in the db, so we've gotta reverse it in the UI
    if (field === "exclude_from_location_matching"){
      changedValueData = !changedValueData;
    }

    let changedValues = {...this.state.changedValues};
    changedValues[field] = `${translatePhoneTypeToFriendlyName(field)}: ${changedValueData}`;

    let abbrevForm = {...this.state.abbrevForm};
    abbrevForm[field] = data;

    let submitData = data;
    const filedType = ['assigneduser', 'assignedroute', 'assignedmanaged', 'assignedconference'];
    if(filedType.includes(field)){
      this.setData({[field]: submitData})
      return;
    }
    else if(field==='call_forwarding_number'){

      //The lib for international numbers seems to be failing to pick up the "+" after an initial country code change.
      //This ensures a "+" is present so the logic below is able to execute properly.
      //If this logic is reached, then we know for certain that a "+" should be present.
      if(!submitData.includes('+') && this.state.assignedOptions === 'callforwarding'){
        submitData = '+' + submitData;
      }

      if(submitData.slice(0,this.state.countryCode.length) === this.state.countryCode){
        this.setData({[field]: submitData, abbrevForm: {[field]:submitData}, changedValues, assignedcallforwarding:1});
        return;
      }
    }

    if (field === 'assignedto') {
      let {form} = this.state;
      if(data == 'callforwarding'){
        this.state.form['call_forwarding_number'] = '+1';
      } else {
        this.state.form['call_forwarding_number'] = '';
      }
      if(data==='notassigned'){
        //we need to clear all this out if we mark "not assigned"
        abbrevForm["assigned"] = "0";
        abbrevForm["assignedconference"] = '';
        abbrevForm["assigneduser"] = '';
        abbrevForm["assignedroute"] = '';
        abbrevForm["assignedmanaged"] = '';
        abbrevForm["assignedcallforwarding"] = 0;
        abbrevForm["call_forwarding_number"] ='';

        this.setState({ changedValues, form: { ...form, [field]: submitData, assigned: '0',assignedconference:'',assigneduser:'',assignedroute:'',assignedmanaged:'',assignedcallforwarding:0,call_forwarding_number:'' }, abbrevForm, assignedOptions: data}, () => this.adjustValue(true));
      }
      else if(data === 'conferenceroom'){
        this.setData({
          [field]: submitData,
          abbrevForm,
          changedValues,
        }, submitData);
      }
      else{
        this.setState({ 
          form: {
            ...form,
            [field]: submitData
          }, 
          assignedOptions: data,
          abbrevForm,
          changedValues,
        });
      }
      return;
    }

    if(field === 'location') {
      const form = { ...this.state.form };
      form[field] = data;

      const location = this.props.locations.find(item => item.name === data);
      // If the selected location has not been validated, then the room-level
      // e911 should be turned off. However, if the user then then selects 
      // another location that is valid, the room-level 911 should be set to the 
      // original value of the enabled room-level 911 property.
      if(!location || !location.address_components) {
        form["enable_room_level_e911"] = 0;
        abbrevForm["enable_room_level_e911"] = 0;
        changedValues["enable_room_level_e911"] = `${translatePhoneTypeToFriendlyName("enable_room_level_e911")}: 0`;
      }
      else {
        const originalValue = this.props.phoneNumberDetails.enable_room_level_e911;
        form["enable_room_level_e911"] = originalValue;
        abbrevForm["enable_room_level_e911"] = originalValue;
        changedValues["enable_room_level_e911"] = `${translatePhoneTypeToFriendlyName("enable_room_level_e911")}: ${originalValue}`;
      }

      this.setState({ 
        form, 
        abbrevForm,
        changedValues,
      });

      return;
    }

    this.setState({ 
      form: { 
        ...this.state.form, 
        [field]: submitData 
      },
      abbrevForm,
      changedValues
    }, () => this.adjustValue(true));

  }

  //I'm sorry for this
  //So so sorry
  //I should be better than this, but I guess I'm not
  reverseValue = (field, data) => {
    const reversNums = { '0': 1, '1': 0, true: 0, false: 1 };
    data[field] = reversNums[data[field]];
    return data;
  }

  routeSettings = () => {
    const routelist = this.props.routes.map(item => { return { label: item.friendlyname ? item.friendlyname : item.name, value: item.name } });
    let distinctivering = [];
    if(this.props.phoneNumberDetails){
      distinctivering = [...this.props.phoneNumberDetails.config.distinctivering];
    }
    distinctivering.unshift('No Distinctive Ring');
    return (
      <>
        <h3>Route Settings</h3>
        <FormControlCard
          form={this.state.form}
          field="distinctivering"
          formControl="SelectInput"
          title="Distinctive Ring"
          submitForm={this.submitForm}
          options={distinctivering}
        />
        <FormControlCard
          form={this.state.form}
          field="emergencyroute"
          formControl="SelectInput"
          title="Emergency Route"
          submitForm={this.submitForm}
          options={['No Emergency Route', ...routelist]}
        />
        <FormControlCard
          form={this.state.form}
          field="holidayroute"
          formControl="SelectInput"
          title="Observed Holiday Route"
          submitForm={this.submitForm}
          options={['No Holiday Route', ...routelist]}
        />
        <FormControlCard
          form={this.state.form}
          field="callrecording"
          formControl="ToggleInput"
          title="Record All Inbound Calls"
          subtitle=""
          submitForm={this.submitForm}
          label="No / Yes"
        />
        <FormControlCard
          form={this.reverseValue('exclude_from_location_matching', {...this.state.form})}
          field="exclude_from_location_matching"
          formControl="ToggleInput"
          title="Enable Local Area Presence"
          subtitle=""
          submitForm={(field, data) => this.submitForm(field, !data)}
          label="No / Yes"
        />
      </>
    )
  }

  uploadFile = (file) => {
    this.setState({ fileUploading: 'Uploading...' })
    let formData = new FormData();
    formData.append("file", file);

    const reqData = {
      reqAction: 'conferencegreetings',
      reqObject: this.props.selectedPhoneNumber,
      reqBody: formData,
    };

    const storeKey = 'editupload';
    this.props.postCoreHttp(reqData, storeKey);
  }

  fileDelete = () => {
    this.setState({ fileUploading: 'Deleting...' });
    const reqData = {
      reqAction: 'conferencegreetings',
      reqObject: this.props.selectedPhoneNumber,
    };

    const storeKey = 'deletefilephonenumbers';
    this.props.deleteCoreHttp(reqData, storeKey);
  }

  prepareDataForAPI = () => {
    let data = this.state.form;
    const neutralValues = this.state.form.assignedto !== 'conferenceroom'
      ? this.neutralValue
      : this.neutralValue.filter(item => item !== 'No Room');

    for(let i in data){
      if(neutralValues.includes(data[i])){
        data[i]='';
      }
    }

    if(data.assignedconference === 'No Room') {
      data.external_dispatch = '603';
      data.lock_to_room = '';
    } else if(data.assignedconference) {
      data.lock_to_room = data.assignedconference;
    }

    let value = '';
    let transparencyValue = data.callForwardingTransparency;
    let displayDialedNumberValue = data.callForwardingDialedDisplay;
    if(this.state.form.assignedto == "callforwardinginternal" || this.state.form.assignedto == "callforwarding"){
      if (!transparencyValue && !displayDialedNumberValue) {
        value = "1";
      } else if (transparencyValue && !displayDialedNumberValue) {
        value = "2";
      } else if (transparencyValue && displayDialedNumberValue) {
        value = "3";
      } else if (!transparencyValue && displayDialedNumberValue) {
        value = "4";
      }
    }

    //Get our location name
    let selectedLocationName = this.state.form.location || "";

    //Get the real name from props
    this.props.locations.forEach(location => {

      if (selectedLocationName === location["friendlyname"]){
        data.name = location["friendlyname"];
        data.location = location["name"]
      }
    });

    data.assignedcallforwarding = value;

    let message = '';
    if(this.state.form.assignedto!=='' && this.state.form.assignedto!=='notassigned' && this.state.form.assignedto!=='conferenceroom'){
      switch(this.state.form.assignedto){
        case 'user':
          if(this.state.form.assigneduser.length<1){
            message = 'Must select a user to assign this number to';
          }
          break;
        case 'route':
          if(this.state.form.assignedroute.length<1){
            message = 'Must select a route to assign this number to';
          }
          break;
        case 'managedroute':
          if(this.state.form.assignedmanaged.length<1){
            message = 'Must select a managed route to assign this number to';
          }
          break;
        case 'callforwarding':
          if(this.state.form.call_forwarding_number.replace( /^\D+/g, '').length<11){
            message = 'Must input a valid call forwarding number to assign this number to';
          }
          break;
        case 'callforwardinginternal':
          if(this.state.form.call_forwarding_number.replace( /^\D+/g, '').length<3){
            message = 'Must input a valid call forwarding number to assign this number to';
          }
          break;
      }
      if(message){
        errorNotification({title: 'Error!', message: message})
        return; 
      }
    }
    if(this.state.form.assigned==='1' && this.state.form.location.length<1){
      message = 'You must select a valid location for this phone number';
      errorNotification({title: 'Error!', message: message})
      return;
    }
    if(this.state.form.call_forwarding_number.slice(0,this.state.countryCode.length) !== this.state.countryCode && this.state.form.assignedto === 'callforwarding') {
      errorNotification({title: 'Wrong phone number format', message: 'Please make sure the Call Forwarding # has the correct country code for the selected country'});
      return;
    }
    if((this.state.form.call_forwarding_number.length - this.state.countryCode.length) >= 11){
      errorNotification({title: 'Error!', message: 'Your phone number is too long'})
      return
    }
    this.props.disableButton(true);
    return data
  }

  editNumber = (cancel) => {
    if(cancel){
      this.props.onCancel();
      return;
    }

    const data = this.prepareDataForAPI();

    const reqData = {
      reqAction: 'phonenumbers',
      reqObject: encodeURIComponent(data.phonenumber),
      reqBody: data,
    };

    const storeKey = 'editphonenumbers';
    this.props.putCoreHttp(reqData, storeKey);
  }

  bulkEditNumbers = (cancel) => {
    if(cancel){
      this.props.onCancel();
      return;
    }

    let { abbrevForm } = {...this.state};

    const data = this.prepareDataForAPI();
    //we need to add all the phone numbers here
    abbrevForm.phonenumber = this.props.selectedPhoneNumber;

    const reqData = {
      reqAction: 'bulkeditphonenumber',
      reqObject: 'bulkeditphonenumber',
      reqBody: abbrevForm,
    };

    const storeKey = 'bulkeditphonenumber';
    this.props.putCoreHttp(reqData, storeKey);
  }

  dependentInputs = () => {

    if (!this.props || !this.props.extensions){
      return;
    }

    let extOptions;
    let routeOptions = this.props.routes.map(item => { return{label: item.friendlyname ? item.friendlyname : item.name, value: item.name }});
    routeOptions.unshift({label: 'No Route Assigned', value: 'No Route Assigned'});
    const managedRouteList = this.props.managedroutes.map(item => {
      return { label: item.friendlyname ? item.friendlyname : item.name, value: item.name}
    });
    managedRouteList.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1)

    managedRouteList.unshift({ label: 'No Managed Route', value: 'No Managed Route'});
    const conferenceRoomList = this.props.conferenceRooms.map(item => { return { value: item.confno, label: `${item.confno} - ${item.name}` } })
    conferenceRoomList.unshift({ label: 'No Room', value: 'No Room' });

    switch (this.state.assignedOptions) {
      case 'notassigned':
        return (
          <DescriptionCard title='Not Assigned' />
        )
      case 'route':
        return (
          <>
            <FormControlCard
              form={this.state.form}
              field="assignedroute"
              formControl="SelectInput"
              title={<span>Route: <span style={styles.required}>(Required)</span></span>}
              submitForm={this.submitForm}
              options={routeOptions}
              linkAddress='#routes'
            />
            <DescriptionCard title='Assign A Route' />
          </>
        )
      case 'managedroute':
        return (
          <>
            <FormControlCard
              form={this.state.form}
              field="assignedmanaged"
              formControl="SelectInput"
              title={<span>Managed Route: <span style={styles.required}>(Required)</span></span>}
              submitForm={this.submitForm}
              options={managedRouteList}
              linkAddress='#managedroutes'
            />
            <DescriptionCard title='Assign A Managed Route' />
          </>
        )
      case 'conferenceroom':
        return (
          <>
            <FormControlCard
              form={this.state.form}
              field="assignedconference"
              formControl="SelectInput"
              title={<span>Conference Room: <span style={styles.required}>(Required)</span></span>}
              submitForm={this.submitForm}
              options={conferenceRoomList}
              linkAddress='#conferencerooms'
            />
            <FormControlCard
              form={this.state.form}
              field="pinless_entry"
              formControl="ToggleInput"
              title="Allow Pinless Entry"
              subtitle=""
              submitForm={this.submitForm}
              label="No / Yes"
            />
            <FormControlCard
              form={this.state.form}
              field="enable_custom_conf_greeting"
              formControl="ToggleInput"
              title="Enable Custom Greeting"
              subtitle=""
              submitForm={this.submitForm}
              label="No / Yes"
            />
            <FormControlCard
              form={this.state.form}
              field="newRecording"
              formControl="FileInput"
              title="Upload a Custom Greeting for Your Room"
              subtitle=""
              fileUploading={this.state.fileUploading}
              show={this.state.form && this.state.form.custom_conf_greeting_file ? false : true}
              submitForm={(val) => this.uploadFile(val)}
              extra={this.state.form && this.state.form.custom_conf_greeting_file
                ? <><div><strong>Custom Greeting:</strong> {this.props.selectedPhoneNumber}</div><br />
                  <Button onClick={this.fileDelete} style={{fontSize: '90%'}} size='large' variant="contained" color='primary'>
                    Delete Current Greeting
                  </Button>
                  </>
                : null }
            />
            <DescriptionCard title='Assign A Conference Room' />
          </>
        )
      case 'callforwarding':

        const minWidth = this.state.mediaQuery.matches;

        const cardStyle = {
            card: {
                width: minWidth ? '50%' : '100%',
                minWidth: '400px',
            },
        };

        return (
          <>
            <Card style={cardStyle.card} >
              <CardHeader avatar={null} title={<span>Always Forward to: <span style={styles.required}>(Required)</span></span>} />
              <CardContent style={this.state.forwardExpanded ? {height: '275px'} : null}>
                <div style={styles.container}>
                  <div style={{width: '300px'}}>
                    <PhoneInput
                      isDropdownShowing={(isShowing)=>this.setState({forwardExpanded: isShowing})}
                      countryCodeEditable={false}
                      defaultCountry={'us'}
                      placeholder="Enter phone number"
                      value={this.state.form.call_forwarding_number}
                      onChange={value => this.submitForm('call_forwarding_number', value)}
                      getCountryCode={countryCode => this.setState({countryCode: "+" + countryCode})}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
            <FormControlCard
              form={this.state.form}
              field="callForwardingTransparency"
              formControl="ToggleInput"
              title="Transparency"
              subtitle=""
              submitForm={this.submitForm}
              label="No / Yes"
            />
            <FormControlCard
              form={this.state.form}
              field="callForwardingDialedDisplay"
              formControl="ToggleInput"
              title="Display Dialed Number"
              subtitle=""
              submitForm={this.submitForm}
              label="No / Yes"
            />
            <DescriptionCard title='Assign Call Forwarding' />
          </>
        )
      case 'callforwardinginternal':
        extOptions = this.props.extensions.map(item => ({ value: item.extension, label: `${item.extension} - ${item.firstname} ${item.lastname}` }));
        return (
          <>
            <FormControlCard
              form={this.state.form}
              field="call_forwarding_number"
              formControl="SelectInput"
              title={<span>Always Forward to: <span style={styles.required}>(Required)</span></span>}
              submitForm={this.submitForm}
              options={extOptions}
            />
            <FormControlCard
              form={this.state.form}
              field="callForwardingTransparency"
              formControl="ToggleInput"
              title="Transparency"
              subtitle=""
              submitForm={this.submitForm}
              label="No / Yes"
            />
            <FormControlCard
              form={this.state.form}
              field="callForwardingDialedDisplay"
              formControl="ToggleInput"
              title="Display Dialed Number"
              subtitle=""
              submitForm={this.submitForm}
              label="No / Yes"
            />
            <DescriptionCard title='Assign Call Forwarding' />
          </>
        )
      case 'user':
        extOptions = this.props.extensions.map(item => ({ value: item.extension, label: `${item.extension} - ${item.firstname} ${item.lastname}` }));
        extOptions = extOptions.filter(item => item.label.toLowerCase().includes(this.state.controlValue.toLowerCase()));
        if(extOptions.length && extOptions[0].value!==this.state.form.assigneduser && this.state.controlValue){
          this.submitForm('assigneduser', extOptions[0].value)
        }
        extOptions.sort((a, b) => (parseInt(a.value)) > parseInt(b.value) ? 1 : -1)
        extOptions.unshift('No Extension Assigned');
        return (
          <>
          <div  style={{ minWidth: '400px', width: '50%' }}>
            <Card>
              <CardHeader avatar={null} title={<span>User: <span style={styles.required}>(Required)</span></span>} />
              <CardContent>
                <div style={styles.container}>
                  <TextInput
                    disabled={false}
                    type='text'
                    onChange={(val) => this.setState({ controlValue: val })}
                    value={this.state.controlValue}
                    placeholder='Search'
                  />
                  <SelectInput
                    disabled={false}
                    field='assigneduser'
                    onChange={(val) => {
                      this.setState({ controlValue: '' }, this.submitForm('assigneduser', val))
                    }}
                    value={this.state.controlValue
                      ? (extOptions.length>1 ? extOptions[1].value : extOptions[0])
                      : this.state.form.assigneduser
                    }
                    options={extOptions}
                    placeholder='Extensions'
                  />
                  <a href={`#extensions/${this.state.controlValue ? (extOptions.length>1 ? extOptions[1].value : extOptions[0]) : this.state.form.assigneduser}`} title="Go to user">
                  {this.state.controlValue ? (extOptions.length>1 ? extOptions[1].value : extOptions[0]): this.state.form.assigneduser}<LinkIcon style={{height:'17px'}}/></a>
                </div>
              </CardContent>
            </Card>
            </div>
            <DescriptionCard title='Assign To User' />
          </>
        )

    }
  }

  render() {

    let { isModalOpen, changedValues, loading } = {...this.state};
    const { selectedPhoneNumber } = this.props;

    if (this.props.loading === false){
      loading = false
    }


    let locationsOptions = this.props && this.props.locations ? this.props.locations.map(item => {
      const isValidated = !!item.address_components;
      const country = item.country;

      let errorMsg = InvalidMessage;
      let icon = <InvalidAddressIcon />;
      if(isValidated) {
        icon = <ValidatedAddressIcon />;
        errorMsg = undefined;
      }
      else if(Boolean(country) && country !== "United States of America" && country !== "Canada") {
        icon = <InvalidCountryIcon />;
        errorMsg = InvalidCountryMessage;
      }

      const validationAllowed = isAllowedTenant();
      return {
        value: item.name,
        label: item.friendlyname ? item.friendlyname : item.name,
        icon: validationAllowed ? icon : undefined,
        errorMsg: validationAllowed ? errorMsg : undefined,
      }
    }) : [];
    locationsOptions.sort(function (a, b) {
      return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
    });
    locationsOptions.unshift('No locations');

    if (loading){
      return <Loading />
    }

    const optionForCurAddr = locationsOptions.find(item => item.value === this.state.form.location);

    return (
      <div
        ref={node => this.node = node}
        style={{
          display: 'flex',
          flex: 1,
          height: '100%',
          background: '#EFEFEF',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: '50px',
          overflow: 'auto',
        }}
        onMouseDown={this.handleClick}
      >
      <StandardSettingsPage>
        <FormControlCard
          form={this.state.form}
          field="location"
          icon={<PriorityIcon />}
          formControl="SelectInput"
          title={<span>Phone Number Location: <span style={styles.required}>(Required)</span></span>}
          submitForm={this.submitForm}
          options={locationsOptions}
          errorText={optionForCurAddr?.errorMsg}
        />
        <FormControlCard
          form={this.state.form}
          field="description"
          icon={<EmptyIcon />}
          formControl="TextInput"
          title="Phone Number Description"
          type='text'
          subtitle=""
          submitForm={this.submitForm}
        />
        <FormControlCard
          form={this.state.form}
          field="prependcid"
          icon={<EmptyIcon />}
          formControl="TextInput"
          title="Prepend Caller ID"
          type='text'
          subtitle=""
          submitForm={this.submitForm}
        />
        <FormControlCard
          form={this.state.form}
          field="assignedto"
          icon={<PriorityIcon />}
          formControl="SelectInput"
          title="Set Phone Number to"
          submitForm={this.submitForm}
          options={this.assignOptions}
        />
        {this.dependentInputs()}
        {this.state.assignedOptions === 'notassigned' || this.state.assignedOptions === null ? routingOptions() : this.routeSettings()}

        <Footer
          multipleChanges={this.props.selectedPhoneNumber.length > 1 ? true : false}
          disabled={this.props.buttonDisabled}
          onClick={ (cancel) => this.editNumber(cancel)}
          onUpdateAll={()=> this.setState({ isModalOpen: true })}
        />
      </StandardSettingsPage>
      {/* Apply all changes modal */}
      <DialogModal 
        show={isModalOpen}
        affirmativeResponse={()=> {this.bulkEditNumbers(), this.props.disableButton(true), this.setState({ isModalOpen:false, loading: true, changedValues: {}, abbrevForm:{} })}}
        negativeResponse={()=> {this.props.disableButton(true), this.setState({ isModalOpen: false })}}
        title={`Are you sure you want to apply the following changes to all selected phone numbers?`}
        content={Object.values(changedValues)}
      />
      </div>
    )
  }

}

const styles = {
  container: {
    paddingLeft: '50px',
    paddingBottom: '50px'
  },
  required: {
    color: 'red'
  }
}

const routingOptions = () => (
  <>
    <h3>Routing Options</h3>
    <DescriptionCard title='User' />
    <DescriptionCard title='Route' />
    <DescriptionCard title='Managed Route' />
    <DescriptionCard title='Conference Room' />
    <DescriptionCard title='Call Forwarding' />
  </>
)

const bindActions = (dispatch) => ({
  putCoreHttp: (reqData, storeKey) => dispatch(putCoreHttp(reqData, storeKey)),
  getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
  postCoreHttp: (reqData, storeKey) => dispatch(postCoreHttp(reqData, storeKey)),
  deleteCoreHttp: (reqData, storeKey) => dispatch(deleteCoreHttp(reqData, storeKey)),
  resetReloadDetails: (data) => dispatch(resetReloadDetails(data)),
})

const mapStateToProps = state => ({
  phoneNumberDetails: state.phoneNumbers.phoneNumberDetails,
  locations: state.http.configurations.locations,
  loading: state.phoneNumbers.loading,
  routes: state.http.configurations.routes,
  managedroutes: get(state, 'configurations.data.managedroutes') || state.http.configurations.managedroutes,
  conferenceRooms: state.http.configurations.conferenceRooms,
  extensions: state.extensions.data,
  selectedPhoneNumber: state.phoneNumbers.selectedPhoneNumber,
  reloadDetails: state.phoneNumbers.reloadDetails
});

export default connect(mapStateToProps, bindActions)(RoutingSetting);
