import {
  SELECT_PHONE_NUMBER,
  LEFT_BAR_DATA_PREPARE,
  PHONENO_LOADING_RESET,
  RESET_FLAG,
  RELOAD_DETAILS,
  RELOAD_NUMBERS,
  SET_TENANT,
  RESET_LOCATION,
} from './typeConstants';

export const selectPhoneNumber = phoneNumber => ({
  type: SELECT_PHONE_NUMBER,
  payload: phoneNumber
})

export const resetLeftBarFlag = () => ({
  type: LEFT_BAR_DATA_PREPARE
})

export const resetLoading = () => ({
  type: PHONENO_LOADING_RESET
})

export const resetFlag = () => ({
  type: RESET_FLAG
})

export const resetReloadDetails = (data) => ({
  type:RELOAD_DETAILS,
  data
})

export const reloadAvailableNumbersFlag = () => ({
  type: RELOAD_NUMBERS
})

export const setTenant = (data) => ({
  type: SET_TENANT,
  data
})

export const resetLocation = () => ({
  type: RESET_LOCATION,
})