import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Button from '@material-ui/core/Button';
import makeGlobalStyles from 'makeGlobalStyles';

export default function HelpContent(props) {
  const classes = props.useStyles();
  const globalClasses = makeGlobalStyles();
  return (
    <div className={classes.wrapper}>
      <Card classes={{ root: classes.helpPaper }} elevation={1}>
        <CardContent classes={{ root: classes.helpContent }}>
          <div>
            <h2 className={classes.pageTitle}>
              Manage My FluentCloud Managed Routes
            </h2>
            {tplHelper.get('label') == 'yiptel' ? (
              <p className={classes.helpText}>
                A Managed Route is very similar to Day/Night mode. You can 
                toggle the route state via the web portal or by entering a 
                feature code on your YipTel device.
              </p>
            ) : (
              <p className={classes.helpText}>
                A Managed Route is very similar to Day/Night mode. You can 
                toggle the route state via the web portal or by entering a 
                feature code on your FluentStream device.
              </p>
            )}
          </div>
          <div className={classes.helpTextTitle}>
            About Managed Routes
          </div>
          <div>
            {tplHelper.get('label') == 'yiptel' ? (
              <p className={classes.helpText}>
                A Managed Route includes a Day option, Night option, and Temp 
                option. You can predefine how calls are routed during the day 
                and at night and then you can enable the option by simply clicking 
                a button in the web portal or entering a feature code on your 
                YipTel device.
              </p>
            ) : (
              <p className={classes.helpText}>
                A Managed Route includes a Day option, Night option, and Temp 
                option. You can predefine how calls are routed during the day 
                and at night and then you can enable the option by simply clicking 
                a button in the web portal or entering a feature code on your 
                FluentStream device.
              </p>
            )}
            <p>
              If you desire to change call routing based on time and date 
              conditions,you should use <a href='#timeconditions'>time conditions </a>
              rather than managed routes.
            </p>
          </div>
        </CardContent>
        <CardContent classes={{ root: classes.standardHelpBlurb }}>
          <div>
            <h2 className={classes.helpTextTitle}>Need Help?</h2>
            {tplHelper.get('label') == 'yiptel' ? (
              <div>
                <p className={classes.helpText}>The whole team at YipTel is ready to help you. 
                  24 hours a day, 365 days a year.
                  You can reach us a number of ways.</p>
                <ul>
                  <li>By calling 800-752-6110 </li>
                  <li>By emailing <a href="mailto:support@yiptel.com">support@yiptel.com</a></li>
                  <li>By clicking the button below!</li>
                </ul>
              </div>
            ) : (
              <div>
                <p className={classes.helpText}>The whole team at FluentStream is ready to help you. 
                  24 hours a day, 365 days a year.
                  You can reach us a number of ways.</p>
                <ul>
                  <li>By calling 303-GO-CLOUD (303-462-5683)</li>
                  <li>By emailing <a href="mailto:support@fluentstream.com">support@fluentstream.com</a></li>
                  <li>By clicking the button below!</li>
                </ul>
              </div>
            )}
            <p />
            <Button
              size='large'
              classes={{ root: globalClasses.helpButton }}
              href="http://help.fluentcloud.com/support/solutions/articles/4000149201-how-to-use-managed-routes-day-night-temp-mode"
              target="_blank"
            >How to use Managed Routes</Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}