import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from 'presentational/Loading';

import CallBlockingForm from './callBlockingForm';
import CallBlockingButton from './callBlockingButton';

import { getUserPortalHttp, putUserPortalHttp } from 'actions/httpRequest';

import { errorNotification, successNotification } from 'actions/notifications';
import { resetFlag } from 'actions/callBlocking';

class CallBlocking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    }
    this.storedFetchedData = false;
  }

  componentDidMount() {
    const reqData = {
      reqAction: 'callblocking',
    };
    this.props.getUserPortalHttp(reqData);
  }

  componentDidUpdate() {
    if (this.props.callBlockingData && !this.storedFetchedData) {
      this.storedFetchedData = true;
      this.setState({ data: this.props.callBlockingData });
      return;
    }

    if (this.props.successMsg) {
      this.props.resetFlag();
      successNotification({
        title: 'Success!', message: this.props.successMsg
      });
    }
    else if (this.props.errorMsg) {
      if (this.props.errorCode === 404) {
        this.props.resetFlag();
        app.navigate('#userportal', { trigger : true });
      } else {
        this.props.resetFlag();
        errorNotification({
          title: 'Failed!', message: this.props.errorMsg,
        });
      }
      
    }
  }

  editBlockingOptions = (data) => {
    data.id = app.token.attributes.extension;
    const reqData = {
      reqAction: 'callblocking',
      reqBody: data,
    };
    const storeKey = 'editcallblocking';
    this.props.putUserPortalHttp(reqData, storeKey);
  }

  toggleBlocking = () => {
    const { data } = this.state;
    data.cbk_enabled = data.cbk_enabled === '1' ? '0' : '1';
    this.editBlockingOptions(data);
    this.setState({ data });
  }

  render() {
    if (this.state.data == null) {
      return <Loading/>;
    }
    const { data } = this.state;
    const text = `Turn ${data.cbk_enabled === '1' ? 'off' : 'on'} Call Blocking`;
    
    return (
      <div className='container'>
        <h1>Call Blocking Options</h1>
        <h4>Block and allow callers</h4>
        <p />
        <CallBlockingButton text={text} toggleBlocking={this.toggleBlocking}/>
        {data.cbk_enabled === '1' && <CallBlockingForm form={data} editRequest={this.editBlockingOptions}/>}
      </div>
    );
  }
}

const bindActions = (dispatch) => ({
  getUserPortalHttp: (reqData) => dispatch(getUserPortalHttp(reqData)),
  putUserPortalHttp: (reqData, storeKey) => dispatch(putUserPortalHttp(reqData, storeKey)),
  resetFlag: () => dispatch(resetFlag()),
});

const mapStateToProps = (state) => ({
  callBlockingData: state.callBlocking.callBlockingData,
  successMsg: state.callBlocking.successMsg,
  errorMsg: state.callBlocking.errorMsg,
  errorCode: state.callBlocking.errorCode,
});

CallBlocking.propTypes = {
  callBlockingData: PropTypes.oneOfType([() => null, PropTypes.object]),
  successMsg: PropTypes.oneOfType([() => null, PropTypes.string]),
  errorMsg: PropTypes.oneOfType([() => null, PropTypes.string]),
  errorCode: PropTypes.oneOfType([() => null, PropTypes.number]),
}

export default connect(mapStateToProps, bindActions)(CallBlocking);
