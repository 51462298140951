import { combineReducers } from 'redux';

import {
  CHANGE_TENANT,
  INVALIDATE_HTTP_DATA,
  SELECT_MULTICAST_GROUP,
} from 'actions/typeConstants';

const id = 'name';

const auditHistory = (state = null, action) => {

  switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

      return null;

    case 'MULTICASTGROUPS_HTTP_SUCCESS':

      if (!action.auditHistory) {
        return state;
      }

      // If this is for an object, return
      if (action.reqObject) {
        return state;
      }

      return Object.assign([], state, action.data);
    default:
      return state;
  };
};

const selected = (state = null, action) => {

  switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

      return null;

    case SELECT_MULTICAST_GROUP:
      return action.data;

    default:
      return state;
  }
};

const deleteSuccess = (state = null, action) => {
  switch (action.type){
    case "MULTICASTGROUPS_HTTP_SUCCESS":
      switch (action.reqType) {
        case 'DELETE':
          return {success:true, object: action.reqObject};
        default:
          return false;
    }
    case 'MULTICASTGROUPS_HTTP_FAILURE':
      switch (action.reqType) {
        case 'DELETE':
          return { failure: true, object: action.reqObject };
        default:
          return false;
      }
    default:
      return false;
  }
}

const putSuccess = (state = null, action) => {
  switch (action.type){
    case "MULTICASTGROUPS_HTTP_SUCCESS":
      switch (action.reqType) {
        case 'PUT':
          return { success: true, object: action.reqObject };
        default:
          return false;
    }
    case 'MULTICASTGROUPS_HTTP_FAILURE':
      switch (action.reqType) {
        case 'PUT':
          return { failure: true, object: action.reqObject };
        default:
          return false;
      }
    default:
      return false;
  }
}

const postSuccess = (state = null, action) => {
  switch (action.type){
    case "MULTICASTGROUPS_HTTP_SUCCESS":
      switch (action.reqType) {
        case 'POST':
          return { success: true, object: action.data.name };
        default:
          return false;
    }
    case 'MULTICASTGROUPS_HTTP_FAILURE':
      switch (action.reqType) {
        case 'POST':
          return { failure: true, object: action.reqObject };
        default:
          return false;
      }
    default:
      return false;
  }
}

const data = (state = null, action) => {

  switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

      return null;

    case 'MULTICASTGROUPS_HTTP_SUCCESS':
    
      if (action.auditHistory) {

        const newState = Object.assign([], state);

        const found = newState.findIndex(obj => obj[id] == action.reqObject);
        if (found == -1 || !newState[found]) {
          return state;
        }

        newState[found].auditHistory = action.data;

        return Object.assign([], newState);
      }

      if(action.reqObject){
        const newState = Object.assign([], state);
        const found = newState.findIndex(obj => obj[id] == action.reqObject);

        if (!action.data) {
          newState.splice(found, 1);
        } else if (found === -1) {
          newState.push(action.data);
        } else {
          newState[found] = action.data;
        }
        return Object.assign([], newState);
      }

      if (action.data.name) {
        const newState = Object.assign([], state);
        const found = newState.findIndex(obj => obj && obj[id] ? obj[id] == action.data.name : -1);
        if (!action.data) {
          delete newState[found];
        } else if (found === -1) {
          newState.push(action.data);
        } else {
          newState[found] = action.data;
        }

        return Object.assign([], newState);
      }

      return Object.assign([], state, action.data);
    default:
      return state;
  };
};

const metaData = (state = null, action) => {

  switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

      return null;

    case 'MULTICASTGROUPS_HTTP_SUCCESS':
      if (!action.data || !action.data.metaData || action.reqObect) {
        return state;
      }

      return action.data.metaData;
    default:
      return state;
  }
};

const reducerTarget = ()  => {
  return "multicastgroups"
}

const pending = (state = false, action) => {

  switch (action.type) {

    case 'MULTICASTGROUPS_HTTP_PENDING':
      return true;

    case 'MULTICASTGROUPS_HTTP_FAILURE':
    case 'MULTICASTGROUPS_HTTP_SUCCESS':
      return false;

    default:
      return state;
  }
};


export const multicastGroups = combineReducers({
  auditHistory,
  data,
  metaData,
  pending,
  selected,
  postSuccess,
  putSuccess,
  deleteSuccess,
  reducerTarget,
});
