import { SELECTED_NOTIFICATION_WORKFLOW_DATA, RESET_FLAG, RESET_CREATED_WORKFLOW } from './typeConstants';

export const selectedNotificationWorkflow = (data) => ({
    type: SELECTED_NOTIFICATION_WORKFLOW_DATA,
    payload: data
  })

  export const resetFlag = () => ({
    type: RESET_FLAG
  })

  export const resetCreatedWorkflow = () => ({
    type: RESET_CREATED_WORKFLOW
  })