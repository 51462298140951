import { stripNumber } from 'utils/phoneNumbers';

export const isInvalidNumber = (phonenumber) => {
  const strippedNumber = stripNumber(phonenumber);

  if (strippedNumber.length < 10 || strippedNumber.length > 15) {
    return {
      title: 'Error!',
      message: 'Please enter a valid phone number.',
    };
  }
  return null;
}

export const isSuperDuperAdmin = (token) => {
  if (token) {
    return token.role === 'superduperadmin';
  } else {
    return false;
  }
}