import React, { Component } from 'react';
import { connect } from 'react-redux';

import Loading from 'presentational/Loading';
import StandardSettingsPage from 'presentational/StandardSettingsPage';

import { Cdr } from '../standardReports';

class Report extends Component {
    // constructor will set up the Reports class with initial state
    constructor(props) {
        super(props);
        this.state = {};
    }

    // componentDidMount will set up anything that needs to be set up after the component is on the dom
    componentDidMount() {}

    render() {
        let reporttype = '__cdr';
        switch (reporttype) {
            case '__cdr':
                return <Cdr configuration={this.props.configuration} />;

                // case "custom"
                // return <Custom />

                break;
        }

        // return <Loading />
    }
}

const bindActions = dispatch => ({
    // getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
    // getUserPortalHttp: (reqData, storeKey) => dispatch(getUserPortalHttp(reqData, storeKey)),
    // httpSuccess: (data, storeKey) => dispatch(httpSuccess(data, storeKey)),
    // selectReportsConfigurations: (reportsConfigurations) => dispatch(selectReportsConfigurations(reportsConfigurations)),
});

const mapStateToProps = state => ({
    // reportsConfigurations: state.reportsConfigurations.data,
    // selectedReportsConfigurationsName: state.reportsConfigurations.selected,
    // pending: state.reportsConfigurations.pending,
});

export default connect(
    mapStateToProps,
    bindActions
)(Report);
