import React, { Component } from 'react';
import { connect } from 'react-redux';

import ErrorCard from 'presentational/ErrorCard';
import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';
import Footer from 'presentational/Footer';

import AssociatedIcon from '@material-ui/icons/SupervisorAccount';
import PersonIcon from '@material-ui/icons/PersonOutline';
import AgentIcon from '@material-ui/icons/PersonPin';
import PasswordIcon from '@material-ui/icons/Security';

import {
    errorNotification,
    successNotification,
} from 'actions/notifications';

import {
    updateConfigurations,
} from 'actions/httpRequest';

import { keySrt } from 'utils/misc';

class Details extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            errors: {},
            form: {},
            submitError: null,
        };
    }

    createRollBackForm() {
        const rollbackForm = Object.assign({}, this.state.form);
        this.setState({ rollbackForm });
    }

    onSubmit(cancel){
        if(cancel){
            this.props.cancel();
        } else{
            this.props.submitForm(this.state.form);
        }
    }

    handleClick = (e) => {
        if(this.node) {
            if(this.node.contains(e.target)) {
                this.props.clickedNode();
            }
        }
    };

    componentDidMount() {
        this.setState({ form: this.props.data }, ()=> this.createRollBackForm());
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.data) {
            this.setState({ form: nextProps.data });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    async submitForm(field, data) {

        if (this.state.rollbackForm[field] == data && data !== '' && this.state.newChanges == false){
            console.log('No update');
            return;
        } else {
            this.setState({
                newChanges: true,
                disabled: false}, ()=>
                this.props.madeChanges(this.state.newChanges, this.state.form));
        }


        if (this.state.disabled == true){
            this.setState({disabled: false});
        }

        const { form } = this.state;
        form[field] = data;


        // Lets validate our field
        const error = this.validateField(field, data);
        if (error) {

            this.setState({
                errors: { [field]: error },
                form,
            });

            errorNotification({ message: 'Please fix errors on form' });
            return;
        }

        // If no error, lets set the data
        // on the form and set it to state without errors
        this.setState({
            form,
            errors: { [field]: '' },
        });
    }

    validateField(field, data) {

        // We want to make sure our wait
        // times are actually numbers
        // and greater than 0
        switch (field) {

        case 'password':

            // We only want to allow numbers
            if (data.match(/[^0-9]/)) {
                return 'Please only use digits 0-9 for password';
            }

        default:
            return null;
        }
    }

    usersAsOptions(users) {

        const options = [];
        users.map((user) => {
            options.push({
                label: user.userName,
                value: user.userName,
            });
        });

        const orderedOptions = options.sort(keySrt('userName', true));
        orderedOptions.sort((a, b) => (a.value.toLowerCase() > b.value.toLowerCase()) ? 1 : -1)

        return orderedOptions;
    }

    render() {
        let submitDisabled = false;
        if(this.state.disabled || this.state.errors.password){
            submitDisabled = true;
        }

        if (!this.props.data || !this.state.form) {
            return (
              <StandardSettingsPage>
                <Loading />
              </StandardSettingsPage>
            );
        }

        return (
        <div ref={node => this.node = node} style={{
            display: 'flex',
            flex: 1,
            height: '100%',
            background: '#EFEFEF',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '50px',
            overflow: 'auto',
        }}>
            <StandardSettingsPage>
            {this.state.submitError ?
              <ErrorCard data={this.state.submitError} />
              : null
            }
            <FormControlCard
              disabled
              form={this.state.form}
              field="agent_id"
              formControl="TextInput"
              title="Agent ID"
              subtitle="Agent IDs are used to identify agents. This should be a unique number that is not the same as any extension or other agent ID."
              icon={<AgentIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
            />
            <FormControlCard
              form={this.state.form}
              field="firstname"
              formControl="TextInput"
              title="First name"
              subtitle="Agent's first name"
              icon={<PersonIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
            />
            <FormControlCard
              form={this.state.form}
              field="lastname"
              formControl="TextInput"
              title="Last name"
              subtitle="Agent's last name"
              icon={<PersonIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
            />
            <FormControlCard
              errorText={this.state.errors['password']}
              form={this.state.form}
              field="password"
              formControl="TextInput"
              title="Password"
              subtitle="Password agent will use to log in and out of phone"
              icon={<PasswordIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
            />
            <FormControlCard
              form={this.state.form}
              field="username"
              formControl="SelectInput"
              title="Associated username"
              subtitle="Username associated with the agent"
              icon={<AssociatedIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
              options={this.usersAsOptions(this.props.users)}
            />
            <Footer
                disabled={submitDisabled}
                onClick={(cancel) => this.onSubmit(cancel)}
            />
            </StandardSettingsPage>
          </div>
        );
    }
}

const bindActions = (dispatch) => ({
    updateConfigurations: (configurations) => dispatch(updateConfigurations(configurations)),
});

const mapStateToProps = (state) => ({
    configurations: state.http.configurations,
});

export default connect(mapStateToProps, bindActions)(Details);
