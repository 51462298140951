import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Paper from '@material-ui/core/Paper';

class MasterView extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      mediumView: false,
      smallView: false,
      xsmallView: false,
      mobileView: false,
      adminMedView: false,
      adminPortMedView: false,
      adminPortMedSmView: false,
      adminPortSmView: false,
      adminPortXSmView: false,
      adminPortXXSmView: false,
      adminPortMobileView: false,
      mobilePortToggle: false,
      superPortalMedView: false,
      superPortalSmallView: false,
      superPortalXSmallView: false,
      cloudMedView: false,
      mobileToggle: false
    };
  }

  mv = window.matchMedia('(min-width: 1541px)');
  sv = window.matchMedia('(min-width: 925px)');
  xsv = window.matchMedia('(min-width: 909px)');
  mobv = window.matchMedia('(min-width: 767px)');
  adminmv = window.matchMedia('(min-width: 1358px)');
  adminportmv = window.matchMedia('(min-width: 1138px)');
  adminportmsv = window.matchMedia('(min-width: 910px)');
  adminportsv = window.matchMedia('(min-width: 869px)');
  adminportxsv = window.matchMedia('(min-width: 810px)');
  adminportxxsv = window.matchMedia('(min-width: 775px)');
  adminportmobv = window.matchMedia('(min-width: 768px)');
  cloudmv = window.matchMedia('(min-width: 1340px)');
  spm = window.matchMedia('(min-width: 1557px)');  
  sps = window.matchMedia('(min-width: 1114px)');  
  spxs = window.matchMedia('(min-width: 883px)');  

  componentDidMount() {
    this.setState({
      mediumView: this.mv,
      smallView: this.sv,
      xsmallView: this.xsv,
      mobileView: this.mobv,
      adminMedView: this.adminmv,
      adminPortMedView: this.adminportmv,
      adminPortMedSmView: this.adminportmsv,
      adminPortSmView: this.adminportsv,
      adminPortXSmView: this.adminportxsv,
      adminPortXXSmView: this.adminportxxsv,
      adminPortMobileView: this.adminportmobv,
      cloudMedView: this.cloudmv,
      superPortalMedView: this.spm,
      superPortalSmallView: this.sps,
      superPortalXSmallView: this.spxs
      });

    this.mv.addListener(( mediumView ) => this.setState({  mediumView }));
    this.sv.addListener(( smallView ) => this.setState({ smallView }));
    this.xsv.addListener(( xsmallView ) => this.setState({  xsmallView }));
    this.mobv.addListener(( mobileView ) => this.setState({ mobileView }));
    this.adminmv.addListener(( adminMedView ) => this.setState({  adminMedView }));
    this.adminportmv.addListener(( adminPortMedView ) => this.setState({  adminPortMedView }));
    this.adminportsv.addListener(( adminPortSmView ) => this.setState({  adminPortSmView }));
    this.adminportmsv.addListener(( adminPortMedSmView ) => this.setState({  adminPortMedSmView }));
    this.adminportxsv.addListener(( adminPortXSmView ) => this.setState({  adminPortXSmView }));
    this.adminportxxsv.addListener(( adminPortXXSmView ) => this.setState({  adminPortXXSmView }));
    this.adminportmobv.addListener(( adminPortMobileView ) => this.setState({  adminPortMobileView }));
    this.cloudmv.addListener(( cloudMedView ) => this.setState({  cloudMedView }));
    this.spm.addListener(( superPortalMedView ) => this.setState({ superPortalMedView }));        
    this.sps.addListener(( superPortalSmallView ) => this.setState({ superPortalSmallView }));    
    this.spxs.addListener(( superPortalXSmallView ) => this.setState({ superPortalXSmallView })); 
    
    const btn = document.getElementById("mobileClose");
    btn.addEventListener("click", this.btnToggle);
  }

  btnToggle = (event) => {
    event.preventDefault();
    this.setState({mobileToggle: !this.state.mobileToggle});
  }

  subNavToggle = (event) => {
    event.preventDefault();
    this.setState({mobilePortToggle: !this.state.mobilePortToggle});
  }

  render() {
    const { route, subItems } = this.props.navigation;
    const showSubNav = subItems.includes(route);

    const mediumWidth = this.state.mediumView.matches;
    const smallWidth = this.state.smallView.matches;
    const xsmallWidth = this.state.xsmallView.matches;
    const mobileWidth = this.state.mobileView.matches;
    const adminMedWidth = this.state.adminMedView.matches;
    const adminPortMedWidth = this.state.adminPortMedView.matches;
    const adminPortMedSmWidth = this.state.adminPortMedSmView.matches;
    const adminPortSmWidth = this.state.adminPortSmView.matches;
    const adminPortXSmWidth = this.state.adminPortXSmView.matches;
    const adminPortXXSmWidth = this.state.adminPortXXSmView.matches;
    const adminPortMobileWidth = this.state.adminPortMobileView.matches;
    const cloudMedWidth = this.state.cloudMedView.matches;
    const superPortalMed = this.state.superPortalMedView.matches;         
    const superPortalSmall = this.state.superPortalSmallView.matches;     
    const superPortalXSmall = this.state.superPortalXSmallView .matches;   
      
    const renderTop = () => {
      if(showSubNav){
        var renderTopValue = 100;
      }else{
        var renderTopValue = 50;
      }
      if(this.props.role === "superduperadmin" ||
        this.props.role === "partneradmin"){
        if(superPortalMed){ //1557
          renderTopValue = showSubNav ? 100 : 50;
        }else if(mediumWidth){ //1541
          renderTopValue = showSubNav ? 100: 50;
        }else if(superPortalSmall){ //1114
          renderTopValue = showSubNav ? 150: 100;
        }else if(smallWidth){ //925
          renderTopValue = showSubNav ? 200: 100;
        }else if(xsmallWidth){ //909
          renderTopValue = showSubNav ? 200: 100;
        }else if(superPortalXSmall){ //883
          renderTopValue = showSubNav ? 250: 150;
        }else if(adminPortXSmWidth){ //810
          renderTopValue = showSubNav ? 300: 150;
        }else if(adminPortXXSmWidth){ //775
          renderTopValue = showSubNav ? 350: 200;
        }else if (adminPortMobileWidth){ //768
          renderTopValue = showSubNav ? 400 : 200;
        }else if (adminPortMobileWidth == false){ //768
          const subNavBtn = document.getElementById("subNavBarToggle");
          subNavBtn.addEventListener("click", this.subNavToggle);
          if(this.state.mobileToggle == true || this.state.mobilePortToggle == true){
            renderTopValue = 390;
          }else{
            renderTopValue = showSubNav ? 100 : 50;
          }
        }
      }else if(this.props.role === "cloudadmin"){
        if(adminMedWidth){ //1438
          renderTopValue = showSubNav ? 100 : 50;
        }else if(adminPortMedWidth){ //1138
          renderTopValue = showSubNav ? 150 : 100;
        }else if(adminPortMedSmWidth){ //910
          renderTopValue = showSubNav ? 200 : 100;
        }else if(adminPortSmWidth){ //869
          renderTopValue = showSubNav ? 250 : 150;
        }else if(adminPortXSmWidth){ //810
          renderTopValue = showSubNav ? 300 : 150;
        }else if(adminPortXXSmWidth){ //775
          renderTopValue = showSubNav ? 350 : 200;
        }else if (adminPortMobileWidth){ //768
          renderTopValue = showSubNav ? 400 : 200;
        }else if (adminPortMobileWidth == false){ //768
          const subNavBtn = document.getElementById("subNavBarToggle");
          subNavBtn.addEventListener("click", this.subNavToggle);
          renderTopValue = adminPortMobileWidth ? 200 : this.state.mobileToggle || this.state.mobilePortToggle ? 390 : showSubNav ? 100 : 50;
        }
      }else if(this.props.role === "cloudusers"){
        if(cloudMedWidth) { //1340
          renderTopValue = 50;
        }else if(smallWidth){  //925
          renderTopValue = 100;
        }else if(xsmallWidth){ //909
          renderTopValue = 100;
        }else if(adminPortXSmWidth){ //810
          renderTopValue = 100;
        }else if(adminPortXXSmWidth){ //775
          renderTopValue = 150;
        }else if(adminPortMobileWidth){ //768
          renderTopValue = 150;
        }else if (adminPortMobileWidth == false) {
          renderTopValue = this.state.mobileToggle ? 390 : 50;
        }
      }
      return renderTopValue;
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Paper
          style={{
            bottom: 0,
            display: 'flex',
            position: 'fixed',
            justifyContent: this.props.contentCenter ? 'center' : 'flexStart',
            top: renderTop(),
            marginTop:  0,
            width: '100%',
            overflowY: 'auto',
          }}
        >
        {this.props.children}
        </Paper>        
      </div>
    );
  }  
}

const mapStateToProps = (state) => {
  if(state.token){
    return ({
      navigation: state.navigation,
      role: state.token.role
    });
  }else{
    return ({
      navigation: state.navigation
    });
  }
}

MasterView.propTypes = {
  contentCenter: PropTypes.bool,
}

MasterView.defaultProps = {
  contentCenter: false,
}

export default connect(mapStateToProps)(MasterView);