import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'presentational/Loading';
import StandardSettingsPage from 'presentational/StandardSettingsPage';


class IdeasPortalLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
        jwt: '',
        ahaURL: 'https://fluentfeedback.ideas.aha.io/auth/jwt/callback?jwt=',
    };
  }

  componentDidMount() {
    fetch(`/api/v1/userportal/${this.props.token.tenant}/${this.props.token.user}/jwt/`)
      .then(response => response.json())
      .then(parsedResponse => {
        if (parsedResponse.error) {
          this.setState({ error: parsedResponse.error });
        } else {
          window.location.replace(this.state.ahaURL+parsedResponse);
          this.setState({ jwt: parsedResponse });
        }
      });
  }

  setText() {
    if (this.state.jwt) {
      return (
        <div>
          <Loading />
          <h2>Redirecting to the FluentCloud Ideas Portal.</h2>
          <p>
            Pop-up blockers may interfere with loading the portal. If you have problems <a href={this.state.ahaURL+this.state.jwt}>click here</a>.
          </p>
        </div>
      )
    } else if (this.state.error) {
      return (
        <div>
          <h2>Error Authenticating</h2>
          <p>
            {this.state.error.message}
          </p>
        </div>
      )
    } else {
      return (
        <div>
            <Loading />
            <h2>Authenticating</h2>
        </div>
      )
    }
  }

  render() {
    return (
      <StandardSettingsPage>
        {this.setText()}
      </StandardSettingsPage>
    )
  }
}

const mapStateToProps = (store) => ({
    token: store.token
});

export default connect(mapStateToProps)(IdeasPortalLogin);
