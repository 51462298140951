import { combineReducers } from 'redux';

import {
    CHANGE_TENANT,
    INVALIDATE_HTTP_DATA,
    SELECT_CALLFILTER,
} from '../actions/typeConstants';

const id = 'filter_group_name';

const auditHistory = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case 'INCOMINGFILTER_HTTP_SUCCESS':

        if (!action.auditHistory) {
            return state;
        }

        // If this is for an object, return
        if (action.reqObject) {
            return state;
        }

        return Object.assign([], state, action.data);
    default:
        return state;
    }
};

const data = (state = null, action) => {
 
    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case 'INCOMINGFILTER_HTTP_SUCCESS':

        if(action.data && action.data.assigned_route){
            return state;
        }

        if (action.auditHistory) {

            const newState = Object.assign([], state);

            const found = state.findIndex(obj => obj[id] == action.reqObject);
            if (found == -1 || !newState[found]) {
                return state;
            }

            newState[found].auditHistory = action.data;

            return Object.assign([], newState);
        }

        if (action.reqObject || action.data[id]) {

            const newState = Object.assign([], state);
            const found = state.findIndex(obj => obj[id] == action.reqObject);

            if (!action.data) {
                return state;
                //newState.splice(found, 1);
            } else if (found === -1) {
                newState.push(action.data);
            } else {
                if (action.data.assigned_route != "") {
                    newState[found]= action.data;
                } else {
                    newState.splice(found, 1);
                }            
            }

            return Object.assign([], newState);
        }

        return Object.assign([], state, action.data.data);
    default:
        return state;
    };
};

const metaData = (state = null, action) => {

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case 'INCOMINGFILTER_HTTP_SUCCESS':

        if (!action.data || !action.data.metaData || action.reqObject) {
            return state;
        }

        return action.data.metaData;
    default:
        return state;
    }
};

const httpStatus = (state = {}, action) => {

    switch (action.type) {

    case 'INCOMINGFILTER_HTTP_PENDING':
        return Object.assign({}, state, {
            pending: true,
            error: null,
        });

    case 'INCOMINGFILTER_HTTP_FAILURE':

        if (action.error) {
            let errorText;
            if(action.error.response.body && action.error.response.body.error.message){
                let errorObj = action.error.response.body.error.message;
                if (errorObj) {
                    errorText = errorObj;
                } else {
                    errorText = "Unspecified error, please try again or contact support."
                }
            } else {
                errorText = "Unspecified error, please try again or contact support."
            }
            return Object.assign({}, state, {
                pending: false,
                error: errorText,
            });
        } else {
            return Object.assign({}, state, {
                pending: false,
                error: errorText,
            });
        };
        
    case 'INCOMINGFILTER_HTTP_SUCCESS':
        return Object.assign({}, state, {
            pending: false,
            error: null,
        });
    default:
        return state;
    }
};

const selected = (state = null, action) => {


    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return null;

    case SELECT_CALLFILTER:
        return action.data;

    default:
        return state;
    }
};

export const incomingCallFilters = combineReducers({
    auditHistory,
    data,
    metaData,
    httpStatus,
    selected,
});
