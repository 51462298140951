import React, { Component } from 'react';
import isEqual from 'lodash.isequal';

import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import SubmitButton from 'presentational/formControls/SubmitButton';
import CancelButton from 'presentational/formControls/CancelButton';

import SettingsIcon from '@material-ui/icons/Settings';

const buttonContainer = {
  position: 'fixed',
  align: 'center',
  top: '95vh',
  zIndex: '5',
}

class CallRecordingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        recordingsetting: '',
      },
      rollBackForm: '',
    };
    this.formEdit = this.formEdit.bind(this);
  }

  componentDidMount() {
    if(!this.state.rollBackForm){
      const rollBackForm = Object.assign({}, this.props.form);
      this.setState({
        form: this.props.form,
        rollBackForm,
      });
    }
  }

  componentDidUpdate() {
    if(!isEqual(this.state.form, this.props.form)) {
      const rollBackForm = Object.assign({}, this.props.form);
      this.setState({
        form: this.props.form,
        rollBackForm,
      });
    }
  }

  formEdit(field, data) {
    const { form } = this.state;
    form[field] = data;
    this.props.madeChange(form);
  }

  render() {
    return (
      <StandardSettingsPage>
        <FormControlCard
          form={this.state.form}
          field="recordingsetting"
          icon={<SettingsIcon/>}
          formControl="RadioInput"
          title="Call Recording Options:"
          subtitle=""
          submitForm={(field, data) => { this.formEdit(field, data) }}
          options={["Always Record Calls", "Record On Key Sequence #9"]}
          values={['Always', 'On Demand',]}
        />
        <div style={buttonContainer}>
          <SubmitButton
            label="submit"
            onClick={() => {
              this.setState({
                rollBackForm: Object.assign({}, this.state.form),
              });
              this.props.editRequest(this.state.form);
            }}
            disabled={!this.props.changed}
          />
          <CancelButton
            label="cancel"
            onClick={() => {app.navigate('#userportal', { trigger: true })}}
          />
        </div>
      </StandardSettingsPage>
    );
  }
}

export default CallRecordingForm;
