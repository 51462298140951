import get from 'lodash.get';

export const navigateInVoicemailDrop = (route) => {
  if (route) {
    app.navigate(`voicemailrecording/${route}`);
  } else {
    app.navigate('voicemailrecording');
  }
}

export const isValid = (data, voicemails, record = true) => {
  const takenNames = voicemails.recordings.map(recording => { return recording.name });
  const friendlyname = get(data, 'friendlyname', '');
  const name = `${friendlyname.replace(/[^a-zA-Z0-9-_]/g, '')}.wav`

  if (record) {
    if (friendlyname === '') {
      return {
        title: 'Error!',
        message: 'You must enter a title for your recording.',
      };
    } else if (friendlyname.toLowerCase() === 'new' || takenNames.includes(name)) {
      return {
        title: 'Error!',
        message: 'Invalid title entered.',
      };
    }
  }
  
  const regExp = new RegExp('/^\d+$/');

  if (data.feature_code < 0 || data.feature_code > 9) {
    return {
      title: 'Error!',
      message: 'You must enter a number between 0-9 as feature code.',
    };
  }

  if (data.feature_code === '' || regExp.test(data.feature_code)) {
    return {
      title: 'Error!',
      message: 'You must enter a unique number as feature code.',
    };
  } else {
    for (const recording of voicemails.recordings) {
      if (data.feature_code === recording.feature_code && data.id !== recording.id) {
        return {
          title: 'Error!',
          message: 'You must enter a unique number as feature code.',
        };
      }
    }
  }

  if (regExp.test(data.countdown)) {
    return {
      title: 'Error!',
      message: 'Countdown must be a number.',
    };
  }

  if (data.countdown_enabled == '1' && data.countdown <= 2) {
    return {
      title: 'Error!',
      message: 'Countdown must be greater than 2 seconds.',
    };
  }
  return false;
}