import React, { Component } from 'react'
import { connect } from 'react-redux';
import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import { putCoreHttp, getCoreHttp } from 'actions/httpRequest';
import DescriptionCard from './descriptionCard';
import Loading from 'presentational/Loading';
import {  resetReloadDetails } from 'actions/phoneNumbers';
import DialogModal from 'presentational/DialogModal';
import Footer from 'presentational/Footer';
import { translatePhoneTypeToFriendlyName } from 'utils/translations';
import { isAllowedTenant } from './limitedRollout';

const US_NAME = "United States of America";
const CANADA_NAME = "Canada";

class ENineOneOne extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      abbrevForm: {},
      changedValues:{},
      error: null,
      submitError: null,
      address: '',
      loading: false,
      validatedAddress: false,
    }

    this.usingapiData = false;
  }

  componentWillUnmount(){
    if(!this.props.buttonDisabled){
      this.props.checkSubmit(this.state.form);
      this.props.disableButton(true);
    }
    this.props.resetReloadDetails('store');
  }

  onSubmit = () => {
    this.props.disableButton(true);
    this.editNumber(this.state.form)
  }

  updateDetails = () => {
    if(this.props.reloadDetails === 'store' && this.props.phoneNumberDetails){
      this.props.resetReloadDetails();

      this.setState({ 
        form: this.props.phoneNumberDetails,
        loading: this.props.loading, 
      });
    }
  }

  componentDidMount(){
    this.updateDetails()
    if(this.props.phoneNumberDetails && this.props.phoneNumberDetails['location']){
      const reqData = {
        reqAction: 'locations',
        reqObject: this.props.phoneNumberDetails['location']
      }
  
      const storeKey = 'locations';
      this.props.getCoreHttp(reqData, storeKey);
    }
  }

  handleClick = (e) => {
    if(this.node) {
      if(this.node.contains(e.target)) {
        this.props.clickedNode();
      }
    }
  }

  componentDidUpdate(){
   this.updateDetails();
   if(this.props.location && this.props.location.address){
     const { address, city, state, zip } = this.props.location;
     const newAddress = `${address}, ${city}, ${state} ${zip}`;
     if (newAddress !== this.state.address) {
      const country = this.props.location.country ?? US_NAME;      
      const isAvailableCountry = country === US_NAME || country === CANADA_NAME;
      const hasComponents = Boolean(this.props.location.address_components);

      this.setState({ 
        address: newAddress,  
        validatedAddress: hasComponents && isAvailableCountry,
      });
     }
   }
  }

  submitForm = (field, data) => {
    if (this.state.form[field] === data) {
      console.log('No update');
      return;
    } else {
      this.props.updateAbbrevForm(field, data)
      this.props.madeChanges(true, this.state.form);
    }
    
    if(this.props.buttonDisabled){
      this.props.disableButton(false)
    }

    let changedValues ={...this.state.changedValues};
    changedValues[field] = `${translatePhoneTypeToFriendlyName(field)}: ${data}`;

    let abbrevForm = {...this.state.abbrevForm};
    abbrevForm[field] = data;

    this.setState({ 
      form: {
        ...this.state.form, 
        [field]: data
      },
      abbrevForm,
      changedValues,
    });
  }

  editNumber = (data) => {
    const reqData = {
      reqAction: 'phonenumbers',
      reqObject: encodeURIComponent(data.phonenumber),
      reqBody: data,
    };

    const storeKey = 'editphonenumbers';
    this.props.putCoreHttp(reqData, storeKey);
  }

  submitE911Settings = (data) => {
    let { abbrevForm } = {...this.state};

    abbrevForm.phonenumber = this.props.selectedPhoneNumber;

    const reqData = {
      reqAction: 'bulkeditphonenumber',
      reqObject: 'bulkeditphonenumber',
      reqBody: abbrevForm,
    };

    const storeKey = 'bulkeditphonenumber';
    this.props.putCoreHttp(reqData, storeKey);
  } 

  render() {

    let { isModalOpen, changedValues, loading } = this.state;
    const { selectedPhoneNumber } = this.props;

    if (this.props.loading === false){
      loading = false;
    }

    if(!this.props.phoneNumberDetails || !this.props.location || loading){
      return ( <Loading /> );
    }

    const isAddressSet = Boolean(this.state.address);

    // Do not want to show the error message until an address is selected. So,
    // I only want to show the error message if an address is set and it is not validated.
    const validatedAddress = isAddressSet && 
                             !this.state.validatedAddress ? false : true;

    // Only want to allow the form to be submitted, if the address is set and 
    // the address has been validated.
    const isSubmitDisabled = this.props.buttonDisabled  ||
                            !isAddressSet ||
                            !this.state.validatedAddress;
                          
    const enabledRoomLevel = this.state.form.enable_room_level_e911;
    const cantTurnOn911 = !isAddressSet || !this.state.validatedAddress;
    const is911TurnedOn = enabledRoomLevel === '1' || enabledRoomLevel === true;

    const validationAllowed = isAllowedTenant();

    return (
      <div
        ref={node => this.node = node} 
        style={{
          display: 'flex',
          flex: 1,
          height: '100%',
          background: '#EFEFEF',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: '50px',
          overflow: 'auto',
        }}
        onMouseDown={this.handleClick}
      >
      <StandardSettingsPage>
        <DescriptionCard title='e911 Settings' />
        <FormControlCard
          form={this.state.form}
          field="enable_room_level_e911"
          formControl="ToggleInput"
          title="Enable room level e911"
          subtitle=""
          submitForm={this.submitForm}
          label="No / Yes"
          disabled={ cantTurnOn911 && validationAllowed}
          errorText={ cantTurnOn911 && validationAllowed && "A validated address must be set." }
        />
        <FormControlCard
          disabled={true}
          form={this.state.form}
          field="disabled_address"
          formControl="TextInput"
          title="Address"
          type='text'
          subtitle=""
          value={this.state.address}
          submitForm={this.submitForm}
          errorText={ !validatedAddress && validationAllowed && "Address has not been validated" }
        />
        <FormControlCard
          form={this.state.form}
          field="room_e911_address_unit_type"
          formControl="SelectInput"
          title="Unit type"
          submitForm={this.submitForm}
          options={['Apartment', 'Suite', 'Unit']}
          disabled={ (!is911TurnedOn || cantTurnOn911) && validationAllowed }
        />
        <FormControlCard
          form={this.state.form}
          field="room_e911_address_unit_number"
          formControl="TextInput"
          title="Unit number"
          type='text'
          subtitle=""
          submitForm={this.submitForm}
          disabled={(!is911TurnedOn || cantTurnOn911) && validationAllowed}
        />
        <div style={{
          position: 'fixed',
          align: 'center',
          top: '93vh',
          zIndex: '1',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '55px',
          padding: '5px 0',
          background: 'white',
         }}>
          <Footer
            multipleChanges={this.props.selectedPhoneNumber.length > 1 ? true : false}
            disabled={isSubmitDisabled && validationAllowed}
            onClick={(cancel) => this.onSubmit(cancel)}
            onUpdateAll={() => this.setState({ isModalOpen: true })}
          />
        </div>
      </StandardSettingsPage>
      <DialogModal 
        show={isModalOpen}
        affirmativeResponse={()=> {this.submitE911Settings(this.state.form), this.props.disableButton(true), this.setState({ isModalOpen: false, loading: true })}}
        negativeResponse={()=> {this.props.disableButton(true), this.setState({ isModalOpen: false })}}
        title={`Are you sure you want to apply the following changes to all selected phone numbers?`}
        content={Object.values(changedValues)}
      />
      </div>
    )
  }
}

const bindActions = (dispatch) => ({
  putCoreHttp: (reqData, storeKey) => dispatch(putCoreHttp(reqData, storeKey)),
  getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
  resetReloadDetails: (data) => dispatch(resetReloadDetails(data))
})

const mapStateToProps = state => ({
  phoneNumberDetails: state.phoneNumbers.phoneNumberDetails,
  location: state.phoneNumbers.location,
  loading: state.phoneNumbers.loading,
  reloadDetails: state.phoneNumbers.reloadDetails
});

export default connect(mapStateToProps, bindActions)(ENineOneOne);
