import { combineReducers } from 'redux';

const data = (state= null, action) => {

    switch(action.type){

        case "VOICEMAIL_HTTP_SUCCESS":

            if (action.reqObject){

                //Delete a left bar VM Box
                if (action.reqType === "DELETE"){

                    Object.entries(state).forEach(mailboxArr => {
                        if (mailboxArr && mailboxArr[1].mailbox == action.reqObject) {
                            delete state[mailboxArr[0]];
                        }
                    })

                    return state;
                }

                //Select a left bar VM Box
                const newState = Object.assign({}, state);
                Object.entries(newState).forEach(mailboxArr => {
                    if (mailboxArr && mailboxArr[1].mailbox == action.reqObject) {
                        newState[mailboxArr[0]] = action.data;
                    }
                })

                return newState;
            };

            if (action && action.data && action.data.data) {
                //There are two conditions which we'd be here, the first is that we switch
                //tenants and we need to reset our state to reflect that. The second is 
                //that we fetch more information on a voicemailbox, and we append that data
                //to that particular voicemail box
                if (!action.data.data[0]){
                    return null;
                }

                if (state && state[0] && state[0].mailbox == action.data.data[0].mailbox && 
                    state[0].fullname == action.data.data[0].fullname){
                    return state;
                } else {
                    return action.data.data;
                }

            } else if(action && action.data && action.data.mailbox) {
                //we added a new voicemail box

                let newVMBox = {
                    mailbox: action.data.mailbox,
                    fullname: action.data.fullname ? action.data.fullname : action.data.mailbox
                }

                const newState = Object.assign({}, state);
                let newStateIndex;
                const stateKeyArr = Object.keys(newState);

                for (let i = 0; i <= stateKeyArr.length; i++) {
                    if (!stateKeyArr.includes(i.toString())) {
                        newStateIndex = i;
                    }
                }

                newState[newStateIndex] = newVMBox;

                return newState;

            } else {
                
                return state;
            }
            break;

        case "VOICEMAILSETTINGS_HTTP_SUCCESS":

            if (action.reqType == "PUT" && action.reqObject) {
                const newState = Object.assign({}, state);
                Object.entries(newState).forEach(mailboxArr => {
                    if (mailboxArr && mailboxArr[1].mailbox == action.reqObject) {
                        newState[mailboxArr[0]] = action.data;
                    }
                })

                return newState;
            } else {
                return state;
            }
            break;

        default:
        
            return state;
        break;
    }
}

const history = (state=null, action) => {
    switch (action.type){        
        case "VOICEMAILHISTORY_HTTP_SUCCESS":
            
            return Object.assign({}, state, action.data );
        break;
        default: 

            return state;
        break;
    }
}

const inbox = (state=null, action) => {
    switch (action.type){        
        case "VOICEMAILMESSAGES_HTTP_SUCCESS":
            switch(action.reqType){
                case 'PATCH':
                    let messageids = action.data;

                    if (action.reqObject == 'permDelete') {
                        return {
                            ...state,
                            data: state.data.filter(msg => !messageids.includes(msg.id)),
                        }
                    } else {
                        return state;
                    }
                default:
                    return Object.assign({}, state, action.data );
            }
        break;

        case "USER_HTTP_SUCCESS":

            return Object.assign({}, state, action.data);
        break;
        default: 

            return state;
        break;
    }
}

const greetings = (state=null, action) => {

    let newState = {};

    switch (action.type){        
        case "VOICEMAILGREETINGS_HTTP_SUCCESS":
            if (action && action.data && action.data.data){
                state = [];
                return Object.assign({}, state, action.data.data);
            } else if (action.reqType === "POST"){
                //if we get a new greeting, let's make sure it's 
                //formatted properly
                let newGreeting = {
                    greeting: action.data.folder,
                    filepath: action.data.filepath
                }

                Object.keys(state).forEach(function(key){
                    if (key === "0" || key === "1" || key === "2" || key === "3"){
                        newState[key] = state[key];
                    }
                });

                //Let's add our new greeting to the end of our state object
                let newStateLength = Object.keys(newState).length;
                newState[newStateLength] = newGreeting;
                state = newState;
                return Object.assign({}, state, newGreeting);
            } else if (action.reqType === "DELETE"){
                let i= 0;
                Object.values(state).forEach(function(values) {
                    if (values['greeting'] && values['greeting'] !== action.reqObject) {
                        newState[i] = values;
                        i++;
                    }
                });

                state = newState;

                return state;
            } else {
                return state;
            }

        break;
        default: 

            return state;
        break;
    }
}

const pending = (state = false, action) => {

    switch (action.type) {

    case 'VOICEMAIL_HTTP_PENDING':
        return true;

    case 'VOICEMAIL_HTTP_SUCCESS':    
    case 'VOICEMAILHISTORY_HTTP_SUCCESS':
    case 'VOICEMAILMESSAGES_HTTP_SUCCESS':
    case 'VOICEMAILGREETINGS_HTTP_SUCCESS':
    
        return false;

    default:
        return state;
    }
};

const error = (state = false, action) => {

    switch (action.reqType) {

        case 'FAILURE_POST': 
        case 'FAILURE_GET':
        case 'FAILURE_PUT':
        case 'FAILURE_DELETE:':
        case 'USER_HTTP_FAILURE':

            return state = action.res.statusText;

        case 'PATCH':
            if (action.error){
                return action.error.message;
            } else {
                return false;
            }
        
        default:
            return false;
    }
};

const putSuccess = ( state = null, action) => {

    switch(action.type){
        case "VOICEMAILSETTINGS_HTTP_SUCCESS":
        case "VOICEMAIL_HTTP_SUCCESS":
            switch (action.reqType) {
                case 'PUT':
                    return {success: true, object: action.reqObject}
                default:
                    return false;
            }
        default:
            return false;
    }
};

const deleteSuccess = ( state = null, action) => {

    switch(action.type){
        case "VOICEMAIL_HTTP_SUCCESS":
            switch(action.reqType) {
                case 'DELETE':
                    return {success: true, object: action.reqObject}
                default:
                    return false;
            }
        case "VOICEMAILGREETINGS_HTTP_SUCCESS":
            switch(action.reqType){
                case 'DELETE':
                    return {success: true, object: action.reqObject}
                default:
                    return false;
            }
        default: 
            return false;
    }
};

const postSuccess = ( state = null, action) => {

    switch(action.type){
        case "VOICEMAIL_HTTP_SUCCESS":
            switch (action.reqType) {
                case 'POST':
                    if (action.storeKey == "voicemailgreetings"){
                        return {success: true, storeKey: action.storeKey, object: action.reqObject}
                    }
                    return {success: true, object: action.data.mailbox}
                default:
                    return false;
            }
        case "VOICEMAILGREETINGS_HTTP_SUCCESS":
            switch (action.reqType) {
                case 'POST':
                    if (action.storeKey == "voicemailgreetings"){
                        return {success: true, storeKey: action.storeKey, object: action.reqObject}
                    }
                    return {success: true, object: action.data.mailbox}
                default:
                    return false;
            }
        case "USER_HTTP_SUCCESS":
            switch (action.reqType) {
                case 'POST':
                    if (action.storeKey == "user"){
                        return {success: true, storeKey: action.storeKey}
                    }
                    return {success: true, object: action.data.mailbox}
                default:
                    return false;
            }
        default: 
            return false;
    }
};

const patchSuccess = ( state = null, action) => {

    switch(action.type){
        case "VOICEMAIL_HTTP_SUCCESS":
            switch(action.reqType){
                case 'PATCH':
                    return {success: true, object:action.reqObject}
                default: 
                    return false;
            }
        case "USER_HTTP_SUCCESS":
            switch(action.reqType){
                case 'PATCH':
                    return {success: true, object: action.reqObject}
                default:
                    return false;
            }
        case "VOICEMAILMESSAGES_HTTP_SUCCESS":
            switch(action.reqType){
                case 'PATCH':
                    if (action.reqObject == 'permDelete') {
                        return {success: true, object: action.reqObject}
                    }
                default:
                    return false;
            }
        default: 
            return false;
    }
};

const selectedMailbox = ( state = null, action) => {

    if (action.type == "SELECT_MAILBOX"){
        return action.payload;
    }

    return state;

};

const reducerTarget = ( state = null, action) => {
    if (action && action.type && action.type == "VOICEMAIL_HTTP_SUCCESS" || "VOICEMAILGREETINGS_HTTP_SUCCESS"){
            return "voicemailboxes";
    } else {
        return state;
    }
};

const extensionData = ( state = null, action) => {

    if (action.type == 'EXTENSIONS_HTTP_SUCCESS' && action && action.data){
        return action.data.data;
    }

    //if we fetch for settings, let's add that to this object"
    if (action.type == "VOICEMAILSETTINGS_HTTP_SUCCESS"){
        const newState = Object.assign([], state);
        const found = newState.findIndex(obj => obj.mailbox == action.reqObject);
        newState[found] = action.data;
        return newState;
    }

    return state;
};

const callforwarding = ( state = null, action ) => {
    if (action && action.type == "CALLFORWARDING_HTTP_SUCCESS" && action.data.config){
        return action.data.config;
    }
    return state;
}

export const voicemail = combineReducers({
    data,
    history,
    inbox,
    greetings,
    pending,
    error, 
    putSuccess,
    postSuccess,
    patchSuccess,
    deleteSuccess,
    selectedMailbox,
    reducerTarget,
    extensionData,
    callforwarding
});
