import {
  CALLSCREENING_HTTP_SUCCESS,
  EDITCALLSCREENING_HTTP_SUCCESS,
  CALLSCREENING_HTTP_FAILURE,
  EDITCALLSCREENING_HTTP_FAILURE,
  RESET_FLAG,
  UPDATE_DO_NOT_SCREEN_NUMBER,
} from '../actions/typeConstants';

import { parseError } from 'utils/misc';

const initialState = {
  callScreeningData: null,
  successMsg: null,
  errorMsg: null,
  errorCode: null,
}

export default function(state = initialState, action) {
  let callScreeningData;
  switch(action.type) {
    case CALLSCREENING_HTTP_SUCCESS:
      return { ...state, callScreeningData: action.data, errorMsg: null, errorCode: null }
    case CALLSCREENING_HTTP_FAILURE:
      return { ...state, errorMsg: parseError(action.error.response.text), successMsg: null, errorCode: action.error.response.status }
    case EDITCALLSCREENING_HTTP_SUCCESS:
      callScreeningData = action.data;
      if (!action.data.callScreeningData) {
        callScreeningData.csc_do_not_screen = state.callScreeningData.csc_do_not_screen;
      }
      return { ...state, callScreeningData, successMsg: 'Saved!', errorMsg: null, errorCode: null }
    case EDITCALLSCREENING_HTTP_FAILURE:
      return { ...state, errorMsg: parseError(action.error.response.text), successMsg: null, errorCode: action.error.response.status }
    case RESET_FLAG:
      return {...state, successMsg: null, errorMsg: null, errorCode: null }
    case UPDATE_DO_NOT_SCREEN_NUMBER:
      callScreeningData = state.callScreeningData;
      callScreeningData.csc_do_not_screen = action.payload;
      return { ...state, callScreeningData }
    default:
      return { ...state }
  }
}
