import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Button from '@material-ui/core/Button';
import makeGlobalStyles from 'makeGlobalStyles';

export default function HelpContent(props) {
  const classes = props.useStyles();
  const globalClasses = makeGlobalStyles();
  return (
    <div className={classes.wrapper}>
      <Card classes={{ root: classes.helpPaper }} elevation={1}>
        <CardContent classes={{ root: classes.helpContent }}>
          <CardContent>
            <h2 className={classes.pageTitle}>Manage Call Groups</h2>
              <p className={classes.helpText}>
                Call Groups are used to ring multiple extensions.  You may ring this extensions simultaneously or sequentially.
              </p>
          </CardContent>
          <CardContent classes={{ root: classes.helpTextTitle }}>
            About Call Groups
          </CardContent>
          <CardContent>
            <p className={classes.helpText}>Call groups can be used individually within a <a href="#routes">route</a>, as an option on an <a href="#autoattendant">Auto Attendant</a>, or as a failover destination.</p>
            <p className={classes.helpText}>You should use a call group when you want multiple phones to ring. For instance, if you have a sales group or multiple reception phones, you will want to group them together in a Call Group.</p>
            <p className={classes.helpText}>If you have very high call volume or long call times, a <a href="#queues">Queue</a> may better suit your needs.  A <a href="#queues">Queue</a> will allow callers to remain on hold until someone becomes available to answer.  This way you can live answer calls without having calls failover to voicemail.</p>
          </CardContent>
        </CardContent>
        <CardContent classes={{ root: classes.standardHelpBlurb }}>
          <CardContent>
            <h2 className={classes.helpTextTitle}>Need Help?</h2>
            {tplHelper.get('label') == 'yiptel' ?
              <div>
                <p className={classes.helpText}>The whole team at YipTel is ready to help you. 24 hours a day, 365 (or 366!) days a year. You can reach us a number of ways.</p>
                <ul>
                  <li>By calling 800-752-6110</li>
                  <li>By emailing support@yiptel.com</li>
                  <li>By clicking the button below!</li>
                </ul>
              </div>
              :
              <div>
                <p className={classes.helpText}>The whole team at FluentStream is ready to help you. 24 hours a day, 365 (or 366!) days a year. You can reach us a number of ways.</p>
                <ul>
                  <li>By calling 303-GO-CLOUD (303-462-5683)</li>
                  <li>By emailing support@fluentstream.com</li>
                  <li>By clicking the button below!</li>
                </ul>
              </div>
            }
            {/*<FlatButton
              backgroundColor="#0F4E8B"
              label="Get help from FluentStream"
              labelStyle={{ color: "#FFF" }}
              href="#supportCallModal"
            />*/}
            <p />
            <Button
              size='large'
              classes={{ root: globalClasses.helpButton }}
              href="http://help.fluentcloud.com/support/solutions/articles/126470-how-to-add-a-call-group"
              target="_blank"
            >How to manage call groups</Button>
          </CardContent>
        </CardContent>
      </Card>
    </div>
  );
}