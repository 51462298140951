import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FormControlCard from 'presentational/FormControlCard';
import CountDownComponent from 'presentational/CountDownComponent';
import Footer from 'presentational/Footer';

import { postUserPortalHttp } from 'actions/httpRequest';
import { errorNotification } from 'actions/notifications';
import { isValid } from './voicemailDropUtils';

const initialForm = {
  name: '',
  feature_code: '',
  countdown_enabled: '0',
  countdown: '',
  sample_rate: 44100,
}

function Record(props) {
  const [data, setData] = useState({...initialForm});
  const [recording, setRecording] = useState(null);
  const [show, setShow] = useState(false);

  const changeData = (field, info) => {
    if (info === true) {
      info = '1';
    } else if (info === false) {
      info = '0';
    }
    const newData = { ...data };
    newData[field] = info;
    setData(newData);
  }

  const isValidInput = () => {
    const error = isValid(data, props.voicemailDropData);

    if (error) {
      errorNotification(error);
      return false;
    }
    return true;
  }

  const saveRecoding = () => {
    const blob = recording.blob;
    const fileType = blob.type.split('/')[0] || 'audio';
    
    const formData = new FormData();
    formData.append(fileType + '-filename', data.friendlyname);
    formData.append(fileType + '-blob', blob);
    formData.append("name", data.friendlyname);
    formData.append("vm_drop", 1);
    formData.append("feature_code", data.feature_code);
    formData.append("countdown", data.countdown);
    formData.append("countdown_enabled", data.countdown_enabled);

    const payload = formData;
    payload.append('file', formData);

    const reqData =  {
      reqAction: 'voicemailrecording',
      reqBody: formData,
    }

    const storeKey = 'createvoicemailrecording';
    props.postUserPortalHttp(reqData, storeKey);
  }

  const submitForm = (cancel) => {
    if (cancel) {
      props.onCancel();
    } else {
      if (isValidInput()) {
        if (recording) {
          saveRecoding()
        } else {
          errorNotification({
            title: 'Error!',
            message: 'There must be a recording present to upload',
          });
        }
      }
    }
  }

  const sampleRateOptions = [
    { label: '16,000Hz', value: 16000 },
    { label: '22,050Hz', value: 22050 },
    { label: '44,100Hz (Recommended)', value: 44100 },
    { label: '48,000Hz', value: 48000 },
    { label: '96,000Hz', value: 96000 },
  ];
  const featureCodeOptions = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const countdownOptions = ['3', '4', '5', '6', '7', '8', '9', '10'];

  return (
    <>
      <FormControlCard
        title='Record'
        formControl='RecordAudio'
        uploadGreeting={(recording) => {setRecording(recording)}}
        createNewGreeting={() => {}}
        audioBitsPerSecond={data.sample_rate}
        noSave={true}
        showDownloadButton={true}
      />
      <FormControlCard
        form={data}
        field="friendlyname"
        formControl="TextInput"
        title="Title"
        type="text"
        subtitle='The title of your voicemail drop'
        submitForm={changeData}
      />
      <FormControlCard
        form={data}
        field="feature_code"
        formControl="SelectInput"
        title="Feature Code"
        subtitle=""
        submitForm={changeData}
        options={featureCodeOptions}
      />
      <FormControlCard
        form={data}
        field="countdown_enabled"
        formControl="ToggleInput"
        title="Enable an Introduction Countdown"
        subtitle=""
        submitForm={changeData}
        label="No / Yes"
      />
      {data.countdown_enabled === '1' && 
        <>
          <FormControlCard
            form={data}
            field="countdown"
            formControl="SelectInput"
            title="Countdown"
            subtitle='This is the number of seconds you get to speak before the voicemail drop is sent.'
            submitForm={changeData}
            options={countdownOptions}
          />
          <CountDownComponent
            title='Test Countdown Introduction'
            value={data.countdown}
          />
        </>  
      }
      <FormControlCard
        formControl='ShowAdvance'
        show={show}
        onChange={() => setShow(!show)}
      />
      {
        show &&
          <FormControlCard
            form={data}
            field='sample_rate'
            formControl="SelectInput"
            title='Sample Rate'
            submitForm={changeData}
            subtitle='Attention: These settings should only be modified by users with audio configurations knowledge.'
            options={sampleRateOptions}
          />
      }
      <Footer
        disabled={false}
        onClick={(cancel) => submitForm(cancel)}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  voicemailDropData: state.voicemailDrop.voicemailDropData,
});

const bindActions = (dispatch) => ({
  postUserPortalHttp: (reqData, storeKey) => dispatch(postUserPortalHttp(reqData, storeKey)),
});

Record.propTypes = {
  onCancel: PropTypes.func,
  voicemailDropData: PropTypes.oneOfType([() => null, PropTypes.object]),
}

export default connect(mapStateToProps, bindActions)(Record);