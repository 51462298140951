import React, { Component } from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/styles';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';


import CopyIcon from '@material-ui/icons/Assignment';
import StarIcon from '@material-ui/icons/Star';
import BlankStarIcon from '@material-ui/icons/StarBorder';
import ToggleInput from 'presentational/formControls/ToggleInput';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import PhoneNoHelp from './help'
import BuyNewNumber from './buyNewNumber';
import History from './history';
import FaxForm from './fax'
import AdvancedSettings from './advancedSettings';
import RoutingSetting from './routingSetting';
import E911 from './e911';
import PurchaseSuccess from './purchaseSuccess';

import StandardDetailsPage from 'presentational/StandardDetailsPage';
import Loading from 'presentational/Loading';
import { formatNumber } from 'utils/phoneNumbers';
import ActionCard from 'presentational/ActionCard';
import DetailContainer from 'presentational/DetailContainer';
import LeftBar from 'presentational/LeftBar';
import MasterView from 'presentational/MasterView';
import SaveChangesModal from 'presentational/SaveChangesModal';

import { ExternalService } from "../services";

import {
  deleteCoreHttp,
  getCoreHttp,
  postCoreHttp,
  putCoreHttp,
} from 'actions/httpRequest';
import {
  selectPhoneNumber,
  resetLeftBarFlag,
  resetLoading,
  resetFlag,
  resetReloadDetails,
  setTenant,
  resetLocation,
} from 'actions/phoneNumbers';
import { successNotification, errorNotification } from 'actions/notifications';

import TimeMachine from './timeMachine';

const styles = () => ({
  button: {
    fontSize: '100%',
  },
  menuItem: {
    fontSize: '100%',
  },
  buttonStyle: {
    minWidth: '25px',
  },
});

class PhoneNumbers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leftBarData: null,
      route: null,
      selectedPhoneNumber: [],
      showingDeleteConfirmModal: false,
      showTimeMachineModal: false,
      showAreYouSureModal: false,
      submitButtonDisabled: true,
      showUnsavedChangesModal: false,
      clickedInsideElements: false,
      newChanges: false,
      childFormData: {},
      closeMenu: false,
      filterPhoneNumbers: false,
      showingClearPortingConfirmModal: false,
      showingDisassociationModal: false,
      showingNotDeletedNumberModal: false,
      timesShowedDeletedNumberModal: 0,
      abbrevForm: {},
    }
    this.storedFetchedData = false;
    this.favorites = [];
  }

  checkSubmit = (data, inputValue, checkValue) => {
    if (!data) {
      return;
    }

    //if we had multiple phone numbers selected, don't do this
    if (this.state.childFormData.selectedPhoneNumber &&
      this.state.childFormData.selectedPhoneNumber.length > 1) {
      return;
    }

    if (!this.state.submitButtonDisabled) {
      const reqData = {
        reqAction: 'phonenumbers',
        reqObject: encodeURIComponent(data.phonenumber),
        reqBody: data,
      };

      const storeKey = 'editphonenumbers';
      this.props.putCoreHttp(reqData, storeKey);
      this.props.resetLocation();
    }
  }

  discardChange = () => {
    const id = this.state.showUnsavedChangesModal;
    if (id === this.state.selectedPhoneNumber || id === true) {
      this.setState({
        showUnsavedChangesModal: false,
        clickedInsideElements: false,
        newChanges: false,
        childFormData: {},
        submitButtonDisabled: true,
        route: this.state.selectedPhoneNumber,
      });
    }
    else {
      this.setState({
        showUnsavedChangesModal: false,
        clickedInsideElements: false,
        newChanges: false,
        childFormData: {},
        submitButtonDisabled: true
      }, () => app.navigate(`phonenumbers/${id}`, { trigger: true }))
    }
  }

  select = (id) => {
    if (!this.state.submitButtonDisabled) {
      this.setState({ showUnsavedChangesModal: id });
      return;
    }
    if (id === this.state.selectedPhoneNumber) {
      this.setState({ route: id })
    }
    else {
      app.navigate(`phonenumbers/${id}`, { trigger: true })
    }
  }

  cancelModal = () => {
    this.setState({
      showUnsavedChangesModal: false,
      clickedInsideElements: false,
      newChanges: false,
      childFormData: {},
      submitButtonDisabled: true,
    });
  }

  bulkEditNumbers = (data) => {

    // We only have data if we've passed it 
    // from the 'Favorite' workflow, so we should
    // handle things slightly differently here

    let { selectedPhoneNumber } = this.state.childFormData;
    let { abbrevForm } = this.state;

    //we need to add all the phone numbers here
    abbrevForm.phonenumber = selectedPhoneNumber;
    if (data) {
      abbrevForm.favorite = data.favorite;
      abbrevForm.phonenumber = this.state.selectedPhoneNumber
    }

    const reqData = {
      reqAction: 'bulkeditphonenumber',
      reqObject: 'bulkeditphonenumber',
      reqBody: abbrevForm,
    };

    const storeKey = 'bulkeditphonenumber';
    this.props.putCoreHttp(reqData, storeKey);
  }

  saveChange = () => {

    if (this.state.childFormData.selectedPhoneNumber && this.state.childFormData.selectedPhoneNumber.length > 1) {
      this.setState({ showUnsavedChangesModal: false })
      return this.bulkEditNumbers();
    }

    let id = this.state.showUnsavedChangesModal;
    if (id === this.state.selectedPhoneNumber || id === true) {
      this.setState({
        showUnsavedChangesModal: false,
        clickedInsideElements: false,
        newChanges: false,
        childFormData: {},
        route: this.state.selectedPhoneNumber,
      })
    }
    else {
      this.setState({
        showUnsavedChangesModal: false,
        clickedInsideElements: false,
        newChanges: false,
        childFormData: {},
      }, () => { app.navigate(`phonenumbers/${id}`, { trigger: true }) })
    }

  }

  disableButton = (value = true) => {
    if (value === true) {
      this.setState({
        submitButtonDisabled: value,
        newChanges: false,
      });
    } else {
      this.setState({ submitButtonDisabled: value });
    }

  }

  onModalClose = () => {
    this.props.resetFlag();
    this.setState({ showTimeMachineModal: false })
  }

  fetchLocations = () => {
    const reqData = {
      reqAction: 'locations',
    };

    const storeKey = 'locations';
    this.props.getCoreHttp(reqData, storeKey);
  }

  editFavorite = () => {
    const data = { ...this.props.phoneNumberDetails };

    if (data.favorite === '1') {
      data.favorite = '0';
    } else if (data.favorite === '0') {
      data.favorite = '1';
    }

    //If we've selected multiple numbers, we need to 
    // follow that workflow, not the typical one
    if (this.state.selectedPhoneNumber && this.state.selectedPhoneNumber.length > 1) {
      return this.bulkEditNumbers(data);
    }

    const reqData = {
      reqAction: 'phonenumbers',
      reqObject: encodeURIComponent(data.phonenumber),
      reqBody: data,
    };

    const storeKey = 'editphonenumbers';
    this.props.putCoreHttp(reqData, storeKey);
  }

  onCancel = () => {
    if (this.state.route === 'New') {
      this.setState({ route: null, newChanges: false });
      this.props.selectPhoneNumber('');
      return;
    }
    this.setState({ route: this.props.selectedPhoneNumber, submitButtonDisabled: true, newChanges: false });
  }

  componentDidUpdate() {

    if (this.props.routeParams && this.props.routeParams.number && this.state.route === null) {
      this.leftBarSelect(this.props.routeParams.number);
      return;
    }

    if (this.props.reloadDetails === 'fetch') {
      this.props.resetReloadDetails('store');

      const reqData = {
        reqAction: 'phonenumbers',
        reqObject: encodeURIComponent(this.state.selectedPhoneNumber)
      }

      const storeKey = 'phonenumbers';
      this.props.getCoreHttp(reqData, storeKey);
    }

    //If we just recieved props, we need to update this.favorites
    if (this.props && this.props.phoneNumberData) {
      let newFavorites = this.props.phoneNumberData.data.filter(phonenumber => phonenumber.favorite === "1");
      this.favorites = [];
      newFavorites.forEach(favorite => {
        this.favorites.push(favorite.phonenumber)
      })
    }

    if (this.props.phoneNumberData && !this.storedFetchedData) {
      this.storedFetchedData = true;
      this.prepareDataForLeftBar(this.props.phoneNumberData.data);
    }

    if (this.props.addedNewNumber) {
      const reqData = {
        reqAction: 'phonenumbers',
      };
      this.props.getCoreHttp(reqData);
    }

    if (this.props.editedNumber || this.props.deleted) {
      this.storedFetchedData = false;
      this.props.resetFlag();
    }

    if (this.props.notRemovedNumbers && this.props.notRemovedNumbers.length > 0
      && this.state.timesShowedDeletedNumberModal == 0
      && this.state.showingNotDeletedNumberModal == false) {
      this.setState({
        showingNotDeletedNumberModal: true,
        timesShowedDeletedNumberModal: { ...this.state.timesShowedDeletedNumberModal++ },
      });
    }

    if (!this.props.leftBarDataPrepared && this.props.phoneNumberData) {
      this.prepareDataForLeftBar(this.props.phoneNumberData.data);
    }

    if (this.props.successflag) {
      this.props.resetFlag();
      successNotification({ title: 'Success!', message: this.props.successflag })
    }
    if (this.props.failureflag) {
      this.props.resetFlag();
      errorNotification({ title: 'Error!', time: 20, message: this.props.failureflag })
    }
  }

  navigate = (route) => {
    this.setState({ route: route })
  }

  shouldShow911 = () => {
    return window.App.TenantObjects.get('adminHelper').get('room_specific_e911');
  }

  getPhoneNumberRoutes = () => {
    return ([
      <ActionCard
        key="Routing"
        title="Routing"
        subtitle="Manage routes from Phone Numbers"
        action={() => this.navigate('Routing')}
      />,
      <ActionCard
        key="Fax"
        title="Fax"
        subtitle="Fax settings"
        action={() => this.navigate('Fax')}
      />,
      <ActionCard
        key="Advanced"
        title="Advanced"
        subtitle="Advanced settings"
        action={() => this.navigate('Advanced')}
      />,
      this.shouldShow911() === '1'
        ? <ActionCard
          key="e911"
          title="E911"
          subtitle="E911 settings"
          action={() => this.navigate('E911')}
        />
        : null
      ,
      <ActionCard
        key="History"
        title="History"
        subtitle="See who made changes to this Phone Number"
        action={() => this.navigate('History')}
      />,
    ])
  }

  prepareDataForLeftBar(data) {
    this.favorites = [];
    if (!data) {
      return console.log('No phone number returned');
    }
    const leftBarData = [];
    data.map((item) => {
      let favorite = null;
      let tag = null;
      const title = formatNumber(item.phonenumber);
      let subtitle = 'Not assigned';
      let porting = false;
      if (item.assigned !== '0') {
        if (item.assigneduser) {
          subtitle = item.assigneduser;
          tag = 'user';
        }
        else if (item.assignedroute) {
          subtitle = item.assignedroute;
          tag = 'route';
        }
        else if (item.assignedmanaged) {
          subtitle = item.assignedmanaged;
          tag = 'managed';
        }
        else if (item.assignedconference) {
          subtitle = item.assignedconference;
          tag = 'conference';
        }
        else if (item.assignedconference === '' && item.assigned === '1' && item.assignedcallforwarding === '0') {
          subtitle = 'No Room';
          tag = 'conference';
        }
        else if (
          item.assignedcallforwarding === '1' ||
          item.assignedcallforwarding === '2' ||
          item.assignedcallforwarding === '3' ||
          item.assignedcallforwarding === '4'
        ) {
          subtitle = 'Call Forwarding';
          tag = 'callforwarding';
        }
      }
      if (item.favorite) {
        if (item.favorite === '0')
          favorite = false;
        else {
          this.favorites.push(item.phonenumber);
          favorite = true;
        }
      }

      if (item && item.porting_in_progress == "1") {
        porting = true;
      }

      const id = item.phonenumber;
      return leftBarData.push({ id, title, subtitle, tag, favorite, porting });
    });

    this.setState({ leftBarData: null });
    this.props.resetLeftBarFlag();
    return this.setState({ leftBarData });
  }

  renderContent() {
    const { route, selectedPhoneNumber } = this.state;

    if (!this.state || !selectedPhoneNumber) {
      return <Loading />
    }

    switch (route) {
      case null:
        return <PhoneNoHelp />;
      case 'New':
        return <ExternalService module="phone_number_order" fallback={<BuyNewNumber onCancel={this.onCancel} />} />
      case 'History':
        return <History />;
      case 'Fax':
        return <FaxForm selectedPhoneNumber={selectedPhoneNumber} onCancel={this.onCancel} disableButton={this.disableButton} buttonDisabled={this.state.submitButtonDisabled} updateAbbrevForm={(field, data) => this.setState({ abbrevForm: { [field]: data } })} checkSubmit={this.checkSubmit} clickedNode={() => this.clickedNode()} madeChanges={(answer, data) => this.madeChanges(answer, data)} />;
      case 'Advanced':
        return <AdvancedSettings selectedPhoneNumber={selectedPhoneNumber} onCancel={this.onCancel} disableButton={this.disableButton} buttonDisabled={this.state.submitButtonDisabled} updateAbbrevForm={(field, data) => this.setState({ abbrevForm: { [field]: data } })} checkSubmit={this.checkSubmit} clickedNode={() => this.clickedNode()} madeChanges={(answer, data) => this.madeChanges(answer, data)} />;
      case 'Routing':
        return <RoutingSetting selectedPhoneNumber={selectedPhoneNumber} onCancel={this.onCancel} disableButton={this.disableButton} buttonDisabled={this.state.submitButtonDisabled} updateAbbrevForm={(field, data) => this.setState({ abbrevForm: { [field]: data } })} checkSubmit={this.checkSubmit} clickedNode={() => this.clickedNode()} madeChanges={(answer, data) => this.madeChanges(answer, data)} />;
      case 'E911':
        return <E911 selectedPhoneNumber={selectedPhoneNumber} onCancel={this.onCancel} disableButton={this.disableButton} buttonDisabled={this.state.submitButtonDisabled} updateAbbrevForm={(field, data) => this.setState({ abbrevForm: { [field]: data } })} checkSubmit={this.checkSubmit} clickedNode={() => this.clickedNode()} madeChanges={(answer, data) => this.madeChanges(answer, data)} />;
      default:
        return <StandardDetailsPage cards={this.getPhoneNumberRoutes()} />
    }
  }

  handleAddNewClick = () => {
    if (!this.state.submitButtonDisabled) {
      this.setState({ showUnsavedChangesModal: 'New' });
      return;
    }
    this.props.selectPhoneNumber('New Phone Number');
    app.navigate(`phonenumbers/new`, { trigger: true });
  }

  getPhoneNumberDetails = (phoneNumber) => {
    const reqData = {
      reqAction: 'phonenumbers',
      reqObject: encodeURIComponent(phoneNumber)
    }

    const storeKey = 'phonenumbers';
    this.props.getCoreHttp(reqData, storeKey);
  }

  getPhoneNumberHistory = (phoneNumber) => {
    const reqData = {
      auditHistory: true,
      reqAction: 'phonenumbers',
      reqObject: encodeURIComponent(phoneNumber),
    };

    const storeKey = 'phonenumbers';

    this.props.getCoreHttp(reqData, storeKey);
  }

  leftBarSelect = (phoneNumber, e) => {
    if (phoneNumber === 'New' || phoneNumber === 'new') {
      this.props.selectPhoneNumber('New Phone Number');
      this.navigate('New');
      return;
    }

    let newPhoneNumbers = []


    if (this.state.selectedPhoneNumber.includes(phoneNumber) && e && e.shiftKey) {
      let i = this.state.selectedPhoneNumber.findIndex((item) => item === phoneNumber);
      newPhoneNumbers = [...this.state.selectedPhoneNumber.slice(0, i), ...this.state.selectedPhoneNumber.slice(i + 1, this.state.selectedPhoneNumber.length)]
    } else if (e && e.shiftKey) {
      newPhoneNumbers.unshift(...this.state.selectedPhoneNumber);
      newPhoneNumbers.unshift(phoneNumber);
    } else {
      newPhoneNumbers.unshift(phoneNumber);
    }

    // A phone number can have the + in the string. This character needs to be
    // encoded before putting it into the URL.
    const encodedNumber = encodeURIComponent(phoneNumber);
    app.navigate(`phonenumbers/${encodedNumber}`)
    this.props.resetLoading();
    this.setState({ route: phoneNumber, selectedPhoneNumber: newPhoneNumbers });

    this.props.selectPhoneNumber(newPhoneNumbers);
    this.getPhoneNumberDetails(phoneNumber);
    this.getPhoneNumberHistory(phoneNumber);
  }

  handleDeleteConfirmClose = (shouldDelete) => {
    shouldDelete && this.handledeletePhoneNo();
    this.setState({ showingDeleteConfirmModal: false });
  }

  handleSetFlagToNotPorting = (portingNumbers) => {

    this.setState({ showingClearPortingConfirmModal: false });

    const reqData = {
      reqAction: 'clearallportingflags',
      reqObject: app.token.get("tenant"),
      reqBody: portingNumbers,
    };

    const storeKey = 'clearallportingflags';
    this.props.putCoreHttp(reqData, storeKey);

  }

  handleDisassociateNumbers() {
    app.navigate(`phonenumbers`, { trigger: true });

    this.setState({ showingDisassociationModal: false });

    const reqData = {
      reqAction: 'disassociatenumbers',
      reqObject: app.token.get("tenant"),
    };

    const storeKey = 'disassociatenumbers';
    this.props.putCoreHttp(reqData, storeKey);
  }

  handledeletePhoneNo() {
    if (!this.state.selectedPhoneNumber) {
      console.log('No phone number selected');
      return;
    }

    const name = encodeURIComponent(this.state.selectedPhoneNumber);
    const reqData = {
      reqAction: 'phonenumbers',
      reqObject: name,
    };

    const storeKey = 'deletephonenumbers';

    this.props.deleteCoreHttp(reqData, storeKey);

    this.setState({
      route: null,
      selectedPhoneNumber: null,
    });
    this.props.selectPhoneNumber('');
    app.navigate(`phonenumbers`, { trigger: true });
  }

  componentDidMount() {
    this.clickedElement();
    this.fetchLocations();
    if (!this.props.phoneNumberData || this.props.tenant !== app.token.attributes.tenant) {
      this.props.setTenant(app.token.attributes.tenant);
      const reqData = {
        reqAction: 'phonenumbers',
      };
      this.props.getCoreHttp(reqData);
    }
    else {
      this.setState({ leftBarData: this.prepareDataForLeftBar(this.props.phoneNumberData.data) })
    }
  }

  clickedElement = () => {
    var elements = document.querySelectorAll(".header, .leftBarDiv, .detailContainerDiv");
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", () => {
        this.unsavedChanges();
      });
    }
  }

  unsavedChanges = () => {
    const { clickedInsideElements, newChanges } = this.state
    if (clickedInsideElements && newChanges) {
      this.openModal()
    }
  }

  openModal = () => {
    this.setState({ showUnsavedChangesModal: true });
  }

  clickedNode = () => {
    this.setState({ clickedInsideElements: true });
  }

  madeChanges = (answer, data) => {
    data.selectedPhoneNumber = this.state.selectedPhoneNumber;
    this.setState({
      newChanges: answer,
      childFormData: data,
    });
  }

  goToHelp = () => {
    const win = window.open('http://help.fluentcloud.com/support/solutions', '_blank');
    win.focus();
  }

  openCloseMenu = () => {
    this.setState({ closeMenu: !this.state.closeMenu });
  }

  filterPhoneNumbersByAssignment = () => {
    let filteredLeftBar = [];
    if (this.props.phoneNumberData && this.props.phoneNumberData.data) {
      this.props.phoneNumberData.data.forEach((phoneNumber) => {
        let tag = null;
        let subtitle = 'Not assigned';

        if (phoneNumber.assigned !== '0') {
          if (phoneNumber.assigneduser) {
            subtitle = phoneNumber.assigneduser;
            tag = 'user';
          }
          else if (phoneNumber.assignedroute) {
            subtitle = phoneNumber.assignedroute;
            tag = 'route';
          }
          else if (phoneNumber.assignedmanaged) {
            subtitle = phoneNumber.assignedmanaged;
            tag = 'managed';
          }
          else if (phoneNumber.assignedconference) {
            subtitle = phoneNumber.assignedconference;
            tag = 'conference';
          }
          else if (phoneNumber.assignedconference === '' && phoneNumber.assigned === '1' && phoneNumber.assignedcallforwarding === '0') {
            subtitle = 'No Room';
            tag = 'conference';
          }
          else if (
            phoneNumber.assignedcallforwarding === '1' ||
            phoneNumber.assignedcallforwarding === '2' ||
            phoneNumber.assignedcallforwarding === '3' ||
            phoneNumber.assignedcallforwarding === '4'
          ) {
            subtitle = 'Call Forwarding';
            tag = 'callforwarding';
          }
        }

        let newPhoneNumber = {
          favorite: phoneNumber.favorite === "1" ? true : false,
          id: phoneNumber.phonenumber,
          title: formatNumber(phoneNumber.phonenumber),
          subtitle: subtitle,
          tag: tag,
          porting: phoneNumber.porting_in_progress === "1" ? true : false,
          temporary: phoneNumber.temporary === "1" ? true : false,
        }
        if (this.state.filterPhoneNumbers && phoneNumber.assigned === "1") { return }
        filteredLeftBar.push(newPhoneNumber)
      })

      return filteredLeftBar;
    } else {
      return;
    }
  }

  checkForPortingNumbers = () => {
    if (!this.props || !this.props.phoneNumberData) {
      return false;
    }

    let portingNumbers = [];
    this.props.phoneNumberData.data.forEach(number => {
      if (number.porting_in_progress === "1") {
        portingNumbers.push(number.phonenumber)
      }
    });

    if (portingNumbers.length === 0) {
      return false;
    } else {
      return portingNumbers;
    }
  }

  checkForTemporaryNumbers() {
    if (!this.props || !this.props.phoneNumberData) {
      return false;
    }

    let temporaryNumbers = [];
    this.props.phoneNumberData.data.forEach(number => {
      if (number.temporary === "1") {
        temporaryNumbers.push(number.phonenumber)
      }
    });

    if (temporaryNumbers.length === 0) {
      return false;
    } else {
      return temporaryNumbers;
    }
  }

  render() {

    let filteredLeftBarData = this.filterPhoneNumbersByAssignment();
    let portingNumbers = this.checkForPortingNumbers();
    let temporaryNumbers = this.checkForTemporaryNumbers();

    const re = /[0-9]{10}/;
    const { route, leftBarData, selectedPhoneNumber } = this.state;
    const { classes } = this.props;
    const role = app.token.get("role");

    const contextMenuOptions = [
      <MenuItem
        disabled={selectedPhoneNumber.length === 0}
        onClick={() => { this.setState({ showingDeleteConfirmModal: true }); this.openCloseMenu(); }}
        key={0}
      > Delete </MenuItem>,
      <MenuItem
        onClick={() => { this.goToHelp(); this.openCloseMenu(); }}
        classes={{ root: classes.menuItem }}
        key={1}
      >Help</MenuItem>,
      <MenuItem
        disabled={!selectedPhoneNumber || selectedPhoneNumber.length !== 1}
        onClick={() => { this.setState({ showTimeMachineModal: true }); this.openCloseMenu(); }}
        classes={{ root: classes.menuItem }}
        key={2}
      >Time machine</MenuItem>,

    ];

    if (role === "superduperadmin" || role === "partneradmin") {
      contextMenuOptions.push(
        <div>
          <MenuItem
            disabled={portingNumbers === false ? true : false}
            onClick={() => { this.setState({ showingClearPortingConfirmModal: true }); this.openCloseMenu(); }}
            classes={{ root: classes.menuItem }}
            key={3}
          >Remove all Porting Flags</MenuItem>
          <MenuItem
            disabled={temporaryNumbers === false ? true : false}
            onClick={() => {
              this.setState({ showingDisassociationModal: true });
              this.openCloseMenu();
            }}
            classes={{ root: classes.menuItem }}
            key={4}
          >Disassociate temporary numbers</MenuItem>
        </div>
      )
    };

    let actions = [];
    let found = null;
    let deletionTitle = '';
    let deletionErrorText = '';
    let url = '';
    if (this.props.locations && this.props.locations.length) {
      this.props.locations.map(location => {
        if (location.did == this.state.selectedPhoneNumber) {
          found = location.name;
          return;
        }

      });
    }
    if (found) {
      url = `#locations/${found}`;
      if (window.BASE_URL) {
        url = `${window.BASE_URL}#locations/${found}`;
      }
      deletionTitle = "Cannot Delete This Number:"
      deletionErrorText = `The number ${this.state.selectedPhoneNumber} is registered for 911 connectivity on your ${found} location. Please register a different phone number for emergency dialing purposes on the location, `;
      actions = [
        <Button
          label="OK"
          primary
          onClick={() => this.handleDeleteConfirmClose(false)}
        />
      ];
    } else {
      deletionTitle = "Are You Sure?"
      deletionErrorText = '';
      actions = [
        <Button
          label="Cancel"
          primary
          onClick={() => this.handleDeleteConfirmClose(false)}
        />,
        <Button
          label="Delete"
          primary
          onClick={() => this.handleDeleteConfirmClose(true)}
        />,
      ];
    }

    const starToRender = this.state.selectedPhoneNumber && this.favorites.includes(this.state.selectedPhoneNumber[0])
      ? <StarIcon />
      : <BlankStarIcon />;

    let utilityComponenets = [];

    utilityComponenets.push(
      <ToggleInput
        style={{
          paddingLeft: 50,
          paddingRight: 50,
          paddingBottom: 0,
          paddingTop: 30,
        }}
        field="filterPhoneNumbers"
        placeholder="Unassigned Only"
        value={this.state.filterPhoneNumbers}
        onChange={(value) => {
          this.setState({ filterPhoneNumbers: value })
        }}
      />
    );

    //If we weren't able to delete all the phone numbers from their respective
    //carriers, let's show which ones we were unable to delete 
    let notDeletedNumbers = [];
    if (this.props.notRemovedNumbers && this.props.notRemovedNumbers.length > 0) {
      this.props.notRemovedNumbers.forEach((item) => {
        if (item && item.length === 10 && item !== 'Restricted') {
          let cleaned = ('' + item).replace(/\D/g, '');
          let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
          if (match) {
            item = '(' + match[1] + ') ' + match[2] + '-' + match[3];
          }
          notDeletedNumbers.push(item);
        } else {
          notDeletedNumbers.push(item);
        }
      });
    };

    notDeletedNumbers = notDeletedNumbers.map((number) =>
      <li key={number}>
        {number}
      </li>
    );

    let titleBar = [];
    selectedPhoneNumber && selectedPhoneNumber.forEach((item) => {
      titleBar.push(`${formatNumber(item)}`)
    })

    return (
      <MasterView>
        <TimeMachine show={this.state.showTimeMachineModal} onModalClose={this.onModalClose} />
        <SaveChangesModal
          show={this.state.showUnsavedChangesModal}
          discardChange={this.discardChange}
          saveChange={this.saveChange}
          cancelModal={this.cancelModal}
        />
        <PurchaseSuccess onModalClose={this.onModalClose} />
        <DetailContainer
          leftBar={
            <LeftBar
              utilityComponents={utilityComponenets}
              addNew={() => this.handleAddNewClick()}
              data={filteredLeftBarData}
              pending={this.props.pending || !this.state.leftBarData}
              selected={selectedPhoneNumber}
              select={(id, e) => this.leftBarSelect(id, e)}
              title="Phone Numbers"
              phoneNumber={true}
            />
          }
          title={this.state.route === 'New' ? 'New Phone Number' : titleBar.toString()}
          route={route !== 'New' && route !== this.state.selectPhoneNumber && !re.test(route) ? route : null}
          return={() => this.select(this.state.selectedPhoneNumber)}
          contextMenuOptions={contextMenuOptions}
          closeMenu={this.state.closeMenu}
          buttons={this.state.selectedPhoneNumber.length > 0
            ? <>
              <CopyToClipboard text={this.state.selectedPhoneNumber} >
                <Button onClick={() => successNotification({ title: 'Success!', message: 'Copied number to clipboard' })}
                  classes={{ root: classes.buttonStyle }}><CopyIcon /></Button>
              </CopyToClipboard>
              <Button classes={{ root: classes.buttonStyle }} onClick={this.editFavorite}>{starToRender}</Button>
            </>
            : null
          }
        >
          {this.renderContent()}
        </DetailContainer>
        <Dialog
          open={this.state.showingDeleteConfirmModal}
          onClose={() => this.handleDeleteConfirmClose(false)}
          fullWidth={true}
        >
          {deletionErrorText}
          {deletionErrorText && <a href={url}>here.</a>}
          {!deletionErrorText && <DialogTitle>Are you sure?</DialogTitle>}
          {!deletionErrorText && <DialogActions>
            <Button
              classes={{ root: classes.button }}
              color='primary'
              onClick={() => this.handleDeleteConfirmClose(false)}
            >Cancel</Button>
            <Button
              classes={{ root: classes.button }}
              color='primary'
              onClick={() => this.handleDeleteConfirmClose(true)}
            >Delete</Button>
          </DialogActions>}
        </Dialog>
        <Dialog
          open={this.state.showingClearPortingConfirmModal}
          onClose={() => this.handleDeleteConfirmClose(false)}
          fullWidth={true}
        >
          <DialogTitle>Are you sure you want to remove the porting flag from all numbers?</DialogTitle>
          <DialogActions>
            <Button
              classes={{ root: classes.button }}
              color='primary'
              onClick={() => this.setState({ showingClearPortingConfirmModal: false })}
            >No</Button>
            <Button
              classes={{ root: classes.button }}
              color='primary'
              onClick={() => this.handleSetFlagToNotPorting(portingNumbers)}
            >Yes
            </Button>
          </DialogActions>
        </Dialog>

        {/* Disassociated all temporary numbers */}
        <Dialog
          open={this.state.showingDisassociationModal}
          onClose={() => this.handleDeleteConfirmClose(false)}
          fullWidth={true}
        >
          <DialogTitle>Are you sure you want to disassociate all temporary numbers from this client?</DialogTitle>
          <DialogActions>
            <Button
              classes={{ root: classes.button }}
              color='primary'
              onClick={() => this.setState({ showingDisassociationModal: false })}
            >No</Button>
            <Button
              classes={{ root: classes.button }}
              color='primary'
              onClick={() => this.handleDisassociateNumbers()}
            >Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* Not able to delete some numbers modal */}
        <Dialog
          open={this.state.showingNotDeletedNumberModal}
          onClick={() => this.setState({ showingNotDeletedNumberModal: false })}
          fullWidth={true}
        >
          <DialogTitle>We were not able to delete all phone numbers from our carriers. They are listed here:</DialogTitle>
          <ol>{notDeletedNumbers}</ol>
          <DialogActions>
            <Button
              classes={{ root: classes.button }}
              color='primary'
              onClick={() => this.setState({ showingNotDeletedNumberModal: false })}
            >Ok</Button>
          </DialogActions>
        </Dialog>
      </MasterView>
    )
  }
}

const bindActions = (dispatch) => ({
  deleteCoreHttp: (reqData, storeKey) => dispatch(deleteCoreHttp(reqData, storeKey)),
  getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
  postCoreHttp: (reqData, storeKey) => dispatch(postCoreHttp(reqData, storeKey)),
  putCoreHttp: (reqData, storeKey) => dispatch(putCoreHttp(reqData, storeKey)),
  selectPhoneNumber: (data) => dispatch(selectPhoneNumber(data)),
  resetFlag: () => dispatch(resetFlag()),
  resetCreatedRoom: () => dispatch(resetCreatedRoom()),
  resetLeftBarFlag: () => dispatch(resetLeftBarFlag()),
  resetLoading: () => dispatch(resetLoading()),
  resetReloadDetails: (data) => dispatch(resetReloadDetails(data)),
  setTenant: (data) => dispatch(setTenant(data)),
  resetLocation: () => dispatch(resetLocation()),
});

const mapStateToProps = state => ({
  notRemovedNumbers: state.phoneNumbers.notRemovedNumbers,
  phoneNumberData: state.phoneNumbers.phoneNumberData,
  pending: state.phoneNumbers.pending,
  availableNumbers: state.phoneNumbers.availableNumbers,
  addedNewNumber: state.phoneNumbers.addedNewNumber,
  leftBarDataPrepared: state.phoneNumbers.leftBarDataPrepared,
  phoneNumberDetails: state.phoneNumbers.phoneNumberDetails,
  successflag: state.phoneNumbers.successflag,
  failureflag: state.phoneNumbers.failureflag,
  reloadDetails: state.phoneNumbers.reloadDetails,
  selectedPhoneNumber: state.phoneNumbers.selectedPhoneNumber,
  routeParams: state.navigation.params,
  editedNumber: state.phoneNumbers.editedNumber,
  deleted: state.phoneNumbers.deleted,
  tenant: state.phoneNumbers.tenant,
  locations: state.locations.data,
})

export default connect(mapStateToProps, bindActions)(withStyles(styles)(PhoneNumbers));
