import React from 'react';
import { connect } from 'react-redux';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

import CommunicationChatBubble from '@material-ui/icons/ChatBubble';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { selectConversation, showingNewNumberInput } from 'actions/sms';
import { formatNumber, stripNumber } from 'utils/phoneNumbers';
import { ListItemIcon } from '@material-ui/core';

export const ContactRow = (props) => {

    // If we are passed in a contact, let's return the ListItem for the contact
    if (props.isContact) {

        // Contact Name
        const displayText = `${props.contact.names[0].givenName} ${props.contact.names[0].familyName}`;

        // Contact avatar -- default is person icon
        let leftAvatar = <AccountCircleIcon style={{ height: 40, width: 40 }} />;
        if (props.contact.photos) {
            leftAvatar = <Avatar src={props.contact.photos[0]} />;
        }

        return (
          <ListItem
            onClick={() => {
                props.showingNewNumberInput(false);
                let match = false;
                if (props.contact.phoneNumbers && props.contact.phoneNumbers.length > 0) {
                    // Lets see if there already is a conversation for this user
                    match = props.conversations.data.find((convo) =>
                      convo.participant == props.contact.phoneNumbers[0].value.replace(/[^0-9]/g, ''),
                    );
                }

                return props.selectConversation(
                    match || {
                        messages: [],
                        participant: props.contact.phoneNumbers[0].value,
                        number: props.selectedInternalDid,
                    },
                    leftAvatar,
                    displayText,
                );
            }}
          >
            <ListItemAvatar><Avatar>{leftAvatar}</Avatar></ListItemAvatar>
            <ListItemText primary={displayText} secondary={props.contact.phoneNumbers && props.contact.phoneNumbers.length > 0 ?
                formatNumber(stripNumber(props.contact.phoneNumbers[0].value)) : 'No Number Found'} />
            <ListItemSecondaryAction>
                {props.rightIcon || <CommunicationChatBubble />}
            </ListItemSecondaryAction>
          </ListItem>
        );
    }

    // Lets display the formatted number and account-circle icon by default
    let displayText = formatNumber(props.number);
    let leftAvatar = <AccountCircleIcon style={{ height: 40, width: 40 }} />;

    if(props.matchContact){
        displayText = (
            <span style={{ overflow: 'visible', display: 'flex', flexDirection: 'column', marginBottom: 1 }}>
              <span>{props.number}</span>
              <span style={{ fontSize: 12, color: '#373737', marginTop: 1 }}>{props.numberDisplay}</span>
            </span>
          );
    }

    // By default, secondaryText is props.text
    let secondaryText = props.text;
    if (props.secondaryText) {
        // If we have secondary text passed in, we will append this to the props.text
        secondaryText = (
          <span style={{ overflow: 'visible', display: 'flex', flexDirection: 'column', marginBottom: 12 }}>
            <span>{props.text}</span>
            <span style={{ fontSize: 12, color: '#373737', marginTop: 3 }}>{props.secondaryText}</span>
          </span>
        );
    }

    // Return a list item with pased in primary/secondary text
    return (
      <ListItem
        style={props.selected ? { backgroundColor: '#DFDFDF' } : null}
        onClick={() => {
            props.selectRow(leftAvatar, displayText)
            props.showingNewNumberInput(false)
            }
        }
      >
        <ListItemAvatar><Avatar>{leftAvatar}</Avatar></ListItemAvatar>
        <ListItemText primary={displayText} secondary={secondaryText} />
        <ListItemSecondaryAction>
          {props.rightIcon || <CommunicationChatBubble />}
        </ListItemSecondaryAction>
      </ListItem>
    );
};

const mapStateToProps = (state) => ({
    contacts: state.http.contacts2,
    selectedInternalDid: state.sms ? state.sms.selectedInternalDid : null,
    conversations: state.http.smsData,
});

const bindActions = (dispatch) => ({
    selectConversation: (conversation, avatar, contactInfo) =>
        dispatch(selectConversation(conversation, avatar, contactInfo)),
    showingNewNumberInput: (showNewNumberInput) => dispatch(showingNewNumberInput(showNewNumberInput)),
});

export default connect(mapStateToProps, bindActions)(ContactRow);
