import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

const VerifyDialog = (props) => {
    const classes = makeStyles({
      button: {
        fontSize: '100%',
      },
    })();
    return (
      <Dialog
        onClose={() => props.declined()}
        open
      >
        <DialogContent>{props.text}</DialogContent>
        <DialogActions>
          <Button
            classes={{ root: classes.button }}
            color='primary'
            onClick={() => props.declined()}
          >Cancel</Button>,
          <Button
            classes={{ root: classes.button }}
            color='primary'
            onClick={() => {
              props.accepted();
            }}
          >{props.confirmText}</Button>
        </DialogActions>
      </Dialog>
    );
};

VerifyDialog.defaultProps = {
    accepted: () => console.log('No accepted function passed into verify dialog'),
    declined: () => console.log('No declined function passed into verify dialog'),
    confirmText: 'Delete',
    text: 'Are you sure?',
};

VerifyDialog.propTypes = {
    accepted: PropTypes.func,
    declined: PropTypes.func,
    confirmText: PropTypes.string,
    text: PropTypes.string,
};

export default VerifyDialog;