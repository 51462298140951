import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class RadioInput extends Component {
  constructor(props){
    super(props);

    this.state = {
      selectedValue: ""
    }
    this.storeValue = this.storeValue.bind(this);
  }

  storeValue(e){
    this.setState({selectedValue: e.target.value}, () => this.props.onChange(this.state.selectedValue))
  }

  render() {
    const helpSection = (
      <div>
        <div>
          <p>
            * : Playback Menu Options<br />
            1 : Toggle Mute<br />
            2 : Lock/Unlock the room **<br />
            3 : Kick the last person that joined **<br />
            4 : Decrease Listening Volume<br />
            6 : Increase Listening Volume<br />
            7 : Decrease Talking Volume<br />
            9 : Increase Talking Volume<br />
            8 : Leave Conference<br /><br />
            ** Admin Only
            </p>
        </div>
      </div>
    );
    const helpSectionToShow = this.props.field === 'disable_mute'
      ? helpSection
      : null;
    const { disabledOptions } = this.props;
    const options = this.props.options.map((item, position) => {
      return(
        <FormControlLabel
          key={this.props.values[position]}
          value={this.props.values[position]}
          control={<Radio color='primary'/>}
          label={item}
          disabled={disabledOptions.length ? disabledOptions[position] : false}
        />
      )
    });
    return (
      <div style={{ margin: '15px' }} onBlur={() => this.props.onBlur(this.state.selectedValue)}>
        <RadioGroup row={!this.props.column} name={this.props.value} onChange={this.storeValue} style={{ display: 'flex' }} value={this.props.value}>
          {options}
        </RadioGroup>
        {helpSectionToShow}
      </div>
    )
  }
}

RadioInput.propTypes = {
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  field: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabledOptions: PropTypes.array,
  column: PropTypes.bool,
};

export default RadioInput;
