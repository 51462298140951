import React, { Component } from 'react';
import { makeStyles } from '@material-ui/styles';

import SkillsCardRenderer from './skillCardRenderer';


class SkillsCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showingModal: false,
            mediaQuery: false,
            form: props.form,
            initialForm: {},
        };
    }

    componentDidMount() {

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
    }

    handleClose(submit) {

        const { index } = this.props;

        if (submit) {
            const form = Object.assign([], this.props.form);
            form[index].level = this.state.form[index].level;
            this.props.submitForm(form);
            return this.setState({ showingModal: false });
        }

        const { form } = this.props;

        form[this.props.index] = this.state.initialForm;
        this.setState({ showingModal: false, form });
    }

    updateForm(field, value) {

        const { form } = this.state;

        form[this.props.index][field] = value;
        this.setState({ form });
    }

    getQueueName(queueNameWithTenant) {

        // Splits name into array by '-' (test-queue-FluentStream => ['test', 'queue', 'FluentStream'])
        // then removes the last one (tenant name) => ['test', 'queue']
        // then rejoins the array with '-' => 'test-queue'
        const nameArray = queueNameWithTenant.split('-');
        nameArray.splice(-1);
        return nameArray.join('-')
    }

    render() {

        const minWidth = this.state.mediaQuery.matches;

        const useStyle = makeStyles({
            card: {
                width: minWidth ? '50%' : '100%',
                minWidth: '400px',
            },
            editButton: {

            },
            deleteButton: {
                color: 'red',
            },
            modal: {
                width: minWidth ? '500px' : '95%',
            },
            button: {
                fontSize: '100%',
            },
        });

        return (
          <SkillsCardRenderer parent={this} useStyle={useStyle}/>
        );
    }
}

export default SkillsCard;
