import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getSmsHttp, getUserPortalHttp } from 'actions/httpRequest';
import {
    cancelMessagesToBeDeleted,
    newSmsMessage,
    newInternalDidSelected,
    newOutgoingSmsPosted,
    selectConversation,
    updatePageNumber
        } from 'actions/sms';

import SmsConversationView from './smsConversationView';
import SmsConversationsList from './smsConversationsList';
import SmsNumberDrawer from './smsNumberDrawer';

import MasterView from 'presentational/MasterView';

class Sms extends Component {

    componentDidMount() {

        if(this.dispatcher){
            this.dispatcher.disconnect();
            delete this.dispatcher;
        }
            let options = this.setupLiveOptions();

            this.dispatcher = new SmsDispatcher(options);
            this.dispatcher.run();

        // If we don't already have contacts or the extension changed, let's fetch them
        if (!this.props.contacts || this.props.contacts.extension !== this.props.token.extension){
            this.fetchContacts();
        }

        if (this.props.selectedInternalDid) {
            // Fetch initial selected did sms data
            this.fetchSmsData(this.props.selectedInternalDid);
            return this.props.newInternalDidSelected(this.props.selectedInternalDid);
        } 
    }

    componentDidUpdate(previousProps, _previousState) {
        const { userdids, primarydid } = this.props.token;

        // If user has a primary sms did on their account, let's use that
        if (previousProps.dids.phonenumbers && previousProps.dids.phonenumbers.length > 0 && !previousProps.selectedInternalDid) {
            let selectedNumber = previousProps.dids.phonenumbers.find(number => number.phonenumber === primarydid && number.smsenabled == '1');
            if (selectedNumber) {
                this.fetchSmsData(primarydid);
                return this.props.newInternalDidSelected(primarydid);
            }

            // If not, let's use the first sms enabled number on their account
            let selectedDid = '';
            if (userdids && userdids.length > 0) {
                selectedDid = userdids.find((did) =>
                    did.smsenabled == '1',
                );
                selectedDid = selectedDid && selectedDid.phonenumber ? selectedDid.phonenumber : '';
            }

            if (selectedDid && selectedDid.length > 0) {
                let matchedNumber = previousProps.dids.phonenumbers.find(number => number.phonenumber === selectedDid && number.smsenabled == '1');
                if (matchedNumber) {
                    this.fetchSmsData(selectedDid);
                    // Lets set the newDidForSms to the first number we find on their account
                    return this.props.newInternalDidSelected(selectedDid);
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        // Lets check if we have a new outgoing message that just posted
        if (nextProps.outgoingSmsPosted != this.props.outgoingSmsPosted
                && nextProps.outgoingSmsPosted
                && nextProps.selectedConversation
                && nextProps.selectedConversation.conversation
                && nextProps.selectedConversation.conversation.participant
            ) {

            // If we do, let's check to see if we are still on that conversation
            if (nextProps.selectedConversation.conversation.participant == nextProps.outgoingSmsPosted.participant) {

                // If we are, let's pass the updated messages to the conversation
                this.props.selectConversation(
                    nextProps.outgoingSmsPosted,
                    this.props.selectedConversation.avatar,
                    this.props.selectedConversation.contactInfo,
                );
            }

            // Lets update the entire conversations list
            // with the new conversation object
            this.props.newOutgoingSmsPosted(nextProps.outgoingSmsPosted);
        }


        // If we get a new number for sms's, let's get the new sms's for that number
        // Also, let's get the new websocket connection for the passed in number
        if (nextProps.selectedInternalDid &&
            nextProps.selectedInternalDid != this.props.selectedInternalDid) {

            //reset the page number back to one for the next conversation
            this.props.updatePageNumber(1);
            //scroll back to the top for the next conversation
            if (document.getElementById('conversationsListContainer')) {
                document.getElementById('conversationsListContainer').scrollTop = 0;
            }

            this.fetchSmsData(nextProps.selectedInternalDid);

        }
    }

    componentWillUnmount() {

        this.dispatcher && this.dispatcher.disconnect();
        delete this.dispatcher;

        this.props.cancelMessagesToBeDeleted();
    }

    setupLiveOptions(_numberToSubscribe = null) {

        return {
            tenant: this.props.token.tenant,
            eventScope: 'tenant',
            extension: this.props.token.extension,
            config: window.config,
            incomingMessageHandler: (message) => this.props.newSmsMessage(message),
            outgoingMessageHandler: (message) => this.props.newSmsMessage(message),
            room: `${this.props.token.tenant}`,
        };
    }

    // Fetches sms data
    fetchSmsData(number) {

        let page =  1

        // If a number is passed in, lets use that number, otherwise use the user's primary did
        const reqData = {
            reqAction: number || this.props.token.primarydid,
            page: page
        };

        const storeKey = 'smsData';

        this.props.getSmsHttp(reqData, storeKey, page);
    }

    // Fetches contacts
    fetchContacts() {

        const reqData = {
            reqAction: 'contacts2',
        };

        this.props.getUserPortalHttp(reqData, 'contacts');
    }

    render() {

        return (
          <MasterView>
            <SmsNumberDrawer />
            <SmsConversationsList page={this.props.page} />
            <SmsConversationView />
          </MasterView>
        );
    }
}

const mapStateToProps = (state) => ({
    contacts: state.contacts ? state.contacts.data : null,
    outgoingSmsPosted: state.http ? state.http.outgoingSmsPosted : null,
    selectedInternalDid: state.sms ? state.sms.selectedInternalDid : null,
    smsData: state.http ? state.http.smsData : [],
    token: state.token ? state.token : null,
    selectedConversation: state.sms ? state.sms.selectedConversation : null,
    page: state.sms ? state.sms.page : null,
    dids: state.http ? state.http.configurations : null,
});

const bindActions = (dispatch) => ({
    cancelMessagesToBeDeleted: () => dispatch(cancelMessagesToBeDeleted()),
    getSmsHttp: (reqData, storeKey, page) => dispatch(getSmsHttp(reqData, storeKey, page)),
    updatePageNumber: ( page) => dispatch(updatePageNumber(page)),
    getUserPortalHttp: (reqData, storeKey) => dispatch(getUserPortalHttp(reqData, storeKey)),
    newSmsMessage: (message) => dispatch(newSmsMessage(message)),
    newInternalDidSelected: (number) => dispatch(newInternalDidSelected(number)),
    newOutgoingSmsPosted: (conversation) => dispatch(newOutgoingSmsPosted(conversation)),
    selectConversation: (conversation, avatar, contactInfo) =>
        dispatch(selectConversation(conversation, avatar, contactInfo)),
});

export default connect(mapStateToProps, bindActions)(Sms);
