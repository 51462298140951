function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

function validateUserName(userName) {
    if (isBlank(userName)) {
        return "You must provide a user name.";
    }
    if (userName.length < 6) {
        return "Username must be 6 characters or longer.";
    }
    // Validate the username has only alphanumeric or '@', '_', '.', ','
    const exp = /^[a-zA-Z0-9@_.,+-]+$/;
    if (!exp.test(userName)) {
        return "Username can not have spaces and can only contain letters, numbers, '@', '+', '-', '_', '.', or ','";
    }
}

function validateEmail(email) {
    if (isBlank(email)) {
        return "You must provide an email address.";
    }
    const exp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!exp.test(email)) {
        return "You must provide a valid email address.";
    }
}

function validateUserGroup(groups, role) {
    let err = "You must assign the user to a User Group.";
    if (isBlank(groups)) {
        return err;
    }
    switch (groups) {
        case 'Administrator':
            break;
        case 'Cloud User':
            break;
        case 'Partner Admin':
            if (!(role === "superduperadmin" || role === "partneradmin")) {
                return err;
            }
            break;
        case 'superduperadmin':
        default:
            return err;
    }
}

function validateExtension(extension) {
    if (isBlank(extension)) {
        return "You must assign an extension to the User.";
    }
    const exp = /^[0-9]+$/;
    if (!exp.test(extension)) {
        return "Extension must contain only numeric characters.";
    }
}

function validateFirstLastName(firstName, lastName) {
    if (isBlank(firstName) || isBlank(lastName)) {
        return "You must provide a first and last name.";
    }
}

function validatePasswords(newPassword, confirmPassword) {
    if (isBlank(newPassword) || isBlank(confirmPassword)) {
        return "Please enter a valid password.";
    }
    if (newPassword !== confirmPassword) {
        return "New Password must match Confirm Password.";
    }
    if (newPassword.length < 8 || confirmPassword.length < 8) {
        return "Your password must be at least 8 characters long.";
    }
    if (!/[^a-zA-Z]/.test(newPassword)) {
        return "You need at least 1 special character or number in your password.";
    }
}

exports.isBlank = isBlank;
exports.validateEmail = validateEmail;
exports.validateExtension = validateExtension;
exports.validateFirstLastName = validateFirstLastName;
exports.validatePasswords = validatePasswords;
exports.validateUserGroup = validateUserGroup;
exports.validateUserName = validateUserName;
