import { SELECTED_NOTIFICATION_WORKFLOW_DATA, RESET_FLAG, RESET_CREATED_WORKFLOW} from '../actions/typeConstants';

const initialState = {
    notificationWorkflowData: {},
    pending: true,
    notificationWorkflowSelected: '',
    createdWorkflow: null,
    config: null,
}

import { parseError } from 'utils/misc';

export default function(state = initialState, action){
    switch(action.type){
        case 'EVENTNOTIFICATIONSETTINGS_HTTP_SUCCESS':
            return{ ...state, notificationWorkflowData: action.data.data, pending: false }
        case 'CREATENOTIFICATIONWORKFLOW_HTTP_SUCCESS':
            return{...state, createdWorkflow: action.data[action.data.length -1].id, failureFlag: null, successFlag: `Success! Created Notification Workflow.`,
                notificationWorkflowSelected: action.data.id, notificationWorkflowData: action.data}
        case 'CREATENOTIFICATIONWORKFLOW_HTTP_FAILURE':
            return {... state, successFlag: null, failureFlag: parseError(action.error.response.text) };
        case 'EDITNOTIFICATIONWORKFLOW_HTTP_SUCCESS':
            return {...state, notificationWorkflowData: action.data, successFlag: `Updated Notification Workflow`, failureFlag: null}
        case 'EDITNOTIFICATIONWORKFLOW_HTTP_FAILURE':
            return {...state, successFlag: null, failureFlag: parseError(action.error.response.text)}
        case SELECTED_NOTIFICATION_WORKFLOW_DATA:
            return{...state, notificationWorkflowSelected: action.payload}
        case RESET_CREATED_WORKFLOW:
            return {...state, createdWorkflow: null}
        case RESET_FLAG:
            return {...state, successFlag: null, failureFlag: null}
        default: 
            return { ...state };
    }
}
