import React, { Component } from 'react';
import { connect } from 'react-redux';

//Presentational Components
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import FormControlCard from 'presentational/FormControlCard';
import Footer from 'presentational/Footer';
import AddEmail from './addEmail';
import Loading from 'presentational/Loading';

//Material Components
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import LocationIcon from '@material-ui/icons/LocationOn';
import TimeIcon from '@material-ui/icons/AccessTime';
import EmailIcon from '@material-ui/icons/Email';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VolumeIcon from '@material-ui/icons/VolumeUp';
import DurationIcon from '@material-ui/icons/Timelapse';
import SendIcon from '@material-ui/icons/Send';
import Inbox from '@material-ui/icons/Inbox';
import WarningIcon from '@material-ui/icons/Warning';
import SkipIcon from '@material-ui/icons/SkipNext';
import ExitIcon from '@material-ui/icons/ExitToApp';
import Dialpad from '@material-ui/icons/Dialpad';
import EarIcon from '@material-ui/icons/Hearing';
import HideIcon from '@material-ui/icons/VisibilityOff';
import AZIcon from '@material-ui/icons/SortByAlpha';
import OpenLockIcon from '@material-ui/icons/LockOpen';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

//Requests
import { 
    getCoreHttp,
    putUserPortalHttp,
    getUserPortalHttp,
} from 'actions/httpRequest';

import {
    errorNotification,
    successNotification,
} from 'actions/notifications';

import { selectMailbox } from 'actions/voicemail';
import { translateApplication } from 'utils/translations';

class MailboxSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            voicemailBox:'',
            settings:{},
            newVMEmailFlag:false,
            form:{},
            newVMEmailForm:{},
            newForm:{password: `1234`},
            rollbackForm:{},
            newChanges:false,
            loading: true,
            mailbox: '',
            disabled:true,
            fetchedMailbox: false,
            location:[],
            timezones: 'Please select a country',
            disableExitApplications: true,
            showAddEmailModal: false,
            showDeleteEmailModal: false,
            editingVoicemails: false,
            notificationId: Math.round(Math.random() * 100),
        };
    }

    componentDidMount() {   
        //Set mailbox to url param if present, or token mailbox
        let mailbox;

        if(this.props.selectedVoicemailBox) {
            mailbox = this.props.selectedVoicemailBox;    
        } else {
            mailbox = app.token.get('mailbox');
        }

        this.setState({ mailbox: mailbox });

        this.props.settingsData && this.props.settingsData.exitapp === 'conferenceroom' && this.props.settingsData.exitname === "" ? this.props.settingsData.exitname = "No Room" : null
        // If we're in the admin portal, we need to get our data from props
        if (this.props.adminView){
            this.fetchVoicemailSettings(this.props.selectedVoicemailBox)
            this.setState({ 
                location: this.props.configurations.locations,
                loading: false
            })
        } else {
            //otherwise let's fetch it and add it later
            this.fetchVoicemailSettings(mailbox);
            this.setState({ loading: false });
        }

        if (this.props.settingsData && this.props.settingsData.tz){
            this.fetchCountry();
        }
    }

    componentWillReceiveProps(nextProps){        
        let found;
        let extensionData = nextProps.extensionData || [{}];
        if (this.state.mailbox && nextProps.extensionData){
            found = nextProps.extensionData.findIndex(extension => extension.mailbox == this.state.mailbox);
        }

        if (!nextProps.adminView && nextProps.extensionData && found !== -1) {
            //If we return the larger extensions object, let's find our particular form
            this.setState({
                form: nextProps.extensionData[found],
                loading:false,
            })
        } else {
            this.setState({ 
                form: extensionData[extensionData.length-1], 
                loading: false,
            })
        }

        if(this.props.selectedVoicemailBox !== nextProps.selectedVoicemailBox) {
            this.setState({ 
                mailbox: nextProps.selectedVoicemailBox
            })
        }

        this.fetchCountry();

        if (nextProps.putSuccess && nextProps.reducerTarget == "voicemailboxes"){
            this.renderSuccessMessage(`Successfully updated mailbox ${nextProps.putSuccess.object}`)
        }    
    }

    fetchVoicemailSettings(voicemailbox){
        if (this.props.new){return};

        const reqData = {
            reqAction: 'voicemailsettings',
            reqObject: voicemailbox
        };

        const storeKey = 'voicemailsettings';

        if (voicemailbox){
            this.props.getUserPortalHttp(reqData, storeKey);
        }
    }

    createRollbackForm() {
        const rollbackForm = Object.assign({}, this.state.form);
        this.setState({ rollbackForm });
    }
  
    handleClick = (e) => {
        if (this.node) {
            if (this.node.contains(e.target)) {
                this.clickedNode();
            }
        }
    }

    onSubmitClick(cancel){
        if(cancel){
            this.props.selectMailbox(null);
            this.setState({newChanges: false}, ()=> this.props.cancel())
        } else{
            if(this.props.new){
                this.props.createMailbox(this.state.newForm);
            } else{
                let form = this.state.form
                form.exitname === "No Room" ? form.exitname = '' : form.exitname = form.exitname
                this.editVMBox(form);
            }
            this.setState({ disabled: true });
        }
    }

    async submitForm(field, data) {
        if (field === "country"){
            this.fetchTimezones(data);
        }

        if (this.state && this.state.form &&this.state.form[field] == data) {
            console.log('No update');
            return;
        } 

        let { form } = this.state;

        if (this.props.new){
            form = this.state.newForm;
        }

        form[field] = data;


        // Lets validate our field
        const error = this.validateField(field, data);
        if (error) {
            this.setState({
                errors: { [field]: error },
            });
            this.renderErrorMessage(error);
            return;
        }

        // If no error, lets set the data
        // on the form and set it to state without errors

        if (field == 'exitapp'){
            form['exitname'] = '';
        }

        this.setState({
            ...form,
            errors: { [field]: '' },
            disabled:false,
            newChanges: true,
        }, ()=> this.madeChanges(this.state.newChanges, this.state.form));
    }

    renderErrorMessage(response){
        errorNotification({
            id: this.state.notificationId,
            title:'Error!',
            message: response
        });  
    }

    renderSuccessMessage(response){
        successNotification({
            id: this.state.notificationId,
            title:'Success!',
            message: response
        });
    }

    updateForm(field, data) {
        let currentSettings = this.props.new ? this.state.newForm : this.state.form;
        currentSettings.voicemailEmails[this.state.selectedVMEmail][field] = data;
        this.setState({ newForm: currentSettings});
    }

    prepNewVMEmailForm(field, data){
        let {newVMEmailForm} = this.state;
        newVMEmailForm[field] = data;
        newVMEmailForm["tenant"] = this.props.tenant;
        newVMEmailForm["extension"] = this.props.selectedVoicemailBox;
        this.setState({ newVMEmailForm });
    }

    handleDeleteEmailModal(){
        let newFormLength = 0;

        if (this.state && this.state.newForm){
            newFormLength = Object.keys(this.state.newForm).length;
        }

        let currentSettings = this.props.new ? this.state.newForm : this.state.form;
        let newSettings=[];
        let i=0;

        currentSettings.voicemailEmails.forEach(voicemailEmail => {
            if (this.state.selectedVMEmail !== i){
                newSettings.push(voicemailEmail);
            }
            i++
        });

        currentSettings.voicemailEmails = newSettings;

        if (this.state && this.state.newForm && this.props.new){
            return this.setState({
                showDeleteEmailModal:false,
                newForm: currentSettings,
            })
        }

        this.setState({ showDeleteEmailModal: false });
        this.editVMBox(currentSettings);
    }

    handleAddNewEmail(){

        if (this.state.newVMEmailFlag && !this.state.newVMEmailForm.emailaddress){
            return this.renderErrorMessage("Please add an email address");
        }

        let formLength = this.props.new ? Object.keys(this.state.newForm).length : Object.keys(this.state.form).length;
        let currentSettings = this.props.new ? this.state.newForm : this.state.form;


        //This is for adding when it's a new VMBox
        if (this.props.new){

            if (!currentSettings.voicemailEmails){
                currentSettings['voicemailEmails'] = [];
            }

            if (this.state.newVMEmailFlag){
                currentSettings['voicemailEmails'][this.state.newForm.voicemailEmails.length] = this.state.newVMEmailForm;
            }

            this.setState({ newVMEmailForm: {}, showAddEmailModal:false });
            return;

        }

        //this is for an existing email, editing a field
        if (this.state && !this.props.new && this.state.newVMEmailFlag && formLength !== 0){
            currentSettings["voicemailEmails"][this.state.form.voicemailEmails.length] = this.state.newVMEmailForm;
        } 
        
        if (this.state && !this.props.new && this.state.newVMEmailFlag && formLength == 0){
            currentSettings.voicemailEmails[0]= this.state.newVMEmailForm;
        }

        this.setState({ newVMEmailForm: {}, showAddEmailModal: false });
        this.editVMBox(currentSettings);
    }

    fetchTimezones = (selectedCountry) => {
        let timezones = this.props.timezones;
        let selectedTimezones;

        timezones.forEach(function(country) {
            if (selectedCountry === country["country"]) {
                selectedTimezones = country.zones
            }
        })

        this.setState({ timezones: selectedTimezones });
    }

    addNew(){
        this.setState({ 
            addNewEmail: true,
            newVMEmailFlag: true,
            showAddEmailModal: true
        })
    }

    fetchCountry(){

        //We don't store country in our DB, though we still
        //need to populate the select, if we have a timezone
        let selectedTimezone;
        let selectedCountry;

        if (this.props.settingsData && this.props.settingsData["tz"]) {
            selectedTimezone = this.props.settingsData["tz"];
        } else if (!this.props.adminView && this.state.form.tz){
            selectedTimezone = this.state.form.tz;
        }

        this.props.timezones.map(timezone => {
            timezone.zones.map(zone => {
                if (zone.zone_name === selectedTimezone){
                    selectedCountry = zone.country_code;
                };
            });

            if (selectedCountry === timezone.code){
                this.setState({ country: timezone.country });
            }
        });
    }

    editVoicemailEmails(index){
        this.setState({ 
            showAddEmailModal: true, 
            selectedVMEmail: index,
            editingVoicemails: true,
            newVMEmailFlag: false,
        });
    }

    deleteVoicemailEmails(index){
        this.setState({
            showDeleteEmailModal: true,
            selectedVMEmail: index,
            newVMEmailFlag: false,
        })
    }

    handleDeleteConfirmClose(){
        this.setState({ showAddEmailModal: false });
    }

    handleSubmitConfirmClose(){
        this.editVMBox(this.state.form);
        this.setState({ showAddEmailModal: false });
    }

    validateField(field, data) {

        // We want to make sure our wait
        // times are actually numbers
        // and greater than 0
        switch (field) {
        case 'keypresswait':
        case 'i_limit':
        case 'none_limit':
            if (data && Number.isInteger(parseInt(data)) && data >= 0) {
                return null;
            }
            return 'Please enter a valid number';

        default:
            return null;
        }
    }

    madeChanges(answer, data) {
        this.setState({
            newChanges: answer,
            childFormData: data,
        });
    }

    clickedNode(){
        this.setState({ clickedInsideElements: true })
    }

    editVMBox(data){

        var reg = /^\d+$/;
        if (!reg.test(data.password)) {
            return this.renderErrorMessage("Password must be numeric");
        }

        let { mailbox } = this.state;

        const reqData = {
            reqAction: 'voicemailsettings',
            reqBody: data,
            reqObject: mailbox,
        }

        const storeKey = 'voicemailsettings';

        this.setState({ newChanges: false});
        this.props.putUserPortalHttp(reqData, storeKey);
    }

    render() {
        //If we got here from the "Add New" button
        // let's add a flag to make some basic changes
        let newFlag = this.props.new;
        let additionalEmails= [];
        let { loading, fetchedMailbox } = this.state;
        let role;
        if (app.token.get("role")){
            role = app.token.get("role")
        }

        const styles = {
            buttonContainer:{
                marginTop: '20px'
            },
            header: {
                color: '#595959',
            },
            required:{
                color:'red'
            },
            settingsContainer: {
            }
        }

        if ((!this.props.adminView && loading) || (this.props.adminView && !newFlag && !this.state.form.password && !this.state.form.location) ||
            (!this.props.adminView && (app.token.get("mailbox") !== "" || !this.props.routeParams.mailbox) && this.state.form && !this.state.form.password && !this.state.form.location)) {
            return (
                <StandardSettingsPage>
                    <Loading />
                </StandardSettingsPage>
            )
        }

        if (!this.props.adminView && (!this.state.mailbox || app.token.get("mailbox") == "" )) {
            return (
                <StandardSettingsPage>
                    <h2>This extension does not have a mailbox.</h2>
                    <h4>Please contact support or your administrator.</h4>
                </StandardSettingsPage>
            )
        }

        let formType = 'SelectInput';
        let exitApplications = [];
        let timeZonesList;
        let exitlabel;
        let exitapp;

        if (!newFlag){
            exitapp = this.state.form.exitapp ? this.state.form.exitapp : this.state.form.exitapp;
        } else {
            exitapp = this.state.newForm.exitapp ? this.state.newForm.exitapp : "No Exit Application Selected";
        }

        //We need configurations by now, if we don't have it, let's return
        if (!this.props.configurations && app.token.get("role") != "cloudusers"){
            return null;
        }

        let code;
        let fileFormatTypes = ["mp3","ogg","wav"];
        let countries = this.props.timezones.map(timezone => timezone.country);
        let applications = [{label: 'None', value: null}];

        if (this.props.applications){
            this.props.applications.map(application => 
                applications.push({label:application.friendlyname, value: application.name})
            );
        } else {
            this.props.configurations.applications.map(application => 
                applications.push({label:application.friendlyname, value: application.name})
            );
        }

        let locationOptions = this.props.configurations && this.props.configurations.locations ? this.props.configurations.locations.map(location => location.friendlyname) : [];
        locationOptions.sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          });
        locationOptions = ['None', ...locationOptions];
        let dropDownApplications = ["voicemenu", "callflow", "conferenceroom", "callgroup", "extension", "queue", "recording", "route", "voicemailbox"];
        let textInputApplications = ["callforwarding", "requestacallback"];

        // Based on what is selected for exit app, populate exitname
        if (exitapp && dropDownApplications.includes(exitapp)){

            exitapp = exitapp.toLowerCase().replace(/\s/g, '');
            let listKey = 'friendlyname';
            let valueKey = 'name';

            //this.props.configurations are not the same
            //as the list of applications, let's make some adjustments
            switch(exitapp){
                case 'voicemenu':
                    exitlabel='an auto attendant';
                    exitapp='autoattendant';
                    break;
                case 'callflow':
                    exitapp='callflows';
                    exitlabel='a call flow';
                    break;
                case 'callgroup':
                    exitapp='callgroups';
                    exitlabel='a call group';
                    break;
                case 'conferenceroom':
                    exitapp='conferenceRooms';
                    exitlabel='a conference room';
                    listKey='name'
                    break;
                case 'extension':
                    exitapp='users';
                    exitlabel='an extension';
                    listKey='extension';
                    valueKey='extension';
                    break;
                case 'queue':
                    exitapp='queues';
                    exitlabel='a queue'
                    break;
                case 'recording':
                    exitapp='recordings';
                    exitlabel='a recording';
                    listKey='name';
                    break;
                case 'route':
                    exitapp='routes';
                    exitlabel='a route'
                    break;
                case 'voicemailbox':
                    exitapp='voicemailboxes';
                    exitlabel='a voicemail box';
                    listKey='mailbox';
                    valueKey= 'mailbox';
                    break;
                default:
                    break;

            };

            let exitApplicationsList = this.props.configurations[exitapp];

            if (exitApplicationsList && exitApplicationsList.length > 0){
                let name = exitapp === 'conferenceRooms' ? 'confno' : 'name'
                exitApplicationsList.map(application => {
                    let appLabel = exitapp === 'conferenceRooms' ? `${application[name]} - ${application[listKey] !== '' ? application[listKey] : 'No Name'}` : application[listKey]
                    return exitApplications.push({label: appLabel, value: application[name]})
                });
            }

            //If we have extensions or voicemailboxes, let's show both the number and the name
            if (exitapp ==  'users'){
                let extensionArray = [];
                this.props.configurations["users"].map(user => 
                    extensionArray.push({label: `${user['extension']} - ${user.firstname} ${user.lastname}`, value:user['extension']})
                );
                exitApplications = extensionArray;
            }

            if (exitapp == 'voicemailboxes'){
                let extensionArray = [];
                this.props.configurations["voicemailboxes"].map(box =>
                    extensionArray.push({label: `${box['mailbox']} - ${box['fullname']}`, value:box['mailbox'] })
                );
                exitApplications = extensionArray;
            }

        } else if (exitapp && textInputApplications.includes(exitapp)){
            formType="TextInput";
            switch (exitapp){
                case 'callforwarding':
                    exitlabel='call forwarding';
                    break;
                case 'requestacallback':
                    exitapp='requestcallback';
                    exitlabel='request a callback';
                    break;
                default:
                    break;
            };
        } else {
            exitapp = null;
        }

        //This differentiates numeric values from text values so we can sort them as such
        switch (exitapp){
            case "users":
            case "voicemailboxes":
                exitApplications.sort((a, b) => (parseInt(a.value)) > parseInt(b.value) ? 1 : -1);
                break;
            default:
                exitApplications.sort((a, b) => (a.value.toLowerCase() > b.value.toLowerCase()) ? 1 : -1)
          }

        exitapp === 'conferenceRooms' ? exitApplications.unshift({label: "No Room", value: 'No Room'}) : null

        if (this.state && this.state.form && this.state.form.tz){
            timeZonesList = this.state.form.tz ? [this.state.form.tz] : ['Please select a country'];
        }

        if (typeof this.state.timezones === "object"){
           timeZonesList = this.state.timezones.map(zones => zones.zone_name)
        }

        //If we just selected this VM Box, and we have a saved timezone, 
        //let's fetchTimezones for the other timezones in the selected country
        if (!newFlag && this.state.form.tz && this.state.timezones === "Please select a country"){
            const selectedTimezone = this.state.form.tz;
            this.props.timezones.map(timezone => {
                this.props.timezones.map(timezone => {
                    timezone.zones.map(zone => {
                        if (zone.zone_name === selectedTimezone){
                            code = zone.country_code;
                        }
                    })
                    if (timezone.code === code ){
                        this.fetchTimezones(timezone.country);
                    }
                });
            });
        }

        if (!newFlag && this.state && this.state.form.voicemailEmails){
            let i=0;
            this.state.form.voicemailEmails.forEach(additionalEmail => {
                additionalEmails.push(
                    <FormControlCard
                        formControl="AdditonalEmail"
                        field=''
                        title="Additional Emails"
                        subtitle="Send voicemails to additional email addresses"
                        data={additionalEmail}
                        icon={<Inbox />}
                        index={i}
                        onEdit={(i) => this.editVoicemailEmails(i)}
                        onDelete={(i) => this.deleteVoicemailEmails(i)}
                        key={i}
                     />
                ), i++
            })
        }

        if (newFlag && this.state && this.state.newForm.voicemailEmails){
            let i=0;
            this.state.newForm.voicemailEmails.forEach(additionalEmail => {
                additionalEmails.push(
                    <FormControlCard
                        formControl="AdditonalEmail"
                        field=''
                        title="Additional Emails"
                        subtitle="Send voicemails to additional email addresses"
                        data={additionalEmail}
                        icon={<Inbox />}
                        index={i}
                        onEdit={(i) => this.editVoicemailEmails(i)}
                        onDelete={(i) => this.deleteVoicemailEmails(i)}
                        key={i}
                     />
                ), i++
            })
        }

        let { form, newForm } = this.state;
        
        if (form) {
            form.exitapp === 'conferenceroom' && form.exitname === "" ? form.exitname = "No Room" : null
        }

        let cloudUserLocation = this.state.form.location;

        let exitNameSwitch = "";
        if (exitapp && role !== 'cloudusers') {
            exitNameSwitch = <FormControlCard
                form={ newFlag ? this.state.newForm : this.state.form}
                field="exitname"
                formControl={formType}
                title="Select Exit Application"
                subtitle={`You selected ${exitlabel}, which one would you like to use?`}
                icon={<ExitIcon />}
                submitForm={(field, data) => this.submitForm(field, data)}
                options={exitApplications}
            />
        } else if (exitapp && role == 'cloudusers') {
            exitNameSwitch = <FormControlCard
                form={ newFlag ? this.state.newForm : this.state.form}
                field="exitname"
                disabled
                formControl="TextInput"
                title="Select Exit Application"
                subtitle={`You selected ${exitlabel}, which one would you like to use?`}
                icon={<ExitIcon />}
                submitForm={(field, data) => this.submitForm(field, data)}
                options={exitApplications}
            />
        } else {
            exitNameSwitch = ""
        }
        
        return (
            <div ref={node => this.node = node} style={{
                display: 'flex',
                flex: 1,
                height: '100%',
                background: '#EFEFEF',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: '50px',
                overflow: 'auto',
            }}>
                <StandardSettingsPage style={styles.settingsContainer}>
                    {newFlag ? (
                        <FormControlCard
                            form={this.state.newForm}
                            field="friendlyname"
                            formControl="TextInput"
                            title="Mailbox Number"
                            subtitle={<span style={styles.required}>Required.</span>}
                            icon={<Dialpad />}
                            submitForm={(field, data) => this.submitForm(field, data)}
                    /> 
                    ) : (
                        <FormControlCard
                            form={this.state.form}
                            field= "mailbox"
                            formControl="TextInput"
                            disabled
                            title="Mailbox Number"
                            subtitle={<span style={styles.required}>Required.</span>}
                            icon={<Dialpad />}
                            submitForm={(field, data) => this.submitForm(field, data)}
                        /> 
                    )}
                    <FormControlCard /* RENDER PASSWORD */
                        form={newFlag ? this.state.newForm : this.state.form}
                        field="password"
                        formControl="MailboxPassInput"
                        title="Mailbox Password"
                        value={this.state.value}
                        subtitle={<span style={styles.required}>Required.</span>}
                        icon={<LockIcon />}
                        submitForm={(field, data) => this.submitForm(field, data)}
                    />
                    <FormControlCard
                        form={newFlag ? this.state.newForm : this.state.form}
                        field="fullname"
                        formControl="TextInput"
                        title="Full Name"
                        icon={<PersonIcon />}
                        submitForm={(field, data) => this.submitForm(field, data)}
                    />
                    {role == 'cloudusers' ? (
                        <FormControlCard
                            value={cloudUserLocation}
                            disabled
                            formControl="TextInput"
                            title="Location"
                            subtitle={<span style={styles.required}>Required.</span>}
                            icon={<LocationIcon />}
                            submitForm={(field, data) => this.submitForm(field, data)}
                            options={locationOptions}
                        />
                    ) : (
                        <FormControlCard
                            form={newFlag ? this.state.newForm : this.state.form}
                            field="location"
                            formControl="SelectInput"
                            title="Location"
                            subtitle={<span style={styles.required}>Required.</span>}
                            icon={<LocationIcon />}
                            submitForm={(field, data) => this.submitForm(field, data)}
                            options={locationOptions}
                        />
                    )}
                    {newFlag ? (
                        <FormControlCard
                            form={this.state.newForm}
                            field="country"
                            formControl="SelectInput"
                            title="Country"
                            icon={<TimeIcon />}
                            submitForm={(field, data) => this.submitForm(field, data)}
                            options={countries}
                        />
                    ) : (
                        {...role !== 'cloudusers' ? (
                            <FormControlCard
                                form={this.state.form.country ? this.state.form : this.state}
                                field="country"
                                formControl="SelectInput"
                                title="Country"
                                icon={<TimeIcon />}
                                submitForm={(field, data) => this.submitForm(field, data)}
                                options={countries}
                            />
                        ) : (
                            <div></div>
                        )}
                    )}
                    {newFlag ? (
                        <FormControlCard
                            form={this.state.newForm}
                            field="tz"
                            formControl="SelectInput"
                            title="Time Zone"
                            icon={<TimeIcon />}
                            submitForm={(field, data) => this.submitForm(field, data)}
                            options={timeZonesList}
                        />
                    ) :
                        {...role == 'cloudusers' ? (
                            (
                                <FormControlCard
                                    form={this.state.form.tz ? this.state.form : this.state.form}
                                    field="tz"
                                    formControl="SelectInput"
                                    disabled
                                    title="Time Zone"
                                    icon={<TimeIcon />}
                                    submitForm={(field, data) => this.submitForm(field, data)}
                                    options={timeZonesList}
                                />
                            )) : (
                                <FormControlCard
                                    form={this.state.form.tz ? this.state.form : this.state.form}
                                    field="tz"
                                    formControl="SelectInput"
                                    title="Time Zone"
                                    icon={<TimeIcon />}
                                    submitForm={(field, data) => this.submitForm(field, data)}
                                    options={timeZonesList}
                                />
                            )}
                    }
                    <FormControlCard
                        form={newFlag ? this.state.newForm : this.state.form}
                        field="email"
                        formControl="AddAnother"
                        title="Email Address"
                        icon={<EmailIcon />}
                        subtitle="Email address to receive voicemail notification."
                        addNew={()=> this.addNew()}
                        submitForm={(field, data) => this.submitForm(field, data)}
                    />
                    <FormControlCard
                        form={newFlag ? this.state.newForm : this.state.form}
                        field="attach"
                        formControl="ToggleInput"
                        title="Attach voicemail"
                        icon={<VoicemailIcon />}
                        submitForm={(field, data) => this.submitForm(field, data)}
                        label="No / Yes"
                    />
                    <FormControlCard
                        form={newFlag ? this.state.newForm : this.state.form}
                        field="fileformat"
                        formControl="SelectInput"
                        submitForm={(field, data) => this.submitForm(field, data)}
                        options={fileFormatTypes}
                        subtitle="Your preferred file format for attached voicemails in email notifications."
                    />
                    <FormControlCard
                        form={newFlag ? this.state.newForm : this.state.form}
                        field="transcribefile"
                        formControl="ToggleInput"
                        title="Transcribe File"
                        icon={<EditIcon />}
                        submitForm={(field, data) => this.submitForm(field, data)}
                        label="No / Yes"
                        subtitle="Enabling transcription is a $5/month charge unless you're on the FluentCloud Complete Plan."
                    />
                    {additionalEmails}
                    <FormControlCard
                        form={newFlag ? this.state.newForm : this.state.form}
                        field="deleteafterdelivery"
                        formControl="ToggleInput"
                        title="Delete After Delivery"
                        icon={<DeleteIcon />}
                        submitForm={(field, data) => this.submitForm(field, data)}
                        label="No / Yes"
                        subtitle="Select 'Yes' to delete message after delivered to email."
                    />
                    <FormControlCard
                        form={newFlag ? this.state.newForm : this.state.form}
                        field="saycid"
                        formControl="ToggleInput"
                        title="Say Caller ID"
                        icon={<VolumeIcon />}
                        submitForm={(field, data) => this.submitForm(field, data)}
                        label="No / Yes"
                        subtitle="On voicemail play back, this command will state the caller ID of
                        the person who left the message."
                    />
                    <FormControlCard
                        form={newFlag ? this.state.newForm : this.state.form}
                        field="review"
                        formControl="ToggleInput"
                        title="Review"
                        icon={<EarIcon />}
                        submitForm={(field, data) => this.submitForm(field, data)}
                        label="No / Yes"
                        subtitle="This allows callers to review their voicemail message prior to delivering them."
                    />
                    <FormControlCard
                        form={newFlag ? this.state.newForm : this.state.form}
                        field="envelope"
                        formControl="ToggleInput"
                        title="Envelope"
                        icon={<EmailIcon />}
                        submitForm={(field, data) => this.submitForm(field, data)}
                        label="No / Yes"
                        subtitle="You can have voicemail play back the details of the message
                        before it plays the actual message. Since this information can also be accessed by
                        pressing 5, we generally set this to no."
                    />
                    <FormControlCard
                        form={newFlag ? this.state.newForm : this.state.form}
                        field="sayduration"
                        formControl="ToggleInput"
                        title="Say Duration"
                        icon={<DurationIcon />}
                        submitForm={(field, data) => this.submitForm(field, data)}
                        label="No / Yes"
                        subtitle="This command will state the length of the message."
                    />
                    <FormControlCard
                        form={newFlag ? this.state.newForm : this.state.form}
                        field="sendvoicemail"
                        formControl="ToggleInput"
                        title="Send Voicemail"
                        icon={<SendIcon />}
                        submitForm={(field, data) => this.submitForm(field, data)}
                        label="No / Yes"
                        subtitle="This allows users to compose messages to other users from within their mailboxes."
                    />
                    <FormControlCard
                        form={newFlag ? this.state.newForm : this.state.form}
                        field="hidefromdir"
                        formControl="ToggleInput"
                        title="Hide from Directory"
                        icon={<HideIcon />}
                        submitForm={(field, data) => this.submitForm(field, data)}
                        label="No / Yes"
                        subtitle="If you wish, you can hide this mailbox from the directory."
                    />
                    <FormControlCard
                        form={newFlag ? this.state.newForm : this.state.form}
                        field="tempgreetwarn"
                        formControl="ToggleInput"
                        title="Temporary Greeting Warning"
                        icon={<WarningIcon />}
                        submitForm={(field, data) => this.submitForm(field, data)}
                        label="No / Yes"
                        subtitle="Setting this to yes will warn the mailbox owner that they have a temporary
                        greeting set. This can be a useful reminder when people return from trips or vacations."
                    />
                    <FormControlCard
                        form={newFlag ? this.state.newForm : this.state.form}
                        field="skipgreeting"
                        formControl="ToggleInput"
                        title="Skip Voicemail Message"
                        icon={<SkipIcon />}
                        submitForm={(field, data) => this.submitForm(field, data)}
                        label="No / Yes"
                        subtitle="Skip the automated message from playing after the custom created message."
                    />
                    { newFlag ? (
                        <FormControlCard
                            form={this.state.newForm}
                            field="exitapp"
                            formControl="SelectInput"
                            title="Exit Applications"
                            subtitle="This is the action that will occur when a user dials 0 to exit your voicemail."
                            icon={<ExitIcon />}
                            submitForm={(field, data) => {
                                this.submitForm(field, data);
                                newForm['exitname'] = '';
                                this.setState({ newForm });
                            }}
                            options={applications}
                        />
                    ) : (
                        {...role == "cloudusers" ? (
                            <FormControlCard
                                value={`${translateApplication(this.state.form.exitapp)}`}
                                disabled
                                formControl="TextInput"
                                title="Exit Applications"
                                subtitle="This is the action that will occur when a user dials 0 to exit your voicemail."
                                icon={<ExitIcon />}
                                submitForm={(field, data) => {
                                    this.submitForm(field, data);
                                    form['exitname'] = '';
                                    this.setState({ form });
                                }}
                                options={applications}
                            />
                        ):(
                            <FormControlCard
                                form={this.state.form}
                                field="exitapp"
                                formControl="SelectInput"
                                title="Exit Applications"
                                subtitle="This is the action that will occur when a user dials 0 to exit your voicemail."
                                icon={<ExitIcon />}
                                submitForm={(field, data) => {
                                    this.submitForm(field, data);
                                    form['exitname'] = '';
                                    this.setState({ form });
                                }}
                                options={applications}
                            />
                        )}
                    )}
                    {exitNameSwitch}
                    <FormControlCard
                        form={newFlag ? this.state.newForm : this.state.form}
                        field="sort_asc"
                        formControl="ToggleInput"
                        label="Newest First / Oldest First"
                        title="Voicemail Sort Order"
                        subtitle="Toggle how you'd like to view your voicemails"
                        icon={<AZIcon />}
                        submitForm={(field, data) => this.submitForm(field, data)}
                    />
                    <FormControlCard
                        form={newFlag ? this.state.newForm : this.state.form}
                        field="pinless_extension_access"
                        formControl="ToggleInput"
                        label="No / Yes"
                        title="Pinless Voicemail Access"
                        subtitle="Access your voicemails without using a PIN."
                        icon={<OpenLockIcon />}
                        submitForm={(field, data) => this.submitForm(field, data)}
                    />
                    <div style={styles.buttonContainer}>
                        <Footer
                            disabled={this.state.disabled}
                            onClick={(cancel) => this.onSubmitClick(cancel)}
                            newFlag={newFlag}
                        />
                        <Dialog
                            open={this.state.showAddEmailModal}
                            onClose={this.props.onModalClose}
                            fullWidth={true}
                        >
                            <DialogTitle>Add Another Email</DialogTitle>
                            <DialogContent>
                                {this.state.newForm.voicemailEmails ? (
                                    <AddEmail
                                        newFlag={this.state.newVMEmailFlag}
                                        vmEmailSettings={newFlag ? this.state.newForm.voicemailEmails : this.state.form.voicemailEmails}
                                        selectedVMEmail={this.state.selectedVMEmail}
                                        fileFormatTypes={fileFormatTypes}
                                        timezones={this.props.timezones}
                                        updateForm={(field, data) => this.updateForm(field, data)}
                                        prepNewVMEmailForm={(field, data) => this.prepNewVMEmailForm(field, data)}
                                    />
                                ) :(
                                    <AddEmail
                                        newFlag={this.state.newVMEmailFlag}
                                        vmEmailSettings={newFlag ? '' : this.state.form.voicemailEmails}
                                        selectedVMEmail={this.state.selectedVMEmail}
                                        fileFormatTypes={fileFormatTypes}
                                        timezones={this.props.timezones}
                                        updateForm={(field, data) => this.updateForm(field, data)}
                                        prepNewVMEmailForm={(field, data) => this.prepNewVMEmailForm(field, data)}
                                    />
                                )}
                            </DialogContent>
                            <DialogActions>
                            <Button
                                color='primary'
                                onClick={() => this.setState({ showAddEmailModal: false })}
                            >Cancel</Button>
                            <Button
                                color='primary'
                                onClick={() => this.handleAddNewEmail()}
                            >Submit</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={this.state.showDeleteEmailModal}
                            onClose={() => this.handleDeleteEmailModal(false)}
                            fullWidth={true}
                        >
                            <DialogTitle>Are you sure?</DialogTitle>
                            <DialogActions>
                                <Button
                                    color='primary'
                                    onClick={() => this.setState({ showDeleteEmailModal: false })}
                                >Cancel</Button>
                                <Button
                                    color='primary'
                                    onClick={() => this.handleDeleteEmailModal()}
                                >Delete</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </StandardSettingsPage>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    configurations: state.http ? state.http.configurations : null,
    voicemailBoxes:state.voicemail.data,
    timezones: state.http ? state.http.configurations.timezones : null,
    putSuccess: state.voicemail.putSuccess,
    reducerTarget: state.voicemail.reducerTarget,
    extensionData: state.voicemail.extensionData,
    routeParams: state.navigation.params,
});

const bindActions = (dispatch) => ({
    getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
    putUserPortalHttp: (reqData, storeKey) => dispatch(putUserPortalHttp(reqData, storeKey)),
    getUserPortalHttp: (reqData, storeKey) => dispatch(getUserPortalHttp(reqData, storeKey)),
    selectMailbox: (data) => dispatch(selectMailbox(data)),
});

export default connect(mapStateToProps, bindActions)(MailboxSettings);
