import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';
import { parsePhoneNumber } from 'libphonenumber-js';
import TextField from '@material-ui/core/TextField';

import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Footer from 'presentational/Footer';

import { postUserPortalHttp } from 'actions/httpRequest';
import { errorNotification } from 'actions/notifications';
import {
  resetFlag,
  changeFormData,
} from 'actions/createFax';

function StepOne(props) {

  useEffect(() => {
    if (props.faxCreated) {
      props.changeStep(2);
      props.resetFlag();
    }
  }, [props.faxCreated])

  const updateForm = (field, data) => {
    const newForm = cloneDeep(props.form);

    newForm[field] = data;
    props.changeFormData(newForm);
  }

  const submit = () => {
    const { did, callerid } = props.form;
    const toNumber = parsePhoneNumber(did, {
      defaultCountry: 'US'
    })

    if (did && toNumber.isValid() && callerid) {
      let number = toNumber.nationalNumber;
      if (toNumber.country !== 'US') {
        number = toNumber.number;
      }
      const form = { ...props.form, did: number}
      const reqData = {
        reqAction: 'createfax',
        reqBody: form,
      };

      props.createFax(reqData);
    } else {
      errorNotification({
        title: 'Error!',
        message: 'Both To and From fields are required and need to be valid phone numbers.',
      })
    }
  }

  return (
    <StandardSettingsPage>
      <FormControlCard
        field="did"
        formControl="CustomComponents"
        title="To Phone Number:"
        subtitle=""
      >
        <div style={styles.customNumberInput}>
          <TextField
            label='To Phone Number:'
            fullWidth
            type='text'
            onChange={(e) => updateForm('did', e.target.value)}
            value={props.form.did}
          />
        </div>
      </FormControlCard>
      <FormControlCard
        form={props.form}
        field="callerid"
        formControl="SelectInput"
        title="From Phone Number:"
        subtitle=""
        submitForm={updateForm}
        options={props.faxOptions.faxablenumbers}
      />
      <FormControlCard
        form={props.form}
        field="reference"
        type="text"
        formControl="TextInput"
        title="Reference Code: (Optional)"
        subtitle=""
        submitForm={updateForm}
      />
      <Footer
        fullWidth={true}
        singleButton={true}
        onClick={submit}
        submitButtonText='Next Step: Upload Files'
      />
    </StandardSettingsPage>
  );
}

const bindActions = (dispatch) => ({
  changeFormData: (data) => dispatch(changeFormData(data)),
  resetFlag: () => dispatch(resetFlag()),
  createFax: (data) => dispatch(postUserPortalHttp(data)),
});

const mapStateToProps = (state) => ({
  form: state.createFax.createFaxForm,
  faxOptions: state.createFax.faxOptions,
  faxCreated: state.createFax.faxCreated,
});

const styles = {
  customNumberInput: {
    paddingLeft: '50px',
    paddingRight: '70px',
  },
}

StepOne.propTypes = {
  form: PropTypes.object,
  faxOptions: PropTypes.object,
  faxCreated: PropTypes.bool,
}

export default connect(mapStateToProps, bindActions)(StepOne);
