import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { useTheme } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

export const TITLE = "Cannot be used for room-level 911. The country is not supported.";

function InvalidCountryIcon(props) {
    const theme = useTheme();

    return (
        <Tooltip title={TITLE}>
            <WarningIcon htmlColor="#D9E001" />
        </Tooltip>
    );
}

export default InvalidCountryIcon;
