import React, { Component } from 'react'
import { connect } from 'react-redux';
import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import PriorityIcon from '@material-ui/icons/FormatListNumbered';
import EmptyIcon from '@material-ui/icons/RadioButtonUnchecked';
import AnnouncementIcon from '@material-ui/icons/RecordVoiceOver';
import TemporaryIcon from '@material-ui/icons/Schedule';
import PortingNumberIcon from '@material-ui/icons/ArrowRight';
import DialpadIcon from '@material-ui/icons/Dialpad';
import { putCoreHttp } from 'actions/httpRequest';
import Loading from 'presentational/Loading';
import Footer from 'presentational/Footer';
import {  resetReloadDetails } from 'actions/phoneNumbers';
import DialogModal from 'presentational/DialogModal';
import { translatePhoneTypeToFriendlyName } from '../../utils/translations';

class AdvancedSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumberData: props.phoneNumberData.data,
      isModalOpen: false,
      form: {
        sms_posturl_override: '', 
        acd_queue_identifier: '', 
        acd_queue_entry_priority: '', 
        temporary: '',
        associatedPermanentNumber:'',
        phoneNumberData: [],
      },
      loading: false,
      error: null,
      submitError: null,
      abbrevForm: {},
      changedValues:{},
    }

    this.usingapiData = false;
  }

  componentWillUnmount(){
    if(!this.props.buttonDisabled){
      this.props.checkSubmit(this.state.form);
      this.props.disableButton(true);
    }
    this.props.resetReloadDetails('store');
  }

  onSubmit = (cancel) => {
    if(cancel){
      this.props.onCancel();
      return;
    }
    this.props.disableButton(true);
    this.editNumber(this.state.form)

  }

  updateDetails = () => {
    if(this.props.reloadDetails === 'store' && this.props.phoneNumberDetails){
      this.props.resetReloadDetails();

      this.setState({ 
        form: this.props.phoneNumberDetails,
        loading: this.props.loading,
       });
    }
  }

  componentDidMount(){
    this.updateDetails()

  } 

  handleClick = (e) => {
    if(this.node) {
      if(this.node.contains(e.target)) {
        this.props.clickedNode();
      }
    }
  }

  componentDidUpdate(){
   this.updateDetails();
  }

  submitForm = (field, data) => {
    if (this.state.form[field] === data) {
      console.log('No update');
      return;
    } else {
      this.props.updateAbbrevForm(field, data)
      this.props.madeChanges(true, this.state.form);
    }
    
    if(this.props.buttonDisabled){
      this.props.disableButton(false)
    }

    let changedValues = {...this.state.changedValues};
    changedValues[field] = `${translatePhoneTypeToFriendlyName(field)}: ${data}`;

    let abbrevForm = {...this.state.abbrevForm};
    abbrevForm[field] = data;

    this.setState({ 
      form: {
        ...this.state.form,
        [field]: data
      },
      abbrevForm,
      changedValues
    });
  }

  editNumber = (data) => {
    const reqData = {
      reqAction: 'phonenumbers',
      reqObject: encodeURIComponent(data.phonenumber),
      reqBody: data,
    };

    const storeKey = 'editphonenumbers';
    this.props.putCoreHttp(reqData, storeKey);
  }

  submitAdvancedSettings = (data) => {
    let { abbrevForm } = {...this.state};

    abbrevForm.phonenumber = this.props.selectedPhoneNumber;

    const reqData = {
      reqAction: 'bulkeditphonenumber',
      reqObject: 'bulkeditphonenumber',
      reqBody: abbrevForm,
    };

    const storeKey = 'bulkeditphonenumber';
    this.props.putCoreHttp(reqData, storeKey);
  } 

  render() {

    let acd_queue_entry_priority = [];

    if(this.props.phoneNumberDetails){
      acd_queue_entry_priority = this.props.phoneNumberDetails.config.acd_queue_entry_priority;
    }

    const associatedPhoneNumbers = this.state.form.associated_permanent_numbers || [];

    let { isModalOpen, changedValues, loading } = {...this.state};
    const { selectedPhoneNumber } = this.props;

    if (this.props.loading === false){
      loading = false;
    }

    if(!this.props.phoneNumberDetails || loading){
      return( <Loading /> );
    }


    return (
      <div
        ref={node => this.node = node} 
        style={{
          display: 'flex',
          flex: 1,
          height: '100%',
          background: '#EFEFEF',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: '50px',
          overflow: 'auto',
        }}
        onMouseDown={this.handleClick}
      >
      <StandardSettingsPage>
        <FormControlCard
          form={this.state.form}
          field="sms_posturl_override"
          icon={<BookmarkIcon />}
          formControl="TextInput"
          title="Incoming Text Message / SMS Webhook URL"
          type='text'
          subtitle="Sends incoming text messages via HTTP POST method to this URL"
          submitForm={this.submitForm}
        />
        <FormControlCard
          form={this.state.form}
          field="temporary"
          formControl="ToggleInput"
          title="Temporary Phone Number?"
          subtitle="Is this a temporary phone number, being used while another number is porting?"
          icon={<TemporaryIcon />}
          submitForm={(field, data) => this.submitForm(field, data)}
          label="No / Yes"
        />
        <FormControlCard
          form={this.state.form}
          field="porting_in_progress"
          formControl="ToggleInput"
          title="Porting in Progress?"
          subtitle="Is this number in the process of being ported over?"
          icon={<PortingNumberIcon />}
          submitForm={(field, data) => this.submitForm(field, data)}
          label="No / Yes"
        />  
        {this.state.form.temporary == true ? (
          <FormControlCard
            label="Select Phone Number(s)"
            form={this.state.form}
            data={{...this.props}}
            selected={associatedPhoneNumbers}
            field="associated_permanent_number"
            icon={<DialpadIcon />}
            formControl="MultipleSelectCheckbox"
            title="Associated Permanent Number"
            subtitle="What permanent phone number is this associated with?"
            submitForm={this.submitForm}
            handleChange={(phoneNumber) => this.submitForm('associated_permanent_numbers', phoneNumber)}
          />        
          ) : null
        }
        <FormControlCard
          form={this.state.form}
          field="acd_queue_identifier"
          icon={<EmptyIcon />}
          formControl="TextInput"
          title="ACD Queue Identifier"
          type='text'
          subtitle=""
          submitForm={this.submitForm}
        />
        <FormControlCard
          form={this.state.form}
          field="acd_queue_entry_priority"
          icon={<PriorityIcon />}
          formControl="SelectInput"
          title="ACD Queue Entry Priority"
          submitForm={this.submitForm}
          options={acd_queue_entry_priority}
        />
        <FormControlCard
          form={this.state.form}
          field="external_dispatch"
          icon={<AnnouncementIcon />}
          formControl="TextInput"
          title="External Dispatch"
          type='text'
          subtitle=""
          submitForm={this.submitForm}
        />
        <Footer
          multipleChanges={this.props.selectedPhoneNumber.length > 1 ? true : false}
          disabled={this.props.buttonDisabled}
          onClick={(cancel) => this.onSubmit(cancel)}
          onUpdateAll={() => this.setState({ isModalOpen: true })}
        />
      </StandardSettingsPage>
      <DialogModal 
        show={isModalOpen}
        affirmativeResponse={()=> {this.submitAdvancedSettings(this.state.form), this.props.disableButton(true), this.setState({ isModalOpen: false, loading: true })}}
        negativeResponse={()=> {this.props.disableButton(true), this.setState({ isModalOpen: false })}}
        title={`Are you sure you want to apply the following changes to all selected phone numbers?`}
        content={Object.values(changedValues)}
      />
      </div>
    )
  }
}

const bindActions = (dispatch) => ({
  putCoreHttp: (reqData, storeKey) => dispatch(putCoreHttp(reqData, storeKey)),
  resetReloadDetails: (data) => dispatch(resetReloadDetails(data))
})

const mapStateToProps = state => ({
  phoneNumberDetails: state.phoneNumbers.phoneNumberDetails,
  phoneNumberData: state.phoneNumbers.phoneNumberData,
  reloadDetails: state.phoneNumbers.reloadDetails,
  loading: state.phoneNumbers.loading,
});

export default connect(mapStateToProps, bindActions)(AdvancedSettings);
