import {
  SELECT_PHONE_NUMBER,
  LEFT_BAR_DATA_PREPARE,
  PHONENO_LOADING_RESET,
  RESET_FLAG,
  RELOAD_DETAILS,
  RELOAD_NUMBERS,
  SET_TENANT,
  RESET_LOCATION,
} from 'actions/typeConstants';
import get from 'lodash.get';
import { formatNumber } from 'utils/phoneNumbers';
import { parseError } from 'utils/misc';

const initialState = {
  phoneNumberData: null,
  pending: true,
  selectedPhoneNumber: null,
  phoneNumberDetails: null,
  phoneNumberHistory: null,
  availableNumbers: [],
  stateCities: [],
  cityInputDisplayStyle: 'none',
  addedNewNumber: false,
  editedNumber: false,
  deleted: false,
  leftBarDataPrepared: false,
  location: null,
  successflag: null,
  failureflag: null,
  reloadDetails: null,
  purchaseSuccess: false,
  reloadAvailableNumbers: false,
  tenant: null,
  notRemovedNumbers: null,
}

export default function(state = initialState, action){

  switch(action.type){
    case SELECT_PHONE_NUMBER:
      return {...state, selectedPhoneNumber: action.payload}
    case 'PHONENUMBERS_HTTP_SUCCESS':
      if(action.auditHistory){
        return {...state, phoneNumberHistory: action.data, historyloading: false, }
      }
      else if(action.reqObject){
        return {...state, phoneNumberDetails: action.data, reloadDetails: 'store' }
      }
      return {...state, phoneNumberData: action.data, pending: false, addedNewNumber: false, leftBarDataPrepared: false, }
    case 'PHONENUMBERS_HTTP_FAILURE':
      return{...state, successflag: null, failureflag: parseError(action.error.response.text)}
    case 'PHONENUMBERSEARCH_HTTP_SUCCESS':
      return {...state, availableNumbers: action.data, reloadAvailableNumbers: true }
    case 'PHONENUMBERSEARCH_HTTP_FAILURE':
      return{...state, successflag: null, failureflag: parseError(action.error.response.text)}
    case 'STATECITYSEARCH_HTTP_SUCCESS':
      return {...state, stateCities: action.data, cityInputDisplayStyle: ''}
    case 'STATECITYSEARCH_HTTP_FAILURE':
      return{...state, successflag: null, failureflag: parseError(action.error.response.text)}
    case 'SEARCHBYTOLLFREE_HTTP_SUCCESS':
      return {...state, availableNumbers: action.data, cityInputDisplayStyle: 'none', reloadAvailableNumbers: true }
    case 'SEARCHBYTOLLFREE_HTTP_FAILURE':
      return{...state, successflag: null, failureflag: parseError(action.error.response.text)}
    case 'BYUNUMBERS_HTTP_SUCCESS':
      return {...state, availableNumbers: [], cityInputDisplayStyle: 'none', 
        phoneNumberData: {...state.phoneNumberData, data: [...state.phoneNumberData.data, action.data]}, addedNewNumber: true, 
          availableNumbers:[],successflag: null, failureflag: null, purchaseSuccess: true }
    case 'BYUNUMBERS_HTTP_FAILURE':
      return{...state, successflag: null, failureflag: parseError(action.error.response.text)}
    case LEFT_BAR_DATA_PREPARE:
      return {...state, leftBarDataPrepared: true}
    case 'LOCATIONS_HTTP_SUCCESS':
      return {...state, location: action.data,}
    case 'LOCATIONS_HTTP_FAILURE':
      return{...state, successflag: null, failureflag: parseError(action.error.response.text)}
    case 'EDITPHONENUMBERS_HTTP_SUCCESS':
      // Search for the edited phone number & change it in the store too
      let data = [...state.phoneNumberData.data];
      let position = data.findIndex(item => item.phonenumber === action.data.phonenumber);

      let selectedNumber = action.reqObject;
      data.forEach((phoneNumber, index) => {
        if (phoneNumber.associated_temporary_number === selectedNumber){
          data[index]['associated_temporary_number'] = "";
        }
      })

      //If we add an associated_permanent_number, we need to write to the associated_temporary_number 
      //field for each of those permanent numbers
      if (action.data && action.data.associated_permanent_numbers){
        action.data.associated_permanent_numbers.forEach((number) => {
          let permanentNumberIndex = data.findIndex(item=> item.phonenumber === number);
          data[permanentNumberIndex]['associated_temporary_number'] = action.data.phonenumber;
        })
      }

      // Setting Not assigned to a number doesn't return all the information of that number in payload.
      // As the phonenumber isn't returned from API we have to search again using state value and
      // update with the information got from the payload.

      if (position === -1) {
        position = data.findIndex(item => item.phonenumber === state.selectedPhoneNumber)
        data[position] = { ...data[position], ...action.data };
      } else {
        data[position] = action.data;
      }
      return {...state, phoneNumberDetails: {...data[position], config: state.phoneNumberDetails.config}, editedNumber: true, 
        availableNumbers:[],  successflag: `Successfully updated ${formatNumber(action.reqObject)}`, failureflag: null, reloadDetails: 'store',
        phoneNumberData: {data}}
    case 'EDITPHONENUMBERS_HTTP_FAILURE':
      return{...state, successflag: null, failureflag: parseError(action.error.response.text)}
    case'DELETEPHONENUMBERS_HTTP_SUCCESS':
      // Search for the deleted phone number & delete it from the store too
      let numbers = state.phoneNumberData.data;
      action.reqObject.forEach(phoneNumber => {
        numbers = numbers.filter((item) => item.phonenumber !== phoneNumber)
      })
      const newData = numbers.filter((item) => item.phonenumber !== action.reqObject)
      return {...state, phoneNumberData: {data: newData}, deleted: true, availableNumbers:[],successflag: `Successfully deleted ${formatNumber(action.reqObject)}`, failureflag: null }
    case'DELETEPHONENUMBERS_HTTP_FAILURE':
      return{...state, successflag: null, failureflag: parseError(action.error.response.text)}
    case PHONENO_LOADING_RESET:
      return {...state, phoneNumberDetails: null, phoneNumberHistory: null}
    case 'TIMEMACHINE_HTTP_SUCCESS':
      return {...state, successflag: 'Success!', failureflag: null}
    case 'TIMEMACHINE_HTTP_FAILURE':
      return{...state, successflag: null, failureflag: parseError(action.error.response.text)}
    case 'DELETEFILEPHONENUMBERS_HTTP_SUCCESS':
      return {...state, successflag: 'Successfully deleted', failureflag: null, reloadDetails: 'fetch'}
    case 'DELETEFILEPHONENUMBERS_HTTP_FAILURE':
      return{...state, successflag: null, failureflag: parseError(action.error.response.text)}
    case 'EDITUPLOAD_HTTP_SUCCESS':
      return {...state, successflag: 'Successfully uploaded', failureflag: null, reloadDetails: 'fetch'}
    case "CLEARALLPORTINGFLAGS_HTTP_SUCCESS":
      //Let's update the porting flags to flip them all off 
      let phoneNumberData = {...state.phoneNumberData};
      phoneNumberData.data.forEach((item, index)=>{
        if (item.porting_in_progress === "1"){
          phoneNumberData.data[index].porting_in_progress = "0"
        }
      });
      let newPhoneNumberData = phoneNumberData.data;
      return {...state, phoneNumberData: {data:newPhoneNumberData}, successflag: 'Cleared all porting flags', failureflag: null, reloadDetails:'fetch'}

    case "BULKEDITPHONENUMBER_HTTP_SUCCESS":

      let statePhoneNumberData = {...state.phoneNumberData};
      action.data.forEach(item => {
        let stateIndex = statePhoneNumberData.data.findIndex(stateItem=> stateItem.phonenumber === item.phonenumber);
        statePhoneNumberData.data[stateIndex] = item;
      })

      let bulkData = [...state.phoneNumberData.data];
      const targetDetailNumber = action.data[0].phonenumber;
      let detailNumber = bulkData.filter(number => number.phonenumber === targetDetailNumber);
      return {...state, phoneNumberDetails: {...detailNumber[0], config: state.phoneNumberDetails.config}, phoneNumberData: statePhoneNumberData, successflag: 'You have edited all selected phone numbers', loading: false, failureflag: null}  

    case "BULKEDITPHONENUMBER_HTTP_FAILURE":
      return {...state, successflag: null, failureflag: action.error.response.body.error.message}

    case "DISASSOCIATENUMBERS_HTTP_SUCCESS":
        //Let's update the temporary flags to flip them all off 
        let associatedPhoneNumbers = [...state.phoneNumberData.data].filter(phoneNumber => {
          return phoneNumber.temporary == "0";
        });

        return {...state, phoneNumberData: {data:associatedPhoneNumbers}, successflag: 'Removed all temporary numbers', failureflag: null, reloadDetails:'fetch'}

    case 'EDITUPLOAD_HTTP_FAILURE':
      return{...state, successflag: null, failureflag: parseError(action.error.response.text)}
    case RESET_FLAG:
      return {...state, successflag: null, failureflag: null, purchaseSuccess: false, editedNumber: null, deleted: false}
    case RELOAD_DETAILS:
      return {...state, reloadDetails: action.data ? action.data : null}
    case RELOAD_NUMBERS:
      return {...state, reloadAvailableNumbers: false}
    case SET_TENANT:
      return {...state, tenant: action.data}
    case RESET_LOCATION:
      return {...state, location: null}
    default:
      return {...state}
  }
}