import React from 'react';
import ReactTable from 'react-table';
import Checkbox from '@material-ui/core/Checkbox';
import 'react-table/react-table.css';
import Loading from '../Loading';
import PropTypes from 'prop-types';
/*
    This is a reusable table component. 
    The required props:
        * data: An array of objects, each key in the object will corrispond to a separate column
        * headers: An array of objects that will define your headers. 
                   It needs to have two keys: Header: the title of the column and accessor: the value of the column
                   Optional key: maxWidth: will define how wide your column will be.
    Other props:
        * checked: boolean --> will define if the box is checked or not
        * disabled: boolean --> will make the checkbox disabled
        * id: string --> needs to relate to a unique value in your data object
        * onChange: function --> the function that will fire upon clicking the checkboxes
        * rowHeight: css height value (string) --> will define the height of the rows
        * tableType: string --> can be 'checkbox', will make the first column checkboxes
        * tableTypeTitle: string --> will be the title of the first column
        * tableTypeTitleWidth: number --> will be the width of the first column
*/
const FluentReactTable = ({
    checked = null,
    data = null,
    disabled = null,
    headers = [{ Header: 'no data', accessor: 'nodata' }],
    id = null,
    onChange = null,
    rowHeight = '50px',
    tableType = null,
    tableTypeTitle = 'enter a title',
    tableTypeTitleWidth = 85,
}) => {
    const checkProps = () => {
        const path = '/v4/js/containers/shortcodes/reactTable for the FluentReactTable component.';
        if (tableType == 'checkbox') {
            if (!disabled) {
                console.error(
                    `Error in ${path}\n The disabled prop must be defined for tableType='checkbox'.\n The`,
                    `disabled prop needs to be a string that relates to a key in your data object. This will`,
                    `make the checkbox disabled or not.\n Example: "disabled='myDisabledKey'" data --> {key1: 'value1', myDisabledKey: true}`,
                );
                return null;
            }
            if (!checked) {
                console.error(
                    `Error in ${path}\n The checked prop must be defined for tableType='checkbox'.\n The`,
                    `checked prop needs to be a string that relates to a key in your data object. This will`,
                    `check or uncheck the checkbox.\n Example: "checked='myCheckedKey'" data --> {key1: 'value1', myCheckedKey: true}`,
                );
                return null;
            }
            if (!id) {
                console.error(
                    `Error in ${path}\n The id prop must be defined for tableType='checkbox'.\n The id prop`,
                    `needs to be a string that relates to a unique value in your data object.`,
                );
                return null;
            }
            if (!onChange) {
                console.error(
                    `Error in ${path}\n The onChange prop must be defined for tableType='checkbox'.\n The`,
                    `onChange prop needs to be a function that will capture the state of the checkbox.\n`,
                    `Example: onChange={(e) => myHandleChangeFunction(e.target.name)}`,
                );
                return null;
            }
        }
        if (typeof rowHeight !== 'string') {
            console.error(`Error in ${path}\n The rowHeight prop must be a string.`);
            return null;
        }
        return true;
    };
    const getType = () => {
        switch (tableType) {
            case 'checkbox':
                return data => {
                    const keys = Object.keys(data).join(', ');
                    if (data[id] === undefined) {
                        console.error(
                            `Please make sure the id prop exists and is the same as a key in your data`,
                            `object.\n You entered id='${id}'. Expecting: ${keys}`,
                        );
                        return null;
                    }
                    if (data[disabled] === undefined) {
                        console.error(
                            `Please make sure the disabled prop exists and is the same as a key in your data`,
                            `object.\n You entered disabled='${disabled}'. Expecting: ${keys}`,
                        );
                        return null;
                    }
                    if (data[checked] === undefined) {
                        console.error(
                            `${data[checked]}Please make sure the checked prop exists and is the same as a key in your data`,
                            `object.\n You entered checked='${checked}'. Expecting: ${keys}`,
                        );
                        return null;
                    }

                    return (
                        <Checkbox
                            checked={data[checked]}
                            color="primary"
                            disabled={data[disabled]}
                            id={data[id]}
                            onChange={onChange}
                            indeterminate={data[disabled]}
                        />
                    );
                };
            default:
                return null;
        }
    };
    const getHeaders = () => {
        let tableHeaders;
        try {
            tableHeaders = headers.map((header, index) => {
                let keys = Object.keys(header);
                if (!keys[0]) {
                    throw console.error(`Please make sure your headers objects have keys`);
                }
                if (!keys[1]) {
                    throw console.error(
                        `Please make sure your header object has a second key of "accessor".`,
                    );
                }
                if (keys[0] !== 'Header') {
                    throw console.error(
                        `The key "${keys[0]}" is not a valid key for the object at index ${index}, please use "Header" as the first key in the headers array.`,
                    );
                }
                if (keys[1] !== 'accessor') {
                    throw console.error(
                        `The key "${keys[1]}" is not a valid key for the object at index ${index}, please use "accessor" as the second key in the headers array.`,
                    );
                }
                if (keys[2] && keys[2] !== 'maxWidth') {
                    throw console.error(
                        `The key "${keys[2]}" is not a valid key for the object at index ${index}, please use "maxWidth" as the last key in the headers array.`,
                    );
                }
                if (keys[2] && !Number(header[keys[2]])) {
                    throw console.error(
                        `The value ${
                            header[keys[2]]
                        } is not a number for the object at index ${index} in your headers array.`,
                    );
                }
                return { Header: header[keys[0]], accessor: header[keys[1]], ...header };
            });
        } catch (e) {
            tableHeaders = [];
            console.warn(`Catch Error: ${e}`);
        }
        const makeTableType = getType();
        if (makeTableType) {
            const firstColumn = {
                Header: tableTypeTitle,
                id: 'full',
                accessor: makeTableType,
                maxWidth: tableTypeTitleWidth,
            };
            tableHeaders.unshift(firstColumn);
        }
        return tableHeaders;
    };
    if (checkProps()) {
        return (
            <ReactTable
                className="-highlight"
                columns={getHeaders()}
                data={data}
                getTrProps={(state, rowInfo, column, instance) => {
                    return {
                        style: {
                            background: rowInfo
                                ? !rowInfo.original.checked
                                    ? 'rgba(153, 16, 60, 0.26)'
                                    : null
                                : null,
                            height: rowHeight,
                            alignItems: 'center',
                        },
                    };
                }}
            />
        );
    } else {
        return <Loading />;
    }
};

const headerPropObj = PropTypes.shape({
    Header: PropTypes.string.isRequired,
    accessor: PropTypes.string.isRequired,
});

FluentReactTable.defaultProps = {
    headers: [{ Header: 'no data', accessor: 'nodata' }],
    data: [],
};

FluentReactTable.propTypes = {
    checked: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    disabled: PropTypes.string,
    headers: PropTypes.arrayOf(headerPropObj).isRequired,
    id: PropTypes.string,
    onChange: PropTypes.func,
    rowHeight: PropTypes.string,
    tableType: PropTypes.string,
    tableTypeTitleWidth: PropTypes.number,
};

export default FluentReactTable;
