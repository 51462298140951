import React from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/styles';
import { ListItemIcon } from '@material-ui/core';

const SelectInput = (props) => {
    const style = {
        selectFieldWrapper: {
            padding: props.paddingOverride,
            paddingBottom: props.paddingBottom,
        },
        selectField:{
            fontSize: props.fontSizeOverride,
            width: props.widthOverride,
        }
    };
    //allow parent to override styles
    if(props && props.style && props.style.override){
      Object.entries(props.style.override).forEach( (elementToOverride) => {
        if(style[elementToOverride[0]]){
          Object.keys(props.style.override[elementToOverride[0]]).forEach( (key)=>{
            style[elementToOverride[0]][key] = props.style.override[elementToOverride[0]][key];
          })
        }
      })
    }

    const classes = makeStyles({
      selectClass: {
        ...style.selectField,
        ...props.style,
        display: "flex",
      },
      menuItem: {
        fontSize: '100%',
      },
    })();

    return (
      <div style={style.selectFieldWrapper}>
        <FormControl error={props.errorText ? true : false} fullWidth={true}>
          <InputLabel shrink={Boolean(props.value)}>{props.placeholder}</InputLabel>
          <Select
            classes={{ root: classes.selectClass }}
            disabled={props.disabled}
            id={`slider_input_${props.field}`}
            name={props.field}
            onChange={(e) => props.onChange(e.target.value)}
            value={props.value || ''}
            multiple={props.multiple}
          >
            {props.options.map((option) => {
              // If we have a property of value, but no value or label,
              // we don't want to use this option
              if (option.hasOwnProperty('value') && (!option.value || !option.label)) {
                  return null;
              }

                if (option.hasOwnProperty('value') && option.value && option.label) {
                    return (
                      <MenuItem
                        value={option.value}
                        classes={{ root: classes.menuItem }}
                        key={option.value}
                      >
                        {option.icon && 
                          <>
                            <ListItemIcon>
                              {option.icon}
                            </ListItemIcon>
                            <span style={{marginTop:3}}>{option.label} </span>
                          </>
                        }
                        {!option.icon && option.label}
                      </MenuItem>
                    );
                }

                return (
                  <MenuItem
                    value={option}
                    classes={{ root: classes.menuItem }}
                    key={option}
                  >{option}</MenuItem>
                );
            })}
          </Select>
          <FormHelperText>{props.errorText}</FormHelperText>
        </FormControl>
      </div>
    );
}
SelectInput.defaultProps = {
    errorText: '',
    hintText: '',
    placeholder: '',
    onChange: () => console.log('No onChange func provided for textinput'),
    options: ['No value'],
    paddingBottom: 30,
    multiple: false,
    fontSizeOverride: '16px',
    paddingOverride: '0 50px 0 50px',
    widthOverride: '100%',
    paddingBottom: ''
};

SelectInput.propTypes = {
    widthOverride: PropTypes.string,
    paddingOverride: PropTypes.string,
    fontSizeOverride: PropTypes.string,
    errorText: PropTypes.string,
    field: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    options: PropTypes.array,
    paddingBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    multiple: PropTypes.bool,
};

export default SelectInput;
