import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Input from 'react-phone-input-intl';
import 'react-phone-input-intl/dist/style.css';

const PhoneInputForForwardingNoAdd = (props) => {
  const [value, setValue] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [changeCountry, setChangeCountry] = useState(false);

  const style = {
    wrapper: {
      paddingLeft: window.matchMedia('(min-width: 800px)').matches ? 50 : 20,
      paddingBottom: 30,
    },
    phoneInputContainerStyle: {
      width: 'auto',
    },
  };

  const onChange = (val, data) => {
    if (changeCountry) {
      if (data.dialCode === '1') {
        setValue('');
      } else {
        setValue('+' + data.dialCode);
      }
      setChangeCountry(false);
    } else if (data.dialCode === '1' || val.startsWith('+') || val === '') {
      setValue(val);
    } else {
      setValue('+' + val);
    }

    if (selectedCountryCode !== '+' + data.dialCode) {
      if (data.dialCode !== '1') {
        setSelectedCountryCode('+' + data.dialCode);
      } else if (selectedCountryCode !== '') {
        setSelectedCountryCode('');
      }
    }
    props.onChange(val, data)
  }

  const changeCountryCode = (countryCode) => {
    if (countryCode === '1') {
      if (value !== '') {
        setValue('');
        setChangeCountry(true);
      }
    } else {
      setValue("+" + countryCode);
      setChangeCountry(true);
    }
  }

  return (
    <div style={style.wrapper}>
      <Input
        placeholder={props.placeholder}
        value={props.value}
        defaultCountry={'us'}
        autoFormat={false}
        disableCountryCode={true}
        onChange={onChange}
        getCountryCode={changeCountryCode}
        containerStyle={style.phoneInputContainerStyle}
      />
    </div>
  );
}

PhoneInputForForwardingNoAdd.defaultProps = {
  placeholder: '',
  value: '',
  type: '',
  onChange: () => console.log('No onChange func provided for textinput'),
}

PhoneInputForForwardingNoAdd.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  getCountryCode: PropTypes.func,
  onChange: PropTypes.func,
}

export default PhoneInputForForwardingNoAdd;