import React, { Component } from 'react';
import { connect } from 'react-redux';


import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/styles';

import DetailContainer from 'presentational/DetailContainer';
import LeftBar from 'presentational/LeftBar';
import MasterView from 'presentational/MasterView';

import LoginManagementHelp from './help';
import LoginManagementSettings from './settings';


import {
    isBlank,
    validateEmail,
    validateExtension,
    validateFirstLastName,
    validatePasswords,
    validateUserGroup,
    validateUserName
} from './validation';


import { deleteCoreHttp, getCoreHttp, postCoreHttp, putCoreHttp } from 'actions/httpRequest';

import { selectLogin } from 'actions/loginManagement';
import { errorNotification, successNotification } from 'actions/notifications';

const styles = () => ({
    menuItem: {
        fontSize: '100%',
    },
});

class LoginManagement extends Component {

    constructor(props) {
        super(props);

        let defaultPassword = this.genDefaultPasword();

        this.state = {
            leftBarData: null,
            route: null,
            selectedLogin: null,
            newUserForm: {
                newPassword: defaultPassword,
                confirmPassword: defaultPassword,
                extension: '000',
                groups: 'Cloud User'
            },
            closeMenu: false,
        };
    }

    componentDidMount() {

        this.setState({
            route: null,
            selectedLogin: null,
        });

        this.props.selectLogin('');

        this.fetchloginManagement();

    }

    genDefaultPasword() {
        // Password requires at least 1 number - make sure  always at least 1 numeric value
        let mandatoryInt = Math.floor(Math.random() * (10 - 1) + 1);

        // Generate 8 random characters - math.random will gen a decimal so slice out the first 2 slots and enforce a length of 10 - 2
        let randomString = Math.random().toString(36).slice(2, 10);

        return mandatoryInt + randomString;
    }

    componentWillUnmount() {

        this.setState({
            route: null,
            selectedLogin: null,
        });
        this.props.selectLogin('');
    }

    componentWillReceiveProps(nextProps) {
        // Lets error handle new user creation
        if (this.state.route === 'New' && nextProps.httpStatus.failed && nextProps.httpStatus.failed === true) {
            let error = 'Error creating user';
            if (nextProps.httpStatus.error) {
                error = nextProps.httpStatus.error;
            }
            return errorNotification({ title: 'Error Creating User', message: error });
        }
        else if (
            this.state.route === 'New' &&
            nextProps.httpStatus.success &&
            nextProps.httpStatus.success === true &&
            nextProps.httpStatus.reqType === "POST"
        ) {

            successNotification({
                title: 'Success!',
                message: `Created User`,
            });
            let defaultPassword = this.genDefaultPasword();
            // Lets set state to clear the route and selected user
            this.setState({
                route: '',
                selectedLogin: null,

                newUserForm: {
                    newPassword: defaultPassword,
                    confirmPassword: defaultPassword,
                    extension: '000',
                    groups: 'Cloud User'

                }
            });
            this.props.selectLogin('');
        }

        // If the new loginManagement object is different (or if we
        // didn't have one before) lets get the data ready for leftbar
        if (nextProps.loginManagement && this.props.loginManagement !== nextProps.loginManagement) {

            this.prepareDataForLeftBar(nextProps.loginManagement);
        }

        if (nextProps.selectedLoginName && nextProps.loginManagement) {

            let selectedLogin = nextProps.selectedLoginName

            // If we have a new user account, handle this here
            if (nextProps.selectedLoginName === 'New Login') {
                this.setState({ selectedLogin: { name: 'New Login' } });
                return;
            }

            if (!selectedLogin) {
                console.error('No login found by the name of: ', nextProps.selectedLoginName);
                return;
            }

            // If the name has changed, lets reset the route here
            if (nextProps.selectedLoginName !== this.props.selectedLoginName || nextProps.loginManagement.length !== this.props.loginManagement.length) {
                this.setState({
                    selectedLogin,
                    route: '',
                });
            } else {
                this.setState({ selectedLogin });
            }
        }

    }

    // fetchLogins acquires user data via redux api
    fetchloginManagement() {

        // Lets set up the request data to retreive user data
        const reqData = {
            reqAction: 'loginmanagement',
        };

        const storeKey = 'loginManagement';

        this.props.getCoreHttp(reqData, storeKey);
    }

    // prepareDataForLeftBar maps through all user data
    // via http and formats data objects to be consumed by the leftBar
    prepareDataForLeftBar(data) {

        console.log('preparing data for leftbar');
        // return on no data
        if (!data) {
            return console.log('No  users returned');
        }

        // array we will push our new object into
        const leftBarData = [];
        data.map((item) => {
            // Set the title to friendly name if we have it, otherwise just the name
            const title = item.userName;
            const subtitle = `${item.extension}, ${item.firstName}, ${item.lastName}`;
            const id = item.userName;

            // push the data into the leftBar array
            return leftBarData.push({ id, title, subtitle });
        });

        this.setState({ leftBarData: null });

        // finally, lets set the leftBarData to state to update leftbar
        return this.setState({ leftBarData });
    }

    leftBarSelect(login) {

        this.props.selectLogin(login);

        this.getLoginDetail(login);

        this.setState({ route: 'settings' });

        this.navigate('Settings');
    }

    getLoginDetail(login) {

        const reqData = {
            loginData: true,
            reqAction: 'loginmanagement',
            reqObject: encodeURIComponent(login)
        };

        const storeKey = 'loginManagement';

        this.props.getCoreHttp(reqData, storeKey);
    }

    handleAddNewClick() {

        this.props.selectLogin('New Login');
        this.navigate('New');
    }


    onCancel() {
        this.clearNewUserCreation();
        this.props.selectLogin('');
    }

    async handleDeleteUserLogin() {

        if (!this.props.selectedLoginName) {
            return;
        }

        const userLogin = this.props.selectedLoginName;

        const reqData = {
            reqAction: 'loginmanagement',
            reqObject: encodeURIComponent(userLogin),
        };

        const storeKey = 'loginManagement';

        await this.props.deleteCoreHttp(reqData, storeKey);

        // Lets set state to clear the route and selected user
        this.setState({
            route: '',
            selectedLogin: null,
        });
        this.props.selectLogin('');

        // Lets trigger a notification
        successNotification({
            title: 'Success!',
            message: `Deleted ${userLogin}`,
        });
    }

    async submitUpdateUserForm(data) {

        if (!data) {
            return
        }

        let form = Object.assign({}, data);
        this.setState({ form });

        if (validateFormFields(form, this.props, false)) {
            return;
        }

        form.password = form.confPassword;

        // Set sendemail default:
        form.sendEmail = form.sendEmail ? "1" : "0";

        const storeKey = 'loginmanagement';

        // DEV-1756: added returnPromise to flag putCoreHttp() request to return a promise
        const reqData = {
            reqAction: storeKey,
            reqBody: form,
            reqObject: encodeURIComponent(form.userName)
        };

        await this.props.putCoreHttp(reqData, storeKey)
            .then((res) => {
                successNotification({
                    title: 'Success!',
                    message: `Updated User: ${this.state.selectedLogin}`,
                });

                // Lets set state to clear the route and selected user
                this.setState({
                    route: '',
                    selectedLogin: null,
                });
                this.props.selectLogin('');
            })
            .catch((err) => {
                console.log("Error Updating User: ", JSON.stringify(err))
                let errMsg = err?.response?.body?.error?.message;
                return errorNotification({ title: "Error Updating User", message: errMsg });
            });
    }

    async submitNewUserForm(data) {

        if (!data) {
            return
        }

        let form = Object.assign({}, data);

        form.mail = form.userName
        form.friendlyName = form.userName;
        form.password = form.confirmPassword;
        form.sendEmail = 0;
        delete form.New

        if (validateFormFields(form, this.props, true)) {
            return;
        }

        const storeKey = 'loginmanagement';

        // DEV-1756: added returnPromise to flag postCoreHttp() request to return a promise
        const reqData = {
            reqAction: storeKey,
            reqBody: form,
            reqObject: form.friendlyName
        };
        this.setState({form})
        
        await this.props.postCoreHttp(reqData, storeKey)
            //If we successfully POSTed the new user, serve the notification, reset everything back to defaults, 
            //and navigate back to the User Management landing page.
            .then((res) => {
                successNotification({
                    title: 'Success!',
                    message: `Created User: ${form.userName}`,
                });
                this.props.selectLogin('');
                this.fetchloginManagement();
                this.clearNewUserCreation();
            })
            //If we failed, stay on the new user page and serve an error notification.
            .catch((err) => {
                console.log('Error Creating User: ', JSON.stringify(err))
                let errMsg = err?.response?.body?.error?.message;
                errorNotification({ title: 'Error Creating User', message: errMsg });
            });

    }

    goToHelp() {
        const win = window.open('http://help.fluentcloud.com/support/solutions', '_blank');
        win.focus();
    }

    //We need to clear out all of the new user fields once a new user is created.
    clearNewUserCreation() {
        console.log("[[INFO] loginmanagement.clearnewusercreation] New User successfully created, cleaning up...");

        //Generate a new default password to attach to the "new" new user.
        let defaultPassword = this.genDefaultPasword();

        this.setState({
            route: null,
            selectedLogin: null,
            newUserForm: {
                userName: "",
                extension: "000",
                firstName: "",
                groups: "Cloud User",
                lastName: "",
                mail: "",
                newPassword: defaultPassword,
                confirmPassword: defaultPassword,
            }
        });

        return true;
    }

    handleUserUnblock() {

        if (app.token.get("role") !== "superduperadmin") {
            console.warn(`[[WARN] handleUserUnblock] Cannot unblock user as role ${app.token.get("role")}`);
            return false;
        }

        $.ajax({
            url: "/api/v1/master/" + app.token.get('tenant') + "/unblockuser/" + this.state.selectedLogin,
            type: 'get',
            success: function () {
                successNotification({
                    title: 'Success',
                    message: `User unblocked`,
                });
                return true;
            },
            error: function () {
                errorNotification({
                    title: "Failure",
                    message: "Unable to unblock user"
                });
                return false;
            }
        });
    }

    navigate(route) {

        this.setState({ route });
    }

    openCloseMenu() {
        this.setState({ closeMenu: !this.state.closeMenu });
    }

    renderContent() {
        const {
            loginManagement,
            selectedLoginName,
        } = this.props;

        const { route } = this.state;

        if (!selectedLoginName || !loginManagement) {
            return <LoginManagementHelp />;
        }

        if (route === 'New') {
            let data;
            if (this.state.newUserForm) {
                let { newUserForm } = this.state;
                newUserForm['New'] = true;
                data = newUserForm;
            } else {
                data = { 'New': true }
            }
            return (
                // Create a new user account
                <LoginManagementSettings
                    onCancel={() => this.onCancel()}
                    submitForm={(data) => this.submitNewUserForm(data)}
                    data={data}
                    newUser={true}
                />

            );
        } else {
            // Clear prior state
            let data = this.props.selectedLoginDetails;
            if (data && data.newPassword) {
                delete data.newPassword;
            }
            if (data && data.confirmPassword) {
                delete data.confirmPassword;
            }
            if (data && data.sendEmail) {
                delete data.sendEmail;
            }
            return (

                // Manage an existing user account
                <LoginManagementSettings
                    onCancel={() => this.onCancel()}
                    submitForm={(data) => this.submitUpdateUserForm(data)}
                    data={data}
                    disableNameChange={true}
                    newUser={false}
                />
            );
        }
    }

    render() {
        const {
            loginManagement,
            httpStatus,
            selectedLoginName,
            classes,
        } = this.props;
        const { leftBarData, route, selectedLogin } = this.state;
        let title = '';
        if (loginManagement) {

            if (selectedLogin && selectedLogin.userName) {
                title = selectedLogin.userName;
            }
        }

        const contextMenuOptions = [
          <MenuItem
            classes={{ root: classes.menuItem }}
            disabled={!selectedLogin}
            onClick={() => {this.handleDeleteUserLogin(); this.openCloseMenu();}}
            key={0}
          >
            Delete
          </MenuItem>,
          <MenuItem
            classes={{ root: classes.menuItem }}
            onClick={() => {this.goToHelp(); this.openCloseMenu();}}
            key={1}
          >Help</MenuItem>
        ];

        if (app.token.get("role") === "superduperadmin") {
            const unblockOption = <MenuItem
                classes={{ root: classes.menuItem }}
                onClick={() => { this.handleUserUnblock(); this.openCloseMenu(); }}
                key={1}
            >Unblock</MenuItem>
            contextMenuOptions.push(unblockOption);
        }

        return (
          <MasterView>
            <DetailContainer
              leftBar={
                <LeftBar
                  addNew={() => this.handleAddNewClick()}
                  data={leftBarData}
                  pending={httpStatus.pending}
                  noDescription
                  selected={selectedLoginName}
                  select={(id) => this.leftBarSelect(id)}
                  title="User/Login Management"
                />
              }
              title={title}
              route={route !== 'New' ? route : null}
              return={() => this.setState({ route: null })}
              contextMenuOptions={contextMenuOptions}
              closeMenu={this.state.closeMenu}
            >
              {this.renderContent()}
            </DetailContainer>
          </MasterView>
        );
    }

}

function validateFormFields(form, props, isCreateUser) {
    const errTitle = isCreateUser ? "Error Creating User" : "Error Updating User";
    const isInvalid = (method, ...params) => testIsInvalid(errTitle, method, ...params);

    // Validate Username on create user only:
    if (isCreateUser) {
        // check if username already exists
        if (props.loginManagement && !isBlank(form.userName)) {
            if (props.loginManagement.some(login => (login.userName === form.userName))) {
                errorNotification({ title: errTitle, message: "That username already exists" });
                return true
            }
        }
        // validate username
        if (isInvalid(validateEmail, form.userName)) {
            return true
        }
    }

    // Validate Email:
    if (form.mail && isInvalid(validateEmail, form.mail)) {
        return true
    }

    if (isBlank(form.mail)){
        errorNotification({
            title: "Failed",
            message: "Email cannot be blank"
        });
        return true;
    }

    // Validate User Group:
    if (isInvalid(validateUserGroup, form.groups, app.token.get("role"))) {
        return true;
    } else {
        const map = {
            'Administrator': 'cloudadmin',
            'Cloud User': 'cloudusers',
            'Partner Admin': 'partneradmin'
        }
        console.log('map[form.groups]', map[form.groups]);
        form.groups = [map[form.groups]];
    }

    // Validate Extension:
    if (isInvalid(validateExtension, form.extension)) {
        return true
    } else {
        if (!Array.isArray(form.extension)) {
            form.extension = [form.extension]
        }
    }

    // Validate first, last name
    if (isInvalid(validateFirstLastName, form.firstName, form.lastName)) {
        return true;
    }

    // Validate Passwords on create new user or on edit user when either password fields have a value
    if (isCreateUser || !(isBlank(form.newPassword) && isBlank(form.confirmPassword))) {
        if (isInvalid(validatePasswords, form.newPassword, form.confirmPassword)) {
            return true;
        } else {
            form.confPassword = form.confirmPassword;
            delete form.newPassword;
            delete form.confirmPassword;
        }
    }
}

// helper to test, display error, and return true if invalid
function testIsInvalid(errorTitle, method, ...params) {
    let errMsg = method(...params);
    if (errMsg) {
        errorNotification({ title: errorTitle, message: errMsg });
        return true;
    }
    return false;
}

const bindActions = (dispatch) => ({
    deleteCoreHttp: (reqData, storeKey) => dispatch(deleteCoreHttp(reqData, storeKey)),
    getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
    postCoreHttp: (reqData, storeKey) => dispatch(postCoreHttp(reqData, storeKey)),
    putCoreHttp: (reqData, storeKey) => dispatch(putCoreHttp(reqData, storeKey)),
    selectLogin: (user) => dispatch(selectLogin(user)),
});

const mapStateToProps = (state) => ({
    selectedLoginDetails: state.loginManagement.loginData,
    loginManagement: state.loginManagement.data,
    selectedLoginName: state.loginManagement.selected,
    httpStatus: state.loginManagement.httpStatus,
});

export default connect(mapStateToProps, bindActions)(withStyles(styles)(LoginManagement));

