import React from 'react';

import AutoAttendants from './containers/autoAttendants';
import CallGroups from './containers/callGroup';
import ConferenceRecords from './containers/conferenceRecords';
import DynamicAgents from './containers/dynamicAgents';
import NotificationWorkflows from './containers/notificationWorkflows';
import IncomingCallFilters from './containers/incomingCallFilters';
import LoginManagement from './containers/loginManagement';
import MulticastGroups from './containers/multicast';
import ParkingLots from './containers/parkingLots';
import PostCallSurveys from './containers/postCallSurveys';
import QueueRecords from './containers/queueRecords';
import Reports from './containers/reports';
import Sms from './containers/sms';
import VoicemailBoxes from './containers/voicemailBoxes';
import ConferenceRooms from './containers/conferenceRoom';
import ManagedRoutes from './containers/managedRoutes';
import PhoneNumbers from './containers/phoneNumbers';
import VoicemailInbox from './containers/voicemailBoxes/voicemailInbox';
import Greetings from './containers/voicemailBoxes/greetings';
import MailboxSettings from './containers/voicemailBoxes/settings';
import PhoneOverrides from './containers/phoneOverrides';
import CallBlocking from './containers/callBlocking';
import CallForwarding from './containers/callForwarding'
import CallRecording from './containers/callRecording';
import ZohoIntegration from './containers/zohoIntegration';
import ShortCodes from './containers/shortCodes';
import CallScreening from './containers/callScreening';
import UserPortal from './containers/userPortal';
import PhoneNumbersManager from './containers/phoneNumbersManager';
import IdeasPortalLogin from './containers/ideasPortalLogin';
import VoicemailDrop from './containers/voicemailDrop';
import UserSettings from './containers/userSettings';
import FaxCreate from './containers/faxCreate';
import FaxInbox from './containers/faxInbox';
import TenantChangesReport from './containers/tenantChangesReport';

const handlers = {
    autoattendant: <AutoAttendants />,
    callgroups: <CallGroups />,
    conferencesummary: <ConferenceRecords />,
    dynamicagents: <DynamicAgents />,
    incomingcallfilters: <IncomingCallFilters />,
    loginmanagement: <LoginManagement />,
    multicastgroups: <MulticastGroups />,
    mycallhistory: <Reports />,
    notificationworkflows: <NotificationWorkflows />,
    'notificationworkflows/:id': <NotificationWorkflows />,
    incomingcallfilters: <IncomingCallFilters />,
    phoneoverrides: <PhoneOverrides />,
    postcallsurveys: <PostCallSurveys />,
    queuerecords: <QueueRecords />,
    sms: <Sms />,
    voicemailboxes: <VoicemailBoxes />,
    voicemailinbox: <VoicemailInbox />,
    voicemailgreetings: <Greetings />,
    voicemailsettings: <MailboxSettings />,
    'voicemailsettings/:mailbox': <MailboxSettings />,
    parkinglots: <ParkingLots />,
    conferencerooms: <ConferenceRooms />,
    managedroutes: <ManagedRoutes />,
    'managedroutes/:name': <ManagedRoutes />,
    'conferencerooms/:confno': <ConferenceRooms />,
    phonenumbers: <PhoneNumbers />,
    'phonenumbers/:number': <PhoneNumbers />,
    callblocking: <CallBlocking />,
    callforwarding: <CallForwarding />,
    callrecording: <CallRecording />,
    'zohointegration': <ZohoIntegration />,
    callscreening: <CallScreening />,
    'userportal': <UserPortal />,
    usersettings: <UserSettings />,
    shortcodes: <ShortCodes />,
    'shortcodes/:code': <ShortCodes />,
    dids: <PhoneNumbersManager />,
    'dids/:did': <PhoneNumbersManager />,
    ideasPortalLogin: <IdeasPortalLogin />,
    'voicemailrecording': <VoicemailDrop />,
    'voicemailrecording/:recordingId': <VoicemailDrop />,
    'fax-create': <FaxCreate />,
    'fax-inbox': <FaxInbox />,
    tenantchangesreport: <TenantChangesReport />
};

export default class ReactRouter extends Backbone.Router {
    constructor() {
        super();

        this.routes = {
            autoattendant: 'autoattendant',
            callgroups: 'callgroups',
            conferencesummary: 'conferencesummary',
            dynamicagents: 'dynamicagents',
            incomingcallfilters: 'incomingcallfilters',
            loginmanagement: 'loginmanagement',
            multicast: "multicastgroups",
            mycallhistory: 'mycallhistory',
            parkinglots: 'parkinglots',
            notificationworkflows: 'notificationworkflows',
            'notificationworkflows/:id': 'notificationworkflows/:id',
            incomingcallfilters: 'incomingcallfilters',
            phoneoverrides: 'phoneoverrides',
            postcallsurveys: 'postcallsurveys',
            queuerecords: 'queuerecords',
            reactvoicemail: 'voicemailboxes',
            sms: 'sms',
            conferencerooms: 'conferencerooms',
            voicemailboxes: 'voicemailboxes',
            voicemailinbox: 'voicemailinbox',
            'voicemailinbox/:mailbox': 'voicemailinbox/:mailbox',
            voicemailsettings: 'voicemailsettings',
            'voicemailsettings/:mailbox': 'voicemailsettings/:mailbox',
            voicemailgreetings: 'voicemailgreetings',
            'voicemailboxes/:mailbox': 'voicemailboxes/:mailbox',
            managedroutes: 'managedroutes',
            'managedroutes/:name': 'managedroutes/:name',
            'conferencerooms/:confno': 'conferencerooms/:confno',
            phonenumbers: 'phonenumbers',
            'phonenumbers/:number': 'phonenumbers/:number',
            callblocking: 'callblocking',
            callforwarding: 'callforwarding',
            callrecording: 'callrecording',
            'zohointegration': 'zohointegration',
            callscreening: 'callscreening',
            'userportal': 'userportal',
            usersettings: 'usersettings',
            shortcodes: 'shortcodes',
            'shortcodes/:code': 'shortcodes/:code',
            dids: 'dids',
            'dids/:did': 'dids/:did',
            ideasPortalLogin: 'ideasPortalLogin',
            'voicemailrecording': 'voicemailrecording',
            'voicemailrecording/:recordingId': 'voicemailrecording/:recordingId',
            'fax-create': 'fax-create',
            'fax-inbox': 'fax-inbox',
            tenantchangesreport:'tenantchangesreport',
        };

        this._bindRoutes();
    }
}

export const getHandler = route => handlers[route];
