import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
  slider: {
      marginLeft: 50,
      marginRight: 50
  }
});

function valuetext(value) {
  return `${value}`;
}

export default function DiscreteSlider(props) {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    props.onChange(parseInt(newValue))
  }

  return (
    <div>
      <Typography 
        id="discrete-slider" 
        gutterBottom
        align="inherit"
        color='textSecondary'
        >
        {props.placeholder}
      </Typography>
      <div className={classes.slider}>
      <Typography 
        variant="caption"
        color="textSecondary"
        gutterBottom
      >
      Value indicating the relative strength of emotion in the voicemail based on length and sentiment score
      </Typography>
      <Slider
        defaultValue={6}
        onChange ={handleChange}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={1}
        marks={props.marks}
        min={0}
        max={12}
      />
    </div>
    </div>
  );
}