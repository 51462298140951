import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/styles';

import TextInput from 'presentational/formControls/TextInput';
import SelectInput from 'presentational/formControls/SelectInput';
import ToggleInput from 'presentational/formControls/ToggleInput';
import SubmitButton from 'presentational/formControls/SubmitButton';


import { getOptionsForApplication } from './applicationObject';

const styles = () => ({
    textInput: {
        fontSize: '100%',
    },
});
class KeyCodeForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            prependEnabledToggle: props.form[`${props.number}_prepend-callerid`],
            applicationOptions: [{ value: 'none', label: 'Select one' }],
            selectionOptions: [{ value: 'none', label: 'None' }],
            argumentList: [{ value: 'none', label: 'None' }],
            selectedApplication: '',
        }

    }

    componentDidMount() {
        this.prepareApplicationField();
        
        let number = this.props.number;
        
        switch (this.props.number) {
            case 'n':
                number = 'none';
                break;
                case 'p':
                    number = 'pound';
                    break;
                    case 's':
                        number = 'star';
                        break;
                    }
        Object.keys(this.props.form).forEach(field =>{
            if(field.includes('application') && this.props.form[field] == "conferenceroom"){
                const keyNumber = field.slice(0,1);
                if(this.props.form[`${keyNumber}_value`] == ""){
                    this.props.form[`${keyNumber}_value`] = "No Room";
                }
            }
        });
            

        this.prepareSelectionField(this.props.form[`${number}_application`]);
    }

    // applicationField is filled in based
    // on the configurations
    prepareApplicationField() {

        const applicationOptions = [];
        if (this.props.applications && this.props.applications.length > 0) {
            this.props.applications.map((app) => {
                const application = {
                    value: app.name,
                    label: app.friendlyname
            };
            applicationOptions.push(application)
            });
        }
        this.setState({ applicationOptions });

    }

    // Selection is filled in based
    // on the application selected
    prepareSelectionField(selectedApplication) {

        // If we do not have configurations,
        // we cannot fill out the select box
        if (!this.props.configurations) {
            return;
        }

        const selectionOptions = getOptionsForApplication(selectedApplication, this.props.configurations, this.props.autoAttendants.data);
        this.setState({ selectionOptions, selectedApplication });

        if (selectedApplication === 'voicemailbox'){

            //If we select voicemailbox as an application, let's create an array of arguments to pick from 
            const argumentList = [
                {value: 'd', label:'Play instructions'},
                {value: 's', label:'Play nothing.'},
                {value: 'su', label:'Play unavailable message.'},
                {value: 'sb', label:'Play busy message.'},
                {value: 'u', label:'Play unavailable message, then instructions.'},
                {value: 'b', label: 'Play busy message, then instructions.'},
            ];

            this.setState({ argumentList });

        } else if (selectedApplication === 'zipcoderouter') {
            // If we select zipcoderouter as an application, let's create an array of all the routes to choose as arguments. 
            const argumentList = this.props.configurations.routes.map((route) => {
                return { value: route.friendlyname, label: route.friendlyname}
            })

            this.setState({ argumentList });

        } else {
            //if we dont select an application, lets just set this to an empty string
            return;
        }

    }

    //Bryan says we may need more of this n the future, for now just make sure we don't return none;
    validateSelection(selectionValue){

      switch(selectionValue){

        case 'none':
          return ""

        default:
          return selectionValue
      }
    }

    render() {

        const { form, number, updateForm, classes } = this.props;
        const selectedVoicemail = ( this.state.selectedApplication === 'voicemailbox' );
        const selectedZipCodeRouter = ( this.state.selectedApplication === 'zipcoderouter' );

        let { selectionOptions, argumentList } = this.state;

        switch (this.state.selectedApplication){
            case "extension":
            case "extensionfollowme":
            case "voicemailbox":
                selectionOptions.sort((a, b) => (parseInt(a.value)) > parseInt(b.value) ? 1 : -1);
                break;
            default:
                selectionOptions.sort((a, b) => (a.value.toLowerCase() > b.value.toLowerCase()) ? 1 : -1)
        }

        this.state.selectedApplication === "conferenceroom" ? selectionOptions.unshift({ value: 'No Room', label: 'No Room' }) : "";

        let key = number;

        switch (key) {
        case 'n':
            key = 'none';
        break;
        case 'p':
            key = 'pound';
            break;
        case 's':
            key = 'star';
        break;
        }
        return (
          <div style={{padding: '15px', display: 'flex', flexDirection: 'column'}}>
            <h4>Manage what happens when a caller presses a key in your auto attendant</h4>
            <div>
              <ToggleInput
                field="prepend-callerid"
                placeholder="Prepend caller ID"
                onChange={(val) => {
                    this.setState({ prependEnabledToggle: val });
                    updateForm(`${key}_prepend-callerid`, '');
                }}
                value={Boolean(this.state.prependEnabledToggle)}
              />
              {this.state.prependEnabledToggle ?
                <><br />
                <TextInput
                  field="prependvalue"
                  disabled={this.state.prependEnabled}
                  maxLength={10}
                  onChange={(val) => updateForm(`${key}_prepend-callerid`, val)}
                  value={form[`${key}_prepend-callerid`]}
                  classes={{ root: classes.textInput }}
                /></> : null
              }
            </div>
            <SelectInput
              errorText={this.props.formErrors.application}
              field="application"
              placeholder="Application"
              options={this.state.applicationOptions}
              onChange={(val) => {
                  this.prepareSelectionField(val);
                  updateForm(`${key}_application`, val);
              }}
              value={form[`${key}_application`]}
            />
            <SelectInput
                errorText={this.props.formErrors.value}
                field="selection"
                placeholder={selectedZipCodeRouter ? "Selected Recording" : "Selection"}
                options={this.state.selectionOptions}
                onChange={(val) => updateForm(`${key}_value`, this.validateSelection(val))}
                value={ form[`${key}_value`] }
            />
            <div>
                { selectedVoicemail ?
                <SelectInput
                    field="arguments"
                    placeholder="Argument"
                    options= {argumentList}
                    onChange={(val) => updateForm(`${key}_arguments`, val)}
                    value={form[`${key}_arguments`]}
                />
                : selectedZipCodeRouter ?
                <SelectInput
                    field="arguments"
                    placeholder="Default Route"
                    options= {argumentList}
                    onChange={(val) => updateForm(`${key}_arguments`, val)}
                    value={form[`${key}_arguments`]}
                />
                :
                <TextInput
                    field="arguments"
                    placeholder="Argument"
                    onChange={(val) => updateForm(`${key}_arguments`, val)}
                    value={form[`${key}_arguments`]}
                />
                }
            </div>
          </div>
        );
    }
}

const mapStateToProps = (state) => ({
    autoAttendants: state.autoAttendants,
    configurations: state.configurations.data,
    applications: state.configurations && state.configurations.data ? state.configurations.data.applications : null,
});


export default connect(mapStateToProps)(withStyles(styles)(KeyCodeForm));
