import React, { Component } from 'react';
import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';
import Footer from 'presentational/Footer';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/PersonOutline';
import GroupIcon from '@material-ui/icons/Group';
import MusicIcon from '@material-ui/icons/MusicVideo';
import DialIcon from '@material-ui/icons/Dialpad';
import ContextMenuIcon from '@material-ui/icons/MoreVert';
import RecordIcon from '@material-ui/icons/RecordVoiceOver';
import DelayIcon from '@material-ui/icons/AvTimer';
import EmailIcon from '@material-ui/icons/Email';
import { connect } from 'react-redux';

class ConferenceRoomForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
        errors: {},
        form: {
          adminpin: "",
          announce_join_leave: "0",
          description: "",
          disable_mute: "1",
          lock_to_tenant: "0",
          maxusers: "10",
          meet_extension: "",
          meet_pin_required: "",
          meet_url: "",
          meet_user: "None",
          musiconhold: "default",
          name: "",
          pin: "",
          record_conference: "0",
          report_users: [],
          tenant: "",
          waitforadmin: "0",	
        },
      submitError: null,
      buttonDisabled: true
    };
    this.usingApiData = false;

    this.submitForm = this.submitForm.bind(this);
    this.disableButton = this.disableButton.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onCancel(){
    this.props.selectedConferenceRoom('');
    app.navigate('conferencerooms', { trigger: true });
  }

  componentDidUpdate(){
    if(this.props.conferenceRoomDetails && !this.usingApiData){
      if (this.props.conferenceRoomDetails.musiconhold === '') {
        this.props.conferenceRoomDetails.musiconhold = 'default';
      }
      this.setState({ form: this.props.conferenceRoomDetails });
      this.usingApiData = true;
    }
    else if(this.props.conferenceRoomDetails && this.props.conferenceRoomDetails.confno!==this.state.form.confno){
      this.setState({ form: this.props.conferenceRoomDetails });
    }
  }
  
  disableButton(value = true){
    this.setState({ buttonDisabled: value})
  }

  submitForm(field, data){
    if (this.state.form[field] === data) {
      console.log('No update');
      return;
    }
    
    if(this.state.buttonDisabled){
      this.disableButton(false)
    }

    let dataToStore = data;
    if(typeof(data) === "boolean"){
      if(data === true)
        dataToStore = '1';
      else
        dataToStore = '0';
    }

    let meet_extension;
    if (field === "meet_user") {
      if (dataToStore != 'None') {
        this.props.logins.map(user => {
          if (user.userName === dataToStore) {
            meet_extension = user.extension;
          }
        })
      } else {
        meet_extension = "";
      }
    }
    this.setState({ form: {...this.state.form, [field]: dataToStore, meet_extension } })
  }

  submitFullForm(cancel){
    if(cancel){
      this.onCancel();
    } else{
      this.disableButton(true);
      this.props.submitForm(this.state.form);
    }
  }

  render() {
    if(this.props.formKey !== 'new'){
      if(!this.props.conferenceRoomDetails) {
        return(
          <Loading />
        );
      }
    }
    
    
    let logins = [];
    if(this.props.logins){
      logins = this.props.logins.map(user => user.userName);
    }

    logins.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });

    logins = ['None', ...logins];
    const musiconhold = [{value:'default',label:'Default'},
     {value:'Branches',label: 'Branches'},
      {value:'Carefree', label: 'Carefree'},
      {value:'HolidayHits', label:'Holiday Hits'},
      {value:'Mobinil', label: 'Mobinil'}, 
      {value:'Orchestral', label: 'Orchestral'},
      {value:'WinterWonderland', label: 'Winter Wonderland'}];

    return (
      <div style={{
        display: 'flex',
        flex: 1,
        height: '100%',
        background: '#EFEFEF',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '50px',
        overflow: 'auto',
      }}>
        <StandardSettingsPage>
          <FormControlCard
            form={this.state.form}
            field="name"
            icon={<PersonIcon />}
            formControl="TextInput"
            title="Name"
            type='text'
            subtitle="Name of the Room (Appears in the Left Bar)"
            submitForm={this.submitForm}
          />
          <FormControlCard
            form={this.state.form}
            field="description"
            icon={<DescriptionIcon />}
            formControl="TextInput"
            title="Description"
            type='text'
            subtitle="The Description for This Room"
            submitForm={this.submitForm}
          />
          <FormControlCard
            form={this.state.form}
            field="pin"
            icon={<DialIcon />}
            inputProps={{maxLength: '6'}}
            formControl="TextInput"
            title="Pin"
            type='number'
            subtitle="4 - 6 Digit Pin for This Room"
            submitForm={this.submitForm}
          />
          <FormControlCard
            form={this.state.form}
            field="adminpin"
            icon={<DialIcon />}
            inputProps={{maxLength: '6'}}
            formControl="TextInput"
            title="Admin Pin"
            type='number'
            subtitle="4 - 6 Digit Pin for Admins Entering This Room"
            submitForm={this.submitForm}
          />
          <FormControlCard
            form={this.state.form}
            field="maxusers"
            formControl="TextInput"
            icon={<GroupIcon />}
            title="Max Users"
            type='number'
            inputProps={{ min: 0, max: 10 }}
            subtitle="Maximum Users Allowed in a Room"
            submitForm={this.submitForm}
          />
          <FormControlCard
            form={this.state.form}
            field="musiconhold"
            icon={<MusicIcon />}
            formControl="SelectInput"
            title="Hold Music"
            subtitle="Music Played When Room is Empty or on Hold"
            submitForm={this.submitForm}
            options={musiconhold}
          />
          <FormControlCard
            form={this.state.form}
            field="disable_mute"
            icon={<ContextMenuIcon/>}
            formControl="RadioInput"
            title="Menu Options"
            subtitle="Playback Menu Options"
            submitForm={this.submitForm}
            options={["Standard", "Disable Mute", "Disable All"]}
            values={['0', '1', '2']}

          />
          <FormControlCard
            form={this.state.form}
            field="announce_join_leave"
            formControl="ToggleInput"
            icon={<RecordIcon/>}
            title="Announce Join Leave"
            subtitle="Prompt User for Name Upon Entering Room"
            submitForm={this.submitForm}
            label="No / Yes"
          />
          <FormControlCard
            form={this.state.form}
            field="waitforadmin"
            icon={<DelayIcon/>}
            formControl="ToggleInput"
            title="Wait for Admin"
            subtitle="Don't Allow Conference to Start Until Admin has Arrived"
            submitForm={this.submitForm}
            label="No / Yes"
          />
          <FormControlCard
            form={this.state.form}
            field="record_conference"
            formControl="ToggleInput"
            icon={<RecordIcon/>}
            title="Record Conferences"
            subtitle="Record Each Conference"
            submitForm={this.submitForm}
            label="No / Yes"
          />
          <FormControlCard
            form={this.state.form}
            field="report_users"
            icon={<EmailIcon />}
            formControl="EmailList"
            title="Send Reports To"
            type='EmailList'
            placeholder="Email Address"
            submitForm={this.submitForm}
          />
          <Footer 
            onClick={(cancel) => this.submitFullForm(cancel)}
            disabled={this.state.buttonDisabled}
          />
        </StandardSettingsPage>
      </div>
    )
  }
}

const mapStateToProps = state =>({
  logins: state.http.configurations.logins,
  conferenceRoomDetails: state.conferenceRoom.conferenceRoomDetails,
  conferenceRoomSelected: state.conferenceRoom.conferenceRoomSelected,
})

export default connect(mapStateToProps)(ConferenceRoomForm);
