import React, { Component } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Paper from '@material-ui/core/Paper';

class UserCallSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showingModal: false,
            mediaQuery: false,
        };
    }

    componentDidMount() {

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
    }

    render() {

        const minWidth = this.state.mediaQuery.matches;

        const style = {
            card: {
                width: '100%',
                // minWidth: '400px',
                // marginTop: '40px',
                marginBottom: 10,
            },
            cardContent: {
                display: 'flex',
                flexDirection: minWidth ? 'row' : 'column',
                flex: 1,
            },
            cardHeader: {
                backgroundColor: '#2a72b9',
                textAlign: 'center',
            },
            cardSummary: {
                display: 'flex',
                flexDirection: minWidth ? 'row' : 'column',
                flex: 1,
                paddingTop: '10px',
                justifyContent: 'center',
            },
            divider: {
                marginTop: '3px',
                marginBottom: '3px',
            },
            list: {
                listStyleType: 'none',
            },
            loader: {
                padding: 10,
            },
            summaryData: {
                width: '50%',
                textAlign: 'center',
            },
        };

        const reportDate = this.props.reportDate || 'Today';

        return (
          <Card
            style={style.card}
          >
            <CardHeader
              style={style.cardHeader}
              avatar={this.props.icon}
              subtitle={this.props.subtitle}
              title={<span style={{ color: 'white', fontSize: 15 }}>{this.props.title}</span>}
            />

            <CardContent>
              {this.props.currentlyLoading && !this.props.data ?
                <div style={style.cardContent}><Paper style={style.cardSummary} /></div>
              :
                <div style={style.cardContent}>
                  <Paper
                    style={style.cardSummary}
                    elevation={1}
                  >
                    <div style={style.summaryData}>
                      <ul style={style.list}>
                        <li><b>Total Call Time:</b></li>
                        <li><b>Max Call Duration:</b></li>
                        <li><b>Average Call Time:</b></li>
                      </ul>
                    </div>
                    <div style={style.summaryData}>
                      <ul style={style.list}>
                        <li>{this.props.data.totalcalltime || 0}</li>
                        <li>{this.props.data.maxcallduration || 0}</li>
                        <li>{this.props.data.averagecalltime || 0}</li>
                      </ul>
                    </div>
                  </Paper>
                  <Paper
                    style={style.cardSummary}
                    elevation={1}
                  >
                    <div style={style.summaryData}>
                      <ul style={style.list}>
                        <li><b>Total Calls:</b></li>
                        <li><b>Total Inbound Calls:</b></li>
                        <li><b>Total Outbound Calls:</b></li>
                      </ul>
                    </div>
                    <div style={style.summaryData}>
                      <ul style={style.list}>
                        <li>{this.props.data.totalrecords || 0}</li>
                        <li>{this.props.data.directions.Inbound || 0}</li>
                        <li>{this.props.data.directions.Outbound || 0}</li>
                      </ul>
                    </div>
                  </Paper>
                </div>
              }
            </CardContent>
          </Card>
        );
    }
}

export default UserCallSummary;
