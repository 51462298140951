import React, { Component } from 'react';
import { makeStyles } from '@material-ui/styles';

import StandardSettingsPage from 'presentational/StandardSettingsPage';
import ManageRenderer from './manageRenderer';
import Loading from 'presentational/Loading';

class Manage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            form: {},
            submitError: null,
            mediaQuery: false,
            showExtensionSelectionDialog: false,
            showPauseReasonSelectionDialog: false,
        };
    }

    componentDidMount() {

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));

        this.setState({ form: this.props.data });
    }

    componentWillReceiveProps(nextProps) {

        this.setState({ form: nextProps.data });
    }

    async submitForm() {

        const { form } = this.state;

        const submitError = await this.props.submitForm(form);

        if (submitError) {
            this.setState({ submitError });
        } else {
            this.setState({ submitError: null });
        }
    }

    // Turns extensions into dropdown options
    getExtensionsAsOptions() {

        if (!this.props.extensions || this.props.extensions.length == 0) {
            return [{ label: 'No extension available', value: '' }];
        }

        const extensions = [];
        this.props.extensions.map((ext) => {

            extensions.push({
                label: `${ext.extension} - ${ext.firstname} ${ext.lastname}`,
                value: ext.extension,
            });
        });

        extensions.sort((a, b) => (parseInt(a.value) > parseInt(b.value) ? 1 : -1))
        return extensions;
    }

    // Turns extensions into dropdown options
    getReasonsAsOptions() {

        if (!this.props.config.reasons) {
            return [{ label: 'No reason available', value: '' }];
        }

        const reasons = [];
        this.props.config.reasons.data.map((reason) => {

            reasons.push({
                label: `${reason.code_number} - ${reason.code_friendly_name}`,
                value: reason.code_friendly_name,
            });
        });

        return reasons;
    }

    // Handle toggle of agent sign in/out
    handleSigninChange(value) {

        const { form } = this.state;
        // Lets get the string val
        form.signedin = value ? '1' : '0';
        form.statusUpdate = value ? 'login' : 'logout';

        // If we are logging the user in, we want to
        // show the dialog for the user to select
        // what extension to map the agent to
        if (value) {

            this.setState({ form, showExtensionSelectionDialog: true });

        // If logging the user out,
        // set extension mapping to blank
        // and submit the form
        } else {

            form.extension_mapping = "";
            form.extension = "";
            form.paused = "0";
            this.setState({ form });
            this.submitForm(form);
        }
    }

    handlePauseChange(value) {

        const { form } = this.state;
        const { config } = this.props;

        form.paused = value ? '1' : '0';
        form.statusUpdate = value ? "pause" : 'unpause';
        this.setState({ form });

        if (value && config.reasons && config.reasons.data && config.reasons.data.length > 0) {
            this.setState({ showPauseReasonSelectionDialog: true });
            return;
        }

        this.props.submitForm(form);
    }

    handleExtensionSelectionDialogClose(submit) {

        // Handles closing the extension selection dialog that
        // pops if logging the user in. If submit is true, we actually
        // submit the form

        this.setState({ signInErrorText: '' });
        if (submit) {

            if (!this.state.form.extension) {
                this.setState({ signInErrorText: 'Please select an extension to sign agent into' });
                return;
            }

            this.setState({ showExtensionSelectionDialog: false });
            this.submitForm();
            return;
        }


        console.log('User cancelled logging in user');
        const { form } = this.state;
        form.signedin = "0";
        form.extension = "";
        form.extension_mapping = "";
        this.setState({ form,  showExtensionSelectionDialog: false });
    }

    handlePauseReasonSelectionDialogClose(submit) {

        // Handles closing the extension selection dialog that
        // pops if pausing the user and reasons are available.
        // If submit is true, we actually submit the form

        this.setState({ pauseReasonErrorText: '' });
        if (submit) {

            if (!this.state.form.pausedreason) {
                this.setState({ pauseReasonErrorText: 'Please select a reason to pause agent' });
                return;
            }

            this.setState({ showPauseReasonSelectionDialog: false });
            this.submitForm();
            return;
        }

        console.log('User cancelled pausing user');
        const { form } = this.state;
        form.paused = "0";
        this.setState({ form,  showPauseReasonSelectionDialog: false });
    }

    render() {

        const minWidth = this.state.mediaQuery.matches;


        const useStyle = makeStyles({
            card: {
                width: minWidth ? '50%' : '100%',
                minWidth: '400px',
            },
            typeahead: {
                marginLeft: '40px',
            },
            searchIcon: {
                marginTop: 35,
                marginLeft: -5,
            },
            button: {
                fontSize: '100%',
            },
        });

        const dataSourceConfig = {
            text: 'label',
            value: 'value',
        };

        if (!this.props.data ||
            !this.props.extensions) {

            return (
              <StandardSettingsPage>
                <Loading />
              </StandardSettingsPage>
            );
        }

        return (
          <ManageRenderer useStyle={useStyle} parent={this} />
        );
    }
}

export default Manage;
