import React from 'react';
import { connect } from 'react-redux';

import Checkbox from '@material-ui/core/Checkbox';

import { addMessageToBeDeleted, removeMessageToBeDeleted } from 'actions/sms';

const ConversationBubble = (props) => {

    const style = {
        wrapper: {
            display: 'flex',
            width: '100%',
            flexDirection: props.incomingMessage ? '' : 'row-reverse',
        },
        avatar: {
            margin: 8,
        },
        checkbox: {
            alignSelf: 'center',
            marginBottom: 10,
            width: 'auto',
        },
        messageWrapper: {
            backgroundColor: props.incomingMessage ? 'rgba(22, 107, 192, .4)' : 'rgba(22, 107, 192, .8)',
            borderRadius: 5,
            margin: 4,
            padding: 8,
            width: '60%',
        },
        date: {
            color: '#373737',
            fontSize: 10,
            marginTop: 4,
        },
        sending:{
            color: '#737373',
            fontSize: 10,
            textAlign: 'center',
            alignSelf: 'flex-end',
            marginBottom: 0,
        },
        failed:{
            color: 'red',
            fontSize: 10,
            textAlign: 'center',
            alignSelf: 'flex-end',
            marginBottom: 0,
        },
    };

    // Returns a message bubble. If we are currently deleting, it will show the checkbox to delete
    return (

      <div style={style.wrapper}>
        {props.deletingMessages ?
          <Checkbox
            style={style.checkbox}
            color='primary'
            onChange={(e, checked) => (
                checked ?
                    props.addMessageToBeDeleted(props.messageId) :
                    props.removeMessageToBeDeleted(props.messageId)
            )}
            checked={props.messagesToBeDeleted.some((messageId) =>
                messageId == props.messageId)
            }
          /> : null
        }
        <div style={style.avatar}>{props.avatar}</div>

        <div style={style.messageWrapper}>
          <span>{props.message}</span>
          <p style={style.date}>{props.date}</p>
        </div>
        {props.sending && !props.failed ? <p style={style.sending}>Sending...</p> : null}
        {props.failed && <p style={style.failed}>Failed!</p>}

      </div>
    );
};

const mapStateToProps = (state) => ({
    deletingMessages: state.sms ? state.sms.deletingMessages : null,
    messagesToBeDeleted: state.sms ? state.sms.messagesToBeDeleted : null,
});

const bindActions = (dispatch) => ({
    addMessageToBeDeleted: (messageId) => dispatch(addMessageToBeDeleted(messageId)),
    removeMessageToBeDeleted: (messageId) => dispatch(removeMessageToBeDeleted(messageId)),
});

export default connect(mapStateToProps, bindActions)(ConversationBubble);
