import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCoreHttp } from 'actions/httpRequest';
import { successNotification, errorNotification } from 'actions/notifications';

import { getHTTP, putHTTP } from '../../simpleHTTP';
import Loading from 'presentational/Loading';
import ParkingLotOptions from './options';

class ParkingLots extends Component {
    state = {
        applicationOptions: [],
        fetched: false,
        fetchedData: {},
        pending: false,
    };

    componentDidMount() {
        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener(mediaQuery => this.setState({ mediaQuery }));

        const { fetched } = this.state;
        if (!fetched) {
            if (!this.props.extensions) {
                this.fetchExtensions();
            }
            if (!this.props.configurations || !this.props.configurations.data) {
                this.fetchConfigurations();
            }
            this.fetchParkingLotSetting();
        }
    }

    componentWillUnmount() {
        this.setState({
            applicationOptions: [],
            fetched: false,
            fetchedData: {},
            pending: false,
        });
    }

    fetchConfigurations() {
        const reqData = { reqAction: 'configurations' };
        this.props.getCoreHttp(reqData);
    }

    fetchExtensions() {
        const reqData = { reqAction: 'extensions' };
        this.props.getCoreHttp(reqData, 'extensions');
    }

    async fetchParkingLotSetting() {
        this.setState({ fetched: true });
        const parkingLotSettings = await getHTTP('core', 'parkinglots');
        if (parkingLotSettings.error) {
            const error = error.error;
            return;
        }
        this.setState({ fetchedData: parkingLotSettings.data });
    }

    async submitRequest(form) {
        let argError = false;
        this.setState({ pending: true });

        // VERIFY WE ARE SAVING A VALUE TO OUR SELECTED APPLICATION IF REQUIRED
        const argNeeded = ['voicemenu', 'callflow', 'callforwarding', 'callgroup', 'extension', 'queue', 'recording', 'voicemailbox'];
        argNeeded.forEach(app => {
            if (app == form.parkingtimeout_application) {
                if (form.parkingtimeout_value == ""){
                    argError = true;
                    errorNotification({
                        title: 'Error Updating Parking Lot',
                        message: 'Can Not Save Without Application Value',
                    });
                    this.setState({ pending: false });
                }
            }
        });
        if (argError){ return };

        /// BASIC VALIDATION FOR CALL FORWARDING
        if (form.parkingtimeout_application == 'callforwarding'){
            const callForwardNum = form.parkingtimeout_value
            if (callForwardNum.length !== 10) {
                errorNotification({
                        title: 'Error Updating Parking Lot',
                        message: 'Call Forward number must be 10 digits',
                    });
                this.setState({ pending: false });
                return;
            }   
        }

        const reqObject = app.token.attributes.tenant;
        const submit = await putHTTP('core', 'parkinglots', form, reqObject);
        if (submit.error) {
            const error = submit.error;
            errorNotification({
                title: 'Error Updating Parking Lot',
                message: error.message,
            });
            this.setState({ pending: false });
            return;
        } else {
            this.setState({ pending: false });
            successNotification({
                title: 'Success!',
                message: `Updated Parking Lot`,
            });
        }
    }

    render() {
        const { configurations, extensions } = this.props;
        const { fetchedData, pending } = this.state;
        const childProps = {
            configurations,
            extensions,
        };
        if (
            !configurations ||
            configurations.pending ||
            !extensions ||
            !fetchedData
        ) {
            return <Loading />;
        }
        return (
            <div style={style.wrapper}>
                <ParkingLotOptions
                    pending={pending}
                    form={fetchedData}
                    data={childProps}
                    submitRequest={form => this.submitRequest(form)}
                />
            </div>
        );
    }
}
const bindActions = dispatch => ({
    getCoreHttp: (reqData, storeKey) =>
        dispatch(getCoreHttp(reqData, storeKey)),
});
const mapStateToProps = state => ({
    configurations: state.configurations,
    extensions: state.extensions.data,
});

const style = {
    wrapper: {
        marginTop: '-20px',
        height: '97vh',
        background: '#EFEFEF',
        alignItems: 'center',
    },
};
export default connect(
    mapStateToProps,
    bindActions
)(ParkingLots);
