import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import AutoComplete from 'presentational/autoComplete';
import SearchIcon from '@material-ui/icons/Search';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import ToggleInput from 'presentational/formControls/ToggleInput';
import SelectInput from 'presentational/formControls/SelectInput';

export default function manageRenderer(props) {
  const classes = props.useStyle();
  const { parent } = props;
  const { form } = parent.state;
  return (
    <div style={{
      display: 'flex',
      flex: 1,
      height: '100%',
      background: '#EFEFEF',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '50px',
      overflow: 'auto',
    }}>
    <StandardSettingsPage>
      <Card classes={{ root: classes.card }}>
        <CardHeader
        // avatar={parent.props.icon}
        // subtitle={parent.props.subtitle}
                
        >Manage agent status</CardHeader>
        <CardContent>
          <ToggleInput
            field="loggedin"
            onChange={(val) => parent.handleSigninChange(val)}
            value={form.signedin}
            // errorText={parent.props.errorText}
            placeholder="Agent signed in"
          />
          <ToggleInput
            disabled={form.signedin == "0"}
            field="paused"
            onChange={(val) => parent.handlePauseChange(val)}
            value={parent.state.form.paused}
            placeholder="Agent paused"
          />
        </CardContent>
      </Card>
      <Dialog
        open={parent.state.showExtensionSelectionDialog}
        onClose={() => parent.handleExtensionSelectionDialogClose(false)}
        fullWidth={true}
      >
        <DialogTitle>Select extension to log agent into</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flewDirection: 'row' }}>
            <AutoComplete
              placeholder='Select an extension'
              title='Select an extension'
              maxSearchResults={10}
              persist={true}
              onNewRequest={(val) => {
                form.extension = val.value;
                form.extension_mapping = val.value;
                parent.setState({ form });
              } }
              suggestions={parent.getExtensionsAsOptions()}
            />
            <SearchIcon classes={{ root: classes.searchIcon }} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            classes={{ root: classes.button }}
            size='large'
            color='primary'
            onClick={() => parent.handleExtensionSelectionDialogClose(false)}
          >Cancel</Button>
          <Button
            classes={{ root: classes.button }}
            color='primary'
            onClick={() => parent.handleExtensionSelectionDialogClose(true)}
          >Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={parent.state.showPauseReasonSelectionDialog}
        onClose={() => parent.handlePauseReasonSelectionDialogClose(false)}
        fullWidth={true}
      >
        <DialogTitle>Select reason for pausing</DialogTitle>
        <DialogContent>
          <SelectInput
            errorText={parent.state.pauseReasonErrorText}
            field="pausedreason"
            placeholder="Reason"
            options={parent.getReasonsAsOptions()}
            onChange={(val) => {
              form.pausedreason = val;
              parent.setState({ form });
            }}
            value={parent.state.form.pausedreason}
          />
        </DialogContent>
        <DialogActions>
          <Button
            style={{ fontSize: '100%' }}
            size='large'
            color='primary'
            onClick={() => parent.handlePauseReasonSelectionDialogClose(false)}
          >Cancel</Button>
          <Button
            style={{ fontSize: '100%' }}
            color='primary'
            onClick={() => parent.handlePauseReasonSelectionDialogClose(true)}
          >Submit</Button>
        </DialogActions>
      </Dialog>
    </StandardSettingsPage>
    </div>
  );
}