import React, { Component } from 'react';
import {Draggable} from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/HighlightOff';
import AddIcon from '@material-ui/icons/AddCircle';
import { withStyles } from '@material-ui/styles';

class ColumnItem extends Component {
  // Constructor builds initial state
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getItemStyle(draggableStyle, isDragging) {
    const grid = 8;
    return ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      padding: grid * 2,
      marginBottom: grid,

      // change background colour if dragging
      background: isDragging ? 'lightblue' : 'white',
      border: '1px solid lightgrey',

      // styles we need to apply on draggables
      ...draggableStyle
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Draggable
        draggableId={this.props.item.id}
        index={this.props.index}
        isDragDisabled={this.props.isDragDisabled}
      >
        {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={this.getItemStyle(
                provided.draggableProps.style,
                snapshot.isDragging
              )}
            >
            <div className={classes.container}>
              {this.props.item.content}
              {this.props.columnId =='right' &&
              <Button classes={{ root: classes.button }} onClick={()=>this.props.handleClick(this.props.item)}><RemoveIcon style={{float: 'right'}} /></Button>
            }
              {this.props.columnId =='left' && 
              <Button classes={{ root: classes.button }} onClick={()=>this.props.handleClick(this.props.item)}><AddIcon style={{float: 'right'}} /></Button>  
              }
            </div>
              {provided.placeholder}
            </div>
        )}
      </Draggable>);
  }
};

const styles = () => ({
  container:{
    display: 'grid',
    gridTemplateColumns: '3fr 25px',
  },
  button:{
    minWidth: '15px',
  }
});

ColumnItem.defaultProps = {
  isDragDisabled: false,
};

ColumnItem.propTypes = {
  isDragDisabled: PropTypes.bool,
};
export default withStyles(styles)(ColumnItem);
