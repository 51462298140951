import {
    SELECT_REPORTS_CONFIGURATION,
    SEARCH_INPUT,
    CHANGE_FILTER_DATETIMES,
    CHANGE_FILTER_DIRECTIONS,
    CHANGE_FILTER_CALLTYPES,
    CHANGE_FILTER_EXTENSIONS,
    CHANGE_FILTER_DISPOSITIONS,
    CHANGE_FILTER_AGENTS,
    CHANGE_FILTER_DURATIONS,
    CHANGE_FILTER_PHONENUMBERS,
    CHANGE_FILTER_TAGS,
    FETCH_RECORDINGS,
} from './typeConstants';

export const selectReportsConfigurations = (data) => ({
    type: SELECT_REPORTS_CONFIGURATION,
    data,
});

export const searchInput = (data) => ({
    type: SEARCH_INPUT,
    data,
});

export const changeFilterDateTime = (data) => ({
    type: CHANGE_FILTER_DATETIMES,
    data,
});

export const fetchRecordings = (data) => ({
    type: FETCH_RECORDINGS,
    data
});

export const changeFilterDirections = (data) => ({
    type: CHANGE_FILTER_DIRECTIONS,
    data,
});

export const changeFilterCallTypes = (data) => ({
    type: CHANGE_FILTER_CALLTYPES,
    data,
});

export const changeFilterExtensions = (data) => ({
    type: CHANGE_FILTER_EXTENSIONS,
    data,
});

export const changeFilterDispositions = (data) => ({
    type: CHANGE_FILTER_DISPOSITIONS,
    data,
});

export const changeFilterPhoneNumbers = (data) => ({
    type: CHANGE_FILTER_PHONENUMBERS,
    data,
});

export const changeFilterTags = (data) => ({
    type: CHANGE_FILTER_TAGS,
    data,
});

export const changeFilterAgents = (data) => ({
    type: CHANGE_FILTER_AGENTS,
    data,
});

export const changeFilterDurations = (data) => ({
    type: CHANGE_FILTER_DURATIONS,
    data,
});
