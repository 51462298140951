import { combineReducers } from 'redux';

import {
    CHANGE_TENANT,
    INVALIDATE_HTTP_DATA,
} from '../actions/typeConstants';


const data = (state = null, action) => {
    switch (action.type) {
        case CHANGE_TENANT:
        case INVALIDATE_HTTP_DATA:
            return null;

        case 'PHONEOVERRIDES_HTTP_FAILURE':
            return state;

        case 'PHONEOVERRIDES_HTTP_SUCCESS':
            if (action.reqObject == 'save') {
                return state;
            }

            return action.data;

        default:
            return state;
    }
};

const pendingInitialState = {
    pending: false,
    getSuccess: null,
    putSuccess: null,
};

const pending = (state = pendingInitialState, action) => {
    switch (action.type) {
        case 'PHONEOVERRIDES_HTTP_PENDING':
        case 'PUT_HTTP':
        case 'GET_HTTP':
            return { ...state, pending: true, putSuccess: null, getSuccess: null };

        case 'PHONEOVERRIDES_HTTP_FAILURE':
            if (action.res.statusCode == 400 || action.res.statusCode == 501) {
                return { ...state, pending: false, putSuccess: false };
            } else if (action.res.statusCode == 404) {
                return { ...state, pending: false, getSuccess: false };
            }
            return { ...state, pending: false };

        case 'PHONEOVERRIDES_HTTP_SUCCESS':
            if (action.reqObject == 'save') {
                return { ...state, pending: false, putSuccess: true };
            } else if (action.reqObject) {
                return { ...state, pending: false, getSuccess: true };
            }
            return { ...state, pending: false };

        default:
            return state;
    }
};

export const phoneOverrides = combineReducers({
    data,
    pending,
});
