import { SHOW_VOICEMAIL_DETAIL, NEW_VOICEMAIL_BOX, SELECT_MAILBOX } from './typeConstants';

export const showDetail = (data) => ({
    type: SHOW_VOICEMAIL_DETAIL,
    data
});

export const newVoicemailbox = (data) => ({
    type: NEW_VOICEMAIL_BOX,
    data
})

export const selectMailbox = data => ({
    type: SELECT_MAILBOX,
    payload: data
  })

export const transferVoicemail = data => ({
    type: TRANSFER_VOICEMAILS,
    data
})