import React, {useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      paddingLeft: '40px',
      paddingRight: 20,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 180,
      maxWidth: 700,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 400,
        },
    },
};

export default function MultiSelectChip(props){
  if(!props.data){
    return;
  }
  const classes = useStyles();
  var [title, setTitle] = useState([]);
  const listTitles= props.data.map(function(item){
    return item.title
  })
  const filteredTitles = listTitles.filter((item, index) => listTitles.indexOf(item) === index )
  const label = props.label
  let hookfieldString = '';

  function handleChange(event) {
    if(event.target.value && event.target.value.length > 1){
      const values = event.target.value;
      const array = [];
      for (let i = 0, l = values.length; i < l; i += 1) {
          setTitle(values[i])
          array.push(values[i]);
      }
      setTitle(array);
      if(props.hookfield){
        hookfieldString = array.join('');
        props.onMultiSelect(hookfieldString, true);
        return;
      }else{
        props.onMultiSelect(array, true);
        return;
      }
    }
    setTitle(event.target.value);
    props.onMultiSelect(event.target.value, true);
  }

  return(
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="select-multiple-chip">{label}</InputLabel>
        <Select
          multiple
          value={title}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected.map(value => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {filteredTitles.map(title => (
            <MenuItem 
                key={title} 
                value={title}
                >
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}