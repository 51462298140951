import React, { Component } from 'react';
import { connect } from 'react-redux';

//Presentational Components
import Button from 'presentational/formControls/SubmitButton';
import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';

//Material Components
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';

//Components
import WorkflowActionCard from './WorkflowActionCard';

//utils
import { validateEmail } from 'utils/misc';

import {
    errorNotification,
    successNotification,
} from 'actions/notifications';

class NotificationWorkflowForm extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            actionDeleteButtonDisabled: true,
            addActionButtonDisabled: false,
            addingAction: false,
            disabled: false,
            errors: [],
            fieldOne: '',
            fieldTwo: '',
            form: {},
            formControl: 'SelectInput',
            isDeletingAction: false,
            newChanges:false,
            notificationWorkflow: {},
            options: [],
            placeholder1: '',
            placeholder2: '',
            rollbackForm: {},
            searchableFormOption: '',
            submitButtonDisabled: true,
            subTitle: '',
            title: '',
            validEvent: false
        };
        
    }

    componentDidMount = () => {
        if(this.props.notificationWorkflow){
            this.getEventConfiguration(this.props.notificationWorkflow.event, this.props.configData, this.props.events) 
            this.setState({form: this.props.notificationWorkflow}, () => this.createRollbackForm())
        }
        document.addEventListener('mousedown', this.handleClick, false)
    }

    componentWillUnmount = () => {
        document.removeEventListener('mousedown', this.handleClick, false)
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevState.form.event != this.state.form.event && this.props.formkey==="new") {
            let form = this.state.form
            form.object = '';
            this.setState({form})
            this.getEventConfiguration(this.state.form.event, this.props.configData, this.props.events)
        }
        if(this.props.notificationWorkflow && this.props.notificationWorkflow !== prevProps.notificationWorkflow){
            this.setState({form: this.props.notificationWorkflow})
        }
        if(this.state.form.actions != prevState.form.actions){
            this.checkActionLength();
        }
    }

    createRollbackForm = () => {
        const rollbackForm = {...this.state.form}
        this.setState({ rollbackForm });
    }

    disableButton = (value = true) => {
        this.setState({ submitButtonDisabled: value})
    }

    disableActionDelete = (value = true) => {
        this.setState({ actionDeleteButtonDisabled: value})
    }

    disableAddNewAction = (value = true) => {
        this.setState({ addActionButtonDisabled: value })
    }

    onCancel = () => {
        this.props.selectedNotificationWorkflow('')
    }

    getEventConfiguration = (event, configurations, events) => {

        let uniqueEventArray = [];
        let uniqueEvents = [];
        let options = [];
        let formControl = 'SelectInput';
        let title = events[event]
        let subTitle = ''
        let fieldOne = ''
        let fieldTwo = ''
        let placeholder1 = ''
        let placeholder2 = ''
        let searchableFormOption = ''

        if(this.props.inUse){
            uniqueEvents = this.props.inUse.filter(function (use){
                return use.event === event;
            })
            uniqueEvents.map(obj =>
                uniqueEventArray.push(
                    obj.object
            ))
        }

        switch(event){
            case 'offline_site_location':
            case '911':
                subTitle = 'Select Location'
                formControl = "SearchableSelectInput"
                if(!configurations.locations){
                    options=[];
                }else{
                    configurations.locations.forEach(obj => {
                        if(uniqueEventArray.indexOf(obj.name) === -1) {
                            options.push({
                                value: obj.name,
                                label: obj.friendlyname ||obj.name,
                            })
                        }
                });
                options = options.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1)
            }
                break;
            case 'agent_autopaused':
            case 'queue_entered':
            case 'queue_exit':
            case 'service_load_agreement':
                subTitle = 'Select Queue'
                formControl = "SearchableSelectInput"
                if(!configurations.queues){
                    options=[];
                }else{
                    configurations.queues.forEach(obj => {
                        if(uniqueEventArray.indexOf(obj.name) === -1) {
                            options.push({
                                value: obj.name,
                                label: obj.friendlyname ||obj.name,
                            })
                        }
                    });
                }
                break;
            case 'voicemail':
                subTitle = 'Select Voicemail Box'
                formControl = "SearchableSelectInput"
                searchableFormOption = "PhoneNumberSearch"
                configurations.voicemailboxes.forEach(obj => {
                    if(uniqueEventArray.indexOf(obj.mailbox) === -1) {
                        options.push({
                            value: obj.mailbox,
                            label: `${obj.mailbox} - ${obj.fullname}`,
                        })
                    }
                });
                options = options.sort((a, b) => (parseInt(a.value)) > parseInt(b.value) ? 1 : -1);
                break;
            case 'parking_lot':
                subTitle = 'Select Extension'
                formControl = "SearchableSelectInput"
                if(!configurations.users){
                    options=[];
                }else{
                    configurations.users.forEach(obj => {
                        if(uniqueEventArray.indexOf(obj.extension) === -1) {
                            options.push({
                                value: obj.extension,
                                label: `${obj.extension} - ${obj.firstname} ${obj.lastname}`,
                            })
                        }
                    });
                    options = options.sort((a, b) => (parseInt(a.value)) > parseInt(b.value) ? 1 : -1);
                }
                break;
            case 'call_group_exit':
                subTitle = 'Select Call Group'
                formControl = "SearchableSelectInput"
                if(!configurations.callgroups){
                    options=[];
                }else{
                    configurations.callgroups.forEach(obj => {
                        if(uniqueEventArray.indexOf(obj.name) === -1) {
                            options.push({
                                value: obj.name,
                                label: obj.friendlyname || obj.name, 
                            })
                        }
                    });
                }
                break;
            case 'post_call_survey_question':
            case 'post_call_survey':   
                subTitle = 'Select Survey'
                formControl = "SearchableSelectInput"
                if(!configurations.postcallsurveys){
                    options=[];
                }else{
                    configurations.postcallsurveys.forEach(obj => {
                        if(uniqueEventArray.indexOf(obj.name) === -1) {
                            options.push({
                                value: obj.name,
                                label: obj.friendlyname || obj.name, 
                            })
                        }
                    });
                    options = options.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1)
                }
                break;
            case 'voicemail_sentiment_analysis':
                subTitle = "Select Voicemail Box"
                formControl = "VoicemailSentimentSlider"
                fieldOne = fieldOne
                fieldTwo = fieldTwo
                placeholder1 = "Sentiment Analysis"
                placeholder2 = "Sentiment Magnitude"
                if(!configurations.voicemailboxes){
                    options=[];
                }else{
                    configurations.voicemailboxes.forEach(obj => {
                        if(uniqueEventArray.indexOf(obj.mailbox) === -1) {
                            options.push({
                                value: obj.mailbox,
                                label: `${obj.mailbox} - ${obj.fullname}`,
                            })
                        }
                    });
                    options = options.sort((a, b) => (parseInt(a.value)) > parseInt(b.value) ? 1 : -1);
                }
                break;
            case 'incoming_call':
            case 'incoming_sms':
                subTitle = 'Select Phonenumber'
                formControl = "SearchableSelectInput"
                searchableFormOption = "PhoneNumberSearch"
                if(!configurations.phonenumbers){
                    options=[];
                }else{
                    configurations.phonenumbers.forEach(obj => {
                        if(uniqueEventArray.indexOf(obj.phonenumber) === -1) {
                            options.push({
                                value: obj.phonenumber,
                                label: obj.phonenumber,
                                smsenabled: obj.smsenabled,
                            })
                        }
                    });
                    if(event == 'incoming_sms'){
                        options = options.filter(function (option){
                            return option.smsenabled === "1"
                        })
                    }
                }
                break;
            default:
               return;
        }

        this.setState({options, formControl, title, subTitle, fieldOne, fieldTwo, placeholder1, placeholder2, searchableFormOption})
    }

    addAction = () => {
        this.props.unsavedChanges();
        this.setState(({form}) =>({
            addingAction: true,
            form: {
                ...form,
                actions: [...form.actions, [] ]
            }
        }))
    }

    handleClick = (e) => {
        if (this.node) {
            if (!this.node.contains(e.target)) {
                this.props.clickedNode();
            }
        }
    }

    handleRemoveAction = (selectedActionForm) => {
        if(this.state.form.actions.length<=1){
            this.renderErrorMessage("No action available to delete")
            return;
        }
        let {actions} = this.state.form;

        const newActionArray = actions.filter((action) => action.id !== selectedActionForm.id)

        this.setState(({form}) =>({
            form: {...form, actions: newActionArray}
        }),() => this.submitFullForm())
    }

    checkActionLength = () => {
        if(this.state.form.actions && this.state.form.actions.length <= 1){
            this.disableActionDelete(true)
        }else{
            this.disableActionDelete(false)
        }
    }

    checkForValidEvent = () => {
        if(this.state.form.event && this.state.form.object){
            this.setState({validEvent: true})
        }else{
            this.setState({validEvent: false})
        }
    }

    validateField = (field, data) => {
        if(this.state.isDeletingAction){
            return null;
        }
        if(!data.time_condition){
            return 'Please enter a time condition'
        }
        switch(field) {
            case 'sms':
                if(Number.isInteger(parseInt(data.type_value)) && data.type_value.length == 10 && data.type_value.length !== 0){
                return null;
                }
                return 'Please enter a 10 digit phone number';
            case 'email':
               return validateEmail(data.type_value);
            case 'webhook':
                if(!data.hook_verb){
                    return 'Please enter a request verb'
                }else if(!data.hook_value){
                    return 'Please enter a URL'
                }else if(!data.hook_fields){
                    return 'Please enter a JSON object'
                }else{
                    return null;
                }
            default:
                return null;
            }
      }

    renderErrorMessage = (response) => {
        errorNotification({
            title:'Error!',
            message: response
        });
    }

    //workflowActionCard will push a new actionForm to this function
    //and if valid will add this actionForm to the event form
    submitActionForm = (actionForm, madeChanges = null, index) => {
        const error = this.validateField(actionForm.type, actionForm);

        let errors = [];
        if(error) {
            errors.push(error)
            this.setState({errors})
        }else{
            this.setState({errors: []})
        }

        let {form} = this.state;
        if(form && form.actions && form.actions.length){
            let newForm = [];
            form.actions.forEach((action, i) => {
                if (index == i){
                    newForm.push(actionForm)
                } else {
                    newForm.push(action)
                }
            })
            if(this.state.submitButtonDisabled){
                this.disableButton(false)
              }
            if(!madeChanges){
                this.setState(({form}) =>({
                    form: {...form, actions: newForm},
                }), () => this.submitFullForm())
            }else{
                this.setState(({form}) =>({
                    form: {...form, actions: newForm},
                }), () => this.props.madeChanges(madeChanges, this.state.form))
            } 
        }else{
            if(!madeChanges){
                this.setState({form: {...form, actions: [actionForm]}}, ()=> this.submitFullForm())
            }else{
                this.setState({form: {...form, actions: [actionForm]}}, ()=> this.props.madeChanges(madeChanges, this.state.form)) 
            }
        }
    }

    //this submits changes as they happen when creating a new event on the event card
    submitForm = (field, data) => {
        if(this.state.rollbackForm[field] === data) {
            console.log('No update');
            return;
        }else{
            this.setState({
                newChanges: true,
                disabled: false}, () =>
            this.props.madeChanges(this.state.newChanges, this.state.form))
        }
        let dataToStore = data;
        if(typeof(data) ==='boolean'){
            if(data === true)
                dataToStore = '1';
            else
                dataToStore = '0'; 
        }

         this.setState(({form}) =>({
            form: {...form, [field]: dataToStore},
         }), () => this.checkForValidEvent())

    }

    //submitFullForm takes the full form which contains the event and action data
    //and will submit to database through parent function this.props.submitForm
    submitFullForm = (cancel) => {
        if(this.state.errors && this.state.errors != [] && this.state.errors != '' && !this.state.isDeletingAction){
            this.renderErrorMessage(this.state.errors)
            return
        }
        if(cancel){
            this.onCancel();
        }else{
            this.disableButton(true);
            this.setState({addingAction: false, isDeletingAction: false})
            this.props.submitForm(this.state.form);
        }
    }
   
    render = () => {
        const twinForm = {
            maxValue: this.state.form.max_voicemail_value || '',
            minValue: this.state.form.min_voicemail_value || ''
        }
        const {events, configData, configPending, newEventFlag} = this.props;
        const {placeholder1, placeholder2, formControl, searchableFormOption, subTitle, options, addingAction, validEvent, actionDeleteButtonDisabled, form, addActionButtonDisabled} = this.state;
        const eventTypes = Object.keys(events).map(key =>{ return {"value": key, "label": events[key]}});
        const actionTypes =
         [{value: 'onesig', label: "Chrome Push Notification"},
         {value: 'email', label:'Email'},
         {value: 'sms', label:'Text Message'},
         {value: 'webhook', label: "Web Hook"}];

        let actions = [];
        if(this.state.form.actions && this.state.form.actions.length){
            this.state.form.actions.forEach((action, index)=>{
                actions.push(
                    <>
                    <WorkflowActionCard
                        key={action.id}
                        index={index}
                        icon={<DirectionsRunIcon />}
                        eventType = {form.event}
                        validEvent = {validEvent}
                        title='Action'
                        subtitle="Please specify the action to take"
                        submitForm={(actionForm, newChanges, i) => {
                            this.submitActionForm(actionForm, newChanges, i);
                        }}
                        handleRemoveAction = {(form)=> { this.handleRemoveAction(form)}}
                        options={actionTypes}
                        configData = {configData}
                        addingAction = {addingAction}
                        actionData={action}
                        disableDeleteAction={actionDeleteButtonDisabled}
                        selectedNotificationWorkflow = {(workflow)=> { this.onCancel(workflow)}}
                        newEvent = {newEventFlag}
                        disableAddNewAction = {(value)=> {this.disableAddNewAction(value)}}
                        clickedNode = {()=>this.props.clickedNode()}
                        configPending = {configPending}
                    />
                    <br/>
                    </>
                )             
            })
        } else {
            let action = [];
            actions.push(
                <WorkflowActionCard
                    key={0}
                    index={0}
                    icon={<DirectionsRunIcon />}
                    eventType = {form.event}
                    title='Action'
                    validEvent = {validEvent}
                    subtitle="Please specify the action to take"
                    submitForm={(actionForm, newChanges, i) => {
                        this.submitActionForm(actionForm, newChanges, i);
                    }}
                    handleRemoveAction = {(form)=> { this.handleRemoveAction(form), this.setState({isDeletingAction: true})}}
                    options={actionTypes}
                    configData = {configData}
                    addingAction = {addingAction}
                    actionData={action}
                    disableDeleteAction={actionDeleteButtonDisabled}
                    selectedNotificationWorkflow = {(workflow)=> { this.onCancel(workflow)}}
                    newEvent = {newEventFlag}
                    disableAddNewAction = {(value)=> {this.props.disableAddNewAction(value)}}
                    clickedNode = {()=>this.props.clickedNode()}
                    configPending = {configPending}
                />
            )
        }
        return(
        <div ref={node => this.node = node} style={{
                display: 'flex',
                flex: 1,
                height: '100%',
                background: '#EFEFEF',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: '50px',
                overflow: 'auto',}}>    
            <StandardSettingsPage>
                {newEventFlag ?
                <>
                <FormControlCard
                    form={form}
                    field="event"
                    icon={<FormatAlignLeftIcon />}
                    formControl="SelectInput"
                    title="Event"
                    type='text'
                    subtitle="Select an event to receive notifications for"
                    submitForm={(field, data) => {
                        this.getEventConfiguration(form.event, configData, events)
                        this.submitForm(field, data);
                    }}
                    options={eventTypes}
                />
                {!form.event ? '' :
                    <FormControlCard
                        form={form}
                        field="object"
                        twinForm={twinForm}
                        formControl={formControl}
                        style={{header: '100px'}}
                        type='text'
                        subtitle={subTitle}
                        submitForm={this.submitForm}
                        placeholder1={placeholder1}
                        placeholder2={placeholder2}
                        options={options}
                        searchableFormOption = {searchableFormOption}
                    />
                }
                </>
                :
                ''
                }
                <br/>
                {actions}
                {newEventFlag ? '' :
                <Button
                    disabled = {addActionButtonDisabled}
                    label="Add New Action"
                    style = {{margin: "10px"}}
                    onClick={() => this.addAction()}
                />
                }
            </StandardSettingsPage>
        </div>
        )

    }

}

const bindActions = (dispatch) => ({
});

export default connect(null, bindActions)(NotificationWorkflowForm);