import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import FormControlCard from 'presentational/FormControlCard'
import DragAndDrop from 'presentational/DragAndDrop'
import SelectInput from 'presentational/formControls/CustomSelect'
import ToggleInput from 'presentational/formControls/ToggleInput'

import CardContent from '@material-ui/core/CardContent'
import FollowIcon from '@material-ui/icons/ArrowForward'
import TimeIcon from '@material-ui/icons/Timer'
import SequenceIcon from '@material-ui/icons/People'
import AddIcon from '@material-ui/icons/AddBox'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import ForwardingIcon from '@material-ui/icons/CallMissed'
import { errorNotification } from 'actions/notifications';

class FollowMeForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      parentReference: null,
      ringDuration: 0,
    }
  }

  componentDidMount () {
    const { parentReference } = this.props
    const { form, timeConditions } = parentReference.state
    let ringDuration
    if (form && form.cfw_numbers.length !== 0) {
      ringDuration = form.cfw_numbers[0].duration
    } else {
      ringDuration = 30
    }
    this.setState({
      parentReference,
      ringDuration
    })
  }ß

  changeNumberDurations = data => {
    const { parentReference } = this.props
    const { form } = parentReference.state
    const newSettings = form.cfw_numbers.map(number => {
      number.duration = data
      return number
    })
    this.setState({ ringDuration: data })
    parentReference.changeCondition('cfw_numbers', newSettings)
  }

  addAnother = ()=> {
    const { parentReference } = this.props
    const { timeConditions } = parentReference.state

    let times = timeConditions.timeConditions;
    times.push({})

    parentReference.changeTimeCondition({...timeConditions, timeConditions: times})
  }

  deleteTC = (indexVal) => {
    const { parentReference } = this.props
    const { timeConditions } = parentReference.state
    let times = timeConditions.timeConditions
    times = times.filter((time, key) => {
      return indexVal != key
    })
    parentReference.changeTimeCondition({...timeConditions, timeConditions: times})
  }

  editTimeConditions = (field, data, key, checked) => {
    const { parentReference } = this.props
    const { timeConditions } = parentReference.state
    let newTC
    if(checked){
    }
    if (field === 'occurrence') {
      if (data) {
        newTC = { ...timeConditions, occurrence: 'weekly' }
      } else {
        newTC = { ...timeConditions, occurrence: 'all-times' }
      }
    } else if (
      field === 'startTime' ||
      field === 'endTime' ||
      field === 'weekdays'
    ) {
      let times = timeConditions.timeConditions
      if (!times) {
        times = []
      }
      if (times[key] && times[key][field] && field === 'weekdays' && checked) {
        times[key] = { ...times[key], [field]: [...times[key][field], data] }
      } else if(times[key] && times[key][field] && field === 'weekdays' && !checked){
        let weekdays = times[key][field]
        weekdays = weekdays.filter(day => {
          return day != data
        })
        times[key] = { ...times[key], [field]: weekdays }
      }else {
        times[key] = { ...times[key], [field]: field === 'weekdays' ? [data] : data }
      }
      newTC = { ...timeConditions, timeConditions: times }
    } else {
      newTC = { ...timeConditions, [field]: data }
    }
    parentReference.changeTimeCondition(newTC)
  }

  renderTimeConditionSelection = () => {
    let times = null
    const { parentReference } = this.props
    if (
      parentReference.state.timeConditions &&
      parentReference.state.timeConditions.occurrence == 'weekly'
    ) {
      times = this.renderTimeZone()
    }
    return (
      <>
        <FormControlCard
          formControl='ToggleInput'
          occurrence = {parentReference.state.timeConditions ? parentReference.state.timeConditions.occurrence : null}
          title='Custom Time Conditions'
          icon={<TimeIcon />}
          field='occurrence'
          label='No/Yes'
          submitForm={(field, data) => this.editTimeConditions(field, data)}
        />
        {times}
      </>
    )
  }

  renderTimeZone = () => {
    const { parentReference } = this.props
    const { timeConditions } = parentReference.state
    let countries = []
    let countryTimezones = []
    let newTC
    if (this.props.timezones) {
      this.props.timezones.forEach(timezone => {
        // create the dropdown for timezone countries from timezone config from store
        countries.push(timezone.country)
        // since timezone_country is not returned from API, find timezone country that has the timezone
        if (!timeConditions.timezone_country) {
          if (timeConditions.timezone) {
            timezone.zones.forEach(zone => {
              if (zone.zone_name === timeConditions.timezone) {
                newTC = {
                  ...timeConditions,
                  timezone_country: timezone.country
                }
                parentReference.changeTimeCondition(newTC)
              }
            })
          }
        }
        if (timeConditions.timezone_country === timezone.country) {
          timezone.zones.forEach(zone => {
            countryTimezones.push(zone.zone_name)
          })
        }
      })
    }

    return (
      <>
        <FormControlCard
          form={timeConditions}
          formControl='SelectInput'
          title='Country'
          icon={<TimeIcon />}
          field='timezone_country'
          options={countries}
          submitForm={(field, data) => this.editTimeConditions(field, data)}
        />
        <FormControlCard
          form={timeConditions}
          formControl='SelectInput'
          title='Timezone'
          icon={<TimeIcon />}
          field='timezone'
          options={countryTimezones}
          submitForm={(field, data) => this.editTimeConditions(field, data)}
        />
        <FormControlCard
          formControl='TimeConditions'
          title='Time Conditions'
          icon={<TimeIcon />}
          field='time_conditions'
          num={this.state.numberOfTC}
          timeConditions = {timeConditions}
          addAnother = {() => this.addAnother()}
          deleteTC = {(indexVal) => this.deleteTC(indexVal)}
          submitForm={(field, data, key, checked) =>
            this.editTimeConditions(field, data, key, checked )
          }
        />
      </>
    )
  }

  renderRingDurationField = option => {
    if (option !== 'donotring') {
      const { parentReference } = this.state
      const { form } = parentReference.state
      return (
        <FormControlCard
          form={form}
          field='cfw_ring_time'
          formControl='SelectInput'
          icon={<TimeIcon />}
          title='Ring Duration(seconds):'
          submitForm={(field, data) =>
            parentReference.changeCondition(field, data)
          }
          options={form.config.cfw_ring_time.map(item => {
            return item
          })}
        />
      )
    }
    return null
  }

  renderHowToForwardCalls = ringOption => {
    if (ringOption !== 'simultaneous') {
      const { parentReference } = this.state
      const { form } = parentReference.state
      const cfwRingSequentialOptions = [
        'Call all my Follow Me numbers at the same time',
        'Call each Follow Me number one right after another.'
      ]

      return (
        <>
          <FormControlCard
            form={form}
            field='cfw_ring_sequential'
            formControl='RadioInput'
            icon={<SequenceIcon />}
            title='How to Forward Calls:'
            submitForm={(field, data) =>
              parentReference.changeCondition(field, data)
            }
            options={cfwRingSequentialOptions}
            values={['0', '1']}
            column
          />
          {form.cfw_ring_sequential === '0' && (
            <FormControlCard
              form={this.state}
              field='ringDuration'
              formControl='SelectInput'
              icon={<TimeIcon />}
              title='Ring Duration(seconds):'
              submitForm={(field, data) => this.changeNumberDurations(data)}
              options={form.config.cfw_ring_time.map(item => {
                return item
              })}
            />
          )}
        </>
      )
    }
    return null
  }

  removeNumber = number => {
    const { parentReference } = this.state
    const { form } = parentReference.state
    const newArray = form.cfw_numbers.filter(item => number !== item.number)
    form.cfw_numbers = newArray
    parentReference.setState({ form })
  }

  addNumberToList = number => {
    const { parentReference } = this.state
    const { form } = parentReference.state
    const users = get(form, 'config.users', [])
    const index = form.cfw_numbers.findIndex(
      cfwNumber => cfwNumber.number === number
    )
    if (index === -1) {
      if (number.startsWith('+')) {
        if (parsePhoneNumberFromString(number).isValid()) {
          form.cfw_numbers.push({ number, duration: this.state.ringDuration })
          parentReference.changeCondition('cfw_numbers', form.cfw_numbers)
        } else {
          errorNotification({
            title: 'Failed',
            message: 'Enter a valid number'
          })
        }
      } else {
        const extensionNumber = users.map(user => {
          return user.extension
        })
        const isInputExtension = extensionNumber.includes(number)

        if (isInputExtension || number.length === 10) {
          if (isInputExtension && number === app.token.attributes.extension) {
            errorNotification({
              title: 'Failed',
              message: 'You cannot set forwarding to same extension'
            })
          } else {
            form.cfw_numbers.push({ number, duration: this.state.ringDuration })
            parentReference.changeCondition('cfw_numbers', form.cfw_numbers)
          }
        } else {
          errorNotification({
            title: 'Failed',
            message: 'Enter a valid number'
          })
        }
      }
    } else {
      errorNotification({
        title: 'Failed',
        message: 'This number already exist in the list'
      })
    }
  }

  renderNumberContent = (number, list, position) => {
    const { parentReference } = this.state
    const { form } = parentReference.state
    return (
      <div>
        <div>{number.number}</div>
        {form.cfw_ring_sequential === '1' && form.cfw_ring_extension != "simultaneous"  &&(
          <SelectInput
            onChange={val => {
              number.duration = val
              list[position] = number
              parentReference.changeCondition('cfw_numbers', list)
            }}
            value={number.duration}
            placeholder='Ring Duration(seconds):'
            options={form.config.cfw_ring_time.map(item => {
              return { value: item, label: item }
            })}
          />
        )}
      </div>
    )
  }

  updateNumberSerial = updateInfo => {
    const { parentReference } = this.state
    const { form } = parentReference.state
    const keys = updateInfo.columns.right.itemIds
    const newArray = keys.map(key => {
      const index = form.cfw_numbers.findIndex(item => item.number === key)
      return form.cfw_numbers[index]
    })
    parentReference.changeCondition('cfw_numbers', newArray)
  }

  renderForwardList = list => {
    const numbers = list || []
    const itemIds = []
    const numbersObjects = {}
    numbers.map((number, position) => {
      itemIds.push(number.number)
      numbersObjects[number.number] = {
        id: number.number,
        content: this.renderNumberContent(number, list, position)
      }
    })
    const initialData = {
      items: numbersObjects,
      columns: {
        right: {
          id: 'right',
          title: 'Selected phone numbers',
          itemIds
        }
      },
      columnOrder: ['right']
    }
    return (
      <DragAndDrop
        updateOrder={updateInfo => {
          this.updateNumberSerial(updateInfo)
        }}
        handleSelectedDeletion={item => this.removeNumber(item.id)}
        handleSelectedAddition={() => {}}
        data={initialData}
        searchDisable
      />
    )
  }

  render () {
    if (this.state.parentReference == null) {
      return null
    }
    const { parentReference } = this.state
    const { form } = parentReference.state
    const cfwRingExtensionOptions = [
      'Ring my phone and follow me numbers simultaneously.',
      'Ring my phone and then forward my call if not answered.',
      "Don't ring my phone, just forward."
    ]
    return (
      <>
        <FormControlCard
          form={form}
          field='cfw_ring_extension'
          formControl='RadioInput'
          icon={<FollowIcon />}
          title='When Following Me:'
          submitForm={(field, data) =>
            parentReference.changeCondition(field, data)
          }
          options={cfwRingExtensionOptions}
          values={form.config.cfw_ring_extension.map(item => {
            return item
          })}
          column
        />
        {this.renderRingDurationField(form.cfw_ring_extension)}
        {this.renderTimeConditionSelection()}
        {this.renderHowToForwardCalls(form.cfw_ring_extension)}
        <FormControlCard
          form={this.state}
          field='number'
          formControl='PhoneInputForForwarding'
          title='Follow Me Numbers:'
          subtitle=''
          icon={<AddIcon />}
          buttonLabel={<AddCircleIcon style={{ color: 'rgb(42, 114, 185)' }} />}
          onClick={value => this.addNumberToList(value)}
        />
        <FormControlCard
          form={{ content: this.renderForwardList(form.cfw_numbers) }}
          field='content'
          formControl='blocknumberlist'
          title='Forwarded Numbers'
          subtitle='These numbers will be called in the order below. Drag and drop numbers to reorder.'
          icon={<ForwardingIcon />}
        />
      </>
    )
  }
}

FollowMeForm.propTypes = {
  parentReference: PropTypes.object.isRequired
}

export default FollowMeForm
