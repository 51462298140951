import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCoreHttp, getReportsHttp } from 'actions/httpRequest';
import { REPORTS_NAMESPACE } from 'actions/typeConstants';
import QueueGrid from './queueGrid';
import Search from 'presentational/Search';
import TimeIntervalSelect from 'presentational/reports/TimeIntervalSelect';
import ToggleInput from 'presentational/formControls/ToggleInput';
import MultiSelectInput from 'presentational/formControls/MultiSelectInput';
import Loading from 'presentational/Loading';

import { formatDateTimePicker } from 'utils/misc';

import { DateTimePicker } from "@material-ui/pickers";
import LinearProgress from '@material-ui/core/LinearProgress';

import { errorNotification } from 'actions/notifications';

class QueueRecords extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mediaQuery: false,
            dateTimes: 'today',
            reqQuery: null,
            startDate: null,
            endDate: null,
            timeInterval: 'today',
            search: '',
            displayQueueMemberEvents: false,
            filterQueues: [],
            acdQueues: [],
            acdSelected: false,
        };
    }

    componentDidMount() {
        this.fetchQueueRecords();
        this.fetchConfigurations();
        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener(mediaQuery => this.setState({ mediaQuery }));
    }
    componentWillReceiveProps(nextProps) {
        // Filter through fc_phonenumber data and push all unique ACD queue identifiers to acdQueues in state, populates the dropdown
        if (nextProps && nextProps.phoneNumbers) {
            const phonesWithACD = nextProps.phoneNumbers.filter(phone => {
                return phone.acd_queue_identifier != null;
            });
            if (phonesWithACD.length > 0) {
                const acdQueueIdentifiers = [];
                phonesWithACD.forEach(phone => {
                    if (
                        acdQueueIdentifiers.indexOf(
                            phone.acd_queue_identifier
                        ) === -1
                    ) {
                        acdQueueIdentifiers.push(phone.acd_queue_identifier);
                    }
                });
                this.setState({ acdQueues: acdQueueIdentifiers });
            }
        }
        // Sets dropdowns to select all dialednumbers and queues by default onload fo Queue reports
        if (
            nextProps &&
            nextProps.queues &&
            nextProps.queues !== this.props.queues &&
            nextProps.dialedNumbers != this.props.dialedNumbers
        ) {
            this.setState({
                filterQueues: nextProps.queues.concat(nextProps.dialedNumbers),
            });
        }
    }
    // Build the query based off provided filters
    buildReqQuery() {
        let dateTimes = this.state.timeInterval;
        const startDate = this.state.startDate;
        const endDate =
            this.state.endDate ||
            moment()
                .endOf('day')
                .format('MM DD YYYY hh:mm a');
        const queryEndDate =
            this.state.endDate ||
            moment()
                .endOf('day')
                .format('MM DD YYYY hh:mm');
        let query = '';
        if (dateTimes == 'custom' && startDate.length && endDate.length) {
            query += `&startdate=${moment(startDate).format(
                'YYYY-MM-DD HH:mm:ss'
            )}`;
            query += `&enddate=${moment(queryEndDate).format(
                'YYYY-MM-DD HH:mm:ss'
            )}`;
        } else if (dateTimes && dateTimes.length > 0) {
            this.setState({ timeInterval: dateTimes });
            query += `&timeinterval=${dateTimes}`;
        }

        this.setState({ reqQuery: query });
        return query;
    }
    // Change the ReportDates filter option
    async changeDateTimeInverval(dateTime) {
        if (dateTime != 'custom') {
            this.setState({ startDate: null });
            this.setState({ endDate: null });
            await this.setState({ timeInterval: dateTime });
            this.fetchQueueRecords();
        } else {
            await this.setState({ timeInterval: dateTime });
        }
    }
    // Change the custom startdate
    async changeStartDate(startDate) {
        const startD = formatDateTimePicker(startDate);
        await this.setState({ startDate: startD });
        this.fetchQueueRecords();
    }
    // Change the custom endDate
    async changeEndDate(endDate) {
        const endD = formatDateTimePicker(endDate);
        await this.setState({ endDate: endD });
        this.fetchQueueRecords();
    }
    // Lets set up the request data to retreive queue summary data
    fetchQueueRecords() {
        const reqData = {
            reqAction: 'queuelogreport',
            reqQuery: this.buildReqQuery(),
        };
        const storeKey = 'queueRecords';
        const nameSpace = REPORTS_NAMESPACE;
        this.props.getReportsHttp(reqData, storeKey, nameSpace);
    }

    fetchConfigurations() {
        const reqData = {
            reqAction: 'configurations',
        };
        this.props.getCoreHttp(reqData);
    }

    // Recceive term to filter with
    async searchRecords(term) {
        await this.setState({ search: term });
        this.filterData();
    }
    // Filter dataset with term and return or return default data
    filterData() {
        const term = this.state.search;
        const displayQueueMemberEvents = this.state.displayQueueMemberEvents;
        const filterQueues = this.state.filterQueues;
        const data = this.props.queueRecords;
        const filteredData = [];
        if (!data || !data.length) {
            return [];
        }
        // Default: No search term and we don't want to display queuemember events
        // Filter search terms both by queue and dialed numbers
        if (!term && !displayQueueMemberEvents) {
            // All unique phonenumbers(dialed numbers) that are associated with a selected ACD queue identifier are set to phoneNumberACD
            const phoneNumberACD = [];
            this.state.acdQueues.forEach(acd => {
                if (filterQueues.includes(acd)) {
                    this.props.phoneNumbers.forEach(phone => {
                        if (phone.acd_queue_identifier === acd) {
                            if (
                                phoneNumberACD.indexOf(phone.phonenumber) === -1
                            ) {
                                phoneNumberACD.push(phone.phonenumber);
                            }
                        }
                    });
                }
            });

            data.map(queueCall => {
                const queueCallCopy = JSON.stringify(queueCall).toLowerCase();
                // If dialed number filters and queuename filters are selected but no ACD queue identifier selected, output queue log data
                if (
                    !queueCallCopy.includes('addmember') &&
                    !queueCallCopy.includes('removemember') &&
                    (filterQueues.includes(queueCall.queuename) &&
                        filterQueues.includes(queueCall.dialednumber) &&
                        phoneNumberACD.length === 0)
                ) {
                    filteredData.push(queueCall);
                    // If ACD queue identifier(s) selected, output queue log data for phonenumbers assosiaated with identifier
                } else if (phoneNumberACD.length > 0) {
                    phoneNumberACD.forEach(phone => {
                        if (queueCall.dialednumber === phone) {
                            filteredData.push(queueCall);
                        }
                    });
                }
            });
            return filteredData;
        }
        data.map(queueCall => {
            const queueCallCopy = JSON.stringify(queueCall).toLowerCase();
            // We want to display queuemember events and we have a search term
            if (
                displayQueueMemberEvents &&
                term.length &&
                queueCallCopy.includes(term.toLowerCase()) &&
                filterQueues.includes(queueCall.queuename)
            ) {
                filteredData.push(queueCall);
                // We want to display queuemember events and we don't have a search term
            } else if (
                (displayQueueMemberEvents &&
                    !term.length &&
                    filterQueues.includes(queueCall.queuename)) ||
                queueCall.memberEvent
            ) {
                if (!displayQueueMemberEvents && queueCall.memberEvent) {
                    return;
                }
                filteredData.push(queueCall);
                // We don't want to display queuemember events and we have a search term
            } else {
                if (
                    !displayQueueMemberEvents &&
                    queueCallCopy.includes(term.toLowerCase()) &&
                    !queueCall.memberEvent &&
                    filterQueues.includes(queueCall.queuename)
                ) {
                    filteredData.push(queueCall);
                }
            }
        });
        return filteredData;
    }
    // Toggle display of queue memeber events
    async queueMemeberEventsToggle(toggleStatus) {
        await this.setState({
            displayQueueMemberEvents: !this.state.displayQueueMemberEvents,
        });
    }

    changeDisabledFieldsWhenACDApplied(values) {
        if (
            this.state.acdQueues.some(r => values.indexOf(r) >= 0) &&
            this.state.acdSelected
        ) {
            this.setState({ filterQueues: values });
        } else if (
            this.state.acdQueues.some(r => values.indexOf(r) >= 0) &&
            !this.state.acdSelected
        ) {
            this.setState({ filterQueues: values, acdSelected: true });
        } else if (this.state.acdSelected === true) {
            this.setState({ filterQueues: values, acdSelected: false });
        } else {
            this.setState({ filterQueues: values });
        }
    }

    render() {
        const { queueRecords, queues, pending, phoneNumbers } = this.props;
        let { acdQueues} = this.state;
        if (!queueRecords || !queues) {
            return <Loading />;
        }
        let customStartDate = moment()
            .startOf('day')
            .format('MM DD YYYY hh:mm a');
        let customEndDate = moment()
            .endOf('day')
            .format('MM DD YYYY hh:mm a');
        if (this.state.startDate && this.state.startDate.length > 5) {
            customStartDate = moment(this.state.startDate).format(
                'MM DD YYYY hh:mm a'
            );
        }
        if (this.state.endDate && this.state.endDate.length > 5) {
            customEndDate = moment(this.state.endDate).format(
                'MM DD YYYY hh:mm a'
            );
        }
        const gridData = pending ? [] : this.filterData();
        acdQueues.sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1)

        return (
            <div style={style.wrapper}>
                <div style={style.toolBar}>
                    <div style={style.search}>
                        <Search
                            label="Search Records"
                            onSearch={term => this.searchRecords(term)}
                            value={this.state.search}
                        />
                    </div>
                    <div style={style.queueFilter}>
                        <MultiSelectInput
                            label="Filter By Queue"
                            handleChange={values =>
                                this.setState({ filterQueues: values })
                            }
                            names={this.props.queues}
                            values={this.state.filterQueues}
                            disabled={this.state.acdSelected}
                        />
                    </div>
                    <div style={style.queueFilter}>
                        <MultiSelectInput
                            label="Filter By Dialed Numbers"
                            handleChange={values =>
                                this.setState({ filterQueues: values })
                            }
                            names={this.props.dialedNumbers}
                            values={this.state.filterQueues}
                            disabled={this.state.acdSelected}
                        />
                    </div>
                    <div style={style.queueFilter}>
                        <MultiSelectInput
                            label="Filter By ACD Queue Identifier"
                            handleChange={values => {
                                // Boolean set to true if one or more acd queue identifier filters selected, otherwise set to false
                                // When boolean is true all other filters are disabled
                                if (
                                    this.state.acdQueues.some(
                                        r => values.indexOf(r) >= 0
                                    ) &&
                                    this.state.acdSelected
                                ) {
                                    this.setState({ filterQueues: values });
                                } else if (
                                    this.state.acdQueues.some(
                                        r => values.indexOf(r) >= 0
                                    ) &&
                                    !this.state.acdSelected
                                ) {
                                    this.setState({
                                        filterQueues: values,
                                        acdSelected: true,
                                    });
                                } else if (this.state.acdSelected === true) {
                                    this.setState({
                                        filterQueues: values,
                                        acdSelected: false,
                                    });
                                } else {
                                    this.setState({ filterQueues: values });
                                }
                            }}
                            names={acdQueues}
                            values={this.state.filterQueues}
                        />
                    </div>
                    <div style={style.interval}>
                        <TimeIntervalSelect
                            timeIntervals={timeIntervals}
                            timeinterval={this.state.timeInterval}
                            onChange={dateTime =>
                                this.changeDateTimeInverval(dateTime)
                            }
                        />
                    </div>
                    <div style={style.queueMemeberEventsToggle}>
                        <ToggleInput
                            field="paused"
                            onChange={toggleStatus =>
                                this.queueMemeberEventsToggle(toggleStatus)
                            }
                            value={this.state.displayQueueMemberEvents}
                            placeholder="Queue Member Events"
                        />
                    </div>
                    {this.state.timeInterval == 'custom' && (
                        <div style={style.datePicker}>
                            <DateTimePicker
                                ampm={true}
                                autoOk
                                value={customStartDate}
                                format="MMM DD, YYYY hh:mm A"
                                onChange={startDate =>
                                    this.changeStartDate(startDate)
                                }
                                label="Start Date"
                            />
                        </div>
                    )}
                    {this.state.timeInterval == 'custom' && (
                        <div style={style.datePicker}>
                            <DateTimePicker
                                ampm={true}
                                autoOk
                                value={customEndDate}
                                format="MMM DD, YYYY hh:mm A"
                                onChange={endDate =>
                                    this.changeEndDate(endDate)
                                }
                                label="End Date"
                            />
                        </div>
                    )}
                </div>
                {pending && (
                    <LinearProgress
                        style={style.loadingIndicator}
                    />
                )}
                <QueueGrid data={gridData} pending={this.props.pending} />
            </div>
        );
    }
}
const bindActions = dispatch => ({
    
    getCoreHttp: (reqData, storeKey) =>
        dispatch(getCoreHttp(reqData, storeKey)),
    getReportsHttp: (reqData, storeKey, nameSpace) =>
        dispatch(getReportsHttp(reqData, storeKey, nameSpace)),
});
const mapStateToProps = state => ({
    queueRecords:
        state.queueRecords && state.queueRecords.data
            ? state.queueRecords.data
            : null,
    pending: state.queueRecords.pending ? state.queueRecords.pending : false,
    queues:
        state.queueRecords && state.queueRecords.queues
            ? state.queueRecords.queues
            : null,
    dialedNumbers: state.queueRecords.dialedNumbers
        ? state.queueRecords.dialedNumbers
        : null,
    phoneNumbers:
        state.configurations && state.configurations.data
            ? state.configurations.data.phonenumbers
            : null,
});

const timeIntervals = [
    { value: 'today', label: 'Today' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'week', label: 'This Week' },
    { value: 'month', label: 'This Month' },
    // { value: 'custom', label: 'Custom' },
];
const style = {
    wrapper: {
        marginTop: '-20px',
        height: '97vh',
        background: 'linear-gradient(0deg, #0F4E8B 50%, #EFEFEF 50%)',
        alignItems: 'center',
    },
    toolBar: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    },
    interval: {
        marginBottom: '-5px',
        marginLeft: '-25px',
    },
    search: {
        marginTop: '-20px',
        marginLeft: '-25px',
    },
    queueMemeberEventsToggle: {
        marginLeft: '-25px',
        marginTop: '40px',
    },
    datePicker: {
        marginBottom: '5px',
        paddingLeft: '25px',
    },
    queueFilter: {
        marginBottom: '-5px',
        marginLeft: '-25px',
    },
    loadingIndicator: {
        height: 8,
    },
};
export default connect(
    mapStateToProps,
    bindActions
)(QueueRecords);
