import React, { Component } from 'react';
import { connect } from 'react-redux';

//Presenational Components
import DetailContainer from 'presentational/DetailContainer';
import LeftBar from 'presentational/LeftBar';
import Loading from 'presentational/Loading';
import MasterView from 'presentational/MasterView';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import SaveChangesModal from 'presentational/SaveChangesModal';
import DeleteModal from 'presentational/DeleteModal';

//Material Components
import MenuItem from '@material-ui/core/MenuItem';

//Components
import NotificationWorkflowForm from './notificationWorkflowForm';
import NotificationWorkflowHelp from './help';

//Lodash
import get from 'lodash.get';
import isEqual from 'lodash.isequal';

//utils
import { validateEmail } from 'utils/misc';

//Requests
import {
    deleteCoreHttp,
    getCoreHttp,
    postCoreHttp,
    putCoreHttp,
} from 'actions/httpRequest'
import {successNotification, errorNotification } from 'actions/notifications';

import { selectedNotificationWorkflow, resetFlag, resetCreatedWorkflow } from 'actions/notificationWorkflows.js'

class NotificationWorkflows extends Component {
    constructor(props) {
        super(props);

        this.state = {
            closeMenu: false,
            configurations: null,
            clickedInsideElements: false,
            data: null,
            events: {},
            leftBarData: null,
            navAway: false,
            newChanges: false,
            openDropdownElem: '',
            childFormData: {},
            showingSaveChangesModal: false,
            renderFilter: false,
            route: null,
            selectedNotificationWorkflow: null,
            showingDeleteConfirmModal: false,
            workflows: [],
        }

        this.editWorkflow = this.editWorkflow.bind(this);
    }

    componentDidMount = () =>{
        if(this.props.notificationWorkflowData && Object.keys(this.props.notificationWorkflowData).length){
            this.prepareDataForLeftBar(this.props.notificationWorkflowData)
        }
            this.getEventsFriendlyName();
            const reqData = {
                reqAction: 'eventnotificationsettings',
            };
            this.props.getCoreHttp(reqData);
            this.clickedElement();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.state.workflows && this.state.workflows !== prevState.workflows){
            let workflows = [];
            this.state.workflows.map((workflow)=>{
                const event = this.getEventsFriendlyName();
                const uId = event[workflow.event] + ' ' + workflow.object
                workflow.uId = uId
                workflows.push(workflow)
            })
        }

        if (this.props.notificationWorkflowData && this.props.notificationWorkflowData !== prevProps.notificationWorkflowData) {
            this.setState({workflows: this.props.notificationWorkflowData})
            this.prepareDataForLeftBar(this.props.notificationWorkflowData);
            return;
        }

        if(this.props.createdWorkflow && this.state.route ==='new'){
            this.setState({ route: this.props.createdWorkflow, selectedNotificationWorkflow: this.props.createdWorkflow });
            this.prepareDataForLeftBar(this.props.notificationWorkflowData)
            this.getEventsFriendlyName();
            this.props.selectedNotificationWorkflow('')
            this.props.resetCreatedWorkflow();
        }

        const prevData = get(prevProps, 'notificationWorkflowData');
        const currData = get(this.props, 'notificationWorkflowData', 1);

        if(!isEqual(prevData, currData)){
            this.prepareDataForLeftBar(this.props.notificationWorkflowData);
        }

        if(this.props.successFlag) {
            this.props.resetFlag();
            successNotification({
                title: 'Success!', message: this.props.successFlag
            })
        } else if (this.props.failureFlag) {
            this.props.resetFlag();
            errorNotification({
                title: 'Failed!', message: this.props.failureFlag
            })
        }

    }

    createNewWorkflow = (data) => {
        let error = this.validateNewEvent(data)
        //if there is an error while creating a new event, throw an error
        if(error){
            this.leftBarSelect('new')
            this.renderErrorMessage(error)
            return
        }
        //throw error if there is an issue with the action card
        if(data.actions){
            error = this.validateAction(data.actions[0])
            if(error){
                this.leftBarSelect('new')
                this.renderErrorMessage(error)
                return
            }
            //create a uId to populate the title
            const event = this.getEventsFriendlyName();
            const uId = event[data.event] + ' ' + data.object
            data.uId = uId
            let {workflows} = this.state;
            let reqBody = workflows;
            //lets add the new event to the existing events and write to the database
            workflows.push(data);
            const reqData = {
                reqAction: 'eventnotificationsettings',
                reqBody,
            };

            const storeKey = 'createnotificationworkflow';

            this.props.putCoreHttp(reqData, storeKey);

            this.setState({
                clickedInsideElements: false,
                clickedSurroundingElements: false,
                newChanges: false,
            })
        }
    }

    validateNewEvent = (data) => {
        //if you have only partially filled out the new event form throw an error
        if(!data.event || !data.object || !data.actions){
            return "Workflow not created: Please complete all fields"
        }else{
            return false
        }
    }

    renderErrorMessage = (response) => {
        errorNotification({
            title:'Error!',
            message: response
        });
    }

    validateAction = (action) => {
        if(!action.time_condition){
            return 'Please enter a time condition'
        }

        switch(action.type){
        case 'sms':
            if(Number.isInteger(parseInt(action.type_value)) && action.type_value.length == 10 && action.type_value.length !== 0){
                return false;   
            }
            return 'Please enter a 10 digit phone number';

        case 'email':
            return validateEmail(action.type_value)
        case 'webhook':
            if(!action.hook_verb){
                return 'Please enter a request verb'
            }else if(!action.hook_value){
                return 'Please enter a URL'
            }else if(!action.hook_fields){
                return 'Please enter a JSON object'
            }else{
                return false;
            }

        case 'onesig':
            return false;

        default:
            return "There is no action for your event"
        }
  }

    editWorkflow = (data) => {
        let error;
        if(data.actions){
            data.actions.forEach((action) => {
            let failedResponse = this.validateAction(action);
                if (failedResponse){
                    error = failedResponse;
                }
            })

            if(error){
                this.leftBarSelect(data.id)
                this.renderErrorMessage(error)
                return
            }
        }

        let {workflows} = this.state;
        let newWorkflows = []
        workflows.forEach((workflow) => {
            if(workflow.id == data.id){
                workflow = data
            }
            newWorkflows.push(workflow)
        })
        let reqBody = newWorkflows;
        const reqData = {
            reqAction: 'eventnotificationsettings',
            reqBody,
        };

        const storeKey ='editnotificationworkflow';
    
        this.props.putCoreHttp(reqData, storeKey);

        this.setState({
            clickedInsideElements: false,
            clickedSurroundingElements: false,
            newChanges: false,
            workflows: newWorkflows,
        })
   
    }

    madeChanges = (answer, data) => {
        this.setState({
            newChanges: answer,
            childFormData: data,
        })    
    }

    fetchConfigurations = () => {
        const reqData = {
            reqAction: 'configurations',
        }
        
        this.props.getCoreHttp(reqData);
    }

    getEventsFriendlyName = () => {
        let titles = {
            '911': '911 Call from',
            'agent_autopaused': 'Agent Auto Paused on',
            'call_group_exit': 'Call Group Exited',
            'incoming_sms': 'Incoming SMS to',
            'incoming_call': 'Incoming Call to',
            'offline_site_location': 'Location Offline at',
            'parking_lot': 'Parking Lot Answered by',
            'queue_exit': 'Queue exited from',
            'queue_entered': 'Queue entered in',
            'service_load_agreement': 'SLA Limit Exceeded on',  
            'post_call_survey': 'Survey Submitted for',
            'voicemail': 'Voicemail Received on',
            'voicemail_sentiment_analysis': 'Voicemail with Sentiment Analysis Received in'
        }
        this.setState({events: titles});
        return titles;
    }

    goToHelp = () => {
        const win = window.open('http://help.fluentcloud.com/support/solutions', '_blank');
        win.focus();
    }

    handleAddNewClick = () => {
        this.props.selectedNotificationWorkflow('new');
        this.setState({
            route: 'new',
            selectedNotificationWorkflow: null
        })
    }

    navigate = (route) => {
        this.setState({route})
    }

    handleDeleteConfirmClose = (shouldDelete) => {
        this.setState({showingDeleteConfirmModal: false}, () => {shouldDelete && this.handleDeleteNotificationWorkflow()})
    }

    handleDeleteNotificationWorkflow= () => {
        let {workflows, selectedNotificationWorkflow} = this.state;
        var newWorkflows = workflows.filter(workflow => workflow.id !== selectedNotificationWorkflow )
        const notificationName = workflows.filter(workflow => workflow.id == selectedNotificationWorkflow )

        const reqData = {
            reqAction: 'eventnotificationsettings',
            reqBody: newWorkflows,
        };

        const storeKey = 'editnotificationworkflow';
        this.props.putCoreHttp(reqData, storeKey);
        this.setState({
            route: null,
            selectedNotificationWorkflow: null,
            workflows: newWorkflows
        });

        this.props.selectedNotificationWorkflow('');
        successNotification({
            title: 'Success!',
            message: `Deleted ${notificationName[0].uId}`,
        });
        this.prepareDataForLeftBar(newWorkflows)
    }

    leftBarSelect = (eventId) => {
        this.setState({ route: eventId, selectedNotificationWorkflow: eventId });
        this.props.selectedNotificationWorkflow(eventId==='new' ? 'new' : eventId);
        this.unsavedChanges();
      }

    unsavedChanges = () => {
        const { clickedInsideElements, newChanges } = this.state
        if (clickedInsideElements && newChanges ) {
            this.setState({ showingSaveChangesModal: true });
        }
    }

    saveChange = () => {
        this.setState({
          showingSaveChangesModal: false,
          newChanges: false,
          clickedInsideElements: false,
          clickedSurroundingElements: false,
        },
        this.state.childFormData.id ?
            ()=> this.editWorkflow(this.state.childFormData)
        :
            ()=> this.createNewWorkflow(this.state.childFormData))
      }

    discardChange = () => {
        this.setState({
            showingSaveChangesModal: false,
            newChanges: false,
            clickedInsideElements: false,
            clickedSurroundingElements: false,
        });
        if(this.state.route !== 'new'){
            this.discardWorkflow();
        }
    }

    //lets perform a get request to regain our previous action before it was edited
    discardWorkflow = () => {
        const reqData = {
            reqAction: 'eventnotificationsettings',
        };
        this.props.getCoreHttp(reqData)
    }

    clickedNode = () => {
        this.setState({ clickedInsideElements: true})
    }

    clickedElement = () => {
        var elements = document.querySelectorAll(".header, .leftBarDiv, .detailContainerDiv");
        for (var i = 0; i < elements.length; i++) {
          elements[i].addEventListener("click", () => {
            this.unsavedChanges();
          });
        }
      }

    onCancel = (workflow) => {
        this.setState({
            route: null,
            selectedNotificationWorkflow: null,
            newChanges: false,
        });
        this.props.selectedNotificationWorkflow(workflow)
    }

    navBack = () => {
        this.unsavedChanges();
        this.setState({
            route: null,
            selectedNotificationWorkflow: null,
        });
    }

    openCloseMenu = () => {
        this.setState({ closeMenu: !this.state.closeMenu })
    }

    prepareDataForLeftBar = (data) => {
        if(!data){
            return;
        }
        const leftBarData = [];
        const events = this.getEventsFriendlyName();
        data.map((item)=>{
            if(!item.event || !item.object){
                return;
            }
            let title = events[item.event];
            const id = item.id
            const subtitle = item.object;
            leftBarData.push({id, title, subtitle});
            return leftBarData.sort((a, b) =>(a.title.toLowerCase() > b.title.toLowerCase()) ? 1 : -1)
        });

        this.setState({leftBarData: null});

        if(leftBarData && leftBarData.length > 0){
            this.setState({ renderFilter: true})
        }else{
            this.setState({ renderFilter: false})
        }

        return this.setState({ leftBarData});
    }

    renderContent = () => {
        const { route, workflows, selectedNotificationWorkflow, events} = this.state;
        const { configPending } = this.props;
        const index = workflows.findIndex((obj) => obj.id == selectedNotificationWorkflow);
        const notificationWorkflow = workflows[index];
        switch (route) {
            case 'new':
                return <NotificationWorkflowForm
                    key={'new'}
                    formkey={'new'}
                    events={events}
                    configData = {this.props.configurations}
                    selectedNotificationWorkflow = {workflow => this.onCancel(workflow)}
                    submitForm={data => this.createNewWorkflow(data)} 
                    newEventFlag= {true}
                    inUse = {workflows}
                    madeChanges= {(answer, data) => this.madeChanges(answer, data)}
                    clickedNode = {() => this.clickedNode()}
                    configPending = {configPending}
                    />;
            case null:
                return <NotificationWorkflowHelp 
                    key={'help'}
                    />;
            default:
                return <NotificationWorkflowForm
                    key={route}
                    events={events}
                    formKey={route}
                    configData = {this.props.configurations}
                    selectedNotificationWorkflow = {workflow => this.onCancel(workflow)}
                    submitForm={(data) => this.editWorkflow(data)}
                    unsavedChanges= {() => this.unsavedChanges()}
                    notificationWorkflow= {notificationWorkflow}
                    madeChanges= {(answer, data) => this.madeChanges(answer, data)}
                    clickedNode = {() => this.clickedNode()}
                    configPending = {configPending}
                    />;
        }
    }

    render = () => {
        const{ pending } = this.props;
        const { leftBarData, route, selectedNotificationWorkflow, renderFilter, workflows} = this.state;
        const index = workflows.findIndex((obj) => obj.id == selectedNotificationWorkflow);
        const notificationWorkflow = workflows[index];

        const contextMenuOptions = [
            <MenuItem
              disabled={!selectedNotificationWorkflow || selectedNotificationWorkflow==='new'}
              onClick={() => {this.setState({ showingDeleteConfirmModal: true }); this.openCloseMenu();}}
              key={0}
            >
              Delete
            </MenuItem>,
            <MenuItem
              onClick={() => {this.goToHelp(); this.openCloseMenu();}}
              key={1}
            >
              Help
            </MenuItem>,
          ];
        if(pending === true){
            return (
                <StandardSettingsPage>
                    <Loading />
                </StandardSettingsPage>
              );
        }

        return(
            <MasterView>
                <DetailContainer
                    leftBar ={
                        <LeftBar 
                            addNew={() => this.handleAddNewClick()}
                            data = {leftBarData}
                            pending = {pending || !this.props.notificationWorkflowData}
                            selected={selectedNotificationWorkflow}
                            select={ (id) => this.leftBarSelect(id)}
                            title="Notification Workflows"
                            renderFilter = {renderFilter}
                        />
                    }
                    description={notificationWorkflow?notificationWorkflow.uId:null}
                    title={this.state.route ==='new'? "New Notification Workflow":null}
                    route={route != 'new'? route: null}
                    return={() => this.navBack()}
                    contextMenuOptions = {contextMenuOptions}
                    closeMenu={this.state.closeMenu}
                >
                    {this.renderContent()}
                </DetailContainer>
                <DeleteModal
                    open={this.state.showingDeleteConfirmModal}
                    title='Are you sure?'
                    delete={() => this.handleDeleteConfirmClose(true)}
                    cancel={() => this.handleDeleteConfirmClose(false)}
                />
                <SaveChangesModal
                    saveChange={() => this.saveChange()}
                    discardChange={() => this.discardChange()}
                    show={this.state.showingSaveChangesModal}
                />
            </MasterView>
        )
    }

}

const mapStateToProps = state => ({
    createdWorkflow: state.notificationWorkflow.createdWorkflow,
    configurations:state.http ? state.http.configurations : null,
    configPending: state.configurations.pending,
    notificationWorkflowData: state.notificationWorkflow.notificationWorkflowData,
    pending: state.notificationWorkflow.pending,
    successFlag: state.notificationWorkflow.successFlag,
    failureFlag: state.notificationWorkflow.failureFlag
})

const bindActions = (dispatch) => ({
    deleteCoreHttp: (reqData, storeKey) => dispatch(deleteCoreHttp(reqData, storeKey)),
    getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
    postCoreHttp: (reqData, storeKey) => dispatch(postCoreHttp(reqData, storeKey)),
    putCoreHttp: (reqData, storeKey) => dispatch(putCoreHttp(reqData, storeKey)),
    selectedNotificationWorkflow: (data) => dispatch(selectedNotificationWorkflow(data)),
    resetFlag: () => dispatch(resetFlag()),
    resetCreatedWorkflow: () => dispatch(resetCreatedWorkflow())
})

export default connect(mapStateToProps, bindActions)(NotificationWorkflows);