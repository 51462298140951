import React, { Component } from 'react'
import PropTypes from 'prop-types';

import FormControlCard from 'presentational/FormControlCard';

import NumberIcon from '@material-ui/icons/Dialpad';

class AlwaysForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentReference: null,
    };
  }

  componentDidMount() {
    this.setState({
      parentReference: this.props.parentReference,
    });
  }
  render() {
    if (this.state.parentReference == null){
      return null;
    }
    const { parentReference } = this.state;
    return (
      <>
        <FormControlCard
          form={parentReference.state.form}
          field="cfw_always_number"
          formControl="PhoneInputForForwardingNoAdd"
          icon={<NumberIcon/>}
          title="Always Number:"
          subtitle="Always forward to this number"
          submitForm={(field, data, counrtyData) => parentReference.updateNumber(field, data, counrtyData)}
          getCountryCode={(countryCode) => {
            if (countryCode === '+1') {
              parentReference.changeCondition('cfw_always_number', '');
            } else {
              parentReference.changeCondition('cfw_always_number', countryCode);
            }
            parentReference.setState({ countryCode });
          }}
        />
      </>
    );
  }
}

AlwaysForm.propTypes = {
  parentReference: PropTypes.object.isRequired,
}

export default AlwaysForm;