import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

export default function HelpContent(props) {
  const classes = props.useStyles();
  return (
    <div className={classes.wrapper}>
            <Card classes={{ root: classes.helpPaper }} elevation={1}>
              <CardContent classes={{ root: classes.helpContent }}>
                <CardContent>
                  <h2 className={classes.pageTitle}>Incoming Call Filters</h2>
                  { tplHelper.get('label') =='yiptel' ?
                  <p className={classes.helpText}>
                    This section of the portal allows you to configure Incoming Call Filters, which allow you to set
                    specific routes for a specific phone number.
                  </p>
                  :
                  <p className={classes.helpText}>
                    This section of My FluentCloud allows you to configure Incoming Call Filters, which allow you
                    to override any other settings and send a specific phone number to a specific route.
                  </p>
                  }
                </CardContent>
                <CardContent classes={{ root: classes.helpTextTitle }}>
                  About Incoming Call Filters
                </CardContent>
                <CardContent>
                  <p className={classes.helpText}>Incoming Call Filters can be used to route specific phone number 
                  to a specific route, for VIP customers, or known telemarketers.
                  </p>
                  <p className={classes.helpText}>Once you have created your Incoming Call Filter group, you'll want to add 
                  some incoming call filters, using specific phone numbers.</p>
                </CardContent>
              </CardContent>
              <CardContent classes={{ root: classes.standardHelpBlurb }}>
                <CardContent>
                  <h2 className={classes.helpTextTitle}>Need Help?</h2>
                  { tplHelper.get('label') =='yiptel' ?
                  <div>
                  <p className={classes.helpText}>The whole team at YipTel is ready to help you. 24 hours a day, 365 
                  (or 366!) days a year. You can reach us a number of ways.</p>
                  <ul>
                    <li>By calling 800-752-6110</li>
                    <li>By emailing support@yiptel.com</li>
                    <li>By clicking the button below!</li>
                  </ul>
                  </div>
                  :
                  <div>
                  <p className={classes.helpText}>The whole team at FluentStream is ready to help you. 24 hours a day, 365 
                  (or 366!) days a year. You can reach us a number of ways.</p>
                  <ul>
                    <li>By calling 303-GO-CLOUD (303-462-5683)</li>
                    <li>By emailing support@fluentstream.com</li>
                  </ul>
                  </div>
                  }
                  <p />
                </CardContent>
              </CardContent>
            </Card>
          </div>
  );
}