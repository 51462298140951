import React, { Component } from 'react';
import TextInput from './formControls/TextInput';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

class SendReportsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
      userList: [],
      errorText: ''
    }
    this.storeValue = this.storeValue.bind(this);
    this.removeAddress = this.removeAddress.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(){
    if(this.props.value.length && !this.addedDefaultValues){
      this.addedDefaultValues = true;
      this.setState({ userList: this.props.value,})
    }
  }

  onChange(user){
    const userList = this.state.userList.map(item => {
      if(item.email_address === user.email_address){
        return {email_address: item.email_address, attach_confbridge_recordings: item.attach_confbridge_recordings === '0'? '1' : '0'}
      }
      return item;
    })
    this.setState({ userList: userList }, () => this.props.onChange(this.state.userList));
  }

  removeAddress(address) {
    let addressList = [...this.state.userList];
    addressList = addressList.filter(item => item !== address);
    this.setState({ userList: addressList }, () => {
      this.props.onChange(this.state.userList)
    })
  }

  storeValue(e) {
    this.setState({ inputValue: e });
  }

  validateField(email) {
    const addressList = [...this.state.userList];
    var emailReg = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (emailReg.test(email)) {
      addressList.push({email_address:email, attach_confbridge_recordings: '0'});
      this.setState({ userList: addressList, inputValue: '', errorText: '' }, () => this.props.onChange(this.state.userList));
    }
    else{
      this.setState({errorText: 'Invalid email format'})
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div onBlur={ () => this.props.onBlur(this.state.userList)}>
        <div className={classes.container}>
          <TextInput
            onChange={text => this.storeValue(text)}
            placeholder='Email address'
            type="text"
            classes={{ root: classes.textInput }}
            value={this.state.inputValue}
            errorText= {this.state.errorText}
          />
          <Button classes={{ root: classes.button }}
            onClick={() => {
              this.validateField(this.state.inputValue);
            }}>
            <AddIcon classes={{ root: classes.addIcon }} />
          </Button>
        </div>
        <ul style={{ listStyle: "none" }}>
          {this.state.userList.map(item =>
            <li key={item.email_address}>
              <span>{item.email_address}</span>
              <Button classes={{ root: classes.button }}
                onClick={() => {
                  this.removeAddress(item);
                }}>
                <RemoveIcon classes={{ root: classes.removeIcon }} />
              </Button>
              <span>
                       Attach Recording: <input type="checkbox" onChange={() => this.onChange(item)} 
                defaultChecked={item.attach_confbridge_recordings==='0'? false : true} /></span>
            </li>
          )}
        </ul>
      </div>
    )
  }
}

const styles = () => ({
  numberInput:{
    padding: 0,
  },
  button:{
    minWidth: '25px',
  },
  container:{
    display: 'grid',
    gridTemplateColumns: 'auto 30px',
    justifyContent: 'left',
    alignItems: 'center',
  },
  addIcon: {
    color: 'rgb(42, 114, 185)',
  },
  removeIcon: {
    color: 'rgb(201, 60, 44)',
  },
  textInput: {
    paddingRight:"0px"
  },
})

const mapStateToProps = state => ({
  conferenceRoomSelected: state.conferenceRoom.conferenceRoomSelected
})

export default connect(mapStateToProps)(withStyles(styles)(SendReportsList));