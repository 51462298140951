import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import DeleteIcon from '@material-ui/icons/Delete'

class TimeCondition extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      startTime: '',
      endTime: '',
      weekdays: [], 
      timeCondition: {}
    }
    this.loadedDefaultData = false
  }

  componentDidUpdate () {
    const { timeCondition } = this.props;
    if (!this.loadedDefaultData) {
      this.setState({
        timeCondition: timeCondition,
        startTime: timeCondition.startTime,
        endTime: timeCondition.endTime,
        weekdays: timeCondition.weekdays
      })
      this.loadedDefaultData = true
    }
  }

  static getDerivedStateFromProps(props, state){
    const { timeCondition } = props;
    if(props.timeCondition != state.timeCondition){
      return {
        timeCondition,
        startTime: timeCondition.startTime,
        endTime: timeCondition.endTime,
        weekdays: timeCondition.weekdays ? timeCondition.weekdays : timeCondition.weekdays
      }
    }else {
      return null
    }
  }

  render () {
    const weekdays = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ]

    return (
      <div
        style={{
          marginLeft: '29px',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div>
            <TextField
              onChange={e =>
                this.setState(
                  {
                    startTime: e.target.value,
                    field: 'startTime',
                    key: this.props.index
                  },
                  () =>
                    this.props.submitForm(
                      this.state.field,
                      this.state.startTime,
                      this.state.key
                    )
                )
              }
              style={{ marginRight: '20px' }}
              id='startTime'
              label='Start Time'
              type='time'
              key={this.props.index}
              field='startTime'
              value={this.state.startTime}
              InputLabelProps={{
                shrink: true
              }}
            />

            <TextField
              onChange={e =>
                this.setState(
                  {
                    endTime: e.target.value,
                    field: 'endTime',
                    key: this.props.index
                  },
                  () =>
                    this.props.submitForm(
                      this.state.field,
                      this.state.endTime,
                      this.state.key
                    )
                )
              }
              id='endTime'
              label='End Time'
              type='time'
              field='endTime'
              value={this.state.endTime}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div>
            {this.props.index > 0 ? (
              <DeleteIcon
                onClick={() =>this.props.deleteTC(this.props.index)
                }
                style={{ marginRight: '10px' }}
              />
            ) : null}
          </div>
        </div>
        <div>
          {weekdays.map((day, index) => {
            let checked
            this.state.weekdays && this.state.weekdays.includes(day)
              ? (checked = true)
              : (checked = false)

            return (
              <FormControlLabel
                control={<Checkbox color='primary' checked={checked} />}
                label={<span style={{ fontSize: '0.8rem' }}>{day}</span>}
                value={day}
                onChange={e => {
                  const boolVal = e.target.checked
                  let weekdays = [];
                  if(this.state.weekdays && boolVal){
                    weekdays = [...this.state.weekdays, e.target.value]
                  }else if(this.state.weekdays && !boolVal){
                    weekdays = this.state.weekdays.filter(day => {
                       return day != e.target.value
                    } )
                  }else{
                      weekdays = [e.target.value]
                  }
                  this.setState(
                    {
                      weekdays,
                      controlValue: e.target.value,
                      field: 'weekdays',
                      key: this.props.index
                    },
                    () =>
                      this.props.submitForm(
                        this.state.field,
                        this.state.controlValue,
                        this.state.key,
                        boolVal
                      )
                  )
                }}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

export default TimeCondition
