import React, { Component } from 'react';
import SelectInput from 'presentational/formControls/SelectInput';
import SubmitButton from 'presentational/formControls/SubmitButton';
import TextInput from 'presentational/formControls/TextInput';
import ToggleInput from 'presentational/formControls/ToggleInput';

class ParkingLotOptions extends Component {
    state = {
        errors: {},
        form: this.props.form || null,
        submitError: null,
    };

    componentDidMount() {
        const { data } = this.props;
        const applicationOptions = [];
        if (
            data.configurations.data.applications &&
            data.configurations.data.applications.length > 0
        ) {
            data.configurations.data.applications.map(app => {
                const application = {
                    value: app.name,
                    label: app.friendlyname,
                };
                applicationOptions.push(application);
            });
        }
        this.setState({
            applicationOptions,
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.form !== state.form) {
            return {
                form: props.form,
            };
        }
        return null;
    }

    submitForm = (field, data) => {
        if (this.state.form[field] == data) {
            return;
        }
        const { form } = this.state;
        const rollbackForm = Object.assign({}, form);

        // RESET ARGS FIELD WHEN WE CHANGE APPLICATIONS
        if (field == 'parkingtimeout_application'){
            form.parkingtimeout_value = ''
            this.setState({form})
        }

        // Lets validate our field
        const error = this.validateField(field, data);
        if (error) {
            this.setState({
                errors: { [field]: error },
                form: rollbackForm,
            });
            return;
        }

        // If no error, lets set the data
        // on the form and set it to state without errors
        form[field] = data;
        this.setState({
            form,
            errors: { [field]: '' },
        });

        if (field === 'parkingenabled' && !data) {
            this.setState({ errors: {} });
        }
    };

    validateField = (field, data) => {
        const { form } = this.state;
        if (!form.parkingenabled) {
            return null;
        }
        switch (field) {
            case 'parkingenabled':
                if (typeof data !== 'boolean') {
                    return 'Must either be enabled or disabled';
                } else {
                    return null;
                }
            case 'parkinglotcount':
                if (isNaN(data)) {
                    return 'Spaces must be a valid number';
                } else {
                    return null;
                }
            case 'parkinglottimeout':
                if (isNaN(data)) {
                    return 'Timeout must be a valid number';
                } else {
                    return null;
                }
            case 'parkingtimeout_argument':
                if (data.length > 50) {
                    return 'Argument exceeds 50 characters';
                } else {
                    return null;
                }
            case 'parkingexten':
                if (isNaN(data)) {
                    return 'Extension must be a valid number';
                } else {
                    return null;
                }
            default:
                return null;
        }
    };

    renderSelectedAppOptions(app) {
        const configurations = this.props.data.configurations.data;
        let fieldOptions;
        let type;
        let args;
        switch (app) {
            case 'voicemenu':
                fieldOptions = configurations.autoattendant.map(
                    app => app.name
                );
                type = 'select';
            break;
            case 'callflow':
                fieldOptions = configurations.callflows.map(
                    callFlow => callFlow.name
                );
                type = 'select';
            break;
            case 'callforwarding':
                type = 'input';
                break;
            case 'callgroup':
                fieldOptions = configurations.callgroups.map(
                    callGroup => callGroup.name
                );
                type = 'select';
                break;
            case 'conferenceroom':
                fieldOptions = []
                fieldOptions.push({value:"No Room", label: "No Room"});
                configurations.conferenceRooms.forEach(room => {
                    if (room.confno !== "") {
                        fieldOptions.push({
                            value: room.confno,
                            label: `${room.confno} - ${room.name}`
                        });
                    }
                })
                type = 'select'
                break;
            case 'extension':
                fieldOptions = configurations.users.map(user => {
                const extension = {
                    value: user.extension,
                    label: `${user.extension} ${user.firstname} ${
                        user.lastname
                    }`,
                };
                return extension;
            });
            type = 'select';
            break;
            case 'queue':
                fieldOptions = configurations.queues.map(queue => queue.name);
                type = 'select';
                break;
            case 'recording':
                fieldOptions = configurations.recordings.map(
                    recording => recording.name
                );
                type = 'select';
                break;
            case 'voicemailbox':
                fieldOptions = configurations.voicemailboxes.map(
                    voicemailbox => {
                        const mailbox = {
                            value: voicemailbox.mailbox,
                            label: `${voicemailbox.mailbox} ${
                                voicemailbox.fullname
                            }`,
                        };
                        return mailbox;
                    }
                );
                type = 'select';
                args = true;
                break;
            default:
                break;
        }
        if (!type) {
            return;
        }

        switch (app){
            case "conferenceroom":
                break;
            case "extension":
            case "callforwarding":
                break;
            case "voicemailbox":
                fieldOptions.sort((a, b) => (parseInt(a.value)) > parseInt(b.value) ? 1 : -1);
                break;
            default:
                fieldOptions.sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1)
        }

        const { errors, form } = this.state;
        switch (type) {
            case 'input':
                return (
                    <TextInput
                        errorText={this.state.errorText}
                        field="parkingtimeout_value"
                        onChange={data =>
                            this.submitForm('parkingtimeout_value', data)
                        }
                        placeholder="Args:"
                        value={form.parkingtimeout_value}
                    />
                );
            case 'select':
                return (
                    <React.Fragment>
                        <div style={style.customWidth}>
                            <SelectInput
                                style={style.customWidth}
                                options={fieldOptions}
                                placeholder="Select Option"
                                value={form.parkingtimeout_value === "" ? "No Room" : form.parkingtimeout_value}
                                field="parkingtimeout_value"
                                onChange={data =>{
                                    data === "No Room" ? data = '' : data
                                    this.submitForm(
                                        'parkingtimeout_value',
                                        data
                                    )
                                }
                                }
                            />
                        </div>
                        {args && (
                            <TextInput
                                errorText={errors.parkingtimeout_argument}
                                field="parkingtimeout_argument"
                                onChange={data =>
                                    this.submitForm(
                                        'parkingtimeout_argument',
                                        data
                                    )
                                }
                                placeholder="Args:"
                                value={form.parkingtimeout_argument}
                            />
                        )}
                    </React.Fragment>
                );
        }
    }

    render() {
        const { form, errors } = this.state;
        const { submitRequest, pending } = this.props;

        return (
            <div style={style.wrapper}>
                <div style={style.customWidth}>
                    <ToggleInput
                        field="parkingenabled"
                        onChange={data =>
                            this.submitForm('parkingenabled', data)
                        }
                        placeholder="Parking Enabled"
                        value={form.parkingenabled}
                    />
                </div>
                <TextInput
                    errorText={errors.parkingexten}
                    field="extension"
                    enabled
                    onChange={data => this.submitForm('parkingexten', data)}
                    placeholder="Extension"
                    value={form.parkingexten}
                />
                <TextInput
                    errorText={errors.parkinglotcount}
                    field="parkinglotcount"
                    onChange={data => this.submitForm('parkinglotcount', data)}
                    placeholder="Spaces"
                    value={form.parkinglotcount}
                />
                <TextInput
                    errorText={errors.parkinglottimeout}
                    field="parkinglottimeout"
                    onChange={data =>
                        this.submitForm('parkinglottimeout', data)
                    }
                    placeholder="Timeout"
                    value={form.parkinglottimeout}
                />
                <div style={style.customWidth}>
                    <SelectInput
                        style={style.customWidth}
                        options={this.state.applicationOptions}
                        placeholder="Timeout Application"
                        value={form.parkingtimeout_application}
                        field="parkingtimeout_application"
                        onChange={data =>
                            this.submitForm('parkingtimeout_application', data)
                        }
                    />
                </div>
                {form.parkingtimeout_application &&
                    this.renderSelectedAppOptions(
                        form.parkingtimeout_application
                    )}
                <SubmitButton
                    disabled={pending}
                    style={style.submit}
                    onClick={() => submitRequest(form)}
                />
            </div>
        );
    }
}

const style = {
    wrapper: {
        width: 256,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: 50,
    },
    submit: {
        marginTop: 20,
        marginLeft: '50%',
        marginRight: '50%',
    },
    customWidth: {
        width: 350,
    },
};
export default ParkingLotOptions;
