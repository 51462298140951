import React, { Component } from 'react';
import { makeStyles } from '@material-ui/styles';
import KeyCodeCardBuilder from './keyCodeCardBuilder';
import { translateApplication } from 'utils/translations';


class KeyCodeCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showingModal: false,
            mediaQuery: false,
            formErrors: {
                application: '',
                value: '',
            },
        };
    }

    componentDidMount() {
        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
    }

    getTitle(key) {

        switch (key) {
            case 'i':
                return 'Invalid';
            case 't':
                return 'Timeout';
            case 'n':
                return 'None';
            case 'p':
            case 'pound':
                return '#';
            case 's':
            case 'star':
                return '*';
            default:
                return key;
        }
    }

    handleClose(submit) {

        let formErrors = {
            application: '',
            value: '',
        };

        this.setState({ formErrors });
        if (submit) {

            formErrors = this.validateForm();
            if (Object.keys(formErrors).some(err => formErrors[err])) {

                this.setState({ formErrors })
                return;
            }

            this.props.submitForm();
        } else {
            this.props.revertForm();
        }

        this.setState({ showingModal: false });
    }

    validateForm() {

        const { form, number } = this.props;

        let key = number;

        switch (key) {
            case 'n':
                key = 'none';
                break;
            case 'p':
                key = 'pound';
                break;
            case 's':
                key = 'star';
                break;
        }

        const application = `${key}_application`;
        const value = `${key}_value`;

        let formErrors = {};

        if (!this.props.form[application]) {
            formErrors.application = 'Application required';
        }

        let needsArgument = false;
        switch (form[application]) {
            case 'callflow':
            case 'callgroup':
            case 'extension':
            case 'extensionfollowme':
            case 'queue':
            case 'requestcallback':
            case 'route':
            case 'voicemailbox':
            case 'voicemenu':
            case 'zipcoderouter':
                needsArgument = true;
                break;
            default:
                needsArgument = false;
                break;
        }

        if (needsArgument && (!form[value] || form[value] === 'n/a')) {
            formErrors.value = 'Value required for this application';
        }

        return formErrors;
    }

    render() {

        const minWidth = this.state.mediaQuery.matches;

        const useStyle = makeStyles({
            card: {
                width: minWidth ? '50%' : '100%',
                minWidth: '400px',
            },
            editButton: {
                
            },
            deleteButton: {
                color: 'red',
            },
            modal: {
                width: minWidth ? '500px' : '95%',
            },
            iconcontainer: {
                display: 'flex'
            }
        });

        return (
            <KeyCodeCardBuilder
                parent={this} useStyles={useStyle}
            />
        );
    }
}

export default KeyCodeCard;
