import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import get from 'lodash.get';

import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import DetailContainer from 'presentational/DetailContainer';
import LeftBar from 'presentational/LeftBar';
import MasterView from 'presentational/MasterView';
import SaveChangesModal from 'presentational/SaveChangesModal';
import DeleteModal from 'presentational/DeleteModal';

import { getUserPortalHttp, putUserPortalHttp, deleteUserPortalHttp } from 'actions/httpRequest';
import {
  resetFlag,
  selectVoicemail,
  updateReducerData,
  setUpdateRequested,
  resetGotNewData,
} from 'actions/voicemailDrop';
import { errorNotification, successNotification } from 'actions/notifications';

import HelpPage from './helpPage';
import VoicemailDropForm from './voicemailDropForm';
import CreateVoicemailDropForms from './createVoicemailDropForms';
import { navigateInVoicemailDrop, isValid } from './voicemailDropUtils';

function VoicemailDrop(props) {
  const [route, setRoute] = useState(null);
  const [title, setTitle] = useState('');
  const [leftBarData, setLeftBarData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [childData, setChildData] = useState(null);
  const [newChange, setNewChange] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [redirectID, setRedirectID] = useState(null);
  const [redirectVoicemail, setRedirectVoicemail] = useState(null);
  // ComponentDidMount
  useEffect(() => {
    const reqData = {
      reqAction: 'voicemailrecording',
    };

    if (props.fetchedVoicemail === false) {
      props.getUserPortalHttp(reqData);
    }
    
    clickedElement();
  }, []);

  // ComponentDidUpdate
  useEffect(() => {
    const recordingId = get(props, 'routeParams.recordingId', null);
    
    if (props.voicemailDropData.recordings.length !== 0) {
      prepareLeftBarData(props.voicemailDropData);

      if (!props.routeParams) {
        setRoute(null);
      } else if (recordingId !== null && route === null) {
        if (recordingId === 'new') {
          setRoute('new');
        } else {
          const selected = findSelectedObject(recordingId, props.voicemailDropData);
          props.selectVoicemail(selected);
        }
      }
    } else {
      if (recordingId === 'new') {
        setRoute('new');
      } else {
        setLeftBarData([]);
      }
    }
  }, [props.voicemailDropData])

  useEffect(() => {
    if (props.selectedVoicemail) {
      setTitle(props.selectedVoicemail.friendlyname);
      setRoute(props.selectedVoicemail.name);
      setChildData(props.selectedVoicemail);
    }

    if (props.shouldUpdateData) {
      const reqData = {
        reqAction: 'voicemailrecording',
      };
      props.getUserPortalHttp(reqData);
    }
  }, [props.selectedVoicemail, props.shouldUpdateData]);

  useEffect(() => {
    if (props.successMsg) {
      if (props.edited) {
        props.updateReducerData(props.updateRequested);
      }

      props.resetFlag();
      successNotification({
        title: 'Success!',
        message: props.successMsg
      });
    } else if (props.errorMsg) {
      if (props.errorCode === 404) {
        props.resetFlag();
        app.navigate('#userportal', { trigger : true });
      } else {
        props.resetFlag();
        errorNotification({
          title: 'Failed!',
          message: props.errorMsg,
        });
      }
    }
  }, [props.successMsg,props.errorMsg]);

  useEffect(() => {
    if (props.gotNewData) {
      select(props.shouldSelectVoicemail);
      props.resetGotNewData();
    }
  }, [props.gotNewData])

  const makeChanges = (isChanged, data) => {
    setNewChange(isChanged);
    setChildData(data);
  }

  const findSelectedObject = (recordingId, voicemails) => {
    const selected = voicemails.recordings.find(recording => {
      return recording.name === recordingId;
    });

    return selected;
  }

  const renderContent = () => {
    switch (route) {
      case null:
        return <HelpPage />;
      case 'new':
        return <CreateVoicemailDropForms onCancel={closeForm}/>;
      default:
        return <VoicemailDropForm onCancel={closeForm} makeChanges={makeChanges}/>;
    }
  }

  const prepareLeftBarData = (data) => {
    const preparedData = data.recordings.map(voicemail => {
      return ({
        id: voicemail.name,
        title: voicemail.friendlyname,
        subtitle: '',
      });
    });
    setLeftBarData(preparedData);
  }

  const goToHelp = () => {
    const win = window.open('http://help.fluentcloud.com/support/solutions', '_blank');
    win.focus();
  }

  const clickedElement = () => {
    var elements = document.querySelectorAll(".header, .detailContainerDiv, .leftBarDiv");
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", () => {
        setShowUnsavedChangesModal(true);
      });
    }
  }

  const select = (id) => {
    if (id === null) {
      props.selectVoicemail(null);
      navigateInVoicemailDrop();
    } else {
      const selected = findSelectedObject(id, props.voicemailDropData);

      if (newChange) {
        setRedirectID(id);
        setRedirectVoicemail(selected);
        setShowUnsavedChangesModal(true);
        return;
      }

      navigateInVoicemailDrop(id);
      props.selectVoicemail(selected);
    }
  }

  const openNewForm = () => {
    props.selectVoicemail(null);
    setRoute('new');
    navigateInVoicemailDrop('new');
  }

  const closeForm = () => {
    setRoute(null);
    setTitle('');
    props.selectVoicemail(null);
    navigateInVoicemailDrop();
  }

  const deleteRequest = () => {
    const reqData = {
      reqAction: 'voicemailrecording',
      reqObject: props.selectedVoicemail.name,
    };

    const storeKey = 'deletevoicemailrecording';
    props.deleteUserPortalHttp(reqData, storeKey);
    setRoute(null);
    setTitle('');
    props.selectVoicemail(null);
    navigateInVoicemailDrop();
    setShowDeleteModal(false);
  }

  const returnPressed = () => {
    if (newChange) {
      setRoute(null);
      setTitle('');
      setShowUnsavedChangesModal(true);
      props.selectVoicemail(null);
    } else {
      setRoute(null);
      setTitle('');
      props.selectVoicemail(null);
      navigateInVoicemailDrop();
    }
  }

  const editRequest = () => {
    const error = isValid(childData, props.voicemailDropData, false);
    
    if (error) {
      errorNotification(error);
      return;
    }

    props.setUpdateRequested(childData);

    const { countdown_enabled, countdown, feature_code } = childData;
    const payload = {
      countdown,
      countdown_enabled,
      feature_code,
      vm_drop: 1,
    };
    const reqData = {
      reqAction: 'voicemailrecording',
      reqObject: childData.name,
      reqBody: payload,
    };
    const storeKey = 'editvoicemailrecording';
    props.putUserPortalHttp(reqData, storeKey);
  }

  const saveChanges = () => {
    editRequest();
    setNewChange(false);
    setShowUnsavedChangesModal(false);
    if (redirectID) {
      navigateInVoicemailDrop(redirectID);
      props.selectVoicemail(redirectVoicemail);
      setRedirectID(null);
      setRedirectVoicemail(null);
    } else {
      setRoute(null);
      setTitle('');
      props.selectVoicemail(null);
      navigateInVoicemailDrop();
    }
  }

  const discardChanges = () => {
    setNewChange(false);
    setShowUnsavedChangesModal(false);
    if (redirectID) {
      navigateInVoicemailDrop(redirectID);
      props.selectVoicemail(redirectVoicemail);
      setRedirectID(null);
      setRedirectVoicemail(null);
    } else {
      setRoute(null);
      setTitle('');
      props.selectVoicemail(null);
      navigateInVoicemailDrop();
    }
  }

  const contextMenuOptions = [
    <MenuItem
      onClick={goToHelp}
      key={0}
    >Help</MenuItem>,
    <MenuItem
      disabled={!props.selectedVoicemail}
      onClick={() => setShowDeleteModal(true)}
      key={1}
    >Delete</MenuItem>,
  ];

  if (showUnsavedChangesModal && !newChange) {
    setShowUnsavedChangesModal(false);
  } 

  const selectedName = get(props, 'selectedVoicemail.name', null)

  return (
    <MasterView>
      <DetailContainer
        leftBar={
          <LeftBar
            addNew={() => openNewForm()}
            data={leftBarData}
            pending={false}
            selected={selectedName}
            select={(id) => select(id)}
            title="Voicemail Drop"
            noDescription
          />
        }
        title={route !== 'new' ? title : 'New Voicemail Drop'}
        route={null}
        return={returnPressed}
        contextMenuOptions={contextMenuOptions}
        closeMenu={false}
        buttons={null}
        backButtonWithoutRoute={true}
      >
        {renderContent()}
      </DetailContainer>
      <SaveChangesModal
        show={showUnsavedChangesModal && newChange}
        saveChange={saveChanges}
        onClose={() => setShowUnsavedChangesModal(false)}
        discardChange={discardChanges}
      />
      <DeleteModal
        open={showDeleteModal}
        title='Are you sure?'
        content={<p>Do you really want to delete this recording? This action is irreversible.</p>}
        delete={() => deleteRequest()}
        cancel={() => setShowDeleteModal(false)}
      />
    </MasterView>
  );
}

const bindActions = (dispatch) => ({
  getUserPortalHttp: (reqData) => dispatch(getUserPortalHttp(reqData)),
  putUserPortalHttp: (reqData, storeKey) => dispatch(putUserPortalHttp(reqData, storeKey)),
  deleteUserPortalHttp: (reqData, storeKey) => dispatch(deleteUserPortalHttp(reqData, storeKey)),
  resetFlag: () => dispatch(resetFlag()),
  selectVoicemail: (data) => dispatch(selectVoicemail(data)),
  updateReducerData: (data) => dispatch(updateReducerData(data)),
  setUpdateRequested: (data) => dispatch(setUpdateRequested(data)),
  resetGotNewData: () => dispatch(resetGotNewData()),
});

const mapStateToProps = (state) => ({
  voicemailDropData: state.voicemailDrop.voicemailDropData,
  successMsg: state.voicemailDrop.successMsg,
  errorMsg: state.voicemailDrop.errorMsg,
  errorCode: state.voicemailDrop.errorCode,
  routeParams: state.navigation.params,
  selectedVoicemail: state.voicemailDrop.selectedVoicemail,
  shouldUpdateData: state.voicemailDrop.shouldUpdateData,
  shouldSelectVoicemail: state.voicemailDrop.shouldSelectVoicemail,
  fetchedVoicemail: state.voicemailDrop.fetchedVoicemail,
  edited: state.voicemailDrop.edited,
  updateRequested: state.voicemailDrop.updateRequested,
  gotNewData: state.voicemailDrop.gotNewData,
});

VoicemailDrop.propTypes = {
  voicemailDropData: PropTypes.oneOfType([() => null, PropTypes.object]),
  successMsg: PropTypes.oneOfType([() => null, PropTypes.string]),
  errorMsg: PropTypes.oneOfType([() => null, PropTypes.string]),
  errorCode: PropTypes.oneOfType([() => null, PropTypes.number]),
  routeParams: PropTypes.oneOfType([() => null, PropTypes.string]),
  selectedVoicemail: PropTypes.oneOfType([() => null, PropTypes.object]),
  shouldUpdateData: PropTypes.bool,
  shouldSelectVoicemail: PropTypes.oneOfType([() => null, PropTypes.string]),
  fetchedVoicemail: PropTypes.bool,
  edited: PropTypes.bool,
  updateRequested: PropTypes.oneOfType([() => null, PropTypes.object]),
  gotNewData: PropTypes.bool,
}


export default connect(mapStateToProps, bindActions)(VoicemailDrop);
