import React, { Component } from 'react';
import { connect } from 'react-redux';

import ErrorCard from 'presentational/ErrorCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';
import Footer from 'presentational/Footer';

import DragAndDrop from 'presentational/DragAndDrop';
import AutoComplete from 'presentational/autoComplete';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const styles = theme => ({
    root: {
        width: '100%',
        marginBottom: 5,
    },
});

class MulticastDevices extends Component {

    constructor(props) {
        super(props);
        this.state = {

            errors: {},
            revertedForm: {},
            searchText:"",

            submitError: null,
            showingModal: false,
            newChanges: false,
            allSelected: false,
            mediaQuery: false,
            reloadDeviceConfig: false,
            updatedDevices: [],
            openReloadDialog: false,
            selects: 0,
            form:{
                name: '',
                description: '',
                port: '',
                ip_address: '',
                devices: '',
                device_manufacturer: '',
            }
        };
    }

    componentWillMount(){
        let { form } = this.state;
        if(this.props && this.props.group){
            form = Object.assign({}, this.props.group);
        }
        this.setState({form});
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false)
        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));

    }

    componentWillReceiveProps(nextProps){
        if(nextProps && nextProps.reloadDeviceConfig && !this.state.newChanges){
            let { reloadDeviceConfig } = this.props;
            this.setState({ reloadDeviceConfig });
        }
        if (nextProps && nextProps.group && nextProps.group.devices && !this.state.form.devices) {
            this.setState({ form: nextProps.group });
        }
    }

    createRollbackForm() {
        const rollbackForm = Object.assign({}, this.state.form);
        this.setState({ rollbackForm });
    }

    handleClick = (e) => {
        if (this.node) {
            if (!this.node.contains(e.target)) {
                this.props.clickedNode();
            }
        }
    }

    handleSelectedDeletion(item){
        let macaddress = item.id;
        let {form, reloadDeviceConfig} = this.state;
        let selectedDevices = form.devices;
        let index = selectedDevices.findIndex(device => device.macaddress == macaddress);
        selectedDevices = selectedDevices.splice(index, 1);
        reloadDeviceConfig = false;
        this.setState({
            reloadDeviceConfig,
            newChanges: true,
            form,
            searchText: '',
        }, () => this.props.madeChanges(this.state.newChanges, this.state.form));
    }

    updateOrder(){

    }

    addDevice(macaddress){

        let selectedDevices = [];
        let fullDevice = {};
        const { devices } = this.props;
        let { form, reloadDeviceConfig } = this.state;

        if (macaddress.value == "add-all") {
            selectedDevices = devices.map(device => { return { device_id: device.id, macaddress: device.macaddress }})
            this.setState({allSelected: true});
        } else{
            if (form && form['devices']) {
                selectedDevices = form['devices'];
            }

            devices.forEach(device => {
                if(device.macaddress == macaddress.value){
                    fullDevice['device_id'] = device.id;
                    fullDevice['macaddress'] = device.macaddress;
                }
            });

            if(fullDevice && fullDevice['device_id']) {
                selectedDevices.push(fullDevice);
            } else {
                this.setState({deviceError: "This is an invalid device! Please select from the dropdown."});
                return;
            }
        }
        // If no error, lets set the data
        // on the form and set it to state without errors
        form['devices'] = selectedDevices;
        reloadDeviceConfig = false;

        this.setState({
            reloadDeviceConfig,
            newChanges: true,
            form,
            searchText: '',
            deviceError: '',
        }, () => this.props.madeChanges(this.state.newChanges, this.state.form));
    }

    submitFullForm(cancel){
        if(cancel){
            this.props.cancel();
        } else{
            this.props.submitForm(this.state.form);
            this.setState({ newChanges: false });
        }
    }

    renderDeviceList(){

        let styles= {
          numberInput:{
            padding: 0,
          },
          button:{
            minWidth: '25px',
          },
          container:{
            display: 'grid',
            gridTemplateRows: 'auto 30px',
            justifyContent: 'center',
            alignItems: 'center',
          }
        }

        const { devices } = this.props;
        let properdevices = [];
        let returndevices = [{ text: 'Add All Devices', value: 'add-all' }];
        if(this.state.allSelected){
            returndevices = [];
        }
        let selectedDevices = [];
        let deviceData = [];
        let items = {};
        if(this.state.form.devices){
            selectedDevices = this.state.form.devices;
        }

        devices.forEach(device => {
            if(device.macaddress){
                properdevices.push(device);
            }
        });

        properdevices.forEach( device => {
            let associatedExtensions = "";
            if(device.associatedExtensions && device.associatedExtensions.length){
                device.associatedExtensions.forEach((extension, index)=>{
                    if (index !== device.associatedExtensions.length -1){
                        associatedExtensions += `${extension}, `;
                    } else{
                        associatedExtensions += `${extension}`;
                    }
                });
            }

            if(associatedExtensions){
                items[device.macaddress] = { id: device.macaddress, content: `${associatedExtensions} - ${device.macaddress}` };
                deviceData.push({ text: `${associatedExtensions} - ${device.macaddress}`, value: device.macaddress });
            } else {
                items[device.macaddress] = { id: device.macaddress, content: device.macaddress };
                deviceData.push({ text: device.macaddress, value: device.macaddress });
            }

            let found = false;
            selectedDevices.map(selectedDevice => {
                if (device.macaddress == selectedDevice.macaddress || selectedDevice == device.macaddress) {
                    found = true;
                }
            });
            if(!found){
                if (associatedExtensions) {
                    returndevices.push({text: `${associatedExtensions} - ${device.macaddress}`, value: device.macaddress});
                } else {
                    returndevices.push({ text: `${device.macaddress}`, value: `${device.macaddress}`});
                }
            } 
        });

        if(returndevices && returndevices.length == 1 && returndevices[0].value == "add-all"){
            returndevices = [];
        }

        if(selectedDevices){
            selectedDevices = selectedDevices.map(device => device.macaddress);
        }

        const initialData = {
            items,
            columns: {
              'right': {
                id: 'right',
                // title: 'Selected Devices',
                itemIds: selectedDevices,
              }
            },

            columnOrder: ['right']
        };

        return (

            <div>
                <div className="header" style={{margin: '0 0 -20px 10px'}}>
                    <h3>Select Multicast Devices:</h3>
                </div>
                <AutoComplete
                  style={{ margin: "0 0 20px 15px" }}
                  listStyle={{ maxHeight: 100, overflow: 'auto' }}
                  floatingLabelText="Search devices to select"
                  filter={AutoComplete.fuzzyFilter}
                  errorText={this.state.deviceError}
                  searchText={this.state.searchText}
                  onUpdateInput={(searchText) => this.setState({ searchText, })}
                  onNewRequest={ (device) => this.addDevice(device) }
                  openOnFocus={true}
                  suggestions={ returndevices }
                />
                <div className="header" style={{ marginLeft: "10px" }}>
                    <h3>Selected Devices:</h3>
                </div>
                <DragAndDrop
                  updateOrder={(updatedUserList) => this.updateOrder(updatedUserList)}
                  handleSelectedDeletion={(item) => this.handleSelectedDeletion(item)}
                  handleSelectedAddition={(item) => this.handleSelectedAddition(item)}
                  data={initialData}
                  columnPlaceholder="Search selected devices"
                />
                <Button
                    disabled={!this.state.reloadDeviceConfig}
                    label="Reload Devices"
                    onClick={() => this.setState({ openReloadDialog: true })}
                    style={{ width: "100%" }}
                    color="primary"
                    variant="contained"
                >Reload Devices</Button>
                <Footer
                    disabled={!this.state.newChanges}
                    onClick={(cancel) => this.submitFullForm(cancel)}
                />
            </div>
        )
    }

    renderReloadDevicesDialog() {
        const { openReloadDialog, form } = this.state;
        const { devices, classes } = this.props;
        const formDevicesObj = {};

        if (form && form.devices && devices) {
            // created a hash map to easily reference what devices are selected in the drag n drop section to avoid using a nested loop
            form.devices.forEach(device => formDevicesObj[device.macaddress] = 1);
            devices.forEach(device => {
                if (formDevicesObj[device.macaddress] == 1) {
                    formDevicesObj[device.macaddress] = [device.associatedExtensions, device.manufacturer];
                }
            })
    
            return (
                <Dialog open={openReloadDialog} onClose={() => this.handleDialogClose(false)} fullWidth={true}>
                    <DialogTitle>Reload Devices</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            In order to reload the devices in this multicast group, select an extension
                            for each device and click on the "Reload" button at the bottom of the modal.
                            Depending on your model the phone may reboot itself, resulting in some downtime.
                            (Currently available for only Yealink and Mitel devices).
                        </DialogContentText>
                        {Object.entries(formDevicesObj).map((device, index) => {
                            if (device[1][1] == "Yealink" || device[1][1] == "Mitel") {
                                if (!this.state[`selectedDevice${index}Val`]) {
                                    // setting the first extension in the array of associated extensions as the default value of the select component
                                    this.setState({ [`selectedDevice${index}Val`]: device[1][0].length > 0 && device[1][0][0] ? device[1][0][0] : 'none', selects: Object.values(formDevicesObj).length })
                                }
                                return <FormControl className={classes.root}>
                                    <InputLabel id={`select${index}Label`}>{`${device[0]} Reload Extension`}</InputLabel>
                                    <Select
                                        labelId={`select${index}label`}
                                        value={this.state[`selectedDevice${index}Val`]}
                                        onChange={e => this.setState({ [`selectedDevice${index}Val`]: e.target.value })}
                                    >
                                        {device[1][0].length ? 
                                            device[1][0].map(extension => {
                                                if (extension) {
                                                    return <MenuItem value={extension}>
                                                        {extension}
                                                    </MenuItem>
                                                }
                                            }) :
                                            <MenuItem value={'none'}>No Associated Extensions</MenuItem>
                                        }
                                    </Select>
                                </FormControl>
                            }
                        })}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDialogClose(false)}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.handleDialogClose(true, formDevicesObj)}>
                            Reload
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        }
    }

    handleDialogClose(reload, devicesObj) {
        if (reload) {
            Object.entries(devicesObj).forEach(async (deviceArr, index) => {
                if (!this.state[`selectedDevice${index}Val`] || this.state[`selectedDevice${index}Val`] == 'none') {
                    return;
                }
                const url = `${window.BASE_URL ? window.BASE_URL : ""}/api/v1/call/${app.token.get('tenant')}/reboot/${this.state[`selectedDevice${index}Val`]}`;

                try {
                    const response = await fetch(url, { method: "PUT" });
                    if (response) {
                        app.notifications.add({
                            message: `<strong>Successfully Reloaded Device ${deviceArr[0]}!</strong> A configuration reload signal has been sent to ${deviceArr[0]}. Please allow a few minutes for settings to take effect.`,
                            type: 'info',
                            dismiss: true,
                        });
                    }
                } catch (error) {
                    app.notifications.add({
                        message: `<strong>Failed to Reload ${deviceArr[0]}!</strong> Something went wrong, please try again. If a problem persists, please contact a support agent.`,
                        type: 'danger',
                        dismiss: false,
                    });
                }
            });
            this.setState({ openReloadDialog: false, reloadDeviceConfig: false });
        } else {
            this.setState({ openReloadDialog: false });
        }
    }

    render() {
        if ((!this.props.group && !this.state.new) || !this.props.devices) {
            return (
                <StandardSettingsPage>
                    <Loading />
                </StandardSettingsPage>
            );
        }

        return (
            <div ref={node => this.node = node} style={{
                display: 'flex',
                flex: 1,
                height: '100%',
                background: '#EFEFEF',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: '50px',
                overflow: 'auto',
            }}>
                <StandardSettingsPage>
                    {this.state.submitError ? <ErrorCard data={this.state.submitError} /> : null}
                    {this.renderDeviceList()}
                    {this.renderReloadDevicesDialog()}
                    <hr />
                </StandardSettingsPage>
          </div>
        );
    }
}

const bindActions = (dispatch) => {};

const mapStateToProps = (store) => ({
    navigation: store.navigation,
    multicastGroups:store.multicastGroups,
    pending: store.multicastGroups.pending,
    failure: store.multicastGroups.failure,
});

export default connect(mapStateToProps, bindActions)(withStyles(styles)(MulticastDevices));
