import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';

import List from './list';
import Loading from './loading';
import Search from 'presentational/Search';
import Title from './title';
import Wrapper from './wrapper';
import MultiSelectChip from 'presentational/MultipleSelectChip';

import { keySrt } from 'utils/misc';

export default class LeftBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchInput: '',
            selectInput: '',
            multiSelectInput: '',
            filteredData: [],
            dataLength: 50,
        };
    }

    prepareDataForLeftBar(data) {

        // return on no data
        if (!data) {
            return null;
        }

        // array we will push our new object into
        const leftBarData = [];

        data.map( (item) => {

            // Set the title to friendly name if we have it, otherwise just the name
            const title = item.friendlyname ? item.friendlyname : item.name;
            const subtitle = item.description ? item.description : "";
            const id = item.name;

            // push the data into the leftBar array
            return leftBarData.push({ id, title, subtitle });
        });

        return leftBarData;
    }
    
    onSearch(searchInput, isMultiselect) {
        isMultiselect ? this.setState({ multiSelectInput: searchInput }) : this.setState({ searchInput });
    }

    getFilteredData() {
        
        if (!this.props.data || this.props.data.length == 0) {
            return null;
        }

        let sortedEntry = [];
        let extension = {};
        let favData;
        let notFavData;

        if (this.props){
            switch (this.props.title) {

                case 'Conference Rooms':
                    // Need to sort numerically
                    this.props.data.forEach(entry => {
                        let id = entry.id;
                        let title = entry.title;
                        let subtitle = entry.subtitle;
                        extension = {id, title, subtitle};
                        sortedEntry.push(extension);
                    });
                    sortedEntry.sort((a, b)=> a.id - b.id);
                    favData = this.props.data.filter(item => item.favorite).sort(keySrt('id', 'asc'));
                    notFavData = this.props.data.filter(item => !item.favorite).sort(keySrt('id', 'asc'));
                    break;
                
                case 'Dynamic Agents':
                    // Need to sort numerically
                    this.props.data.forEach(entry => {
                        let id = entry.id;
                        let title = entry.title;
                        let subtitle = entry.subtitle;
                        extension = {id, title, subtitle};
                        sortedEntry.push(extension);
                    });
                    sortedEntry.sort((a, b)=> a.id - b.id);
                    favData = this.props.data.filter(item => item.favorite).sort(keySrt('id', 'asc'));
                    notFavData = this.props.data.filter(item => !item.favorite).sort(keySrt('id', 'asc'));
                    break;

                case 'Voicemail Boxes':
                    // Need to sort numerically
                    this.props.data.forEach(entry => {
                        let id = entry.id;
                        let title = entry.title;
                        let subtitle = entry.subtitle;
                        extension = {id, title, subtitle};
                        sortedEntry.push(extension);
                    });
                    sortedEntry.sort((a, b)=> a.id - b.id);
                    favData = this.props.data.filter(item => item.favorite).sort(keySrt('id', 'asc'));
                    notFavData = this.props.data.filter(item => !item.favorite).sort(keySrt('id', 'asc'));
                    break;
                case 'Short Codes':
                    this.props.data.forEach(entry => {
                        sortedEntry.push(entry);
                    });
                    sortedEntry.sort((a, b) => a.id - b.id);
                    favData = this.props.data
                        .filter(item => item.favorite)
                        .sort(keySrt('id', 'asc'));
                    notFavData = this.props.data
                        .filter(item => !item.favorite)
                        .sort(keySrt('id', 'asc'));
                    break;
                default:
                    //Need to sort alphabetically
                    this.props.data.forEach(entry => {
                        const { id, title, subtitle } = entry;
                        extension = { id, title, subtitle };
                        sortedEntry.push(extension);
                    });
                    sortedEntry.sort((a, b) => a.codeNumber - b.codeNumber);

                    favData = this.props.data
                        .filter(item => item.favorite)
                        .sort(keySrt('title', 'asc'));
                    notFavData = this.props.data
                        .filter(item => !item.favorite)
                        .sort(keySrt('title', 'asc'));
                    break;

            }
        }

        const orderedData = [...favData, ...notFavData];
        if (this.props.noOrdering && !this.state.searchInput && !this.state.multiSelectInput) {
            return this.props.data;
        }

        if (this.props.phoneNumber && !this.state.searchInput && !this.state.multiSelectInput) {
            return orderedData;
        }

        if (!this.state.searchInput&& !this.state.multiSelectInput) {
            return sortedEntry;
        }

        const phoneFilterData = [];
        const domesticRegex = /(\+\d*)?\w?\(\d{3}\) \d{3}-\d{4}/;
        const internationalRegex = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/

        let filteredData = cloneDeep(orderedData)
        if(this.state.multiSelectInput && this.state.multiSelectInput.length){
            filteredData = orderedData.filter(obj => this.state.multiSelectInput.includes(obj.title));
        }
        const cleanedSearchInput = this.state.searchInput.replace(/[^a-zA-Z0-9\+]/g, "");

        if (this.props.noOrdering) {
            this.props.data.map((item) => {

                if (item.title.toLowerCase().indexOf(this.state.searchInput.toLowerCase()) !== -1 ||
                    item.subtitle.toLowerCase().indexOf(this.state.searchInput.toLowerCase()) !== -1) {
    
                    return filteredData.push(item);
                }
            });
            return filteredData;
        }
        // Lets map through dids to get sms enabled ones
        orderedData.map((item) => {
            if(domesticRegex.test(item.title) || internationalRegex.test(item.title)){
                const number = item.title.replace(/\D/g,'');
                if (number.indexOf(this.state.searchInput) !== -1 ||
                    item.subtitle.toLowerCase().indexOf(this.state.searchInput.toLowerCase()) !== -1 || 
                    item.id.includes(cleanedSearchInput)){
                        return phoneFilterData.push(item);
                }
            }
        });

        filteredData = filteredData.filter(obj =>{
            let searchString = obj.title.toLowerCase() + obj.subtitle.toLowerCase()
            return searchString.includes(this.state.searchInput);
        });

        if(this.props.title !== "Phone Numbers" && this.props.title !== "Phone Numbers Manager"){
            return filteredData
        }else{
            return phoneFilterData
        }
    }


    renderUtilityComponents(){
        return this.props.utilityComponents.map((renderUtilityComponent, index) => {
            return <div key={index}>{renderUtilityComponent}</div>
        } )
    }

    setUrlHash(id){
        //Update the window address bar to include the id param
        //Some navigations work flows change id through props, this updates the url to match
        //Limit url updates to sections using route params
        const useRouteParams = ['#voicemailgreetings', '#voicemailsettings']
        const section = window.location.hash.split("/")[0];
        if (useRouteParams.includes(section)) {
            window.location.hash = `${section}/${id}`
        }
    }

    loadMore = () => {
        this.setState({
            dataLength: this.state.dataLength + 10,
        });
    }

    render() {
        return (
          <div className='leftBarDiv'>  
            <Wrapper open={this.props.leftBarOpen}>
                <Title
                addNew={() => {
                    this.props.toggleDrawer();
                    this.props.addNew();
                }}
                title={this.props.title}
                noAdd={this.props.noAdd}
                />
                {this.props.renderFilter ?
                <MultiSelectChip
                    label = "Filter Event"
                    onMultiSelect={(text, isMultiselect) => this.onSearch(text, isMultiselect)} value={this.state.multiSelectInput}
                    data = {this.props.data}
                /> : <div></div>}
                <Search onSearch={(text) => this.onSearch(text)} value={this.state.searchInput}/>
                
                {this.props.utilityComponents ? this.renderUtilityComponents() : null}

                {this.props.pending ?
                <Loading /> : 
                <List
                    data={this.getFilteredData()}
                    onSelect={(id, e) => {
                        this.props.toggleDrawer();
                        this.props.select(id, e);
                        this.setUrlHash(id);
                    }}
                    addNew={() => {
                        this.props.toggleDrawer();
                        this.props.addNew();
                    }}
                    selectedItem={this.props.selected}
                    noDescription={this.props.noDescription}
                    useInfiniteScroll={this.props.useInfiniteScroll}
                    dataLength={this.state.dataLength}
                    loadMore={this.loadMore}
                />}
            </Wrapper>
          </div>
        );
    }
}

LeftBar.defaultProps = {
    data: null,
    noDescription: false,
    pending: false,
    selected: null,
    noAdd: false,
    useInfiniteScroll: false,
};

LeftBar.propTypes = {
    data: PropTypes.array,
    noDescription: PropTypes.bool,
    pending: PropTypes.bool,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    select: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    noAdd: PropTypes.bool,
    useInfiniteScroll: PropTypes.bool,
};
