import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Button from '@material-ui/core/Button';
import makeGlobalStyles from 'makeGlobalStyles';

export default function HelpContent(props) {
  const classes = props.useStyles();
  const globalClasses = makeGlobalStyles();
  return (
    <div className={classes.wrapper}>
      <Card classes={{ root: classes.helpPaper }} elevation={1}>
        <CardContent classes={{ root: classes.helpContent }}>
          <CardContent>
          <h2 className={classes.pageTitle}>Manage Notification Workflows</h2>
          <p className={classes.helpText}>
            Notifcation workflows allow the ability to receive notifications for different call events.
          </p>
          </CardContent>
          <CardContent classes={{ root: classes.helpTextTitle }}>
            About Notification Workflows
          </CardContent>
          <CardContent>
            <div>
              <p>Notification workflows will allow the ability to receive notifications by email, SMS, webhook, or Chrome Push Notifications.</p>
            </div>
          </CardContent>
        </CardContent>
        <CardContent classes={{ root: classes.standardHelpBlurb }}>
          <CardContent>
            <h2 className={classes.helpTextTitle}>Need Help?</h2>
            <div>
              <p className={classes.helpText}>The whole team at FluentStream is ready to help you. 24 hours a day, 365 days a year.
                You can reach us a number of ways.</p>
              <ul>
                <li>By calling 303-GO-CLOUD (303-462-5683)</li>
                <li>By emailing support@fluentstream.com</li>
                <li>By clicking the button below!</li>
              </ul>
            </div>
            <p />
            <Button
              size='large'
              classes={{ root: globalClasses.helpButton }}
              href="http://help.fluentcloud.com/support/solutions/articles/4000065950-notification-workflows"
              target="_blank"
            >How To Set Up Notification Workflows</Button>
          </CardContent>
        </CardContent>
      </Card>
    </div>
  );
}