import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/styles';

import List from 'presentational/LeftBar/list';
import Loading from 'presentational/Loading';
import Search from 'presentational/Search';
import Title from './title';
import Wrapper from 'presentational/LeftBar/wrapper';

import { keySrt } from 'utils/misc';

const styles = () => ({
    menuItem: {
        fontSize: '100%',
    },
  });

class ReportsList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            searchInput: '',
            filteredData: [],
        };
    }

    onSearch(searchInput) {
        this.setState({ searchInput });
    }

    getFilteredData() {

        if (!this.props.data || this.props.data.length == 0) {
            return null;
        }

        const orderedData = this.props.data.sort(keySrt('title', true));

        if (!this.state.searchInput) {
            return orderedData;
        }

        const filteredData = [];

        // Lets map through report configurations to get searched reports
        orderedData.map((item) => {

            if (item.title.toLowerCase().indexOf(this.state.searchInput.toLowerCase()) !== -1 ||
                item.subtitle.toLowerCase().indexOf(this.state.searchInput.toLowerCase()) !== -1) {

                return filteredData.push(item);
            }
        });

        return filteredData;
    }

    render() {

        const contextMenuOptions = [];

        if(this.props.data){
            this.props.data.map((report) => {
                contextMenuOptions.push(
                    <MenuItem
                        key={report.id}
                        classes={{ root: this.props.classes.menuItem }}
                    >{report.subtitle || report.title}</MenuItem>
                );
            });
        }

        return (
          <Wrapper open={this.props.leftBarOpen}>
            <Title
              addNew={() => {
                this.props.toggleDrawer();
                this.props.addNew();
            }}
              title={this.props.title}
              contextMenuOptions={contextMenuOptions}
            />
            <Search onSearch={(text) => this.onSearch(text)} />

            {this.props.pending ?
              <Loading /> :
              <List
                data={this.getFilteredData()}
                onSelect={(id) => {
                    this.props.toggleDrawer();
                    this.props.select(id)
                }}
                addNew={() => {
                    this.props.toggleDrawer();
                    this.props.addNew();
                }}
                selectedItem={this.props.selected}
              />
            }

            <Search onSearch={(text) => this.onSearch(text)} />

            {this.props.pending ?
              <Loading /> :
              <List
                data={[]}
                onSelect={(id) => {
                    this.props.toggleDrawer();
                    this.props.select(id)
                }}
                addNew={() => {
                    this.props.toggleDrawer();
                    this.props.addNew();
                }}
                selectedItem={this.props.selected}
              />
            }

          </Wrapper>
        );
    }
}

export default withStyles(styles)(ReportsList);
