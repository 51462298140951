/*
This hook includes all of the CRUD actions for Short Codes that need to go through 
our http middleware. This allows us to use these functions without binding actions 
and mapping state to props, so these functions can be used anywhere for any 
delete, get, post or put. The useCallback hook is needed to memoize these 
functions so that any child components we pass these to don't render 
unnecessarily due to change of refrence.
*/
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    RESET_FLAG,
    GET_HTTP,
    DELETE_HTTP,
    POST_HTTP,
    PUT_HTTP,
    CORE_NAMESPACE,
} from '../../actions/typeConstants';

const useShortCodesActions = () => {
    const dispatch = useDispatch();

    // actions

    const deleteCoreHttpHook = useCallback(
        (requestData, storeKey) =>
            dispatch({
                type: DELETE_HTTP,
                namespace: CORE_NAMESPACE,
                requestData,
                storeKey,
            }),
        [dispatch],
    );

    const getCoreHttpHook = useCallback(
        (requestData, storeKey) =>
            dispatch({
                type: GET_HTTP,
                namespace: CORE_NAMESPACE,
                requestData,
                storeKey,
            }),
        [dispatch],
    );

    const postCoreHttpHook = useCallback(
        (requestData, storeKey) =>
            dispatch({
                type: POST_HTTP,
                namespace: CORE_NAMESPACE,
                requestData,
                storeKey,
            }),
        [dispatch],
    );

    const putCoreHttpHook = useCallback(
        (requestData, storeKey) =>
            dispatch({
                type: PUT_HTTP,
                namespace: CORE_NAMESPACE,
                requestData,
                storeKey,
            }),
        [dispatch],
    );

    const resetFlag = useCallback(
        () =>
            dispatch({
                type: RESET_FLAG,
            }),
        [dispatch],
    );

    return {
        deleteCoreHttpHook,
        getCoreHttpHook,
        postCoreHttpHook,
        putCoreHttpHook,
        resetFlag,
    };
};

export default useShortCodesActions;
