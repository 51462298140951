/*
This is the custom error handling object for creating/editing
Short Codes. Since we need to do many different checks on the codes,
this shortens the validateValues function in the shortCodesContext.js file 
and consolidates all of the error reasons for us.
*/
import React, { useState } from 'react';
import { errorNotification } from '../../actions/notifications';

const useShortCodesValidation = () => {
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const errorObj = {
        undefinedFcn: {
            single: () => {
                errorNotification({
                    title: `Code Not Saved.`,
                    message: `Please make sure all of the fields are filled out.`,
                });
                setButtonDisabled(true);
            },
            iterating: row => {
                row.id = 'undefined';
                row.disabled = true;
                row.checked = false;
                row.reason = <p>Please make sure all of the fields are filled out.</p>;
                return row;
            },
        },
        extension: {
            single: codeNumber => {
                errorNotification({
                    title: `Code ${codeNumber} Not Saved.`,
                    message: `The code number ${codeNumber} already exists as an extension, please choose another code number.`,
                });
                setButtonDisabled(true);
            },
            iterating: (findLikeExtension, row) => {
                const extNum = findLikeExtension.get('extension');
                const name = findLikeExtension.get('firstname');
                row.id = extNum;
                row.disabled = true;
                row.checked = false;
                row.reason = (
                    <p>
                        Extension{' '}
                        <a target="_blank" href={`#extensions/${extNum}`}>
                            {name} #{extNum} <i className="icon-external-link" />
                        </a>{' '}
                        exists.
                    </p>
                );
                return row;
            },
        },
        conferenceRoom: {
            single: findLikeConfrenceRoom => {
                errorNotification({
                    title: `Code ${findLikeConfrenceRoom.confno} Not Saved.`,
                    message: `The Conference Room
                        ${findLikeConfrenceRoom.name} with extension:
                        #${findLikeConfrenceRoom.meet_extension} already exists, please choose another code number.`,
                });
                setButtonDisabled(true);
            },
            iterating: (findLikeConfrenceRoom, row) => {
                row.id = findLikeConfrenceRoom.meet_extension;
                row.disabled = true;
                row.checked = false;
                row.reason = (
                    <p>
                        Conference Room
                        <a
                            target="_blank"
                            href={`#conferencerooms/${findLikeConfrenceRoom.confno}`}
                        >
                            {findLikeConfrenceRoom.name}: {findLikeConfrenceRoom.meet_extension}{' '}
                            <i className="icon-external-link" />
                        </a>{' '}
                        exists.
                    </p>
                );
                return row;
            },
        },
        parkingLot: {
            single: codeNumber => {
                errorNotification({
                    title: `Code ${codeNumber} Not Saved.`,
                    message: `The Code Number ${codeNumber} interferes with Parking Lot numbers.`,
                });
                setButtonDisabled(true);
            },
            iterating: (codeNumber, row) => {
                row.id = codeNumber;
                row.disabled = true;
                row.checked = false;
                row.reason = (
                    <p>The Code Number {codeNumber} interferes with Parking Lot numbers.</p>
                );
                return row;
            },
        },
        queueExt: {
            single: findLikeQueueExt => {
                errorNotification({
                    title: `Code ${findLikeQueueExt.extension} Not Saved.`,
                    message: `Queue Extension ${findLikeQueueExt.friendlyname} with: 
                        #${findLikeQueueExt.extension} already exists, please choose another code number.`,
                });
                setButtonDisabled(true);
            },
            iterating: (findLikeQueueExt, row) => {
                row.id = findLikeQueueExt.extension;
                row.disabled = true;
                row.checked = false;
                row.reason = (
                    <p>
                        Queue Extension{' '}
                        <a target="_blank" href={`#queues/${findLikeQueueExt.name}`}>
                            {findLikeQueueExt.friendlyname} #{findLikeQueueExt.extension}{' '}
                            <i className="icon-external-link" />
                        </a>
                        exists.
                    </p>
                );
                return row;
            },
        },
        callGroup: {
            single: findLikeCallGroupExt => {
                errorNotification({
                    title: `Code ${findLikeCallGroupExt.extension} Not Saved.`,
                    message: `Call Group extension ${findLikeCallGroupExt.name} with
                        #${findLikeCallGroupExt.extension} already exists, please choose another code number.`,
                });
                setButtonDisabled(true);
            },
            iterating: (findLikeCallGroupExt, row) => {
                row.id = findLikeCallGroupExt.extension;
                row.disabled = true;
                row.checked = false;
                row.reason = (
                    <p>
                        Call Group #:{' '}
                        <a target="_blank" href={`#callgroups`}>
                            {findLikeCallGroupExt.name} #{findLikeCallGroupExt.extension}{' '}
                            <i className="icon-external-link" />
                        </a>{' '}
                        exists.
                    </p>
                );
                return row;
            },
        },
        currentCode: {
            single: codeNumber => {
                errorNotification({
                    title: `Code ${codeNumber} Not Saved.`,
                    message: `The code number already exists, please choose another code number.`,
                });
                setButtonDisabled(true);
            },
            iterating: (findLikeCurrentCode, row) => {
                row.id = findLikeCurrentCode.codeNumber;
                row.disabled = true;
                row.checked = false;
                row.reason = (
                    <p>
                        Short Code number:{' '}
                        <a target="_blank" href={`#shortcodes/${findLikeCurrentCode.codeNumber}`}>
                            {' '}
                            #{findLikeCurrentCode.codeNumber} <i className="icon-external-link" />
                        </a>{' '}
                        exists.
                    </p>
                );
                return row;
            },
        },
        n11Nums: {
            single: codeNumber => {
                errorNotification({
                    title: `Code ${codeNumber} Not Saved.`,
                    message: `Code Number interferes with N11 Codes`,
                });
                setButtonDisabled(true);
            },
            iterating: (codeNumber, row) => {
                row.id = codeNumber;
                row.disabled = true;
                row.checked = false;
                row.reason = (
                    <p>
                        Code Number interferes with:{' '}
                        <a target="_blank" href="https://en.wikipedia.org/wiki/N11_code">
                            N11 Codes
                            <i className="icon-external-link" />
                        </a>
                    </p>
                );
                return row;
            },
        },
        specialCharacters: {
            single: () => {
                errorNotification({
                    title: `Code ${selectedCode} Not Saved.`,
                    message: `No special characters are allowed.`,
                });
                setButtonDisabled(true);
            },
            iterating: (codeNumber, row) => {
                row.id = codeNumber;
                row.disabled = true;
                row.checked = false;
                row.reason = `No special characters allowed.`;
                return row;
            },
        },
        numberCheck: {
            single: () => {
                errorNotification({
                    title: `Code Not Saved.`,
                    message: `The code must be a number.`,
                });
                setButtonDisabled(true);
            },
            iterating: (codeNumber, row) => {
                row.id = codeNumber;
                row.disabled = true;
                row.checked = false;
                row.reason = `Not a valid code number.`;
                return row;
            },
        },
        codeLength: {
            single: () => {
                errorNotification({
                    title: `Code Not Saved.`,
                    message: `The code number must be between two and four digits long.`,
                });
                setButtonDisabled(true);
            },
            iterating: (codeNumber, row) => {
                row.id = codeNumber;
                row.disabled = true;
                row.checked = false;
                row.reason = `Code number isn't between 2 and 4 digits long.`;
                return row;
            },
        },
        nameLength: {
            single: () => {
                errorNotification({
                    title: `Code Not Saved.`,
                    message: `The description field must be filled out.`,
                });
                setButtonDisabled(true);
            },
            iterating: (codeNumber, row) => {
                row.id = codeNumber;
                row.disabled = true;
                row.checked = false;
                row.reason = `Code needs to have a description.`;
                return row;
            },
        },
        forwardingNumNumber: {
            single: forwardingNumber => {
                errorNotification({
                    title: `Code Not Saved.`,
                    message: `The value ${forwardingNumber} is not a number.`,
                });
                setButtonDisabled(true);
            },
            iterating: (codeNumber, row) => {
                row.id = codeNumber;
                row.disabled = true;
                row.checked = false;
                row.reason = `Forwarding number must be a number.`;
                return row;
            },
        },
        forwardingNumCountryCode: {
            single: () => {
                errorNotification({
                    title: `Code Not Saved.`,
                    message: `The Forwarding Number isn't long enough.`,
                });
                setButtonDisabled(true);
            },
            iterating: (codeNumber, row) => {
                row.id = codeNumber;
                row.disabled = true;
                row.checked = false;
                row.reason = (
                    <p>
                        The{' '}
                        <a
                            target="_blank"
                            href="https://en.wikipedia.org/wiki/List_of_country_calling_codes"
                        >
                            Country Code{'  '}
                            <i className="icon-external-link" />
                        </a>{' '}
                        must be included.
                    </p>
                );
                return row;
            },
        },
    };

    return {
        errorObj,
        buttonDisabled,
        setButtonDisabled,
    };
};
export default useShortCodesValidation;
