import React, { useMemo, useState } from "react";
import RenderComponent from "../RenderComponent";
import useDynamicScript from "./useDynamicScript";

const componentCache = new Map();

const useFederatedComponent = (remoteUrl, scope, module, rootSx = undefined) => {
    const key = useMemo(() => {
        return `${remoteUrl}-${scope}-${module}`        
    }, [remoteUrl, scope, module]);

    const [Component, setComponent] = useState(undefined);

    const { ready, errorLoading } = useDynamicScript(remoteUrl);
    const [errorLoadingComponent, setErrorLoadingComponent] = useState(false);

    React.useEffect(() => {
        if (Component) {
            setComponent(undefined);
        }
        // Only recalculate when key changes
    }, [key]);

    React.useEffect(() => {
        try {
            if (ready && !Component) {
                // This approach is not hooking into the React.lazy approach (example below).  
                // This is because the modules do not return a component.  Instead they are 
                // returning a module definition.  To make it possible to initialize modules
                // and to load modules render into a HTML element (i.e Backbone, jquery).  
                //
                // TODO: Figure out a why to plug into the React Suspense component.
                loadComponent(scope, module)().then(
                    moduleDef =>  {
                        const Comp = <RenderComponent moduleDef={moduleDef.default} sx={rootSx}/>;
                        componentCache.set(key, Comp)
                        setComponent(Comp);
                    });
            }
        }
        catch(e) {
            console.error(e);
            setErrorLoadingComponent(true);
        }
        // key includes all dependencies (scope/module)
    }, [Component, ready, key]);

    return { 
        errorLoading: errorLoading || errorLoadingComponent, 
        Component 
    };
};

function loadComponent(scope, module) {
    return async () => {
        // Initializes the share scope. This fills it with known provided modules from this build and all remotes
        // @ts-ignore
        await __webpack_init_sharing__('default');

        // @ts-ignore
        const container = window[scope]; // or get the container somewhere else
        if(!container) {
            throw new Error(`Scope named ${scope} is missing!`)
        }

        // Initialize the container, it may provide shared modules
        // @ts-ignore
        await container.init(__webpack_share_scopes__.default);

        // @ts-ignore
        const factory = await window[scope].get(module);
        if(!factory) {
            throw new Error(`Unable to load the module ${module}!`)
        }

        const Module = factory();
        return Module;
    };
}

export default useFederatedComponent;