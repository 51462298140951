import React, { Component } from 'react';
import { connect } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { makeStyles } from '@material-ui/styles';

import CallFilterCardRenderer from './callFilterCardRenderer';
import { getCoreHttp } from 'actions/httpRequest';

class CallFilterCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showingModal: false,
            mediaQuery: false,
            incomingPhoneNumber: '',
            incomingAreaCode: null,
            assignedRoute: '',
            routes: [],
            isEditing: false,
        };

    }

    componentDidMount() {

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));

        if (this.props && this.props.callFilter) {
            let assignedRoute;
            let incomingPhoneNumber;
            let incomingAreaCode;

            assignedRoute = this.props.callFilter.assigned_route;
            incomingPhoneNumber = this.props.callFilter.incoming_phonenumber;
            incomingAreaCode = this.props.callFilter.incoming_area_code;

            this.setState({ assignedRoute, incomingPhoneNumber, incomingAreaCode });

        }

        if (this.props && this.props.configurations && this.props.configurations.routes) {

            let routes = [];

            this.props.configurations.routes.map((route) => {
                routes.push(route.name);
            });     

            this.setState({ routes });
        }

    }

    componentWillReceiveProps(nextProps){ 
        if (nextProps && nextProps.callFilter && nextProps.callFilter != this.props.callFilter) {
            
            let assignedRoute;
            let incomingPhoneNumber;
            let incomingAreaCode;

            assignedRoute = nextProps.callFilter.assigned_route;
            incomingPhoneNumber = nextProps.callFilter.incoming_phonenumber;
            incomingAreaCode = nextProps.callFilter.incoming_area_code;

            this.setState({ assignedRoute, incomingPhoneNumber, incomingAreaCode});
        }
    }

    toggleEditing = () => {

        this.setState({ 
            isEditing: true,
        })

        if (this.state.isEditing){
            this.setState({
                isEditing: false
            })
        }
    }

    checkInvalidAreaCodes(numberToCheck){
        const invalidN11Numbers = [
            '011',
            '111',
            '211',
            '311',
            '411',
            '511',
            '611',
            '711',
            '811',
            '911',
        ];
        return invalidN11Numbers.includes(numberToCheck);
    }

    validateAreaCode(areaCode){
        var re =/^[2-9]\d{2}$/;
        if(re.test(areaCode) && !this.checkInvalidAreaCodes(areaCode)){
            return true
        }else{
            return false
        }
    };

    onAreaCodeChange = (value) => {
        //only change state if the value is a number and the length <= 3
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value) && value.length <= 3) {
           this.setState({incomingAreaCode: value})
        }
     }

    formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return null
    }

    handleSubmit = () => {
        let { incomingPhoneNumber, assignedRoute, incomingAreaCode } = this.state;
        let { filterType } = this.props;

        if (filterType == 'areacode') {
            //If this is an areacode filter, we do not want to write a phone number to the db
            if(incomingPhoneNumber !== ''){
                this.setState({
                    incomingPhoneNumber: '',
                })
            }
            if(!this.validateAreaCode(incomingAreaCode) && assignedRoute !==''){
                alert('This does not appear to be a valid area code. Please double check.')
                this.setState({
                    isEditing: true,
                })

            }else if(this.validateAreaCode(incomingAreaCode) && assignedRoute ==''){
                alert('Please choose a route')
                this.setState({
                    isEditing: true
                })
            }else if(this.validateAreaCode(incomingAreaCode) && assignedRoute !== ''){
                this.setState({
                    incomingAreaCode,
                    isEditing: false,
                })
                let newCallFilter = {};
                newCallFilter = {
                    incomingPhoneNumber,
                    assignedRoute,
                    incomingAreaCode,
                }
                let filterIndex = this.props.callFilter.index;
                this.props.submitForm(newCallFilter, filterIndex,);

            }else{
                this.setState({
                    isEditing: true
                })
                alert('Please choose a route and ensure that the area code is correct')
            }
        }else{
            //If this is a phone number, we do not want to write an areacode to the db
            if(incomingAreaCode !== ''){
                this.setState({
                    incomingAreaCode: '',
                })
            }
            if (assignedRoute == null || assignedRoute == '') {
                alert('Please choose a route.');
            } else if (incomingPhoneNumber == null || parsePhoneNumberFromString(incomingPhoneNumber).isValid() === false) {
                alert('Please ensure that the phone number is correct.');
            } else {
                let newCallFilter = {
                    incomingPhoneNumber,
                    assignedRoute,
                    incomingAreaCode,
                };
                let filterIndex = this.props.callFilter.index;
                
                this.props.submitForm(newCallFilter, filterIndex);
                this.toggleEditing();
            }
        }
    }

    removeCallFilter = (index) => {
        this.props.removeCallFilter(index);
    }

    setStateFromChild = (newValues) => {
        this.setState({ ...newValues });
    }

    render() {
        const{ index } = this.props;        
        const minWidth = this.state.mediaQuery.matches;
        const useStyle = makeStyles({
            card: {
                width: minWidth ? '50%' : '100%',
                minWidth: '600px',
                overflow: 'visible',
            },
            grid:{
                display: 'grid'
            },
            deleteButton: {
                color: 'red',
            },
            allOptionsContainer: {
                display:'flex',
                flexDirection: 'row',
                justifyContent:'space-around',
                alignItems:'center'
            },
            routeOptionContainer: {
                width: '160px',
                padding: '0px',
                overflow: 'hidden',
                position: 'relative',
                top: '2px'
            },
            modal: {
                width: minWidth ? '500px' : '95%'
            },
            textInput: {
                border: '1px solid green',
                width: '100px',
                placeholder: 'Phone Number'
            },
            btnContainer: {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridColumn: '3',
                justifySelf: 'end',
                justifyContent: 'center',
                alignItems: 'center'
            },
        });

        //This is how we'll render what's on the card
        const { isEditing, incomingPhoneNumber, assignedRoute, incomingAreaCode} = this.state;
        const { filterType } = this.props;
        let phoneString;
        
        let subtitle;
        let displayNumber;
        if(filterType === 'areacode'){   
            displayNumber = incomingAreaCode ? incomingAreaCode.toString() : '';
        }else{
            //If we have an incomingPhoneNumber, 
            //make that a string
            if (incomingPhoneNumber){
                phoneString = incomingPhoneNumber.toString();
            };

            if (phoneString && phoneString.length == 12 && phoneString[0]== '+' && phoneString[1]=='1'){
                displayNumber = this.formatPhoneNumber(incomingPhoneNumber.substr(2));
            } else if (phoneString && phoneString.length == 10) {
                displayNumber = this.formatPhoneNumber(incomingPhoneNumber);
            } else {
                displayNumber = incomingPhoneNumber;
            }
        }
        subtitle = "When callers from " + displayNumber + " call, route them to " + assignedRoute;

        return (
            <CallFilterCardRenderer
                filterType={filterType}
                useStyles={useStyle}
                subtitle={subtitle}
                isEditing={isEditing}
                index={index}
                routes={this.state.routes}
                incomingAreaCodeOnParentState={incomingAreaCode}
                incomingPhoneNumberOnParentState={incomingPhoneNumber}
                assignedRouteOnParentState={assignedRoute}
                setParentState={this.setStateFromChild}
                storeForm={this.props.storeForm}
                toggleEditing={this.toggleEditing}
                handleSubmit={this.handleSubmit}
                removeCallFilter={this.removeCallFilter}
                onAreaCodeChange={this.onAreaCodeChange}
            />
        );
    }
}

const bindActions = (dispatch) => ({
    getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
});

const mapStateToProps = (state) => ({
    configurations: state.configurations.data
});

export default connect(mapStateToProps, bindActions)(CallFilterCard);
