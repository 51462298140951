import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import deepClone from 'lodash.clonedeep';
import Loading from 'presentational/Loading';

import MenuItem from '@material-ui/core/MenuItem';

import DetailContainer from 'presentational/DetailContainer';
import LeftBar from 'presentational/LeftBar';
import MasterView from 'presentational/MasterView';
import ToggleInput from 'presentational/formControls/ToggleInput';
import SaveChangesModal from 'presentational/SaveChangesModal';
import DeleteModal from 'presentational/DeleteModal';

import { getMasterHttp, putMasterHttp, deleteMasterHttp } from 'actions/httpRequest';
import { resetFlag, deselectSelectedNumber } from 'actions/phoneNumbersManager';
import { errorNotification, successNotification } from 'actions/notifications';
import { formatNumber } from 'utils/phoneNumbers';

import CreatePhoneNumberForm from './createPhoneNumberForm';
import EditPhoneNumberForm from './editPhoneNumberForm';
import UploadPhoneNumbers from './bulkUploadPhoneNumbers';
import PhoneNumberManagerHelp from './help';
import { isSuperDuperAdmin } from './phoneNumberManagerUtils';

function PhoneNumbersManager(props) {
  const [unassigned, setUnassigned] = useState(false);
  const [temporary, setTemporary] = useState(false);
  const [porting, setPorting] = useState(false);
  const [smsEnabled, setSmsEnabled] = useState(false);
  const [smsDisabled, setSmsDisabled] = useState(false);
  const [leftBarData, setLeftBarData] = useState([]);
  const [route, setRoute] = useState(null);
  const [title, setTitle] = useState('');
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [redirectID, setRedirectID] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  //Component Did Mount
  useEffect(() => {
    const { loadedDids, loadedTenants, loadedCarriers } = props;
    const phonenumberID = getPhonenumberIDFromRouteParams();

    if (phonenumberID == null) {
      props.deselectNumber();
    }
    
    if (loadedDids === false) {
      requestToMaster('globaldids');
    }

    if (loadedTenants === false) {
      requestToMaster('tenants');
    }

    if (loadedCarriers === false) {
      requestToMaster('carriers');
    }
  }, []);

  //Component Did Update
  useEffect(() => {
    if (props.phonenumbers.length !== 0) {
      prepareLeftBarData();
      autoRedirect();
    }
  }, [props.phonenumbers]);

  useEffect(() => {
    const phonenumberID = getPhonenumberIDFromRouteParams();

    if (props.selectedNumber) {
      setTitle(formatNumber(route));
    } else if (phonenumberID === 'new') {
      setTitle('Add New Phonenumber');
    } else if (phonenumberID === 'bulkupload') {
      setTitle('Bulk Upload Phone Numbers');
    } else {
      setTitle('');
    }
  }, [props.selectedNumber, props.routeParams])

  useEffect(() => {
    prepareLeftBarData();
  }, [unassigned, temporary, porting, smsEnabled, smsDisabled])

  useEffect(() => {
    if (props.successMsg) {
      props.resetFlag();
      if (props.redirectTo) {
        app.navigate(`dids/${props.redirectTo}`, { trigger : true });
      }
      successNotification({
        title: 'Success!', message: props.successMsg,
      })
    } 
    
    if (props.errorMsg) {
      props.resetFlag();
      if(Array.isArray(props.errorMsg)) {
         props.errorMsg.forEach((msg) => {
          errorNotification({
            title: 'Failed!', 
            time: false,
            message: msg
          });
        })
      } else {
        errorNotification({
          title: 'Failed!', message: props.errorMsg,
        });
      }
    }
  }, [props.successMsg, props.errorMsg]);

  const requestToMaster = (action, object) => {
    const reqData = {
      reqAction: action,
    }

    if (object) {
      reqData.reqObject = object
      props.getMasterHttp(reqData, 'selectphonenumber');
    } else {
      props.getMasterHttp(reqData);
    }
  }

  const prepareLeftBarData = () => {
    let filteredNumber = [...props.phonenumbers];

    if (unassigned) {
      filteredNumber = filteredNumber.filter(item => item.assigned === '0');
    }
    if (temporary) {
      filteredNumber = filteredNumber.filter(item => item.temporary === '1');
    }
    
    if (porting) {
      filteredNumber = filteredNumber.filter(item => item.temporary === '1');
    }
    
    if (smsEnabled) {
      filteredNumber = filteredNumber.filter(item => item.smsenabled === '1');
    } else if (smsDisabled) {
      filteredNumber = filteredNumber.filter(item => item.smsenabled === '0');
    }
    
    const dataForLeftBar = filteredNumber.map(item => (
      { id: item.phonenumber || item.friendlyname, title: formatNumber(item.phonenumber || item.friendlyname), subtitle: ''}
    ));
    setLeftBarData(dataForLeftBar);
  }

  const getPhonenumberIDFromRouteParams = () => {
    return get(props, 'routeParams.did', null);
  }

  const selectPhonenumber = (id) => {
    const phonenumberID = getPhonenumberIDFromRouteParams();

    if(isEqual(props.selectedNumber, props.editForm) || phonenumberID === 'new') {
      props.deselectNumber();
      app.navigate(`dids/${id}`, {trigger: true});
    } else {
      setRedirectID(id);
      setShowUnsavedModal(true);
    }
  }

  const onSubmit = (cancel) => {
    if (cancel === false) {
      const payload = deepClone(props.editForm);
      props.deselectNumber();
      if (payload.tenant === 'none') {
        payload.tenant = '';
      }

      const reqData = {
        reqAction: 'globaldids',
        reqObject: encodeURIComponent(payload.phonenumber),
        reqBody: payload,
      };
      const storeKey = 'editphonenumbersmanager';

      props.editPhoneNumber(reqData, storeKey);
    }

    if (redirectID) {
      app.navigate(`dids/${redirectID}`, {trigger: true});
    } else {
      app.navigate(`dids`, {trigger: true});
      props.deselectNumber();
    }
  }

  const autoRedirect = () => {
    // The phone number on the app URL is being encoded. So, we need to decode it to 
    // get the phone number id.
    const phonenumberID = decodeURIComponent(getPhonenumberIDFromRouteParams());

    if (!props.routeParams) {
      setRoute(null);
      setTitle('');
    } else if (phonenumberID !== route) {
      if (phonenumberID === 'new') {
        props.deselectNumber();
        setRoute('new');
      } else if (phonenumberID === 'bulkupload') {
        props.deselectNumber();
        setRoute('bulkupload');
      } else {
        props.deselectNumber();
        const selected = props.phonenumbers.find(item => {
          return item.phonenumber === phonenumberID;
        });
        setRoute(selected.phonenumber);
        requestToMaster('globaldids', encodeURIComponent(selected.phonenumber));
      }
    }
  }

  const renderContent = () => {
    switch(route){
      case 'new':
        return props.loadedDids ? <CreatePhoneNumberForm /> : <Loading />;
      case 'bulkupload':
        return <UploadPhoneNumbers requestToMaster={requestToMaster}/>;
      case null:
        return props.loadedDids ? <PhoneNumberManagerHelp /> : <Loading />;
      default:
        return props.selectedNumber ? <EditPhoneNumberForm /> : <Loading />;
    }
  }

  const deleteConfirm = (ShouldDelete) => {
    if (ShouldDelete) {
      const phonenumber = get(props, 'selectedNumber.phonenumber', null);
      const reqData = {
        reqAction: 'globaldids',
        reqObject: encodeURIComponent(phonenumber),
      };
      const storeKey = 'deletephonenumbersfrommanager';
  
      props.deletePhoneNumber(reqData, storeKey);
      app.navigate('dids', {trigger: true});
    }
    setShowDeleteModal(false)
  }

  const onBackButtonClick = () => {
    const phonenumberID = getPhonenumberIDFromRouteParams();
    props.deselectNumber();

    if(isEqual(props.selectedNumber, props.editForm) === false && phonenumberID !== 'new') {
      setRoute(null);
      setTitle('');
      setRedirectID('');
      setShowUnsavedModal(true);
    } else {
      setRoute(null);
      setTitle('');
      app.navigate(`dids`, {trigger: true});
    }
  }

  const utilityComponents = [
    <ToggleInput 
      style = {styles.toggleInput} 
      field = 'unassigned'
      placeholder = 'Unassigned'
      value = {unassigned}
      onChange = {value => setUnassigned(value)}
    />,
    <ToggleInput 
      style = {styles.toggleInput} 
      field = 'temporary'
      placeholder = 'Temporary'
      value = {temporary}
      onChange = {value => setTemporary(value)}
    />,
    <ToggleInput 
      style = {styles.toggleInput} 
      field = 'porting'
      placeholder = 'Porting'
      value = {porting}
      onChange = {value => setPorting(value)}
    />,
    <ToggleInput 
      style = {styles.toggleInput} 
      field = 'smsEnabled'
      placeholder = 'SMS Enabled'
      value = {smsEnabled}
      onChange = {value => { setSmsEnabled(value); setSmsDisabled(false); }}
    />,
    <ToggleInput 
      style = {styles.toggleInput} 
      field = 'smsDisabled'
      placeholder = 'SMS Disabled'
      value = {smsDisabled}
      onChange = {value => { setSmsDisabled(value); setSmsEnabled(false); }}
    />,
  ];

  const isDeleteDisabled = () => {
    const assigned = get(props, 'selectedNumber.assigned', null);
    return assigned === '1';
  }

  const contextMenuOptions = [
    <MenuItem
      disabled={isDeleteDisabled()}
      onClick={() => setShowDeleteModal(true)}
      key={0}
    > Delete </MenuItem>,
    <MenuItem
      onClick={() => {
        setTitle('Bulk Upload Phone Numbers');
        selectPhonenumber('bulkupload');
      }}
      key={1}
    > Bulk Upload Phone Numbers </MenuItem>
  ];

  return (
    <MasterView>
      <DetailContainer
        leftBar={
          <LeftBar
            utilityComponents={utilityComponents}
            addNew={() => {
              setTitle('Add New Phonenumber');
              selectPhonenumber('new');
            }}
            data={leftBarData}
            selected={route}
            select={id => selectPhonenumber(id)}
            title='Phone Numbers Manager'
            useInfiniteScroll={true}
          />
        }
        title={title}
        route={null}
        return={onBackButtonClick}
        contextMenuOptions={isSuperDuperAdmin(props.token) ? contextMenuOptions : null}
        backButtonWithoutRoute={true}
      >
        {renderContent()}
      </DetailContainer>
      <SaveChangesModal
        show={showUnsavedModal}
        saveChange={() => {
          props.deselectNumber();
          onSubmit(false)
        }}
        onClose={() => { onSubmit(true) }}
        discardChange={() => { onSubmit(true) }}
      />
      <DeleteModal
        open={showDeleteModal}
        title='Are you sure?'
        delete={() => deleteConfirm(true)}
        cancel={() => deleteConfirm(false)}
      />
    </MasterView>
  )
}

const styles = {
  toggleInput: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 0,
    paddingTop: 0,
    marginTop: 5,
    height: 35,
  },
};

const bindActions = (dispatch) => ({
  getMasterHttp: (reqData, storeKey) => dispatch(getMasterHttp(reqData, storeKey)),
  resetFlag: () => dispatch(resetFlag()),
  editPhoneNumber: (reqData, storeKey) => dispatch(putMasterHttp(reqData, storeKey)),
  deletePhoneNumber: (reqData, storeKey) => dispatch(deleteMasterHttp(reqData, storeKey)),
  deselectNumber: () => dispatch(deselectSelectedNumber()),
});

const mapStateToProps = state => ({
  phonenumbers: state.phoneNumbersManager.phonenumbers,
  routeParams: state.navigation.params,
  selectedNumber: state.phoneNumbersManager.selectedNumber,
  successMsg: state.phoneNumbersManager.successMsg,
  errorMsg: state.phoneNumbersManager.errorMsg,
  errorCode: state.phoneNumbersManager.errorCode,
  redirectTo: state.phoneNumbersManager.redirectTo,
  editForm: state.phoneNumbersManager.editPhoneNumberForm,
  token: state.token,
  loadedDids: state.phoneNumbersManager.loadedDids,
  loadedTenants: state.phoneNumbersManager.loadedTenants,
  loadedCarriers: state.phoneNumbersManager.loadedCarriers,
});

PhoneNumbersManager.propTypes = {
  phonenumbers: PropTypes.array.isRequired,
  routeParams: PropTypes.oneOfType([() => null, PropTypes.string]),
  successMsg: PropTypes.oneOfType([() => null, PropTypes.string]),
  errorMsg: PropTypes.oneOfType([() => null, PropTypes.string]),
  errorCode: PropTypes.oneOfType([() => null, PropTypes.number]),
  redirectTo: PropTypes.oneOfType([() => null, PropTypes.string]),
  editForm: PropTypes.oneOfType([() => null, PropTypes.object]),
  token: PropTypes.object,
  loadedDids: PropTypes.bool,
  loadedTenants: PropTypes.bool,
  loadedCarriers: PropTypes.bool,
}


export default connect(mapStateToProps, bindActions)(PhoneNumbersManager);
