import React from 'react';
import PropTypes from 'prop-types';

function CustomSelect(props) {
  return (
    <div className="wrap">
      <div className="select">
				<select className="select-text" onChange={(e) => props.onChange(e.target.value)} value={props.value}>
          {props.options.map(selectOption => {
            return (
              <option key={selectOption.value} value={selectOption.value}>{selectOption.label}</option>
            );
          })}
				</select>
				<span className="select-highlight"></span>
				<span className="select-bar"></span>
				<label className="select-label">{props.placeholder}</label>
			</div>
    </div>
  );
}

CustomSelect.defaultProps = {
  options: ['Select Option', 'No option given'],
  onChange: () => console.log('No onChange method given'),
  value: '',
  placeholder: 'Temp placeholder',
}

CustomSelect.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  placeholder: PropTypes.string,
}

export default CustomSelect;