import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Menu from '@material-ui/core/Menu';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles'

import DownloadIcon from '@material-ui/icons/CloudDownload';
import TranscribeIcon from '@material-ui/icons/SpeakerNotes';
import PauseIcon from '@material-ui/icons/Pause';
import PlayIcon from '@material-ui/icons/PlayArrow';
import CopyIcon from '@material-ui/icons/FileCopy';
import { CircularProgress } from '@material-ui/core';

import {
  errorNotification,
  successNotification,
} from 'actions/notifications';

const style = () => ({
    downloadIcon: {
        color: '#000000',
        height: 18,
        marginTop: 4,
        width: 18,
    },
    iconButton: {
        height: 48,
        padding: 0,
        width: 48,
    },
    loadingRecording: {
        bottom: 12,
        marginLeft: 22,
    },
    notFound: {
        textAlign: 'center',
    },
    pauseIcon: {
        width: 26,
        height: 26,
        color: '#0F4E8B',
    },
    playIcon: {
        width: 28,
        height: 28,
        color: '#0F4E8B',
    },
    slider: {
        width: '100%',
    },
    sliderWrapper: {
        display: 'flex',
        alignItems: 'center',
        height: 40,
    },
    menuItem: {
        fontSize: '100%',
    },
    copyIcon:{
      marginTop: '10px',
      cursor: 'pointer',
    }
});

class AudioPlayer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentlyPlaying: false,
            audio: null,
            anchorEl1: null,
            anchorEl2: null,
            showTranscriptionModal: false,
            isLoadingCopyLink: false
        };
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.value != this.props.value && this.state.audio) {
            this.state.audio.pause();
            this.setState({ audio: null, currentlyPlaying: false });
        }
    }

    componentWillUnmount() {

        this.timeInterval && clearInterval(this.timeInterval);
        this.state.audio && this.state.audio.pause();
        this.state.audio = null;
    }

    async checkForRecording(url) {

      try {
        const res = await new Promise((resolve, reject) => {
          $.ajax({
            url,
            type: 'GET',
            success: function () {
              resolve("success"); 
            },
            error: function (error) {
              console.log("ok, no recording here!")
              reject(error);
            }
          });
        });
  
        return true;

      } catch (error) {
        console.log("there was an error", error);
        return false;
      }
    }

    copyRecordingURL = async (url) => {

      this.setState({ isLoadingCopyLink: true });

      if (await this.checkForRecording(url) === false) {
        errorNotification({ title: 'Error!', message: `No URL found for this recording!` });
        return;
      }

      $.ajax({
        url: "/api/v1/userportal/" + app.token.get('tenant') + "/ext/tempurl/" + this.props.recordingName,
        type: 'get',
        success: async (res) => {

          await navigator.clipboard.writeText(res.temp_url);
          successNotification({ title: 'Success!', message: `Successfully copied recording URL!` });
          this.setState({ isLoadingCopyLink: false });

        },
        error: (e) => {

          console.error("Failed to copy recording link!", error);
          errorNotification({ title: 'Error!', message: `No URL found for this recording!` });
          this.setState({ isLoadingCopyLink: false });

        }
      });
    }

    pauseRecording() {

        this.setState({ currentlyPlaying: false });
        this.timeInterval && clearInterval(this.timeInterval);
        this.state.audio.pause();
        if (typeof this.props.playing == 'function') {this.props.playing(false)};
    }

    playRecording(playURL) {

        const audio = new Audio(`${playURL}`);

        this.setState({ audio, currentlyPlaying: true, currentlyLoading: true });
        audio.load();
        audio.onloadeddata = () => {
            this.startTimeTracking();
            audio.play();
            audio.playbackRate = this.props.playbackRate ? this.props.playbackRate : 1;
        }
        audio.onended = () => this.setState({ audio: null, currentlyPlaying: false });
        audio.onerror = () => this.setState({ audio: null, currentlyPlaying: false, error: true });

        if (typeof this.props.playing == 'function') {
          this.props.playing(true);
          audio.onended = () => this.props.playing(false);
          audio.onerror = () => this.props.playing(false)
        };
    }

    async fetchRecording(shouldPlayRecording=true) {

      const routeDetailsArray = this.routeBuilder();
      let mailbox;
      let recordingName;
      if(routeDetailsArray[1] === 'voicemailgreetings' || routeDetailsArray[1] === 'voicemailmessages'){
        const getMailboxFromUrl = this.getMailboxFromUrl();
        recordingName = getMailboxFromUrl[1];
        mailbox = getMailboxFromUrl[0];
      }

      const playURL = await remoteResourceInterface({
        nameSpace: routeDetailsArray[0],
        route: routeDetailsArray[1],
        reqObject: this.props.recordingName? this.props.recordingName : recordingName,
        fileType: "audio/wav",
        reqQuery: this.props.recordingParams? this.props.recordingParams : null,
        mailbox: mailbox? mailbox : null,
      });

      console.log("playURL --->", playURL)


      if(!playURL){
        console.warn("[[WARN] audioplayer.fetchrecording.fail] playURL is incorrect")
        return false;
      }
      if(shouldPlayRecording){
        this.playRecording(playURL);
      } else {
        return playURL;
      }
    }

    getMailboxFromUrl(){
      var recordingUrl = this.props.recordingUrl;
      if(recordingUrl.startsWith('/')){
        recordingUrl = recordingUrl.substring(1);
      }
      const urlArray = recordingUrl.split("/");
      const namespace = urlArray[2];
      let mailbox;
      let greetingName;
      if (namespace === "userportal") {
        mailbox = urlArray[4];
        greetingName = urlArray[6];
        return [mailbox, greetingName];
      }
    return null;
    }

    routeBuilder(){
      var recordingUrl = this.props.recordingUrl;
      
      if(recordingUrl.startsWith('/')){
        recordingUrl = recordingUrl.substring(1);
      }

      const urlArray = recordingUrl.split("/");
      const namespace = urlArray[2];
      let route;
      if (namespace === "userportal") {
        route = urlArray[5];
      }else if(namespace === "core" || namespace ==="reporting"){
        route = urlArray[4];
      }
      
      return [namespace, route]; 
       
    }

    startTimeTracking() {

        this.setState({
            totalTime: this.state.audio.duration,
            currentlyLoading: false,
        });

        this.timeInterval = setInterval(() => {
            this.setState({ currentTime: this.state.audio && this.state.audio.currentTime ? this.state.audio.currentTime : 0 });
        }, 500);
    }

    handleSliderDrag(e, position) {

        if (!this.state.audio) {

            return;
        }

        this.state.audio.currentTime = ((position / 100) * this.state.totalTime);
    }

    async triggerDownloadPrompt(e, fileType) {

        const routeDetailsArray = this.routeBuilder();
        let mailbox = null;
        let recordingNameFromUrl = null;

        if(routeDetailsArray[1] === 'voicemailgreetings' || routeDetailsArray[1] === 'voicemailmessages'){
          mailbox = this.getMailboxFromUrl()[0];
          recordingNameFromUrl = this.getMailboxFromUrl()[1];
        }

        if(!this.props.recordingName && !recordingNameFromUrl) {
          console.warn("[[WARN] audioplayer.triggerdownloadprompt.fail] recordingName is not defined ")
          return false;
        }

        const recordingName = this.props.recordingName? this.props.recordingName : recordingNameFromUrl;
        const match = recordingName.match(/^([^.]*)\./);
        const fileName = match[1];

        const playURL = await remoteResourceInterface({
          nameSpace: routeDetailsArray[0],
          route: routeDetailsArray[1],
          reqObject: `${fileName}.${fileType}`,
          reqQuery: this.props.recordingParams? `${this.props.recordingParams}&method=attachment` : 'method=attachment',
          fileType: `audio/${fileType}`,
          shouldDownload: true, 
          mailbox
        });

        if(!this.props.triggerDownload || !e){
          return false;
        }

        this.props.triggerDownload(e);
    }

    closeTranscriptionModal = () => {
      this.setState({ showTranscriptionModal: false })
    }

    render() {
        const { classes } = this.props;

        if (this.state.error) {

            return <div className={classes.notFound}>Recording not found</div>;
        }

        let currentTime = 0;
        if (this.state.audio) {

            currentTime = this.state.currentTime ? (this.state.currentTime / this.state.totalTime) * 100 : 0;
        }

        const timeTracker = (
          <div className={classes.slider}>
            {this.state.currentlyLoading && this.state.currentlyPlaying ?
              <LinearProgress mode="indeterminate" />
              :
              <Slider
                disabled={!this.state.audio}
                onChange={(e, newPosition) => this.handleSliderDrag(e, newPosition)}
                value={currentTime}
                width="100%"
              />
            }
          </div>
        );

        return (
          <div className={classes.wrapper}>
            {!this.state.currentlyPlaying ?
              <div className={classes.sliderWrapper}>
                {this.props.showDownload && this.props.recordingUrl &&
                <>
                  {this.props.callHistory && <a className={classes.copyIcon}>
                    {this.state.isLoadingCopyLink ?  
                      <CircularProgress size="28px" /> 
                    : 
                      <CopyIcon onClick={() => this.copyRecordingURL(this.props.recordingUrl)} />
                    }
                    </a>
                  }
                  <IconButton aria-haspopup="true" onClick={ (e) => this.setState({ anchorEl1: e.currentTarget })}><DownloadIcon classes={{ root: classes.downloadIcon}} /></IconButton>
                  {this.props.showTranscribe &&
                    <React.Fragment>
                      <IconButton aria-haspopup="true" onClick={ () => this.setState({ showTranscriptionModal: true }) }><TranscribeIcon classes={{ root: classes.downloadIcon}} /></IconButton>
                      <Dialog onClose={this.closeTranscriptionModal} open={this.state.showTranscriptionModal}>
                        <DialogContent onClose={this.closeTranscriptionModal} style={{ textAlign: 'center' }}>
                          <strong>{this.props.voicemailInfo.calleridname}</strong><br/>
                          {this.props.voicemailInfo.calleridnumber}<br/>
                          {this.props.voicemailInfo.time}
                        </DialogContent>
                        <DialogContent dividers>
                          {this.props.voicemailInfo.transcription ? this.props.voicemailInfo.transcription : "There is no transcription for this voicemail."}
                        </DialogContent>
                      </Dialog>
                    </React.Fragment>
                  }
                  <Menu
                    classes={{ paper: classes.menu }}
                    uselayerforclickaway="true"
                    keepMounted
                    anchorEl={this.state.anchorEl1}
                    open={ Boolean(this.state.anchorEl1)} onClose={() => this.setState({ anchorEl1: null })}  >
                    {!this.props.greeting ? (
                      <div>
                        <MenuItem onClick={(e) => this.triggerDownloadPrompt(e, 'wav')} >.wav</MenuItem>
                        <MenuItem onClick={(e) => this.triggerDownloadPrompt(e, 'mp3')} >.mp3</MenuItem>
                        <MenuItem onClick={(e) => this.triggerDownloadPrompt(e, 'opus')} >.opus</MenuItem>
                      </div>
                    ) : (
                      <MenuItem onClick={(e) => this.triggerDownloadPrompt(e, 'wav')} >Download</MenuItem>
                    )}
                  </Menu>
                  </>
                }
                <IconButton
                  disabled={!this.props.recordingUrl}
                  classes={{ root: classes.iconButton }}
                  onClick={() => this.fetchRecording()}
                >
                  <PlayIcon classes={{ root: classes.playIcon }}/>
                </IconButton>
                {this.props.showTime && timeTracker}
              </div>
            :
              <div className={classes.sliderWrapper}>
                {this.props.showDownload && this.props.recordingUrl &&
                <>
                  {this.props.callHistory && <a className={classes.copyIcon}>
                    {this.state.isLoadingCopyLink ?  
                      <CircularProgress size="28px" /> 
                    : 
                      <CopyIcon onClick={() => this.copyRecordingURL(this.props.recordingUrl)} />
                    }
                    </a>
                  }
                  <IconButton aria-haspopup="true" onClick={ (e) => this.setState({ anchorEl2: e.currentTarget })}><DownloadIcon classes={{ root: classes.downloadIcon}} /></IconButton>
                  {this.props.showTranscribe &&
                    <React.Fragment>
                      <IconButton aria-haspopup="true" onClick={ () => this.setState({ showTranscriptionModal: true }) }><TranscribeIcon classes={{ root: classes.downloadIcon}} /></IconButton>
                      <Dialog onClose={this.closeTranscriptionModal} open={this.state.showTranscriptionModal}>
                        <DialogContent onClose={this.closeTranscriptionModal} style={{ textAlign: 'center' }}>
                          <strong>{this.props.voicemailInfo.calleridname}</strong><br/>
                          {this.props.voicemailInfo.calleridnumber}<br/>
                          {this.props.voicemailInfo.time}
                        </DialogContent>
                        <DialogContent dividers>
                          {this.props.voicemailInfo.transcription ? this.props.voicemailInfo.transcription : "There is no transcription for this voicemail."}
                        </DialogContent>
                      </Dialog>
                    </React.Fragment>
                  }
                  <Menu uselayerforclickaway = "true" open={Boolean(this.state.anchorEl1)}
                    onClose={() => this.setState({ anchorEl2: null })}
                    classes={{ paper: classes.menu }}
                    keepMounted
                    anchorEl={this.state.anchorEl2}
                  >
                    {!this.props.greeting ? (
                      <div>
                        <MenuItem onClick={(e) => this.triggerDownloadPrompt(e, 'wav')} > .wav</MenuItem>
                        <MenuItem onClick={(e) => this.triggerDownloadPrompt(e, 'mp3')} >.mp3</MenuItem>
                        <MenuItem onClick={(e) => this.triggerDownloadPrompt(e, 'opus')} >.opus</MenuItem>
                      </div>
                    ) : (
                        <MenuItem onClick={(e) => this.triggerDownloadPrompt(e, 'wav')} >Download</MenuItem>
                    )}

                  </Menu>
                </>
                }
                <IconButton
                  classes={{ root: classes.iconButton }}
                  onClick={() => this.pauseRecording()}
                >
                  <PauseIcon classes={{ root: classes.pauseIcon }}/>
                </IconButton>
                {this.props.showTime && timeTracker}
              </div>
            }

          </div>
        );
    }
}

AudioPlayer.defaultProps = {
    options: [{ label: 'No value', value: '' }],
    recordingUrl: '',
    showDownload: false,
    showTime: false,
    conf: false,
    callRecording: false,
};

AudioPlayer.propTypes = {
    recordingUrl: PropTypes.string,
    options: PropTypes.array,
    showDownload: PropTypes.bool,
    showTime: PropTypes.bool,
    conf: PropTypes.bool,
    callRecording: PropTypes.bool,
};


const bindActions = (dispatch) => ({
});
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, bindActions)(withStyles(style)(AudioPlayer));
