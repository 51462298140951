import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import get from 'lodash.get'

import Loading from 'presentational/Loading'

import {
  getUserPortalHttp,
  putUserPortalHttp,
  getCoreHttp
} from 'actions/httpRequest'
import { errorNotification, successNotification } from 'actions/notifications'
import { resetFlag } from 'actions/callForwarding'

import CallForwardingForm from './callForwadingForm'
import CallForwardingButton from './callForwardingButton'

class CallForwarding extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: null,
      change: false, 
      error: false
    }
    this.storedFetchedData = false
  }

  componentDidMount () {
    let reqData = {
      reqAction: 'callforwarding'
    }
    this.props.getUserPortalHttp(reqData)

    reqData = {
      reqAction: 'userportaltimeconditions'
    }
    this.props.getUserPortalHttp(reqData)
  }

  componentDidUpdate () {
    if (this.props.callForwardingData && !this.storedFetchedData) {
      this.storedFetchedData = true
      this.setState({ data: this.props.callForwardingData })
      return
    }

    if (this.props.successMsg) {
      this.props.resetFlag()
      successNotification({
        title: 'Success!',
        message: this.props.successMsg
      })
    } else if (this.props.errorMsg) {
      if (this.props.errorCode === 404) {
        this.props.resetFlag()
        app.navigate('#userportal', { trigger: true })
      } else {
        this.props.resetFlag()
        errorNotification({
          title: 'Failed!',
          message: this.props.errorMsg
        })
      }
    }
  }

  isValidForm = data => {
    const extraFieldOptions = [
      'voicemenu',
      'callflow',
      'callforwarding',
      'callgroup',
      'extension',
      'queue',
      'recording',
      'voicemailbox'
    ]
    const position = extraFieldOptions.findIndex(
      item => item === data.cfw_busy_application
    )
    
    if(data.cfw_enabled === "1" && data.cfw_follow_me === "0" && data.cfw_always_enabled === "0" && data.cfw_busy_enabled === "0"){
      errorNotification({
        title: 'Error',
        message: 'Select Call Forwarding Type'
      })
      return false
    }
    if (data.cfw_follow_me === '1' && data.cfw_numbers.length === 0) {
      errorNotification({
        title: 'Error',
        message: 'Cannot enable Follow Me with no Follow Me numbers.'
      })
      return false
    }

    if (position !== -1 && !data.cfw_busy_value) {
      errorNotification({
        title: 'Error: Cannot Save',
        message: 'Invalid application value.'
      })
      return false
    } else if (data.cfw_busy_application === 'callforwarding') {
      if (data.cfw_busy_value.length > 15) {
        errorNotification({
          title: 'Error',
          message: 'Please enter a valid number.'
        })
        return false
      } else if (data.cfw_busy_value.length < 10) {
        const extensions = get(data, 'config.voicemailboxes', [])
        const extensionNumber = extensions.map(extension => {
          return extension.mailbox
        })
        const isInputExtension = extensionNumber.includes(
          String(data.cfw_busy_value)
        )
        if (isInputExtension) {
          return true
        }
        errorNotification({
          title: 'Error: Cannot Save',
          message: 'Invalid application value.'
        })
        errorNotification({
          title: 'Error',
          message: 'Extension not valid.'
        })
        return false
      }
    }

    if (data.cfw_always_enabled === '1') {
      if (data.cfw_always_number.length < 10) {
        if (data.cfw_always_number === app.token.attributes.extension) {
          errorNotification({
            title: 'Error',
            message: 'Cannot Set Same Extension For Forwarding'
          })
          return false
        }

        const extensions = get(data, 'config.voicemailboxes', [])
        const extensionNumber = extensions.map(extension => {
          return extension.mailbox
        })
        const isInputExtension = extensionNumber.includes(
          data.cfw_always_number
        )

        if (isInputExtension) {
          return true
        }

        errorNotification({
          title: 'Error: Cannot Save',
          message: 'Invalid application value.'
        })
        errorNotification({
          title: 'Error',
          message: 'Extension not valid.'
        })
        return false
      } else if (data.cfw_always_number.length > 15) {
        errorNotification({
          title: 'Error',
          message: 'Please enter a valid number.'
        })
        return false
      }
    }
    return true
  }


  editForwardingOptions = (data, timeConditions) => {
    let errors = false;
    if(timeConditions){
      if(timeConditions.occurrence === "weekly"){
        if(timeConditions.timeConditions.length === 0){
          errorNotification({
            title: 'Error: Cannot Save',
            message: 'Provide at least one time condition'
          })
          errors = true
        }else if(!timeConditions.timezone){
          errorNotification({
            title: 'Error: Cannot Save',
            message: 'Provide Country and Timezone'
          })
          errors = true;
        }else if(timeConditions.timeConditions){
          timeConditions.timeConditions.forEach(timeCondition => {
            if(!timeCondition.startTime || !timeCondition.endTime || !timeCondition.weekdays  || timeCondition.weekdays.length === 0){
              errorNotification({
                title: 'Error: Cannot Save',
                message: 'Provide start time, end time, and weekdays'
              })
              errors = true;
              console.log(timeCondition.startTime)
            }else if(Number(timeCondition.endTime.split(':')[0]) - Number(timeCondition.startTime.split(':')[0]) < 0){
              errorNotification({
                title: 'Error: Cannot Save',
                message: 'Start time cannot be later than end time'
              })
              errors = true;
            }
          })
        }
      }
    }

    if(!errors && this.isValidForm(data) ){
      let reqData;
      if(timeConditions){
      timeConditions.id = app.token.attributes.extension
      timeConditions.tenant = app.token.attributes.tenant
        reqData = {
          reqBody: timeConditions,
          reqAction: 'userportaltimeconditions'
      }
      this.props.putUserPortalHttp(reqData)
      }
      data.id = app.token.attributes.extension
      data.tenant = app.token.attributes.tenant
      reqData = {
        reqAction: 'callforwarding',
        reqBody: data
      }
      let storeKey = 'editcallforwarding'
      this.props.putUserPortalHttp(reqData, storeKey)
      this.setState({ change: false })
    }    
  }

  toggleForwarding = () => {
    const { data } = this.state
    let change = false
    if (data.cfw_enabled === '1') {
      data.cfw_enabled = '0'
      this.editForwardingOptions(data)
    } else {
      change = true
      data.cfw_enabled = '1'
    }
    this.setState({ data, change })
  }

  render () {
    if (this.state.data == null) {
      return <Loading />
    }
    const { data } = this.state
    const text = `Turn ${
      data.cfw_enabled === '1' ? 'off' : 'on'
    } Call Forwarding`
    return (
      <div className='container'>
        <h1>Call Forwarding Options</h1>
        <h4>Forward calls to other numbers</h4>
        <p />
        <CallForwardingButton
          text={text}
          toggleForwarding={this.toggleForwarding}
        />
        {data.cfw_enabled === '1' && (
          <CallForwardingForm
            form={data}
            editRequest={(form, timeConditions) => this.editForwardingOptions(form, timeConditions)}
            change={this.state.change}
          />
        )}
      </div>
    )
  }
}

const bindActions = dispatch => ({
  getUserPortalHttp: reqData => dispatch(getUserPortalHttp(reqData)),
  getCoreHttp: reqData => dispatch(getCoreHttp(reqData)),
  putUserPortalHttp: (reqData, storeKey) =>
    dispatch(putUserPortalHttp(reqData, storeKey)),
  resetFlag: () => dispatch(resetFlag())
})

const mapStateToProps = state => ({
  callForwardingData: state.callForwarding.callForwardingData,
  successMsg: state.callForwarding.successMsg,
  errorMsg: state.callForwarding.errorMsg,
  errorCode: state.callForwarding.errorCode
})

CallForwarding.propTypes = {
  callForwardingData: PropTypes.oneOfType([() => null, PropTypes.object]),
  successMsg: PropTypes.oneOfType([() => null, PropTypes.string]),
  errorMsg: PropTypes.oneOfType([() => null, PropTypes.string]),
  errorCode: PropTypes.oneOfType([() => null, PropTypes.number])
}

export default connect(
  mapStateToProps,
  bindActions
)(CallForwarding)
