import React, { Component } from 'react';
import SubmitButton from 'presentational/formControls/SubmitButton';


class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = { mediaQuery: false };
  }

  componentDidMount() {

    // Media query for responsive UI
    const mq = window.matchMedia('(min-width: 800px)');
    this.setState({ mediaQuery: mq });
    mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
  }

  render() {

    const minWidth = this.state.mediaQuery.matches;

    const style = {
      footer:{
        width: '115%',
        height: '60px',
        background: 'white',
        position: 'fixed',
        zIndex: '1',
        display: 'flex',
        bottom: 0,
        left: 0,
        justifyContent: 'center',
        borderTop: '1px solid #e3e3e3',
        padding: '10px 5px',
        margin: '0px 5px',
      },
      button:{
        margin: '0px 5px'
      },
    };

    let forwardDisabled = this.props.forwardVM == 'yes' && this.props.disabled == false ? this.props.disabled : forwardDisabled = true;

    return (
      <div style={style.footer}>
        <SubmitButton
            style={style.button}
            label={"Move"}
            disabled={this.props.disabled}
            onClick={this.props.move}
        />
        <SubmitButton
            style={style.button}
            label={"Download"}
            disabled={this.props.disabled}
            onClick={this.props.downloadVoicemail}
        />
        <SubmitButton
            style={style.button}
            label={"Forward"}
            disabled={forwardDisabled}
            onClick={this.props.forwardVoicemail}
        />
        <SubmitButton
            style={style.button}
            label={"Delete"}
            disabled={this.props.disabled}
            onClick={this.props.deleteVoicemail}
        />
      </div>
    );
  }
}


export default Footer;
