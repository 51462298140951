import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import SayNameIcon from '@material-ui/icons/PhoneInTalk';
import SayNumberIcon from '@material-ui/icons/Dialpad';
import CustomAnnounceIcon from '@material-ui/icons/SpeakerPhone';
import AddIcon from '@material-ui/icons/AddBox';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ScreenIcon from '@material-ui/icons/QuestionAnswer';

import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import SaveChangesModal from 'presentational/SaveChangesModal';
import DragAndDrop from 'presentational/DragAndDrop';
import Footer from 'presentational/Footer';
import Loading from 'presentational/Loading';

import { errorNotification, successNotification } from 'actions/notifications';
import { updateDoNotScreenNumbers } from 'actions/callScreening';

const DEFAULT_COUNTRY_CODE = '+1';

function CallScreeningForm(props) {
  const [form, setForm] = useState(null);
  const [numberObject, setNumberObject] = useState({ number: DEFAULT_COUNTRY_CODE });
  const [stateCountryCode, setStateCountryCode] = useState(DEFAULT_COUNTRY_CODE);
  const [rollBackForm, setRollBackForm] = useState(null);
  const [showingSaveChangesModal, setShowingSaveChangesModal] = useState(false);
  const [changed, setChanged] = useState(false);

  // componentDidMount
  
  useEffect(() => {
    const newForm = cloneDeep(props.form);

    clickedElement();
    if (newForm.csc_custom_announce === '') {
      newForm.csc_custom_announce = 'Default';
    }
    setForm(newForm);
    setRollBackForm(newForm);
    setChanged(props.changed);
  }, []);

  const clickedElement = () => {
    var elements = document.querySelectorAll(".header, .subnav");
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", () => {
        triggerModal();
      });
    }
  }

  const triggerModal = () => {
    setShowingSaveChangesModal(true);
  }

  const formEdit = (field, data) => {
    const newForm = cloneDeep(form);

    if (data === true){
      data = '1';
    } else if (data === false){
      data = '0';
    }

    newForm[field] = data;
    setForm(newForm);
    setChanged(true);
  }

  const updateCountry = (field, data, countryData) => {
    const { dialCode } = countryData;

    setStateCountryCode('+' + dialCode);
    setNumberObject({ [field]: data });
    setChanged(true);
  }

  const addNumberToList = () => {
    const newForm = cloneDeep(form);
    const index = newForm.csc_do_not_screen.findIndex(cscNumber => cscNumber === numberObject.number);

    if (index === -1) {
        if (!numberObject.number || !parsePhoneNumberFromString(numberObject.number).isValid()) {
        errorNotification({
          title: 'Failed', message: 'Enter a valid number',
        });
      } else {
        newForm.csc_do_not_screen.push(numberObject.number);
        props.updateDoNotScreenNumbers(newForm.csc_do_not_screen);
        setNumberObject({ number: stateCountryCode });
        setForm(newForm);
      }
    } else {
      errorNotification({
        title: 'Failed', message: 'This number already exist in the list',
      });
    }
  }

  const onSubmit = (cancel) => {
    if (cancel) {
      app.navigate('#userportal', { trigger: true });
    } else {
      const newForm = cloneDeep(form);
      const newRollBackForm = cloneDeep(form);
      
      if (newForm.csc_custom_announce === 'Default') {
        newForm.csc_custom_announce = '';
      }

      props.editRequest(newForm)
      setRollBackForm(newRollBackForm);
      setChanged(false);
      setShowingSaveChangesModal(false);
    }
  }

  const revertChanges = () => {
    const newForm = cloneDeep(rollBackForm);

    setChanged(false);
    setShowingSaveChangesModal(false);
    props.updateDoNotScreenNumbers(newForm.csc_do_not_screen);
    setForm(newForm);
  }

  const removeNumber = (number) => {
    const newForm = cloneDeep(form);
    const newList = newForm.csc_do_not_screen.filter((content) => content !== number);

    newForm.csc_do_not_screen = newList;
    props.updateDoNotScreenNumbers(newList);
    setForm(newForm);
    setChanged(true);
  }

  const renderDoNotScreenList = (list) => {
    const numbers = list || [];
    const itemIds = [];

    const numbersObjects = numbers.map((number, position) => {
      itemIds.push(position);
      return {
        [position]: number,
        id: number,
        content: number,
      };
    });

    const initialData = {
      items: numbersObjects,
      columns: {
        'right': {
          id: 'right',
          title: 'Selected phone numbers',
          itemIds,
        }
      },
      columnOrder: ['right']
    };

    return (
      <DragAndDrop
        updateOrder={() => {}}
        handleSelectedDeletion={(item) => removeNumber(item.id)}
        handleSelectedAddition={() => {}}
        data={initialData}
        placeholder="Search number"
        isDragDisabled={true}
      />
    );
  }

  if (!form) {
    return <Loading />;
  }

  const recordingsList = ['Default'].concat(props.recordings.map(recording => { return recording.name; }));

  return (
    <StandardSettingsPage>
      <h4>Callers with caller ID</h4>
      <FormControlCard
        form={form}
        field="csc_with_cid_say_name"
        formControl="ToggleInput"
        icon={<SayNameIcon/>}
        title="Ask user to say name"
        subtitle=""
        submitForm={(field, data) => formEdit(field, data)}
        label="No / Yes"
      />
      <FormControlCard
        form={numberObject}
        field="number"
        formControl="TextFieldWithButton"
        title="Do not screen these callers:"
        subtitle=""
        icon={<AddIcon />}
        submitForm={(field, data, countryData) => updateCountry(field, data, countryData)}
        buttonLabel={<AddCircleIcon style={{ color: 'rgb(42, 114, 185)' }}/>}
        onClick={() => addNumberToList()}
        getCountryCode={(countryCode) => {
          setNumberObject({ number: countryCode });
          setStateCountryCode(countryCode);
        }}
      />
      <FormControlCard
        form={{ content: renderDoNotScreenList(form.csc_do_not_screen)}}
        field="content"
        formControl="blocknumberlist"
        title="Do Not Screen Numbers"
        subtitle='Note: All callers on this list will be exempt from screening'
        icon={<ScreenIcon/>}
        />
      <h4>Callers without caller ID</h4>
      <FormControlCard
        form={form}
        field="csc_without_cid_say_name"
        formControl="ToggleInput"
        icon={<SayNameIcon/>}
        title="Ask user to say name"
        subtitle=""
        submitForm={(field, data) => formEdit(field, data)}
        label="No / Yes"
      />
      <FormControlCard
        form={form}
        field="csc_without_cid_enter_number"
        formControl="ToggleInput"
        icon={<SayNumberIcon/>}
        title="Ask callers to say number"
        subtitle=""
        submitForm={(field, data) => formEdit(field, data)}
        label="No / Yes"
      />
      <FormControlCard
        form={form}
        field="csc_custom_announce"
        icon={<CustomAnnounceIcon/>}
        formControl="SelectInput"
        title="Caller Announcement:"
        submitForm={(field, data) => formEdit(field, data)}
        options={recordingsList}
      />
      <Footer 
        disabled={isEqual(form, rollBackForm) && !changed}
        onClick={(cancel) => onSubmit(cancel)}
        fullWidth={true}
      />
      <SaveChangesModal
        show={showingSaveChangesModal && changed}
        saveChange={() => onSubmit(false)}
        onClose={() => onSubmit(true)}
        discardChange={() => revertChanges()}
      />
    </StandardSettingsPage>
  )
}

CallScreeningForm.propTypes = {
  form: PropTypes.object,
  editRequest: PropTypes.func,
  recordings: PropTypes.array.isRequired,
  changed: PropTypes.bool,
}

const bindActions = (dispatch) => ({
  updateDoNotScreenNumbers: (options) => dispatch(updateDoNotScreenNumbers(options)),
});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, bindActions)(CallScreeningForm); 
