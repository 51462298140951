import React, { Component } from 'react';
import { connect } from 'react-redux';

import ErrorCard from 'presentational/ErrorCard';
import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';

import DescriptionIcon from '@material-ui/icons/Description';
import DelayIcon from '@material-ui/icons/AvTimer';
import RingingIcon from '@material-ui/icons/RingVolume';
import DialIcon from '@material-ui/icons/Dialpad';
import WarningIcon from '@material-ui/icons/Warning';
import EmptyIcon from '@material-ui/icons/RadioButtonUnchecked';
import AnnouncementIcon from '@material-ui/icons/RecordVoiceOver';

import { getCoreHttp } from 'actions/httpRequest';

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            form: {},
            submitError: null,
        };

	}

    componentDidMount() {

        this.setState({ form: this.props.data });

        if (!this.props.recordings) {
            this.fetchRecordings();
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.data) {
            this.setState({ form: nextProps.data });
        }
    }

    fetchRecordings() {

        // Lets set up the request data to retreive auto attendants
        const reqData = {
            reqAction: 'recording',
        };

        const storeKey = 'recordings';

        this.props.getCoreHttp(reqData, storeKey);
    }

    async submitForm(field, data) {

        if (this.state.form[field] == data) {
            console.log('No update');
            return;
        }

        const { form } = this.state;
        const rollbackForm = Object.assign({}, form);

        // Lets validate our field
        const error = this.validateField(field, data);
        if (error) {
            this.setState({
                errors: { [field]: error },
                form: rollbackForm,
            });
            return;
        }

        // If no error, lets set the data
        // on the form and set it to state without errors
        form[field] = data;
        this.setState({
            form,
            errors: { [field]: '' },
        });

        const submitError = await this.props.submitForm(form);

        if (submitError) {
            this.setState({ submitError });
        } else {
            this.setState({ submitError: null });
        }
    }

    validateField(field, data) {

        // We want to make sure our wait
        // times are actually numbers
        // and greater than 0
        switch (field) {
        case 'responsewait':
        case 'keypresswait':
        case 'i_limit':
        case 'none_limit':
            if (data && Number.isInteger(parseInt(data)) && data >= 0) {
                return null;
            }
            return 'Please enter a valid number';

        default:
            return null;
        }
    }

    getRecordingsAsOptions() {

        if (!this.props.recordings || this.props.recordings.length == 0) {

            return [{ label: 'No recordings available', value: '' }];
        }

        const recordings = [];
        this.props.recordings.map((recording) => {
            recordings.push(recording.name);
        });

        recordings.sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
        });

        return recordings;
    }

    render() {

        this.recordingUrl = `/api/v1/core/${this.props.configurations.tenant}/playrecording`;

        if (!this.props.data || !this.state.form || !this.props.recordings) {
            return (
              <StandardSettingsPage>
                <Loading />
              </StandardSettingsPage>
            );
        }

        return (
        <div style={{
            display: 'flex',
            flex: 1,
            height: '100%',
            background: '#EFEFEF',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '50px',
            overflow: 'auto',
        }}>
          <StandardSettingsPage>
            {this.state.submitError ?
              <ErrorCard data={this.state.submitError} />
              : null
            }
            <FormControlCard
              disabled
              form={this.state.form}
              field="name"
              formControl="TextInput"
              title="Name"
              subtitle="Name of the survey"
              icon={<DescriptionIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
            />
            <FormControlCard
              form={this.state.form}
              field="intro_recording"
              formControl="AudioPlayerInput"
              recordingUrl={this.recordingUrl}
              title="Intro Recording"
              subtitle="Pre-recorded announcement to be played when the caller reaches the Post Call Survey"
              icon={<AnnouncementIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
              options={this.getRecordingsAsOptions()}
            />
             <FormControlCard
              form={this.state.form}
              field="exit_recording"
              formControl="AudioPlayerInput"
              recordingUrl={this.recordingUrl}
              title="Exit Recording"
              subtitle="Pre-recorded announcement to be played when the caller reaches the end of the Post Call Survey"
              icon={<AnnouncementIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
              options={this.getRecordingsAsOptions()}
            />
          </StandardSettingsPage>
          </div>
        );
    }
}

const bindActions = (dispatch) => ({
    getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
});

const mapStateToProps = (state) => ({
    configurations: state.http.configurations,
    recordings: state.recordings.data,
});

export default connect(mapStateToProps, bindActions)(Settings);
