import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/styles';

import Loading from 'presentational/Loading';
import Footer from 'presentational/Footer';

import ZohoIntegrationButton from './zohoIntegrationButton';

import { getUserPortalHttp, putUserPortalHttp } from 'actions/httpRequest';
import { errorNotification, successNotification } from 'actions/notifications';
import { resetFlag } from 'actions/zohoIntegration';


function ZohoIntegration(props) {

  const [data, setData] = useState(null);

  // componentDidMount
  
  useEffect(() => {
    const reqData = {
      reqAction: 'zohointegration',
    };
    props.getUserPortalHttp(reqData);
  }, []);

  //componentDidUpdate starts

  useEffect(() => {
    if (props.zohoIntegrationAuth) {
      window.open(encodeURI(props.zohoIntegrationAuth), '_blank')
      props.resetFlag();
    }
  }, [props.zohoIntegrationAuth]);

  useEffect(() => {
    if (props.successMsg) {
      props.resetFlag();
      successNotification({
        title: 'Success!', message: props.successMsg,
      })
    }
  }, [props.successMsg]);

  useEffect(() => {
    if (props.errorMsg) {
      if (props.errorCode === 404) {
        props.resetFlag();
        app.navigate('#userportal', { trigger : true });
      } else {
        props.resetFlag();
        errorNotification({
          title: 'Failed!', message: props.errorMsg,
        });
      }
    }
  }, [props.errorMsg])

  useEffect(() => {
    if (props.zohoIntegrationData) {
      setData(Object.assign({}, props.zohoIntegrationData));
    }
  }, [props.zohoIntegrationData]);

  //componentDidUpdate ends

  const updateSettings = (data) => {
    const reqData = {
      reqAction: 'zohointegration',
      reqBody: data,
    };
    const storeKey = 'editzohointegration';
    props.putUserPortalHttp(reqData, storeKey);
  }

  const autorizeZoho = () => {
    const reqData = {
      reqAction: 'zohointegrationauth',
    };
    props.getUserPortalHttp(reqData);
  }

  const toggleZoho = (field) => {
    if (data[field] === '1'){
      data[field] = '0';
    } else {
      data[field] = '1';
    }
    updateSettings(data);
    setData(data);
  }

  const onSubmit = (cancel) => {
    if (cancel) {
      app.navigate('#userportal', { trigger: true });
    } else {
      updateSettings(data);
    }
  }

  if (!data) {
    return <Loading/>;
  }
  
  const zohoEnableText = `Turn ${data.zohointegration_enabled === '1' ? 'off' : 'on'} Zoho CRM Integration`;
  const clickToCallText = `${data.zohointegration_clicktocall_enabled === '1' ? 'Disable' : 'Enable'} Click To Call for Zoho CRM`;

  return (
    <div className='container'>
      <h1>Zoho CRM Integration</h1>
      <h4>Manage your Zoho CRM Integration</h4>
      <ZohoIntegrationButton
        text={zohoEnableText}
        toggleZoho={() => toggleZoho('zohointegration_enabled')}
      />
      <p/>
      {data.zohointegration_enabled === '1' && 
        <ZohoIntegrationButton
          text={clickToCallText}
          toggleZoho={() => toggleZoho('zohointegration_clicktocall_enabled')}
        />
      }
      <p />
      <ZohoIntegrationButton
        text='Authorize Zoho'
        toggleZoho={autorizeZoho}
      />
      <h5 style={{ color: '#737373', fontWeight: 'bold' }}>Save before authorizing</h5>
      <Footer
        onClick={onSubmit}
        fullWidth={true}
      />
    </div>
  );
}

const bindActions = (dispatch) => ({
  getUserPortalHttp: (reqData) => dispatch(getUserPortalHttp(reqData)),
  putUserPortalHttp: (reqData, storeKey) => dispatch(putUserPortalHttp(reqData, storeKey)),
  resetFlag: () => dispatch(resetFlag()),
});

const mapStateToProps = (state) => ({
  zohoIntegrationData: state.zohoIntegration.zohoIntegrationData,
  successMsg: state.zohoIntegration.successMsg,
  errorMsg: state.zohoIntegration.errorMsg,
  errorCode: state.zohoIntegration.errorCode,
  zohoIntegrationAuth: state.zohoIntegration.zohoIntegrationAuth,
});


export default connect(mapStateToProps, bindActions)(ZohoIntegration);