import React, { Component } from 'react';
import { connect } from 'react-redux';

import request from 'superagent';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';

import MasterHistory from 'presentational/MasterHistory';
import MasterView from 'presentational/MasterView';
import ActionCard from 'presentational/ActionCard';
import DetailContainer from 'presentational/DetailContainer';
import StandardDetailsPage from 'presentational/StandardDetailsPage';
import LeftBar from 'presentational/LeftBar';
import SaveChangesModal from 'presentational/SaveChangesModal';


import DynamicAgentDetails from './details';
import DynamicAgentsHelp from './help';
import DynamicAgentSkills from './skills';
import DynamicAgentNew from './newAgent';
import DynamicAgentManage from './manage';

import {
    getCoreHttp,
    deleteCoreHttp,
    patchCoreHttp,
    postCoreHttp,
    putCoreHttp,
    httpSuccess,
    updateConfigurations,
} from 'actions/httpRequest';

import {
    errorNotification,
    successNotification,
} from 'actions/notifications';

const styles = () => ({
    button: {
        fontSize: '100%',
    },
    menuItem: {
        fontSize: '100%',
    },
});

import { selectDynamicAgent } from 'actions/dynamicAgents';

class DynamicAgents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dynamicAgentsFetchError: null,
            clickedInsideElements: false,
            clickedSurroundingElements: false,
            childFormData: {},
            leftBarData: [],
            pendingAgents: true,
            route: null,
            selectedDynamicAgent: null,
            newChanges: false,
            showingSaveChangesModal: false,
            closeMenu: false,
            isSkillRemoved: null,
            showingCopyAgentModal: false,
            showingSaveChangesModal: false,
            title: '',
        };
    }

    componentDidMount() {

        // If we don't have extensions, lets get that meow
        if (!this.props.extensions || !this.props.extensions) {

            this.fetchExtensions();
        }

        this.setState({
            route: null,
            selectedDynamicAgent: null,
        });

        this.props.selectDynamicAgent('');
        this.clickedElement();

        if (!this.props.dynamicAgents) {

            return this.fetchDynamicAgents();
        }

        this.setState({ pendingAgents: false });


        return this.prepareDataForLeftBar(this.props.dynamicAgents);
    }

    componentWillReceiveProps(nextProps) {

        // If the new agents object is different (or if we
        // didn't have one before) lets get the data ready for leftbar
        if (nextProps.dynamicAgents && this.props.dynamicAgents !== nextProps.dynamicAgents) {

            this.setState({ pendingAgents: false });
            this.prepareDataForLeftBar(nextProps.dynamicAgents);
        }

        if (nextProps.selectedDynamicAgentId && nextProps.dynamicAgents) {

            const selectedDynamicAgent = nextProps.dynamicAgents.find(
                (obj) => obj.agent_id == nextProps.selectedDynamicAgentId,
            );

            // If we have a new agent, handle this here
            if (nextProps.selectedDynamicAgentId == 'New Agent') {
                this.setState({ selectedDynamicAgent: { agent_id: 'New Agent' } });
                return;
            }

            if (!selectedDynamicAgent) {
                console.error('No agent found by the id of: ', nextProps.selectedDynamicAgentId);
                return;
            }

            // If the id has changed, lets reset the route here
            if (nextProps.selectedDynamicAgentId !== this.props.selectedDynamicAgentId || nextProps.dynamicAgents.length !== this.props.dynamicAgents.length) {
                this.setState({
                    selectedDynamicAgent,
                    route: '',
                });
            } else {
                this.setState({ selectedDynamicAgent });
            }
        }
    }

    openCloseMenu(){
        this.setState({closeMenu: !this.state.closeMenu});
    }

    componentWillUnmount() {

        this.setState({
            route: null,
            selectedDynamicAgent: null,
        });
        this.props.selectDynamicAgent('');
    }

    fetchDynamicAgents() {

        const reqData = {
            reqAction: 'dynamicagents',
        };

        this.props.getCoreHttp(reqData);
    }

    fetchExtensions() {

        console.log('No extensions found, fetching extensions now');

        const reqData = {
            reqAction: 'extensions',
        };

        this.props.getCoreHttp(reqData, 'extensions');
    }

    // prepareDataForLeftBar maps through all agents received
    // via http and formats data objects to be consumed by the leftBar
    prepareDataForLeftBar(data) {

        // return on no data
        if (!data) {
            return console.log('No dynamic agents returned');
        }

        // array we will push our new object into
        const leftBarData = [];
        data.map((item) => {

            // Set the titles here
            const title = item.agent_id
            const subtitle = `${item.firstname} ${item.lastname}`;
            const id = item.agent_id;

            // push the data into the leftBar array
            return leftBarData.push({ id, title, subtitle });
        });

        // finally, lets set the leftBarData to state to update leftbar
        return this.setState({ leftBarData });
    }

    leftBarSelect(agentId) {
        var that = this;
        var leftBarDataArray = this.state.leftBarData;
        leftBarDataArray.forEach(function (obj) {
            if (obj.id == agentId) {
                that.setState({title: obj.id + ' - ' + obj.subtitle})
            }
        });
        
        this.setState({ route: null });

        this.props.selectDynamicAgent(agentId);

        this.getDynamicAgentDetails(agentId);
        this.getDynamicAgentAuditHistory(agentId);
    }

    getDynamicAgentDetails(agentId) {

        const reqData = {
            reqAction: 'dynamicagents',
            reqObject: agentId,
        };

        const storeKey = 'dynamicAgents';

        this.props.getCoreHttp(reqData, storeKey);
    }

    getDynamicAgentAuditHistory(agentId) {

        const reqData = {
            auditHistory: true,
            reqAction: 'dynamicagents',
            reqObject: agentId,
        };

        const storeKey = 'dynamicAgents';

        this.props.getCoreHttp(reqData, storeKey);
    }

    submitSettingsForm(data) {

        if (!data)
            return;

        const reqData = {
            reqAction: 'dynamicagents',
            reqObject: data.agent_id,
            reqBody: data,
        };

        const storeKey = 'dynamicAgents';

        this.props.putCoreHttp(reqData, storeKey);

        successNotification({ title: 'Dynamic Agent Updated!', message: `Successfully updated dynamic agent ${data.agent_id}` });

        if (this.state.isSkillRemoved) {
            errorNotification({ title: 'Please Note: ', message: 'Changes will remain ineffective until subsequent sign-in!' });
        }

        this.setState({isSkillRemoved: null});
        this.setState({
            title: data.agent_id + ' - ' + data.firstname + ' ' + data.lastname,
            clickedInsideElements: false,
            clickedSurroundingElements: false,
            newChanges: false,
        });
    }

    createNewDynamicAgent(newAgentId, copiedAgentObj) {

        const reqData = {
            reqAction: 'dynamicagents',
            reqObject: newAgentId,
            reqBody: copiedAgentObj ? copiedAgentObj : { agent_id: newAgentId },
        };

        const storeKey = 'dynamicAgents';

        this.props.postCoreHttp(reqData, storeKey);

    }

    async handleDeleteDynamicAgent() {

        if (!this.state.selectedDynamicAgent) {
            console.log('No agent selected');
            return;
        }

        const { agent_id } = this.state.selectedDynamicAgent;
        const { selectedDynamicAgent } = this.state;

        const reqData = {
            reqAction: 'dynamicagents',
            reqObject: agent_id,
        };

        const storeKey = 'dynamicAgents';

        this.props.deleteCoreHttp(reqData, storeKey);

        let configurations = this.props.configurations;
        configurations.dynamicagents.map((agent, index) => {
            if (selectedDynamicAgent.agent_id == agent.agent_id) {
                configurations.dynamicagents.splice(index,1);
            }
        });
        this.props.updateConfigurations(configurations);

        // Lets set state to clear the route and selected dynamic agent
        this.setState({
            route: '',
            selectedDynamicAgent: null,
        });
        this.props.selectDynamicAgent('');

        // Lets trigger a notification
        successNotification({
            title: 'Success!',
            message: `Deleted ${agent_id}`,
        });
        this.props.updateConfigurations(configurations);
    }

    clickedElement = () => {
        var elements = document.querySelectorAll(".header, .leftBarDiv, .detailContainerDiv");
        for (var i = 0; i < elements.length; i++) {
            elements[i].addEventListener("click", () => {
                this.setState({ clickedSurroundingElements: true }, () => {
                    this.unsavedChanges();
                })
            });
        }
    };

    unsavedChanges() {
        const { clickedInsideElements, clickedSurroundingElements, newChanges } = this.state;
        if (clickedInsideElements && clickedSurroundingElements && newChanges) {
            this.openModal()
        }
    }

    openModal() {
        this.setState({ showingSaveChangesModal: true })
    }

    clickedNode(){
        this.setState({ clickedInsideElements: true })
    }

    madeChanges(answer, data) {
        this.setState({
            newChanges: answer,
            childFormData: data,
        });
    }

    goToHelp() {
        const win = window.open('http://help.fluentcloud.com/support/solutions', '_blank');
        win.focus();
    }

    handleAddNewClick() {
        this.props.selectDynamicAgent('New Agent');
        this.navigate('New');
    }

    handleCancel(){
        this.leftBarSelect(this.state.selectedDynamicAgent.agent_id);
        this.setState({showingSaveChangesModal: false});
        this.setState({newChanges: false});
    }

    getStatus() {

        const { dynamicAgents, selectedDynamicAgentId } = this.props;
        const index = dynamicAgents.findIndex((obj) => obj.agent_id == selectedDynamicAgentId);
        const agent = dynamicAgents[index];

        if (!agent) {
            return 'Loading...';
        }

        if (agent.paused == '1') {
            return 'Paused';
        }

        if (agent.signedin == '1') {
            return 'Signed In';
        }

        return 'Signed Out';
    }

    getSkillRemovalStatus = async skillRemoval => {
        this.setState({isSkillRemoved: skillRemoval});
    };

    dynamicAgentRoutes() {

        return ([
            <ActionCard
                key="Details"
                title="Details"
                subtitle="Manage details for this agent"
                action={() => this.navigate('Details')}
            />,
            <ActionCard
                key="Skills"
                title="Skills"
                subtitle="Update agent skills and the queues they are part of"
                action={() => this.navigate('Skills')}
            />,
            <ActionCard
                key="Manage"
                title="Manage"
                subtitle="Manage current status for this agent"
                footer={`Current Status: ${this.getStatus()}`}
                action={() => this.navigate('Manage')}
            />,
            <ActionCard
                key="History"
                title="History"
                subtitle="See who made changes to this agent"
                action={() => this.navigate('History')}
            />,
        ]);
    }

    navigate(route) {

        this.setState({ route });
    }

    //Async function to handle copying of Agents
    async handleCopyConfirmClose(shouldCopy) {

        //Enter this function with a boolean to make sure we should be copying, and not just closing our modal.
        if(shouldCopy){

            //We must reset our Route here.
            //This avoids issues that stem from being in a details view at the time of copy.
            this.setState({ route: null });

            //Loop through our agents to ensure that our agent doesn't already exist.
            let agentExists = this.props.dynamicAgents.some(agent => agent.agent_id === this.state.newAgentId)

            //Catch Agent IDs that already exist here.
            if(agentExists){
                this.props.errorNotification({
                    title: 'Failed!',
                    message: 'New Agent ID must be unique.',
                });
                this.setState({
                    newAgentId: '',
                    showingCopyAgentModal: false
                });
                return;
            }

            //Create a copy of our currently selected agent.
            const targetAgentObj = this.props.dynamicAgents.find(agent => agent && agent.agent_id == this.props.selectedDynamicAgentId);
            const copiedAgentObj = Object.assign({}, targetAgentObj);

            //We just need to change the agent ID and names wherever applicable.
            //We also need to zero out agent status so they aren't logged in or paused by default.
            //Also clear associated username as this being set can cause internal problems, especially with WebPhone.
            copiedAgentObj.agent_id = this.state.newAgentId;
            copiedAgentObj.firstname = this.state.newAgentFirstName;
            copiedAgentObj.lastname = this.state.newAgentLastName;
            if(copiedAgentObj.DynamicAgentsSkills.length > 0){
                copiedAgentObj.DynamicAgentsSkills.forEach((skill) => {
                    skill.agent_id = this.state.newAgentId;
                });
            }
            copiedAgentObj.paused = '0';
            copiedAgentObj.signedin = '0';
            copiedAgentObj.username = '';

            //Attempt to create our new agent, catch errors encountered during creation.
            const error = await this.createNewDynamicAgent(this.state.newAgentId, copiedAgentObj);
            if (error) {
                console.error(error);
                this.props.errorNotification({
                    title: 'Failed!',
                    message: 'Failed to copy Agent.',
                });
                this.setState({ showingCopyAgentModal: false });
                return;
            } else {
                //if we're without error, let's manually set our new agent to be the current agent along with closing the copy modal.
                this.props.selectDynamicAgent(this.state.newAgentId);
                this.setState({
                    title: this.state.newAgentId + ' - ' + this.state.newAgentFirstName + ' ' + this.state.newAgentLastName,
                    showingCopyAgentModal: false
                });
            }
        //We'll hit here if the user is just trying to leave the modal.
        } else {
            this.setState({ showingCopyAgentModal: false })
        }
    }

    handleCopyFieldChange = (e) => {
        const regex = /^[0-9\b]+$/;

        switch(e.target.name){
            case 'newAgentID':
                //accept all numbers, or empty string.
                if(regex.test(e.target.value) || e.target.value == ''){
                    this.setState({ newAgentId: e.target.value });
                }
                break;
            case 'newAgentFirstName':
                this.setState({ newAgentFirstName: e.target.value });
                break;
            case 'newAgentLastName':
                this.setState({ newAgentLastName: e.target.value });
                break;
            default:
                break;
        }
    }

    renderContent() {

        const {
            dynamicAgents,
            dynamicAgentsConfig,
            extensions,
            selectedDynamicAgentId,
            tokenConfig,
        } = this.props;

        const { route } = this.state;

        const { selectedDynamicAgent } = this.state;

        if (!selectedDynamicAgentId || !dynamicAgents) {
            return <DynamicAgentsHelp />;
        }

        const index = dynamicAgents.findIndex((obj) => obj.agent_id == selectedDynamicAgentId);

        const agent = dynamicAgents[index];

        switch (route) {

            case 'New':
                return (
                    <DynamicAgentNew
                        submitForm={(data) => this.createNewDynamicAgent(data)}
                        selectDynamicAgent={(agent_id) => this.props.selectDynamicAgent(agent_id)}
                    />
                );

            case 'Details':

                return (
                    <DynamicAgentDetails
                        submitForm={(data) => this.submitSettingsForm(data)}
                        cancel={() => this.handleCancel()}
                        data={agent}
                        users={dynamicAgentsConfig.users}
                        clickedNode={() => this.clickedNode()}
                        madeChanges={(answer, data) => this.madeChanges(answer, data)}
                    />
                );

            case 'Skills':

                return (
                    <DynamicAgentSkills
                        submitForm={(skills) => {
                            const formData = Object.assign({}, agent);
                            formData.DynamicAgentsSkills = skills;
                            this.submitSettingsForm(formData);
                        }}
                        skillRemovalStatus = {this.getSkillRemovalStatus}
                        data={agent}
                        skills={agent.DynamicAgentsSkills}
                    />
                );

            case 'Manage':

                return (
                    <DynamicAgentManage
                        config={dynamicAgentsConfig}
                        data={agent}
                        extensions={extensions}
                        submitForm={(data) => this.submitSettingsForm(data)}
                    />
                );

            case 'History':
                return <MasterHistory data={agent.auditHistory} />;

            default:
                return <StandardDetailsPage cards={this.dynamicAgentRoutes()} />;
        }
    }

    saveChange() {
        this.setState({
            showingSaveChangesModal: false,
            newChanges: false,
            clickedInsideElements: false,
            clickedSurroundingElements: false,
        });
        this.submitSettingsForm(this.state.childFormData);
        this.handleCancel();
    }

    discardChange() {
        this.setState({
            showingSaveChangesModal: false,
            newChanges: false,
            clickedInsideElements: false,
            clickedSurroundingElements: false,
        }, () => this.handleCancel());
    }

    render() {

        const {
            leftBarData,
            route,
        } = this.state;

        const {
            dynamicAgents,
            selectedDynamicAgentId,
            classes
        } = this.props;

        let title = '';

        let selectedDynamicAgent = null;
        
        if (dynamicAgents && selectedDynamicAgentId) {
            
            selectedDynamicAgent = dynamicAgents.find(
                (obj) => obj.agent_id == selectedDynamicAgentId,
            );
            
            if (selectedDynamicAgent && selectedDynamicAgent.firstname) {
                title = this.state.title;
            } else if (selectedDynamicAgent) {
                title = selectedDynamicAgent.agent_id;
            }
        }

        const contextMenuOptions = [
            <MenuItem
                disabled={!selectedDynamicAgent}
                onClick={() => {this.handleDeleteDynamicAgent(); this.openCloseMenu();}}
                classes={{ root: classes.menuItem }}
                key={0}
            >
                Delete
            </MenuItem>,
            <MenuItem
                onClick={() => {this.setState({ showingCopyAgentModal: true }); this.openCloseMenu();}}
                classes={{ root: classes.menuItem }}
                disabled={!selectedDynamicAgent}
                key={1}
            >
            Copy
            </MenuItem>,
            <MenuItem
                onClick={() => {this.goToHelp(); this.openCloseMenu();}}
                classes={{ root: classes.menuItem }}
                key={2}
            >
                Help
            </MenuItem>,
        ];

        const saveModalActions = [
            <Button
                label="Save Changes"
                primary
                onClick={() => {
                    this.setState({
                        showingSaveChangesModal: false,
                        newChanges: false,
                        clickedInsideElements: false,
                        clickedSurroundingElements: false,
                    });
                    this.submitSettingsForm(this.state.childFormData);
                    this.handleCancel();
                }}
            >
            </Button>,
            <Button
                label="Discard Changes"
                secondary
                onClick={() => {
                    this.setState({
                        showingSaveChangesModal: false,
                        newChanges: false,
                        clickedInsideElements: false,
                        clickedSurroundingElements: false,
                    }, () => this.handleCancel())
                }}
            >
            </Button>
        ];

        const copyModal = [
            <Dialog
                open={this.state.showingCopyAgentModal}
                onEnter={() => this.setState({ newAgentFirstName: '', newAgentLastName: '', newAgentId: '' })}
                onClose={() => this.handleCopyConfirmClose(false)}
                fullWidth={true}
            >
                <DialogTitle>Agent Copy</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please provide a unique Agent ID.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        fullWidth={true}
                        margin='dense'
                        name='newAgentID'
                        label='New Agent ID'
                        type='text'
                        value={this.state.newAgentId}
                        onChange={this.handleCopyFieldChange}
                    />
                    <TextField
                        fullWidth={true}
                        margin='dense'
                        name='newAgentFirstName'
                        label='New Agent First Name'
                        type='text'
                        value={this.state.newAgentFirstName}
                        onChange={this.handleCopyFieldChange}
                    />
                    <TextField
                        fullWidth={true}
                        margin='dense'
                        name='newAgentLastName'
                        label='New Agent Last Name'
                        type='text'
                        value={this.state.newAgentLastName}
                        onChange={this.handleCopyFieldChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        classes={{ root: classes.button }}
                        color='primary'
                        onClick={() => this.handleCopyConfirmClose(false)}
                    >Cancel</Button>
                    <Button
                        disabled={!this.state.newAgentId || !this.state.newAgentFirstName || !this.state.newAgentLastName}
                        classes={{ root: classes.button }}
                        color='primary'
                        onClick={() => this.handleCopyConfirmClose(true)}
                    >Copy</Button>
                </DialogActions>
            </Dialog>
        ]

        return (
            <MasterView>
                <DetailContainer
                    leftBar={
                        <LeftBar
                            addNew={() => this.handleAddNewClick()}
                            data={leftBarData}
                            pending={this.state.pendingAgents}
                            selected={selectedDynamicAgentId}
                            select={(id) => this.leftBarSelect(id)}
                            title="Dynamic Agents"
                        />
                    }
                    title={title}
                    route={route != 'New' ? route : null}
                    return={() => this.setState({ route: null })}
                    contextMenuOptions={contextMenuOptions}
                    closeMenu={this.state.closeMenu}
                >
                    {this.renderContent()}
                </DetailContainer>

                {copyModal}

                <SaveChangesModal
                    saveChange={() => this.saveChange()}
                    discardChange={() => this.discardChange()}
                    cancelModal={() =>this.discardChange()}
                    show={this.state.showingSaveChangesModal}
                />
            </MasterView>
        );
    }
}

const bindActions = (dispatch) => ({
    getCoreHttp: (reqData) => dispatch(getCoreHttp(reqData)),
    deleteCoreHttp: (reqData) => dispatch(deleteCoreHttp(reqData)),
    patchCoreHttp: (reqData) => dispatch(patchCoreHttp(reqData)),
    putCoreHttp: (reqData) => dispatch(putCoreHttp(reqData)),
    postCoreHttp: (reqData) => dispatch(postCoreHttp(reqData)),
    httpSuccess: (data) => dispatch(httpSuccess(data)),
    selectDynamicAgent: (agent) => dispatch(selectDynamicAgent(agent)),
    updateConfigurations: (configurations) => dispatch(updateConfigurations(configurations)),
});

const mapStateToProps = (state) => ({
    dynamicAgentsConfig: state.dynamicAgents.config,
    dynamicAgents: state.dynamicAgents.data,
    metaData: state.dynamicAgents.metaData,
    pending: state.dynamicAgents.pending,
    extensions: state.extensions.data,
    selectedDynamicAgentId: state.dynamicAgents.selected,
    tenant: state.token.tenant,
    tokenConfig: state.token.config,
    session: state.token.token,
    configurations: state.http.configurations,
});

export default connect(mapStateToProps, bindActions)(withStyles(styles)(DynamicAgents));
