import {
    CHANGE_TENANT,
    INVALIDATE_HTTP_DATA,
} from 'actions/typeConstants';
import { formatDateReportsService } from 'utils/misc';

export const reportsData = (state = {}, action) => {

    const currentState = Object.assign({}, state);

    switch (action.type) {

    case CHANGE_TENANT:
    case INVALIDATE_HTTP_DATA:

        return '';

    case 'REPORTSDATA_HTTP_PENDING':

        if (!action.reportName) {

            console.log('Got pending report with no report name: ', action);
            return state;
        }

        console.log('[Reducer][reportsData] Retreiving report data for report: ', action.reportName);

        if (!currentState[action.reportName]) {
            currentState[action.reportName] = {};
        }

        currentState[action.reportName].pending = true;

        return currentState;

    case 'REPORTSDATA_HTTP_SUCCESS':

        if (!action.reportName) {

            console.log('[Reducer][reportsData] Got success report with no report name: ', action);
            return state;
        }

        // Lets create the sub object here if it doesn't exist
        if (!currentState[action.reportName]) {
            currentState[action.reportName] = {};
        }

        currentState[action.reportName].pending = false;

        if (!action.data || !action.data.data) {

            console.log('No data received for report: ', action.reportName, action);

            currentState[action.reportName]['metadata'] = action.data.metadata;
            currentState[action.reportName]['data'] = [];
            return currentState;
        }

        // If we have a reqObj lets find index of it inside dataset
        if (action.reqObject) {

            const found = currentState[action.reportName]['data'].findIndex((obj) => obj.linkedid === action.reqObject)
            // If no returned data from API then delete from state
            if (!action.data) {
                delete currentState[action.reportName]['data'][found];
            // If not found it is a new object, add to state
            } else if (found === -1) {
                currentState[action.reportName]['data'].push(action.data.data);
            // Otherwise set to state
            } else {
                currentState[action.reportName]['data'][found] = action.data.data;
            }

            // currentState[action.reportName]['changed'] = !currentState[action.reportName]['changed'];
            if (currentState && currentState.cdr && currentState.cdr.data) {
                for (const call of Object.keys(currentState.cdr.data)) {
                    // Lets only format the data if it has not already been formatted
                    if (!currentState.cdr.data[call].formatted) {
                        currentState.cdr.data[call].calldate = formatDateReportsService(currentState.cdr.data[call].calldate);
                        currentState.cdr.data[call].formatted = true;
                    }
                }
            }
            return Object.assign({}, currentState);

        }

        if (Array.isArray(currentState[action.reportName]['data']) &&
            action.reportPage &&
            action.reportPage != 1) {

            // Concat new data to current data
            const newData = currentState[action.reportName]['data'].concat(action.data.data);

            // Lets store data to our report
            currentState[action.reportName]['data'] = newData;
            currentState[action.reportName]['metadata'] = action.data.metadata;

            return currentState;

        } else {

            // Lets store data to our report
            currentState[action.reportName]['data'] = action.data.data;
            currentState[action.reportName]['metadata'] = action.data.metadata;

            if (currentState && currentState.cdr && currentState.cdr.data) {
                for (const call of Object.keys(currentState.cdr.data)) {
                    // Lets only format the data if it has not already been formatted
                    if (!currentState.cdr.data[call].formatted) {
                        currentState.cdr.data[call].calldate = formatDateReportsService(currentState.cdr.data[call].calldate);
                        currentState.cdr.data[call].formatted = true;
                    }
                }
            }
        }

        // Finally, return our state
        return currentState;

    case "LADDER_HTTP_SUCCESS":
        let ladder = action.data.data[0].ladders;
        const found = currentState.cdr.data.findIndex(obj => ladder[0].linkedid == obj.linkedid);
        currentState.cdr.data[found].ladders = ladder;
        return currentState;

    default:
        return state;
    };
};
