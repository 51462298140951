import React, { Component } from 'react'
import PropTypes from 'prop-types';

import FormControlCard from 'presentational/FormControlCard';

import BusyApplicationIcon from '@material-ui/icons/PhoneMissed';

class BusyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentReference: null,
    };
  }

  componentDidMount() {
    this.setState({
      parentReference: this.props.parentReference,
    });
  }

  changeState = (data) => {
    const { parentReference } = this.props
    const { form } = parentReference.state;
    const position = form.config.applications.findIndex(item => item.friendlyname === data);
    const selected = form.config.applications[position].name;
    delete form.cfw_busy_value;
    form.cfw_busy_application = selected;
    parentReference.setState({
      form,
      cfw_busy_application_friendlyname: data,
    })
  }

  alphebetizeDropdownValues(){
    const applications = ["autoattendant", "callflows", "callgroups", "queues", "recordings"]
    if(this.state.parentReference){
      const { parentReference } = this.state;
      const { form } = parentReference.state;

      applications.forEach(application => {
          form.config[application].sort(function(a,b){
            if ((a.name).toLowerCase() < (b.name).toLowerCase()) {
            return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
            }
            return 0;
        })
      })
    }
    if( App.Configurations && App.Configurations.attributes.conferenceRooms){
      App.Configurations.attributes.conferenceRooms.sort((a,b) => {
        return Number(a.confno) - Number(b.confno)
       })
    }
  }

  renderExtraField = () => {
    const { parentReference } = this.state;
    const { form } = parentReference.state;
    switch(parentReference.state.cfw_busy_application_friendlyname) {
      case 'Auto Attendant':
        return (
          <FormControlCard
            form={parentReference.state.form}
            field="cfw_busy_value"
            formControl="SelectInput"
            title="Auto Attendant:"
            submitForm={(field, data) => parentReference.changeCondition(field, data)}
            options={['Select option'].concat(form.config.autoattendant.map(item => {return item.name}))}
          />
        );
      case 'Call Flow':
        return (
          <FormControlCard
            form={parentReference.state.form}
            field="cfw_busy_value"
            formControl="SelectInput"
            title="Call Flow:"
            submitForm={(field, data) => parentReference.changeCondition(field, data)}
            options={['Select option'].concat(form.config.callflows.map(item => {return item.name}))}
          />
        );
      case 'Call Forwarding':
        return (
          <FormControlCard
            form={parentReference.state.form}
            field="cfw_busy_value"
            formControl="PhoneInputForForwardingNoAdd"
            title="Call Forwarding:"
            submitForm={(field, data, counrtyData) => parentReference.updateNumber(field, data, counrtyData)}
            getCountryCode={(counrtyCode) => parentReference.setState({ counrtyCode })}
          />
        );
      case 'Call Group':
        return (
          <FormControlCard
            form={parentReference.state.form}
            field="cfw_busy_value"
            formControl="SelectInput"
            title="Call Group:"
            submitForm={(field, data) => parentReference.changeCondition(field, data)}
            options={['Select option'].concat(form.config.callgroups.map(item => {return item.name}))}
          />
        );
      case 'Extension':
        const currentExtension = {
          cfw_busy_value: '',
        };
        const position = app.token.attributes.users.findIndex(item => parentReference.state.form.cfw_busy_value === item.extension);
        if (position !== -1) currentExtension.cfw_busy_value = app.token.attributes.users[position].fullextension;
        return (
          <FormControlCard
            form={currentExtension}
            field="cfw_busy_value"
            formControl="SelectInput"
            title="Extension:"
            submitForm={(field, data) => parentReference.changeCondition(field, data)}
            options={['Select option'].concat(app.token.attributes.users.map(item => {return item.fullextension}))}
          />
        );
      case 'Queue':
        return (
          <FormControlCard
            form={parentReference.state.form}
            field="cfw_busy_value"
            formControl="SelectInput"
            title="Queue:"
            submitForm={(field, data) => parentReference.changeCondition(field, data)}
            options={['Select option'].concat(form.config.queues.map(item => {return item.name}))}
          />
        );
      case 'Recording':
        return (
          <FormControlCard
            form={parentReference.state.form}
            field="cfw_busy_value"
            formControl="SelectInput"
            title="Recording:"
            submitForm={(field, data) => parentReference.changeCondition(field, data)}
            options={['Select option'].concat(form.config.recordings.map(item => {return item.name}))}
          />
        );
      case 'Conference Room':
        return (
          <FormControlCard
            form={parentReference.state.form}
            field="cfw_busy_value"
            formControl="SelectInput"
            title="Conference Room:"
            submitForm={(field, data) => parentReference.changeCondition(field, data)}
            options={['Select option'].concat(App.Configurations && App.Configurations.attributes.conferenceRooms ? App.Configurations.attributes.conferenceRooms.map(item => item.name == "No Room" ? item.name : `${item.confno} - ${item.name}` ): "") } 
          />
        );
      case 'Voicemail':
        const currentVoicemail = {
          cfw_busy_value: '',
        };
        const index = form.config.voicemailboxes.findIndex(item => parentReference.state.form.cfw_busy_value === item.mailbox);
        if (index !== -1) {
          const selected = form.config.voicemailboxes[index]
          currentVoicemail.cfw_busy_value = `${selected.mailbox} - ${selected.fullname}`;
        }
        return (
          <FormControlCard
            form={currentVoicemail}
            field="cfw_busy_value"
            formControl="SelectInput"
            title="Voicemail:"
            submitForm={(field, data) => parentReference.changeCondition(field, data)}
            options={['Select option'].concat(form.config.voicemailboxes.map(item => {return `${item.mailbox} - ${item.fullname}`}))}
          />
        );
      default:
        return null;
    }
  }

  render() {
    this.alphebetizeDropdownValues()
    if (this.state.parentReference == null){
      return null;
    }
    const { parentReference } = this.state;
    const { form } = parentReference.state;
    return (
      <>
        <FormControlCard
          form={parentReference.state}
          field="cfw_busy_application_friendlyname"
          formControl="SelectInput"
          icon={<BusyApplicationIcon/>}
          title="On Busy Application:"
          submitForm={(field, data) => this.changeState(data)}
          options={form.config.applications.map(item => {return item.friendlyname})}
        />
        {this.renderExtraField()}
      </>
    );
  }
}

BusyForm.propTypes = {
  parentReference: PropTypes.object.isRequired,
}

export default BusyForm;