import React, {useState} from 'react';
import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-input-intl';

import AreaCodeInput from 'presentational/formControls/AreaCodeInput'
import SelectInput from 'presentational/formControls/SelectInput';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

const style = {
    phoneInput: {
        width: '250px',
    },
    containerStyle: {
        width: '255px',
        marginRight: '10px',
    },
    areaCodeInput: {
        width: '80px',
        position: 'relative',
        bottom: '3px',
        padding: "15px 2px"
    },
    selectRoute: {
        marginBottom: '10px'
    },
}

function CallFilterCardRenderer(props) {
  const { incomingPhoneNumberOnParentState, assignedRouteOnParentState, incomingAreaCodeOnParentState } = props
  const classes = props.useStyles();
  const [refreshValue, setRefreshValue] = useState(false);
  let { routes } = props;
  routes.sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1)

  let cardDisplay;

  if (props.isEditing) {
    cardDisplay = 
        <div className={classes.grid}>
            <div className={classes.allOptionsContainer}>
                <p style={{marginLeft: "14px", marginRight: '10px'}}>When</p>
                {props.filterType ==="areacode"?
                    <AreaCodeInput
                        placeholder="Area Code"
                        style={style.areaCodeInput}
                        inputClassName={classes.phoneInput}
                        value={ incomingAreaCodeOnParentState }
                        onChange={(val) => props.onAreaCodeChange(val)}
                    />
                :
                    <PhoneInput
                        placeholder="Phone Number"
                        defaultCountry={'us'}
                        inputStyle={style.phoneInput}
                        containerStyle={style.containerStyle}
                        value={ incomingPhoneNumberOnParentState || '+1' }
                        onChange={(val) => {
                            if (refreshValue) {
                                setRefreshValue(false);
                            } else {
                                let incomingPhoneNumber = val;
                                if (incomingPhoneNumber.startsWith('+') === false) {
                                    incomingPhoneNumber = `+${val}`;
                                } 
                                props.setParentState({ incomingPhoneNumber })
                            }
                        }}
                        getCountryCode={countryCode => {
                            if (incomingPhoneNumberOnParentState.startsWith(`+${countryCode}`) === false) {
                                props.setParentState({ incomingPhoneNumber: `+${countryCode}` })
                                setRefreshValue(true);
                            }
                        }}
                    />
                }    
                <p>calls, route them to</p>
                <div className={classes.routeOptionContainer}>

                <SelectInput
                    fontSizeOverride={'14px'}
                    paddingOverride={'0px 0px 0px 5px'}
                    style={style.selectRoute}
                    field="assigned route"
                    onChange={ assignedRoute => props.setParentState({ assignedRoute })}
                    value={ assignedRouteOnParentState }
                    options={routes}
                    />     
                </div>
            </div>
            <div className={classes.btnContainer}>
                <IconButton
                    onClick={() => {
                        props.setParentState({ showingModal: true });
                        props.storeForm();
                        props.toggleEditing();
                    }}
                    >
                    <CancelIcon />
                </IconButton>
                <IconButton
                    onClick={() => {
                        props.handleSubmit();
                        props.storeForm();
                    }}
                    >
                    <SaveIcon  classes={{ root: classes.saveButton }}/>
                </IconButton>
            </div>
        </div>
} else {
    cardDisplay = 
        <div className={classes.grid}>
            
            <CardHeader title={props.subtitle}/>
            <div className={classes.btnContainer}>

                <IconButton
                    onClick={() => {
                        props.storeForm();
                        props.toggleEditing();
                    }}
                    classes={{ root: classes.editButton }}
                    >
                    <EditIcon />
                </IconButton>
                <IconButton
                    onClick={() => {
                        props.removeCallFilter( props.index );
                    }}
                    classes={{ root: classes.deleteButton }}
                    >
                    <DeleteIcon  />   
                </IconButton>
            </div>
        </div>
}
  return (
    <Card classes={{ root: classes.card }}>
      {cardDisplay}                     
    </Card>
  )
}

CallFilterCardRenderer.propTypes = {
    useStyles: PropTypes.func,
    subtitle: PropTypes.string,
    isEditing: PropTypes.bool,
    routes: PropTypes.array,
    incomingPhoneNumberOnParentState: PropTypes.string,
    assignedRouteOnParentState: PropTypes.string,
    setParentState: PropTypes.func,
    storeForm: PropTypes.func,
    toggleEditing: PropTypes.func,
    handleSubmit: PropTypes.func,
    removeCallFilter: PropTypes.func,
}

export default CallFilterCardRenderer;