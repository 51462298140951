import React, { Component } from 'react'

import StandardSettingsPage from 'presentational/StandardSettingsPage';
import SubmitButton from 'presentational/formControls/SubmitButton';
import CancelButton from 'presentational/formControls/CancelButton';
import Loading from 'presentational/Loading';

import AddIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import { withStyles } from '@material-ui/styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AutoComplete from 'presentational/autoComplete';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  deleteCoreHttp,
  getCoreHttp,
  postCoreHttp,
  putCoreHttp,
} from 'actions/httpRequest';
import { successNotification, errorNotification } from 'actions/notifications';
import { reloadAvailableNumbersFlag } from 'actions/phoneNumbers';

import { connect } from 'react-redux';

class BuyNewNumber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        state: '',
        city: '',
        areaCode: '',
        numberPattern: '',
      },
      key: '0',
      selectedNumbers: [],
      buttonDisabled: true,
      availableNumbers: [],
      loadLimit: 10,
      loaderMessage: ''
    };

    this.selectNumberMap = {};
  }

  purchaseNumbers = () => {
    let data = this.state.selectedNumbers.map(number => {
      return {phonenumber: number}
    });
    data = { phonenumber: data, total: '$NaN'};

    const reqData = {
      reqAction: 'addphonenumber',
      reqBody: data,
    };

    const storeKey = 'byunumbers';

    this.props.postCoreHttp(reqData, storeKey);
    this.setState({ buttonDisabled: true, selectedNumbers: [] })
  }
  
  setInputData = (stateObj, formObj) => {
    const defaultData = { loaderMessage: '', availableNumbers: [], key: '0'};
    const defaultForm = {state: '', city: '', areaCode: '', numberPattern: ''};
    const data = {...defaultData, ...stateObj, form: {...defaultForm, ...formObj}};

    this.setState(data);
  }

  searchByPattern = (e) => {
    const value = e.target.value;
    if(value===''){
      this.setInputData({key: value}, {numberPattern: value});
      return;
    }
    this.setInputData({key: value,  loaderMessage: 'Loading...'}, {numberPattern: value});

    if(value.length >= 3){
      const reqData = {
        reqObject: `phonenumbersearch.json`,
        reqQuery: `searchnumber=${value}`
      }
      const storeKey = 'searchbytollfree';
      this.props.getCoreHttp(reqData, storeKey);
    }
  }

  onChange = (e) => {
    const value = e.target.value;
    if(value===''){
      this.setInputData({key: value}, {areaCode: value});  
      return;
    }
    this.setInputData({key: value,  loaderMessage: 'Loading...'}, {areaCode: value});

    if(value.length === 3){
      const reqData = {
        reqObject: `phonenumbersearch.json`,
        reqQuery: `areacode=${value}`
      }
      const storeKey = 'searchbytollfree';
      this.props.getCoreHttp(reqData, storeKey);
    }
    else if(value.length === 6){
      const reqData = {
        reqObject: `phonenumbersearch.json`,
        reqQuery: `areacode=${value.substr(0,3)}&prefix=${value.substr(3,3)}`
      }
      const storeKey = 'searchbytollfree';
      this.props.getCoreHttp(reqData, storeKey);
    }
    
  }

  searchByCity = (val, status) => {
    if(!status){
      return;
    }
    this.setInputData({ loaderMessage: 'Loading...'}, {city: val, state: this.state.form.state});

    const reqData = {
      reqObject: `phonenumbersearch.json`,
      reqQuery: `state=${this.state.form.state}&city=${val}`
    }
    const storeKey = 'phonenumbersearch';
    this.props.getCoreHttp(reqData, storeKey);
  }

  searchByTollFree = () => {
    this.setInputData({key: '0',  loaderMessage: 'Loading...'}, {});
    const reqData = {
      reqObject: `phonenumbersearch.json`,
      reqQuery: `tollfree=true`
    }
    const storeKey = 'searchbytollfree';
    this.props.getCoreHttp(reqData, storeKey);
  }

  searchByState = (val, stateVal, status) => {
    if(!status){
      return;
    }

    this.setInputData({ loaderMessage: 'Loading...'}, {state: val});
    NProgress.start()
    let reqData = {
      reqObject: `phonenumbersearch.json`,
      reqQuery: `state=${val}`
    }
    let storeKey = 'phonenumbersearch';
    this.props.getCoreHttp(reqData, storeKey);

    reqData = {
      reqObject: `phonenumbersearch.json`,
      reqQuery: `state=${val}&listcities=true`
    }
    storeKey = `statecitysearch`;
    this.props.getCoreHttp(reqData, storeKey);
  }

  selectNumber = (number) => {
    if(this.selectNumberMap[number]) {
      return;
    }

    this.selectNumberMap[number] = true;
    this.setState({ loadLimit: this.state.loadLimit + 1, selectedNumbers: [...this.state.selectedNumbers, number], buttonDisabled: false });
  }

  removeNumber = (number) => {
    const filteredNumber = this.state.selectedNumbers.filter(item => item!== number);
    let buttonDisabled = false;
    if(filteredNumber.length===0){
      buttonDisabled = true;
    }

    this.selectNumberMap[number] = false;
    this.setState({ selectedNumbers: filteredNumber, buttonDisabled: buttonDisabled });
  }

  componentDidUpdate(prevProps) {
    if(this.props.reloadAvailableNumbers){
      this.props.reloadAvailableNumbersFlag();
      NProgress.done();
      this.setState({availableNumbers: this.props.availableNumbers, loaderMessage: this.props.availableNumbers.length === 0 ? 'no' : ''} );
    }
  }

  getErrorMessage = () => {
    return(
      <div>
        <h4>Oh No!</h4>
        <p>
          It looks like we don't have what you are looking for.
        </p>
      </div>
    )
  }

  loadMore = () => {
    this.setState({
      loadLimit: this.state.loadLimit + 10
    });
  }

  getList = () => {
    return(
      <ul style={styles.listLook} id="testId">
        <InfiniteScroll
          dataLength={this.state.loadLimit}
          next={this.loadMore}
          hasMore={this.state.availableNumbers.length > this.state.loadLimit}
          loader={<h4>{this.state.availableNumbers.length === 0 ? '' : 'Loading...'}</h4>}
          scrollableTarget="testId"
          endMessage={<h4>{this.state.availableNumbers.length === 0 ? '' : 'Loading finished'}</h4>}
        >
          {this.state.availableNumbers.slice(0, this.state.loadLimit).map(number =>
            !this.selectNumberMap[number] && <li key={number}>
              <Button onClick={() => this.selectNumber(number)}>
                <AddIcon />
              </Button>
              {number}
            </li>
          )}
        </InfiniteScroll>
      </ul>
    )
  }

  renderList = () => {
    return (
      <div style={styles.container}>
        <div style={styles.listStyle}>
          <h3>Search results</h3>
          {this.state.loaderMessage==='Loading...' 
            ? <Loading />
            : this.getList()
          }
        </div>
        <div style={styles.listStyle}>
          <h3>Selected Numbers</h3>
          <ul style={styles.listLook}>
            {this.state.selectedNumbers.map(number =>
              <li key={number}>
                <Button onClick={() => this.removeNumber(number)}>
                  <RemoveIcon />
                </Button>
                {number}
              </li>)}
          </ul>
        </div>
      </div>
    )
  }

  

  render() {
    const states = [
      "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware",
      "District of Columbia", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa",
      "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota",
      "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico",
      "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island",
      "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington",
      "West Virginia", "Wisconsin", "Wyoming"
    ];
    const stateMap = {
      "Alabama": "AL", "Alaska": "AK", "American Samoa": "AS", "Arizona": "AZ", "Arkansas": "AR",
      "California": "CA", "Colorado": "CO", "Connecticut": "CT", "Delaware": "DE", "District Of Columbia": "DC",
      "Federated States Of Micronesia": "FM", "Florida": "FL", "Georgia": "GA", "Guam": "GU", "Hawaii": "HI",
      "Idaho": "ID", "Illinois": "IL", "Indiana": "IN", "Iowa": "IA", "Kansas": "KS", "Kentucky": "KY",
      "Louisiana": "LA", "Maine": "ME", "Marshall Islands": "MH", "Maryland": "MD", "Massachusetts": "MA",
      "Michigan": "MI", "Minnesota": "MN", "Mississippi": "MS", "Missouri": "MO", "Montana": "MT",
      "Nebraska": "NE", "Nevada": "NV", "New Hampshire": "NH", "New Jersey": "NJ", "New Mexico": "NM",
      "New York": "NY", "North Carolina": "NC", "North Dakota": "ND", "Northern Mariana Islands": "MP",
      "Ohio": "OH", "Oklahoma": "OK", "Oregon": "OR", "Palau": "PW", "Pennsylvania": "PA", "Puerto Rico": "PR",
      "Rhode Island": "RI", "South Carolina": "SC", "South Dakota": "SD", "Tennessee": "TN", "Texas": "TX",
      "Utah": "UT", "Vermont": "VT", "Virgin Islands": "VI", "Virginia": "VA", "Washington": "WA",
      "West Virginia": "WV", "Wisconsin": "WI", "Wyoming": "WY"
    };

    const { classes } = this.props;

    return (
      <StandardSettingsPage>
        <h2>Buy New Phone Numbers</h2>
        <div style={styles.parentContainer}>
          <div style={styles.container}>
            <div style={styles.stateitem}>
              <AutoComplete
                persist={true}
                key={this.state.key}
                placeholder='Search By State'
                title='Search By State'
                suggestions={states}
                onNewRequest={ value => this.searchByState( stateMap[value], value , states.includes(value))}
              />
              <div style={{ display: this.props.cityInputDisplayStyle }}>
                <AutoComplete
                  persist={true}
                  key={this.state.key}
                  title="Filter By City"
                  placeholder="Filter By City"
                  suggestions={this.props.stateCities}
                  onNewRequest={ value => this.searchByCity(value, this.props.stateCities.includes(value))}
                />
              </div>
              <Button classes={{ root: classes.button }} size='large' variant="contained" color='primary' onClick={this.searchByTollFree}>
                Toll free
              </Button>
            </div>
            <div style={styles.item}>
              <TextField
                placeholder="Search by Area Code"
                helperText={null}
                label="Search Area Code"
                onChange={this.onChange}
                value={this.state.form.areaCode}
                InputLabelProps={{shrink: true}}
              />
            </div>
            <div style={styles.item}>
              <TextField
                label="Search by Number Pattern"
                placeholder="Number Pattern"
                onChange={this.searchByPattern}
                value={this.state.form.numberPattern}
                InputLabelProps={{shrink: true}}
              />
            </div>
          </div>
          {this.state.loaderMessage === 'Loading...' || this.state.loaderMessage === ''
            ? this.renderList()
            : this.getErrorMessage()
          }

        </div>
        <div style={{
          position: 'fixed',
          align: 'center',
          top: '93vh',
          zIndex: '1',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '55px',
          padding: '5px 0',
          background: 'white',
         }}>
          <SubmitButton
            label="submit"
            disabled={this.state.buttonDisabled}
            onClick= {this.purchaseNumbers}
          />
          <CancelButton
            label="Cancel"
            onClick={() => app.navigate(`phonenumbers`, {trigger: true})}
          />
        </div>
      </StandardSettingsPage>
    )
  }
}


const styles = {
  parentContainer:{
    display: 'inline-grid'
  },
  container:{
    display: 'flex',
    margin: '30px',
    justifyContent: 'space-evenly'
  },
  item:{
    display: 'inline-grid',
    margin: '10px'
  },
  stateitem:{
    display: 'inline-grid',
    margin: '10px',
    marginTop: '-10px'
  },
  listStyle:{
    display: 'inline-grid',
    margin: '10px',
    width: '50%',
  },
  listLook: {
    listStyle: 'none',
    backgroundColor: 'white',
    height: '300px',
    overflowY: 'scroll'
  },
  menuStyle:{
    maxHeight: '150px',
    overflowY:'scroll', 
    width: 'fixed'
  }
  
};

const matStyle = () => ({
  button: {
    fontSize: '90%',
  },
});


const bindActions = (dispatch) => ({
  deleteCoreHttp: (reqData, storeKey) => dispatch(deleteCoreHttp(reqData, storeKey)),
  getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
  postCoreHttp: (reqData, storeKey) => dispatch(postCoreHttp(reqData, storeKey)),
  putCoreHttp: (reqData, storeKey) => dispatch(putCoreHttp(reqData, storeKey)),
  reloadAvailableNumbersFlag: () => dispatch(reloadAvailableNumbersFlag())
});

const mapStateToProps = state => ({
  availableNumbers: state.phoneNumbers.availableNumbers,
  stateCities: state.phoneNumbers.stateCities,
  cityInputDisplayStyle: state.phoneNumbers.cityInputDisplayStyle,
  reloadAvailableNumbers: state.phoneNumbers.reloadAvailableNumbers
});

export default connect(mapStateToProps, bindActions)(withStyles(matStyle)(BuyNewNumber));
