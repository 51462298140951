import React, { Component } from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

import ErrorCard from 'presentational/ErrorCard';
import Loading from 'presentational/Loading';
import NewCardPlaceholder from 'presentational/NewCardPlaceholder';
import StandardSettingsPage from 'presentational/StandardSettingsPage';

import QuestionCard from './questionCard';
import NewAgentQuestionForm from './newAgentQuestionForm'

import { getCoreHttp } from 'actions/httpRequest';

const styles = () => ({
    button: {
        fontSize: '100%',
    },
    contentStyle: {
        height: '500px'
    },
    contentStyleSmall: {
        height: '95%'
    },
  });

class AgentQuestions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            form: null,
            submitError: null,
            showingModal: false,
            mediaQuery: false,
            newAgentQuestionForm: {
                question_description: '',
                question_name: '',
                question_number: '',
                question_text: '',
                question_type: 'numerical',
                rating_min: '1',
                rating_max: '5',
            },
            formErrors: {
                question_name: '',
                question_text: '',
                rating_min: '',
                rating_max: '',
            },
        };
    }

    componentDidMount() {

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));

        // If we don't have data, lets return
        if (!this.props.data) {
            return;
        }

        this.setState({ form: this.props.data });
    }

    componentWillReceiveProps(nextProps) {

        // If we don't have data, lets return
        if (!nextProps.data) {
            return;
        }

        this.setState({ form: nextProps.data });
    }

    async submitForm(submittedForm) {

        const form = submittedForm || this.state.form;

        console.log('SUBMITTING FORM: ', form);

        // If no error, lets set the data
        // on the form and set it to state without errors
        this.setState({
            form,
            errors: {},
        });

        const submitError = await this.props.submitForm(form);

        if (submitError) {
            this.setState({ submitError });
        } else {
            this.setState({ submitError: null });
        }
    }

    validateForm() {

        const { newAgentQuestionForm } = this.state;

        let formErrors = {};

        if (newAgentQuestionForm['rating_min'] > newAgentQuestionForm['rating_max']) {
            formErrors['rating_max'] = 'Maximum rating must be higher than minimum rating';
        }

        return formErrors;
    }

    updateNewAgentQuestionForm(field, value) {

        const { newAgentQuestionForm } = this.state;

        newAgentQuestionForm[field] = value;

        this.setState({ newAgentQuestionForm });
    }

    removeQuestion(id) {

        console.log('Removing question for agent');

        const foundIndex = this.state.form.questions.app.findIndex(
            (obj) => obj.question_number == id,
        );

        this.state.form.questions.app.splice(foundIndex, 1);
        this.submitForm(this.state.form);
    }

    renderQuestionCard(question, index) {

        return (
            <QuestionCard
              form={this.state.form}
              context="app"
              data={question}
              key={index}
              id={question.question_number}
              submitForm={(form) => this.submitForm(form)}
              removeQuestion={(question_number) => this.removeQuestion(question_number)}
            />
        );
    }

    handleClose(submit) {

        if (submit) {

            const { newAgentQuestionForm, form } = this.state;

            this.setState({ formErrors: {} });

            const formErrors = this.validateForm();

            if (Object.keys(formErrors).some(err => formErrors[err])) {

                this.setState({ formErrors })
                return;
            }

            const questionNumbers = [];

            form.questions['app'].map(question => questionNumbers.push(parseInt(question.question_number)));

            if(questionNumbers.length > 0){
                newAgentQuestionForm.question_number = Math.max(...questionNumbers) + 1;
            } else {
                newAgentQuestionForm.question_number = 1;
            }

            this.setState({ formErrors: { name: '' } });

            if (!this.props.data.name) {

                this.setState({ formErrors: { name: 'No name for survey, please try again.' } });
                return;
            }
            form.questions['app'].push(newAgentQuestionForm);
            this.setState({ form });
            this.submitForm(form);
        }

        // Reset state
        this.setState({
            showingModal: false,
            newAgentQuestionForm: {
                question_description: '',
                question_name: '',
                question_number: '',
                question_text: '',
                question_type: 'numerical',
                rating_min: '1',
                rating_max: '5',
            },
        });
    }

    render() {

        if (!this.props.data || !this.props.data.questions || !this.props.recordings || !this.state.form) {
            return (
              <StandardSettingsPage>
                <Loading />
              </StandardSettingsPage>
            );
        }


        const actions = [
            
        ];

        const { form } = this.state;
        const minWidth = this.state.mediaQuery.matches;
        const { classes } = this.props;

        return (
          <StandardSettingsPage>

            <NewCardPlaceholder
              title="Add new question for agent"
              onClick={() => this.setState({ showingModal: true })}
            />

            {this.state.submitError ?
              <ErrorCard data={this.state.submitError} />
              : null
            }

            {form.questions.app.map((question, i) => {
                return this.renderQuestionCard(question, i);
            })}

            <Dialog
              actions={actions}
              open={this.state.showingModal}
              onClose={() => this.handleClose(false)}
              fullWidth={true}
            >
                <DialogTitle>Add new question for agent</DialogTitle>
                <DialogContent>
                    <NewAgentQuestionForm
                        // usedSkills={form}
                        form={this.state.newAgentQuestionForm}
                        formErrors={this.state.formErrors}
                        updateForm={(field, value) => this.updateNewAgentQuestionForm(field, value)}
                    />
              </DialogContent>
              <DialogActions>
                <Button
                    classes={{ root: classes.button }}
                    color='primary'
                    onClick={() => this.handleClose(false)}
                    >Cancel</Button>
                <Button
                    classes={{ root: classes.button }}
                    color='primary'
                    onClick={() => this.handleClose(true)}
                    >Submit</Button>
              </DialogActions>
            </Dialog>
          </StandardSettingsPage>
        );
    }
}

const bindActions = (dispatch) => ({
    getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
});

const mapStateToProps = (state) => ({
    recordings: state.recordings.data,
});

export default connect(mapStateToProps, bindActions)(withStyles(styles)(AgentQuestions));
