import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core';

import { sendSmsMessage } from 'actions/sms';

const matStyle = () => ({
    textField: {
        flex: 1,
        marginLeft: 20,
        marginRight: 4,
    },
});

const style = {
    wrapper: {
        borderTop: 'solid',
        borderTopColor: '#DFDFDF',
        borderTopWidth: 1,
        display: 'flex',
        marginBottom: '40px'
    },
    charCountWrapper: {
        alignItems: 'flex-end',
        display: 'flex',
        marginLeft: 4,
        marginRight: 25,
    },
    sendIcon: {
        height: 30,
        marginRight: 15,
        marginTop: 5,
        verticalAlign: 'center',
        width: 30,
    },
};

// Returns a text field and send icon
class ConversationInput extends Component {

    constructor(props) {
        super(props);

        this.state = {
            messageInput: '',
        };
    }

    componentWillReceiveProps(nextProps) {

        // If a new conversation is selected, let's clear out the message
        // only if we do not specify keeping the current input
        if (nextProps.selectedConverstation &&
            nextProps.selectedConversation != this.props.selectedConversation &&
            nextProps.selectedConversation.keepCurrentInput) {
            this.setState({ messageInput: '' });
        }
    }

    onChange(messageInput) {
        this.setState({ messageInput });
    }

    handleKeyUp(e) {

        // If the user presses enter without shift key, we want to send the message
        if (e.nativeEvent.keyCode == 13 && !e.nativeEvent.shiftKey) {

            if (this.state.messageInput.length == 1) {
                this.setState({ messageInput: '' });
                return;
            }

            this.sendMessage();
        }
    }

    sendMessage() {

        const { messageInput } = this.state;

        console.log('SENDING', messageInput);
        const { conversation } = this.props.selectedConversation;
        const newMessage = {
            from: conversation.number,
            to: conversation.participant,
            message: messageInput,
            messageId: conversation.messageId,
            timestamp: moment().format(),
        };
        
        this.props.sendSmsMessage(newMessage);

        this.setState({ messageInput: '' });
    }

    render() {

        const { selectedConversation, classes } = this.props;

        return (
          <div style={!selectedConversation ? { opacity: 0.3 } : null}>
            <div style={style.wrapper}>

              <TextField
                disabled={!selectedConversation}
                InputLabelProps={{shrink: true}}
                placeholder="Write a message..."
                maxLength="160"
                onChange={(event, text) => this.onChange(event.target.value)}
                onKeyUp={(event) => this.handleKeyUp(event)}
                ref={(c) => { this.textField = c; }}
                rows={1}
                rowsMax={4}
                classes={{ root: classes.textField }}
                value={this.state.messageInput}
              />

              <div style={style.charCountWrapper}>
                <p
                  style={{
                      marginBottom: 6,
                      color: this.state.messageInput.length == 160 ? 'red' : 'black',
                      fontSize: this.state.messageInput.length == 160 ? 12 : 10,
                  }}
                >{this.state.messageInput.length} / 160</p>
              </div>

              <div style={style.sendIcon}>
                <Button
                  disabled={!selectedConversation}
                  fullWidth
                  onClick={() => this.sendMessage()}
                  color='primary'
                >{<SendIcon />}</Button>
              </div>

            </div>
          </div>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedConversation: state.sms ? state.sms.selectedConversation : null,
    draftedConversation: state.sms ? state.sms.draftedConversation : null,
});

const bindActions = (dispatch) => ({
    sendSmsMessage: (message) => dispatch(sendSmsMessage(message)),
});

export default connect(mapStateToProps, bindActions)(withStyles(matStyle)(ConversationInput));
