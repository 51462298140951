import React, { Component } from 'react';

import CardHeader from '@material-ui/core/CardHeader';

import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import OpenIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import ReactTable from 'react-table'
import "react-table/react-table.css";

import AudioPlayer from 'presentational/formControls/AudioPlayer';
import Loading from 'presentational/Loading';
import { withStyles } from '@material-ui/styles';

const style = () => ({
    card: {
        width: '80%',
        minWidth: '400px',
        minHeight: '400px'
    },
    bottomGrid: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    noCalls: {
        textAlign: 'center',
        fontSize: 16,
        color: '#737373',
        fontWeight: 'bold',
        padding: 50,
    },
    totalRecords: {
        top: '11px',
        right: '3px',
        height: '30px',
    },
    wrapper: {
        minHeight: '400px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        width: '100%',
        bottom: 0,
    },
    button: {
        fontSize: '70%',
    },
});

class ReportCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showingModal: false,
            mediaQuery: false,
            formErrors: {
                application: '',
                value: '',
            },
            filters: {},
            showingRecordingModal: false,
            sortedData: {},
        };
    }

    handleClose(i) {
        this.setState({ [`showingRecordingModal${i}`]: false });
    }

    buildRecordingModal(tenant, data, classes) {
        let allRecordings = [`/api/v1/userportal/${tenant}/${data.original.extension}/cdrrecording/${data.value}`];

        //Let's first loop through all legs of this call and capture all unique recordings
        if (data.original.ladders && data.original.ladders.length) {
            data.original.ladders.forEach(leg => {
                if (leg.recording && leg.recording !== data.value) {
                    const recordingURL = `/api/v1/userportal/${tenant}/${data.original.extension}/cdrrecording/${leg.recording}`;
                    if (!allRecordings.includes(recordingURL)) {
                        allRecordings.push(recordingURL);
                    }
                }
            });
        }

        return (
            <div>
                <IconButton
                    classes={{ root: classes.button }}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        this.setState({ [`showingRecordingModal${data.value}`]: true });
                        this.props.fetchSpecificCallRecord(data.original.linkedid, data.original.extension);
                    }}>
                    <OpenIcon />
                </IconButton>
                <Dialog
                    open={this.state[`showingRecordingModal${data.value}`] || false}
                    onClose={() => this.handleClose(data.value)}
                    fullWidth={true}
                >
                    <DialogTitle>All recordings for this call:</DialogTitle>
                    <DialogContent>
                        <p style={{fontSize: '15px'}}>The primary recording for this call is highlighted in blue</p>
                        {allRecordings.map((recording, index) => {
                            if(index == 0){
                                const regex = /([^\/]+$)/;
                                const matches = recording.match(regex);
                                return (
                                    <div style={{width:'50%', background:'rgb(207, 226, 242)'}} key={index}>
                                        <AudioPlayer
                                            callHistory={true}
                                            key={index}
                                            background='green'
                                            recordingUrl={recording}
                                            recordingName={matches[0]}
                                            showTime
                                            showDownload />
                                    </div>
                                )
                            }
                            return (
                                <div style={{width:'50%'}} key={index}>
                                    <AudioPlayer
                                        callHistory={true}
                                        key={index}
                                        recordingUrl={recording}
                                        showTime
                                        showDownload />
                                </div>
                            )
                        })}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            classes={{ root: classes.button }}
                            color='primary'
                            onClick={() => this.handleClose(data.value)}
                        >Close</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }

    render() {
        const token = app.token.attributes;
        const columns = [{
            Header: 'Extension',
            accessor: 'extension',
            Cell: props => <p style={style.column}>{props.value}</p>
        }, {
            Header: 'Call Date / Time',
            accessor: 'calldate',
            Cell: props => (
                <p style={style.column}>
                    {moment(props.value).format("MMM Do, YYYY hh:mm:ss A")}
                </p>
            )
        }, {
            Header: 'Caller ID Name',
            accessor: 'calleridname',
            Cell: props => <p style={style.column}>{props.value}</p>
        }, {
            Header: 'Caller ID Number',
            accessor: 'calleridnumber',
            Cell: props => <p style={style.column}>{props.value}</p>
        }, {
            Header: 'Number Dialed',
            accessor: 'dialednumber',
            Cell: props => <p style={style.column}>{props.value}</p>
        }, {
            Header: 'Dialed From',
            accessor: 'dialedfrom',
            Cell: props => <p style={style.column}>{props.value}</p>
        }, {
            Header: 'Direction',
            accessor: 'direction',
            Cell: props => <p style={style.column}>{props.value}</p>
        }, {
            Header: 'Call Type',
            accessor: 'calltype',
            Cell: props => <p style={style.column}>{props.value}</p>
        }, {
            Header: 'Duration',
            accessor: 'billsec',
            Cell: props => {
                const duration = moment.utc(moment.duration(props.value, 'seconds').asMilliseconds()).format('HH:mm:ss')
                return (<p style={style.column}>{duration}</p>)
            }
        }, {
            Header: 'Disposition',
            accessor: 'disposition',
            Cell: props => <p style={style.column}>{props.value}</p>
        }, {
            Header: 'Recording',
            accessor: 'recording',
            Cell: props => {
                if (props.value) {
                    return this.buildRecordingModal(token.tenant, props, this.props.classes)
                } else {
                    return "Not Found"
                }
            }
        }]

        let { currentlyLoading, data, classes } = this.props;

        if (currentlyLoading) {

            return <Loading />;
        }

        if (!data || data == null) {

            return <div className={classes.noCalls}>No calls found</div>
        }

        let timeZone = ''
        if (token.timezone && token.timezone.length) {
            timeZone = `&timezone=${token.timezone}`
        }
        const downloadReport = this.props.reqQuery ?
            `https://reports.fluentcloud.com/v2/reports/${token.tenant}/${token.extension}/calldetailrecords?${this.props.reqQuery}&page=1&exportcsv=true${timeZone}` :
            `https://reports.fluentcloud.com/v2/reports/${token.tenant}/${token.extension}/calldetailrecords?&exportcsv=true${timeZone}`;

        return (
            <div className={classes.wrapper}>
                <ReactTable
                    data={this.props.data}
                    columns={columns}
                    defaultPageSize={10}
                    styles={style.column}
                    className="-striped -highlight"
                    pageSizeOptions={[10, 25, 50]}
                />
                <div className={classes.bottomGrid}>
                    <CardHeader action={
                            <Button
                                classes={{ root: classes.button }}
                                disabled={this.props.data.length < 1}
                                href={downloadReport}
                            >Export Csv</Button>
                    } />

                    <Chip classes={{ root: classes.totalRecords }}
                        component='div'
                        label={<div>Total Calls: {this.props.data.length}</div>}
                    />
                </div>
            </div>
        );
    }
}

export default withStyles(style)(ReportCard);
