import React, { Component } from 'react';
import { connect } from 'react-redux';
import TenantChangesFilter from '../tenantChangesReport/tenantChangesFilter';
import TenantChangesGrid from './tenantChangesGrid'

class TenantChangesReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dateRangeBegin: moment().format("YYYY-MM-DD"),
            dateRangeEnd: moment().format("YYYY-MM-DD"),
            changeType: "All",
            changeTarget: "All",
            subscription_level: "All",
            tenant:"All",
        }
    }


    componentDidMount(){
    }

    getFilteredData(){
        //first, let's start with the raw data from our backend
        const rawData = this.props.tenantChanges.data;

        // then, let's use state from tenantChangesFilter to filter our results
        if (!rawData || rawData.length < 1){
            return [];
        }

        let filteredData = rawData; 
        // Type (added/deleted/etc)
        if (this.state.changeType !== 'All') {
            filteredData = filteredData.filter(item => item.target.includes(this.state.changeType))
        }

        // Target (e911, phone number, etc)
        if (this.state.changeTarget !== 'All') {
            filteredData = filteredData.filter(item => item.target.includes(this.state.changeTarget))
        }

        // Subscription Level
        if (this.state.subscription_level !== 'All') {
            filteredData = filteredData.filter(item => item.subscription_level  == this.state.subscription_level)
        }

        if (this.state.tenant !== 'All'){
            filteredData = filteredData.filter(item => item.tenant  == this.state.tenant)
        }

        return filteredData;

    }

    getFilterOptions(){
        let filterOptions= {};
        let typeOfChange = ["All"];
        let targets = ["All"];
        let subscriptionLevels = ["All"];
        let tenants = [];
        if (this.props.tenantChanges && this.props.tenantChanges.data && this.props.tenantChanges.data.length > 0){
            this.props.tenantChanges.data.forEach(record => {
                if (!typeOfChange.includes(record.target.split(".")[1])){
                    typeOfChange.push(record.target.split(".")[1]);
                }

                if (!targets.includes(record.target.split(".")[0])){
                    targets.push(record.target.split(".")[0]);
                }

                if (!subscriptionLevels.includes(record.subscription_level)){
                    subscriptionLevels.push(record.subscription_level);
                }

                if (!tenants.includes(record.tenant)){
                    tenants.push(record.tenant);
                }

            })
        }

        tenants.sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
        });

        tenants.unshift("All");

        filterOptions["typeOfChange"] = typeOfChange;
        filterOptions["targets"] = targets;
        filterOptions["subscriptionLevels"] = subscriptionLevels;
        filterOptions["tenants"] =  tenants;

        return filterOptions;

    }

    render() {

        const reportData = this.getFilteredData();
        const filterOptions = this.getFilterOptions();

        return (
            <div>
                <TenantChangesFilter filterState={this.state ? {...this.state} : {}} changeFilter={(val)=> this.setState(val)} data={reportData} filterOptions={filterOptions} />
                <TenantChangesGrid data={reportData} />
            </div>
        );
    }
}

const bindActions = dispatch => ({
});
const mapStateToProps = state => ({
    tenantChanges:state.tenantChangesReport.data
});

export default connect(mapStateToProps, bindActions)(TenantChangesReport);