import React, { Component } from 'react';
import { connect } from 'react-redux';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';

import MasterView from 'presentational/MasterView';
import ActionCard from 'presentational/ActionCard';
import DetailContainer from 'presentational/DetailContainer';
import StandardDetailsPage from 'presentational/StandardDetailsPage';
import LeftBar from 'presentational/LeftBar';
import NewCallGroup from './newCallGroup';
import CallGroupsHelp from './help';
import CallGroupSettings from './settings';
import CallGroupFailover from './failover';
import CallGroupMembers from './members';
import CallGroupAuditHistory from './auditHistory';
import { selectCallGroup } from 'actions/callGroups';
import Loading from 'presentational/Loading';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import SaveChangesModal from 'presentational/SaveChangesModal';
import DeleteModal from 'presentational/DeleteModal';

import {
  getCoreHttp,
  deleteCoreHttp,
  patchCoreHttp,
  postCoreHttp,
  putCoreHttp,
  httpSuccess,
} from 'actions/httpRequest';

import {
  errorNotification,
  successNotification,
} from 'actions/notifications';

const styles = () => ({
  button: {
    fontSize: '100%',
  },
  menuItem: {
    fontSize: '100%',
  },
});

class CallGroups extends Component {

  constructor(props) {
    super(props)
    this.state = {
      callGroups: [],
      selectedCallGroup: null,
      leftBarData: [],
      route: null,
      pendingCallGroups: true,
      showingDeleteConfirmModal: false,
      showingCopyCallGroupModal: false,
      showingNoFailoverModal: false,
      inUse: false,
      inUseArray: [],
      showingSaveChangesModal: false,
      clickedSurroundingElements: false,
      clickedInsideElements: false,
      newChanges: false,
      closeMenu: false,
      nextSelectedCallGroup: null,
      childFormData: {},
      overrideNoFailoverModal: false,
      navAway: false,
      navAwayAttempts: 0,
      openDropdownElem: '',
      newCallGroupName: '',
      form:{
        callrecording: "0",
        config: {},
        confirm_call: "0",
        confirm_external_member: "0",
        description: "",
        distinctivering: "",
        duration: "30",
        extension: "",
        failover_application: "none",
        failover_argument: "",
        failover_markunanswered: "",
        failover_value: "",
        friendlyname: "",
        mark_answered_elsewhere: "0",
        musiconhold: "",
        musiconhold_caller: "",
        name: null,
        pass_did_to_external: "0",
        prependedcid: "",
        ringinuse: "",
        strategy: "ringall",
        unselectedUsers: [],
        usersList: [],
        usersSelected: [],
        value: "",
      },
    };

    this.saveChange = this.saveChange.bind(this);
    this.discardChange = this.discardChange.bind(this);
  }

  componentDidMount() {

    // If we don't have extensions, lets get that meow
    if (!this.props.extensions || !this.props.extensions) {

      this.fetchExtensions();
    }

    this.setState({
      route: null,
      selectedCallGroup: null,
      inUseArray: [],
      inUse: false
    });

    this.props.selectCallGroup('');
    this.clickedElement();

    if (!this.props.callGroups) {

      return this.fetchCallGroups();
    }

    this.setState({ pendingCallGroups: false });

    return this.prepareDataForLeftBar(this.props.callGroups);
  }

  componentWillReceiveProps(nextProps) {

    // If the new call group object is different (or if we
    // didn't have one before) lets get the data ready for leftbar
    if (nextProps.callGroups && this.props.callGroups !== nextProps.callGroups) {
      this.setState({ pendingCallGroups: false });
      this.prepareDataForLeftBar(nextProps.callGroups);
    }

    if (nextProps.selectedCallGroup && nextProps.callGroups) {

      const selectedCallGroupName = nextProps.callGroups.find(
        (obj) => obj && obj.name == nextProps.selectedCallGroup,
      );

      let inUseArray = [];
      if(selectedCallGroupName && selectedCallGroupName.usage){
          Object.keys(selectedCallGroupName.usage).map(key=>{
            selectedCallGroupName.usage[key].map(applicationObj =>{
              Object.keys(applicationObj).map(application =>{
                let applicationRoute = `#${key}/${applicationObj[application]}`
                inUseArray.push(applicationRoute);
              })
            })
          })
      }

      this.setState({inUseArray});

      // If we have a new call group, handle this here
      if (nextProps.selectedCallGroup == 'New Call Group') {
        this.setState({ selectedCallGroup: { name: 'New Call Group' } });
        return;
      }

      if (!selectedCallGroupName) {
        //it hacky but it worky

        //if we have just made a new call group we do not have
        //it's data yet so since it has not been found in
        //our call groups, we want to do a fetch for its data here
        setTimeout(() => {
          try{
            this.getCallGroupDetails(nextProps.selectedCallGroup);
          }
          catch (error){
            console.error('No call group found by the id of: ', nextProps.selectedCallGroup);
          }
        }, 500);
        return;
      }

      // If the id has changed, lets reset the route here
      if (nextProps.selectedCallGroup !== this.props.selectedCallGroup || nextProps.callGroups.length !== this.props.callGroups.length) {
        this.setState({
          selectedCallGroup: selectedCallGroupName.name,
          route: '',
        });
      } else {
        this.setState({ selectedCallGroup: selectedCallGroupName.name });
      }
    }
  }

  fetchCallGroups() {

    const reqData = {
      reqAction: 'callgroup',
    };

    this.props.getCoreHttp(reqData);
  }

  fetchExtensions() {

    console.log('No extensions found, fetching extensions now');

    const reqData = {
      reqAction: 'extensions',
    };

    this.props.getCoreHttp(reqData, 'extensions');
  }

  // prepareDataForLeftBar maps through all agents received
  // via http and formats data objects to be consumed by the leftBar
  prepareDataForLeftBar(data) {

    // return on no data
    if (!data) {
      return console.log('No call groups returned');
    }

    // array we will push our new object into
    const leftBarData = [];
    data.map((item) => {

      // Set the titles here
      const title = item.friendlyname || item.name;
      const subtitle = item.description || "";
      const id = item.name;

      // push the data into the leftBar array
      return leftBarData.push({ id, title, subtitle });
    });

    // finally, lets set the leftBarData to state to update leftbar
    return this.setState({ leftBarData });
  }

  changeCallGroup(callGroup){
    this.setState({overrideNoFailoverModal: false});
    this.setState({ route: null, });
    this.props.selectCallGroup(callGroup);
    this.getCallGroupDetails(callGroup);
  }

  leftBarSelect(callGroup) {
    this.setState({
      navAwayAttempts: 0,
      nextSelectedCallGroup: callGroup
    }, () => {
      if(this.state.selectedCallGroup){
        const currentCallGroup = this.props.callGroups.find(
          (obj) => obj && obj.name == this.state.selectedCallGroup,
        );

        //if a failover is not set for the call group lets require it with a modal
        if ((!this.state.overrideNoFailoverModal && currentCallGroup && (!currentCallGroup.failover_application || currentCallGroup.failover_application == "none")
            && this.state.nextSelectedCallGroup !== callGroup) && (this.state.selectedCallGroup !== callGroup) || (currentCallGroup && this.state.form.failover_application == "none" && currentCallGroup.failover_application == "none" && this.state.selectedCallGroup !== callGroup)){
          this.showNoFailoverModal();
          return;
        }
      }
      this.changeCallGroup(callGroup);
    });
  }

  getCallGroupDetails(callGroup) {
    if(!callGroup){
      callGroup = this.state.selectedCallGroup;
    }
    const reqData = {
      reqAction: 'callgroup',
      reqObject: callGroup,
    };

    const storeKey = 'callGroup';

    this.props.getCoreHttp(reqData, storeKey);
  }

  async submitSettingsForm(data) {
    if (!data) {
      return
    }

    const reqData = {
      reqAction: 'callgroup',
      reqObject: data.name,
      reqBody: data,
    };

    const storeKey = 'callGroup';

    this.props.putCoreHttp(reqData, storeKey);

    if (data.failover_application == "none") {
      errorNotification({title: `Successfully Updated Call Group ${data.friendlyname}`, message: `However, you need to assign a Failover in order for this call group to function properly!`});
    } else {
      successNotification({ title: 'Call Group Updated!', message: `Successfully updated Call Group ${data.friendlyname}` });

    }

    this.setState({
      clickedInsideElements: false,
      clickedSurroundingElements: false,
      newChanges: false,
    })

  }

  createNewCallGroup(newCallGroupName, callGroupDescription, copiedCallGroupObj) {

    let reqBody = this.state.form;
    reqBody.friendlyname = newCallGroupName;
    reqBody.description = callGroupDescription;
    const reqData = {
      reqAction: 'callgroup',
      reqBody: copiedCallGroupObj ? copiedCallGroupObj : reqBody,
    };

    const storeKey = 'callGroup';

    this.props.postCoreHttp(reqData, storeKey);

    // Lets trigger a notification
    successNotification({
      title: 'Success!',
      message: `Created call group ${newCallGroupName}`,
    });
  }

async handleDeleteCallGroup() {

  if (!this.state.selectedCallGroup) {
    console.log('No call group selected');
    return;
  }

    const { selectedCallGroup } = this.state;

    const reqData = {
      reqAction: 'callgroup',
      reqObject: selectedCallGroup,
    };

    const storeKey = 'callGroup';

    this.props.deleteCoreHttp(reqData, storeKey);

    // Lets set state to clear the route and selected dynamic agent
    this.setState({
      route: '',
      selectedCallGroup: null,
    });

    this.props.selectCallGroup('');

    // Lets trigger a notification
    successNotification({
      title: 'Success!',
      message: `Deleted ${selectedCallGroup}`,
    });
  }

  goToHelp() {
    const win = window.open('http://help.fluentcloud.com/support/solutions', '_blank');
    win.focus();
  }

  handleAddNewClick() {
    this.props.selectCallGroup('New Call Group');
    this.navigate('New');
  }

  handleDeleteConfirmClose(shouldDelete){
    this.setState({ showingDeleteConfirmModal: false, newCallGroupName: '' }, () => {shouldDelete && this.handleDeleteCallGroup()});
  }

  handleCopyConfirmClose(shouldCopy) {
    if (shouldCopy) {
        const targetCallGroupObj = this.props.callGroups.find(callGroupObj => callGroupObj && callGroupObj.name == this.props.selectedCallGroup);
        const copiedCallGroupObj = Object.assign({}, targetCallGroupObj);
        copiedCallGroupObj.name = this.state.newCallGroupName;
        copiedCallGroupObj.friendlyname = this.state.newCallGroupName;
        copiedCallGroupObj.index = null;

        this.createNewCallGroup(this.state.newCallGroupName, copiedCallGroupObj.description, copiedCallGroupObj);

        setTimeout(() => this.props.selectCallGroup(this.state.newCallGroupName.replace(/[^A-Za-z0-9_]/g, '')), 100);
    }
    this.setState({ showingCopyCallGroupModal: false });
  }

  handleTextFieldChange = (e) => {
    if (e.target.name == 'newCallGroupName') {
        this.setState({ newCallGroupName: e.target.value });
    } else if (e.target.name == 'newCallGroupDesc') {
      this.setState({ newCallGroupDesc: e.target.value });
    }
  }

  handleCancel(){
    this.leftBarSelect(this.state.selectedCallGroup);
  }

  handleInUse(){
    if(this.state.inUseArray.length > 0){
      this.setState({inUse: true})
    }else{
      this.setState({inUse: false})
    }
  }

  handleNoFailoverConfirmClose(shouldClose) {
    if(!shouldClose && this.state.selectedCallGroup != this.state.nextSelectedCallGroup) {
      this.changeCallGroup(this.state.nextSelectedCallGroup);
    }
    
    this.setState({ showingNoFailoverModal: false });

    if(this.state.navAway) {
      if (this.state.openDropdownElem){document.getElementById(this.state.openDropdownElem).click()};
      this.setState({ showingNoFailoverModal: false });
    }
    this.setState({navAway: false});
  }

  showNoFailoverModal(){
    //If we're trying to navigate away, let's just pop this once
    if (this.state.navAwayAttempts === 0){
      this.setState({ 
        showingNoFailoverModal: true,
        navAwayAttempts: this.state.navAwayAttempts + 1,
      });
    }
  }

  callGroupRoutes() {

    return ([
      <ActionCard
        key="Settings"
        title="Settings"
        subtitle="Manage settings for this call group"
        action={() => this.navigate('Settings')}
      />,
      <ActionCard
        key="Members"
        title="Members"
        subtitle="Update members in this call group"
        action={() => this.navigate('Members')}
      />,
      <ActionCard
        key="Failover"
        title="Failover"
        subtitle="Update failover for this call group"
        action={() => this.navigate('Failover')}
      />,
      <ActionCard
        key="History"
        title="History"
        subtitle="See who made changes to this call group"
        action={() => this.navigate('History')}
      />,
    ]);
  }

  navigate(route) {

    this.setState({ route });
  }
  renderInUse() {
    if(this.state.inUse) {
      const inUseArray = this.state.inUseArray;
      return (
        <div>This Call Group is currently being used by:
          <ul>
            {inUseArray.map(element => {
              var parsedElement = element.substring(element.indexOf("/")+1);
              var application = element.slice(0, element.indexOf("/"));
              var uppercaseApplication = application.charAt(1).toUpperCase() + application.slice(2)
              if(application === '#featurecodes' || application === '#notificationworkflows'|| application ==="#autoattendant"){
                return (
                  <li key={Date.now()}>
                    <a href={application}>{uppercaseApplication} - {parsedElement}</a>
                  </li>
                );
              } else {
                return (
                  <li key={Date.now()}>
                    <a href={element}>{uppercaseApplication} - {parsedElement}</a>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      );
    }else{
      return;
  }
}

  renderContent() {

    const {selectedCallGroup, callGroups} = this.props;
    if (!selectedCallGroup || !callGroups) {
      return <CallGroupsHelp />;
    }
    
    const { route } = this.state;
    const index = callGroups.findIndex((obj) => obj && obj.name == selectedCallGroup);
    const callGroup = callGroups[index];

    if(route !== "New"){
      if(callGroup && !callGroup.config){
        return (
          <StandardSettingsPage>
              <Loading />
          </StandardSettingsPage>
        );
      }
    }

    switch (route) {

      case 'New':
        return (
          <NewCallGroup
            selectCallGroup={(data) => this.props.selectCallGroup(data)}
            callGroups={this.props.callGroups}
            submitForm={(name, description) => this.createNewCallGroup(name, description)}
          />
        );

      case 'Settings':

        return (
          <CallGroupSettings
            submitForm={(data) => this.submitSettingsForm(data)}
            data={callGroup}
            cancel={() => this.handleCancel()}
            clickedNode={() => this.clickedNode()}
            madeChanges={(answer, data) => this.madeChanges(answer, data)}
          />
        );

      case 'Members':

        return (
          <CallGroupMembers
            submitForm={(data) => this.submitSettingsForm(data)}
            data={callGroup}
            cancel={() => this.handleCancel()}
            clickedNode={() => this.clickedNode()}
            madeChanges={(answer, data) => this.madeChanges(answer, data)}
          />
        );

      case 'Failover':

        return (
          <CallGroupFailover
            submitForm={(data) => this.submitSettingsForm(data)}
            data={callGroup}
            cancel={() => this.handleCancel()}
            clickedNode={() => this.clickedNode()}
            madeChanges={(answer, data) => this.madeChanges(answer, data)}
          />
        );

      case 'History':
        return <CallGroupAuditHistory data={callGroup}/>;

      default:
        return <StandardDetailsPage cards={this.callGroupRoutes()} />;
    }
  }
  
  //to be called when attempting navigate away from the callgroups feature. Verifies if a failover has not been set for
  //  current call group. A call group with no failover will prompt the no failover modal.
  setNoFailoverOverride = () => {
      const { selectedCallGroup } = this.props;
      if (selectedCallGroup) {
          const currentCallGroup = this.props.callGroups.find(
              (obj) => obj.name == selectedCallGroup
          );
          
          if(!currentCallGroup.failover_value || currentCallGroup.failover_value == 'none') {
            this.showNoFailoverModal();
          }
      }
  };

  clickedElement = () => {
    var elements = document.querySelectorAll(".header," +
        " .detailContainerDiv, .leftBarDiv");
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", (e) => {
        
        if (e.target.classList.contains('dropdown-toggle')) {
          this.setState({
            navAway: true,
            openDropdownElem: e.target.getAttribute('id'),
          });
          //we are navigating away from this page, setNoFailoverOverride verifies whether
          // the failover modal should first be displayed prior to navigating away from the callgroups page.
          this.setNoFailoverOverride();
        }
        else if (e.currentTarget.classList.contains('detailContainerDiv')) {
          //  we are NOT navigating away from the current call group here, so override the failover modal prompt
          this.setState({overrideNoFailoverModal: true});
        }
        this.unsavedChanges();
      });
    }
  }; 
  
  unsavedChanges() {
    const { clickedInsideElements, newChanges } = this.state
    if (clickedInsideElements && newChanges) {
      this.openModal()
    }
  }

  openModal() {
    this.setState({
        overrideNoFailoverModal: true,
        showingSaveChangesModal: true })
  }

  clickedNode(){
    this.setState({ clickedInsideElements: true })
  }

  madeChanges(answer, data) {
    this.setState({
      newChanges: answer,
      childFormData: data,
    });
  }

  saveChange() {
    this.setState({
      showingSaveChangesModal: false,
      newChanges: false,
      clickedInsideElements: false,
      clickedSurroundingElements: false,
    });
    this.submitSettingsForm(this.state.childFormData);
  }

  discardChange() {
    this.setState({
      showingSaveChangesModal: false,
      newChanges: false,
      clickedInsideElements: false,
      clickedSurroundingElements: false,
     });
  }

  openCloseMenu(){
    this.setState({closeMenu: !this.state.closeMenu});
  }

  render() {

    const inUse = this.state.inUse;
    const { classes } = this.props;
    const {
      leftBarData,
      route,
    } = this.state;

    const {
      callGroups,
      selectedCallGroup,
    } = this.props;

    let title = '';

    let selectedCallGroupName = null;
    if (callGroups && selectedCallGroup) {

      selectedCallGroupName = callGroups.find(
        (obj) => obj.name == selectedCallGroup,
      );

      if (selectedCallGroupName) {
        title = selectedCallGroupName.name;
      }
    }

    const contextMenuOptions = [
      <MenuItem
        disabled={!selectedCallGroup}
        classes={{ root: classes.menuItem }}
        onClick={() => {
          this.openCloseMenu();
          this.setState({ showingDeleteConfirmModal: true });
          this.handleInUse();
        }}
        key={0}
      >
        Delete
      </MenuItem>
      ,
      <MenuItem
          onClick={() => {this.setState({ showingCopyCallGroupModal: true }); this.openCloseMenu();}}
          classes={{ root: classes.menuItem }}
          disabled={!selectedCallGroup}
          key={1}
      >
      Copy
      </MenuItem>,
      <MenuItem
        onClick={() => {this.goToHelp(); this.openCloseMenu();}}
        classes={{ root: classes.menuItem }}
        key={2}
      >
        Help
      </MenuItem>,
    ];

    return (
      <MasterView>
        <DetailContainer
          leftBar={
            <LeftBar
              addNew={() => this.handleAddNewClick()}
              data={leftBarData}
              pending={this.state.pendingCallGroups}
              selected={selectedCallGroup}
              select={(id) => this.leftBarSelect(id)}
              title="Call Groups"
            />
          }
          title={title}
          route={route != 'New' ? route : null}
          return={() => this.setState({ route: null })}
          contextMenuOptions={contextMenuOptions}
          closeMenu={this.state.closeMenu}
        >
          {this.renderContent()}
        </DetailContainer>
        <DeleteModal
          open={this.state.showingDeleteConfirmModal}
          title='Are you sure?'
          content={this.renderInUse()}
          delete={() => this.handleDeleteConfirmClose(true)}
          cancel={() => this.handleDeleteConfirmClose(false)}
        />
        <Dialog
            open={this.state.showingCopyCallGroupModal}
            onEnter={() => this.setState({ newCallGroupName: '' })}
            onClose={() => this.handleCopyConfirmClose(false)}
            fullWidth={true}
        >
            <DialogTitle>Call Group Copy</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To make a copy of this call group, please enter a unique name and a description.
                </DialogContentText>
                <TextField 
                    autoFocus
                    fullWidth={true}
                    margin='dense'
                    name='newCallGroupName'
                    label='New Call Group Name'
                    type='text'
                    value={this.state.newCallGroupName}
                    onChange={this.handleTextFieldChange}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    classes={{ root: classes.button }}
                    color='primary'
                    onClick={() => this.handleCopyConfirmClose(false)}
                >Cancel</Button>
                <Button
                    disabled={!this.state.newCallGroupName || this.props.callGroups.some(callGroup => callGroup.friendlyname == this.state.newCallGroupName || callGroup.name == this.state.newCallGroupName)}
                    classes={{ root: classes.button }}
                    color='primary'
                    onClick={() => this.handleCopyConfirmClose(true)}
                >Copy</Button>
            </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.showingNoFailoverModal}
          onClose={() =>this.handleNoFailoverConfirmClose(false)}
          fullWidth={true}
        >
          <DialogTitle>Are you sure? Not properly assigning a failover may result in a dropped call in most configurations.</DialogTitle>
          <DialogActions>
            <Button
              classes={{ root: classes.button }}
              size='large'
              color='primary'
              onClick={() => this.handleNoFailoverConfirmClose(false)}
            >Don't Assign a Failover</Button>
            <Button
              classes={{ root: classes.button }}
              size='large'
              color='primary'
              onClick={() => {
                this.handleNoFailoverConfirmClose(true);
                this.navigate('Failover');
              }}
            >Assign a Failover</Button>
          </DialogActions>
        </Dialog>
        <SaveChangesModal
          saveChange={() => this.saveChange()}
          discardChange={() => this.discardChange()}
          show={this.state.showingSaveChangesModal}
        />
      </MasterView>
    );
  }
}

const bindActions = (dispatch) => ({
  getCoreHttp: (reqData) => dispatch(getCoreHttp(reqData)),
  deleteCoreHttp: (reqData) => dispatch(deleteCoreHttp(reqData)),
  patchCoreHttp: (reqData) => dispatch(patchCoreHttp(reqData)),
  putCoreHttp: (reqData) => dispatch(putCoreHttp(reqData)),
  postCoreHttp: (reqData) => dispatch(postCoreHttp(reqData)),
  httpSuccess: (data) => dispatch(httpSuccess(data)),
  selectCallGroup: (group) => dispatch(selectCallGroup(group)),
});

const mapStateToProps = (state) => ({
  extensions: state.extensions.data,
  callGroups: state.callGroups.data,
  callGroupsConfig: state.callGroups.config,
  selectedCallGroup: state.callGroups.selected,
  auditHistory: state.callGroups.auditHistory,
});

export default connect(mapStateToProps, bindActions)(withStyles(styles)(CallGroups));
