import React, { Component }from 'react';

import Button from '@material-ui/core/Button';

//This component handles logic and rendering of the code itself, along with the expire time.
class Code extends Component{

    constructor(props) {
        super(props);
        this.interval = null;
        this.state = {
            expireTime: '0:00'
        }
    }

    //Function that starts our timer on render of the code.
    initTimer() {
        this.props.toggleShouldStartTimer();
        this.interval = setInterval(() => {
            this.checkExpireTime(this.props.values.validUntil);
        }, 1000);
    }

    //This function intended to run our timer and the logic around requesting a new code.
    //Kinda weird lookin' but it doesn't introduce any new deps and gets the job done with relatively little overhead.
    checkExpireTime(validUntil) {

        if(!validUntil){
            return;
        }

        const expire = new Date(validUntil)
        const now = new Date()
        const timeDiff = new Date(expire - now);
        const minutes = `${timeDiff.getMinutes()}`;
        let seconds = `${timeDiff.getSeconds()}`;

        if(minutes === '0' && seconds === '0'){
            clearInterval(this.interval);
            this.setState({ expireTime: "0:00" });
            this.interval = null;
            this.props.hideCode();
        } else {
            if(seconds.length < 2){
                seconds = '0' + seconds;
            }
            this.setState({ expireTime: `${minutes}:${seconds}` });
        }
    }

    render() {
        if(this.props.values.shouldStartTimer){
            this.initTimer();
        }

        const styles = {
            codeText: {
                color: '#0F4E8B',
                fontSize: '15px',
                padding: '10px 0px 5px 25px',
                visibility: this.props.values.visible
            },
            expires: {
                fontSize: '13px',
                visibility: this.props.values.visible
            }
        }

        return(
            <div>
                <div style={styles.codeText}>
                    {this.props.values.code}
                </div>
                <div style={styles.expires}>
                    Expires in: {this.state.expireTime}
                </div>
            </div>
        );
    }
}

// This component handles the fetch of the code and metadata as well as the copy and button.
class VerificationCode extends Component{
    constructor(props) {
        super(props);
        this.state = {
            visible: 'hidden',
            shouldStartTimer: false,
            code: ''
        }
    }
    
    //FetchCode calls the API to fetch the code from our code generating Lambda.
    fetchCode() {
        // Every day we stray further from gods light
        $.ajax({
            url: '/api/v1/userportal/' + app.token.get('tenant') + '/' + app.token.get('extension') + '/verificationcode',
            type: 'GET',
            success: (response) => {
                this.setState({
                    code: response['code'],
                    validUntil: response['validUntil'],
                    shouldStartTimer: true,
                    visible: 'visible'
                });
            },
            error: (error) => {
              console.log("[WARN] Failed to fetch verification code due to the following error: ", error);
            }
        });
    }

    //"hideCode" just sets state back to hidden, which trigger a re-render and hide everything once the timer expires.
    hideCode() {
        this.setState({
            visible: 'hidden'
        });
    }

    //Sets a value that we can use to easily tell if the timer should or should not be starting on render of the Code component.
    toggleShouldStartTimer() {
        this.setState({
            shouldStartTimer: false
        });
    }

    render() {

        const styles = {
            codeText: {
                fontSize: '13px',
                paddingTop: '16px',
                paddingBottom: '8px'
            },
            helpButton: {
                fontSize:'90%',
                backgroundColor:"#f8da37",
            },
        };

        return(
            <div>
                <>
                    <p style={styles.codeText}> To get started, get your code by clicking the button below and reach out to us using one of the previously listed contact methods.</p>
                </>
                <Button
                    size='large'
                    style={{ fontSize: '90%', backgroundColor: "#f8da37" }}
                    onClick={() => {this.fetchCode();}}
                    target="_blank"
                >View Code </Button>
                <Code hideCode={() => this.hideCode()} toggleShouldStartTimer={() => this.toggleShouldStartTimer()} values={{shouldStartTimer: this.state.shouldStartTimer, code: this.state.code, visible: this.state.visible, validUntil: this.state.validUntil}}/>
            </div>
        );
    }
}

export default VerificationCode;
