import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/styles';

const AuditHistory = (props) => {
    const classes = makeStyles({
      columnTextLeft: {
        textAlign: 'center',
      },
      columnTextRight: {
        textAlign: 'left',
      },
    })();

    return (
      <Table>
        <TableHead>
          <TableRow >
            <TableCell classes={{ root: classes.columnTextLeft }}>User</TableCell>
            <TableCell classes={{ root: classes.columnTextRight }}>Date edited</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => (
            <TableRow
              key={row._id}>
              <TableCell classes={{ root: classes.columnTextLeft }}>{row.user}</TableCell>
              <TableCell classes={{ root: classes.columnTextRight }}>{moment(row.timestamp).format('MMMM Do YYYY h:mm:ss a')}</TableCell>
            </TableRow>
         ))}
        </TableBody>
      </Table>
    );
};

AuditHistory.defaultProps = {
    data: [{
        timestamp: 0,
        user: '',
        _id: 0,
    }],
};

AuditHistory.propTypes = {
    data: PropTypes.array,
};

export default AuditHistory;
