import { NAVIGATE } from '../actions/typeConstants';

const initialState = {
    route: null,
    params: null,
    subItems: [],
}

export const navigation = (state=[], action) => {

    switch(action.type){

        case NAVIGATE:
            return Object.assign({}, state, { route: action.route, params: action.params });
        break;

        default:

            return state;
        break;
    }
}
