import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Button from '@material-ui/core/Button';
import makeGlobalStyles from 'makeGlobalStyles';

export default function HelpContent(props) {
  const classes = props.useStyles();
  const globalClasses = makeGlobalStyles();
  return (
    <div className={classes.wrapper}>
      <Card classes={{ root: classes.helpPaper }} elevation={1}>
        <CardContent classes={{ root: classes.helpContent }}>
          <CardContent>
            <h2 className={classes.pageTitle}>Manage Auto Attendants</h2>
            { tplHelper.get('label') =='yiptel' ?
              <p className={classes.helpText}>
                This section of the portal allows you to configure auto attendants,
                often referred to as voice menus or Interactive Voice Response (IVR) menus.
              </p>
              :
              <p className={classes.helpText}>
                This section of My FluentCloud allows you to configure auto attendants,
                often referred to as voice menus or Interactive Voice Response (IVR) menus.
              </p>
            }
          </CardContent>
          <CardContent classes={{ root: classes.helpTextTitle }}>
            About Auto Attendants
          </CardContent>
          <CardContent>
            <p className={classes.helpText}>Auto Attendants, sometimes known as Interactive Voice Response (IVR) or Voice Menus, are a navigation tool used in business phone systems, where a caller navigates to a desired destination by using the touch-tone keys on their phone keypad.</p>
            { tplHelper.get('label') =='yiptel' ?
              <p className={classes.helpText}>You can attach a number of applications throughout the YipTel system to use these auto attendants.</p>
              :
              <p className={classes.helpText}>You can attach a number of applications throughout the FluentCloud system to use these auto attendants.</p>
            }
            <p className={classes.helpText}>Once you have created your auto attendant, you will want to create or modify routes in order to use this functionality.</p>
          </CardContent>
        </CardContent>
        <CardContent classes={{ root: classes.standardHelpBlurb }}>
          <CardContent>
            <h2 className={classes.helpTextTitle}>Need Help?</h2>
            { tplHelper.get('label') =='yiptel' ?
              <div>
                <p className={classes.helpText}>The whole team at YipTel is ready to help you. 24 hours a day, 365 (or 366!) days a year. You can reach us a number of ways.</p>
                <ul>
                  <li>By calling 800-752-6110</li>
                  <li>By emailing support@yiptel.com</li>
                  <li>By clicking the button below!</li>
                </ul>
              </div>
              :
              <div>
                <p className={classes.helpText}>The whole team at FluentStream is ready to help you. 24 hours a day, 365 (or 366!) days a year. You can reach us a number of ways.</p>
                <ul>
                  <li>By calling 303-GO-CLOUD (303-462-5683)</li>
                  <li>By emailing support@fluentstream.com</li>
                  <li>By clicking the button below!</li>
                </ul>
              </div>
            }
            {/*<FlatButton
              backgroundColor="#0F4E8B"
              label="Get help from FluentStream"
              labelStyle={{ color: "#FFF" }}
              href="#supportCallModal"
            />*/}
            <p />
            <Button
              size='large'
              classes={{ root: globalClasses.helpButton }}
              href="http://help.fluentcloud.com/support/solutions/articles/4000149436-how-to-create-or-edit-an-auto-attendant"
              target="_blank"
            >How to add an Auto Attendant</Button>
          </CardContent>
        </CardContent>
      </Card>
    </div>
  );
}