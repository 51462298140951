import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Button from '@material-ui/core/Button';
import GlobalconferenceNumbers from './globalConferenceNumbers';
import makeGlobalStyles from 'makeGlobalStyles';

export default function HelpContent(props) {
  const classes = props.useStyles();
  const globalClasses = makeGlobalStyles();
  return (
    <div className={classes.wrapper}>
      <Card classes={{ root: classes.helpPaper }} elevation={1}>
        <CardContent classes={{ root: classes.helpContent }}>
          <CardContent>
          <h2 className={classes.pageTitle}>Manage Conference Rooms</h2>
          <p className={classes.helpText}>
            This section of My FluentCloud allows you to manage your conference room settings and create new ones.
          </p>
          </CardContent>
          <CardContent classes={{ root: classes.helpTextTitle }}>
            About Manage Conference
          </CardContent>
          <CardContent>
            <div>
              <p>With this page you are able to create and manage conference rooms for personal, departmental, or other uses.</p>
              <p>Conference rooms are designed to bring multiple parties together by dialing into the same phone number and PIN number. 
                You can also create an administrator PIN, granting access to controls for the conference as well as the option to force 
                other callers to wait until the administrator has joined.
              </p>
              <p>After setting up your room, you will need to make sure that you have a phone number configured to 
                access the conference system. If you need help accessing one of these, please contact the FluentStream support team.
              </p>
            </div>
          </CardContent>
          <CardContent classes={{ root: classes.helpTextTitle }}>
            Global Conference Numbers
          </CardContent>
          <CardContent>
            <p>Below is a list of conference numbers that anyone can use.</p>
            <GlobalconferenceNumbers />
          </CardContent>
        </CardContent>
        <CardContent classes={{ root: classes.standardHelpBlurb }}>
          <CardContent>
            <h2 className={classes.helpTextTitle}>Need Help?</h2>
            <div>
              <p className={classes.helpText}>The whole team at FluentStream is ready to help you. 24 hours a day, 365 days a year.
                You can reach us a number of ways.</p>
              <ul>
                <li>By calling 303-GO-CLOUD (303-462-5683)</li>
                <li>By emailing support@fluentstream.com</li>
                <li>By clicking the button below!</li>
              </ul>
            </div>
            <p />
            <Button
              size='large'
              classes={{ root: globalClasses.helpButton }}
              href="http://help.fluentcloud.com/support/solutions/articles/4000086811-how-to-create-a-conference-room"
              target="_blank"
            >How To Set Up Conferences</Button>
          </CardContent>
        </CardContent>
      </Card>
    </div>
  );
}