import React, { Component } from 'react';
import { connect } from 'react-redux';

import ErrorCard from 'presentational/ErrorCard';
import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';
import Footer from 'presentational/Footer';

import DelayIcon from '@material-ui/icons/Timer';
import AnnouncementIcon from '@material-ui/icons/RecordVoiceOver';

class MulticastSettings extends Component {

    constructor(props) {
        super(props);

        this.state = {

            errors: {},
            revertedForm: {},
            searchText:"",

            submitError: null,
            showingModal: false,
            newGroup: this.props.new ? this.props.new : false,
            newChanges: false,
            rollbackForm: {},

            mediaQuery: false,

            form:{
                name: '',
                description: '',
                port: '',
                ip_address: '',
                devices: '',
            }
        };
    }

    componentDidMount(){
        document.addEventListener('mousedown', this.handleClick, false)

        let { form, newGroup } = this.state;
        newGroup = this.props.new;
        if(this.props && this.props.group){
            form = Object.assign({}, this.props.group);
        }
        this.setState({form, newGroup }, () => this.createRollbackForm(this.props.group));

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
    }

    componentWillReceiveProps(nextProps){
        let { form, newGroup } = this.state;
        if(nextProps.new){
            form = {};
            newGroup = true;
            this.setState({form, newGroup});
        }
    }

    submitForm(field, data) {

        if (data == this.state.rollbackForm[field]) {
            this.setState({ newChanges: false, });
            return;
        }

        this.setState({ newChanges: true, });
        if(!this.state.newGroup){
            this.props.madeChanges(this.state.newChanges, this.state.form);
        }

        const { form } = this.state;
        form[field] = data;

        //Lets validate our field
        const error = this.validateField(field, data);
        let { errors } = this.state;
        if (error) {
            errors[field] = error;
            this.setState({
                errors,
            });
        } else {
            delete errors[field];
        }

        this.setState({
            form,
        });

    }

    validateField(field, data){
        switch(field){
        case 'name':
            if (/^[a-z0-9]+$/i.test(data)) {
                return '';
            }
            return 'Names should only include letters and numbers. Please remove all spaces and special characters.';
        
        case 'ip_address':
            if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(data)) {
                return '';
            }
            return 'You have entered and invalid IP address!';
        
        case 'port':
            if (/^\d+$/.test(data) && data.length < 10){
                return '';
            }
            return 'You have entered an invalid port!'
        }
    
    }

    createRollbackForm(group) {
        const rollbackForm = Object.assign({}, group);
        this.setState({ rollbackForm });
    }

    revertForm(){

        this.setState({group: this.state.revertedForm});

    }

    handleClick = (e) => {
        if (this.node) {
            if (this.node.contains(e.target)) {
                if(!this.state.newGroup){
                    this.props.clickedNode();
                }
            }
        }
    }

    submitFullForm(cancel){
        if(cancel){
            this.setState({ newChanges: false, form: this.props.group }, () => this.props.cancel(this.props.new));
        } else{
            this.props.submitForm(this.state.form);
            if(!this.props.new){
            this.setState({ newChanges: false });
            }
        }
    }

    render() {
        if ( (!this.props.group && !this.state.new) ||   !this.props.devices ) {
            return (
                <StandardSettingsPage>
                    <Loading />
                </StandardSettingsPage>
            );
        }

        const {errors, newChanges} = this.state;

        const style = {
            header:{
                color: '#595959',
            },
            button: {
                margin: '5px', 
            },
            buttonContainer: {
                marginTop: 20,
            }
        };

        let hasErrors = false;
        let renderDisabled = false;
        if(errors['name'] || errors['port'] || errors['ip_address']){
            hasErrors = true;
        }

        if((!this.props.new && (hasErrors || !newChanges))|| (this.props.new && hasErrors)){
            renderDisabled = true;
        }

        return (
            <div ref={node => this.node = node} style={{
                display: 'flex',
                flex: 1,
                height: '100%',
                background: '#EFEFEF',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: '50px',
                overflow: 'auto',
            }}>
                <StandardSettingsPage>

                    {
                        this.state.submitError ? <ErrorCard data={this.state.submitError} /> : null
                    }

                    <FormControlCard
                    form={this.state.form}
                    field="name"
                    formControl="TextInput"
                    disabled={!this.props.new}
                    errorText={this.state.errors['name']}
                    title="Multicast Group Name"
                    subtitle="Please enter a name for this multicast group"
                    icon={<AnnouncementIcon />}
                    submitForm={ (field, data) => this.submitForm(field, data) }
                    />
                    <FormControlCard
                    form={this.state.form}
                    field="description"
                    formControl="TextInput"
                    title="Multicast Group Description"
                    subtitle="Please enter a description for this multicast group."
                    icon={<AnnouncementIcon />}
                    submitForm={ (field, data) => this.submitForm(field, data) }
                    />
                    <FormControlCard
                    form={this.state.form}
                    field="ip_address"
                    errorText={this.state.errors['ip_address']}
                    formControl="TextInput"
                    title="IP Address"
                    subtitle="Please enter the IP address of this multicast group. Avoid using 224.0.0.75 with port 7243. If you update this field then please reload your devices on the Devices page!"
                    icon={<AnnouncementIcon />}
                    submitForm={ (field, data) => this.submitForm(field, data) }
                    />
                    <FormControlCard
                    form={this.state.form}
                    field="port"
                    errorText={this.state.errors['port']}
                    formControl="TextInput"
                    title="Port"
                    subtitle="Please enter the port of this multicast group. Avoid using 7243 with ip address 224.0.0.75. If you update this field then please reload your devices on the Devices page!"
                    icon={<DelayIcon />}
                    submitForm={ (field, data) => this.submitForm(field, data) }
                    />

                    <Footer
                        onClick={(cancel) => this.submitFullForm(cancel)}
                        disabled={renderDisabled}
                    />
                </StandardSettingsPage>
            </div>
        );
    }
}

const bindActions = (dispatch) => ({

});

const mapStateToProps = (store) => ({
    devices: store.devices.data,
    navigation: store.navigation,
    multicastGroups:store.multicastGroups,
    data:store.multicastGroups.data,
    pending: store.multicastGroups.pending,
    failure: store.multicastGroups.failure,
});

export default connect(mapStateToProps, bindActions)(MulticastSettings);
