import React from 'react';
import PropTypes from 'prop-types';
import TimeIcon from '@material-ui/icons/AccessTime';

import {
    KeyboardTimePicker,
  } from '@material-ui/pickers';


const TimePickerInput = (props) => {

  let value = (props.value ? props.value : null);

  return (
    <div>
      <KeyboardTimePicker
        keyboardIcon = {<TimeIcon/>}
        margin = "normal"
        id = "mui-pickers-time"
        label = {props.label}
        format = 'hh:mm a'
        value = {value}
        onChange = {(val) =>{
            props.onChange(val)
          }
        }
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
      />
    </div>
  )
}


TimePickerInput.defaultProps = {
    disabled: false,
    errorText: '',
    placeholder: '',
    value: null,
};


TimePickerInput.propTypes = {
    disabled: PropTypes.bool,
    errorText: PropTypes.string,
    field: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};


export default TimePickerInput;
