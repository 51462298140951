import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Button from '@material-ui/core/Button';
import makeGlobalStyles from 'makeGlobalStyles';

const HelpContent = ({
    aboutText,
    featureName,
    fsHelpText,
    helpButtonText,
    helpURL,
    useStyles,
    yiptelHelpText,
}) => {
    const classes = useStyles();
    const globalClasses = makeGlobalStyles();
    return (
        <div className={classes.wrapper}>
            <Card classes={{ root: classes.helpPaper }} elevation={1}>
                <CardContent classes={{ root: classes.helpContent }}>
                    <CardContent>
                        <h2 className={classes.pageTitle}>Manage {featureName}</h2>
                        {tplHelper.get('label') == 'yiptel' ? (
                            <p className={classes.helpText}>{yiptelHelpText}</p>
                        ) : (
                            <p className={classes.helpText}>{fsHelpText}</p>
                        )}
                    </CardContent>
                    <CardContent classes={{ root: classes.helpTextTitle }}>
                        About {featureName}
                    </CardContent>
                    <CardContent>{aboutText}</CardContent>
                </CardContent>
                <CardContent classes={{ root: classes.standardHelpBlurb }}>
                    <CardContent>
                        <h2 className={classes.helpTextTitle}>Need Help?</h2>
                        {tplHelper.get('label') == 'yiptel' ? ( // eslint-disable-line no-undef
                            <div>
                                <p className={classes.helpText}>
                                    The whole team at YipTel is ready to help you. 24 hours a day,
                                    365 (or 366!) days a year. You can reach us a number of ways.
                                </p>
                                <ul>
                                    <li>By calling 800-752-6110</li>
                                    <li>By emailing support@yiptel.com</li>
                                    <li>By clicking the button below!</li>
                                </ul>
                            </div>
                        ) : (
                            <div>
                                <p className={classes.helpText}>
                                    The whole team at FluentStream is ready to help you. 24 hours a
                                    day, 365 (or 366!) days a year. You can reach us a number of
                                    ways.
                                </p>
                                <ul>
                                    <li>By calling 303-GO-CLOUD (303-462-5683)</li>
                                    <li>By emailing support@fluentstream.com</li>
                                    <li>By clicking the button below!</li>
                                </ul>
                            </div>
                        )}
                        <Button
                            size="large"
                            classes={{ root: globalClasses.helpButton }}
                            href={helpURL}
                            target="_blank"
                        >
                            {helpButtonText}
                        </Button>
                    </CardContent>
                </CardContent>
            </Card>
        </div>
    );
};

HelpContent.defaultProps = {
    aboutText: (
        <div>
            <p>This is the about text for your feature</p>
        </div>
    ),
    featureName: 'This is the name of your feature',
    fsHelpText: 'This is the Fluentstream focused help text',
    helpButtonText: 'This is the text on the help button',
    helpURL: 'This is the url for the help button',
    useStyles: () => ({
        helpButton: '',
        helpContent: '',
        helpPaper: '',
        helpText: '',
        helpTextTitle: '',
        pageTitle: '',
        standardHelpBlurb: '',
        wrapper: '',
    }),
    yiptelHelpText: '',
};

HelpContent.propTypes = {
    aboutText: PropTypes.object,
    featureName: PropTypes.string.isRequired,
    fsHelpText: PropTypes.string.isRequired,
    helpButtonText: PropTypes.string.isRequired,
    helpURL: PropTypes.string.isRequired,
    useStyles: PropTypes.func.isRequired,
    yiptelHelpText: PropTypes.string.isRequired,
};

export default HelpContent;
