import React, { Component } from 'react'
import ReactTable from 'react-table'
import "react-table/react-table.css";
import { CSVLink } from "react-csv";
import Button from '@material-ui/core/Button';


const style = {
    grid: {
        background: 'white',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        minWidth: '75%'
    },
    column: {
        textAlign: 'center',
        marginTop: '10px',
    },
}

export default class TenantChangesGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            rowInfo: null,
            downloadPrompt: false,
            dialogKey: 0,
        }
    }

    parseCSVData(){

        let data = [...this.props.data];
        let parsedData = [];
        data.forEach((item, index) => {
            const newItem = {...item}
            if (item.details){
                const newDetails = JSON.stringify(item.details).replace(",", "")
                newItem["details"] = newDetails;
            };
            parsedData[index] = newItem;
        })

        return parsedData;

    }


    render() {

        const columns = [{
            Header: 'Tenant',
            accessor: 'tenant' ,
            Cell: props => <p style={style.column}>{props.value}</p>
        },
        {
            Header: 'Subscription Level',
            accessor: 'subscription_level' ,
            Cell: props => <p style={style.column}>{props.value}</p>
        },
        {
            Header: 'Target',
            accessor: 'target' ,
            Cell: props => <p style={style.column}>{props.value}</p>
        },
        {
            Header: 'Changes',
            accessor: 'value' ,
            Cell: props => <p style={style.column}>{props.value}</p>
        },
        {
            Header: 'Details',
            accessor: 'details' ,
            Cell: props => <p style={style.column}>{ props.value ? (JSON.stringify(props.value).replace(/[,]/g,', ').replace(/[{}"]/g, '')) : ""}</p>
        },
        {
            Header: 'User',
            accessor: 'audit_user' ,
            Cell: props => <p style={style.column}>{props.value}</p>
        },
        {
            Header: 'Created At',
            accessor: 'created_at' ,
            Cell: props => <p style={style.column}>{props.value}</p>
        },
    ]

    const csvData = this.parseCSVData();

        return (
            <div>
                <ReactTable
                    data={this.props.data}
                    columns={columns}
                    defaultPageSize={10}
                    defaultSorted={[ {id:'queuename', desc: false} ]}
                    styles={style.column}
                    className="-striped -highlight"
                    pageSizeOptions={[10, 25, 50, 100, 200]}
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: (e, handleOriginal) => {
                                if (rowInfo && column.id !== 'recording') {
                                    this.handleOpen(rowInfo);
                                }
                                return;
                            }   
                        }
                    }}
                />            
                {csvData.length > 0 && <CSVLink data={csvData} filename={"transaction_export"}>
                    <Button>Export CSV</Button>
                </CSVLink>}
            </div>
        )
    }
}
