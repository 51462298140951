import React, { Component } from 'react';
import PropTypes from 'prop-types';


import MenuButton from '@material-ui/icons/Menu';
import ContextMenuIcon from '@material-ui/icons/MoreVert';

import IconButton from '@material-ui/core/IconButton';

class ReportContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            leftBarOpen: false,
            mediaQuery: false,
        };
    }

    componentDidMount() {

        // Media query for responsive UI
        const mq = window.matchMedia('(min-width: 800px)');
        this.setState({ mediaQuery: mq });
        mq.addListener((mediaQuery) => this.setState({ mediaQuery }));
    }

    render() {

        const minWidth = '800px';

        const style = {
            menuButton: {
                marginLeft: this.state.leftBarOpen ? 360 : 10,
            },
            titleGroup: {
                justifyContent: 'flex-start',
            },
            toolbarTitle: {
                marginLeft: '10px',
                paddingLeft: '19px',
                minWidth: '60px',
            },
            titleWrapper: {
                display: 'flex',
                alignItems: 'center',
            },
            toolbar: {
                paddingLeft: '15px',
            },
            wrapper: {
                // display: 'flex',
                flex: 1,
                // marginLeft: minWidth ? 350 : 0,
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                overflowX: 'hidden'
            },
        };

        const leftBarWithProps = React.Children.map(this.props.leftBar,
            (child) => {

                const toggleLeftBar = () => {

                    if (!minWidth) {
                        this.setState({ leftBarOpen: !this.state.leftBarOpen });
                    }
                }

                if (!minWidth) {
                    return React.cloneElement(child, { leftBarOpen: this.state.leftBarOpen, toggleDrawer: () => toggleLeftBar() });
                }

                return React.cloneElement(child, { leftBarOpen: true, toggleDrawer: () => toggleLeftBar() });
            },
        );

        return (
          <div style={style.wrapper}>
            {/*<Toolbar style={style.toolbar}>
              <ToolbarGroup style={style.titleGroup} firstChild>
                  <ToolbarTitle
                    text={this.props.title}
                    style={style.toolbarTitle}
                  />
              </ToolbarGroup>
              {this.props.contextMenuOptions && this.props.contextMenuOptions.length > 0 ?
                <ToolbarGroup>
                  <IconMenu
                    iconButtonElement={
                      <IconButton touch>
                        <ContextMenuIcon />
                      </IconButton>
                    }
                  >
                    {this.props.contextMenuOptions.map((item) => item)}
                  </IconMenu>
                </ToolbarGroup>
              : null}
            </Toolbar>*/}
            {this.props.children}
          </div>
        );
    }
}

ReportContainer.defaultProps = {
    title: '',
    contextMenuOptions: [],
};

ReportContainer.propTypes = {
    title: PropTypes.string,
    leftBar: PropTypes.element.isRequired,
    contextMenuOptions: PropTypes.array, // array of material-ui MenuItems
};

export default ReportContainer;
