import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';

import ErrorCard from 'presentational/ErrorCard';
import FormControlCard from 'presentational/FormControlCard';
import StandardSettingsPage from 'presentational/StandardSettingsPage';
import Loading from 'presentational/Loading';
import Footer from 'presentational/Footer';

import DescriptionIcon from '@material-ui/icons/Description';
import DelayIcon from '@material-ui/icons/AvTimer';
import RingingIcon from '@material-ui/icons/RingVolume';
import RecordIcon from '@material-ui/icons/RecordVoiceOver';
import DialIcon from '@material-ui/icons/Dialpad';
import WarningIcon from '@material-ui/icons/Warning';
import EmptyIcon from '@material-ui/icons/RadioButtonUnchecked';
import AnnouncementIcon from '@material-ui/icons/RecordVoiceOver';

import { getCoreHttp,postCoreHttp, } from 'actions/httpRequest';

import {
    errorNotification,
    successNotification,
} from 'actions/notifications';

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            errors: {},
            form: {},
            submitError: null,
            newRecording: false, 
            formVal: ""
        };

        if (!props.configurations) {
            return;
        }

        this.recordingUrl = `/api/v1/core/${props.configurations.tenant}/playrecording`;
    }

    componentDidMount() {

        this.setState({ form: this.props.data }, ()=> this.createRollBackForm());
        document.addEventListener('mousedown', this.handleClick, false)

        this.fetchRecordings();
    }

    createRollBackForm() {
      const rollbackForm = Object.assign({}, this.state.form);
      this.setState({ rollbackForm });
    }
    
    onSubmit(cancel){
      if(cancel){
        this.props.cancel();
      } else{
        this.props.submitForm(this.state.form);
      }
    }

    onCancel = () => {
      this.setState({newRecording: false})
    }

    handleClick = (e) => {
      if(this.node) {
        if(this.node.contains(e.target)) {
          this.props.clickedNode();
        }
      }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.configurations) {

            this.recordingUrl = `/api/v1/core/${nextProps.configurations.tenant}/playrecording`;
        }
    }

    componentWillUnmount(){
      document.removeEventListener('mousedown', this.handleClick, false)
    }

    fetchRecordings() {

        // Lets set up the request data to retreive auto attendants
        const reqData = {
            reqAction: 'recording',
        };

        const storeKey = 'recordings';

        this.props.getCoreHttp(reqData, storeKey);
    }

    async submitForm(field, data) {

        if (this.state.rollbackForm[field] == data && data !== '' && this.state.newChanges == false){
            console.log('No update');
            return;
        } else {
          this.setState({
            newChanges: true,
            disabled: false}, ()=>
          this.props.madeChanges(this.state.newChanges, this.state.form))
        }

        if (this.state.disabled == true){
          this.setState({disabled: false});
        }

        const { form } = this.state;
        form[field] = data;

        // Lets validate our field
        const error = this.validateField(field, data);
        if (error) {
            this.setState({
                form,
                errors: { [field]: error },
            });
            return;
        }

        // If no error, lets set the data
        // on the form and set it to state without errors
        this.setState({
            form,
            errors: { [field]: '' },
        });

    }

    validateField(field, data) {

        // We want to make sure our wait
        // times are actually numbers
        // and greater than 0
        switch (field) {
        case 'responsewait':
        case 'keypresswait':
        case 'i_limit':
        case 'none_limit':
            if (data && Number.isInteger(parseInt(data)) && data >= 0 && data <= 10) {
                return null;
            }
            return 'Please enter a valid number';

        default:
            return null;
        }
    }

    updateFormVal = () => {
      this.setState({formVal: ""})
    }

    getRecordingsAsOptions() {

        if (!this.props.recordings || this.props.recordings.length == 0) {

            return [{ label: 'No recordings available', value: '' }];
        }

        const recordings = [];
        this.props.recordings.map((recording) => {
            recordings.push(recording.name);
        });

        recordings.sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1)

        return recordings;
    }

    recordNewGreeting(){
      this.setState({newRecording: true});
    }

    recordingNameSet = (recordingName) => {
      recordingName = recordingName.replace(/[\W_]/g, "")
      this.setState({recordingName})
    }

    //Upload a new recording from an audio file
    upload(recording){
      if (recording && recording.target) {
        let recordingName = recording.target.files[0].name;
        recordingName = recordingName.split(".")[0].replace(/[\W_]/g, "")
        if(recordingName.length > 40){
          errorNotification({ title: 'Error!', message: `Recording cannot exceed 45 characters`});
          return;
        }
          this.setState({
              disabled: false, 
              recordingPath: recording.target.files[0],
              recordingName,
              recordingData:null
          })
      } else {
          this.setState({
              disabled: false,
              recordingData: recording,
          })
      }
  }

  createRecording = () => {

    let formData;
 
    if(this.state.recordingData){
      let blob = this.state.recordingData.blob;
      let fileType = blob.type.split('/')[0] || 'audio';
      let fileName = (Math.random() * 1000).toString().replace(".", "");

      if(fileType === "audio"){
        fileName += "." + (!!navigator.mozGetUserMedia ? 'ogg' : 'wav');
      }else {
        fileName += '.webm';
      }

      formData = new FormData();
      formData.append(fileType + '-filename', fileName);
      formData.append(fileType + '-blob', blob);
      // let data = formData;
      formData.append('description', "");
      formData.append('redo', false);
      formData.append('name', "name")
    }else {
      formData = new FormData();
      let file = this.state.recordingPath;
      formData.append('file', file);
      formData.append('name', "name")
      formData.append('redo', false);
      formData.append('description', "");
  }
    //  Lets set up the request data to retreive auto attendants
     const reqData = {
      reqAction: 'recording',
      reqObject: this.state.recordingName,
      reqBody: formData
    };

  const storeKey = 'recordings';
   let errors = false
   if(this.state.recordingName.length > 50){
     errors = true;
   }
   this.props.recordings.forEach(recording => {
     if(recording.name == this.state.recordingName){
       errors = true
     }
   })
  if(this.state.recordingName && !errors){
    this.props.postCoreHttp(reqData, storeKey);
    this.fetchRecordings();
    this.submitForm('announcement', this.state.recordingName);
    this.setState({formVal: reqData.reqObject, recordingName: "", newRecording: false})
    successNotification({ title: 'Success!', message: `Recording now available! Make sure to save changes.` });
  }else if(errors){
    if(this.state.recordingName.length > 40){
      errorNotification({ title: 'Error!', message: `Recording cannot exceed 45 characters`});
    }else{
      errorNotification({ title: 'Error!', message: `Recording cannot have same name as existing recording. Choose unique name` });
    }
  }
  
  else if(!this.state.recordingName && !this.state.recordingData){
      errorNotification({ title: 'Error!', message: `Recording file must be provided` });
  }else{
    errorNotification({ title: 'Error!', message: `Recording Name must be provided` });
  }
}

    render() {

        if (!this.props.data || !this.state.form || !this.props.recordings || !this.props.config || !this.props.configurations) {
            return (
              <StandardSettingsPage>
                <Loading />
              </StandardSettingsPage>
            );
        }
        let submitDisabled = false;
        if(this.state.disabled || this.state.errors.responsewait || this.state.errors.keypresswait || this.state.errors['i_limit'] || this.state.errors['none_limit']){
          submitDisabled = true;
        }
        return (
          <div ref={node => this.node = node} style={{
            display: 'flex',
            flex: 1,
            height: '100%',
            background: '#EFEFEF',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '50px',
            overflow: 'auto',
          }}>
          <StandardSettingsPage>
            {this.state.submitError ?
              <ErrorCard data={this.state.submitError} />
              : null
            }
            <FormControlCard
              form={this.state.form}
              field="description"
              formControl="TextInput"
              title="Description"
              subtitle="Summary of the auto attendant"
              icon={<DescriptionIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
            />
            <FormControlCard
              form={this.state.form}
              field="announcement"
              formControl="AudioPlayerInput"
              formVal = {this.state.formVal}
              recordingUrl={this.recordingUrl}
              title="Announcement"
              updateFormVal = {() => this.updateFormVal()}
              subtitle="Pre-recorded announcement to be played when the caller reaches the Auto Attendant"
              icon={<AnnouncementIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
              options={this.getRecordingsAsOptions()}
            />
            {!this.state.newRecording ? (<FormControlCard 
              key="Create New Recording"
              field="newrecording"
              formControl = 'RecordNew'
              title= "Create New Recording"
              icon={<RecordIcon />}
              recordNewGreeting={() => this.recordNewGreeting()}
            /> ): 
            (<FormControlCard 
              key="Create New Recording"
              field= "newrecording"
              formControl = 'newRecording'
              title= "Create New Recording"
              icon={<RecordIcon />}
              upload={(recording) => this.upload(recording)}
              createRecording = {this.createRecording}
              recordingName = {(recordingName) => this.recordingNameSet(recordingName)}
              onCancel = {() => this.onCancel()}
            />)}
            <FormControlCard
              form={this.state.form}
              field="delaybeforeplaying"
              formControl="SelectInput"
              title="Delay before playing"
              subtitle="How many seconds the caller will hear dead air until the greeting begins"
              icon={<DelayIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
              options={this.props.config['delaybeforeplaying']}
            />
            <FormControlCard
              form={this.state.form}
              field="ringwhilewaiting"
              formControl="ToggleInput"
              title="Ring while waiting"
              subtitle="Determines whether the caller will hear ringing while they are being connected"
              icon={<RingingIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
              label="No / Yes"
            />
            <FormControlCard
              form={this.state.form}
              field="responsewait"
              formControl="TextInput"
              title="Response wait time"
              subtitle="How many seconds the auto attendant will wait for your response before timing out to the no input response"
              icon={<DelayIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
              label="Seconds"
              min={this.props.config['responsewait'][0]}
              max={this.props.config['responsewait'][this.props.config['responsewait'].length -1]}
              errorText={this.state.errors['responsewait']}
            />
            <FormControlCard
              form={this.state.form}
              field="keypresswait"
              formControl="TextInput"
              title="Key press wait time"
              subtitle="How many seconds the auto attendant will wait for you to dial an extension, or any other option, before it times out"
              icon={<DelayIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
              label="Seconds"
              min={this.props.config['keypresswait'][0]}
              max={this.props.config['keypresswait'][this.props.config['keypresswait'].length -1]}
              errorText={this.state.errors['keypresswait']}
            />
            <FormControlCard
              form={this.state.form}
              field="i_limit"
              formControl="TextInput"
              title="Invalid key press limit"
              subtitle="Number of times the auto attendant will restart when an invalid key is pressed"
              icon={<WarningIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
              label=""
              errorText={this.state.errors['i_limit']}
            />
            <FormControlCard
              form={this.state.form}
              field="none_limit"
              formControl="TextInput"
              title="No key press limit"
              subtitle="Number of times the auto attendant will restart when no key is pressed"
              icon={<EmptyIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
              label=""
              errorText={this.state.errors['none_limit']}
            />
            <FormControlCard
              form={this.state.form}
              field="allowextensiondial"
              formControl="ToggleInput"
              title="Allow extension dial"
              subtitle="Allow caller to dial an extension from the auto attendant."
              icon={<DialIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
              label="No / Yes"
            />
            <FormControlCard
              form={this.state.form}
              field="allowfeaturedial"
              formControl="ToggleInput"
              title="Allow feature dial"
              subtitle="Allows feature codes to be dialed from the Auto Attendant."
              icon={<DialIcon />}
              submitForm={(field, data) => this.submitForm(field, data)}
              label="No / Yes"
            />
            <Footer 
              disabled={submitDisabled}
              onClick={(cancel) => this.onSubmit(cancel)}
            />
          </StandardSettingsPage>
        </div>
        );
    }
}

const bindActions = (dispatch) => ({
    getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
    postCoreHttp: (reqData, storeKey) => dispatch(postCoreHttp(reqData, storeKey)),
});

const mapStateToProps = (state) => ({
    configurations: state.configurations.data,
    recordings: state.recordings.data,
});

export default connect(mapStateToProps, bindActions)(Settings);
