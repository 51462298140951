import React, { Component } from 'react';

import StandardSettingsPage from 'presentational/StandardSettingsPage';
import TextInput from 'presentational/formControls/TextInput';
import SubmitButton from 'presentational/formControls/SubmitButton';
import CancelButton from 'presentational/formControls/CancelButton';

class NewAgent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorText: '',
            agent_id: '',
        };
    }

    async submitForm() {
        const error = await this.props.submitForm(this.state.agent_id);
        if (error) {

            console.error(error);
            this.setState({
                disabled: false,
                errorText: error.summary,
            });
            return;
        }

        this.props.selectDynamicAgent(this.state.agent_id);
    }

    render() {

        const style = {
            header: {
                color: '#595959',
            },

        };

        return (
          <div style={{
            display: 'flex',
            flex: 1,
            height: '100%',
            background: '#EFEFEF',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '50px',
            overflow: 'auto',
          }}>
          <StandardSettingsPage>
            <h2 style={style.header}>Enter Dynamic Agent ID</h2>
            <TextInput
              errorText={this.state.errorText}
              field="agent_id"
              onChange={(agent_id) => this.setState({ agent_id })}
              placeholder="Agent ID"
              value={this.state.agent_id.replace(/[^0-9]/g, '')}
            />
            <div>
              <SubmitButton
                disabled={this.state.disabled}
                label="Create"
                onClick={() => {
                    this.setState({ disabled: true });
                    this.submitForm(this.state.agent_id);
                }}
              />
              <CancelButton
                label="Cancel"
                onClick={() => this.props.selectDynamicAgent('')}
              />
            </div>
          </StandardSettingsPage>
          </div>
        );
    }
}

export default NewAgent;
