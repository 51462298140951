import React, { Component } from 'react';
import { connect } from 'react-redux';

import request from 'superagent';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import DetailContainer from 'presentational/DetailContainer';
import ActionCard from 'presentational/ActionCard';
import StandardDetailsPage from 'presentational/StandardDetailsPage';
import LeftBar from 'presentational/LeftBar';
import MasterView from 'presentational/MasterView';
import DeleteModal from 'presentational/DeleteModal';

import MultiCastGroupHelp from './help';
import MulticastSettings from "./settings.js"
import MulticastDevices from "./devices.js"

import {
    deleteCoreHttp,
    getCoreHttp,
    postCoreHttp,
    putCoreHttp,
} from 'actions/httpRequest';

import { selectMulticastGroup } from "actions/multicastGroups";

import { successNotification, errorNotification } from 'actions/notifications';

class MulticastGroups extends Component {

    constructor(props) {

        super(props);

        this.state = {
            route: null,
            selectedGroup: null,
            leftBarData: null,
            closeMenu: false,
            showingDeleteConfirmModal:false,
            showingSaveChangesModal: false,
            clickedSurroundingElements: false,
            clickedInsideElements: false,
            newChanges: false,
            reloadDeviceConfig: false,
            childFormData: {},
        };
    }

    componentWillMount() {

        this.fetchMulticastGroups();
    }

    componentDidMount() {

        this.props.selectMulticastGroup('');

        if(!this.props.configurations || !this.props.configurations.devices){
            this.fetchDevices();
        } else {
           this.setState({leftBarData: this.prepareDataForLeftBar(this.props.multicastGroups.data)});
        }

        this.clickedElement()
    }

    componentWillUnmount() {
        this.setState({
            route: null,
            selectedGroup: null,
            leftBarData: null,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.postSuccess && nextProps.postSuccess.success && nextProps.reducerTarget === "multicastgroups"){
            // Lets trigger a notification
            successNotification({
                title: 'Success!',
                message: `Created Multicast Group ${nextProps.postSuccess.object}`,
            });
        }

        if (nextProps.postSuccess && nextProps.postSuccess.failure && nextProps.reducerTarget === "multicastgroups"){
            errorNotification({
                title: 'Failed!',
                message: 'Failure Creating Multicast Group',
            });
        }

        if (nextProps.putSuccess && nextProps.putSuccess.success && nextProps.reducerTarget === "multicastgroups"){
            // Lets trigger a notification
            if(nextProps.putSuccess.object){
                successNotification({
                    title: 'Success!',
                    message: `Updated Multicast Group ${nextProps.putSuccess.object}`,
                });
            } else{
                successNotification({
                    title: 'Success!',
                    message: `Updated Multicast Group`,
                });
            }
            this.setState({reloadDeviceConfig: true});
        }

        if (nextProps.putSuccess && nextProps.putSuccess.failure && nextProps.reducerTarget === "multicastgroups"){
            errorNotification({
                title: 'Failed!',
                message: 'Failure Updating Multicast Group',
            });
        }

        if (nextProps.deleteSuccess &&nextProps.deleteSuccess.success && nextProps.reducerTarget === "multicastgroups"){
            // Lets trigger a notification
            successNotification({
                title: 'Success!',
                message: `Deleted Multicast Group ${nextProps.deleteSuccess.object}!`,
            });
        }

        if (nextProps.deleteSuccess && nextProps.deleteSuccess.failure && nextProps.reducerTarget === "multicastgroups"){
            errorNotification({
                title: 'Failed!',
                message: 'Failure Deleting Multicast Group',
            });
        }

        if (nextProps.data && nextProps.data && this.props.data !== nextProps.data) {
            this.setState({leftBarData: this.prepareDataForLeftBar(nextProps.data)});
        }
        else{
            this.setState({leftBarData: this.prepareDataForLeftBar(nextProps.data)});
        }

        let selectedGroup = nextProps.selectedGroup;
        if (nextProps.selectedGroup && nextProps.selectedGroup !== "New Multicast Group" && nextProps.selectedGroup !== this.props.selectedGroup){
            this.setState({ route: null, selectedGroup });
        } else{
            this.setState({ selectedGroup });
        }
    }

    fetchMulticastGroups() {

        //production
        const reqData = {
            reqAction: 'multicastgroups',
        };

        const storeKey = 'multicastGroups';

        this.props.getCoreHttp(reqData, storeKey);

        // this.props.selectMulticastGroup();
    }

    fetchMulticastGroup(name) {

        //production
        const reqData = {
            reqAction: 'multicastgroups',
            reqObject: name,
        };

        const storeKey = 'multicastGroups';

        this.props.getCoreHttp(reqData, storeKey);
    }


    //TODO this shouldn't be here.  not sure where, but for sure not here
    fetchDevices() {

        // Lets set up the request data to retreive auto attendants
        const reqData = {
            reqAction: 'devices',
        };

        const storeKey = 'devices';

        this.props.getCoreHttp(reqData, storeKey);
    }

    leftBarSelect(name) {
        let selectedGroup = this.props.data.find( (group) => group.name === name);
        if(selectedGroup){
            this.fetchMulticastGroup(name);
            this.props.selectMulticastGroup(name);
        }
    }

    prepareDataForLeftBar(data) {

        // return on no data
        if (!data) {
            return null;
        }

        // array we will push our new object into
        const leftBarData = data.map( (item) => {
            // Set the title to friendly name if we have it, otherwise just the name
            const title = item.friendlyname ? item.friendlyname : item.name;
            const subtitle = item.description ? item.description : "";
            const id = item.name;

            // push the data into the leftBar array
            return { id, title, subtitle };
        });


        return leftBarData;
    }

    handleCancel(newGroup){
        let {selectedGroup} = this.props;
        if(newGroup){
            selectedGroup = '';
        }
        this.setState({ 
            selectedGroup,
            route: null,
            newChanges: false,
            clickedInsideElements: false,
            clickedSurroundingElements: false
        })
    }

    handleAddNewClick() {
        this.props.selectMulticastGroup('New Multicast Group');
        this.navigate('New');
    }

    handleDeleteConfirmClose(shouldDelete) {

        shouldDelete && this.deleteGroup();
        this.setState({ showingDeleteConfirmModal: false });
    }

    deleteGroup() {

        if (!this.state.selectedGroup) {
            return;
        }

        const { selectedGroup } = this.state;
        const reqData = {
            reqAction: 'multicastgroups',
            reqObject: selectedGroup,
        };

        const storeKey = 'multicastGroups';

        this.props.deleteCoreHttp(reqData, storeKey);
        
        // Lets set state to clear the route and selected auto attendant
        this.setState({
            route: null,
            selectedGroup: '',
        });
        this.props.selectMulticastGroup('');
    }

    goToHelp() {
        const win = window.open('http://help.fluentcloud.com/support/solutions', '_blank');
        win.focus();
    }

    async createNewMulticastGroup(newGroup) {

        if(!newGroup.name){
            errorNotification({title: 'Failed!', message: 'Please enter a name for this group'});
            return;
        }
        if(!newGroup['ip_address']){
            errorNotification({title: 'Failed!', message: 'Please enter an IP address for this group'});
            return;
        }
        if(!newGroup.port){
            errorNotification({title: 'Failed!', message: 'Please enter a port for this group'});
            return;
        }

        let reqBody = newGroup;
        const reqData = {
            reqAction: 'multicastgroups',
            reqObject: newGroup.name,
            reqBody,
        };

        const storeKey = 'multicastGroups';

        this.props.postCoreHttp(reqData, storeKey);

        await this.props.selectMulticastGroup(newGroup.name);
    }
       
    async submitSettingsForm(data) {

        if (!data.name) {
            errorNotification({ title: 'Failed!', message: 'Please enter a name for this group' });
            return;
        }
        if (!data['ip_address']) {
            errorNotification({ title: 'Failed!', message: 'Please enter an IP address for this group' });
            return;
        }
        if (!data.port) {
            errorNotification({ title: 'Failed!', message: 'Please enter a port for this group' });
            return;
        }

        const reqData = {
            reqAction: 'multicastgroups',
            reqObject: data.name,
            reqBody: data,
        };

        const storeKey = 'multicastGroups';

        this.props.putCoreHttp(reqData, storeKey);

        this.setState({
            clickedInsideElements: false,
            clickedSurroundingElements: false,
            newChanges: false,
        })
    }

    clickedElement = () => {
        var elements = document.querySelectorAll(".header, .leftBarDiv, .detailContainerDiv");
        for (var i = 0; i < elements.length; i++) {
            elements[i].addEventListener("click", () => {
                this.setState({ clickedSurroundingElements: true }, () => {
                    this.unsavedChanges();
                })
            });
        }
    }

    unsavedChanges() {
        const { clickedInsideElements, clickedSurroundingElements, newChanges } = this.state;
        if (clickedInsideElements && clickedSurroundingElements && newChanges) {
            this.openModal();
        }
    }

    openModal() {
        this.setState({ showingSaveChangesModal: true })
    }

    clickedNode() {
        this.setState({ clickedInsideElements: true })
    }

    madeChanges(answer, data) {
        this.setState({
            newChanges: answer,
            childFormData: data,
        });
    }


    navigate(route) {

        this.setState({ route });
    }

    multiCastGroupRoutes(){
        return ([
            <ActionCard
                key="Settings"
                title="Settings"
                subtitle="Manage details for this multicast group"
                action={() => this.navigate('Settings')}
            />,
            <ActionCard
                key="Devices"
                title="Devices"
                subtitle="Update devices in this multicast group"
                action={() => this.navigate('Devices')}
            />,
        ]);
    }

    renderContent() {

        const { route, selectedGroup } = this.state;
        const { data } = this.props;

        if (!selectedGroup) {
            return <MultiCastGroupHelp />;
        }

        let selectedGroupData;
        if(selectedGroup && selectedGroup !== "New Multicast Group"){
            let found = data.findIndex((group) => group.name == selectedGroup);
            if(found !== -1){
                selectedGroupData = data[found];
            }
        }

        switch (route) {

        case 'New':
            return (
              <MulticastSettings
                new={true}
                submitForm={(data) => this.createNewMulticastGroup(data)}
                selectMulticastGroup={(name) => this.leftBarSelect(name)}
                cancel={(newGroup) => this.handleCancel(newGroup)}
                group={{}}
                madeChanges={(answer, data) => this.madeChanges(answer, data)}
                clickedNode={() => this.clickedNode()}
              />
            );
        case 'Settings':
            return (
              <MulticastSettings
                submitForm={(data) => this.submitSettingsForm(data)}
                selectMulticastGroup={(name) => this.leftBarSelect(name)}
                cancel={() => this.handleCancel()}
                group={selectedGroupData}
                madeChanges={(answer, data) => this.madeChanges(answer, data)}
                clickedNode={() => this.clickedNode()}
              />
            );
        case 'Devices':
            return (
              <MulticastDevices
                submitForm={(data) => this.submitSettingsForm(data)}
                selectMulticastGroup={(name) => this.leftBarSelect(name)}
                devices={this.props.devices}
                cancel={() => this.handleCancel()}
                group={selectedGroupData}
                madeChanges={(answer, data) => this.madeChanges(answer, data)}
                clickedNode={() => this.clickedNode()}
                reloadDeviceConfig={this.state.reloadDeviceConfig}
              />
            );
        default:
            return <StandardDetailsPage cards={this.multiCastGroupRoutes()} />;
        }
    }

    openCloseMenu(){
        this.setState({closeMenu: !this.state.closeMenu});
    }

    render() {

        const {
            data,
            pending,
            failure,
        } = this.props;

        const { leftBarData, route, selectedGroup } = this.state;

        let title = '';
        if (selectedGroup) {
            title = selectedGroup;
        }

        const contextMenuOptions = [
        <MenuItem
            disabled={!selectedGroup}
            onClick={() => {
                this.setState({ showingDeleteConfirmModal: true });
                this.openCloseMenu();
            }}
            key={0}
        >
            Delete
        </MenuItem>,
        <MenuItem
        onClick={() => {
            this.goToHelp();
            this.openCloseMenu();
        }}
        key={1}
        >
            Help
        </MenuItem>,

        ];


        const saveModalActions = [
            <Button
                color="primary"
                onClick={() => {
                    this.setState({
                        showingSaveChangesModal: false,
                        newChanges: false,
                        clickedInsideElements: false,
                        clickedSurroundingElements: false,
                    });
                    this.submitSettingsForm(this.state.childFormData);
                    this.handleCancel();
                }}
            >
                Save Changes
            </Button>,
            <Button
                color="secondary"
                onClick={() => {
                    this.setState({
                        showingSaveChangesModal: false,
                        newChanges: false,
                        clickedInsideElements: false,
                        clickedSurroundingElements: false,
                    }, () => this.handleCancel())
                }}
            >
                Discard Changes
            </Button>
        ]


        return (
          <MasterView>

            <DetailContainer
              leftBar={
                <LeftBar
                    addNew={ () => this.handleAddNewClick() }
                    data={leftBarData}
                    pending={pending}
                    selected={selectedGroup}
                    select={ (id) => this.leftBarSelect(id) }
                    title="Multicast Groups"
                />
              }
              title={title}
              route={route == 'New' || selectedGroup ? route == 'Settings' || route == 'Devices' ? route : true : false}
              return={() => {
                  if (route != 'Settings' && route != 'Devices' && selectedGroup) {
                      this.setState({ route: null, selectedGroup: '' });
                  } else {
                      this.setState({ route: null });
                  }
              }}
              contextMenuOptions={contextMenuOptions}
              closeMenu={this.state.closeMenu}
            >

                {this.renderContent()}

            </DetailContainer>

            <DeleteModal
                open={this.state.showingDeleteConfirmModal}
                title='Are you sure?'
                delete={() => this.handleDeleteConfirmClose(true)}
                cancel={() => this.handleDeleteConfirmClose(false)}
            />
            <Dialog
                open={this.state.showingSaveChangesModal}
                onClose={() => this.handleUnsavedConfirmClose(false)}
                fullWidth={true}
            >
                <DialogTitle>Would you like to save or discard your changes?</DialogTitle>
                <DialogActions>{saveModalActions}</DialogActions>
            </Dialog>
          </MasterView>
        );
    }
}

const bindActions = (dispatch) => ({

    deleteCoreHttp: (reqData, storeKey) => dispatch(deleteCoreHttp(reqData, storeKey)),
    getCoreHttp: (reqData, storeKey) => dispatch(getCoreHttp(reqData, storeKey)),
    postCoreHttp: (reqData, storeKey) => dispatch(postCoreHttp(reqData, storeKey)),
    putCoreHttp: (reqData, storeKey) => dispatch(putCoreHttp(reqData, storeKey)),
    selectMulticastGroup: (name) => dispatch(selectMulticastGroup(name)),
});

//juliette make sure you reset the data here to be store.multicastGroups.data
const mapStateToProps = (store) => ({
    devices: store.devices.data,
    metaData: store.multicastGroups.metaData,
    navigation: store.navigation,
    data:store.multicastGroups.data,
    pending: store.multicastGroups.pending,
    failure: store.multicastGroups.failure,
    selectedGroup: store.multicastGroups.selected,
    deleteSuccess: store.multicastGroups.deleteSuccess,
    putSuccess: store.multicastGroups.putSuccess,
    postSuccess: store.multicastGroups.postSuccess,
    reducerTarget: store.multicastGroups.reducerTarget,
});

export default connect(mapStateToProps, bindActions)(MulticastGroups);
