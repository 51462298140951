import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import DragHandle from '@material-ui/icons/DragHandle';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import { formatNumber } from 'utils/phoneNumbers';
import { keySrt } from 'utils/misc';
import { newInternalDidSelected, showingNewNumberInput } from 'actions/sms';

const matStyles = () => ({
    handle: {
        transform: 'rotate(90deg)',
        color: '#666',
        minWidth: 0,
    },
    searchBox: {
        marginLeft: 25,
    },
    searchIcon: {
        color: 'rgba(115, 115, 115, .8)',
        marginTop: 20,
        marginLeft: 5,
    },
});

class SmsNumberDrawer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            drawerOffset: '22vw',
            searchInput: '',
        };
    }

    componentWillReceiveProps(nextProps){
        if(this.props.selectedInternalDid != nextProps.selectedInternalDid){
            this.setState({showNewNumberInput: false});
        }
    }

    toggleDrawer() {

        return this.state.drawerOffset == '22vw' ?
            this.setState({ drawerOffset: '2vw' }) :
            this.setState({ drawerOffset: '22vw' });
    }

    // gets all sms enabled dids passed in via token
    smsDids() {

        const smsEnabledDids = [];
        // If we have dids from configurations
        if (this.props.dids && this.props.dids.length > 0) {
            // Lets map through dids to get sms enabled ones
            this.props.dids.map((did) => {
                if (did.smsenabled == '1') {

                    if (!this.state.searchInput) {
                        return smsEnabledDids.push({ number: did.phonenumber, description: did.description, unread: did.unreadMessages || 0 });
                    }

                    if (did.phonenumber.toLowerCase().indexOf(this.state.searchInput) !== -1 ||
                        did.description.toLowerCase().indexOf(this.state.searchInput) !== -1) {
                        return smsEnabledDids.push({ number: did.phonenumber, description: did.description, unread: did.unreadMessages || 0 });
                    }
                }
            });
            // Lets sort by unread so all unread messages are at top
            return smsEnabledDids.sort(keySrt('unread'));
        }

        // Let's use the user's dids (from token) otherwise
        // Lets map through dids to get sms enabled ones
        if(this.props.token && this.props.token.userdids) {
            this.props.token.userdids.map((did) => (
                did.smsenabled == '1' ? smsEnabledDids.push({ number: did.phonenumber, description: did.description, unread: did.unreadMessages || 0 }) : null
            ));
            if (this.props.token.primarydid && this.props.token.primarydid.length) {
                smsEnabledDids.push({ number: this.props.token.primarydid, description: "", unread:  0 })
            }
        }

        // lets return the updated array
        return smsEnabledDids;
    }

    render() {
        const { classes } = this.props;
        return (
          <div
            style={{
                transition: 'width 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
                width: this.state.drawerOffset,
                overflow: 'hidden',
            }}
          >
            <div style={style.handleWrapper}>
              <Button
                classes={{ root: classes.handle }}
                onClick={() => this.toggleDrawer()}
              >{<DragHandle />}</Button>
            </div>
            <div style={{ height: '100%', paddingTop: '10px' }}>
              <div style={style.searchWrapper}>
                <TextField
                  classes={{ root: classes.searchBox }}
                  fullWidth
                  placeholder="Search"
                  label="Internal SMS Number"
                  InputLabelProps={{shrink: true}}
                  onChange={(e, text) => this.setState({ searchInput: e.target.value })}
                />
                <SearchIcon classes={{ root: classes.searchIcon }} />
              </div>
              <div style={style.numbersWrapper}>
                <List>
                  {this.smsDids().map((number) =>
                    <ListItem button
                      key={number.number}
                      style={
                          this.props.selectedInternalDid == number.number ?
                              { backgroundColor: '#DFDFDF' } : null
                      }
                      onClick={() => {
                        this.props.newInternalDidSelected(number.number);
                        this.props.showingNewNumberInput(false)
                      }}
                    >
                        <ListItemText primary={formatNumber(number.number)} secondary={number.description || 'No description'} />
                        <ListItemSecondaryAction>{getUnreadCount(number)}</ListItemSecondaryAction>
                    </ListItem>
                  )}
                </List>
              </div>
            </div>
          </div>
        );
    }
}

const getUnreadCount = (number) => {

    const unreadCount = number.unread;

    // Set style for the counter
    let countStyle = {
        alignItems: 'center',
        borderRadius: 50,
        display: 'flex',
        fontSize: 12,
        height: 27,
        justifyContent: 'center',
        width: 27,
        backgroundColor: '#DFDFDF',
        color: '#737373',
    };

    if (unreadCount > 0) {

        countStyle = {
            alignItems: 'center',
            borderRadius: 50,
            display: 'flex',
            fontSize: 12,
            height: 27,
            justifyContent: 'center',
            width: 27,
            backgroundColor: 'rgba(22, 107, 192, .6)',
            color: '#FFF',
            fontWeight: 'bold',
        };
    }

    return <div style={countStyle}>{unreadCount}</div>;
};

const style = {
    handleWrapper: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        width: 25,
        float: 'right',
        borderRightStyle: 'solid',
        borderRightWidth: 1,
        borderRightColor: '#DFDFDF',
        paddingBottom: 100,
        backgroundColor: '#f0f8ff',
    },
    handle: {
        transform: 'rotate(90deg)',
        color: '#666',
        minWidth: 0,
    },
    numbersWrapper: {
        marginLeft: 25,
        marginRight: 25,
        height: '100%',
        overflowY: 'auto',
    },
    searchWrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 10,
    },
};

const mapStateToProps = (state) => ({
    dids: state.http && state.http.configurations ? state.http.configurations.phonenumbers : null,
    selectedInternalDid: state.sms ? state.sms.selectedInternalDid : null,
    showNewNumberInput: state.sms? state.sms.showNewNumberInput : null,
    token: state.token,
});

const bindActions = (dispatch) => ({
    newInternalDidSelected: (number) => dispatch(newInternalDidSelected(number)),
    showingNewNumberInput: (showNewNumberInput) => dispatch(showingNewNumberInput(showNewNumberInput)),
});

export default connect(mapStateToProps, bindActions)(withStyles(matStyles)(SmsNumberDrawer));
