import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Button from '@material-ui/core/Button';
import makeGlobalStyles from 'makeGlobalStyles';

export default function HelpContent(props) {
  const classes = props.useStyles();
  const globalClasses = makeGlobalStyles();
  return (
    <div className={classes.wrapper}>
        <Card classes={{ root: classes.helpPaper }} elevation={1}>
          <CardContent classes={{ root: classes.helpContent }}>
            <CardContent>
              <h2 className={classes.pageTitle}>Manage and Purchase FluentCloud Phone Numbers</h2>
              <p className={classes.helpText}>
                This section of My FluentCloud allows you to manage your phone numbers, as well as to purchase new ones.
              </p>
            </CardContent>
            <CardContent classes={{ root: classes.helpTextTitle }}>
              About Phone Numbers
            </CardContent>
            <CardContent>
              <div>
                <p>Phone numbers, also known as DID (Direct Inward Dial) numbers or Telephone Numbers (TNs),
                   are the unique identifier that anyone in the world can use to reach you.</p>
                <p>With this page, you can purchase new phone numbers and manage all the existing phone numbers on your account.</p>
                <p>Once you have created your phone numbers, 
                  you can manage add <a href="#routes">routes</a> or <a href="#extensions">extensions</a> to point to these numbers.</p>
              </div>
            </CardContent>
          </CardContent>
          <CardContent classes={{ root: classes.standardHelpBlurb }}>
            <div className="phoneNumbersLegend">
              <div className="phoneNumbersLegendElement">
                <span style={{fontSize:"80%"}} className="label label-extension">Extension</span>
              </div>
              <div className="phoneNumbersLegendElement">
                <span style={{fontSize:"80%"}} className="label label-route">Route</span>
              </div>
              <div className="phoneNumbersLegendElement">
                <span style={{fontSize:"80%"}} className="label label-managed-route">Managed Route</span>
              </div>
              <div className="phoneNumbersLegendElement">
                <span style={{fontSize:"80%"}} className="label label-conference">Conference</span>
              </div>
              <div className="phoneNumbersLegendElement">
                <span style={{fontSize:"80%"}} className="label label-forwarding">Call Forwarding</span>
              </div>
            </div>
          </CardContent>
          <CardContent classes={{ root: classes.standardHelpBlurb }}>
            <CardContent>
              <h2 className={classes.helpTextTitle}>Need Help?</h2>
              <div>
                <p className={classes.helpText}>The whole team at FluentStream is ready to help you. 24 hours a day, 365 days a year.
                    You can reach us a number of ways.</p>
                <ul>
                  <li>By calling 303-GO-CLOUD (303-462-5683)</li>
                  <li>By emailing support@fluentstream.com</li>
                  <li>By clicking the button below!</li>
                </ul>
              </div>
              <p />
              <Button
                size='large'
                classes={{ root: globalClasses.helpButton }}
                href="http://help.fluentcloud.com/support/solutions/articles/4000148905-how-to-set-up-phone-numbers-call-routing"
                target="_blank"
              >How To Set Up Phone Numbers</Button>
            </CardContent>
          </CardContent>
        </Card>
        
      </div>
  );
}