import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
    slider: {
        marginLeft: 50,
        marginRight: 50
    }
});

function valuetext(value) {
  return `${value}`;
}

export default function RangeSlider(props) {
  const classes = useStyles();
  const [value, setValue] = useState([-.5, .5]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onMaxRangeSlide(newValue)
    props.onMinRangeSlide(newValue)
    props.onChange(newValue)
  };

  return (
    <div>
      <Typography 
        id="range-slider" 
        gutterBottom
        align="inherit"
        color='textSecondary'
      >
        {props.placeholder}
      </Typography>
      <div className={classes.slider}>
      <Typography 
        variant="caption"
        color="textSecondary"
        gutterBottom
      >
      Score between -1.0 (indicating negative emotions) and 1.0 (indicating positive emotions) based on the analysis of key words in the received voicemail
      </Typography>
      <Slider
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        marks={props.marks}
        step={.1}
        min={-1}
        max={1}
      />
    </div>
    </div>
  );
}
