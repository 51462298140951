import React from 'react';
import PropTypes from 'prop-types';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import BackArrow from '@material-ui/icons/KeyboardBackspace';
import MenuButton from '@material-ui/icons/Menu';
import ContextMenuIcon from '@material-ui/icons/MoreVert';

import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

function DetailContainerRenderer(props) {
  const classes = props.useStyles();
  const { parent } = props;
  return (
    <div className={classes.wrapper}>
      <Toolbar classes={{ root: classes.toolbar }} className="detailContainerDiv">
        <span>
          {!props.minWidth ?
            <IconButton
              classes={{ root: classes.menuButton }}
              onClick={() => parent.setState({ leftBarOpen: !parent.state.leftBarOpen })}
            >
              <MenuButton />
            </IconButton>
            : null
          }
          {parent.props.route || (parent.props.backButtonWithoutRoute && parent.props.title) ?
            <Button
              classes={{ root: classes.backButton }}
              onClick={() => parent.props.return()}
            >{<BackArrow />}</Button> : <div className={classes.backButton} />}
        </span>
        <span
          className={classes.toolbarTitle}
        >{props.title}</span>
        <Typography>{parent.props.buttons ? parent.props.buttons : null}</Typography>
        <div style={{ flexGrow: '1' }} />
          {parent.props.contextMenuOptions && parent.props.contextMenuOptions.length > 0 ?
            <div>

              <IconButton onClick={(e) => parent.setState({ anchorEl: e.currentTarget })}>
                <ContextMenuIcon />
              </IconButton>
              <Menu open={Boolean(parent.state.anchorEl)} anchorEl={parent.state.anchorEl} onClose={()=> parent.setState({ anchorEl: null })}>
                {parent.props.contextMenuOptions.map((item) => item)}
              </Menu>

            </div>
            : null}
      </Toolbar>
      {props.leftBarWithProps}
      {parent.props.children}
    </div>
  );
}

DetailContainerRenderer.propTypes = {
  useStyles: PropTypes.func,
}

export default DetailContainerRenderer;
